import { autoinject } from 'aurelia-framework';
import { GlobalizationService } from '../../framework/base/export';

import * as moment from "moment";

@autoinject
export class FormatDateExValueConverter {
  constructor(
    private _globalizationService: GlobalizationService
  ) {}

  toView(date: Date) {
    if (!date) {
      return "";
    }

    const mDate = moment(date).startOf("day");
    const today = moment().startOf("day");
    
    if (mDate.isSame(today)) {
      return this._globalizationService.format(date, "t");
    } else if (today.diff(mDate, "d") <= 6) {
      return this._globalizationService.format(date, "DT_dddd, HH:mm");
    } else {
      return this._globalizationService.format(date, "g");
    }
  }
}
