export interface IZitMarkersOptions {
  markerList?: IZitMarker[];
  displayMode?: ZitMarkerDisplayMode;
  idObjekt?: number;
  onClick?: { (event: Event, data: any) };
}
interface IZitMarker {
  caption: string;
  backgroundColor: string;
}
export enum ZitMarkerDisplayMode {
  balken = "b",
  kreis = "k"
}
