import {
  autoinject, TemplatingEngine, TaskQueue
} from "aurelia-framework";
import {
  FormBase
} from "../classes/form-base";
import {
  CommandService,
  DefaultCommandsService,
  PopupInfoService,
  SelectItemService,
  ToolbarService,
  ValidationService,
  SettingService
} from "../services/export";
import {
  DataSourceService,
  GlobalizationService,
  LocalizationService,
  RestService
} from "../../base/services/export";
import {
  BaseWidgetCreatorService
} from "./base-widget-creator-service";
import {
  ICommandData,
  IUpdateablePopupOptions
} from "../interfaces/export";
import * as WidgetOptions from "../widget-options/export";
import * as DxLoader from "../../dx/dx-loader";
import { HtmlEditorService } from '../services/html-editor-service';

@autoinject
export class SimpleWidgetCreatorService {
  constructor(
    private baseWidgetCreator: BaseWidgetCreatorService,
    private dataSource: DataSourceService,
    private globalization: GlobalizationService,
    private localization: LocalizationService,
    private toolbar: ToolbarService,
    private defaultCommands: DefaultCommandsService,
    private validation: ValidationService,
    private selectItem: SelectItemService,
    private popupInfo: PopupInfoService,
    private command: CommandService,
    private setting: SettingService,
    private templatingEngine: TemplatingEngine,
    private taskQueue: TaskQueue,
    private htmlEditorService: HtmlEditorService
  ) { }

  addAccordion(form: FormBase, options: WidgetOptions.IAccordionOptions) {
    this.baseWidgetCreator.createWidgetOptions(form, options);
  }
  addCalendar(form: FormBase, options: WidgetOptions.ICalendarOptions) {
    this.createEditorOptions(form, options);
  }
  addCheckBox(form: FormBase, options: WidgetOptions.ICheckBoxOptions) {
    const editorOptions: DevExpress.ui.dxCheckBoxOptions = this.createEditorOptions(form, options);

    if (this.setting.instance.IsLabelBeforeEditor) {
      editorOptions.text = "        ";
    } else {
      editorOptions.text = this.localization.translateOnce(options.caption, form.scopeContainer);
    }

    editorOptions;
  }
  addColorBox(form: FormBase, options: WidgetOptions.IColorBoxOptions) {
    const editorOptions: DevExpress.ui.dxColorBoxOptions = this.createEditorOptions(form, options);

    if (options.editAlphaChannel) {
      editorOptions.editAlphaChannel = options.editAlphaChannel;
    }

    editorOptions;
  }
  addDateBox(form: FormBase, options: WidgetOptions.IDateBoxOptions) {
    const editorOptions: DevExpress.ui.dxDateBoxOptions = this.createEditorOptions(form, options);

    if (options.min) {
      editorOptions.min = options.min;
    } else {
      editorOptions.min = new Date(1800, 0, 1);
    }

    if (options.max) {
      editorOptions.max = options.max;
    }
    if (options.format) {
      editorOptions.displayFormat = this.globalization.getFormatterParser(options.format);

      switch (options.format.toLowerCase()) {
        case "t": {
          editorOptions.type = "time";
          break;
        }
        case "g": {
          editorOptions.type = "datetime";
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  addCommand(form: FormBase, options: WidgetOptions.ICommandElementOptions) {
    let command: ICommandData;

    if (options.binding.dataContext) {
      command = form.commandServerData[`${options.binding.dataContext};${options.binding.bindTo}`];
    } else {
      command = form.binding.evaluate(form.scope, options.binding.bindToFQ);
    }

    const buttonOptions: DevExpress.ui.dxButtonOptions = {};
    buttonOptions.text = this.localization.translateOnce(command.title, form.scopeContainer);
    buttonOptions.hint = this.localization.translateOnce(command.tooltip, form.scopeContainer);
    buttonOptions.width = "100%";
    buttonOptions.onClick = (a: any) => {
      if (typeof command.execute === "function") {
        command.execute({
          event: a.event
        });
      } else if (typeof command.execute === "string") {
        form.binding.evaluate(form.scope, command.execute);
      } else {
        throw new Error();
      }
    };

    form[options.options.optionsName] = buttonOptions;
  }
  addFileUploaderWithViewer(form: FormBase, options: WidgetOptions.IFileUploaderWithViewerOptions) {
    const widgetOptions = this.createEditorOptions(form, options);

    widgetOptions.acceptType = options.acceptType;
    widgetOptions.acceptTypeEnum = options.acceptTypeEnum;
    widgetOptions.caption = options.caption;
    widgetOptions.height = options.height;
    widgetOptions.iconDownload = options.iconDownload;
    widgetOptions.iconDownloadExpression = options.iconDownloadExpression;
    widgetOptions.placeholderIcon = options.placeholderIcon;
    widgetOptions.placeholderIconExpression = options.placeholderIconExpression;
    widgetOptions.placeholderImage = options.placeholderImage;
    widgetOptions.placeholderImageExpression = options.placeholderImageExpression;
    widgetOptions.placeholderImageText = options.placeholderImageText;
    widgetOptions.showViewer = options.showViewer;
    widgetOptions.tooltip = options.tooltip;
    widgetOptions.maxFileSizeMB = options.maxFileSizeMB;
    widgetOptions.caption = options.caption;
    widgetOptions.showToolbar = options.showToolbar;
    widgetOptions.showClearButton = options.showClearButton;

    //Werte aus erstellten Options nehmen, da diese ggf. erweitert wurden
    widgetOptions.isDisabled = widgetOptions.disabled;
    if (widgetOptions.bindingOptions.disabled != void (0)) {
      widgetOptions.isDisabledExpression = widgetOptions.bindingOptions.disabled;
    }

    widgetOptions.isReadOnly = widgetOptions.readOnly;
    if (widgetOptions.bindingOptions.readOnly != void (0)) {
      widgetOptions.isReadOnlyExpression = widgetOptions.bindingOptions.readOnly;
    }
  }
  addHtmlEditor(form: FormBase, options: WidgetOptions.IHtmlEditorOptions) {
    const editorOptions: DevExpress.ui.dxHtmlEditorOptions = this.createEditorOptions(form, options);
    this.htmlEditorService.configureHtmlEditor(form.scopeContainer, options, editorOptions);
  }
  addInclude(form: FormBase, options: WidgetOptions.IIncludeOptions) {
  }
  addLookup(form: FormBase, options: WidgetOptions.ISelectOptions) {
    const editorOptions: DevExpress.ui.dxLookupOptions = this.createEditorOptions(form, options);
    const selectItem = this.selectItem.getSelectItem(options.idSelect);

    this.addDataExpressionOptions(form, options, editorOptions, selectItem);

    if (editorOptions.closeOnOutsideClick == void (0)) {
      editorOptions.closeOnOutsideClick = true;
    }

    editorOptions.title = this.localization.translateOnce("forms.lookup_selectItem");

    if (selectItem.searchMode) {
      editorOptions.searchMode = <any>selectItem.searchMode;
    }

    if (selectItem.titleTemplateName) {
      editorOptions.titleTemplate = selectItem.titleTemplateName;
    }
    if (selectItem.fieldTemplateName) {
      editorOptions.fieldTemplate = selectItem.fieldTemplateName;
    }
    if (selectItem.itemTemplateName) {
      editorOptions.itemTemplate = selectItem.itemTemplateName;
    }
  }
  addNumberBox(form: FormBase, options: WidgetOptions.INumberBoxOptions) {
    const editorOptions: DevExpress.ui.dxNumberBoxOptions = this.createEditorOptions(form, options);

    if (options.format) {
      editorOptions.format = this.globalization.getNumberFormat(options.format);
    }
    if (options.showClearButton) {
      editorOptions.showClearButton = true;
    }
    if (options.showSpinButtons) {
      editorOptions.showSpinButtons = true;
    }
    if (options.max != void (0)) {
      editorOptions.max = options.max;
    }
    if (options.min != void (0)) {
      editorOptions.min = options.min;
    }
    if (options.step) {
      editorOptions.step = options.step;
    }
  }
  addPopover(form: FormBase, options: WidgetOptions.IPopoverOptions) {
    const widgetOptions: DevExpress.ui.dxPopoverOptions = this.baseWidgetCreator.createWidgetOptions(form, options);

    widgetOptions.contentTemplate = "contentTemplate";

    if (options.caption) {
      widgetOptions.title = this.localization.translateOnce(options.caption);
    }
  }
  addPopup(form: FormBase, options: WidgetOptions.IPopupOptions, registerPopupInfo: boolean = true) {
    const widgetOptions: DevExpress.ui.dxPopupOptions = this.baseWidgetCreator.createWidgetOptions(form, options);
    const idToolbar = `${options.id}Toolbar`;

    widgetOptions.showCloseButton = false;
    widgetOptions.contentTemplate = "contentTemplate";
    widgetOptions.titleTemplate = (container: Element) => {

      const toolbar = document.createElement("toolbar");
      toolbar.setAttribute("options.bind", idToolbar);
      container.appendChild(toolbar);

      const view = this.templatingEngine.enhance({
        element: toolbar,
        bindingContext: form.scopeContainer.scope.bindingContext,
        overrideContext: form.scopeContainer.scope.overrideContext
      });

      const dxEventOn: any = DevExpress.events.on;
      dxEventOn(<any>toolbar, "dxremove", () => {
        view.unbind();
        view.detached();
      });
    };

    let onInitialized = widgetOptions.onInitialized;

    widgetOptions.onInitialized = (e) => {
      if (onInitialized) {
        onInitialized(e);
      }
      onInitialized = null;

      const component = <DevExpress.ui.dxPopup>e.component;
      if (registerPopupInfo) {

      }
      component.on("showing", c => {
        const content: Element = component.content();
        content.parentElement.classList.add("t--popup");

        if (registerPopupInfo) {
          this.popupInfo.onShowPopup({
            owner: form,
            popup: c.component
          });
        }
      });
      component.on("hidden", c => {
        if (registerPopupInfo) {
          this.popupInfo.onHidePopup(c.component);
        }
      });
    };

    if (options.height) {
      widgetOptions.height = options.height;
    }
    if (options.width) {
      widgetOptions.width = options.width;
    }
    widgetOptions.maxHeight = "100%";
    widgetOptions.maxWidth = "100%";

    const commands: ICommandData[] = [];
    if (options.showCloseButton == void (0) || options.showCloseButton == true) {
      commands.push(this.defaultCommands.getClosePopupCommand());
    }

    commands.push(...options.commands.map(c => {
      const cmd = form.binding.evaluate(form.scope, c.binding.bindToFQ);
      if (!cmd) {
        throw new Error(`No command for ${c.binding.bindToFQ} found`);
      }

      return cmd;
    }));

    form[idToolbar] = this.toolbar.createToolbarOptions(
      form.scopeContainer,
      options.caption,
      commands
    );
  }
  addRadioGroup(form: FormBase, options: WidgetOptions.ISelectOptions) {
    const editorOptions: DevExpress.ui.dxRadioGroupOptions = this.createEditorOptions(form, options);
    const selectItem = this.selectItem.getSelectItem(options.idSelect);

    this.addDataExpressionOptions(form, options, editorOptions, selectItem);

    if (selectItem.itemTemplateName) {
      editorOptions.itemTemplate = selectItem.itemTemplateName;
    }
  }
  addSelectBox(form: FormBase, options: WidgetOptions.ISelectOptions) {
    const editorOptions: DevExpress.ui.dxSelectBoxOptions = this.createEditorOptions(form, options);
    const selectItem = this.selectItem.getSelectItem(options.idSelect);

    editorOptions.searchEnabled = true;
    editorOptions.searchExpr = selectItem.displayMember;
    editorOptions.showClearButton = true;

    if (selectItem.searchMode) {
      editorOptions.searchMode = <any>selectItem.searchMode;
    }

    this.addDataExpressionOptions(form, options, editorOptions, selectItem);

    if (selectItem.fieldTemplateName) {
      editorOptions.fieldTemplate = selectItem.fieldTemplateName;
    }
    if (selectItem.itemTemplateName) {
      editorOptions.itemTemplate = selectItem.itemTemplateName;
    }
  }
  addTab(form: FormBase, options: WidgetOptions.ITabOptions) {
    const tabOptions: DevExpress.ui.dxTabsOptions = this.baseWidgetCreator.createWidgetOptions(form, options);

    let component: DevExpress.ui.dxTabs;
    tabOptions.onInitialized = (e) => {
      component = <DevExpress.ui.dxTabs>e.component;
    }

    tabOptions.items = [];
    const propSelected = `${options.id}Selected`;
    tabOptions.bindingOptions["selectedIndex"] = propSelected;

    options.pages.forEach((page, index) => {
      const pageOptions = {
        text: this.localization.translateOnce(page.caption, form.scopeContainer),
        visible: true,
        __options: page,
        __index: index
      };

      if (page.if) {
        form.binding.observe({
          scopeContainer: form.scopeContainer,
          expression: page.if,
          callback: (newValue) => {
            if (component) {
              component.option(`items[${index}].visible`, newValue);
            }

            pageOptions.visible = newValue;

            if ((!newValue && form[`${options.id}Selected`] == index)
              || (newValue && form[`${options.id}Selected`] == -1)) {
              this.taskQueue.queueMicroTask(() => {
                //Prüfen, welchen Tab wird jetzt anzeigen, da aktueller nicht mehr sichtbar ist ...
                for (let p of tabOptions.items) {
                  if (!p.visible) {
                    continue;
                  }

                  form[`${options.id}Selected`] = p.__index;
                  break;
                }

                if (form[`${options.id}Selected`] == index) {
                  form[`${options.id}Selected`] = -1;
                }
              });
            }
          }
        });

        pageOptions.visible = form.binding.evaluate(form.scope, page.if);
      }

      tabOptions.items.push(pageOptions);
    });

    const firstVisible = tabOptions.items.find(c => c.visible);
    if (firstVisible) {
      form[propSelected] = firstVisible.__index;
    } else {
      form[propSelected] = -1;
    }

    const callOnActivate = (e) => {
      if (!e.addedItems || e.addedItems.length === 0) {
        return;
      }

      const page = e.addedItems[0];
      if (!page || !page.__options || !page.__options.onActivated) {
        return;
      }

      form.binding.evaluate(form.scope, page.__options.onActivated);
    }
    const checkGridInitialize = (e) => {
      const index = e.component.option("selectedIndex");
      const name = `${options.id}TabPage${index}`;

      if (!form[name]) {
        return;
      }

      const element: Element = form[name];
      const attr = element.getAttribute("data-tab-page-activated");
      if (attr == "true") {
        return;
      }

      element.setAttribute("data-tab-page-activated", "true");
      const children = Array.from(element.querySelectorAll(".dx-widget > .dx-datagrid"));
      for (let i = 0; i < children.length; i++) {
        const gr = <HTMLElement>children[i];
        let parent = gr.parentElement;

        while (parent != null && parent != element) {
          if (parent.getAttribute("data-tab-page-activated") == "false") {
            return;
          }
          parent = parent.parentElement;
        }

        DxLoader.getInstance("dxDataGrid", gr.parentElement)
          .updateDimensions();
      }
    }

    tabOptions.onSelectionChanged = (e) => {
      callOnActivate(e);

      this.taskQueue.queueMicroTask(() => {
        checkGridInitialize(e);
      });
    };
  }
  addTagBox(form: FormBase, options: WidgetOptions.ITagBoxOptions) {
    const widgetOptions: DevExpress.ui.dxTagBoxOptions = this.createEditorOptions(form, options);
    const selectItem = options.editorOptions && options.editorOptions.idSelect
      ? this.selectItem.getSelectItem(options.editorOptions.idSelect)
      : null;

    widgetOptions.searchEnabled = true;
    let keyProperty = "";

    if (selectItem) {
      widgetOptions.valueExpr = selectItem.valueMember;
      widgetOptions.displayExpr = selectItem.displayMember;

      if (selectItem.fieldTemplateName) {
        widgetOptions.fieldTemplate = selectItem.fieldTemplateName;
      }
      if (selectItem.itemTemplateName) {
        widgetOptions.itemTemplate = selectItem.itemTemplateName;
      }

      this.addDataExpressionOptions(form, options.editorOptions, widgetOptions, selectItem);
      keyProperty = selectItem.valueMember;
    } else {
      widgetOptions.valueExpr = options.itemValueExpr;
      widgetOptions.displayExpr = options.itemDisplayExpr;

      const model = form.models.getInfo(options.itemDataContext);
      const dataSource = this.dataSource.createDataSource(
        form.scopeContainer, 
        model,
        null,
        () => dataSource.reload());
      widgetOptions.dataSource = dataSource;
      keyProperty = model.keyProperty;
    }

    if (options.batchSelectionEnabled) {
      widgetOptions.showSelectionControls = true;
      widgetOptions.applyValueMode = "useButtons";
    }

    if (options.fieldTemplateName) {
      widgetOptions.fieldTemplate = options.fieldTemplateName;
    }
    if (options.itemTemplateName) {
      widgetOptions.itemTemplate = options.itemTemplateName;
    }
    if (options.tagTemplateName) {
      widgetOptions.tagTemplate = options.tagTemplateName;
    }

    let onInitialized = widgetOptions.onInitialized;

    widgetOptions.onInitialized = (e) => {
      if (onInitialized) {
        onInitialized(e);
      }

      onInitialized = null;

      const component = <DevExpress.ui.dxTagBox>e.component;
      component.on("selectionChanged", scArgs => {
        const addedItems: any[] = scArgs.addedItems;
        const removedItems: any[] = scArgs.removedItems;

        let list: any[] = form.binding.evaluate(form.scope, options.relationBinding.bindToFQ);

        if (list == void (0)) {
          list = [];
          form.binding.assign(form.scope, options.relationBinding.bindToFQ, list);
        }

        addedItems.map(c => c).forEach(c => {
          const indexOf1 = removedItems.findIndex(d => c[keyProperty] == d[keyProperty]);
          if (indexOf1 < 0) {
            return;
          }

          const indexOf2 = addedItems.findIndex(d => c[keyProperty] == d[keyProperty]);
          addedItems.splice(indexOf2, 1);
          removedItems.splice(indexOf1, 1);
        });

        addedItems.forEach(c => {
          const exists = list.some(d => d[options.relationProperty] == c[keyProperty]);
          if (exists) {
            return;
          }

          const newObj = {};
          newObj[options.relationProperty] = c[keyProperty];
          list.push(newObj);
        });
        removedItems.forEach(c => {
          const existsList = list.filter(d => d[options.relationProperty] == c[keyProperty]);

          existsList.forEach(d => {
            const index = list.indexOf(d);
            list.splice(index, 1);
          })
        });
      });
    }

    form.models.onLoaded.register(a => {
      if (a.model.id !== options.dataContext) {
        return;
      }

      const list: any[] = form.binding.evaluate(form.scope, options.relationBinding.bindToFQ)
        || [];

      const data = list.map(c => c[options.relationProperty])
        .filter(c => c != void (0));

      if (form[options.id]) {
        form[options.id].setOption({
          value: data
        });
      } else {
        widgetOptions.value = data;
      }

      return Promise.resolve();
    });
  }
  addTextBox(form: FormBase, options: WidgetOptions.ITextBoxOptions) {
    const editorOptions: DevExpress.ui.dxTextBoxOptions = this.createEditorOptions(form, options);

    if (options.maxLength) {
      editorOptions.maxLength = options.maxLength;
    }
    if (options.mode) {
      editorOptions.mode = <any>options.mode;
    }
    if (options.useMaskedValue != void (0)) {
      editorOptions.useMaskedValue = options.useMaskedValue;
    }
    if (options.mask) {
      editorOptions.mask = options.mask;
    }
    if (options.maskChar) {
      editorOptions.maskChar = options.maskChar;
    }
    if (editorOptions.mode == "password" && form.id.toLowerCase().indexOf("login") < 0) {
      if (editorOptions.inputAttr == void(0)) {
        editorOptions.inputAttr = {};
      }
      editorOptions.inputAttr["autocomplete"] = "new-password";
    }
  }
  addTextArea(form: FormBase, options: WidgetOptions.ITextAreaOptions) {
    const editorOptions: DevExpress.ui.dxTextAreaOptions = this.createEditorOptions(form, options);

    if (options.maxLength) {
      editorOptions.maxLength = options.maxLength;
    }

    if (options.height) {
      editorOptions.height = options.height;
    }
  }
  addValidationGroup(form: FormBase, options: WidgetOptions.IValidationGroupOptions) {
    const validationOptions: DevExpress.ui.dxValidationGroupOptions = {};

    form[options.options.optionsName] = validationOptions;

    form.onValidating.register(r => {
      const widget = form[options.id];
      if (!widget) {
        return Promise.resolve();
      }

      const groupConfig = DevExpress.validationEngine.getGroupConfig(widget.instance);
      if (groupConfig === undefined) {
        return Promise.resolve();
      }

      const instance: DevExpress.ui.dxValidationGroup = widget.instance;

      const result = instance.validate();

      if (result.isValid) {
        return Promise.resolve(r.validationResult);
      } else {
        r.validationResult.isValid = false;
        r.validationResult.messages.push(...result
          .brokenRules
          .map(c => c.message));

        return Promise.resolve();
      }
    });
  }

  updatePopupOptions(updateablePopupOptions: IUpdateablePopupOptions) {
    const options = updateablePopupOptions.options;

    var commands: ICommandData[] = [];
    commands.push(this.defaultCommands.getClosePopupCommand());
    commands.push(...updateablePopupOptions.commands);

    options.showCloseButton = false;
    options.titleTemplate = (container: Element) => {
      const toolbar = document.createElement("toolbar");
      toolbar.setAttribute("options.bind", updateablePopupOptions.idToolbar);

      container.appendChild(toolbar);

      const view = this.templatingEngine.enhance({
        element: toolbar,
        bindingContext: updateablePopupOptions.scopeContainer.scope.bindingContext,
        overrideContext: updateablePopupOptions.scopeContainer.scope.overrideContext
      });

      const dxEventOn: any = DevExpress.events.on;
      dxEventOn(<any>toolbar, "dxremove", () => {
        view.unbind();
        view.detached();
      });
    };
    options.contentTemplate = "contentTemplate";

    let onInitialized = options.onInitialized;
    let component: DevExpress.ui.dxPopup;
    options.onInitialized = (e) => {
      if (onInitialized) {
        onInitialized(e);
      }

      onInitialized = null;

      component = <DevExpress.ui.dxPopup>e.component;
      component.on("showing", c => {
        const content: Element = c.component.content();
        content.parentElement.classList.add("t--popup");

        this.popupInfo.onShowPopup({
          owner: null,
          popup: c.component,
          executeCommand: (idCommand) => {
            const command = commands.find(c => c.id == idCommand);
            if (!command) {
              return;
            }

            this.command.execute(
              updateablePopupOptions.scopeContainer.scope,
              command, {
                event: null
              }
            );
          }
        });
      });
      component.on("hidden", c => {
        this.popupInfo.onHidePopup(c.component);
      });
    }

    updateablePopupOptions.scopeContainer.scope.bindingContext[updateablePopupOptions.idToolbar] = this.toolbar.createToolbarOptions(
      updateablePopupOptions.scopeContainer,
      updateablePopupOptions.caption,
      commands
    );
  }

  private createEditorOptions(form: FormBase, options: WidgetOptions.IEditorOptions): any {
    const editorOptions: DevExpress.ui.EditorOptions = this.baseWidgetCreator.createWidgetOptions(form, options);

    editorOptions["onValueChangedByUser"] = (e) => {
      form.onEditorValueChanged.fire({
        binding: options.binding,
        value: e.value
      });
    };

    let onInitialized = editorOptions.onInitialized;
    editorOptions.onInitialized = (e) => {
      if (onInitialized) {
        onInitialized(e);
      }

      onInitialized = null;

      const component = <DevExpress.ui.Editor>e.component;
      component.on("valueChanged", (c) => {
        if (options.onValueChanged) {
          form.binding.execute(form.scope, options.onValueChanged, c);
        }
      });
    };

    if (options.binding && options.binding.bindToFQ) {
      editorOptions.bindingOptions["value"] = options.binding.bindToFQ;
    }

    if (options.isReadOnly) {
      editorOptions.readOnly = true;
    } else if (options.binding && options.binding.dataContext) {
      const model = form.models.getInfo(options.binding.dataContext, false);
      let readOnlyExpression = options.isReadOnlyExpression;

      if (model && model.modificationInfoEnabled) {
        const modExpression = `!models.data.${model.id}.CanSave`;

        if (readOnlyExpression == void (0)) {
          readOnlyExpression = modExpression;
        } else {
          readOnlyExpression = `(${readOnlyExpression}) || (${modExpression})`;
        }
      }

      if (readOnlyExpression != void (0)) {
        editorOptions.bindingOptions["readOnly"] = readOnlyExpression;
      }
    } else if (options.isReadOnlyExpression) {
      editorOptions.bindingOptions["readOnly"] = options.isReadOnlyExpression;
    }

    if (options.placeholder) {
      (<any>editorOptions).placeholder = this.localization.translateOnce(options.placeholder, form.scopeContainer);
    }

    editorOptions["validators"] = options.validationRules.map(v => {
      if (v.binding) {
        return form.binding.evaluate(form.scope, v.binding.bindToFQ);
      } else if (v.item) {
        return this.validation.getValidator(
          form.scopeContainer,
          v.item.type,
          options.caption,
          v.item.parameters
        );
      } else {
        throw new Error("No binding/item specified");
      }
    });

    return editorOptions;
  }
  private addDataExpressionOptions(form: FormBase, options: WidgetOptions.ISelectOptions, current: DevExpress.ui.DataExpressionMixinOptions, selectItem: WidgetOptions.ISelectItem): void {
    const observerCallback = (dataSourceOptions) => {
      const observers = this.dataSource.getElementsToObserve(dataSourceOptions);
      if (observers.length > 0) {
        const editorOptions: DevExpress.ui.EditorOptions = current;

        let onInitialized = editorOptions.onInitialized;
        editorOptions.onInitialized = (e) => {
          if (onInitialized) {
            onInitialized(e);
          }

          onInitialized = null;

          form.onEditorValueChanged.register(evcArgs => {
            if (evcArgs.binding) {
              if (observers.some(c => c === evcArgs.binding.bindToFQ)) {
                form.binding.assign(form.scope, options.binding.bindToFQ, null);

                //Event manuell auslösen (wurde ja nicht direkt durch Benutzer gemacht)
                form.onEditorValueChanged.fire({
                  binding: options.binding,
                  value: null
                });
              }
            }

            return Promise.resolve();
          });
        }
      }
    };
    
    const dataSource = this.selectItem.createSelectDataSource(
      form.scopeContainer,
      selectItem,
      options.filter,
      options.filters,
      options.customs,
      observerCallback);

    current.dataSource = dataSource;
    current.valueExpr = selectItem.valueMember;
    current.displayExpr = selectItem.displayMember;
  }
}
