import { FormBase } from '../../../../framework/forms/classes/form-base';
import { autoinject, transient } from 'aurelia-framework';

@autoinject
@transient()
export class PersonAusbildungErweitertEditUtils {
  constructor(
  ) { }

  form: FormBase;
  requiredValidationRule = { type: "required" };
  idAusbildungAbschluss: number;

  bind(form: FormBase) {
    this.form = form;

    form.onValidating.register((args) => {
      if (args.form.models && args.form.models.data.$m_AusbildungNeu) {
        args.form.models.data.$m_AusbildungNeu.IdAusbildungAbschluss = this.idAusbildungAbschluss;
      }

      return Promise.resolve();
    });

    form.models.onSaved.register((args) => {
      if (args && args.model && args.model.id === "$m_AusbildungNeu") {
        this.form.models.data.$m_AusbildungNeu.IdAusbildungAbschluss = this.idAusbildungAbschluss;
      }

      return Promise.resolve();
    });
  }
}
