import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class AufgabeListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "aufgabe-obj-list";
        this.title = "aufgabe-obj-list.aufgabe-obj-list_caption";
        this.addModel({
            "id": "$m_Aufgabe",
            "webApiAction": "ZIT/Objekte/Aufgabe",
            "webApiExpand": {
                Kopf: null
            },
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_aufgabeBezeichnung",
                "caption": "aufgabe-obj-list.r_aufgabebezeichnung_caption",
                "bindTo": "Kopf.Name"
            }],
            "optionsToolbar": {
                "optionsName": "r_aufgabeGridToolbarOptions",
                "optionsNameFQ": "r_aufgabeGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Aufgabe"
            },
            "dataModel": "$m_Aufgabe",
            "editUrl": "Objekte/Aufgabe",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_aufgabeGrid",
            "options": {
                "optionsName": "r_aufgabeGridOptions",
                "optionsNameFQ": "r_aufgabeGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}