import { autoinject, transient } from "aurelia-framework";
import { GlobalizationService } from "./../../../../framework/base/services/globalization-service";
import { RestService } from "./../../../../framework/base/services/rest-service";
import { FormBase } from "./../../../../framework/forms/classes/form-base";
import { ICommandData } from "./../../../../framework/forms/interfaces/command-data";
import { IZitTagOptions } from "./../../../../zit-ui/elements/zit-tag/zit-tag-options";
import { ZitMomentValueConverter } from "./../../../../zit-ui/value-converters/moment-value-converter";
import { Share } from "./../../../elements/share/share";

@autoinject
@transient()
export class EventTeilnehmerReadUtils {

  constructor(
    private _globalizationService: GlobalizationService,
    private _zitMomentValueConverter: ZitMomentValueConverter,
    private _restService: RestService
  ) { }

  form: FormBase;

  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };

  eventZeitraumTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item.EventZeitraumBezeichnung`,
    icon: {
      text: "E"
    }
  };

  startDatumTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: "item._Zeitraum",
    icon: {
      faIcon: "far fa-calendar-alt"
    }
  };

  ansprechpersonTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item.Ansprechperson`,
    icon: {
      faIcon: "fas fa-user"
    }
  };

  meldenTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item._Melden`,
    icon: {
      text: "M"
    }
  };

  ortTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item._MeldenOrt`,
    icon: {
      faIcon: "fas fa-map-marker-alt"
    }
  };

  geschaeftspartnerTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item.GeschaeftspartnerName1`,
    icon: {
      text: "G"
    }
  };

  geschaeftspartnerSchuleTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item.GeschaeftspartnerSchuleName1`,
    icon: {
      text: "G"
    }
  };

  bewerbungTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item.BewerbungBezeichnung`,
    icon: {
      text: "B"
    }
  };

  stelleninseratTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item.StelleninseratBezeichnung`,
    icon: {
      text: "S"
    }
  };

  terminDownloadTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    cssClass: "z--tag z--download-button",
    icon: {
      faIcon: "far fa-calendar-alt"
    },
    text: "Event in Kalender eintragen",
    onClick: () => {
      this.downloadEventICSFile();
    }
  };

  bind(form) {
    this.form = form;
  }

  private async downloadEventICSFile() {
    window.open(this.getICSFileDownloadUrl(this.form.models.data.$m_EventTeilnehmer.WebsiteKey), "_self");
  }

  private getICSFileDownloadUrl(key: string): string {
    return `${this._restService.getApiUrl("ZIT/WebsiteTermin/ICSFile")}?key=${key}`;
  }
}
