import * as fwx from "../../../../framework/forms/form-export";
import {
    InformationReadUtils
} from "./information-read-utils";

@fwx.autoinject
export class InformationReadForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_InformationReadUtils: InformationReadUtils) {
        super(element, formBaseImport);
        this.id = "information-read";
        this.title = "information-read.information-read_caption";
        this.addModel({
            "id": "$m_Information",
            "webApiAction": "ZIT/Objekte/Information",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'Informationstyp': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addFunction("$f_InformationReadUtils", $f_InformationReadUtils, "functions.$f_InformationReadUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}