import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class EventZeitraumStelleninseratEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "event-zeitraum-stelleninserat-edit";
        this.title = "event-zeitraum-stelleninserat-edit.event-zeitraum-stelleninserat-edit_caption";
        this.addModel({
            "id": "$m_EventZeitraumStelleninserat",
            "webApiAction": "ZIT/Objekte/EventZeitraumKopfElementZuStelleninserat",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addVariable({
            "id": "$v_idEvent"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "stelleninserat",
            "filters": [{
                "webApiCustomKey": "IdEvent",
                "webApiCustomValue": "variables.data.$v_idEvent"
            }, {
                "webApiCustomKey": "IdEvent",
                "webApiCustomValue": "variables.data.$v_idEvent"
            }],
            "caption": "event-zeitraum-stelleninserat-edit.r_stelleninserat_caption",
            "binding": {
                "dataContext": "$m_EventZeitraumStelleninserat",
                "bindTo": "IdStelleninserat",
                "bindToFQ": "models.data.$m_EventZeitraumStelleninserat.IdStelleninserat"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_stelleninserat",
            "options": {
                "optionsName": "r_stelleninseratOptions",
                "optionsNameFQ": "r_stelleninseratOptions"
            }
        });
        super.onConstructionFinished();
    }
}