import * as fwx from "../../../../framework/forms/form-export";
import {
    DokumentEditUtils
} from "./dokument-edit-utils";

@fwx.autoinject
export class DokumentEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_DokumentUtils: DokumentEditUtils) {
        super(element, formBaseImport);
        this.id = "dokument-edit";
        this.title = "dokument-edit.dokument-edit_caption";
        this.addModel({
            "id": "$m_Dokument",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "webApiExpand": {
                'Informationstyp': null
            },
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_InfoTyp",
            "webApiAction": "ZIT/Stammdaten/Informationstyp",
            "key": "models.data.$m_Dokument.IdInformationstyp",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addVariable({
            "id": "$v_idStelleninserat"
        });
        this.addVariable({
            "id": "$v_allowTypes"
        });
        this.addVariable({
            "id": "$v_objektTypeFullname"
        });
        this.addVariable({
            "id": "$v_dmsLinkDokument"
        });
        this.addVariable({
            "id": "$v_dateiname"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_DokumentUtils.kameraCommand",
                "bindToFQ": "functions.$f_DokumentUtils.kameraCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_DokumentUtils.videoCommand",
                "bindToFQ": "functions.$f_DokumentUtils.videoCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_DokumentUtils.gallerieCommand",
                "bindToFQ": "functions.$f_DokumentUtils.gallerieCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_DokumentUtils.dateiCommand",
                "bindToFQ": "functions.$f_DokumentUtils.dateiCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_DokumentUtils.showExtendedCommand",
                "bindToFQ": "functions.$f_DokumentUtils.showExtendedCommand"
            }
        });
        this.addEditPopup({
            "idContent": "dokumentVersionInformationEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_Dokument",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_Dokument.Id"
                }
            }, {
                "to": "$v_versionKey",
                "binding": {
                    "dataContext": "$m_Dokument",
                    "bindTo": "VersionKey",
                    "bindToFQ": "models.data.$m_Dokument.VersionKey"
                }
            }, {
                "to": "$v_isReadOnly",
                "binding": {
                    "bindTo": "false",
                    "bindToFQ": "false"
                }
            }],
            "id": "dokumentVersionInformationEditPopup",
            "options": {
                "optionsName": "dokumentVersionInformationEditPopupOptions",
                "optionsNameFQ": "dokumentVersionInformationEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addFunction("$f_DokumentUtils", $f_DokumentUtils, "functions.$f_DokumentUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "informationstyp",
            "filters": [{
                "if": "variables.data.$v_objektTypeFullname",
                "webApiCustomKey": "ObjekttypFullname",
                "webApiCustomValue": "variables.data.$v_objektTypeFullname"
            }, {
                "if": "variables.data.$v_objektTypeFullname",
                "webApiCustomKey": "ObjekttypFullname",
                "webApiCustomValue": "variables.data.$v_objektTypeFullname"
            }],
            "caption": "dokument-edit.r_dokumentinformationstyp_caption",
            "binding": {
                "dataContext": "$m_Dokument",
                "bindTo": "IdInformationstyp",
                "bindToFQ": "models.data.$m_Dokument.IdInformationstyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_dokumentInformationstyp",
            "options": {
                "optionsName": "r_dokumentInformationstypOptions",
                "optionsNameFQ": "r_dokumentInformationstypOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "dokument-edit.r_dokumentdatum_caption",
            "binding": {
                "dataContext": "$m_Dokument",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Dokument.Datum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_dokumentDatum",
            "options": {
                "optionsName": "r_dokumentDatumOptions",
                "optionsNameFQ": "r_dokumentDatumOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "stelleninserat",
            "customs": [{
                "key": "IdPerson",
                "value": "models.data.$m_Dokument.IdObjekt"
            }],
            "filters": [],
            "caption": "dokument-edit.r_dokumentstelleninserat_caption",
            "binding": {
                "dataContext": "$m_Dokument",
                "bindTo": "IdStelleninserat",
                "bindToFQ": "models.data.$m_Dokument.IdStelleninserat"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_dokumentStelleninserat",
            "options": {
                "optionsName": "r_dokumentStelleninseratOptions",
                "optionsNameFQ": "r_dokumentStelleninseratOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "caption": "dokument-edit.r_dokumentbemerkung_caption",
            "binding": {
                "dataContext": "$m_Dokument",
                "bindTo": "Bemerkung",
                "bindToFQ": "models.data.$m_Dokument.Bemerkung"
            },
            "validationRules": [],
            "id": "r_dokumentBemerkung",
            "options": {
                "optionsName": "r_dokumentBemerkungOptions",
                "optionsNameFQ": "r_dokumentBemerkungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "zustand",
            "filter": ["Bereich", "2"],
            "filters": [],
            "caption": "dokument-edit.r_dokumentzustand_caption",
            "binding": {
                "dataContext": "$m_Dokument",
                "bindTo": "IdZustand",
                "bindToFQ": "models.data.$m_Dokument.IdZustand"
            },
            "validationRules": [],
            "id": "r_dokumentZustand",
            "options": {
                "optionsName": "r_dokumentZustandOptions",
                "optionsNameFQ": "r_dokumentZustandOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "dokument-edit.r_dokumentgueltigvon_caption",
            "binding": {
                "dataContext": "$m_Dokument",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_Dokument.GueltigVon"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Dokument",
                    "bindTo": "$f_DokumentUtils.gueltigVonValidationRule",
                    "bindToFQ": "functions.$f_DokumentUtils.gueltigVonValidationRule"
                }
            }],
            "id": "r_dokumentGueltigVon",
            "options": {
                "optionsName": "r_dokumentGueltigVonOptions",
                "optionsNameFQ": "r_dokumentGueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "dokument-edit.r_dokumentgueltigbis_caption",
            "binding": {
                "dataContext": "$m_Dokument",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_Dokument.GueltigBis"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Dokument",
                    "bindTo": "$f_DokumentUtils.gueltigBisValidationRule",
                    "bindToFQ": "functions.$f_DokumentUtils.gueltigBisValidationRule"
                }
            }],
            "id": "r_dokumentGueltigBis",
            "options": {
                "optionsName": "r_dokumentGueltigBisOptions",
                "optionsNameFQ": "r_dokumentGueltigBisOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "dokument-edit.r_dokumentdateiname_caption",
            "binding": {
                "dataContext": "$m_Dokument",
                "bindTo": "Dateiname",
                "bindToFQ": "models.data.$m_Dokument.Dateiname"
            },
            "validationRules": [],
            "id": "r_dokumentDateiname",
            "options": {
                "optionsName": "r_dokumentDateinameOptions",
                "optionsNameFQ": "r_dokumentDateinameOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "zustand",
            "filter": ["Bereich", "2"],
            "filters": [],
            "caption": "dokument-edit.r_dokumentzustand_caption",
            "binding": {
                "dataContext": "$m_Dokument",
                "bindTo": "IdZustand",
                "bindToFQ": "models.data.$m_Dokument.IdZustand"
            },
            "validationRules": [],
            "id": "r_dokumentZustand",
            "options": {
                "optionsName": "r_dokumentZustandOptions",
                "optionsNameFQ": "r_dokumentZustandOptions"
            }
        });
        super.onConstructionFinished();
    }
}