import * as fwx from "../../../../framework/forms/form-export";
import {
    TerminReadUtils
} from "./termin-read-utils";

@fwx.autoinject
export class TerminReadForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_TerminReadUtils: TerminReadUtils) {
        super(element, formBaseImport);
        this.id = "termin-read";
        this.title = "termin-read.termin-read_caption";
        this.addModel({
            "id": "$m_Termin",
            "webApiAction": "ZIT/Objekte/Termin",
            "webApiExpand": {
                'Empfaenger': null,
                'Kopf': {
                    'expand': {
                        'Benutzer': null,
                        'Geschaeftspartner': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "addReadFormData",
                "webApiCustomValue": "true"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_TerminReadUtils.actionCommand",
                "bindToFQ": "functions.$f_TerminReadUtils.actionCommand"
            }
        });
        this.addFunction("$f_TerminReadUtils", $f_TerminReadUtils, "functions.$f_TerminReadUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "50px",
            "maxLength": 250,
            "placeholder": "termin-read.r_absagegrund_placeholder",
            "binding": {
                "bindTo": "$m_Termin.AbsageGrund",
                "bindToFQ": "models.data.$m_Termin.AbsageGrund"
            },
            "validationRules": [],
            "id": "r_absageGrund",
            "options": {
                "optionsName": "r_absageGrundOptions",
                "optionsNameFQ": "r_absageGrundOptions"
            }
        });
        super.onConstructionFinished();
    }
}