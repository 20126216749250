import { autoinject, Scope, OverrideContext, bindable, computedFrom } from "aurelia-framework";
import { ScopeContainer, LocalizationService } from "../../../framework/base/export";
import { ICommandData, SimpleWidgetCreatorService } from "../../../framework/forms/export";
import { DownloadService } from "./../../services/export";
import { IdxPopupComponent, IdxValidationGroupComponent, IdxCheckBoxComponent, IPersonDossierData } from "../../interfaces/export";
import { PersonDossierTyp } from "../../enumerations/export";

@autoinject
export class PersonDossier {
  private _keyList: number[];
  private _objektFullName: string;

  constructor(
    private download: DownloadService,
    private localization: LocalizationService,
    private simpleWidgetCreator: SimpleWidgetCreatorService
  ) { }

  get dossiertTyp() {
    if (!this._objektFullName) {
      return;
    }
    switch (this._objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Personen.Person":
        return PersonDossierTyp.Person;
      case "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung":
        return PersonDossierTyp.Bewerber;
      case "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstellePersonenzuteilung":
        return PersonDossierTyp.DispoPlanstellePersonenzuteilung;
      default:
        return;
    }
  }

  scope: Scope;
  scopeContainer: ScopeContainer;

  popupCommands: ICommandData[] = [
    {
      id: "downLoadPersonDossierDokumentList",
      idCategory: "$start",
      icon: "fas fa-check",
      tooltip: "person-dossier.download-starten",
      sortIndex: 1001,
      execute: () => {
        this.downLoadPersonDossierDokumentList();
      }
    }
  ];

  personDossierPopup: IdxPopupComponent;
  personDossierPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxHeight: "80%",
    maxWidth: "420px"
  };

  personenstammblatt: IdxCheckBoxComponent;
  personenstammblattOptions: DevExpress.ui.dxCheckBoxOptions = {
    value: true,
    text: this.localization.translateOnce("person-dossier.personenstammblatt-caption")
  };
  bewerbungsstammblatt: IdxCheckBoxComponent;
  bewerbungsstammblattOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: this.localization.translateOnce("person-dossier.bewerbungsstammblatt-caption")
  };
  dokumente: IdxCheckBoxComponent;
  dokumenteOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: this.localization.translateOnce("person-dossier.dokumente-caption")
  };

  showPopup(objektFullName: string, keyList: number[]) {
    this._objektFullName = objektFullName;
    this._keyList = keyList;
    this.personDossierPopup.instance.show();
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "personDossierPopupToolbar",
      caption: "person-dossier.popup-title",
      options: this.personDossierPopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  private downLoadPersonDossierDokumentList() {
    if (!this._keyList || !this._keyList.length) {
      return;
    }
    const data: IPersonDossierData = {
      IdObjekt: null,
      Typ: this.dossiertTyp,
      Personenstammblatt: this.personenstammblatt.instance.option("value"),
      Bewerbungsstammblatt: this.bewerbungsstammblatt.instance.option("value"),
      Dokumente: this.dokumente.instance.option("value")
    };

    const hasAuswahl = data.Personenstammblatt
      || data.Bewerbungsstammblatt
      || data.Dokumente;

    if (!hasAuswahl) {
      DevExpress.ui.notify(
        this.localization.translateOnce("person-dossier.auswahl-fehlt"),
        "error", 
        3000
      );
      return;
    }

    const downloadPersonDossierList = this._keyList.map((key) => {
      const dataToSend = Object.assign({}, data, { IdObjekt: Number(key) });
      return this.download.downloadPersonDossier(dataToSend);
    });
    
    Promise.all(downloadPersonDossierList)
      .then((r) => {
        this.personDossierPopup.instance.hide();
        });
  }
}
