import { ObjektInfoService } from './../../services/objekt-info-service';
import { LocationService } from './../../../framework/base/services/location-service';
import { SortOrderColumnEnum } from './../../../framework/forms/enums/sort-order-column-enum';
import { DxWidget } from './../../../framework/dx/elements/dx-widget';
import { observable } from 'aurelia-framework';
import { RestService } from './../../../framework/base/services/rest-service';
import { autoinject, Scope, OverrideContext, bindable, computedFrom } from 'aurelia-framework';
import { ScopeContainer, LocalizationService } from '../../../framework/base/export';
import { ICommandData, SimpleWidgetCreatorService } from '../../../framework/forms/export';
import { IdxPopupComponent, IdxValidationGroupComponent, IdxCheckBoxComponent, IdxSelectBoxComponent, IdxTextBoxComponent } from '../../interfaces/export';
import { IStandardStelleninseratErstellenData } from '../../interfaces/standard-stelleninserat-erstellen-data';

import * as moment from "moment";

@autoinject
export class StandardStelleninseratErstellen {
  constructor(
    private localization: LocalizationService,
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private rest: RestService,
    private location: LocationService,
    private objektInfo: ObjektInfoService
  ) { }

  scopeContainer: ScopeContainer;
  keyList: number[] = [];

  requiredValidationRule = { type: "required", message: "" };

  popupCommands: ICommandData[] = [
    {
      id: "doStandardStelleninseratErstellen",
      idCategory: "$start",
      icon: "fas fa-check",
      tooltip: "standard-stelleninserat.erstellen",
      sortIndex: 1600,
      execute: () => {
        this.doStandardStelleninseratErstellen();
      }
    }
  ];

  standardStelleninseratErstellenPopup: IdxPopupComponent;
  standardStelleninseratErstellenPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    width: "800px",
    maxHeight: "80%",
    maxWidth: "800px"
  }

  lehrberuf: IdxSelectBoxComponent;
  lehrberufOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    searchEnabled: true
  }

  geschaeftspartner: IdxSelectBoxComponent;
  geschaeftspartnerOptions: DevExpress.ui.dxSelectBoxOptions = {
    readOnly: true
  }

  kontaktperson: IdxSelectBoxComponent;
  kontaktpersonOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "AnsprechpersonName",
    searchEnabled: true
  }

  bezeichnung: IdxTextBoxComponent;
  bezeichnungOptions: DevExpress.ui.dxTextBoxOptions = {
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "standardStelleninseratErstellenPopupToolbar",
      caption: "standard-stelleninserat.popup-title",
      options: this.standardStelleninseratErstellenPopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  showPopup(keyList: number[]) {
    this.keyList = keyList;

    this.setGeschaeftspartnerValueAndDataSource();
    this.setLehrberufDataSource();
    this.setKontaktpersonDataSource();

    this.standardStelleninseratErstellenPopup.instance.show();
  }

  private setGeschaeftspartnerValueAndDataSource(): void {
    if (this.keyList && this.keyList.length === 1) {
      this.rest.get({
        url: this.rest.getWebApiUrl("ZIT/Objekte/Geschaeftspartner/" + this.keyList[0]),
        getOptions: {
          columns: ['Name1']
        }
      }).then(r => {
        if (r && r.Name1 && r.Name1.length > 0) {
          if (this.geschaeftspartner) {
            this.geschaeftspartner.instance.option("value", r.Name1);
            this.geschaeftspartner.instance.option("dataSource", [r.Name1]);
          } else {
            this.geschaeftspartnerOptions.value = r.Name1;
            this.geschaeftspartnerOptions.dataSource = [r.Name1];
          }
        } else {
          this.geschaeftspartner.instance.option("value", null);
          this.geschaeftspartner.instance.option("dataSource", null);
        }
      });
    } else {
      this.geschaeftspartner.instance.option("value", null);
      this.geschaeftspartner.instance.option("dataSource", null);
    }
  }

  private setLehrberufDataSource(): void {
    const stichtag = moment().startOf("day").toDate();

    if (this.lehrberuf) {
      this.lehrberuf.instance.option("value", null);
    } else {
      this.lehrberufOptions.value = null;
    }

    this.rest.get({
      url: this.rest.getWebApiUrl("ZIT/Stammdaten/Lehrberuf"),
      getOptions: {
        where: [['GueltigVon', '<=', stichtag], 'and', ['GueltigBis', '>=', stichtag]],
        columns: ['Id', 'Bezeichnung']
      }
    }).then(r => {
      if (r && r.length > 0) {
        this.lehrberuf.instance.option("dataSource", r);
      } else {
        this.lehrberuf.instance.option("dataSource", null);
      }
    });
  }

  private setKontaktpersonDataSource(): void {
    if (this.keyList && this.keyList.length === 1) {
      const stichtag = moment().startOf("day").toDate();

      if (this.kontaktperson) {
        this.kontaktperson.instance.option("value", null);
      } else {
        this.kontaktpersonOptions.value = null;
      }

      this.rest.get({
        url: this.rest.getWebApiUrl("ZIT/Objekte/AnsprechpersonZuGeschaeftspartner"),
        getOptions: {
          where: [['IdGeschaeftspartner', this.keyList[0]], 'and', ['GueltigVon', '<=', stichtag], 'and', ['GueltigBis', '>=', stichtag]],
          columns: ['Id', 'AnsprechpersonName']
        }
      }).then(r => {
        if (r && r.length > 0) {
          this.kontaktperson.instance.option("dataSource", r);
        } else {
          this.kontaktperson.instance.option("dataSource", null);
        }
      });
    } else {
      this.kontaktperson.instance.option("dataSource", null);
    }
  }

  private doStandardStelleninseratErstellen() {
    if (!this.keyList || this.keyList.length !== 1) {
      return;
    }

    const lehrberuf = this.lehrberuf.instance.option("value");
    const kontaktperson = this.kontaktperson.instance.option("value");

    const data: IStandardStelleninseratErstellenData = {
      IdGeschaeftspartner: this.keyList[0],
      IdLehrberuf: lehrberuf ? lehrberuf.Id : null,
      IdGeschaeftspartnerZuAnsprechperson: kontaktperson ? kontaktperson.Id : null,
      Bezeichnung: this.bezeichnung.instance.option("value")
    }

    if (!this.canStandardStelleninseratErstellen(data)) {
      return;
    }

    this.rest.post({
      url: this.rest.getApiUrl("ZIT/Objekt/StelleninseratStandardErstellen"),
      data: data,
      increaseLoadingCount: true
    }).then(r => {
      if (r.HasError) {
        DevExpress.ui.notify(r.Message, "error", 3000);
      } else {
        this.standardStelleninseratErstellenPopup.instance.hide();

        const urlToNavigate = this.objektInfo.getObjektNavigationUrl("TIP.ZIT.Business.Entities.Objekte.Stelleninserate.Stelleninserat")
        if (!urlToNavigate) {
          return;
        }

        this.location.goTo({
          url: `${urlToNavigate}/` + r.IdStelleninserat
        });
      }
    });
  }

  private canStandardStelleninseratErstellen(data: IStandardStelleninseratErstellenData): boolean {
    if (!data.IdGeschaeftspartner) {
      DevExpress.ui.notify(this.localization.translateOnce("standard-stelleninserat.geschaeftspartner-fehlt"), "error", 3000);
      return false;
    } else if (!data.IdLehrberuf) {
      DevExpress.ui.notify(this.localization.translateOnce("standard-stelleninserat.lehrberuf-fehlt"), "error", 3000);
      return false;
    } else if (!data.IdGeschaeftspartnerZuAnsprechperson) {
      DevExpress.ui.notify(this.localization.translateOnce("standard-stelleninserat.kontaktperson-fehlt"), "error", 3000);
      return false;
    }

    return true;
  }
}
