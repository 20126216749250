import { CustomEditPopup } from './../../framework/forms/elements/custom-edit-popup/custom-edit-popup';
import { autoinject } from 'aurelia-framework';

@autoinject
export class CustomEditPopupService {
  constructor() { }

  ansprechpersonRead: CustomEditPopup;
  dokumentBearbeiten: CustomEditPopup;
  dokumentEdit: CustomEditPopup;
  dokumentRead: CustomEditPopup;
  dokumentUnterfertigung: CustomEditPopup;
  geschaeftspartnerAnsprechperson: CustomEditPopup;
  geschaeftspartnerAuswaehlen: CustomEditPopup;
  geschaeftspartnerEntlohnungsschema: CustomEditPopup;
  geschaeftspartnerGruppierung: CustomEditPopup;
  markersAuswaehlen: CustomEditPopup;
  personAusbildungNeu: CustomEditPopup;
  objektZuBenutzergruppe: CustomEditPopup;
  nachrichtGruppe: CustomEditPopup;
}
