import { autoinject, Scope } from "aurelia-framework";
import { IdxPopupComponent, IdxDataGridComponent } from "../../interfaces/dx-components";
import { RestService, GlobalizationService, ScopeContainer } from "../../../framework/base/export";
import { SimpleWidgetCreatorService, ICommandData } from "../../../framework/forms/export";
import { Geschlecht } from "../../enumerations/export";

@autoinject
export class SchnupperterminAuswahl {
  private _evalOptions: ISchnupperterminAuswahlEvalOptions;

  constructor(
    private restService: RestService,
    private globalizationService: GlobalizationService,
    private simpleWidgetCreatorService: SimpleWidgetCreatorService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  auswahlList: IAuswahlItem[];

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "600px",
    height: "auto"
  };

  grid: IdxDataGridComponent;
  gridOptions: DevExpress.ui.dxDataGridOptions = {
    columns: [{
      dataField: "StartDatum",
      caption: "Start",
      width: "120px",
      cellTemplate: (element, info) => {
        const val = this.globalizationService.format(info.data.StartDatum, "DT_DD.MM.YYYY (dd)")
          .toUpperCase();

        element.innerHTML = val;
      }
    }, {
      dataField: "EndeDatum",
      caption: "Ende",
      width: "120px",
      cellTemplate: (element, info) => {
        const val = this.globalizationService.format(info.data.EndeDatum, "DT_DD.MM.YYYY (dd)")
          .toUpperCase();
          
        element.innerHTML = val;
      }
    }, {
      dataField: "Geschlecht",
      caption: "Anrede",
      dataType: "string",
      width: "80px",
      cellTemplate: (element, info) => {
        if (info.data.Geschlecht == void (0)) {
          return;
        }

        switch (info.data.Geschlecht) {
          case Geschlecht.Maennlich: {
            element.innerHTML = "Herr";
            break;
          }
          case Geschlecht.Weiblich: {
            element.innerHTML = "Frau";
            break;
          }
          default: {
            return;
          }
        }
      }
    }, {
      dataField: "Vorname",
      caption: "Vorname"
    }, {
      dataField: "Nachname",
      caption: "Nachname"
    }],
    keyExpr: "IdEventTeilnehmer",
    height: "400px",
    hoverStateEnabled: true,
    bindingOptions: {
      dataSource: "auswahlList"
    },
    onRowClick: (e) => {
      this._evalOptions.callback(e.data.IdEventTeilnehmer);
      this.popup.instance.hide();
    }
  };

  neuCommand: ICommandData = {
    id: "neuCommand",
    icon: "fas fa-plus",
    sortIndex: 1000,
    tooltip: "base.add",
    execute: () => {
      this.add();
    }
  };

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: null
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "schnupperterminAuswahlToolbar",
      caption: "schnuppertermin.auswahl-header",
      scopeContainer: this.scopeContainer,
      options: this.popupOptions,
      commands: [this.neuCommand]
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  onNeuClick() {
    this.add();
  }

  async evalIdEventTeilnehmer(options: ISchnupperterminAuswahlEvalOptions) {
    this._evalOptions = options;

    await this.loadAuswahlList();

    if (this.auswahlList.length == 0) {
      options.callback(null);
    } else if (this.auswahlList.length == 1) {
      options.callback(this.auswahlList[0].IdEventTeilnehmer);
    } else {
      this.popup.instance.show();
    }
  }

  private add() {
    this._evalOptions.callback(null);
    this.popup.instance.hide();
  }
  private async loadAuswahlList() {
    this.auswahlList = await this.restService.post({
      url: this.restService.getApiUrl("ZIT/Schnuppertermin/AuswahlSchnuppertermin"),
      data: {
        IdStelleninserat: this._evalOptions.idStelleninserat
      },
      increaseLoadingCount: true
    });

    this.auswahlList.sort((a, b) => (a.StartDatum > b.StartDatum) ? -1 : 1);
  }
}
export interface ISchnupperterminAuswahlEvalOptions {
  idStelleninserat: number;
  callback: { (idEventTeilnehmer: number): void };
}
interface IAuswahlItem {
  IdEventTeilnehmer: number;
  StartDatum: Date;
  EndeDatum: Date;
  Vorname: string;
  Nachname: string;
}
