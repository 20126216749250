import { RestService } from './../../../../framework/base/services/rest-service';
import { FormBase } from './../../../../framework/forms/classes/form-base';
import { autoinject, transient } from 'aurelia-framework';
import { StartupService } from '../../../services/startup-service';
import { BenutzerTyp } from '../../../enumerations/export';

@autoinject
@transient()
export class GeschaeftspartnerPackageEditUtils {
  constructor(
    private startupService: StartupService,
    private restService: RestService
  ) {
    const benutzertyp = startupService.startupInfo.Benutzer.Benutzertyp;

    this.canEditPackage = benutzertyp == BenutzerTyp.Administrator
      || benutzertyp == BenutzerTyp.InternerBenutzer;
    
    this.showLehrlingAnzahlProJahr = this.startupService.startupInfo.Mandant.IdMandant == 4;
  }

  form: FormBase;
  canEditPackage: boolean;
  anzahlStelleninserateUeberschreibenNichtZulaessig: boolean;
  showLehrlingAnzahlProJahr = false;

  bind(form: FormBase) {
    this.form = form;

    form.models.onLoaded.register((args) => {
      if (args.model.id == "$m_GeschaeftspartnerPackage" && args.data) {
        this.setCanEditAnzahlStelleninserate(args.data.IdPackage);
      }
      return Promise.resolve();
    });

    form.onEditorValueChanged.register((args) => {
      if (args.binding.dataContext == "$m_GeschaeftspartnerPackage"
        && args.binding.bindTo == "IdPackage") {
        this.setCanEditAnzahlStelleninserate(args.value);

        return Promise.resolve();
      }
    });
  }

  private setCanEditAnzahlStelleninserate(idPackage: number): void {
    if (!idPackage || idPackage == 0) {
      this.anzahlStelleninserateUeberschreibenNichtZulaessig = false;
      return;
    }

    this.restService.get({
      url: this.restService.getWebApiUrl("ZIT/Stammdaten/Package/" + idPackage),
      getOptions: {
        columns: ["AnzahlStelleninserateUeberschreibenNichtZulaessig"]
      }
    }).then(r => {
      if (r) {
        this.anzahlStelleninserateUeberschreibenNichtZulaessig = r.AnzahlStelleninserateUeberschreibenNichtZulaessig;
      } else {
        this.anzahlStelleninserateUeberschreibenNichtZulaessig = false;
      }
    });
  }
}
