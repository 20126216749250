import { autoinject, computedFrom } from 'aurelia-framework';

@autoinject
export class DraggingService {
  constructor() { }

  @computedFrom("dragginData.ObjektTypeFullName")
  get objektTypeFullName() {
    return this.draggingData
      ? this.draggingData.ObjektTypeFullName
      : null;
  }

  draggingData: any;
}