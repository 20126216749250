import { FormBase } from "./../../../../framework/forms/classes/form-base";
import { autoinject, transient } from "aurelia-framework";

@autoinject
@transient()
export class EventListUtils {

  form: FormBase;

  bind(form: FormBase) {
    this.form = form;
  }
}
