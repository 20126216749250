import { autoinject } from "aurelia-framework";
import { FormEventService } from '../../framework/forms/services/form-event-service';
import { RouterService } from '../../framework/forms/services/router-service';
import { FormBase } from '../../framework/forms/classes/form-base';

@autoinject
export class FormRefreshService {
  constructor(
    private formEventService: FormEventService,
    private routerService: RouterService
  ) {    
    this.registerFormSaved();
  }

  refreshPrevForm(form: FormBase) {
    const model = form.models.modelWithKeyId;
      if (!model) {
        return;
      }

      const index = this.routerService.viewStack.findIndex(c => {
        const controller: any = c.controller;

        return controller
          && controller.currentViewModel == form;
      });

      if (index < 1) {
        return;
      }

      const prevIndex = index - 1;
      const prevView = this.routerService.viewStack[prevIndex];
      const prevController: any = prevView.controller;

      if (!prevController || !prevController.currentViewModel) {
        return;
      }

      const prevForm: FormBase = prevController.currentViewModel;
      if (!(prevForm instanceof FormBase)) {
        return;
      }

      if (prevForm.models.modelWithKeyId) {
        return;
      }

      for (let prevModel of prevForm.models.getModels()) {
        prevForm.models.onLoadRequired.fire({
          model: prevModel,
          onlyCurrentPage: true
        });
      }
  }

  private registerFormSaved() {
    this.formEventService.onSaved.register(async e => {
      return this.refreshPrevForm(e.form);
    });
    this.formEventService.onDeleted.register(async e => {
      return this.refreshPrevForm(e.form);
    });
  }
}
