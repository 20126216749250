import { autoinject, computedFrom, bindable, observable, createOverrideContext, bindingMode } from "aurelia-framework";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { StartupService, DownloadService } from "../../../../services/export";
import { Share } from "../../../../elements/share/share";
import { SucheLayoutAuswahl } from "../../../../elements/suche-layout-auswahl/suche-layout-auswahl";
import { GlobalizationService, RestService, LocationService, ScopeContainer } from "../../../../../framework/base/export";
import { IZitTagOptions } from "../../../../../zit-ui/elements/zit-tag/export";
import { INachrichtEmpfObjektModel, NachrichtService } from "../../../../services/nachricht-service";
import { TerminEmpfaenger, ErweiterteSuche, ReportErstellen } from "../../../../elements/export";
import { FormBase, FormEventService, SimpleWidgetCreatorService } from "../../../../../framework/forms/export";
import { IdxPopupComponent, IdxListComponent, IdxPopoverComponent, IdxDataGridComponent } from "../../../../interfaces/export";
import { ILoginprofilEinstellung } from "../../../../services/loginprofil-einstellungen-service";
import { ZustandStatus, ZustandService, ZustandBereich } from "../../../../services/zustand-service";
import { ISchnupperterminZeitraumResult, SchnupperterminService } from "../../../../services/schnuppertermin-service";
import { StatusAendern } from "../../../../elements/status-aendern/status-aendern";
import { ObjektStatusSammlungTyp, StatusService } from "../../../../services/status-service";

@autoinject
export class Tableau {
  private _canvas: HTMLCanvasElement;
  private _defaultFilter: IDefaultFilter;
  private _terminChangedSubscription: Subscription;
  private _terminEmpfChangedSubscription: Subscription;
  private _objektStatusChangedSubscription: Subscription;
  private _formSavedSubscription: {(): void};

  constructor(
    public startupService: StartupService,
    private _globalizationService: GlobalizationService,
    private _nachrichtService: NachrichtService,
    private _restService: RestService,
    private _locationService: LocationService,
    private _eventAggregator: EventAggregator,
    private _downloadService: DownloadService,
    private _zustandService: ZustandService,
    private _statusService: StatusService,
    private _schnupperterminService: SchnupperterminService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _formEventService: FormEventService
  ) {
    this.hasSchnuppertermin = startupService.startupInfo.ShowSchnupperterminMaske;
  }

  @bindable({defaultBindingMode: bindingMode.twoWay}) @observable currentViewType: ViewType = ViewType.DispoPlanstelle;
  @bindable hasTerminEmpfView: boolean;
  @bindable hasEventTeilnehmerView: boolean;
  @bindable filterIdPerson: number;

  scopeContainer: ScopeContainer;

  hasSchnuppertermin: boolean = false;
  hasSelection: boolean = false;
  selectionCount: number = 0;
  previousResult: IResult;

  share: Share;
  erweiterteSuche: ErweiterteSuche;
  layoutAuswahl: SucheLayoutAuswahl;
  terminEmpfaenger: TerminEmpfaenger;
  reportErstellen: ReportErstellen;
  statusAendern: StatusAendern;
  
  filter: IFilter;
  filterTermin: IFilterTermin;
  filterEventZeitraum: IFilterEventZeitraum;

  grid: IdxDataGridComponent;
  zeitraumPopup: IdxPopupComponent;
  geschaeftspartnerFilterList: IdxListComponent;
  geschaeftspartnerSchuleFilterList: IdxListComponent;
  terminEmpfZustandFilterList: IdxListComponent;
  objektStatusFilterList: IdxListComponent;
  geschaeftspartnerFilterPopover: IdxPopoverComponent;
  geschaeftspartnerSchuleFilterPopover: IdxPopoverComponent;
  objektStatusFilterPopover: IdxPopoverComponent;
  terminEmpfZustandFilterPopover: IdxPopoverComponent;

  zeitraumEdit: IZeitraumEdit;

  currentObjektStatusFilterList: IObjektStatusFilter[];
  currentGeschaeftspartnerFilterList: IGeschaeftspartnerFilter[];
  currentEventZeitraumList: ISchnupperterminZeitraumResult[];

  parentForm: FormBase;

  @computedFrom("filter.zeitraumVon", "filter.zeitraumBis")
  get zeitraumFormatiert() {
    if (!this.filter) {
      return null;
    }
    if (!this.filter.zeitraumVon) {
      return null;
    }
    if (!this.filter.zeitraumBis) {
      return null;
    }

    const von = this._globalizationService.format(this.filter.zeitraumVon, "d");
    const vonTag = this._globalizationService.format(this.filter.zeitraumVon, "DT_dd");
    const bis = this._globalizationService.format(this.filter.zeitraumBis, "d");
    const bisTag = this._globalizationService.format(this.filter.zeitraumBis, "DT_dd");

    return `${von} (${vonTag}) - ${bis} (${bisTag})`.toUpperCase();
  }
  @computedFrom("currentViewType")
  get currentObjektFullName() {
    switch (this.currentViewType) {
      case ViewType.DispoPersonenzuteilung:
        return "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstellePersonenzuteilung";
      case ViewType.DispoPlanstelle:
        return "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstelle";
      case ViewType.Termin:
      case ViewType.TerminEmpfaenger:
        return "TIP.ZIT.Business.Entities.Objekte.Termine.Termin";
      case ViewType.Schnuppertermin:
      case ViewType.EventSchnuppertermin:
        return "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung";
      case ViewType.EventLinkAnmeldung:
        return "TIP.ZIT.Business.Entities.Objekte.Events.EventLinkAnmeldung";
      case ViewType.Aufgabe:
        return "TIP.ZIT.Business.Entities.Objekte.Aufgaben.Aufgabe";
      case ViewType.Event:
        return "TIP.ZIT.Business.Entities.Objekte.Events.EventZeitraum";
      case ViewType.EventTeilnehmer:
        return "TIP.ZIT.Business.Entities.Objekte.Events.EventTeilnehmer";
      default:
        return "";
    }
  }
  @computedFrom("currentViewType")
  get isObjektStatusFilterVisible() {
    switch (this.currentViewType) {
      case ViewType.Schnuppertermin:
      case ViewType.EventSchnuppertermin: {
        return false;
      }
      default: {
        return true;
      }
    }
  }
  @computedFrom("currentObjektStatusFilterList", "currentViewType")
  get isObjektStatusFilterListVisible() {
    if (!this.currentObjektStatusFilterList) {
      return false;
    }
    if (this.currentObjektStatusFilterList.length == 0) {
      return false;
    }

    if (this.currentViewType == ViewType.EventTeilnehmer) {
      return false;
    }

    return true;
  }
  @computedFrom("currentViewType")
  get isKalenderVisible() {
    return this.currentViewType != ViewType.EventLinkAnmeldung;
  }
  @computedFrom("currentViewType")
  get isAktiveAufgabeFilterVisible() {
    return this.currentViewType == ViewType.Aufgabe;
  }
  @computedFrom("currentViewType")
  get isGeschaeftspartnerFilterVisible() {
    switch (this.currentViewType) {
      case ViewType.TerminEmpfaenger:
      case ViewType.Schnuppertermin:
      case ViewType.EventSchnuppertermin:
      case ViewType.Aufgabe: {
        return false;
      }
      default: {
        return ["ADMIN", "VERTRIEB", "KUNDE-SCHULE"].indexOf(this.startupService.startupInfo.Benutzer.CodeBenutzerrolle) >= 0;
      }
    }
  }
  @computedFrom("currentViewType")
  get isTerminEmpfZustandFilterVisible() {
    return this.currentViewType == ViewType.TerminEmpfaenger;
  }
  @computedFrom("startupService", "currentViewType")
  get isGeschaeftspartnerSchuleFilterVisible() {
    if (!this.startupService) {
      return false;
    }

    switch (this.currentViewType) {
      case ViewType.Termin:
      case ViewType.TerminEmpfaenger:
      case ViewType.Schnuppertermin: {
        break; 
      }
      default: {
        return false;
      }
    }

    return this.startupService.startupInfo.Benutzer.CodeBenutzerrolle == "ADMIN";
  }
  @computedFrom("currentViewType")
  get isInfoVisible() {
    switch (this.currentViewType) {
      case ViewType.Schnuppertermin:
      case ViewType.EventLinkAnmeldung: {
        return false;
      }
      default: {
        return true;
      }
    }
  }
  @computedFrom("currentViewType", "currentEventZeitraumList")
  get isEventZeitraumListVisible() {
    switch (this.currentViewType) {
      case ViewType.EventTeilnehmer:
      case ViewType.EventSchnuppertermin: {
        break;
      }
      default: {
        return false;
      }
    }

    return this.currentEventZeitraumList
      && this.currentEventZeitraumList.length > 0;
  }
  @computedFrom("currentViewType")
  get isZeitraumVisible() {
    switch (this.currentViewType) {
      case ViewType.Aufgabe: {
        return false;
      }
      case ViewType.EventSchnuppertermin: {
        return !this.isEventZeitraumListVisible;
      }
      default: {
        return true;
      }
    }
  }
  @computedFrom("previousResult", "selectionCount")
  get gridFooterInfo() {
    const r = [];

    const anzahl = this.previousResult && this.previousResult.Data
      ? this.previousResult.Data.length
      : 0;

    if (anzahl === 1) {
      r.push("1 Element");
    } else {
      r.push(`${this._globalizationService.format(anzahl, "n0")} Elemente`);
    }

    if (this.hasSelection) {
      if (this.selectionCount == 1) {
        r.push("1 Element markiert");
      } else {
        r.push(`${this._globalizationService.format(this.selectionCount, "n0")} Elemente markiert`);
      }
    }

    return r.join(", ");
  }

  gridOptions: DevExpress.ui.dxDataGridOptions = {
    allowColumnReordering: true,
    allowColumnResizing: true,
    columnAutoWidth: false,
    columnResizingMode: "widget",
    height: "100%",
    pager: {
      showInfo: false,
      visible: false
    },
    columnFixing: {
      enabled: true
    },
    groupPanel: {
      visible: true
    },
    selection: {
      allowSelectAll: true,
      mode: "multiple",
      showCheckBoxesMode: "always"
    },
    scrolling: {
      mode: "virtual",
      columnRenderingMode: "virtual",
      rowRenderingMode: "virtual",
      showScrollbar: "always"
    },
    sorting: {
      mode: "multiple"
    },
    hoverStateEnabled: false,
    // tslint:disable-next-line: cyclomatic-complexity
    onCellClick: async(e) => {
      if (!e.column) {
        return;
      }

      if (e.column.dataField == "_DetailsKommunikation") {
        const info = await this._nachrichtService.getBenutzerFromObjekt(
          "TIP.ZIT.Business.Entities.Objekte.Personen.Person",
          e.data["IdPerson"]
        );

        if (info) {
          this._nachrichtService.goToNachrichtList(info, this.parentForm);
        }
        return;
      } else if (e.column.dataField == "_DetailsObjektStatus" && !e.data["_DetailsObjektStatus_Url"]) {
        switch (this.currentViewType) {
          case ViewType.Event:
          case ViewType.EventTeilnehmer: {
            break;
          }
          default: {
            return;
          }
        }
        
        const id = e.data["Id"];
        if (!id) {
          return;
        }

        this.statusAendern.keyList = [id];
        this.statusAendern.showPopup(() => {
          this.reloadById(id);
        });
        return;
      } else if (e.column.dataField == "_DetailsEmpfStatus") {
        const idTermin = e.data["Id"];
        const idPerson = e.data["IdPerson"];
        this.terminEmpfaenger.showStatusPopup(idTermin, idPerson);
        return;
      } else if (this.currentViewType == ViewType.TerminEmpfaenger && e.column.dataField == "_DetailsZustandTerminBestaetigung") {
        const idTermin = e.data["Id"];

        if (e.data["_DetailsZustandTerminBestaetigungHasData"]) {
          const urlDokBest = this._restService.getApiUrl(`ZIT/Termin/DokBestaetigung?auth-token=${encodeURIComponent(this._restService.getAuthorizationToken())}&idTermin=${idTermin}`);
          window.open(urlDokBest, "_blank");
        } else {
          this.share.dokumentLautVorlageErstellen.showPopup([idTermin], "TIP.ZIT.Business.Entities.Objekte.Termine.Termin");
        }

        return;
      } else if (this.currentViewType == ViewType.EventTeilnehmer && e.column.dataField == "_DetailsDokument") {
        const idEventTeilnehmer = e.data["Id"];

        if (e.data["_DetailsDokumentHasData"]) {
          const urlDokBest = this._restService.getApiUrl(`ZIT/Event/DokBestaetigung?auth-token=${encodeURIComponent(this._restService.getAuthorizationToken())}&idEventTeilnehmer=${idEventTeilnehmer}`);
          window.open(urlDokBest, "_blank");
        } else {
          this.share.dokumentLautVorlageErstellen.showPopup([idEventTeilnehmer], "TIP.ZIT.Business.Entities.Objekte.Events.EventTeilnehmer");
        }

        return;
      } else if (this.hasTerminEmpfView && this.currentViewType == ViewType.Termin && e.column.dataField == "_Offen") {
        this.filterTermin = {
          idTermin: e.data["Id"],
          zustandStatus: ZustandStatus.Offen
        };

        this.currentViewType = ViewType.TerminEmpfaenger;
      } else if (this.hasTerminEmpfView && this.currentViewType == ViewType.Termin && e.column.dataField == "_Zugesagt") {
        this.filterTermin = {
          idTermin: e.data["Id"],
          zustandStatus: ZustandStatus.ErledigtPositiv
        };
        
        this.currentViewType = ViewType.TerminEmpfaenger;
      } else if (this.hasTerminEmpfView && this.currentViewType == ViewType.Termin && e.column.dataField == "_Abgesagt") {
        this.filterTermin = {
          idTermin: e.data["Id"],
          zustandStatus: ZustandStatus.ErledigtNegativ
        };
        
        this.currentViewType = ViewType.TerminEmpfaenger;
      } else if (this.hasTerminEmpfView && this.currentViewType == ViewType.Termin && e.column.dataField == "_DetailsKontingent") {
        this.filterTermin = {
          idTermin: e.data["Id"]
        };
        
        this.currentViewType = ViewType.TerminEmpfaenger;
      } else if (e.column.dataField == "_DetailsIcs") {
        this._downloadService.download(
          this._restService.getApiUrl("ZIT/Tableau/Ics"), {
            Id: e.data["Id"]
          },
          "termin.ics");
      } else if (this.hasEventTeilnehmerView && this.currentViewType == ViewType.Event && e.column.dataField == "_Offen") {
        this.filterEventZeitraum = {
          idEventZeitraum: e.data["Id"],
          objektStatusSammlungTyp: ObjektStatusSammlungTyp.EventTeilnehmerOffen,
          zeitraumVon: this.filter.zeitraumVon,
          zeitraumBis: this.filter.zeitraumBis
        };

        this.currentViewType = ViewType.EventTeilnehmer;
      } else if (this.hasEventTeilnehmerView && this.currentViewType == ViewType.Event && e.column.dataField == "_Zugesagt") {
        this.filterEventZeitraum = {
          idEventZeitraum: e.data["Id"],
          objektStatusSammlungTyp: ObjektStatusSammlungTyp.EventTeilnehmerZugesagt,
          zeitraumVon: this.filter.zeitraumVon,
          zeitraumBis: this.filter.zeitraumBis
        };
        
        this.currentViewType = ViewType.EventTeilnehmer;
      } else if (this.hasEventTeilnehmerView && this.currentViewType == ViewType.Event && e.column.dataField == "_Abgesagt") {
        this.filterEventZeitraum = {
          idEventZeitraum: e.data["Id"],
          objektStatusSammlungTyp: ObjektStatusSammlungTyp.EventTeilnehmerAbgesagt,
          zeitraumVon: this.filter.zeitraumVon,
          zeitraumBis: this.filter.zeitraumBis
        };
        
        this.currentViewType = ViewType.EventTeilnehmer;
      } else if (this.currentViewType == ViewType.Schnuppertermin && ["Vorname", "Nachname"].includes(e.column.dataField)) {
        this._locationService.goTo({
          url: `Objekte/Person/${e.data["IdPerson"]}`,
          currentViewModel: this.parentForm
        });
      }

      if (!e.column.dataField || !e.value) {
        return;
      }

      const dataField = e.column.dataField + "_Url";

      const url = e.data[dataField];
      if (url && url.length > 0) {
        this._locationService.goTo({
          url: url + "?mode=1",
          currentViewModel: this.parentForm
        });
      }
    },
    onSelectionChanged: (e) => {
      this.hasSelection = e.selectedRowKeys.length > 0;
      this.selectionCount = e.selectedRowKeys.length;
    }
  };
  searchOptions: DevExpress.ui.dxTextBoxOptions = {
    placeholder: "Suchen ...",
    elementAttr: {
      class: "z--suche"
    },
    mode: "search",
    onValueChangedByUser: () => {
      this.doSearch();
    },
    bindingOptions: {
      value: "filter.searchText"
    }
  };
  objektStatusFilterPopoverOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    maxWidth: "300px",
    toolbarItems: this.createPopoverToolbarItems(() => {
      this.filter.idObjektStatusList = this.objektStatusFilterList.instance.option("selectedItemKeys");

      this.doSearch();
      this.updateObjektStatusFilterTagList();
      this.objektStatusFilterPopover.instance.hide();
    }, () => this.objektStatusFilterPopover.instance.hide())
  };
  objektStatusFilterListOptions: DevExpress.ui.dxListOptions = {
    keyExpr: "Id",
    displayExpr: "BezeichnungBenutzerrolle",
    selectionMode: "all",
    showSelectionControls: true
  };
  objektStatusFilterTagOptions: IZitTagOptions = {
    textExpression: "item.BezeichnungBenutzerrolle",
    onDeleteClick: (ev, filter) => {
      const indexFilter = this.filter.idObjektStatusList.findIndex((c) => c == filter.Id);
      if (indexFilter >= 0) {
        this.filter.idObjektStatusList.splice(indexFilter, 1);
      }

      const indexCurrent = this.currentObjektStatusFilterList.indexOf(filter);
      if (indexCurrent >= 0) {
        this.currentObjektStatusFilterList.splice(indexCurrent, 1);
      }

      this.doSearch();
    }
  };
  geschaeftspartnerFilterPopoverOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    maxWidth: "300px",
    toolbarItems: this.createPopoverToolbarItems(() => {
      this.filter.idGeschaeftspartnerList = this.geschaeftspartnerFilterList.instance.option("selectedItemKeys");

      this.doSearch();
      this.updateGeschaeftspartnerFilterTagList();
      this.geschaeftspartnerFilterPopover.instance.hide();
    }, () => this.geschaeftspartnerFilterPopover.instance.hide())
  };
  geschaeftspartnerFilterListOptions: DevExpress.ui.dxListOptions = {
    keyExpr: "Id",
    displayExpr: "Name1",
    selectionMode: "multiple",
    showSelectionControls: true
  };
  geschaeftspartnerFilterTagOptions: IZitTagOptions = {
    textExpression: "item.Name1",
    onDeleteClick: (ev, filter) => {
      const indexFilter = this.filter.idGeschaeftspartnerList.findIndex((c) => c == filter.Id);
      if (indexFilter >= 0) {
        this.filter.idGeschaeftspartnerList.splice(indexFilter, 1);
      }

      const indexCurrent = this.currentGeschaeftspartnerFilterList.indexOf(filter);
      if (indexCurrent >= 0) {
        this.currentGeschaeftspartnerFilterList.splice(indexCurrent, 1);
      }

      this.doSearch();
    }
  };
  geschaeftspartnerSchuleFilterPopoverOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    maxWidth: "300px",
    toolbarItems: this.createPopoverToolbarItems(() => {
      this.filter.idGeschaeftspartnerSchuleList = this.geschaeftspartnerSchuleFilterList.instance.option("selectedItemKeys");

      this.doSearch();
      this.geschaeftspartnerSchuleFilterPopover.instance.hide();
    }, () => this.geschaeftspartnerSchuleFilterPopover.instance.hide())
  };
  geschaeftspartnerSchuleFilterListOptions: DevExpress.ui.dxListOptions = {
    keyExpr: "Id",
    displayExpr: "Name1",
    selectionMode: "multiple",
    showSelectionControls: true
  };
  terminEmpfZustandFilterPopoverOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    maxWidth: "300px",
    toolbarItems: this.createPopoverToolbarItems(() => {
      this.filter.idTerminEmpfZustandList = this.terminEmpfZustandFilterList.instance.option("selectedItemKeys");

      this.doSearch();
      this.terminEmpfZustandFilterPopover.instance.hide();
    }, () => this.terminEmpfZustandFilterPopover.instance.hide())
  };
  terminEmpfZustandFilterListOptions: DevExpress.ui.dxListOptions = {
    keyExpr: "Id",
    displayExpr: "Bezeichnung",
    selectionMode: "all",
    selectAllMode: "allPages",
    showSelectionControls: true
  };
  eventZeitraumTagTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "far fa-calendar-alt"
    },
    textExpression: "item.Bezeichnung",
    backgroundColorExpression: `item.IsAktiv ? '${this.startupService.startupInfo.Skin.Farbe}' : ''`,
    colorExpression: "item.IsAktiv ? 'white' : 'black'",
    isDeleteClickEnabledExpression: "item.IsAktiv",
    onClick: (ev, tag: ISchnupperterminZeitraumResult) => {
      ev.preventDefault();
      ev.stopPropagation();

      const indexOf = this.filter.idEventZeitraumList.indexOf(tag.Id);
      if (indexOf >= 0) {
        return;
      }

      this.filter.idEventZeitraumList.push(tag.Id);
      this.updateEventZeitraumList();
      
      this.doSearch();
    },
    onDeleteClick: (ev, tag: ISchnupperterminZeitraumResult) => {
      ev.preventDefault();
      ev.stopPropagation();

      const indexOf = this.filter.idEventZeitraumList.indexOf(tag.Id);
      if (indexOf < 0) {
        return;
      }

      this.filter.idEventZeitraumList.splice(indexOf, 1);
      this.updateEventZeitraumList();
      this.doSearch();
    }
  };
  zeitraumOptions: DevExpress.ui.dxTextBoxOptions = {
    readOnly: true,
    width: "250px",
    bindingOptions: {
      value: "zeitraumFormatiert"
    }
  };
  zeitraumPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    height: "auto",
    maxWidth: "500px"
  };
  zeitraumGueltigVonOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this._globalizationService.getFormatterParser("d"),
    bindingOptions: {
      value: "zeitraumEdit.zeitraumVon"
    }
  };
  zeitraumGueltigBisOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this._globalizationService.getFormatterParser("d"),
    bindingOptions: {
      value: "zeitraumEdit.zeitraumBis"
    }
  };
  zeitraumKalenderOptions: DevExpress.ui.dxSelectBoxOptions = {
    dataSource: [
      { id: KalenderViewType.Tag, text: "Tag" },
      { id: KalenderViewType.Woche, text: "Woche" },
      { id: KalenderViewType.Monat, text: "Monat" },
    ],
    displayExpr: "text",
    valueExpr: "id",
    bindingOptions: {
      value: "zeitraumEdit.kalenderViewType"
    }
  };
  zeitraumUngebuchteAusblendenOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Ungebuchte Zeiträume ausblenden",
    bindingOptions: {
      value: "zeitraumEdit.shouldUngebuchteAusblenden"
    }
  };

  bind(bindingContext) {
    this.parentForm = bindingContext;

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: createOverrideContext(this.parentForm)
    });

    this.prepareZeitraumPopup();

    this._terminChangedSubscription = this._eventAggregator.subscribe("termin:changed", (e) => {
      if (this.currentViewType == ViewType.Termin) {
        this.reloadTermin(e.idTermin);
      } else if (this.currentViewType == ViewType.TerminEmpfaenger) {
        if (e.isZiel) {
          this.doSearch();
        }
      }
    });
    this._terminEmpfChangedSubscription = this._eventAggregator.subscribe("termin-empf:changed", (e) => {
      if (this.currentViewType == ViewType.TerminEmpfaenger) {
        this.reloadTerminEmpf(e.idTermin, e.idPerson);
      }
    });
    this._objektStatusChangedSubscription = this._eventAggregator.subscribe("objekt-status:changed", async (e) => {
      if (!e.idObjektList) {
        return;
      }

      for (const id of e.idObjektList) {
        await this.reloadById(id);
      }
    });

    this._formSavedSubscription = this._formEventService.onSaved.register(async(args) => {
      const model = args.form.models.modelWithKeyId;
      if (!model) {
        return;
      }

      const id = args.form.models.data[model.id].Id;
      if (!id) {
        return;
      }

      switch (model.webApiAction) {
        case "ZIT/Objekte/EventZeitraum": {
          this.reloadEventZeitraum(id);
          break;
        }
        case "ZIT/Objekte/EventTeilnehmer": {
          this.reloadEventTeilnehmer(id);
          break;
        }
        default: {
          break;
        }
      }
    });
  }
  unbind() {
    this._terminEmpfChangedSubscription.dispose();
    this._terminEmpfChangedSubscription = null;

    this._terminChangedSubscription.dispose();
    this._terminChangedSubscription = null;

    this._objektStatusChangedSubscription.dispose();
    this._objektStatusChangedSubscription = null;

    this._formSavedSubscription();
    this._formSavedSubscription = null;

    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async attached() {
    await this.initializeQuery();
    this.doSearch(true);
  }

  resetCustomFilter() {
    this.filterTermin = null;
    this.filterEventZeitraum = null;
  }

  showReportAuswahlPopup() {
    this.reportErstellen.reportErstellenPopup.instance.show();
  }
  showNachrichtSendenPopup() {
    const rows = this.grid.instance.getSelectedRowsData();

    const objektModelList = rows.map((r) => {
      return <INachrichtEmpfObjektModel>{
        IdObjekt: r["IdPerson"],
        IdObjektRef: r["IdTermin"]
      };
    });

    this._nachrichtService.doNachrichtErstellenFromObjekt(
      objektModelList,
      "TIP.ZIT.Business.Entities.Objekte.Personen.Person"
    );
  }
  showTerminEmpfStatusAenderPopup() {
    const rows = this.grid.instance.getSelectedRowsData();

    const dataList = rows.map((r) => {
      return {
        IdPerson: r["IdPerson"],
        IdTermin: r["Id"]
      };
    });

    if (dataList.length === 1) {
      this.terminEmpfaenger.showStatusPopup(dataList[0].IdTermin, dataList[0].IdPerson);
    } else {
      this.terminEmpfaenger.showStatusPopupMulti(dataList);
    }
  }

  currentViewTypeChanged() {
    this.filterIdPerson = null;
    this.doSearch(true);
  }

  onFilterClick() {
    this.erweiterteSuche.showPopup();
  }
  onShowErweiterteSucheClick(ev: Event) {
    this.erweiterteSuche.showVorlageContextMenu(ev, true);
  }
  async onObjektStatusFilterClick(ev: Event) {
    const dataSource = await this.getObjektStatusList();

    const options = {
      dataSource,
      selectedItemKeys: this.filter.idObjektStatusList
    };

    if (this.objektStatusFilterList) {
      this.objektStatusFilterList.setOption(options);
    } else {
      Object.assign(this.objektStatusFilterListOptions, options);
    }

    this.objektStatusFilterPopover.instance.show(<any>ev.target);
  }
  onAktiveAufgabeFilterClick() {
    this.filter.shouldLoadOnlyAktiveAufgabe = !this.filter.shouldLoadOnlyAktiveAufgabe;
      this.doSearch();
  }
  async onGeschaeftspartnerFilterClick(ev: Event) {
    const dataSource = await this.getGeschaeftspartnerList();

    const options = {
      dataSource,
      selectedItemKeys: this.filter.idGeschaeftspartnerList
    };

    if (this.geschaeftspartnerFilterList) {
      this.geschaeftspartnerFilterList.setOption(options);
    } else {
      Object.assign(this.geschaeftspartnerFilterListOptions, options);
    }

    this.geschaeftspartnerFilterPopover.instance.show(<any>ev.target);
  }
  async onGeschaeftspartnerSchuleFilterClick(ev: Event) {
    const dataSource = await this.getGeschaeftspartnerSchuleList();

    const options = {
      dataSource,
      selectedItemKeys: this.filter.idGeschaeftspartnerSchuleList
    };

    if (this.geschaeftspartnerSchuleFilterList) {
      this.geschaeftspartnerSchuleFilterList.setOption(options);
    } else {
      Object.assign(this.geschaeftspartnerSchuleFilterListOptions, options);
    }

    this.geschaeftspartnerSchuleFilterPopover.instance.show(<any>ev.target);
  }
  async onTerminEmpfZustandFilterClick(ev: Event) {
    if (!this.terminEmpfZustandFilterList) {
      const dataSource = await this.getTerminEmpfZustandList();
      this.terminEmpfZustandFilterListOptions.dataSource = dataSource;
    }

    const options = {
      selectedItemKeys: this.filter.idTerminEmpfZustandList
    };

    if (this.terminEmpfZustandFilterList) {
      this.terminEmpfZustandFilterList.setOption(options);
    } else {
      Object.assign(this.terminEmpfZustandFilterListOptions, options);
    }

    this.terminEmpfZustandFilterPopover.instance.show(<any>ev.target);
  }
  onInfoClick() {
    this.filter.shouldLoadInfo = !this.filter.shouldLoadInfo;
    this.doSearch();
  }
  onKalenderClick() {
    this.filter.shouldLoadKalender = !this.filter.shouldLoadKalender;
    this.doSearch();
  }
  onZeitraumClick() {
    this.zeitraumEdit = {
      zeitraumVon: this.filter.zeitraumVon,
      zeitraumBis: this.filter.zeitraumBis,
      kalenderViewType: this.filter.kalenderViewType,
      shouldUngebuchteAusblenden: this.filter.shouldUngebuchteAusblenden || false
    };

    this.zeitraumPopup.instance.show();
  }
  onExecuteSucheErweitert(ev: CustomEvent) {
    this.filter.searchExtended = ev.detail.wertQueryList
      ? JSON.stringify(ev.detail.wertQueryList)
      : null;

    this.doSearch();
  }
  onLayoutChanged() {
    this.doSearch();
  }
  onLayoutLoaded(ev: CustomEvent) {
    const data: ILoginprofilEinstellung = ev.detail.data;
    if (!data.Wert.Custom) {
      return;
    }

    this.filter = data.Wert.Custom;
    this.updateGeschaeftspartnerFilterTagList();
    this.updateObjektStatusFilterTagList();
    this.updateEventZeitraumList();
  }
  onLayoutSaving(ev: CustomEvent) {
    const data: ILoginprofilEinstellung = ev.detail.data;
    data.Wert.Custom = this.filter;
  }
  onExportExcel() {
    const query = this.getQuery();
    query.IdList = this.grid.instance
      .getSelectedRowsData()
      .map((c) => c["Id"]);

    this._downloadService.downloadExcelFromTableau(query);
    return false;
  }

  private createPopoverToolbarItems(okCallback: {(): void}, cancelCallback: {(): void}) {
    return <DevExpress.ui.dxPopupToolbarItem[]>[{
      toolbar: "bottom",
      location: "center",
      widget: "dxButton",
      options: <DevExpress.ui.dxButtonOptions>{
        text: "Übernehmen",
        onClick: () => {
          okCallback();
        }
      }
    }, {
      toolbar: "bottom",
      location: "center",
      widget: "dxButton",
      options: <DevExpress.ui.dxButtonOptions>{
        text: "Abbrechen",
        onClick: () => {
          cancelCallback();
        }
      }
    }];
  }
  private prepareZeitraumPopup() {
    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "zeitraumPopupToolbar",
      caption: "tableau.zeitraum-uebernehmen",
      options: this.zeitraumPopupOptions,
      commands: [{
        id: "zeitraumUebernehmenCommand",
        icon: "fas fa-check",
        tooltip: "tableau.zeitraum-uebernehmen",
        sortIndex: 1001,
        execute: (ev: any) => {
          if (!this.zeitraumEdit.zeitraumVon) {
            DevExpress.ui.notify(
              "Datum von ist ein Pflichtfeld",
              "error",
              3000
            );
            return;
          } 
          if (!this.zeitraumEdit.zeitraumBis) {
            DevExpress.ui.notify(
              "Datum bis ist ein Pflichtfeld",
              "error",
              3000
            );
            return;
          } 
          if (this.zeitraumEdit.kalenderViewType == void(0)) {
            DevExpress.ui.notify(
              "Kalender-Zeitraum ist ein Pflichtfeld",
              "error",
              3000
            );
            return;
          }

          this.zeitraumPopup.instance.hide();

          this.filter.zeitraumVon = this.zeitraumEdit.zeitraumVon;
          this.filter.zeitraumBis = this.zeitraumEdit.zeitraumBis;
          this.filter.kalenderViewType = this.zeitraumEdit.kalenderViewType;
          this.filter.shouldUngebuchteAusblenden = this.zeitraumEdit.shouldUngebuchteAusblenden;

          this.doSearch();
        }
      }],
      scopeContainer: this.scopeContainer
    });
  }

  private async initializeQuery() {
    const result: IQuery = await this._restService.get({
      url: this._restService.getApiUrl("ZIT/Tableau/QueryModel"),
      increaseLoadingCount: true
    });

    this._defaultFilter = {
      kalenderViewType: result.KalenderViewType,
      shouldLoadKalender: result.LoadKalender,
      shouldLoadInfo: result.LoadDetail,
      shouldUngebuchteAusblenden: result.UngebuchteZeitraumAusblenden,
      zeitraumVon: result.GueltigVon,
      zeitraumBis: result.GueltigBis,
      idGeschaeftspartnerSchuleList: [],
      idGeschaeftspartnerList: result.IdGeschaeftspartnerList,
      idObjektStatusList: result.IdObjektStatusList,
      idTerminEmpfZustandList: result.IdTerminEmpfZustandStatusList,
      idEventZeitraumList: result.IdEventZeitraumList,
      dataSourceIdObjektStatusFilterDic: result.DataSourceIdObjektStatusFilterDic
    };

    this.resetFilter();
    this.updateObjektStatusFilterTagList();
    this.updateGeschaeftspartnerFilterTagList();

    if (this.startupService.startupInfo.Benutzer.CodeBenutzerrolle == "KUNDE-SCHULE") {
      this.currentEventZeitraumList = await this._schnupperterminService.getEventZeitraumSchnupperterminList(
        this.filter.zeitraumVon,
        this.filter.zeitraumBis
      );
      
      this.updateEventZeitraumList();
    }
  }
  private async doSearch(isNewDataType: boolean = false) {
    //Prüfen, ob es ein Standardlayout für den Typ gibt ...
    if (isNewDataType) {
      const layout = await this.layoutAuswahl.getStandardLayout(this.currentObjektFullName);
      if (layout) {
        this.layoutAuswahl.assignLayoutToGrid(layout, true);
        return;
      }

      this.resetFilter();

      this.updateObjektStatusFilterTagList();
      this.updateGeschaeftspartnerFilterTagList();
    }

    if (this.filterTermin && this.filterTermin.zustandStatus != void(0)) {
      await this.updateTerminEmpfZustand(this.filterTermin.zustandStatus);
      this.filterTermin.zustandStatus = null;
    }
    if (this.filterEventZeitraum) {
      if (this.filterEventZeitraum.objektStatusSammlungTyp != void(0)) {
        await this.updateObjektStatus(this.filterEventZeitraum.objektStatusSammlungTyp);
        this.filterEventZeitraum.objektStatusSammlungTyp = null;

        this.updateObjektStatusFilterTagList();
      }
      
      if (this.filterEventZeitraum.zeitraumVon) {
        this.filter.zeitraumVon = this.filterEventZeitraum.zeitraumVon;
      }
      
      if (this.filterEventZeitraum.zeitraumBis) {
        this.filter.zeitraumBis = this.filterEventZeitraum.zeitraumBis;
      }
    }

    const query = this.getQuery(isNewDataType);

    if (query.GueltigVon && query.GueltigBis) {
      const result: IResult = await this._restService.post({
        url: this._restService.getApiUrl("ZIT/Tableau/Data"),
        data: query,
        increaseLoadingCount: true
      });

      if (isNewDataType) {
        this.filter.zeitraumVon = result.GueltigVon;
        this.filter.zeitraumBis = result.GueltigBis;
      }

      this.setGridData(result);
    } else {
      this.setGridData({
        Data: []
      });
    }
  }

  private async getGeschaeftspartnerList() {
    const query = this.getQuery();
    if (!query) {
      return;
    }

    const result: IGeschaeftspartnerFilter[] = await this._restService.post({
      url: this._restService.getApiUrl(`ZIT/Tableau/Geschaeftspartner`),
      data: query
    });

    return result;
  }
  private async getGeschaeftspartnerSchuleList() {
    const result = await this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Simple/Geschaeftspartner"),
      getOptions: {
        where: ["IsGeschaeftspartnerSchule", true],
        orderBy: [{columnName: "Name1", sortOrder: 0}]
      }
    });

    return result;
  }
  private async getObjektStatusList() {
    const result: IObjektStatusFilter[] = await this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Stammdaten/ObjektStatus"),
      getOptions: {
        where: ["Objekttyp.Fullname", this.currentObjektFullName]
      }
    });

    return result;
  }
  private async getTerminEmpfZustandList() {
    const result: ITerminEmpfFilter[] = await this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Stammdaten/Zustand"),
      getOptions: {
        where: ["Bereich", 7],
        columns: ["Id", "Bezeichnung"]
      }
    });

    return result;
  }

  private resetFilter() {    
    this.filter = {
      kalenderViewType: this._defaultFilter.kalenderViewType,
      shouldLoadKalender: this._defaultFilter.shouldLoadKalender,
      shouldLoadInfo: this._defaultFilter.shouldLoadInfo,
      shouldUngebuchteAusblenden: this._defaultFilter.shouldUngebuchteAusblenden,
      shouldLoadOnlyAktiveAufgabe: this._defaultFilter.shouldLoadOnlyAktiveAufgabe,
      zeitraumVon: this._defaultFilter.zeitraumVon,
      zeitraumBis: this._defaultFilter.zeitraumBis,
      idGeschaeftspartnerList: [...this._defaultFilter.idGeschaeftspartnerList],
      idGeschaeftspartnerSchuleList: [...this._defaultFilter.idGeschaeftspartnerSchuleList],
      idObjektStatusList: [...this._defaultFilter.idObjektStatusList],
      idTerminEmpfZustandList: [...this._defaultFilter.idTerminEmpfZustandList],
      idEventZeitraumList: [...this._defaultFilter.idEventZeitraumList]
    };

    const objektStatusFilterCurrentViewType = this._defaultFilter.dataSourceIdObjektStatusFilterDic[this.currentViewType];
    if (objektStatusFilterCurrentViewType) {
      this.filter.idObjektStatusList = objektStatusFilterCurrentViewType;
    }

    this.updateEventZeitraumList();
  }
  
  private async updateTerminEmpfZustand(zustandStatus: ZustandStatus) {
    const zustandList = await this._zustandService.getZustandList(
      ZustandBereich.TerminEmpfaengerStatus,
      this.filterTermin.zustandStatus
    );

    this.filter.idTerminEmpfZustandList = zustandList.map((z) => z.Id);
  }
  private async updateObjektStatus(objektStatusSammlungTyp: ObjektStatusSammlungTyp) {
    const objektStatusList = await this._statusService.getObjektStatusByObjektStatusSammlung(
      this.currentObjektFullName,
      objektStatusSammlungTyp
    );

    this.filter.idObjektStatusList = objektStatusList.map((s) => s.Id);
  }
  private async updateObjektStatusFilterTagList() {
    if (!this.filter.idObjektStatusList) {
      this.currentObjektStatusFilterList = [];
      return;
    }

    this.currentObjektStatusFilterList = (await this.getObjektStatusList())
      .filter((c) => this.filter.idObjektStatusList.indexOf(c.Id) >= 0);
  }
  private async updateGeschaeftspartnerFilterTagList() {
    if (!this.filter.idGeschaeftspartnerList) {
      this.currentGeschaeftspartnerFilterList = [];
      return;
    }

    this.currentGeschaeftspartnerFilterList = (await this.getGeschaeftspartnerList())
      .filter((c) => this.filter.idGeschaeftspartnerList.indexOf(c.Id) >= 0);
  }
  private updateEventZeitraumList() {
    if (!this.currentEventZeitraumList) {
      return;
    }
    if (!this.filter.idEventZeitraumList) {
      return;
    }

    for (const tag of this.currentEventZeitraumList) {
      tag.IsAktiv = this.filter.idEventZeitraumList.indexOf(tag.Id) >= 0;
    }
  }

  private getQuery(isNewDataType: boolean = false) {
    const query = <IQuery>{
      DataSourceType: this.currentViewType,
      KalenderViewType: this.filter.kalenderViewType,
      LoadKalender: this.filter.shouldLoadKalender,
      LoadDetail: this.filter.shouldLoadInfo,
      UngebuchteZeitraumAusblenden: this.filter.shouldUngebuchteAusblenden,
      NurAktiveAufgaben: this.filter.shouldLoadOnlyAktiveAufgabe,
      GueltigVon: this.filter.zeitraumVon,
      GueltigBis: this.filter.zeitraumBis,
      SearchText: this.filter.searchText,
      SearchExtendedValue: this.filter.searchExtended,
      IdObjektStatusList: this.filter.idObjektStatusList,
      IdGeschaeftspartnerList: this.filter.idGeschaeftspartnerList,
      IdGeschaeftspartnerSchuleList: this.filter.idGeschaeftspartnerSchuleList,
      IdTerminEmpfZustandStatusList: this.filter.idTerminEmpfZustandList,
      IdEventZeitraumList: this.filter.idEventZeitraumList,
      IdPerson: this.filterIdPerson
    };

    if (this.filterTermin && this.filterTermin.idTermin) {
      query.IdTermin = this.filterTermin.idTermin;
    }
    if (this.filterEventZeitraum && this.filterEventZeitraum.idEventZeitraum) {
      query.IdEventZeitraum = this.filterEventZeitraum.idEventZeitraum;
    }

    query.Columns = isNewDataType
      ? "[]"
      : JSON.stringify(this.layoutAuswahl.getCurrentGridColumns());

    return query;
  }

  private setGridData(result: IResult) {
    this.previousResult = result;
    this.grid.instance.beginUpdate();

    try {
      if (result.Columns && result.Columns.length > 0) {
        this.updateGridColumns(result.Columns);
      }

      this.grid.setOption({
        dataSource: [],
        columns: result.Columns
      });

      this.grid.setOption({
        dataSource: result.Data
      });

      this.updateGroupIndexes(result.Columns);
      this.calcColumnsWidth(result);
    } finally {
      this.grid.instance.endUpdate();
    }
  }
  private updateGridColumns(columns: IResultColumn[]) {
    for (const column of columns) {
      if (!column.format && column["_formatString"]) {
        column.format = this._globalizationService.getFormatter(column["_formatString"]);
      }

      if (column.dataField.substring(0, 1) !== "_") {
        continue;
      }

      column.headerCellTemplate = (element, data) => {
        if (data.column._headerIcon) {
          const iconElement = document.createElement("i");
          iconElement.className = `fa-fw ${data.column._headerIcon}`;
          element.appendChild(iconElement);
        } else {
          element.innerText = data.column.caption;
          element.style.whiteSpace = "normal";
        }

        if (data.column._headerFarbe) {
          element.style.color = data.column._headerFarbe;
        }

        element.title = data.column._tooltip || data.column.caption;
      };

      const isKalender = column.dataField.substring(0, 2) === "_K";
      const isDetail = column.dataField.substring(0, 8) === "_Details";

      if (isKalender || isDetail) {
        column.cellTemplate = (element, options) => {
          let value = null;

          if (isKalender) {
            const div = document.createElement("div");
            const dataField = options.column.dataField + "_Farbe";

            const farbe = options.data[dataField];
            if (farbe && farbe.length > 0) {
              div.style.backgroundColor = farbe;
              value = "&nbsp;";
            }

            div.innerHTML = value || options.value || "";

            element.appendChild(div);
          } else if (isDetail) {
            const div = document.createElement("div");
            div.innerHTML = options.value;

            element.appendChild(div);
          }
        };
      }
    }
  }
  private updateGroupIndexes(columns: IResultColumn[]) {
    const groups = columns
      .filter(c => c.groupIndex != void(0))
      .sort((a, b) => a.groupIndex - b.groupIndex);
    
    if (groups.length === 0) {
      return;
    }

    let index = 0;
    groups.forEach(c => {
      this.grid.instance.columnOption(c.dataField, "groupIndex", index);
      index++;
    });
  }
  private calcColumnsWidth(data: IResult) {
    const dataSource = data.Data;
    
    let columns: DevExpress.ui.dxDataGridColumn[] = this.grid
      ? this.grid.instance.option("columns")
      : this.gridOptions.columns;

    columns = columns.filter((c: any) => {
      return c._autoWidth
        || (c.dataField.substr(0, 1) !== "_" && (!this.layoutAuswahl || !this.layoutAuswahl.idCurrentLayout));
    });

    columns.forEach((c) => {
      const width = this.calcColumnWidth(dataSource, c);

      if (this.grid) {
        this.grid.instance.columnOption(c.dataField, "width", width);
      } else {
        c.width = width;
      }
    });
  }
  private calcColumnWidth(data: any[], column: DevExpress.ui.dxDataGridColumn): string {
    const min = Math.min(data.length, 15);

    let curr: number = 30;

    if (column.caption) {
      const width = this.calcTextWidth(column.caption);
      if (width > curr) {
        curr = width;
      }
    }
    
    for (let i = 0; i < min; i++) {
      let val = data[i][column.dataField];

      if (val == void (0)) {
        continue;
      }

      if (column.format) {
        val = (<any>column).format(val);
      }

      if (val == void (0)) {
        continue;
      }

      const width = this.calcTextWidth(val);
      if (curr < width) {
        curr = width;
      }
    }

    if (curr > 400) {
      curr = 400;
    } else if (curr < 30) {
      curr = 30;
    }

    return `${curr + 5}px`;
  }
  private calcTextWidth(text) {
    const canvas = this._canvas || (this._canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = "12pt arial";

    const metrics = context.measureText(text);
    return Math.ceil(metrics.width);
  }
  
  private async reloadById(id: number) {
    switch (this.currentViewType) {
      case ViewType.Termin: {
        await this.reloadTermin(id);
        break;
      }
      case ViewType.Event: {
        await this.reloadEventZeitraum(id);
        break;
      }
      case ViewType.EventTeilnehmer: {
        await this.reloadEventTeilnehmer(id);
        break;
      }
      default: {
        break;
      }
    }
  }
  private async reloadTermin(idTermin: number) {
    this.reload((dataSource) => {
      return dataSource.find((i) => {
        return i.Id == idTermin;
      });
    }, {
        IdTerminReload: idTermin
      });
  }
  private async reloadTerminEmpf(idTermin: number, idPerson: number) {
    this.reload((dataSource) => {
      return dataSource.find((i) => {
        return i.Id == idTermin
          && i.IdPerson == idPerson;
      });
    }, {
        IdTerminReload: idTermin,
        IdPersonReload: idPerson
      });
  }
  private async reloadEventZeitraum(idEventZeitraum: number) {
    this.reload((dataSource) => {
      return dataSource.find((i) => {
        return i.Id == idEventZeitraum;
      });
    }, {
        IdEventZeitraumReload: idEventZeitraum
      });
  }
  private async reloadEventTeilnehmer(idEventTeilnehmer: number) {
    this.reload((dataSource) => {
      return dataSource.find((i) => {
        return i.Id == idEventTeilnehmer;
      });
    }, {
        IdEventTeilnehmerReload: idEventTeilnehmer
      });
  }
  private async reload(findItem: { (dataSource: any[]): number }, reloadParams: any) {
    const dataSource = this.grid.instance.getDataSource();
    const store: DevExpress.data.ArrayStore = dataSource.store();
    const items = await store.load();
    const item = findItem(items);

    if (!item) {
      return;
    }

    const key = store.keyOf(item);
    const rowIndex = this.grid.instance.getRowIndexByKey(key);
    if (rowIndex < 0) {
      return;
    }

    const data = Object.assign({},
      this.getQuery(), {
        IsReload: true
      },
      reloadParams);

    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Tableau/Data"),
      data: data,
      increaseLoadingCount: true
    });

    if (!r || !r.Data || r.Data.length != 1) {
      return;
    }

    store.update(key, r.Data[0]);
    this.grid.instance.repaintRows([rowIndex]);
    this.grid.instance.clearSelection();
  }
}

export enum ViewType {
  DispoPlanstelle = 0,
  DispoPersonenzuteilung = 1,
  Termin = 2,
  TerminEmpfaenger = 3,
  Aufgabe = 4,
  Schnuppertermin = 5,
  Event = 6,
  EventTeilnehmer = 7,
  EventSchnuppertermin = 8,
  EventLinkAnmeldung = 9
}
enum KalenderViewType {
  Tag = 0,
  Woche = 1,
  Monat = 2
}
interface IFilter {
  idGeschaeftspartnerList: number[];
  idGeschaeftspartnerSchuleList: number[];
  idObjektStatusList: number[];
  idTerminEmpfZustandList: number[];
  idEventZeitraumList: number[];
  shouldLoadKalender?: boolean;
  shouldLoadOnlyAktiveAufgabe?: boolean;
  shouldUngebuchteAusblenden?: boolean;
  shouldLoadInfo?: boolean;
  kalenderViewType?: KalenderViewType;
  zeitraumVon?: Date;
  zeitraumBis?: Date;
  searchText?: string;
  searchExtended?: string;
}
interface IDefaultFilter extends IFilter {
  dataSourceIdObjektStatusFilterDic: any;
}
interface IFilterTermin {
  idTermin: number;
  zustandStatus?: ZustandStatus;
}
interface IFilterEventZeitraum {
  idEventZeitraum: number;
  objektStatusSammlungTyp: ObjektStatusSammlungTyp;
  zeitraumVon: Date;
  zeitraumBis: Date;
}
interface IQuery {
  DataSourceType: ViewType;
  KalenderViewType: KalenderViewType;
  LoadKalender?: boolean;
  LoadDetail?: boolean;
  IsNewDataType?: boolean;
  UngebuchteZeitraumAusblenden?: boolean;
  NurAktiveAufgaben?: boolean;

  GueltigVon: Date;
  GueltigBis: Date;

  SearchText?: string;
  SearchExtendedValue?: string;

  Columns?: string;

  IdList: number[];
  IdObjektStatusList: number[];
  IdGeschaeftspartnerList: number[];
  IdGeschaeftspartnerSchuleList: number[];
  IdTerminEmpfZustandStatusList: number[];
  IdEventZeitraumList: number[];
  IdTermin?: number;
  IdEventZeitraum?: number;
  IdPerson?: number;
  
  IsReload?: boolean;
  IdTerminReload?: number;
  IdPersonReload?: number;
  IdEventZeitraumReload?: number;
  IdEventTeilnehmerReload?: number;

  DataSourceIdObjektStatusFilterDic?: any;
}
interface IResult {
  Data: any[];
  Columns?: IResultColumn[];
  GueltigVon?: Date;
  GueltigBis?: Date;
}
interface IResultColumn {
  caption: string;
  dataField: string;
  visibleIndex?: number;
  width?: number;
  sortIndex?: number;
  sortOrder?: string;
  fixed?: boolean;
  allowReordering?: boolean;
  groupIndex?: number;
  format?: string;
  headerIcon?: string;
  headerFarbe?: string;
  tooltip?: string;
  authWidth?: boolean;

  headerCellTemplate?: any;
  cellTemplate?: any;
}
interface IZeitraumEdit {
  zeitraumVon: Date;
  zeitraumBis: Date;
  kalenderViewType: KalenderViewType;
  shouldUngebuchteAusblenden: boolean;
}
interface IObjektStatusFilter {
  Id: number;
  Bezeichnung: string;
  BezeichnungBenutzerrolle: string;
}
interface IGeschaeftspartnerFilter {
  Id: number;
  Name1: string;
}
interface ITerminEmpfFilter {
  Id: number;
  Bezeichnung: string;
}
