import { autoinject, transient } from "aurelia-framework";
import { LocalizationService, LocationService } from "../../../../framework/base/export";
import { ContextMenu, FormBase } from "../../../../framework/forms/classes/export";
import { ICommandData } from "../../../../framework/forms/interfaces/command-data";
import { IZitTagOptions } from "../../../../zit-ui/elements/zit-tag/zit-tag-options";
import { IZitToolbarOptions } from "../../../../zit-ui/elements/zit-toolbar/zit-toolbar-options";
import { IdxPopupComponent } from "../../../interfaces/export";
import { AufgabeService, NavTyp } from "../../../services/aufgabe-service";
import { StartupService } from "../../../services/export";
import { FormPopupService } from "../../../services/form-popup-service";

@autoinject
@transient()
export class AufgabeEditUtils {
  private TIMEOUT: number = 3000;

  constructor(
    private _locationService: LocationService,
    private _aufgabeService: AufgabeService,
    private _localizationService: LocalizationService,
    private _startupService: StartupService,
    private _formPopupService: FormPopupService
  ) { }

  form: FormBase;

  navPrevCommand: ICommandData = {
    id: "navPrevCommand",
    icon: "fas fa-arrow-left",
    tooltip: "aufgabe-read.vorherige",
    sortIndex: 200,
    isVisibleExpression: "models.data.$m_Aufgabe.Kopf.StammAufgabensetVersion.Typ === 1",
    execute: async() => {
      const r = await this._aufgabeService.nav({
        IdAufgabe: this.form.models.data.$m_Aufgabe.Id,
        NavTyp: NavTyp.Vorherige
      });

      if (!r || r.AufgabeList.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("aufgabe-read.kein-vorherige"),
          "info",
          this.TIMEOUT);

        return;
      }

      this._locationService.goTo({
        url: `Objekte/Aufgabe/${r.AufgabeList[0].Id}`,
        currentViewModel: this.form,
        replace: true
      });
    }
  };
  navNextCommand: ICommandData = {
    id: "navNextCommand",
    icon: "fas fa-arrow-right",
    tooltip: "aufgabe-read.naechste",
    sortIndex: 201,
    isVisibleExpression: "models.data.$m_Aufgabe.Kopf.StammAufgabensetVersion.Typ === 1",
    execute: async() => {
      const r = await this._aufgabeService.nav({
        IdAufgabe: this.form.models.data.$m_Aufgabe.Id,
        NavTyp: NavTyp.Naechste
      });

      if (!r || r.AufgabeList.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("aufgabe-read.kein-nachfolge"),
          "info",
          this.TIMEOUT);

        return;
      }

      this._locationService.goTo({
        url: `Objekte/Aufgabe/${r.AufgabeList[0].Id}`,
        currentViewModel: this.form,
        replace: true
      });
    }
  };
  navHistoryCommand: ICommandData = {
    id: "navHistoryCommand",
    icon: "fas fa-history",
    tooltip: "aufgabe-read.wechseln",
    sortIndex: 202,
    isVisibleExpression: "models.data.$m_Aufgabe.Kopf.StammAufgabensetVersion.Typ === 1",
    execute: async(ev) => {
      const r = await this._aufgabeService.nav({
        IdAufgabe: this.form.models.data.$m_Aufgabe.Id,
        NavTyp: NavTyp.Alle
      });

      if (!r) {
        return;
      }

      const ctx = new ContextMenu();
      r.AufgabeList.forEach((a) => {
        ctx.items.push({
          text: a.Bezeichnung,
          execute: () => {
            this._locationService.goTo({
              url: `Objekte/Aufgabe/${a.Id}`,
              currentViewModel: this.form,
              replace: true
            });
          }
        });
      });

      ctx.show(ev.event.target);
    }
  };
  infoAusbilderCommand: ICommandData = {
    id: "infoAusbilderCommand",
    icon: "fas fa-info-circle",
    title: "aufgabe-read.info-ausbilder",
    sortIndex: 300,
    isVisible: false,
    execute: async(ev) => {
      this._formPopupService.formPopup.show({
        titel: "Info für Ausbilder",
        formDef: [{
          name: "info",
          template: (data: any, itemElement: HTMLElement) => {
            itemElement.style.maxWidth = "400px";
            itemElement.innerHTML = this.form.models.data.$m_Aufgabe.Kopf.StammAufgabeVersion.BemerkungAusbilder;
          }
        }]
      });
    }
  };
  addZielCommand: ICommandData = {
    id: "addZielCommand",
    icon: "fas fa-tasks",
    title: "aufgabe-read.neues-ziel",
    sortIndex: 290,
    isVisibleExpression: "models.data.$m_Aufgabe.Id > 0",
    execute: async(ev) => {
      this._locationService.goTo({
        url: "Objekte/Ziel/0",
        clearStack: false,
        currentViewModel: this.form,
        customOptions: {
          idUrsprungsobjekt: this.form.models.data.$m_Aufgabe.Id
        },
      });
    }
  };

  beantwToolbarOptions: IZitToolbarOptions = {
    title: "aufgabe-read.deine-antwort",
    smallToolbar: true,
    items: [{
      id: "info",
      icon: "fas fa-info",
      isVisible: ["ADMIN", "ADMIN_HB", "TEAM"].indexOf(this._startupService.startupInfo.Benutzer.CodeBenutzerrolle) >= 0,
      execute: async(e) => {
        await this.form.saveIfDirty();

        const popup: IdxPopupComponent = this.form["r_popupInf"];
        popup.instance.show();
      }
    }]
  };

  indexAnzahlTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "fas fa-map-signs"
    },
    textExpression: "'Aufgabe ' + (item._Index + (item.Kopf.IsErfuellt ? 0 : 1)) + ' von ' + item._Anzahl"
  };
  objektTagOptions: IZitTagOptions = {
    icon: {
      textExpression: "item._ObjektTyp"
    },
    textExpression: "item._ObjektBezeichnung",
    onClick: (e, data) => {
      if (data._ObjektTyp == "P") {
        this._locationService.goTo({
          url: `Objekte/Person/${data._IdObjekt}`,
          currentViewModel: this.form
        });
      } else if (data._ObjektTyp == "A") {
        this._locationService.goTo({
          url: `Objekte/Ansprechperson/${data._IdObjekt}`,
          currentViewModel: this.form
        });
      }
    }
  };
  dauerTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "far fa-clock"
    },
    textExpression: "item.DauerMinuten | zitMinutenText"
  };
  aufgabensetTagOptions: IZitTagOptions = {
    icon: {
      text: "AS"
    },
    textExpression: "item.Bezeichnung"
  };
  vorbereitetTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "fas fa-info",
      backgroundColor: "#C24334"
    },
    textExpression: "'Startdatum noch nicht erreicht (' + globalization.format(item.StartDatum, 'd') + ')'",
    backgroundColor: "#E84C3D",
    color: "white"
  };
  vorgaengerOffenTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "fas fa-info",
      backgroundColor: "#C24334"
    },
    text: "Vorgängeraufgabe noch nicht erfüllt",
    backgroundColor: "#E84C3D",
    color: "white"
  };
  bestaetigungOffenTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "fas fa-info",
      backgroundColor: "#D35400"
    },
    text: "Bestätigung ausständig",
    backgroundColor: "#E67F22",
    color: "white"
  };
  bestaetigungOffen2TagOptions: IZitTagOptions = {
    icon: {
      faIcon: "fas fa-hourglass",
      backgroundColor: "#D25400"
    },
    textExpression: "'Die Aufgabe muss von ' + item.BestaetigungBenutzerName + ' bestätigt werden'",
    backgroundColor: "#E67F22",
    color: "white"
  };

  bind(form: FormBase) {
    this.form = form;

    this.form.callOnCommandsLoaded(() => {
      const saveCommand = this.form.commands.getCommands()
        .find((c) => c.id == "$save");
      if (saveCommand) {
        saveCommand.isVisible = false;
      }
    });

    this.form.models.onLoaded.register(async(ev) => {
      if (ev.model.id == "$m_Aufgabe") {
        this.checkInfoAusbilderVisible(ev.data);
      }
    });
    this.form.onSaved.register(async(ev) => {
      const data = this.form.models.data.$m_Aufgabe;

      if (data && data.Id && !data._CanErfuellen && !data._CanBestaetigen && data.Kopf.IsBestaetigt) {
        const r = await this._aufgabeService.nav({
          IdAufgabe: data.Id,
          NavTyp: NavTyp.Naechste
        });

        if (r && r.AufgabeList.length > 0) {
          this._locationService.goTo({
            url: `Objekte/Aufgabe/${r.AufgabeList[0].Id}`,
            currentViewModel: this.form,
            replace: true
          });
        }
      }
    });
  }

  async onErfuelltClick() {
    let data = this.form.models.data.$m_Aufgabe;
    if (data.Kopf.IsErfuellt) {
      DevExpress.ui.notify(
        "Aufgabe wurde bereits erfüllt",
        "info",
        this.TIMEOUT
      );

      return;
    } else if (!data._CanErfuellen) {
      DevExpress.ui.notify(
        "Aufgabe kann noch nicht erfüllt werden",
        "error",
        this.TIMEOUT
      );

      return;
    }

    const r1 = await this.form.saveIfDirty();
    if (!r1.isValid) {
      return;
    }

    //Neu holen, da die Daten ev. geändert wurden
    data = this.form.models.data.$m_Aufgabe;
    data.Kopf.IsErfuellt = true;

    const r2 = await this.form.save();
    if (!r2.isValid) {
      data.Kopf.IsErfuellt = false;
    }
  }
  async onBestaetigtClick() {
    const r1 = await this.form.saveIfDirty();
    if (!r1.isValid) {
      return;
    }

    const data = this.form.models.data.$m_Aufgabe;
    data.Kopf.IsBestaetigt = true;

    const r2 = await this.form.save();
    if (!r2.isValid) {
      data.Kopf.IsBestaetigt = false;
    }
  }
  async onRetourClick() {
    const r1 = await this.form.saveIfDirty();
    if (!r1.isValid) {
      return;
    }

    const data = this.form.models.data.$m_Aufgabe;
    data.Kopf.IsErfuellt = false;
    await this.form.save();
  }

  private checkInfoAusbilderVisible(data) {
    const benutzergruppeList = ["ADMIN", "ADMIN_HB", "TEAM", "KUNDE"];

    this.infoAusbilderCommand.isVisible = data
      && data.Kopf
      && data.Kopf.StammAufgabeVersion
      && data.Kopf.StammAufgabeVersion.BemerkungAusbilder
      && benutzergruppeList.indexOf(this._startupService.startupInfo.Benutzer.CodeBenutzerrolle) >= 0;
  }
}
