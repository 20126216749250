import * as fwx from "../../../../framework/forms/form-export";
import {
    PersonAusbildungErweitertEditUtils
} from "./person-ausbildung-erweitert-edit-utils";

@fwx.autoinject
export class PersonAusbildungErweitertEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_PersonAusbildungErweitert: PersonAusbildungErweitertEditUtils) {
        super(element, formBaseImport);
        this.id = "person-ausbildung-erweitert-edit";
        this.title = "person-ausbildung-erweitert-edit.person-ausbildung-erweitert-edit_caption";
        this.addModel({
            "id": "$m_AusbildungNeu",
            "webApiAction": "ZIT/Stammdaten/Ausbildung",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addFunction("$f_PersonAusbildungErweitert", $f_PersonAusbildungErweitert, "functions.$f_PersonAusbildungErweitert");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "r_personAusbildungValidationGroup",
            "options": {
                "optionsName": "r_personAusbildungValidationGroupOptions",
                "optionsNameFQ": "r_personAusbildungValidationGroupOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "person-ausbildung-erweitert-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_AusbildungNeu",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_AusbildungNeu.Bezeichnung"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_AusbildungNeu",
                    "bindTo": "$f_PersonAusbildungErweitert.requiredValidationRule",
                    "bindToFQ": "functions.$f_PersonAusbildungErweitert.requiredValidationRule"
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "ausbildungAbschluss",
            "filters": [],
            "caption": "person-ausbildung-erweitert-edit.r_ausbildungabschluss_caption",
            "binding": {
                "dataContext": "$m_AusbildungNeu",
                "bindTo": "$f_PersonAusbildungErweitert.idAusbildungAbschluss",
                "bindToFQ": "functions.$f_PersonAusbildungErweitert.idAusbildungAbschluss"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_AusbildungNeu",
                    "bindTo": "$f_PersonAusbildungErweitert.requiredValidationRule",
                    "bindToFQ": "functions.$f_PersonAusbildungErweitert.requiredValidationRule"
                }
            }],
            "id": "r_ausbildungAbschluss",
            "options": {
                "optionsName": "r_ausbildungAbschlussOptions",
                "optionsNameFQ": "r_ausbildungAbschlussOptions"
            }
        });
        super.onConstructionFinished();
    }
}