
import { autoinject } from "aurelia-framework";
import { SucheService, StartupService } from '../../services/export';
import { BenutzerTyp } from '../../enumerations/export';
import { IZitCardOptions, ZitCardTyp } from '../../../zit-ui/elements/zit-card/export';

@autoinject
export class DashboardBoxEinsatz {
  constructor(
    private sucheService: SucheService,
    private startupSerive: StartupService
  ) {
    const natuerlichePerson = this.startupSerive.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.NatuerlichePerson;

    this.isNatuerlichePerson = natuerlichePerson;
  }

  item: any;
  isNatuerlichePerson: boolean;

  einsatzCardOptions: IZitCardOptions = {
    isClickEnabled: true,
    useDefaultBindings: true,
    typ: ZitCardTyp.Kompakt
  };

  activate(item) {
    this.item = item;
  }

  onBoxHeaderClicked() {
    this.sucheService.activateSuche("TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstellePersonenzuteilung");
  }
}
