import { autoinject } from "../../framework/forms/form-export";
import { RestService } from "../../framework/base/services/rest-service";
import { GlobalizationService } from "../../framework/base/export";

import * as moment from "moment";

@autoinject
export class SchnupperterminService {
  private SWITCH_MONTH = 9;

  constructor(
    private _restService: RestService,
    private _globalizationService: GlobalizationService
  ) {}

  getSchnupperterminZeitraum(): ISchnupperterminZeitraum {
    const today = moment()
      .startOf("day");

    const month = today.month() + 1;

    let von = moment({ year: today.year(), month: this.SWITCH_MONTH - 1, day: 1});

    if (month + 1 < this.SWITCH_MONTH) {
      von = von.subtract(1, "year");
    }

    return {
      von: von.toDate(),
      bis: von.add(1, "year")
        .subtract(1, "day")
        .toDate()
    };
  }
  async getEventZeitraumSchnupperterminList(von: Date, bis: Date): Promise<ISchnupperterminZeitraumResult[]> {
    return await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Schnuppertermin/SchnupperterminZeitraum"),
      data: {
        Von: von,
        Bis: bis
      }
    });
  }
}

export interface ISchnupperterminZeitraum {
  von: Date;
  bis: Date;
}
export interface ISchnupperterminZeitraumResult {
  Id: number;
  Bezeichnung: string;
  Start: Date;
  Ende: Date;
  IsAktiv?: boolean;
}