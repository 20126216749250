import { autoinject, TaskQueue } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { ViewItem } from "../../framework/forms/classes/view-item";

@autoinject
export class ContainerViewWidthService {
  constructor(
    private _eventAggregator: EventAggregator,
    private _taskQueue: TaskQueue
  ) {
    this._eventAggregator.subscribe("router:view-item-created", (e) => {
      this.onViewItemCreated(e.viewItem);
    });
    this._eventAggregator.subscribe("router:view-animation-end", (e) => {
      //bissele warten, damit wir nicht irgendwelche anderen Animation behindern
      this._taskQueue.queueTask(() => {
        this._eventAggregator.publish("grid:check-class", {
          element: e.element
        });
      });
    });
  }

  private onViewItemCreated(viewItem: ViewItem) {
    switch (viewItem.moduleId) {
      case "zit/views/dashboard/dashboard-kunde-form":
      case "zit/views/dashboard/dashboard-person-form":
      case "zit/views/suche/suche-form":
      case "zit/views/objekte/disposition/dispo-list-form":
      case "zit/views/tableau/tableau-list-form":
      case "zit/views/schnuppertermin/schnuppertermin-list-form":
      case "zit/views/entwicklungszone/entwicklungszone-list-form":
      case "zit/views/entwicklungsziele/entwicklungsziele-list-form":
      case "zit/views/objekte/event/event-list-form":
      case "zit/views/erklaervideo/erklaervideo-list-form": {
        viewItem.customClassName = "z--view-can-be-full-width";
        break;
      }
      default: {
        break;
      }
    }
  }
}
