import { IdxButtonComponent } from './../../interfaces/dx-components';
import { StartupService } from './../../services/startup-service';
import { TaskQueue } from 'aurelia-framework';
import { autoinject, OverrideContext, Scope } from 'aurelia-framework';
import { ScopeContainer, LocalizationService } from '../../../framework/base/export';
import { SimpleWidgetCreatorService } from '../../../framework/forms/export';
import { DataSourceService, GlobalizationService } from '../../../framework/base/services/export';
import { IdxDataGridComponent } from '../../interfaces/export';
import { LoginprofilService } from '../../services/loginprofil-service';
import { IdxValidationGroupComponent, IdxSelectBoxComponent, IdxPopupComponent } from '../../interfaces/export';
@autoinject
export class BenutzerWechseln {
  constructor(
    public startup: StartupService,
    private _dataSourceService: DataSourceService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _localizationService: LocalizationService,
    private _loginprofilService: LoginprofilService,
    private _taskQueue: TaskQueue,
    private _globalizationService: GlobalizationService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  idCurrentLoginprofil: number;

  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };
  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {};

  benutzerWechselnPopup: IdxPopupComponent;
  benutzerWechselnPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxHeight: "100%",
    maxWidth: "900px"
  }

  loginprofil: IdxSelectBoxComponent;
  loginprofilOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Bezeichnung",
    bindingOptions: {
      value: "idCurrentLoginprofil"
    }
  }

  loginprofilWechselnButton: IdxButtonComponent;
  loginprofilWechselnButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fas fa-check",
    onClick: () => {
      this.onLoginprofilWechselnClicked();
    }
  }

  benutzerWechselnGridDataSource: DevExpress.data.DataSource;
  benutzerWechselnGrid: IdxDataGridComponent;
  benutzerWechselnGridOptions: DevExpress.ui.dxDataGridOptions = {
    columnAutoWidth: true,
    height: "400px",
    selection: {
      mode: "single"
    },
    paging: {
      pageSize: 10
    },
    remoteOperations: {
      filtering: true,
      paging: true,
      sorting: true
    },
    searchPanel: {
      visible: true
    },
    columns: [
      { dataField: "Benutzer.Benutzername", caption: this._localizationService.translateOnce("benutzereinstellungen.benutzername") },
      { dataField: "Benutzer.Vorname", caption: this._localizationService.translateOnce("benutzereinstellungen.vorname") },
      { dataField: "Benutzer.Nachname", caption: this._localizationService.translateOnce("benutzereinstellungen.nachname") },
      { dataField: "Bezeichnung", caption: this._localizationService.translateOnce("benutzereinstellungen.loginprofil") },
      { 
        dataField: "Benutzer.Status", 
        caption: this._localizationService.translateOnce("benutzereinstellungen.status"),
        dataType: "string",
        customizeText: (e) => {
          switch (e.value) {
            case 0: {
              return "aktiviert";
            }
            case 1: {
              return "vorbereitet";
            }
            case 2: {
              return "vom Benutzer gelöscht";
            }
            case 3: {
              return "Aktivierung versendet";
            }
            case 4: {
              return "bereit für Aktivierung";
            }
            case 5: {
              return "gesperrt";
            }
            default: {
              return "";
            }
          }
        }
      },
      { 
        dataField: "Benutzer.Anlagedatum", 
        caption: this._localizationService.translateOnce("benutzereinstellungen.anlagedat"),
        format: this._globalizationService.getFormatter("g")
      }
    ],
    onRowClick: (e) => {
      this.onBenutzerRowClicked(e.key);
    }
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "benutzerWechselnPopupToolbar",
      caption: "benutzer-wechseln.popup-titel",
      options: this.benutzerWechselnPopupOptions,
      scopeContainer: this.scopeContainer
    });

    this.benutzerWechselnGridDataSource = this._dataSourceService.createDataSource(this.scopeContainer, {
      webApiAction: "ZIT/Berechtigungen/Loginprofil",
      keyProperty: "Id",
      webApiExpand: { Benutzer: null }
    }, {
        getCustomFilters: () => {
          return [{
            webApiCustomKey: "Editing",
            webApiCustomValue: "true"
          }, {
            webApiCustomKey: "CheckBenutzertyp",
            webApiCustomValue: "true"
          }]
        }
      });

    this.benutzerWechselnGridOptions.dataSource = this.benutzerWechselnGridDataSource;
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  onExportLoginprofil() {
    this.benutzerWechselnGrid.instance.exportToExcel(false);
  }

  async showPopup() {
    this.setLoginprofilDataSource();
    this.idCurrentLoginprofil = this.startup.startupInfo.Benutzer.IdLoginprofil;
    await this.benutzerWechselnPopup.instance.show();

    this._taskQueue.queueMicroTask(() => {
      if(!this.benutzerWechselnGrid) {
        return;
      }
      this.benutzerWechselnPopup.instance.repaint();
      const input = this.benutzerWechselnGrid.instance.element()
        .getElementsByClassName("dx-datagrid-search-panel").item(0)
        .getElementsByTagName("input").item(0);
      this._taskQueue.queueMicroTask(() => {
        input.focus();
      })
    });
  }

  private async onBenutzerRowClicked(idProfil: number) {
    await this._loginprofilService.setLoginProfil(idProfil, true);
  }

  private async onLoginprofilWechselnClicked() {
    const validationResult = this.validationGroup.instance.validate();
    if (!validationResult.isValid) {
      return;
    }

    await this._loginprofilService.setLoginProfil(this.idCurrentLoginprofil, true);
  }

  private async setLoginprofilDataSource() {
    const dataSource = await this._loginprofilService.getLoginprofile();

    if (this.loginprofil) {
      this.loginprofil.setOption({
        dataSource: dataSource
      });
    } else {
      this.loginprofilOptions.dataSource = dataSource;
    }
  }
}
