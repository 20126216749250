import { autoinject, transient } from 'aurelia-framework';
import { IZitTagOptions } from '../../../../zit-ui/elements/zit-tag/export';
import { IZitToolbarOptions } from '../../../../zit-ui/elements/zit-toolbar/zit-toolbar-options';
import { ContextMenu, ICommandData } from '../../../../framework/forms/export';
import { CustomEditPopupService, StartupService } from '../../../services/export';
import { Share } from '../../../elements/share/share';
import { FormBase } from '../../../../framework/forms/classes/form-base';
import { LocationService } from '../../../../framework/base/services/location-service';
import { ZitMomentValueConverter } from '../../../../zit-ui/value-converters/export';
import { BenutzerTyp } from '../../../enumerations/export';

@autoinject
@transient()
export class StelleninseratReadUtils {
  constructor(
    private customEditPopup: CustomEditPopupService,
    private location: LocationService,
    private zitMoment: ZitMomentValueConverter,
    private startupService: StartupService
  ) { }

  form: FormBase;

  isNatuerlichePerson = this.startupService.startupInfo.Benutzer.Benutzertyp === BenutzerTyp.NatuerlichePerson;

  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };
  bewerbungToolbarOptions: IZitToolbarOptions = {
    title: "stelleninserat-utils.bewerbung-toolbar-titel",
    smallToolbar: true,
    items: []
  };

  ansprechpersonReadTagOptions: IZitTagOptions = {
    isClickEnabled: !this.isNatuerlichePerson,
    icon: {
      text: "K"
    },
    textExpression: `item.GesamtNameMitTitel 
    ? item.GesamtNameMitTitel
    : item.Titel + " " + item.Name`,
    infoList: [
      {
        isVisibleExpression: `item.IsInaktiv`,
        icon: {
          faIcon: "far fa-trash-alt",
          backgroundColor: "lightgray",
        }
      }
    ],
    onClick: (e, ansprechperson) => {
      if(this.isNatuerlichePerson) {
        return;
      }
      this.customEditPopup.ansprechpersonRead.show({
        mappings: {
          "$id": ansprechperson.Id
        }
      });
    }
  };

  zeitraumReadTagOptions: IZitTagOptions = {
    icon: {
      text: "Z"
    },
    textExpression: `item.zeitraum`
  };

  websiteAktivTagOptions: IZitTagOptions = {
    cssClass: 'z--tag-info-message z--tag-info-ok-message',
    icon: { faIcon: 'far fa-check-circle' },
    textExpression: `"stelleninserat-utils.website-aktiviert-text" | tr`
  };
  websiteInAktivTagOptions: IZitTagOptions = {
    cssClass: 'z--tag-info-message z--tag-info-falsch-message',
    icon: { faIcon: 'far fa-times-circle' },
    textExpression: `"stelleninserat-utils.website-nicht-aktiviert-text" | tr`
  };
  websiteProfilFirmaInaktivTagOptions: IZitTagOptions = {
    cssClass: 'z--tag-info-message z--tag-info-falsch-message',
    icon: { faIcon: 'far fa-times-circle' },
    textExpression: '"stelleninserat-utils.website-profil-firma-inaktiv-text" | tr'
  };
  stelleninseratGruppeTagOptions: IZitTagOptions = {
    textExpression: `item.Text | zitRestrictLength:50`,
    icon: {
      textExpression: `item && item.Gruppentyp && item.Gruppentyp.Bezeichnung
      ? item.Gruppentyp.Bezeichnung[0] : "G"`
    }
  };
  unternehmenTagOptions: IZitTagOptions = {
    icon: {
      text: "U"
    },
    textExpression: `item.Subheadline ? item.Subheadline 
    : item.Geschaeftspartner.Geschaeftspartnerdaten.Name1`
  };
  adresseTagOptions: IZitTagOptions = {
    icon: {
      text: "A"
    },
    textExpression: `item | zitAddress:"short:street"`
  };

  bind(form: FormBase) {
    this.form = form;
  }

  get zeitraum(): string {
    const model = this.form.models.data.$m_Stelleninserat;
    if (!model || !model.Kopf)
      return "";

    let von = model.Kopf.GueltigVon
    let bis = model.Kopf.GueltigBis

    return this.zitMoment.getDispoVonBis(von, { bis: bis });
  }
}
