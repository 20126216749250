export class ZitAddressValueConverter {
  toView(data: any, format: AddressFormat): string {
    if (data == void 0) {
      return "";
    }

    switch (format) {
      case AddressFormat.short:
        return this.shortFormat(data);
      case AddressFormat.shortWithStreet:
        return this.shortWithStrasse(data);
      case AddressFormat.einsatzort:
        return this.einsatzort(data);
      default:
        return data;
    }
  }

  einsatzort(data): string {
    if (!data || !data.Geschaeftspartner) {
      return "";
    }

    if (data.Adresse) {
      return data.Adresse;
    }

    const gp = data.Geschaeftspartner;
    if (!gp || !gp.Adressen || !gp.Adressen.Adressen || gp.Adressen.Adressen.length === 0) {
      return "";
    }

    //TODO Gültigkeit prüfen
    const adresse = gp.Adressen.Adressen[0];

    return `${this.shortWithStrasse(adresse, true)}`;
  }

  shortFormat(data: any, needsLand: boolean = false) {
    if (data.Land) {
      if (data.Land.KFZ && !needsLand) {
        return `${data.Land.KFZ}-${data.Postleitzahl} ${data.Ort}`;
      } else {
        return `${data.Postleitzahl} ${data.Ort}, ${data.Land.Bezeichnung}`;
      }
    }

    return `${data.Postleitzahl} ${data.Ort}`;
  }
  shortWithStrasse(data, needsLand: boolean = false) {
    const shortAdd = this.shortFormat(data, needsLand);
    const result = `${this.getStringValueOrEmpty(data.Strasse + this.getStringValueOrEmpty(data.StrasseZusatz, " "))}, ${shortAdd}`;
    return result;
  }

  private getStringValueOrEmpty(str: string, suffixValue: string = " ") {
    if (!str) {
      return "";
    }

    return suffixValue + str;
  }
}

export enum AddressFormat {
  short = "short",
  shortWithStreet = "short:street",
  einsatzort = "einsatzort"
}