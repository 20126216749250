
import { autoinject } from 'aurelia-framework';
import { RestService, FileService } from '../../framework/base/export';
import { HeaderService } from '../../framework/default-ui/services/header-service';
import { AuthorizationService } from '../../framework/base/services/authorization-service';
import { StartupService } from './startup-service';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class AvatarService {
  constructor(
    private _restService: RestService,
    private _headerService: HeaderService,
    private _authorizationService: AuthorizationService,
    private _startupService: StartupService,
    private _fileService: FileService,
    private _eventAggregator: EventAggregator
  ) {
    this._eventAggregator.subscribe("avatar:update", () => {
      this.updateAvatarUrl();
    });

    this.updateAvatarUrl();
   }

  private updateAvatarUrl() {
    let authKey = this._authorizationService.getAuthorizationKey();
    if (authKey && this._startupService.startupInfo.Benutzer.DMSLinkAvatar) {
      this._headerService.avatarUrl = this._fileService.getInlineUrl(this._startupService.startupInfo.Benutzer.DMSLinkAvatar);
    } else {
      this._headerService.avatarUrl = null;
    }
  }
}
