import { observable } from "aurelia-binding";
import { bindable, computedFrom } from "aurelia-framework";
import { autoinject } from "../../../framework/forms/form-export";
import { ScopeContainer } from "./../../../framework/base/classes/scope-container";
import { DataSourceService } from "./../../../framework/base/services/data-source-service";
import { LocalizationService } from "./../../../framework/base/services/localization-service";
import { RestService } from "./../../../framework/base/services/rest-service";
import { ICommandData } from "./../../../framework/forms/interfaces/command-data";
import { SimpleWidgetCreatorService } from "./../../../framework/forms/widget-services/simple-widget-creator-service";
import { IdxLookupComponent, IdxPopupComponent, IdxSelectBoxComponent, IdxValidationGroupComponent } from "./../../interfaces/dx-components";

@autoinject
export class EventTeilnehmerZuteilen {
  private _options: IEventTeilnehmerZuteilen;
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _restService: RestService,
    private _localizationService: LocalizationService,
    private _dataSourceService: DataSourceService) { }

  @bindable @observable idEvent: number;
  scopeContainer: ScopeContainer;
  idEventZeitraum: number;
  idPerson: number;
  idStelleninserat: number;

  showStelleninserat: boolean = true;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "600",
    height: "auto"
  };

  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };
  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {};

  saveCommand: ICommandData = {
    id: "save",
    icon: "fas fa-save",
    tooltip: "base.save",
    execute: (e) => {
      const validationResult = this.validationGroup.instance.validate();
      if (!validationResult.isValid) {
        return;
      }

      this.saveTeilnehmer();
    }
  };

  teilnehmerAuswaehlenLookup: IdxLookupComponent;
  teilnehmerAuswaehlenLookupOptions: DevExpress.ui.dxLookupOptions = {
    valueExpr: "Id",
    displayExpr: "Titel",
    itemTemplate: "zit-person-template",
    bindingOptions: {
      value: "idPerson"
    },
    onValueChangedByUser: () => {
      this.idEventZeitraum = null;
    }
  };

  zeitraumSelectBox: IdxSelectBoxComponent;
  zeitraumSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    searchEnabled: true,
    displayExpr: "DisplayText",
    valueExpr: "Id",
    bindingOptions: {
      value: "idEventZeitraum"
    }
  };

  stelleninseratEventFilter = {
    webApiCustomKey: "IdEvent",
    webApiCustomValue: "0"
  };
  stelleninseratSelectBox: IdxSelectBoxComponent;
  stelleninseratSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    searchEnabled: true,
    displayExpr: "Titel",
    itemTemplate: "zit-stelleninserat-template",
    valueExpr: "Id",
    bindingOptions: {
      value: "idStelleninserat"
    }
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });
    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "eventTeilnehmerZuteilenPopupToolbar",
      caption: "event-teilnehmer-zuteilen.teilnehmer",
      options: this.popupOptions,
      commands: [this.saveCommand],
      scopeContainer: this.scopeContainer
    });

    this.setTeilnehmerDataSource();
    this.setEventZeitraumDataSource();
    this.setStelleninseratDataSource();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async show(options: IEventTeilnehmerZuteilen) {
    this._options = options;
    this.idEventZeitraum = null;
    this.idPerson = null;
    this.idStelleninserat = null;
    
    this.showStelleninserat = await this.isStelleninseratPflicht();
    
    this.popup.instance.show();
  }

  idEventChanged(newVal) {
    this.stelleninseratEventFilter.webApiCustomValue = (newVal || 0).toString(); 
  }
  
  private async isStelleninseratPflicht() {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl(`ZIT/Objekte/Event/${this.idEvent}`),
      getOptions: {
        columns: ["Id", "IdEventKopfElement", "Titel"],
        expand: {
          Kopf: {
            columns: ["Id", "IdEventTyp"],
            expand: {
              EventTyp: {
                columns: ["Id", "IsStelleninseratPflichtBeiAnlageEventTeilnehmer"]
              }
            }
          }
        }
      },
      increaseLoadingCount: true
    });
    
    return r?.Kopf?.EventTyp?.IsStelleninseratPflichtBeiAnlageEventTeilnehmer || false;
  }

  private setEventZeitraumDataSource() {
    const dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ZIT/Objekte/EventZeitraum",
        webApiWhere: ["IdEvent", { "expression": "idEvent", "isBound": true }],
        filters: [{ webApiCustomKey: "IdPersonExclude", webApiCustomValue: "idPerson" }],
        keyProperty: "Id",
        webApiExpand: {
          Kopf: null
        }
      });

    if (this.zeitraumSelectBox) {
      this.zeitraumSelectBox.instance.option("dataSource", dataSource);
    } else {
      this.zeitraumSelectBoxOptions.dataSource = dataSource;
    }
  }
  private setStelleninseratDataSource() {
    const dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ZIT/Objekte/Stelleninserat",
        filters: [this.stelleninseratEventFilter, {
          webApiCustomKey: "AddStelleninseratCard",
          webApiCustomValue: "true"
        }],
        keyProperty: "Id"
      });

    if (this.stelleninseratSelectBox) {
      this.stelleninseratSelectBox.instance.option("dataSource", dataSource);
    } else {
      this.stelleninseratSelectBoxOptions.dataSource = dataSource;
    }
  }

  private setTeilnehmerDataSource() {
    const dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ZIT/Objekte/Person",
        webApiColumns: ["Id", "Titel"],
        filters: [{
          webApiCustomKey: "AddPersonCard",
          webApiCustomValue: "true"
        }]
      }
    );

    if (this.teilnehmerAuswaehlenLookup) {
      this.teilnehmerAuswaehlenLookup.instance.option("dataSource", dataSource);
    } else {
      this.teilnehmerAuswaehlenLookupOptions.dataSource = dataSource;
    }
  }

  private async saveTeilnehmer() {
    await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Event/CreateTeilnehmer"),
      data: {
        IdEventZeitraum: this.idEventZeitraum,
        IdPerson: this.idPerson,
        IdStelleninserat: this.idStelleninserat
      },
      increaseLoadingCount: true
    });

    DevExpress.ui.notify(this._localizationService.translateOnce("base.save_success"), "success", this.NOTIFY_TIMEOUT);

    this.popup.instance.hide();
    this._options.callback();
  }
}

export interface IEventTeilnehmerZuteilen {
  callback: { (): void };
}
