import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class PersonListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "person-list";
        this.title = "person-list.person-list_caption";
        this.addModel({
            "id": "$m_Person",
            "webApiAction": "ZIT/Objekte/Person",
            "webApiExpand": {
                'Personendaten': null
            },
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_persGeschlecht",
                "caption": "person-list.r_persgeschlecht_caption",
                "bindTo": "Personendaten.Geschlecht",
                "width": "80px",
                "enumTypeName": "TIP.ZIT.Business.Enumerations.GeschlechtEnum"
            }, {
                "id": "r_persTitelVorne",
                "caption": "person-list.r_perstitelvorne_caption",
                "bindTo": "Personendaten.TitelVorne",
                "width": "80px"
            }, {
                "id": "r_persVorname",
                "caption": "person-list.r_persvorname_caption",
                "bindTo": "Personendaten.Vorname",
                "minWidth": 100
            }, {
                "id": "r_persNachname",
                "caption": "person-list.r_persnachname_caption",
                "bindTo": "Personendaten.Nachname",
                "minWidth": 100
            }, {
                "id": "r_persTitelHinten",
                "caption": "person-list.r_perstitelhinten_caption",
                "bindTo": "Personendaten.TitelHinten",
                "width": "80px"
            }],
            "optionsToolbar": {
                "optionsName": "r_personGridToolbarOptions",
                "optionsNameFQ": "r_personGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Person"
            },
            "dataModel": "$m_Person",
            "editUrl": "Objekte/Person",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personGrid",
            "options": {
                "optionsName": "r_personGridOptions",
                "optionsNameFQ": "r_personGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}