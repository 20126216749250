import { autoinject } from "aurelia-framework";
import { StartupService } from "../../zit/services/export";
import { BrowserService } from "../../framework/base/export";

@autoinject
export class TitleValueConverter {
  constructor(
    private _startupService: StartupService,
    private _browserService: BrowserService
  ) {}

  toView(route: string, defaultTitle: string) {
    if (!route) {
      return defaultTitle;
    }

    const menu = this._startupService
      .startupInfo
      .MenuList
      .find((m) => m.Route == route);

    if (!menu) {
      return defaultTitle;
    }

    return this._browserService.isMobile
      ? menu.BezeichnungMobil
      : menu.BezeichnungDesktop;
  }
}