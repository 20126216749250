import { autoinject, computedFrom } from 'aurelia-framework';
import { LocalizationService, ScopeContainer, FileService } from '../../../framework/base/export';
import { SimpleWidgetCreatorService } from '../../../framework/forms/export';
import { IdxPopupComponent } from '../../interfaces/export';
import { StartupService } from '../../services/startup-service';
import { BenutzereinstellungenService } from '../../services/benutzereinstellungen-service';
@autoinject
export class WhatsappAktivieren {
  constructor(
    private benutzereinstellungen: BenutzereinstellungenService,
    private file: FileService,
    private localization: LocalizationService,
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private startupService: StartupService
  ) { }

  scopeContainer: ScopeContainer;

  @computedFrom("startupService.startupInfo.Mandant.WhatsAppTelefonnummer")
  get whatsappTelefon() {
    return this.startupService.startupInfo.Mandant.WhatsAppTelefonnummer;
  }
  @computedFrom("startupService.startupInfo.Mandant.DMSLinkVisitenkarte")
  get dmsLinkVisitenkarte() {
    return this.file.getDownloadUrl(this.startupService.startupInfo.Mandant.DMSLinkVisitenkarte);
  }

  whatsappAktivierenPopup: IdxPopupComponent;
  whatsappAktivierenPopupOptions: DevExpress.ui.dxPopupOptions = {
    title: this.localization.translateOnce("whatsapp-aktivieren.popup-titel"),
    height: "auto",
    maxWidth: "650px",
    maxHeight: "80%"
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "whatsappAktivierenPopupToolbar",
      caption: "whatsapp-aktivieren.popup-titel",
      options: this.whatsappAktivierenPopupOptions,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  showPopup() {
    this.whatsappAktivierenPopup.instance.show();
  }
}
