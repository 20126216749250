import * as fwx from "../../../../framework/forms/form-export";
import {
    GeschaeftspartnerGruppierungUtils
} from "./geschaeftspartner-gruppierung";

@fwx.autoinject
export class GeschaeftspartnerGruppierungForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_GeschaeftsparnterGruppierungUtils: GeschaeftspartnerGruppierungUtils) {
        super(element, formBaseImport);
        this.id = "geschaeftspartner-gruppierung";
        this.title = "geschaeftspartner-gruppierung.geschaeftspartner-gruppierung_caption";
        this.addModel({
            "id": "$m_GeschaeftspartnerGruppierung",
            "webApiAction": "ZIT/Stammdaten/GeschaeftspartnerGruppierung",
            "webApiExpand": {
                GeschaeftspartnerGruppierungstyp: null
            },
            "webApiWhere": ["IdGeschaeftspartner", {
                "isBound": true,
                "expression": "variables.data.$v_idGeschaeftspartner"
            }],
            "webApiOrderBy": [{
                columnName: 'GeschaeftspartnerGruppierungstyp.SortNr',
                sortOrder: 0
            }, {
                columnName: 'SortNr',
                sortOrder: 0
            }, {
                columnName: 'GeschaeftspartnerGruppierungstyp.Bezeichnung',
                sortOrder: 0
            }],
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerGruppierungEdit",
            "webApiAction": "ZIT/Stammdaten/GeschaeftspartnerGruppierung",
            "webApiExpand": {
                'BewertungArbeitseinsatzAnsprechpersonen': null,
                'VerantwortlichAnsprechpersonen': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_VerantwortlichAnsprechperson",
            "webApiAction": "ZIT/Objekte/Ansprechperson",
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BewertungArbeitseinsatzAnsprechperson",
            "webApiAction": "ZIT/Objekte/Ansprechperson",
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Geschaeftspartner",
            "webApiAction": "ZIT/Objekte/Geschaeftspartner",
            "webApiColumns": ["Name1"],
            "modificationInfoEnabled": true,
            "key": "variables.data.$v_idGeschaeftspartner",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$v_idGeschaeftspartner"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_GeschaeftsparnterGruppierungUtils.addNewGruppierungCommand",
                "bindToFQ": "functions.$f_GeschaeftsparnterGruppierungUtils.addNewGruppierungCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_GeschaeftsparnterGruppierungUtils.saveGruppierungCommand",
                "bindToFQ": "functions.$f_GeschaeftsparnterGruppierungUtils.saveGruppierungCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_GeschaeftsparnterGruppierungUtils.deleteGruppierungCommand",
                "bindToFQ": "functions.$f_GeschaeftsparnterGruppierungUtils.deleteGruppierungCommand"
            }
        });
        this.addFunction("$f_GeschaeftsparnterGruppierungUtils", $f_GeschaeftsparnterGruppierungUtils, "functions.$f_GeschaeftsparnterGruppierungUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_GeschaeftspartnerGruppierungTyp",
                "caption": "geschaeftspartner-gruppierung.r_geschaeftspartnergruppierungtyp_caption",
                "bindTo": "GeschaeftspartnerGruppierungstyp.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_GeschaeftspartnerGruppierungBezeichnung",
                "caption": "geschaeftspartner-gruppierung.r_geschaeftspartnergruppierungbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_GeschaeftspartnerGruppierungSortNr",
                "caption": "geschaeftspartner-gruppierung.r_geschaeftspartnergruppierungsortnr_caption",
                "bindTo": "SortNr",
                "width": "90px"
            }],
            "optionsToolbar": {
                "optionsName": "r_GeschaeftspartnerGruppierungDataGridToolbarOptions",
                "optionsNameFQ": "r_GeschaeftspartnerGruppierungDataGridToolbarOptions"
            },
            "caption": "geschaeftspartner-gruppierung.r_geschaeftspartnergruppierungdatagrid_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerGruppierung"
            },
            "dataModel": "$m_GeschaeftspartnerGruppierung",
            "onItemClick": "functions.$f_GeschaeftsparnterGruppierungUtils.onGruppierungClick($event)",
            "selectionMode": 1,
            "pageSize": 10,
            "showPagerInfo": true,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_GeschaeftspartnerGruppierungDataGrid",
            "options": {
                "optionsName": "r_GeschaeftspartnerGruppierungDataGridOptions",
                "optionsNameFQ": "r_GeschaeftspartnerGruppierungDataGridOptions"
            }
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "r_geschaeftspartnerGruppierungValidationGroup",
            "options": {
                "optionsName": "r_geschaeftspartnerGruppierungValidationGroupOptions",
                "optionsNameFQ": "r_geschaeftspartnerGruppierungValidationGroupOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "geschaeftspartnerGruppierungstyp",
            "filters": [],
            "caption": "geschaeftspartner-gruppierung.r_geschaeftspartnergruppierungtyp_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerGruppierungEdit",
                "bindTo": "IdGruppierungstyp",
                "bindToFQ": "models.data.$m_GeschaeftspartnerGruppierungEdit.IdGruppierungstyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_GeschaeftspartnerGruppierungTyp",
            "options": {
                "optionsName": "r_GeschaeftspartnerGruppierungTypOptions",
                "optionsNameFQ": "r_GeschaeftspartnerGruppierungTypOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "geschaeftspartner-gruppierung.r_geschaeftspartnergruppierungbezeichnung_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerGruppierungEdit",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_GeschaeftspartnerGruppierungEdit.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_GeschaeftspartnerGruppierungBezeichnung",
            "options": {
                "optionsName": "r_GeschaeftspartnerGruppierungBezeichnungOptions",
                "optionsNameFQ": "r_GeschaeftspartnerGruppierungBezeichnungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showSpinButtons": true,
            "min": 0.0,
            "caption": "geschaeftspartner-gruppierung.r_geschaeftspartnergruppierungsortnr_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerGruppierungEdit",
                "bindTo": "SortNr",
                "bindToFQ": "models.data.$m_GeschaeftspartnerGruppierungEdit.SortNr"
            },
            "validationRules": [],
            "id": "r_GeschaeftspartnerGruppierungSortNr",
            "options": {
                "optionsName": "r_GeschaeftspartnerGruppierungSortNrOptions",
                "optionsNameFQ": "r_GeschaeftspartnerGruppierungSortNrOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "geschaeftspartner-gruppierung.r_geschaeftspartnergruppierunggueltigvon_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerGruppierungEdit",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_GeschaeftspartnerGruppierungEdit.GueltigVon"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_GeschaeftspartnerGruppierungGueltigVon",
            "options": {
                "optionsName": "r_GeschaeftspartnerGruppierungGueltigVonOptions",
                "optionsNameFQ": "r_GeschaeftspartnerGruppierungGueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "geschaeftspartner-gruppierung.r_geschaeftspartnergruppierunggueltigbis_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerGruppierungEdit",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_GeschaeftspartnerGruppierungEdit.GueltigBis"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_GeschaeftspartnerGruppierungGueltigBis",
            "options": {
                "optionsName": "r_GeschaeftspartnerGruppierungGueltigBisOptions",
                "optionsNameFQ": "r_GeschaeftspartnerGruppierungGueltigBisOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "erfassungTyp",
            "filters": [],
            "caption": "geschaeftspartner-gruppierung.r_geschaeftspartnergruppierungfakturaempfaengererfassungtyp_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerGruppierungEdit",
                "bindTo": "FakturaempfaengerErfassungTyp",
                "bindToFQ": "models.data.$m_GeschaeftspartnerGruppierungEdit.FakturaempfaengerErfassungTyp"
            },
            "validationRules": [],
            "id": "r_GeschaeftspartnerGruppierungFakturaempfaengerErfassungTyp",
            "options": {
                "optionsName": "r_GeschaeftspartnerGruppierungFakturaempfaengerErfassungTypOptions",
                "optionsNameFQ": "r_GeschaeftspartnerGruppierungFakturaempfaengerErfassungTypOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "ansprechperson",
            "filters": [],
            "caption": "geschaeftspartner-gruppierung.r_geschaeftspartnergruppierungidfakturaempfaengeransprechperson_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerGruppierungEdit",
                "bindTo": "IdFakturaEmpfaengerAnsprechperson",
                "bindToFQ": "models.data.$m_GeschaeftspartnerGruppierungEdit.IdFakturaEmpfaengerAnsprechperson"
            },
            "validationRules": [],
            "id": "r_GeschaeftspartnerGruppierungIdFakturaEmpfaengerAnsprechperson",
            "options": {
                "optionsName": "r_GeschaeftspartnerGruppierungIdFakturaEmpfaengerAnsprechpersonOptions",
                "optionsNameFQ": "r_GeschaeftspartnerGruppierungIdFakturaEmpfaengerAnsprechpersonOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "geschaeftspartner-gruppierung.r_geschaeftspartnergruppierungfakturaempfaengeransprechpersonfreitext_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerGruppierungEdit",
                "bindTo": "FakturaEmpfaengerAnsprechpersonFreitext",
                "bindToFQ": "models.data.$m_GeschaeftspartnerGruppierungEdit.FakturaEmpfaengerAnsprechpersonFreitext"
            },
            "validationRules": [],
            "id": "r_GeschaeftspartnerGruppierungFakturaEmpfaengerAnsprechpersonFreitext",
            "options": {
                "optionsName": "r_GeschaeftspartnerGruppierungFakturaEmpfaengerAnsprechpersonFreitextOptions",
                "optionsNameFQ": "r_GeschaeftspartnerGruppierungFakturaEmpfaengerAnsprechpersonFreitextOptions"
            }
        });
        this.widgetCreator.addTagBox(this, {
            "caption": "geschaeftspartner-gruppierung.r_geshaeftspartnergruppierungverantwortlichansprechpersonen_caption",
            "dataContext": "$m_GeschaeftspartnerGruppierungEdit",
            "batchSelectionEnabled": true,
            "relationBinding": {
                "dataContext": "$m_GeschaeftspartnerGruppierungEdit",
                "bindTo": "VerantwortlichAnsprechpersonen",
                "bindToFQ": "models.data.$m_GeschaeftspartnerGruppierungEdit.VerantwortlichAnsprechpersonen"
            },
            "relationProperty": "IdAnsprechperson",
            "itemDataContext": "$m_VerantwortlichAnsprechperson",
            "itemValueExpr": "Id",
            "itemDisplayExpr": "Name",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerGruppierungEdit"
            },
            "validationRules": [],
            "id": "r_GeshaeftspartnerGruppierungVerantwortlichAnsprechpersonen",
            "options": {
                "optionsName": "r_GeshaeftspartnerGruppierungVerantwortlichAnsprechpersonenOptions",
                "optionsNameFQ": "r_GeshaeftspartnerGruppierungVerantwortlichAnsprechpersonenOptions"
            }
        });
        this.widgetCreator.addTagBox(this, {
            "caption": "geschaeftspartner-gruppierung.r_geshaeftspartnergruppierungbewertungarbeitseinsatzansprechpersonen_caption",
            "dataContext": "$m_GeschaeftspartnerGruppierungEdit",
            "batchSelectionEnabled": true,
            "relationBinding": {
                "dataContext": "$m_GeschaeftspartnerGruppierungEdit",
                "bindTo": "BewertungArbeitseinsatzAnsprechpersonen",
                "bindToFQ": "models.data.$m_GeschaeftspartnerGruppierungEdit.BewertungArbeitseinsatzAnsprechpersonen"
            },
            "relationProperty": "IdAnsprechperson",
            "itemDataContext": "$m_BewertungArbeitseinsatzAnsprechperson",
            "itemValueExpr": "Id",
            "itemDisplayExpr": "Name",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerGruppierungEdit"
            },
            "validationRules": [],
            "id": "r_GeshaeftspartnerGruppierungBewertungArbeitseinsatzAnsprechpersonen",
            "options": {
                "optionsName": "r_GeshaeftspartnerGruppierungBewertungArbeitseinsatzAnsprechpersonenOptions",
                "optionsNameFQ": "r_GeshaeftspartnerGruppierungBewertungArbeitseinsatzAnsprechpersonenOptions"
            }
        });
        super.onConstructionFinished();
    }
}