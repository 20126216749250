import * as fwx from "../../../../framework/forms/form-export";
import {
    PersonenEditUtils
} from "./person-edit-utils";
import {
    PersonenAdresseUtils
} from "./person-adresse-utils";

@fwx.autoinject
export class PersonFerialerErsteinstiegEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_PersonenEditUtils: PersonenEditUtils,
        private $f_PersonenAdresseUtils: PersonenAdresseUtils) {
        super(element, formBaseImport);
        this.id = "person-ferialer-ersteinstieg-edit";
        this.title = "person-ferialer-ersteinstieg-edit.person-ferialer-ersteinstieg-edit_caption";
        this.addModel({
            "id": "$m_Person",
            "webApiAction": "ZIT/Objekte/Person",
            "webApiExpand": {
                'Personendaten': null,
                'Adressen': null,
                'Kontakte': {
                    'expand': {
                        'Kontakttyp': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "allowDelete": "false",
            "filters": [{
                "webApiCustomKey": "FragebogenInfo",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_Bewerbung",
            "webApiAction": "ZIT/Objekte/Bewerbung",
            "webApiWhere": ["Kopf.IdPerson", {
                "isBound": true,
                "expression": "models.data.$m_Person.Id"
            }],
            "webApiOrderBy": [{
                columnName: 'Anlagedatum',
                sortOrder: 1
            }],
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "AddBewerbungCard",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_BankverbindungElement",
            "webApiAction": "ZIT/Objekte/PersonBankverbindungElement",
            "key": "models.data.$m_Person.IdPersonBankverbindungElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BerufserfahrungElement",
            "webApiAction": "ZIT/Objekte/PersonBerufserfahrungElement",
            "key": "models.data.$m_Person.IdPersonBerufserfahrungElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_AusbildungElement",
            "webApiAction": "ZIT/Objekte/PersonAusbildungElement",
            "key": "models.data.$m_Person.IdPersonAusbildungElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_SpracheElement",
            "webApiAction": "ZIT/Objekte/PersonSpracheElement",
            "key": "models.data.$m_Person.IdPersonSpracheElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_FuehrerscheinElement",
            "webApiAction": "ZIT/Objekte/PersonFuehrerscheinElement",
            "key": "models.data.$m_Person.IdPersonFuehrerscheinElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonAdresse",
            "webApiAction": "ZIT/Objekte/PersonAdresseElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonKontaktRel",
            "webApiAction": "ZIT/Objekte/PersonKontaktElement",
            "webApiExpand": {
                'Kontakttyp': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonKontaktEdit",
            "webApiAction": "ZIT/Objekte/PersonKontaktElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonBerufserfahrungRel",
            "webApiAction": "ZIT/Objekte/PersonBerufserfahrung",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonBerufserfahrungEdit",
            "webApiAction": "ZIT/Objekte/PersonBerufserfahrung",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonAusbildungRel",
            "webApiAction": "ZIT/Objekte/PersonAusbildung",
            "webApiExpand": {
                'Ausbildung': null,
                'Ausbildungsort': null,
                'AusbildungAbschluss': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonAusbildungEdit",
            "webApiAction": "ZIT/Objekte/PersonAusbildung",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonBerufRel",
            "webApiAction": "ZIT/Objekte/PersonBeruf",
            "webApiExpand": {
                'Beruf': null,
                'BerufQualifikation': null,
                'AusbildungAbschluss': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonBerufEdit",
            "webApiAction": "ZIT/Objekte/PersonBeruf",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonSpracheRel",
            "webApiAction": "ZIT/Objekte/PersonSprache",
            "webApiExpand": {
                'Sprache': null,
                'Sprachniveau': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonSpracheEdit",
            "webApiAction": "ZIT/Objekte/PersonSprache",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonFuehrerscheinRel",
            "webApiAction": "ZIT/Objekte/PersonFuehrerschein",
            "webApiExpand": {
                'Fuehrerscheinklasse': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonFuehrerscheinEdit",
            "webApiAction": "ZIT/Objekte/PersonFuehrerschein",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_DokumentRel",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "webApiExpand": {
                'Informationstyp': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_DokumentEdit",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_PersonenEditUtils.actionCommand",
                "bindToFQ": "functions.$f_PersonenEditUtils.actionCommand"
            }
        });
        this.addEditPopup({
            "idContent": "personBerufserfahrungEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonBerufserfahrungEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonBerufserfahrungEdit.Id"
                }
            }],
            "id": "personBerufserfahrungEditPopup",
            "options": {
                "optionsName": "personBerufserfahrungEditPopupOptions",
                "optionsNameFQ": "personBerufserfahrungEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "personFuehrerscheinEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonFuehrerscheinEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonFuehrerscheinEdit.Id"
                }
            }],
            "id": "personFuehrerscheinEditPopup",
            "options": {
                "optionsName": "personFuehrerscheinEditPopupOptions",
                "optionsNameFQ": "personFuehrerscheinEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "personSpracheEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonSpracheEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonSpracheEdit.Id"
                }
            }],
            "id": "personSpracheEditPopup",
            "options": {
                "optionsName": "personSpracheEditPopupOptions",
                "optionsNameFQ": "personSpracheEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "personAusbildungEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonAusbildungEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonAusbildungEdit.Id"
                }
            }],
            "id": "personAusbildungEditPopup",
            "options": {
                "optionsName": "personAusbildungEditPopupOptions",
                "optionsNameFQ": "personAusbildungEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addFunction("$f_PersonenEditUtils", $f_PersonenEditUtils, "functions.$f_PersonenEditUtils");
        this.addFunction("$f_PersonenAdresseUtils", $f_PersonenAdresseUtils, "functions.$f_PersonenAdresseUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "geschlecht",
            "filters": [],
            "caption": "person-ferialer-ersteinstieg-edit.r_geschlecht_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Geschlecht",
                "bindToFQ": "models.data.$m_Person.Personendaten.Geschlecht",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschlecht",
            "options": {
                "optionsName": "r_geschlechtOptions",
                "optionsNameFQ": "r_geschlechtOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "person-ferialer-ersteinstieg-edit.r_titelvorne_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.TitelVorne",
                "bindToFQ": "models.data.$m_Person.Personendaten.TitelVorne",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_titelVorne",
            "options": {
                "optionsName": "r_titelVorneOptions",
                "optionsNameFQ": "r_titelVorneOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "person-ferialer-ersteinstieg-edit.r_titelhinten_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.TitelHinten",
                "bindToFQ": "models.data.$m_Person.Personendaten.TitelHinten",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_titelHinten",
            "options": {
                "optionsName": "r_titelHintenOptions",
                "optionsNameFQ": "r_titelHintenOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-ferialer-ersteinstieg-edit.r_vorname_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Vorname",
                "bindToFQ": "models.data.$m_Person.Personendaten.Vorname",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_vorname",
            "options": {
                "optionsName": "r_vornameOptions",
                "optionsNameFQ": "r_vornameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-ferialer-ersteinstieg-edit.r_nachname_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Nachname",
                "bindToFQ": "models.data.$m_Person.Personendaten.Nachname",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_nachname",
            "options": {
                "optionsName": "r_nachnameOptions",
                "optionsNameFQ": "r_nachnameOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "person-ferialer-ersteinstieg-edit.r_geburtsdatum_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Geburtsdatum",
                "bindToFQ": "models.data.$m_Person.Personendaten.Geburtsdatum",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geburtsdatum",
            "options": {
                "optionsName": "r_geburtsdatumOptions",
                "optionsNameFQ": "r_geburtsdatumOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "staat",
            "filters": [],
            "caption": "person-ferialer-ersteinstieg-edit.r_idnationalitaet_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.IdNationalitaet",
                "bindToFQ": "models.data.$m_Person.Personendaten.IdNationalitaet",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_idNationalitaet",
            "options": {
                "optionsName": "r_idNationalitaetOptions",
                "optionsNameFQ": "r_idNationalitaetOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "familienstand",
            "filters": [],
            "caption": "person-ferialer-ersteinstieg-edit.r_familienstand_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.IdFamilienstand",
                "bindToFQ": "models.data.$m_Person.Personendaten.IdFamilienstand",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_familienstand",
            "options": {
                "optionsName": "r_familienstandOptions",
                "optionsNameFQ": "r_familienstandOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 4,
            "caption": "person-ferialer-ersteinstieg-edit.r_svnr_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.SVNr",
                "bindToFQ": "models.data.$m_Person.Personendaten.SVNr",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Person",
                    "bindTo": "$f_PersonenEditUtils.svNummerValidationRule",
                    "bindToFQ": "functions.$f_PersonenEditUtils.svNummerValidationRule"
                }
            }, {
                "item": {
                    "type": "svnr",
                    "parameters": []
                }
            }],
            "id": "r_svNr",
            "options": {
                "optionsName": "r_svNrOptions",
                "optionsNameFQ": "r_svNrOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 20,
            "caption": "person-ferialer-ersteinstieg-edit.r_ahvnrliechtenstein_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.AHVNrLiechtenstein",
                "bindToFQ": "models.data.$m_Person.Personendaten.AHVNrLiechtenstein",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Person",
                    "bindTo": "$f_PersonenEditUtils.svNummerValidationRule",
                    "bindToFQ": "functions.$f_PersonenEditUtils.svNummerValidationRule"
                }
            }, {
                "item": {
                    "type": "ahvnr",
                    "parameters": []
                }
            }],
            "id": "r_ahvNrLiechtenstein",
            "options": {
                "optionsName": "r_ahvNrLiechtensteinOptions",
                "optionsNameFQ": "r_ahvNrLiechtensteinOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 20,
            "caption": "person-ferialer-ersteinstieg-edit.r_ahvnrschweiz_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.AHVNrSchweiz",
                "bindToFQ": "models.data.$m_Person.Personendaten.AHVNrSchweiz",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Person",
                    "bindTo": "$f_PersonenEditUtils.svNummerValidationRule",
                    "bindToFQ": "functions.$f_PersonenEditUtils.svNummerValidationRule"
                }
            }, {
                "item": {
                    "type": "ahvnr",
                    "parameters": []
                }
            }],
            "id": "r_ahvNrSchweiz",
            "options": {
                "optionsName": "r_ahvNrSchweizOptions",
                "optionsNameFQ": "r_ahvNrSchweizOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "person-ferialer-ersteinstieg-edit.r_staplerfuehrerschein_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.HasStaplerfuehrerschein",
                "bindToFQ": "models.data.$m_Person.Personendaten.HasStaplerfuehrerschein",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_staplerfuehrerschein",
            "options": {
                "optionsName": "r_staplerfuehrerscheinOptions",
                "optionsNameFQ": "r_staplerfuehrerscheinOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "caption": "person-ferialer-ersteinstieg-edit.r_bemerkung_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Bemerkung",
                "bindToFQ": "models.data.$m_Person.Personendaten.Bemerkung",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_bemerkung",
            "options": {
                "optionsName": "r_bemerkungOptions",
                "optionsNameFQ": "r_bemerkungOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 0,
            "acceptType": "image/*",
            "height": "250px",
            "showToolbar": true,
            "showClearButton": false,
            "caption": "person-ferialer-ersteinstieg-edit.r_dmslinkfoto_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.DMSLinkFoto",
                "bindToFQ": "models.data.$m_Person.Personendaten.DMSLinkFoto",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_dmsLinkFoto",
            "options": {
                "optionsName": "r_dmsLinkFotoOptions",
                "optionsNameFQ": "r_dmsLinkFotoOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "staat",
            "filters": [],
            "caption": "person-ferialer-ersteinstieg-edit.r_land_caption",
            "binding": {
                "dataContext": "$m_PersonAdresse",
                "bindTo": "IdLand",
                "bindToFQ": "models.data.$m_PersonAdresse.IdLand"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_land",
            "options": {
                "optionsName": "r_landOptions",
                "optionsNameFQ": "r_landOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-ferialer-ersteinstieg-edit.r_strasse_caption",
            "binding": {
                "dataContext": "$m_PersonAdresse",
                "bindTo": "Strasse",
                "bindToFQ": "models.data.$m_PersonAdresse.Strasse"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_strasse",
            "options": {
                "optionsName": "r_strasseOptions",
                "optionsNameFQ": "r_strasseOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 20,
            "caption": "person-ferialer-ersteinstieg-edit.r_postleitzahl_caption",
            "binding": {
                "dataContext": "$m_PersonAdresse",
                "bindTo": "Postleitzahl",
                "bindToFQ": "models.data.$m_PersonAdresse.Postleitzahl"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_postleitzahl",
            "options": {
                "optionsName": "r_postleitzahlOptions",
                "optionsNameFQ": "r_postleitzahlOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-ferialer-ersteinstieg-edit.r_ort_caption",
            "binding": {
                "dataContext": "$m_PersonAdresse",
                "bindTo": "Ort",
                "bindToFQ": "models.data.$m_PersonAdresse.Ort"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_ort",
            "options": {
                "optionsName": "r_ortOptions",
                "optionsNameFQ": "r_ortOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "landvorwahl",
            "filters": [],
            "caption": "person-ferialer-ersteinstieg-edit.r_mobilwert1_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.MobilLandvorwahl",
                "bindToFQ": "models.data.$m_Person.Personendaten.MobilLandvorwahl",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Person.Personendaten && models.data.$m_Person.Personendaten.Mobil"
                    }]
                }
            }, {
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Person.Personendaten && !models.data.$m_Person.Personendaten.Email"
                    }]
                }
            }],
            "id": "r_mobilWert1",
            "options": {
                "optionsName": "r_mobilWert1Options",
                "optionsNameFQ": "r_mobilWert1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-ferialer-ersteinstieg-edit.r_mobilwert2_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Mobil",
                "bindToFQ": "models.data.$m_Person.Personendaten.Mobil",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "telefon",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Person.Personendaten && !models.data.$m_Person.Personendaten.Email"
                    }]
                }
            }],
            "id": "r_mobilWert2",
            "options": {
                "optionsName": "r_mobilWert2Options",
                "optionsNameFQ": "r_mobilWert2Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-ferialer-ersteinstieg-edit.r_email_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Email",
                "bindToFQ": "models.data.$m_Person.Personendaten.Email",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Person.Personendaten && (!models.data.$m_Person.Personendaten.Mobil || !models.data.$m_Person.Personendaten.MobilLandvorwahl)"
                    }]
                }
            }],
            "id": "r_email",
            "options": {
                "optionsName": "r_emailOptions",
                "optionsNameFQ": "r_emailOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "col-xs-12 col-sm-6",
            "useDefaultListItemStyle": false,
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_personAusbildungListViewToolbarOptions",
                "optionsNameFQ": "r_personAusbildungListViewToolbarOptions"
            },
            "caption": "person-ferialer-ersteinstieg-edit.r_personausbildunglistview_caption",
            "binding": {
                "dataContext": "$m_AusbildungElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonAusbildungRel",
                "bindTo": "IdPersonAusbildungElement",
                "bindToFQ": "models.data.$m_PersonAusbildungRel.IdPersonAusbildungElement"
            },
            "dataModel": "$m_AusbildungElement",
            "editDataContext": "$m_PersonAusbildungEdit",
            "idEditPopup": "personAusbildungEditPopup",
            "pageSize": 2,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personAusbildungListView",
            "options": {
                "optionsName": "r_personAusbildungListViewOptions",
                "optionsNameFQ": "r_personAusbildungListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "col-xs-12 col-sm-6",
            "useDefaultListItemStyle": false,
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_personBerufserfahrungListViewToolbarOptions",
                "optionsNameFQ": "r_personBerufserfahrungListViewToolbarOptions"
            },
            "caption": "person-ferialer-ersteinstieg-edit.r_personberufserfahrunglistview_caption",
            "binding": {
                "dataContext": "$m_BerufserfahrungElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonBerufserfahrungRel",
                "bindTo": "IdPersonBerufserfahrungElement",
                "bindToFQ": "models.data.$m_PersonBerufserfahrungRel.IdPersonBerufserfahrungElement"
            },
            "dataModel": "$m_BerufserfahrungElement",
            "editDataContext": "$m_PersonBerufserfahrungEdit",
            "idEditPopup": "personBerufserfahrungEditPopup",
            "pageSize": 2,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personBerufserfahrungListView",
            "options": {
                "optionsName": "r_personBerufserfahrungListViewOptions",
                "optionsNameFQ": "r_personBerufserfahrungListViewOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "groesseSchuh",
            "filters": [],
            "caption": "person-ferialer-ersteinstieg-edit.r_schuehgroesse_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.IdSchuhgroesse",
                "bindToFQ": "models.data.$m_Person.Personendaten.IdSchuhgroesse",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_schuehgroesse",
            "options": {
                "optionsName": "r_schuehgroesseOptions",
                "optionsNameFQ": "r_schuehgroesseOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "groesseKleider",
            "filters": [],
            "caption": "person-ferialer-ersteinstieg-edit.r_kleidergroesse_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.IdKleidergroesse",
                "bindToFQ": "models.data.$m_Person.Personendaten.IdKleidergroesse",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_kleidergroesse",
            "options": {
                "optionsName": "r_kleidergroesseOptions",
                "optionsNameFQ": "r_kleidergroesseOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "person-ferialer-ersteinstieg-edit.r_hasesdsicherheitsschuhe_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.HasEsdSicherheitsschuhe",
                "bindToFQ": "models.data.$m_Person.Personendaten.HasEsdSicherheitsschuhe",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_hasEsdSicherheitsschuhe",
            "options": {
                "optionsName": "r_hasEsdSicherheitsschuheOptions",
                "optionsNameFQ": "r_hasEsdSicherheitsschuheOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_personSpracheListViewToolbarOptions",
                "optionsNameFQ": "r_personSpracheListViewToolbarOptions"
            },
            "caption": "person-ferialer-ersteinstieg-edit.r_personsprachelistview_caption",
            "binding": {
                "dataContext": "$m_SpracheElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonSpracheRel",
                "bindTo": "IdPersonSpracheElement",
                "bindToFQ": "models.data.$m_PersonSpracheRel.IdPersonSpracheElement"
            },
            "dataModel": "$m_SpracheElement",
            "editDataContext": "$m_PersonSpracheEdit",
            "idEditPopup": "personSpracheEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personSpracheListView",
            "options": {
                "optionsName": "r_personSpracheListViewOptions",
                "optionsNameFQ": "r_personSpracheListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_personFuehrerscheinListViewToolbarOptions",
                "optionsNameFQ": "r_personFuehrerscheinListViewToolbarOptions"
            },
            "caption": "person-ferialer-ersteinstieg-edit.r_personfuehrerscheinlistview_caption",
            "binding": {
                "dataContext": "$m_FuehrerscheinElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonFuehrerscheinRel",
                "bindTo": "IdPersonFuehrerscheinElement",
                "bindToFQ": "models.data.$m_PersonFuehrerscheinRel.IdPersonFuehrerscheinElement"
            },
            "dataModel": "$m_FuehrerscheinElement",
            "editDataContext": "$m_PersonFuehrerscheinEdit",
            "idEditPopup": "personFuehrerscheinEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personFuehrerscheinListView",
            "options": {
                "optionsName": "r_personFuehrerscheinListViewOptions",
                "optionsNameFQ": "r_personFuehrerscheinListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "col-xs-12 col-sm-6",
            "useDefaultListItemStyle": false,
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_personBewerbungListViewToolbarOptions",
                "optionsNameFQ": "r_personBewerbungListViewToolbarOptions"
            },
            "caption": "person-ferialer-ersteinstieg-edit.r_personbewerbunglistview_caption",
            "binding": {
                "dataContext": "$m_Bewerbung"
            },
            "dataModel": "$m_Bewerbung",
            "pageSize": 2,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personBewerbungListView",
            "options": {
                "optionsName": "r_personBewerbungListViewOptions",
                "optionsNameFQ": "r_personBewerbungListViewOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_personAbgeschlossenPopup",
            "options": {
                "optionsName": "r_personAbgeschlossenPopupOptions",
                "optionsNameFQ": "r_personAbgeschlossenPopupOptions"
            },
            "caption": "person-ferialer-ersteinstieg-edit.r_personabgeschlossenpopup_caption",
            "height": "auto",
            "width": "450px",
            "showCloseButton": false,
            "commands": [{
                "binding": {
                    "bindTo": "$f_PersonenEditUtils.personAbschliessenCommand",
                    "bindToFQ": "functions.$f_PersonenEditUtils.personAbschliessenCommand"
                }
            }]
        });
        super.onConstructionFinished();
    }
}