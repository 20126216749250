import * as fwx from "../../../../framework/forms/form-export";
import {
    PersonenEditUtils
} from "./person-edit-utils";

@fwx.autoinject
export class PersonAusbildungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_PersonenEditUtils: PersonenEditUtils) {
        super(element, formBaseImport);
        this.id = "person-ausbildung-edit";
        this.title = "person-ausbildung-edit.person-ausbildung-edit_caption";
        this.addModel({
            "id": "$m_Ausbildung",
            "webApiAction": "ZIT/Objekte/PersonAusbildung",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addEditPopup({
            "idContent": "personAusbildungNeuEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_Ausbildung",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_Ausbildung.Id"
                }
            }],
            "id": "personAusbildungNeuEditPopup",
            "options": {
                "optionsName": "personAusbildungNeuEditPopupOptions",
                "optionsNameFQ": "personAusbildungNeuEditPopupOptions"
            },
            "height": "200px",
            "width": "450px",
            "commands": []
        });
        this.addFunction("$f_PersonenEditUtils", $f_PersonenEditUtils, "functions.$f_PersonenEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "ausbildung",
            "filters": [],
            "caption": "person-ausbildung-edit.r_ausbildung_caption",
            "binding": {
                "dataContext": "$m_Ausbildung",
                "bindTo": "IdAusbildung",
                "bindToFQ": "models.data.$m_Ausbildung.IdAusbildung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_ausbildung",
            "options": {
                "optionsName": "r_ausbildungOptions",
                "optionsNameFQ": "r_ausbildungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "ausbildungAbschluss",
            "customs": [{
                "key": "IdAusbildung",
                "value": "models.data.$m_Ausbildung.IdAusbildung"
            }],
            "filters": [],
            "caption": "person-ausbildung-edit.r_ausbildungabschluss_caption",
            "binding": {
                "dataContext": "$m_Ausbildung",
                "bindTo": "IdAusbildungAbschluss",
                "bindToFQ": "models.data.$m_Ausbildung.IdAusbildungAbschluss"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_ausbildungAbschluss",
            "options": {
                "optionsName": "r_ausbildungAbschlussOptions",
                "optionsNameFQ": "r_ausbildungAbschlussOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 1900.0,
            "max": 2100.0,
            "caption": "person-ausbildung-edit.r_beginnjahr_caption",
            "binding": {
                "dataContext": "$m_Ausbildung",
                "bindTo": "BeginnJahr",
                "bindToFQ": "models.data.$m_Ausbildung.BeginnJahr"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_beginnJahr",
            "options": {
                "optionsName": "r_beginnJahrOptions",
                "optionsNameFQ": "r_beginnJahrOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 1900.0,
            "max": 2100.0,
            "caption": "person-ausbildung-edit.r_abschlussjahr_caption",
            "binding": {
                "dataContext": "$m_Ausbildung",
                "bindTo": "AbschlussJahr",
                "bindToFQ": "models.data.$m_Ausbildung.AbschlussJahr"
            },
            "validationRules": [],
            "id": "r_abschlussJahr",
            "options": {
                "optionsName": "r_abschlussJahrOptions",
                "optionsNameFQ": "r_abschlussJahrOptions"
            }
        });
        super.onConstructionFinished();
    }
}