import { autoinject } from "aurelia-framework";

@autoinject
export class ZitMinutenTextValueConverter {
  toView(minuten: number) {
    if (!minuten) {
      return null;
    }

    const minutenProStd = 60;
    const std = Math.floor(minuten/ minutenProStd);
    const min = minuten % minutenProStd;

    if (std > 0 && min > 0) {
      return `${std} St. ${min} min.`;
    } else if (std > 0) {
      return `${std} St.`;
    } else {
      return `${min} min.`;
    }
  }
}
