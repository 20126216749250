import { autoinject } from "aurelia-framework";
import { DxWidget } from '../../framework/dx/elements/dx-widget';
import { EventAggregator } from 'aurelia-event-aggregator';
import { RestService } from '../../framework/base/services/rest-service';
import { StartupService } from './startup-service';
import * as DxLoader from "../../framework/dx/dx-loader";

@autoinject
export class TooltipService {
  private _isEnabled: boolean = false;
  private _onElementClick: any;
  private _supportedWidgetList: string[] = ["dxTextBox", "dxDateBox", "dxNumberBox", "dxRadioGroup", "dxSelectBox", "dxLookup", "dxCheckBox", "dxTextArea"];
  private _tooltips: any;

  constructor(
    private eventAggregator: EventAggregator,
    private rest: RestService,
    private startupService: StartupService
  ) {
    this._onElementClick = this.onElementClick.bind(this);  
    
    this._tooltips = {};

    this.startupService.startupInfo.TooltipList.forEach(tooltip => {
      this._tooltips[tooltip.Key] = tooltip.Tooltip;
    });

    this.eventAggregator.subscribe("dx-widget:attached", e => {
      const key = this.getWidgetKey(e.widget, e.widget.element);
      if (!key) {
        return;
      }

      const tooltip = this._tooltips[key];
      if (!tooltip) {
        return;
      }

      e.widget.createTooltip(tooltip);
    });
  }

  get isEnabled(): boolean {
    return this._isEnabled;
  }

  enable() {
    if (this.isEnabled) {
      return;
    }

    document.addEventListener("click", this._onElementClick);
    this._isEnabled = true;
  }
  disable() {
    if (!this.isEnabled) {
      return;
    }

    document.removeEventListener("click", this._onElementClick);
    this._isEnabled = false;
  }

  onElementClick(e) {
    const widget = this.getWidget(<any>e.target);
    if (!widget) {
      return;
    }

    this.eventAggregator.publish("tooltip:widget-changed", {
      tooltipWidget: widget
    });
  }
  getWidget(element: HTMLElement): ITooltipWidget {
    let el = element;
    while (el) {
      if (el.tagName == "DX-WIDGET") {
        break;
      }

      el = el.parentElement;
    }

    if (!el) {
      return null;
    }

    if (el.classList.contains("z--no-tooltip")) {
      return null;
    }

    const widget: any = el;

    const isSupported = widget.au 
      && widget.au.controller 
      && widget.au.controller.viewModel;

    if (!isSupported) {
      return null;
    }
    const dxWidget: DxWidget = widget.au.controller.viewModel;
    
    const key = this.getWidgetKey(dxWidget, el);
    if (!key) {
      return null;
    }

    return {
      widget: dxWidget,
      key: key,
      currentTooltip: this.getCurrentText(key)
    };
  }
  updateTooltip(widget: ITooltipWidget, newTooltip: string) {
    if (!widget || !widget.key) {
      return;
    }

    this.rest.post({
      url: this.rest.getApiUrl("ZIT/Tooltip/Post"),
      data: {
        Key: widget.key,
        Tooltip: newTooltip
      },
      increaseLoadingCount: true
    });

    this._tooltips[widget.key] = newTooltip;
    widget.widget.createTooltip(newTooltip);
  }

  showTooltip(target: Element, htmlText: string) {
    const options: DevExpress.ui.dxTooltipOptions = {
      target: target,
      position: {
        my: "top",
        at: "bottom"
      },
      contentTemplate: (container) => {
        const div = document.createElement("div");
        div.style.textAlign = "left";
        div.innerHTML = htmlText;
        container.appendChild(div);
      },
      onHidden: (e) => {
        element.remove();

        if (instance) {
          instance.dispose();
        }
      }
    };

    const element =  document.createElement("div");
    
    document.body.appendChild(element);

    const instance: DevExpress.ui.dxTooltip = DxLoader.createInstance("dxTooltip", element, options);
    instance.show();
  }

  private getWidgetKey(dxWidget: DxWidget, element: any): string {
    if (!dxWidget.parentViewUrl) {
      return null;
    }
    if (!dxWidget.id) {
      return null;
    }

    return dxWidget.parentViewUrl.concat(";", dxWidget.id);
  }
  private getCurrentText(key: string): string {
    return this._tooltips[key] || "";
  }
}

export interface ITooltipWidget {
  widget: DxWidget;
  key: string;
  currentTooltip: string;
}
