import * as moment from "moment";
import { FormEventService, FormUtilsService } from "../../../framework/forms/export";
import { autoinject } from "../../../framework/forms/form-export";
import { ScopeContainer } from "./../../../framework/base/classes/scope-container";
import { IDataSourceOptionFilter } from "./../../../framework/base/interfaces/data-source-option-filter";
import { RestService } from "./../../../framework/base/services/rest-service";
import { FormBase } from "./../../../framework/forms/classes/form-base";
import { ListView } from "./../../../framework/forms/elements/list-view/list-view";
import { IListViewOptions } from "./../../../framework/forms/elements/list-view/list-view-options";
import { IZitCardOptions, ZitCardTyp } from "./../../../zit-ui/elements/zit-card/zit-card-options";
import { IZitTagOptions } from "./../../../zit-ui/elements/zit-tag/zit-tag-options";
import { IZitToolbarOptions } from "./../../../zit-ui/elements/zit-toolbar/zit-toolbar-options";
import { EventZeitraumService } from "./../../services/event-zeitraum-service";
import { StartupService } from "./../../services/startup-service";
import { LocationService } from "../../../framework/base/services/location-service";
import { GlobalizationService } from "../../../framework/base/services/globalization-service";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { TaskQueue } from "aurelia-framework";
import { IdxSchedulerComponent, IdxScrollViewComponent } from "../../interfaces/dx-components";

@autoinject
export class Event {
  private _formSavedSubscription: {(): void};
  private _routerViewAnimationEndSubscription: Subscription;

  constructor(
    private _restService: RestService,
    private _startupService: StartupService,
    private _eventZeitraumService: EventZeitraumService,
    private _formEventService: FormEventService,
    private _formUtilsService: FormUtilsService,
    private _globalizationService: GlobalizationService,
    private _locationService: LocationService,
    private _eventAggregator: EventAggregator,
    private _taskQueue: TaskQueue) { }

  scopeContainer: ScopeContainer;
  mainForm: FormBase;
  ansichtTyp: EventAnsichtTyp;
  filter: IFilter;
  defaultFilter: IFilter;
  currentEventTypFilterList: IEventTypFilter[];
  schedulerDataSource: DevExpress.data.DataSource;

  eventAnsichtToolbarOptions: IZitToolbarOptions = {
    title: "event.ergebnis",
    smallToolbar: true,
    items: [{
      id: "showCardListToolbarOptionen",
      icon: "fas fa-th-list",
      classNameExpression: "ansichtTyp == 0 ? 'z--button-active' : ''",
      tooltip: "event-veranstaltung.uebersicht",
      execute: () => {
        this.ansichtTyp = EventAnsichtTyp.Card;
        this.reloadDataSource();
      }
    }, {
      id: "showKalenderListToolbarOptionen",
      icon: "far fa-calendar-alt",
      classNameExpression: "ansichtTyp == 1 ? 'z--button-active' : ''",
      tooltip: "event-veranstaltung.kalenderansicht",
      execute: () => {
        this.ansichtTyp = EventAnsichtTyp.Kalender;
        this.reloadDataSource();
      }
    }]
  };

  eventZeitraumCardOptions: IZitCardOptions = {
    isClickEnabled: true,
    useDefaultBindings: true,
    typ: ZitCardTyp.Kompakt
  };

  eventZeitraumListView: ListView;
  eventZeitraumListViewOptions: IListViewOptions = {
    useDefaultListItemStyle: false,
    itemClass: "col-xs-12 col-sm-6 col-lg-4 col-xl-3"
  };

  eventTypFilterTagOptions: IZitTagOptions = {
    textExpression: "item.BezeichnungExtern",
    backgroundColorExpression: `item.IsAktiv ? '${this._startupService.startupInfo.Skin.Farbe}' : ''`,
    colorExpression: "item.IsAktiv ? 'white' : 'black'",
    isDeleteClickEnabledExpression: "item.IsAktiv",
    onClick: (ev, tag: IEventTypFilter) => {
      ev.preventDefault();
      ev.stopPropagation();

      const indexOf = this.filter.idEventTypList.indexOf(tag.Id);
      if (indexOf >= 0) {
        return;
      }

      this.filter.idEventTypList.push(tag.Id);
      this.updateEventTypTagList();

      this.reloadDataSource();
    },
    onDeleteClick: (ev, tag: IEventTypFilter) => {
      ev.preventDefault();
      ev.stopPropagation();

      const indexOf = this.filter.idEventTypList.indexOf(tag.Id);
      if (indexOf < 0) {
        return;
      }

      this.filter.idEventTypList.splice(indexOf, 1);
      this.updateEventTypTagList();

      this.reloadDataSource();
    }
  };

  scheduler: IdxSchedulerComponent;
  schedulerOptions: DevExpress.ui.dxSchedulerOptions = {
    bindingOptions: {
      dataSource: "schedulerDataSource"
    },
    views: ["month"],
    currentView: "month",
    currentDate: new Date(),
    height: 600,
    textExpr: "Card.Betreff",
    descriptionExpr: "Card.Betreff",
    startDateExpr: "Card.Start",
    endDateExpr: "Card.Ende",
    editing: false,
    appointmentTooltipTemplate: (model) => {
      const data = model.appointmentData;
      
      const r = [];
      r.push(`<div style="font-weight: bold">${data.Card.Betreff}</div>`);
      
      if (data.Card.GeschaeftspartnerName) {
        r.push(`<div>${data.Card.GeschaeftspartnerName}</div>`);
      }
      if (data.Card.GeschaeftspartnerSchuleName) {
        r.push(`<div>${data.Card.GeschaeftspartnerSchuleName}</div>`);
      }

      r.push(`<div>${this._globalizationService.format(data.Card.Start, "g")} - ${this._globalizationService.format(data.Card.Ende, "g")}</div>`)
      
      if (data.Card.ort) {
        r.push(`<div>${data.Card.Ort}</div>`);
      }
      
      return r.join("");
    },
    onAppointmentFormOpening: (e) => {
      e.cancel = true;
    },
    onAppointmentClick: (e) => {
      this._locationService.goTo({
        url: `Objekte/EventZeitraum/${e.appointmentData.Id}`,
        currentViewModel: this.mainForm
      });
      
      e.cancel = true;
    },
    onAppointmentDblClick: (e) => {
      e.cancel = true;
    }
  };

  bind(bindingContext) {
    this.mainForm = bindingContext;

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.filter = {
      idEventTypList: []
    };
    this.defaultFilter = {
      idEventTypList: []
    };

    this._formSavedSubscription = this._formEventService.onSaved.register(async(args) => {
      const model = args.form.models.modelWithKeyId;
      if (!model) {
        return;
      }

      switch (model.webApiAction) {
        case "ZIT/Objekte/Event":
        case "ZIT/Objekte/EventZeitraum": {
          this.reloadDataSource();
          break;
        }
        default: {
          break;
        }
      }
    });
    
    this._routerViewAnimationEndSubscription = this._eventAggregator.subscribe("router:view-animation-end", (e) => {
      this._taskQueue.queueTask(() => {
        if (this.scheduler && this.scheduler.instance) {
          this.scheduler.instance.repaint();
        }
      });
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    
    this._formSavedSubscription();
    this._formSavedSubscription = null;
  }

  async attached() {
    await this.setEventTypList();
    this.setDefaultFilter();
    this.updateEventTypTagList();

    this.ansichtTyp = EventAnsichtTyp.Card;
    this.setEventZeitraumDataSources();
  }

  private reloadDataSource() {
    if (this.ansichtTyp == EventAnsichtTyp.Card) {
      this.eventZeitraumListViewOptions.dataSource.reload();
    } else {
      this.schedulerDataSource.reload();
    }
  }

  private setEventZeitraumDataSources() {
    this.schedulerDataSource = this.createEventDataSource();
    this.eventZeitraumListViewOptions.dataSource = this.createEventDataSource();
  }

  private createEventDataSource() {
    return this._eventZeitraumService.createEventZeitraumDataSource(this.scopeContainer,
      () => {
        const where: any[] = [
          ["Event.Kopf.ShowVeranstaltungsmaske", true],
          [["Kopf.KontingentGesamt", "null"], "or", ["Kopf.KontingentGesamt", ">", 0]]];

        if (this.ansichtTyp == EventAnsichtTyp.Card) {
          where.push(["Kopf.Ende",
            ">=",
            moment(new Date())
              .startOf("day")
              .toDate()
          ]);
        }

        const filter = this.filter.idEventTypList.length == 0 ? this.defaultFilter : this.filter;

        if (filter.idEventTypList.length > 0) {
          const tempIdEventTypListWhere = [];
          for (let i = 0; i < filter.idEventTypList.length; i++) {
            tempIdEventTypListWhere.push(["Event.Kopf.IdEventTyp", filter.idEventTypList[i]]);
            if (i + 1 < filter.idEventTypList.length) {
              tempIdEventTypListWhere.push("or");
            }
          }
          where.push(tempIdEventTypListWhere);
        }
        return where;
      },
      () => {
        const filter: IDataSourceOptionFilter[] = [];
        filter.push({
          webApiCustomKey: "AddEventZeitraumCard",
          webApiCustomValue: "true"
        }, {
          webApiCustomKey: "ObjektStatusSammlungCode",
          webApiCustomValue: "'AKTIV'"
        });
        return filter;
      },
      [{ columnName: "Kopf.Start", sortOrder: 0 }, { columnName: "DisplayText", sortOrder: 0 }]
    );
  }

  private async setEventTypList() {
    this.currentEventTypFilterList = await this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Stammdaten/EventTyp"),
      getOptions: {
        columns: ["Id", "BezeichnungExtern"],
        customs: [
          { key: "ShowInVerstaltungsmaske", value: true }
        ]
      }
    });
  }

  private updateEventTypTagList() {
    if (!this.currentEventTypFilterList) {
      return;
    }

    for (const tag of this.currentEventTypFilterList) {
      tag.IsAktiv = this.filter.idEventTypList.indexOf(tag.Id) >= 0;
    }
  }

  private setDefaultFilter() {
    for (const tag of this.currentEventTypFilterList) {
      this.defaultFilter.idEventTypList.push(tag.Id);
    }
  }
}

export interface IFilter {
  idEventTypList: number[];
}

export interface IEventTypFilter {
  Id: number;
  Bezeichnung: string;
  IsAktiv: boolean;
}

export enum EventAnsichtTyp {
  Card = 0,
  Kalender = 1
}
