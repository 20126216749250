import { autoinject, transient } from "aurelia-framework";
import { AuthorizationService, LocalizationService } from "../../../../../framework/base/export";
import { LocationService } from "../../../../../framework/base/services/location-service";
import { FormBase } from "../../../../../framework/forms/export";
import { Datenschutz } from "../../../../../zit/elements/datenschutz/datenschutz";
import { BrowserService } from "./../../../../../framework/base/services/browser-service";
import { RestService } from "./../../../../../framework/base/services/rest-service";
import { ICommandData } from "./../../../../../framework/forms/interfaces/command-data";
import { DomaeneService } from "./../../../../services/domaene-service";

@autoinject
@transient()
export class ActivateAccountFuncs {
  private form: FormBase;
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public browserService: BrowserService,
    private _authorizationService: AuthorizationService,
    private _localizationService: LocalizationService,
    private _restService: RestService,
    private _locationService: LocationService,
    private _domaeneService: DomaeneService) {
    const time = new Date()
      .getTime()
      .toString();
    const randomNumberString = Math.floor((Math.random() * 100))
      .toString();
    const randomKey = time + randomNumberString;
    this.logoSrc = this._restService.getApiUrl(`ZIT/Domaene/Logo?key=${randomKey}`);
  }

  urlImpressum: string;
  farbeDomaene: string;
  bezeichnungLoginMobil: string;
  activationKey: string;
  logoSrc: string;
  isMobile: boolean;

  datenschutzList: any[];

  activateCommand: ICommandData = {
    id: "$login",
    title: "activate-account-form-funcs.account_aktivieren_caption",
    execute: async (e) => {
      if (this.form.models.data.$m_activateAccount.Passwort1 !== this.form.models.data.$m_activateAccount.Passwort2) {
        DevExpress.ui.notify(this._localizationService.translateOnce("activate-account-form-funcs.password_missmatch"), "error", this.NOTIFY_TIMEOUT);
        return;
      }

      const datenschutz: Datenschutz = this.form["r_datenschutz"];
      if (datenschutz && !datenschutz.hasAlleBestaetigt()) {
        return;
      }

      this.form.models.data.$m_activateAccount.CodeAktivierung = this.activationKey;
      this.form.models.data.$m_activateAccount.DatenschutzList = this.datenschutzList;

      const r = await this._restService.post({
        url: this._restService.getApiUrl("ZIT/Security/Aktivierung"),
        data: this.form.models.data.$m_activateAccount,
        increaseLoadingCount: true
      });

      this._authorizationService.login({
        Username: this.form.models.data.$m_activateAccount.Benutzername,
        Password: this.form.models.data.$m_activateAccount.Passwort1
      });
    }
  };

  backToLoginCommand: ICommandData = {
    id: "$backToLogin",
    title: "activate-account-form-funcs.back_to_login_caption",
    execute: () => {
      this.goBackToLogin();
    }
  };

  openImpressumClick() {
    window.open(this.urlImpressum);
  }

  registerForm(form: FormBase) {
    this.form = form;

    form.onActivate.register((r) => {
      if (r && r.parameters) {
        this.activationKey = r.parameters.key;
      }

      return Promise.resolve();
    });
  }

  async bind() {
    this.form.focusFirstEnabledEditor = () => {
      if (this.form["password"].instance) {
        this.form["password"].instance.focus();
      }
    };

    if (!this.activationKey) {
      this.goBackToLogin();
      return;
    }

    this.form.callOnBind(() => {
      if (!this.form.models.data.$m_activateAccount) {
        this.form.models.data.$m_activateAccount = {};
      }

      this.loadBenutzername();
      this.loadDatenschutzUndEinverstaendnis();
    });

    this.urlImpressum = this._domaeneService.urlImpressum;
    this.farbeDomaene = this._domaeneService.farbeDomaene;
    this._domaeneService.setDomainColorsStyle();
    this.bezeichnungLoginMobil = this._domaeneService.bezeichnungMobilLogin;
  }

  private goBackToLogin() {
    this._locationService.goTo({
      url: "Login",
      clearStack: true
    });
  }

  private async loadBenutzername() {
    if (!this.activationKey) {
      return;
    }

    const result = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Security/AktivierungBenutzername"),
      ignoreErrors: true,
      data: {
        CodeAktivierung: this.activationKey
      }
    });

    if (result && result.Benutzername) {
      this.form.models.data.$m_activateAccount.Benutzername = result.Benutzername;
    } else {
      this._locationService.goTo({
        url: "Login",
        clearStack: true,
        customOptions: {
          activationKey: this.activationKey
        }
      });
    }
  }

  private async loadDatenschutzUndEinverstaendnis() {
    const result = await this._restService.get({
      url: this._restService.getApiUrl("ZIT/Security/DatenschutzUndEinverstaendnis")
        .concat("?codeAktivierung=")
        .concat(this.activationKey)
    });

    if (!result) {
      return;
    }

    this.datenschutzList = result;
  }
}
