import * as fwx from "../../../../framework/forms/form-export";
import {
    DokumentReadUtils
} from "./dokument-read-utils";

@fwx.autoinject
export class DokumentReadForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_DokumentUtils: DokumentReadUtils) {
        super(element, formBaseImport);
        this.id = "dokument-read";
        this.title = "dokument-read.dokument-read_caption";
        this.addModel({
            "id": "$m_Dokument",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "webApiExpand": {
                Informationstyp: null,
                Zustand: null
            },
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": false,
            "allowNew": "false",
            "allowDelete": "false",
            "filters": []
        });
        this.addEditPopup({
            "idContent": "dokumentVersionInformationEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_Dokument",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_Dokument.Id"
                }
            }, {
                "to": "$v_versionKey",
                "binding": {
                    "dataContext": "$m_Dokument",
                    "bindTo": "VersionKey",
                    "bindToFQ": "models.data.$m_Dokument.VersionKey"
                }
            }, {
                "to": "$v_isReadOnly",
                "binding": {
                    "bindTo": "true",
                    "bindToFQ": "true"
                }
            }],
            "id": "dokumentVersionInformationEditPopup",
            "options": {
                "optionsName": "dokumentVersionInformationEditPopupOptions",
                "optionsNameFQ": "dokumentVersionInformationEditPopupOptions"
            },
            "height": "250px",
            "width": "500px",
            "commands": []
        });
        this.addFunction("$f_DokumentUtils", $f_DokumentUtils, "functions.$f_DokumentUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}