import { Erklaerungsvideo } from './../elements/erklaerungsvideo/erklaerungsvideo';
import { autoinject } from "aurelia-framework";
import { EventAggregator } from 'aurelia-event-aggregator';
import { ICommandData, ICommand } from '../../framework/forms/export';
import { StartupService } from './startup-service';
import { FormEvalService } from './form-eval-service';
import { IErklaerungsvideo } from '../interfaces/export';
import { BrowserService } from '../../framework/base/export';
import { ErklaerungsvideoAnzeigeEnum } from '../enumerations/erklaerungsvideo-anzeige-enum';

@autoinject
export class ErklaervideoService {
  private info: any = {};

  constructor(
    private eventAggregator: EventAggregator,
    private startupService: StartupService,
    private formEvalService: FormEvalService,
    private browserService: BrowserService
  ) {
    this.eventAggregator.subscribe("startup-info:loaded", () => {
      this.init();
    });
  }

  element: Erklaerungsvideo;

  init() {
    this.info = {};

    if (!this.startupService.startupInfo || !this.startupService.startupInfo.ErklaerungsvideoLayoutList) {
      return;
    }

    for (let item of this.startupService.startupInfo.ErklaerungsvideoLayoutList) {
      const key = this.formEvalService.convertIdFormular(item.Key, false) || item.Key;

      if (!this.info[key]) {
        this.info[key] = [];
      }

      this.info[key].push(item);
    }
  }
  attachedToolbarButton(moduleId: string, addCommand: { (commandData: ICommandData) }) {
    const videoList: IErklaerungsvideo[] = this.getContextMenuItems(moduleId);

    if (videoList.length == 0) {
      return;
    }

    const commanData = this.getErklaervideoCommandData();
    commanData.execute = e => {
      if (!this.element) {
        return;
      }

      this.element.show(moduleId, e.event.target);
    };

    addCommand(commanData);

    return;
  }

  getErklaervideoCommandData(): ICommandData {
    return {
      id: "erklaervideo",
      title: "zit.erklaerung",
      icon: "fas fa-video",
      className: "z--button-active",
      sortIndex: 1000
    };
  }
  getContextMenuItems(moduleId: string): IErklaerungsvideo[] {
    const videoList: IErklaerungsvideo[] = this.info[moduleId];
    if (!videoList) {
      return [];
    }

    return this.getVideoList(videoList);
  }

  getErklaerungsvideoDatasource(): any[] {
    const datasource: any[] = [];

    this.startupService.startupInfo.ErklaerungsvideoLayoutList.forEach(element => {
      const item = datasource.filter(c => c.IdErklaerungsvideo == element.IdErklaerungsvideo);
      if (!item || item.length == 0) {
        const datasourceElement = element;
        datasourceElement.Bezeichnung = this.erklaerungsvideoElementBezeichnung(datasourceElement);

        datasource.push(datasourceElement);
      }
    });

    return this.getVideoList(datasource);
  }

  getErklaerungsvideo(code: string): IErklaerungsvideo {
    if (!code || code.length == 0) {
      return;
    }

    const list = this.getContextMenuItems(code);
    if (!list || list.length == 0) {
      return;
    }

    return list[0];
  }

  erklaerungsvideoElementBezeichnung(erklaerungsvideo: IErklaerungsvideo): string {
    return this.element.erklaerungsvideoElementBezeichnung(erklaerungsvideo);
  }
  erklaerungsvideoElementIcon(erklaerungsvideo: IErklaerungsvideo): string {
    return this.element.erklaerungsvideoElementIcon(erklaerungsvideo);
  }
  erklaerungsvideoAction(erklaerungsvideo: IErklaerungsvideo): any {
    return this.element.erklaerungsvideoAction(erklaerungsvideo);
  }

  private getVideoList(videoList: IErklaerungsvideo[]): IErklaerungsvideo[] {
    const isMobile = this.browserService.isXS;
    const isDesktop = !isMobile;

    return videoList.filter(v => {
      return v.Anzeige == void (0)
        || (v.Anzeige === ErklaerungsvideoAnzeigeEnum.NurAufMobilgeraeten && isMobile)
        || (v.Anzeige === ErklaerungsvideoAnzeigeEnum.NurAufDesktopGeraeten && isDesktop);
    });
  }
}
