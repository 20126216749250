import { autoinject } from 'aurelia-framework';
import { IZitTagOptions } from '../elements/zit-tag/export';

@autoinject
export class ZitTagService {
  private _tagDic: any = {};
  constructor() {
    this.load();
  }

  getTagOptions(optionsName: string): IZitTagOptions {
    return Object.assign({}, this._tagDic[optionsName]);
  }

  private load() {
    this._tagDic.adresse = <IZitTagOptions>{
      isClickEnabled: true,
      hasDefaultInfo: true,
      icon: {
        textExpression: 'item.Adresstyp.TagText ? item.Adresstyp.TagText : item.Adresstyp.Bezeichnung[0]'
      },
      textExpression: `item | zitAddress:"short:street"`
    };

    this._tagDic.ansprechperson = <IZitTagOptions>{
      icon: {
        text: "K"
      },
      textExpression: "item.GesamtNameMitTitel",
      infoList: [
        {
          isVisibleExpression: `item.IsInaktiv`,
          icon: {
            faIcon: "far fa-trash-alt",
          }
        }
      ]
    };

    this._tagDic.ausbildung = <IZitTagOptions>{
      hasDefaultInfo: true,
      textExpression: "item.Ausbildung.Bezeichnung",
      icon: {
        faIconExpression: "item.Ausbildung.TagSymbol ? item.Ausbildung.TagSymbol : null",
        textExpression: "item.Ausbildung.TagText ? item.Ausbildung.Tagtext : 'A'"
      },
      infoList: [{
        isVisibleExpression: "item.AusbildungAbschluss.TagSymbol",
        icon: {
          faIconExpression: "item.AusbildungAbschluss.TagSymbol"
        }
      }]
    };

    this._tagDic.benutzergruppe = <IZitTagOptions>{
      hasDefaultInfo: true,
      icon: {
        faIcon: "fas fa-users"
      },
      textExpression: `item.Bezeichnung`
    };
    this._tagDic.bankverbindung = <IZitTagOptions>{
      isClickEnabled: true,
      hasDefaultInfo: true,
      icon: {
        faIcon: "far fa-money-bill-alt"
      },
      textExpression: `item | zitBankverbindung:"IBAN"`
    };

    this._tagDic.benutzerrolleCode = <IZitTagOptions>{
      icon: {
        faIconExpression: "item.TagSymbol ? item.TagSymbol : ''",
        textExpression: "item.tagText ? item.tagText : ''"
      },
      textExpression: "item.Bezeichnung",
      onClick: (e, data) => {
        data.IsAktiv = !data.IsAktiv;
      },
      infoList: [{
        icon: {
          faIconExpression: "item.IsAktiv ? 'fas fa-check' : 'fas fa-times'",
          backgroundColorExpression: "item.IsAktiv ? 'green':'red'"
        }
      }]
    };

    this._tagDic.berufserfahrung = <IZitTagOptions>{
      hasDefaultInfo: true,
      icon: {
        text: "E"
      },
      textExpression: `item.Bis 
      ? item.Unternehmen + ": " + globalization.format(item.Von, "d") + " - " + globalization.format(item.Bis, "d") 
      : item.Unternehmen + ": " + "seit " + globalization.format(item.Von, "d")`,
    };

    this._tagDic.einsatzbereich = <IZitTagOptions>{
      icon: {
        text: "EB"
      },
      textExpression: `item.Einsatzbereich && item.Einsatzbereich.Einsatzbereich
      ? item.Einsatzbereich.Einsatzbereich
      : item.Wunschbereich`,
    };

    this._tagDic.email = <IZitTagOptions>{
      icon: {
        text: "E"
      },
      textExpression: `item`,
      onClick: (e, email) => {
        window.location.href = `mailto:${email}`;
      }
    };

    this._tagDic.website = <IZitTagOptions>{
      icon: {
        text: "W"
      },
      textExpression: `item | zitRestrictLength:75`,
      onClick: (e, website) => {
        if (!website.match(/^https?:\/\//i)) {
          website = `http://${website}`;
      }
        window.open(`${website}`, "_blank");
      }
    };

    this._tagDic.geschaeftspartner = <IZitTagOptions>{
      isClickEnabled: true,
      hasDefaultInfo: true,
      icon: {
        text: "GP"
      },
      textExpression: `item.Geschaeftspartner.Name1 
      ? item.Geschaeftspartner.Adressen.Adressen[0] 
        ? item.Geschaeftspartner.Name1 + ", " + item.Geschaeftspartner.Adressen.Adressen[0].Ort
        : item.Geschaeftspartner.Name1
      : ""`
    };

    this._tagDic.kontakt = <IZitTagOptions>{
      isClickEnabled: true,
      hasDefaultInfo: true,
      icon: {
        faIconExpression: "item.Kontakttyp.TagSymbol ? item.Kontakttyp.TagSymbol : item.Kontakttyp.Bezeichnung",
        textExpression: `item.Kontakttyp 
        ? item.Kontakttyp.TagSymbol
          ? item.Kontakttyp.TagText 
          : item.Kontakttyp.Bezeichnung
            ? item.Kontakttyp.Bezeichnung[0] 
            : "K"
        : "K"`
      },
      textExpression: `item.Wert1 
      ? item.Wert2
        ? item.Wert1 + " " + item.Wert2
        : item.Wert1
      : ""`,
      infoList: [{
        isVisibleExpression: "item.Wichtig",
        icon: {
          faIcon: "fas fa-exclamation"
        }
      }]
    };

    this._tagDic.package = <IZitTagOptions>{
      isClickEnabled: true,
      icon: {
        text: "P"
      },
      textExpression: `item.Bezeichnung + ", gültig bis: " + globalization.format(item.GueltigBis, "d")`
    };

    this._tagDic.sprache = <IZitTagOptions>{
      hasDefaultInfo: true,
      icon: {
        text: "S"
      },
      textExpression: `item.Sprachniveau 
      ? item.Sprachniveau.Bezeichnung + " | " + item.Sprache.Bezeichnung
      : item.Sprache.Bezeichnung`,
      infoList: [{
        isVisibleExpression: "item.Sprachniveau.TagSymbol",
        icon: {
          faIconExpression: "item.Sprachniveau.TagSymbol"
        }
      }]
    };
  }
}
