import { autoinject, observable, bindable, computedFrom } from "aurelia-framework";
import { NachrichtBenutzer } from '../nachricht-benutzer/nachricht-benutzer';
import { BrowserService } from '../../../framework/base/export';

import * as moment from "moment";
import { INachrichtBenutzerInfo } from '../../services/nachricht-service';
import { NachrichtProtokoll } from '../nachricht-protokoll/nachricht-protokoll';
import { NachrichtMassennachricht } from '../nachricht-massennachricht/nachricht-massennachricht';

@autoinject
export class NachrichtContainer {
  constructor(
    private _element: Element,
    private _browserService: BrowserService
  ) {
    this.isSmallDevice = this._browserService.isXS
      || this._browserService.isSM;
  }

  benutzerInfo: INachrichtBenutzerInfo;
  keyNachricht: string;

  @bindable benutzerInfoFix: INachrichtBenutzerInfo;
  @observable isStellvertreter: boolean = false;
  @observable nurUngeleseneNachrichten: boolean = false;
  @observable benutzer: string;

  nachrichtBenutzer: NachrichtBenutzer;
  nachrichtProtokoll: NachrichtProtokoll;
  nachrichtMassennachricht: NachrichtMassennachricht;

  visibilityContext: VisiblityContext = VisiblityContext.Benutzer;
  
  isSmallDevice: boolean = false;

  @computedFrom("isSmallDevice", "isNachrichtenVisible", "benutzerInfoFix")
  get isBreadcrumpVisible() {
    return this.isSmallDevice
      && this.isNachrichtenVisible
      && !this.benutzerInfoFix;
  }
  @computedFrom("benutzerInfoFix", "isSmallDevice", "visibilityContext")
  get isBenutzerVisible() {
    if (this.benutzerInfoFix) {
      return false;
    }

    if (!this.isSmallDevice) {
      return true;
    }

    return this.visibilityContext == VisiblityContext.Benutzer;
  }
  @computedFrom("isSmallDevice", "visibilityContext")
  get isNachrichtenVisible() {
    if (!this.isSmallDevice) {
      return true;
    }

    return this.visibilityContext == VisiblityContext.Nachrichten;
  }
  @computedFrom("benutzerInfoFix", "benutzerInfo")
  get benutzerInfoValidated() {
    return this.benutzerInfoFix || this.benutzerInfo;
  }

  bind() {
    if (this.benutzerInfoFix) {
      this.changeVisibilityContext(VisiblityContext.Nachrichten);

      const event = new CustomEvent("on-benutzer-click", {
        detail: {
          sender: this,
          benutzerInfo: this.benutzerInfoValidated
        },
        bubbles: true
      });
  
      this._element.dispatchEvent(event);
    } else {
      this.changeVisibilityContext(VisiblityContext.Benutzer);
    }
  }

  benutzerChanged() {
    this.reloadBenutzer();
  }
  isStellvertreterChanged() {
    this.reloadBenutzer();
  }
  nurUngeleseneNachrichtenChanged() {
    this.reloadBenutzer();
  }

  changeVisibilityContext(context: VisiblityContext) {
    if (!this.isSmallDevice) {
      return;
    }

    this.visibilityContext = context;

    if (context == VisiblityContext.Benutzer) {
      this.dispatchBenutzerCleared();
    }
  }
  reloadBenutzer() {
    if (!this.nachrichtBenutzer) {
      return;
    }

    this.keyNachricht = null;
    this.benutzerInfo = null;
    this.dispatchBenutzerCleared();
    this.nachrichtBenutzer.reload();
  }

  onBenutzerClick(e) {
    this.benutzerInfo = e.detail.benutzerInfo;
    this.keyNachricht = null;

    this.changeVisibilityContext(VisiblityContext.Nachrichten);
  }
  onNachrichtClick(e) {
    this.keyNachricht = e.detail.keyNachricht;
  }
  onNachrichtProtokollClick(e: CustomEvent) {
    this.nachrichtProtokoll.showPopup(e.detail.nachricht);
  }
  onNachrichtMassennachrichtClick(e: CustomEvent) {
    this.nachrichtMassennachricht.showPopup(e.detail.nachricht);
  }

  private dispatchBenutzerCleared() {
    this._element.dispatchEvent(new CustomEvent(
      "on-benutzer-cleared", {
        bubbles: true,
        detail: {}
      }
    ));
  }
}

enum VisiblityContext {
  Benutzer = 0,
  Nachrichten = 1
}
