import {
  autoinject,
  valueConverter
} from "aurelia-framework";
import { FileService } from '../../services/file-service';

@autoinject
@valueConverter("backgroundImage")
export class BackgroundImageValueConverter{
  constructor(
    private fileService: FileService
  ) {}

  toView(value: any) {
    if (!value) {
      return "";
    }

    const url = this.fileService.getDownloadUrl(value);
    return `background-image: url(${url})`;
  }
}
