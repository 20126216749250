import * as fwx from "../../../../framework/forms/form-export";
import {
    TerminEditUtils
} from "./termin-edit-utils";

@fwx.autoinject
export class TerminHeartbaseEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_TerminEditUtils: TerminEditUtils) {
        super(element, formBaseImport);
        this.id = "termin-heartbase-edit";
        this.title = "termin-heartbase-edit.termin-heartbase-edit_caption";
        this.addModel({
            "id": "$m_Termin",
            "webApiAction": "ZIT/Objekte/Termin",
            "webApiExpand": {
                'Empfaenger': {
                    'expand': {
                        'Person': null
                    }
                },
                'Trainer': {
                    'expand': {
                        'Ansprechperson': null
                    }
                },
                'Kopf': {
                    'expand': {
                        'Benutzer': null,
                        'TerminDateien': null,
                        'TerminBestaetigungZustand': null,
                        'Lehrberufe': null,
                        'Stelleninserate': {
                            'expand': {
                                'Stelleninserat': {
                                    'columns': [
                                        'Id'
                                    ]
                                }
                            }
                        }
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "addVerteilungstypVeroeffentlichenEmpfaenger",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddStelleninseratCard",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "addEinschaetzung",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_TerminEmpfaengerElement",
            "webApiAction": "ZIT/Objekte/TerminEmpfaengerElement",
            "webApiExpand": {
                'Person': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ZustandAendern",
            "webApiAction": "ZIT/Stammdaten/Zustand",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Lehrberuf",
            "webApiAction": "ZIT/Stammdaten/Lehrberuf",
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "idTerminKopfElement",
                "webApiCustomValue": "models.data.$m_Termin.IdTerminKopfElement"
            }]
        });
        this.addModel({
            "id": "$m_TerminEmpfEinschEdit",
            "webApiAction": "ZIT/Objekte/TerminEmpfEinschElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_TerminEditUtils.actionCommand",
                "bindToFQ": "functions.$f_TerminEditUtils.actionCommand"
            }
        });
        this.addEditPopup({
            "idContent": "zustandAendernEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_ZustandAendern",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_ZustandAendern.Id"
                }
            }, {
                "to": "$v_zustandDatum",
                "binding": {
                    "dataContext": "$m_Termin",
                    "bindTo": "Kopf.TerminBestaetigungZustandDatum",
                    "bindToFQ": "models.data.$m_Termin.Kopf.TerminBestaetigungZustandDatum",
                    "propertyPrefix": "Kopf"
                }
            }, {
                "to": "$v_idTermin",
                "binding": {
                    "dataContext": "$m_Termin",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_Termin.Id"
                }
            }],
            "id": "zustandAendernEditPopup",
            "options": {
                "optionsName": "zustandAendernEditPopupOptions",
                "optionsNameFQ": "zustandAendernEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "terminEmpfEinschEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_TerminEmpfEinschEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_TerminEmpfEinschEdit.Id"
                }
            }, {
                "to": "$v_idTermin",
                "binding": {
                    "dataContext": "$m_Termin",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_Termin.Id"
                }
            }],
            "id": "terminEmpfEinschEditPopup",
            "options": {
                "optionsName": "terminEmpfEinschEditPopupOptions",
                "optionsNameFQ": "terminEmpfEinschEditPopupOptions"
            },
            "height": "90vh",
            "width": "800px",
            "commands": []
        });
        this.addFunction("$f_TerminEditUtils", $f_TerminEditUtils, "functions.$f_TerminEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "termin-heartbase-edit.r_betreff_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.Betreff",
                "bindToFQ": "models.data.$m_Termin.Kopf.Betreff",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_betreff",
            "options": {
                "optionsName": "r_betreffOptions",
                "optionsNameFQ": "r_betreffOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "termin-heartbase-edit.r_kurzbezeichnung_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.Kurzbezeichnung",
                "bindToFQ": "models.data.$m_Termin.Kopf.Kurzbezeichnung",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_kurzbezeichnung",
            "options": {
                "optionsName": "r_kurzbezeichnungOptions",
                "optionsNameFQ": "r_kurzbezeichnungOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "termin-heartbase-edit.r_ort_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.Ort",
                "bindToFQ": "models.data.$m_Termin.Kopf.Ort",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_ort",
            "options": {
                "optionsName": "r_ortOptions",
                "optionsNameFQ": "r_ortOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "termin-heartbase-edit.r_startdatum_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.StartDatum",
                "bindToFQ": "models.data.$m_Termin.Kopf.StartDatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_TerminEditUtils.onStartDatumChanged",
            "id": "r_startDatum",
            "options": {
                "optionsName": "r_startDatumOptions",
                "optionsNameFQ": "r_startDatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "termin-heartbase-edit.r_startuhrzeit_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.StartUhrzeit",
                "bindToFQ": "models.data.$m_Termin.Kopf.StartUhrzeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_startUhrzeit",
            "options": {
                "optionsName": "r_startUhrzeitOptions",
                "optionsNameFQ": "r_startUhrzeitOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "termin-heartbase-edit.r_endedatum_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.EndeDatum",
                "bindToFQ": "models.data.$m_Termin.Kopf.EndeDatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_endeDatum",
            "options": {
                "optionsName": "r_endeDatumOptions",
                "optionsNameFQ": "r_endeDatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "termin-heartbase-edit.r_endeuhrzeit_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.EndeUhrzeit",
                "bindToFQ": "models.data.$m_Termin.Kopf.EndeUhrzeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_endeUhrzeit",
            "options": {
                "optionsName": "r_endeUhrzeitOptions",
                "optionsNameFQ": "r_endeUhrzeitOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "geschaeftspartner",
            "filters": [],
            "caption": "termin-heartbase-edit.r_geschaeftspartner_caption",
            "isReadOnlyExpression": "models.data.$m_Termin.Id",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.IdGeschaeftspartner",
                "bindToFQ": "models.data.$m_Termin.Kopf.IdGeschaeftspartner",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartner",
            "options": {
                "optionsName": "r_geschaeftspartnerOptions",
                "optionsNameFQ": "r_geschaeftspartnerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "terminTyp",
            "filters": [],
            "caption": "termin-heartbase-edit.r_termintyp_caption",
            "isReadOnlyExpression": "models.data.$m_Termin.Kopf.Id",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.IdTerminTyp",
                "bindToFQ": "models.data.$m_Termin.Kopf.IdTerminTyp",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_terminTyp",
            "options": {
                "optionsName": "r_terminTypOptions",
                "optionsNameFQ": "r_terminTypOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 0,
            "acceptType": "image/*",
            "height": "250px",
            "showClearButton": false,
            "caption": "termin-heartbase-edit.r_dmslinklogo_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.DMSLinkLogo",
                "bindToFQ": "models.data.$m_Termin.Kopf.DMSLinkLogo",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_dmsLinkLogo",
            "options": {
                "optionsName": "r_dmsLinkLogoOptions",
                "optionsNameFQ": "r_dmsLinkLogoOptions"
            }
        });
        super.onConstructionFinished();
    }
}