import { IOptions } from './../../../framework/forms/widget-options/options';
import { ObjektInfoService } from './../../services/objekt-info-service';
import { LocationService } from './../../../framework/base/services/location-service';
import { SortOrderColumnEnum } from './../../../framework/forms/enums/sort-order-column-enum';
import { DxWidget } from './../../../framework/dx/elements/dx-widget';
import { observable } from 'aurelia-framework';
import { RestService } from './../../../framework/base/services/rest-service';
import { autoinject, Scope, OverrideContext, bindable, computedFrom } from 'aurelia-framework';
import { ScopeContainer, LocalizationService } from '../../../framework/base/export';
import { ICommandData, SimpleWidgetCreatorService, DialogConfirmService } from '../../../framework/forms/export';
import { IdxPopupComponent, IdxValidationGroupComponent, IdxCheckBoxComponent, IdxSelectBoxComponent, IdxRadioGroupComponent } from '../../interfaces/export';
import { IDokumentLautVorlageErstellenData } from '../../interfaces/dokument-laut-vorlage-erstellen-data';

@autoinject
export class DokumentLautVorlageErstellen {
  constructor(
    private localization: LocalizationService,
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private rest: RestService,
    private location: LocationService,
    private objektInfo: ObjektInfoService,
    private dialogConfirmService: DialogConfirmService
  ) {}

  scopeContainer: ScopeContainer;
  fullname: string;
  keyList: number[] = [];

  requiredValidationRule = { type: "required", message: "" };

  popupCommands: ICommandData[] = [
    {
      id: "doDokumentLautVorlageErstellen",
      idCategory: "$start",
      icon: "fas fa-check",
      tooltip: "dokument-laut-vorlage-erstellen.erstellen",
      sortIndex: 1600,
      execute: () => {
        this.checkAndDoDokumentLautVorlageErstellen();
      }
    }
  ];

  dokumentLautVorlageErstellenPopup: IdxPopupComponent;
  dokumentLautVorlageErstellenPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    width: "800px",
    maxHeight: "80%",
    maxWidth: "800px"
  }

  dokumenteLautVorlageRadioGroup: IdxRadioGroupComponent;
  dokumenteLautVorlageRadioGroupOptions: DevExpress.ui.dxRadioGroupOptions = {
    valueExpr: "value",
    displayExpr: "text",
    layout: "horizontal",
    dataSource: [
      { text: this.localization.translateOnce("dokument-laut-vorlage-erstellen.typ"), value: 0 },
      { text: this.localization.translateOnce("dokument-laut-vorlage-erstellen.paket"), value: 1 }
    ],
    value: 0,
    onValueChanged: () => {
      this.onDokumenteLautVorlageRadioGroupValueChanged();
    }
  };

  vorlageSelectBox: IdxSelectBoxComponent;
  vorlageSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    searchEnabled: true,
    showClearButton: true,
    searchExpr: ["Bezeichnung"],
    valueExpr: "Id",
    displayExpr: "Bezeichnung"
  };

  druckerAuswahlSelectBox: IdxSelectBoxComponent;
  druckerAuswahlSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    showClearButton: true,
    valueExpr: "Druckername",
    displayExpr: "Druckername"
  };

  createOnlyIfNotExistsCheckBox: IdxCheckBoxComponent;
  createOnlyIfNotExistsCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: this.localization.translateOnce("dokument-laut-vorlage-erstellen.erstellen-wenn-nicht-vorhanden"),
    value: true
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "dokumentLautVorlageErstellenPopupToolbar",
      caption: "dokument-laut-vorlage-erstellen.popup-title",
      options: this.dokumentLautVorlageErstellenPopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  showPopup(keyList: number[], fullname: string) {
    this.keyList = keyList;
    this.fullname = fullname;

    this.resetInputData();
    this.onDokumenteLautVorlageRadioGroupValueChanged();
    this.setDruckerDataSource();

    this.dokumentLautVorlageErstellenPopup.instance.show();
  }

  private resetInputData(): void {
    if (this.dokumenteLautVorlageRadioGroup) {
      this.dokumenteLautVorlageRadioGroup.instance.option("value", 0);
    } else {
      this.dokumenteLautVorlageRadioGroupOptions.value = 0;
    }

    if (this.vorlageSelectBox) {
      this.vorlageSelectBox.instance.option("value", null);
    } else {
      this.vorlageSelectBoxOptions.value = null;
    }

    if (this.druckerAuswahlSelectBox) {
      this.druckerAuswahlSelectBox.instance.option("value", null);
    } else {
      this.druckerAuswahlSelectBoxOptions.value = null;
    }
    if (this.createOnlyIfNotExistsCheckBox) {
      this.createOnlyIfNotExistsCheckBox.instance.option("value", true);
    } else {
      this.createOnlyIfNotExistsCheckBoxOptions.value = true;
    }
  }

  private setDruckerDataSource(): void {
    this.rest.get({
      url: this.rest.getWebApiUrl("ZIT/Stammdaten/Drucker"),
      increaseLoadingCount: true
    }).then((data) => {
      if (!data || !data.length) {
        return;
      }

      if (this.druckerAuswahlSelectBox) {
        this.druckerAuswahlSelectBox.instance.option("dataSource", data);
      } else {
        this.druckerAuswahlSelectBoxOptions.dataSource = data;
      }
    });
  }

  private onDokumenteLautVorlageRadioGroupValueChanged(): void {
    let typ;

    if (this.dokumenteLautVorlageRadioGroup) {
      typ = this.dokumenteLautVorlageRadioGroup.instance.option("value");
    } else {
      typ = this.dokumenteLautVorlageRadioGroupOptions.value;
    }

    this.rest.post({
      url: this.rest.getApiUrl("ZIT/Stammdaten/DokumentLautVorlageHelperList"),
      increaseLoadingCount: true,
      data: { Typ: typ, FullName: this.fullname }
    }).then((r) => {
      let dataSource;

      if (r && r.length > 0) {
        dataSource = r;
      } else {
        dataSource = [];
      }

      if (this.vorlageSelectBox) {
        this.vorlageSelectBox.instance.option("dataSource", dataSource);
        this.vorlageSelectBox.instance.option("value", null);
      } else {
        this.vorlageSelectBoxOptions.dataSource = dataSource;
        this.vorlageSelectBoxOptions.value = null;
      }
    });
  }

  private checkAndDoDokumentLautVorlageErstellen(): void {
    if (!this.keyList || this.keyList.length < 1) {
      return;
    }

    const data: IDokumentLautVorlageErstellenData = {
      IdObjektList: this.keyList,
      IdVorlage: this.vorlageSelectBox.instance.option("value"),
      DokumenteLautVorlageTyp: this.dokumenteLautVorlageRadioGroup.instance.option("value"),
      CreateOnlyIfNotExists: this.createOnlyIfNotExistsCheckBox.instance.option("value"),
      Druckername: this.druckerAuswahlSelectBox.instance.option("value")
    };

    if (!this.canDokumentLautVorlageErstellen(data)) {
      return;
    }

    this.rest.post({
      url: this.rest.getApiUrl("ZIT/Stammdaten/CanDokumentLautVorlageErstellen"),
      data: data,
      increaseLoadingCount: true
    }).then((r) => {
      if (r && r.Message && r.Message.length > 0) {
        this.dialogConfirmService.show({
          title: this.localization.translateOnce("base.question"),
          message: r.Message
        }).then((res) => {
          if (res) {
            this.doDokumentLautVorlageErstellen(data);
          } else {
            this.dokumentLautVorlageErstellenPopup.instance.hide();
          }
        });
      } else {
        this.doDokumentLautVorlageErstellen(data);
      }
    });
  }
  private canDokumentLautVorlageErstellen(data: IDokumentLautVorlageErstellenData): boolean {
    if (!data.IdVorlage) {
      DevExpress.ui.notify(this.localization.translateOnce("dokument-laut-vorlage-erstellen.vorlage-fehlt"), "error", 3000);
    } else {
      return true;
    }
  }

  private doDokumentLautVorlageErstellen(data: any): void {
    this.rest.post({
      url: this.rest.getApiUrl("ZIT/Stammdaten/DokumentLautVorlageErstellen"),
      data: data,
      increaseLoadingCount: true
    }).then((r) => {
      this.dokumentLautVorlageErstellenPopup.instance.hide();

      if (r && r.Message && r.Message.length > 0) {
        DevExpress.ui.dialog.alert(r.Message, "Information");
      }
    });
  }
}
