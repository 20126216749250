import * as Interfaces from "../interfaces/export";
import {
  createOverrideContext,
  OverrideContext,
  Scope
} from "aurelia-framework";
import {
  Subscription
} from "aurelia-event-aggregator";
import {
  Models
} from "./models";
import {
  Functions
} from "./functions";
import {
  Commands
} from "./commands";
import {
  Variables
} from "./variables";
import {
  NestedForms
} from "./nested-forms";
import {
  EditPopups
} from "./edit-popups";
import {
  CommandServerData
} from "./command-server-data";
import {
  ToolbarService
} from "../services/toolbar-service";
import {
  CommandService
} from "../services/command-service";
import {
  WidgetCreatorService
} from "../widget-services/widget-creator-service";
import {
  BindingService,
  CustomEvent,
  ErrorService,
  GlobalizationService,
  LocalizationService,
  RestService,
  ScopeContainer
} from "../../base/export";
import {
  IFormAttachedEventArgs,
  IFormActivateEventArgs,
  IFormReadyEventArgs,
  IFormReactivatedEventArgs,
  IFormDetachedEventArgs,
  IFormValidatingEventArgs,
  IFormBindEventArgs,
  IFormUnbindEventArgs,
  IEditorValueChangedEventArgs,
  IFormSavingEventArgs,
  IFormSavedEventArgs,
  IFormDeletedEventArgs
} from "../event-args/export";
import {
  FormBaseImport
} from "./form-base-import";
import {
  IValidationResult
} from "../interfaces/export";
import {
  IViewScrollInfo
} from "../../base/interfaces/export";
import * as Toolbar from "../elements/toolbar/export";
import * as DxLoader from "../../dx/dx-loader";
import { IFormDeletingEventArgs } from '../event-args/form-deleting';

export class FormBase {
  private _callOnBind: { (): void }[];
  private _callOnCommandsLoaded: { (): void }[];
  private _reloadEventAggregatorSubscription: Subscription;
  private _beforeUnloadEventAggregatorSubscription: Subscription;
  private _routerCurrentViewItemChangedSubscription: Subscription;

  constructor(
    public element: Element,
    private formBaseImport: FormBaseImport
  ) {
    this._callOnBind = [];
    this._callOnCommandsLoaded = [];

    this.isEditPopup = element.getAttribute("is-edit-popup") === "true";
    this.isNestedForm = element.getAttribute("is-nested-form") === "true";

    this.rest = formBaseImport.rest;
    this.widgetCreator = formBaseImport.widgetCreator;
    this.command = formBaseImport.command;
    this.toolbar = formBaseImport.toolbar;
    this.models = formBaseImport.models;
    this.variables = formBaseImport.variables;
    this.nestedForms = formBaseImport.nestedForms;
    this.editPopups = formBaseImport.editPopups;
    this.functions = formBaseImport.functions;
    this.commands = formBaseImport.commands;
    this.binding = formBaseImport.binding;
    this.globalization = formBaseImport.globalization;
    this.localization = formBaseImport.localization;
    this.commandServerData = formBaseImport.commandServerData;
    this.error = formBaseImport.error;

    this.onAttached = formBaseImport.onAttached;
    this.onDetached = formBaseImport.onDetached;
    this.onBind = formBaseImport.onBind;
    this.onUnbind = formBaseImport.onUnbind;
    this.onActivate = formBaseImport.onActivate;
    this.onReady = formBaseImport.onReady;
    this.onReactivated = formBaseImport.onReactivated;
    this.onValidating = formBaseImport.onValidating;
    this.onEditorValueChanged = formBaseImport.onEditorValueChanged;
    this.onSaving = formBaseImport.onSaving;
    this.onSaved = formBaseImport.onSaved;
    this.onDeleting = formBaseImport.onDeleting;
    this.onDeleted = formBaseImport.onDeleted;

    this.models.registerForm(this);
    this.variables.registerForm(this);
    this.functions.registerForm(this);
    this.commands.registerForm(this);
    this.nestedForms.registerForm(this);
    this.editPopups.registerForm(this);

    this.focusFirstEnabledEditor = this.focusFirstEnabledEditorEx;
    this.canGoBack = !this.isEditPopup
      && !this.isNestedForm
      && this.formBaseImport.router.viewStack.length > 1;

    this.isCurrentForm = !this.isEditPopup
      && !this.isNestedForm;
  }

  toolbarOptions: Toolbar.IToolbarOptions;
  id: string;
  moduleId: string;
  title: string;
  viewItemInfo: Interfaces.IViewItemModel;
  viewScrollInfo: IViewScrollInfo;
  viewUrl: string;

  isEditPopup: boolean;
  isNestedForm: boolean;

  rest: RestService;
  widgetCreator: WidgetCreatorService;
  command: CommandService;
  toolbar: ToolbarService;
  binding: BindingService;
  globalization: GlobalizationService;
  localization: LocalizationService;
  error: ErrorService;

  models: Models;
  variables: Variables;
  nestedForms: NestedForms;
  editPopups: EditPopups;
  functions: Functions;
  commands: Commands;
  commandServerData: CommandServerData;

  onAttached: CustomEvent<IFormAttachedEventArgs>;
  onDetached: CustomEvent<IFormDetachedEventArgs>;
  onActivate: CustomEvent<IFormActivateEventArgs>;
  onBind: CustomEvent<IFormBindEventArgs>;
  onUnbind: CustomEvent<IFormUnbindEventArgs>;
  onReady: CustomEvent<IFormReadyEventArgs>;
  onReactivated: CustomEvent<IFormReadyEventArgs>;
  onValidating: CustomEvent<IFormValidatingEventArgs>;
  onEditorValueChanged: CustomEvent<IEditorValueChangedEventArgs>;
  onSaving: CustomEvent<IFormSavingEventArgs>;
  onSaved: CustomEvent<IFormSavedEventArgs>;
  onDeleting: CustomEvent<IFormDeletingEventArgs>;
  onDeleted: CustomEvent<IFormDeletedEventArgs>;

  scope: Scope;
  scopeContainer: ScopeContainer;

  owningView: any;
  parent: FormBase;

  canGoBack: boolean;
  focusFirstEnabledEditor: { (): void };
  isCurrentForm: boolean;

  isBound: boolean;

  callOnBind(callback: { (): void }) {
    if (this._callOnBind) {
      this._callOnBind.push(callback);
    } else {
      callback();
    }
  }
  callOnCommandsLoaded(callback: { (): void }) {
    if (this._callOnCommandsLoaded) {
      this._callOnCommandsLoaded.push(callback);
    } else {
      callback();
    }
  }

  async activate(viewItemInfo: Interfaces.IViewItemModel) {
    this.viewItemInfo = viewItemInfo;

    this.viewScrollInfo = viewItemInfo && viewItemInfo.viewScrollInfo
      ? viewItemInfo.viewScrollInfo
      : null;

    let parameters = null;
    if (viewItemInfo && viewItemInfo.routeInfo && viewItemInfo.routeInfo.parameters) {
      parameters = viewItemInfo.routeInfo.parameters;

      if (parameters.id) {
        this.variables.data.$id = viewItemInfo.routeInfo.parameters.id;
      }
    }

    return this.dispatchOnActivate(parameters);
  }
  created(owningView: any, myView: any) {
    this.owningView = owningView;

    if (myView
      && myView.resources
      && myView.resources.viewUrl) {
      this.viewUrl = myView.resources.viewUrl;
      this.moduleId = this.viewUrl
        ? this.viewUrl.substr(0, this.viewUrl.lastIndexOf("."))
        : null;
    }
  }
  async bind(bindingContext: any, overrideContext: OverrideContext) {
    this.isBound = true;
    this.parent = this.owningView.bindingContext;

    this.scope = {
      bindingContext: this,
      overrideContext: createOverrideContext(this)
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.registerHasChangedData();
    this.registerReloadEventAggregator();
    this.registerEditorValueChanged();
    this.registerRouterCurrentViewItemChanged();

    this.formBaseImport.eventAggregator.publish("form:bind", {
      form: this
    });

    for (let i = 0; i < this._callOnBind.length; i++) {
      this._callOnBind[i]();
    }
    this._callOnBind = null;

    this.validateViewScrollInfo();

    await this.dispatchOnBind();

    this.loadCommands();

    for (let i = 0; i < this._callOnCommandsLoaded.length; i++) {
      this._callOnCommandsLoaded[i]();
    }
    this._callOnCommandsLoaded = null;

    this.toolbarOptions = this.toolbar.createFormToolbarOptions(this);

    return this.models.loadModelWithKeyId();
  }
  async attached() {
    await this.dispatchOnAttached();

    if (!this.isEditPopup) {
      await this.dispatchOnReady();
    }
    
    this.formBaseImport.taskQueue.queueTask(() => {
      this.formBaseImport.eventAggregator.publish("form:title-changed", {
        form: this
      });
    });
  }
  async detached() {
    await this.dispatchOnDetached();
  }
  async unbind() {
    this.isBound = false;

    if (this._beforeUnloadEventAggregatorSubscription) {
      this._beforeUnloadEventAggregatorSubscription.dispose();
      this._beforeUnloadEventAggregatorSubscription = null;
    }
    if (this._reloadEventAggregatorSubscription) {
      this._reloadEventAggregatorSubscription.dispose();
      this._reloadEventAggregatorSubscription = null;
    }
    if (this._routerCurrentViewItemChangedSubscription) {
      this._routerCurrentViewItemChangedSubscription.dispose();
      this._routerCurrentViewItemChangedSubscription = null;
    }

    const args = {
      form: this
    };

    await this.dispatchOnUnbind();
    this.dispose();
  }
  handleChangedData(): Promise<boolean> {
    if (this.models.hasChangedData()) {
      return new Promise((resolve, reject) => {
        this.formBaseImport.dialogConfirm.show({
          message: this.localization.translateOnce("forms.confirm_unchanged_data"),
          title: this.localization.translateOnce("base.question"),
          buttons: [{
            id: "yes",
            text: this.localization.translateOnce("base.yes"),
            onClick: () => {
              if (!this.canSave()) {
                resolve(false);
              } else if (!this.canSaveNow()) {
                resolve(false);
              } else {
                this.save().then(r => {
                  resolve(r.isValid);
                });
              }
            }
          }, {
            id: "no",
            text: this.localization.translateOnce("base.no"),
            onClick: () => {
              resolve(true);
            }
          }, {
            id: "cancel",
            text: this.localization.translateOnce("base.cancel"),
            onClick: () => {
              resolve(false);
            }
          }]
        });
      });
    } else {
      return Promise.resolve(true);
    }
  }
  async reactivate(): Promise<any> {
    await this.reloadIfServerHasNewVersion();

    this.formBaseImport.eventAggregator.publish("form:title-changed", {
      form: this
    });

    await this.dispatchOnReactivated();
  }

  getFileDownloadUrl(key: string): string {
    const url = this.binding.evaluate(this.scope, key);
    if (!url) {
      return "";
    }

    return this.formBaseImport.file.getDownloadUrl(url);
  }
  getFormsInclOwn(): FormBase[] {
    return [this, ...this.nestedForms.getNestedForms()];
  }

  executeCommand(id: string, options?: Toolbar.IItemExecuteOptions) {
    options = options || {
      event: null
    };

    if (id === "$command") {
      const commands = this.commands
        .getCommands()
        .filter(c =>
          this.formBaseImport.command.isVisibleAndEnabled(c));

      this.formBaseImport.formEvent.onExecuteCommand.fire({
        form: this,
        commands: commands,
        allowGlobalCommands: !this.isEditPopup
      });
    } else {
      const command = this.commands
        .getCommands()
        .find(i => i.id == id);

      if (!command) {
        return;
      }

      this.command.execute(this.scope, command, options);
    }
  }

  createValidationResult(): IValidationResult {
    return {
      isValid: true,
      messages: []
    };
  }
  async validate(validationResult: IValidationResult, showErrorIfInvalid: boolean = true): Promise<any> {
    const args: IFormValidatingEventArgs = {
      form: this,
      validationResult: validationResult
    }

    await this.dispatchOnValidating(args);

    if (showErrorIfInvalid && validationResult.isValid == false) {
      DevExpress.ui.notify(
        validationResult.messages.length > 0
          ? validationResult.messages[0]
          : this.translate("base.validation_error"),
        "ERROR",
        3000);
    }

    return validationResult;
  }

  canAdd(): boolean {
    const mainModel = this.models.modelWithKeyId;
    if (!mainModel) {
      return false;
    }

    if (!this.formBaseImport.permission.canWebApiNew(mainModel.webApiAction, this.moduleId)) {
      return false;
    }

    if (!this.models.allowNew(this.scopeContainer, mainModel)) {
      return false;
    }

    return true;
  }
  async add(): Promise<any> {
    if (!this.canAdd()) {
      return;
    }

    const hasHandled = await this.handleChangedData();
    if (!hasHandled) {
      return;
    }

    this.loadById("0");

    this.formBaseImport.taskQueue.queueTask(() => {
      this.focusFirstEnabledEditorGuarded();
    });
  }

  canSave(): boolean {
    return this
      .getFormsInclOwn()
      .some(i => i.models.getModels().some(m => {
        if (!m.postOnSave) {
          return false;
        }
        if (!this.models.canSave(m)) {
          return false;
        }

        return true;
      }));
  }
  canSaveNow(): boolean {
    return this
      .getFormsInclOwn()
      .some(i => i.models.getModels().some(m => {
        if (!m.postOnSave) {
          return false;
        }
        if (!this.models.data[m.id] || this.models.data[m.id][m.keyProperty] === undefined) {
          return false;
        }
        if (!this.models.allowSave(this.scopeContainer, m)) {
          return false;
        }

        return true;
      }));
  }
  async reloadIfServerHasNewVersion(): Promise<boolean> {
    const m = this.models.modelWithKeyId;
    if (!m
      || !m.optimisticLockingEnabled
      || !this.models.data[m.id]
      || !this.models.data[m.id][m.keyProperty]
      || this.models.data[m.id]["OLV"] == void (0)) {
      return false;
    }

    const r = await this.rest.get({
      url: `${this.rest.getWebApiUrl(m.webApiAction)}/${this.models.data[m.id][m.keyProperty]}`,
      getOptions: {
        columns: ["OLV"]
      },
      moduleId: this.moduleId
    });

    if (!r) {
      return false;
    }

    const olv = r["OLV"];
    if (olv == this.models.data[m.id]["OLV"]) {
      return false;
    }

    await this.models.reloadAll();
    return true;
  }
  async saveIfDirty(): Promise<IValidationResult> {
    this.formBaseImport.windowService.blurCurrentElement();

    if (this.isNestedForm && this.parent instanceof FormBase) {
      const r = await this.parent.saveIfDirty();
      if (!r.isValid) {
        return r;
      }
    }

    if (!this.models.hasChangedData()) {
      const validationResult = this.createValidationResult();

      if (this.canSave() && this.canSaveNow()) {
        await this.validate(validationResult, true);
      }

      return validationResult;
    }

    return this.save();
  }
  async save(): Promise<IValidationResult> {
    this.formBaseImport.windowService.blurCurrentElement();
    const validationResult = this.createValidationResult();

    if (!this.canSave() || !this.canSaveNow()) {
      validationResult.isValid = false;
      return Promise.resolve(validationResult);
    }

    await this.validate(validationResult, true);

    if (validationResult.isValid) {
      try {
        await this.dispatchOnSaving();
        await this.models.save();

        DevExpress.ui.notify(
          this.translate("base.save_success"),
          "SUCCESS",
          3000);

        this.setCurrentUrl();
        this.dispatchOnSaved();
      } catch (ex) {
        validationResult.isValid = false;
        return validationResult;
      }
    }

    return validationResult;
  }

  canDeleteNow(): boolean {
    return this
      .getFormsInclOwn()
      .some(i => i.models.getModels().some(m => {
        if (!m.postOnSave) {
          return false;
        }
        if (!this.models.data[m.id] || !this.models.data[m.id][m.keyProperty]) {
          return false;
        }
        if (!this.models.allowDelete(this.scopeContainer, m)) {
          return false;
        }
        if (!this.models.canDelete(m)) {
          return false;
        }

        return true;
      }));
  }
  async delete(): Promise<boolean> {
    if (!this.canSave() || !this.canDeleteNow()) {
      return false;
    }

    const deletingResult = await this.dispatchOnDeleting();
    if (!deletingResult) {
      return false;
    }

    await this.models.delete();
    await this.dispatchOnDeleted();
    
    return true;
  }

  translate(key: string): string {
    return this.localization.translateOnce(key, this.scopeContainer);
  }

  loadById(id: string): void {
    this.setCurrentUrl(id);

    if (this.variables.data.$id == id) {
      this.models
        .loadModelWithKeyId();
    }
    else {
      this.variables.data.$id = id;
    }
  }

  async dispatchOnCreated() {
    const args = {
      form: this
    };

    this.formBaseImport.formEvent.onCreated.fire(args);
  }
  async dispatchOnActivate(parameters: any) {
    await this.onActivate.fire({
      form: this,
      parameters: parameters
    });
  }
  async dispatchOnBind() {
    const args = {
      form: this
    };

    await this.onBind.fire(args);
    await this.formBaseImport.formEvent.onBind.fire(args);
  }
  async dispatchOnAttached() {
    const args = {
      form: this
    };

    await this.onAttached.fire(args);
    await this.formBaseImport.formEvent.onAttached.fire(args);
  }
  async dispatchOnReady() {
    const args = {
      form: this
    };

    this.formBaseImport.taskQueue.queueMicroTask(async () => {
      await this.onReady.fire(args);
      await this.formBaseImport.formEvent.onReady.fire(args);

      if (!this.isNestedForm) {
        this.focusFirstEnabledEditorGuarded();
      }
    });
  }
  async dispatchOnReactivated() {
    const args = {
      form: this
    };

    await this.onReactivated.fire(args);
    await this.formBaseImport.formEvent.onReactivating.fire(args);
  }
  async dispatchOnValidating(args: IFormValidatingEventArgs) {
    await this.onValidating.fire(args);
    await this.formBaseImport.formEvent.onValidating.fire(args);

    const forms = this.nestedForms.getNestedForms();

    for (let form of forms) {
      await form.validate(args.validationResult, false);
    }
  }
  async dispatchOnSaving() {
    const args = {
      form: this
    };

    await this.onSaving.fire(args);
    await this.formBaseImport.formEvent.onSaving.fire(args);
  }
  async dispatchOnSaved() {
    const args = {
      form: this
    };

    await this.onSaved.fire(args);
    await this.formBaseImport.formEvent.onSaved.fire(args);
  }
  async dispatchOnDeleting() {
    const args = {
      form: this,
      cancel: false
    };

    await this.onDeleting.fire(args);

    if (args.cancel) {
      return false;
    }

    await this.formBaseImport.formEvent.onDeleting.fire(args);

    if (args.cancel) {
      return false;
    }

    return true;
  }
  async dispatchOnDeleted() {
    const args = {
      form: this
    };

    await this.onDeleted.fire(args);
    await this.formBaseImport.formEvent.onDeleted.fire(args);
  }
  async dispatchOnDetached() {    
    const args = {
      form: this
    };

    await this.onDetached.fire(args);
    await this.formBaseImport.formEvent.onDetached.fire(args);
  }
  async dispatchOnUnbind() {
    const args = {
      form: this
    };

    await this.onUnbind.fire(args);
    await this.formBaseImport.formEvent.onUnbind.fire(args);
  }

  focusFirstEnabledEditorGuarded() {
    if (this.formBaseImport.browser.isTouch) {
      return;
    }

    if (!this.focusFirstEnabledEditor) {
      return;
    }

    this.focusFirstEnabledEditor();
  }

  protected addModel(model: Interfaces.IModel): void {
    this.callOnBind(() => {
      this.models.addInfo(model);
    });
  }
  protected addVariable(variable: Interfaces.IVariable): void {
    this.callOnBind(() => {
      this.variables.addInfo(variable);
    });
  }
  protected addCommandServerData(id: string, commandServerData: Interfaces.ICommandData): void {
    this.callOnBind(() => {
      this.commandServerData.add(id, commandServerData);
    });
  }
  protected addCommand(command: Interfaces.ICommand): void {
    this.callOnBind(() => {
      this.commands.addInfo(command);
    });
  }
  protected addFunction(id: string, functionInstance: any, namespace: string, customParameter?: any): void {
    if (functionInstance.registerForm) {
      functionInstance.registerForm(this);
    }

    this.callOnBind(() => {
      this.functions.add(id, functionInstance, namespace, customParameter);
    });
  }
  protected addNestedForm(id: string, mappings: Interfaces.IMapping[]): void {
    this.callOnBind(() => {
      this.nestedForms.addInfo(id, mappings);
    });
  }
  protected addEditPopup(editPopup: Interfaces.IEditPopup): void {
    this.callOnBind(() => {
      this.editPopups.addInfo(editPopup);
    });
  }
  protected addMapping(mapping: Interfaces.IMapping): void {
  }
  protected submitForm(commandExpression: string, options?: Toolbar.IItemExecuteOptions): void {
    const command: Interfaces.ICommandData = this.binding.evaluate(this.scope, commandExpression);
    if (!command || !command.execute) {
      return;
    }

    this.command.execute(this.scope, command, options);
  }
  protected onConstructionFinished(): void {
    this.dispatchOnCreated();
  }

  private loadCommands() {
    if (!this.isNestedForm) {
      this.commands.addCommand(this.formBaseImport.defaultCommands.getFormGoBackCommand(this));

      if (this.isEditPopup) {
        this.commands.addCommand(this.formBaseImport.defaultCommands.getFormAddCommand(this));
        this.commands.addCommand(this.formBaseImport.defaultCommands.getFormSaveCommand(this));
        this.commands.addCommand(this.formBaseImport.defaultCommands.getEditPopupSaveAndCloseCommand(this));
        this.commands.addCommand(this.formBaseImport.defaultCommands.getEditPopupSaveAndAddCommand(this));
        this.commands.addCommand(this.formBaseImport.defaultCommands.getEditPopupDeleteCommand(this));

        this.commands.addCommand(this.formBaseImport.defaultCommands.getScrollDown(this));
        this.commands.addCommand(this.formBaseImport.defaultCommands.getScrollUp(this));
      } else {
        this.commands.addCommand(this.formBaseImport.defaultCommands.getFormAddCommand(this));
        this.commands.addCommand(this.formBaseImport.defaultCommands.getFormSaveCommand(this));
        this.commands.addCommand(this.formBaseImport.defaultCommands.getFormDeleteCommand(this));

        if (this.viewScrollInfo) {
          this.commands.addCommand(this.formBaseImport.defaultCommands.getScrollDown(this));
          this.commands.addCommand(this.formBaseImport.defaultCommands.getScrollUp(this));
        }
      }
    }

    if (this.isEditPopup) {
      this.commands.addCommand(this.formBaseImport.defaultCommands.getClosePopupCommand());
    }
  }
  private setCurrentUrl(id: string = null): void {
    if (this.isEditPopup || this.isNestedForm) {
      return;
    }

    if (id == void (0)) {
      const mainModel = this.models.modelWithKeyId;
      if (!mainModel) {
        return;
      }

      const data = this.models.data[mainModel.id];
      if (!data) {
        return;
      }

      const key = data[mainModel.keyProperty];
      if (key == void (0)) {
        return;
      }

      id = key;
    }

    const currentUrl = this.formBaseImport.history.getUrl();
    const currentRoute = this.formBaseImport.router.getRoute(currentUrl);

    if (!currentRoute) {
      return;
    }

    const newUrl = this.formBaseImport.router.constructUrl(currentRoute.route, {
      id: id
    });

    this.formBaseImport.history.setUrlWithoutNavigation(newUrl, true);
  }
  private focusFirstEnabledEditorEx() {
    const elements = Array.from(this.element.querySelectorAll(".dx-texteditor"));

    for (let element of elements) {
      let cancelCurrent = false;
      let parent = element.parentElement;
      while (parent && parent != this.element) {
        if (parent.classList.contains("dx-popup")) {
          cancelCurrent = true;
          break;
        }
        parent = parent.parentElement;
      }

      if (cancelCurrent) {
        continue;
      }

      const name = element.parentElement.getAttribute("name");
      if (!name) {
        continue;
      }

      const instance = DxLoader.getInstance(name, element);
      if (!instance || !instance.focus) {
        continue;
      }

      const tabIndex = instance.option("tabIndex");
      const isDisabled = instance.option("disabled");

      if ((tabIndex && tabIndex < 0) || isDisabled) {
        continue;
      }

      instance.focus();
      return;
    }
  }

  private registerHasChangedData() {
    this._beforeUnloadEventAggregatorSubscription = this.formBaseImport.eventAggregator.subscribe("window:beforeunload", e => {
      e.hasChangedData = e.hasChangedData || this.models.hasChangedData();
    });
  }
  private registerReloadEventAggregator() {
    this._reloadEventAggregatorSubscription = this.formBaseImport.eventAggregator.subscribe(
      "data:reload",
      (data) => {
        if (!data) {
          return;
        }

        const id = data.Id;
        const action = data.Action;

        if (!id || !action) {
          return;
        }

        const modelWithKeyId = this.models.modelWithKeyId;

        //Wenn ein Model mit Key-ID existiert, dann wird nur spezifisch darauf geprüft
        if (modelWithKeyId) {
          if (!modelWithKeyId || modelWithKeyId.webApiAction != action) {
            return;
          }

          const modelData = this.models.data[modelWithKeyId.id];
          if (modelData == null || modelData[modelWithKeyId.keyProperty] != id) {
            return;
          }

          this.models.reloadAll();
        } else {
          this.models
            .getModels()
            .filter(model => !model.key && model.webApiAction == action)
            .forEach(model => this.models.onLoadRequired.fire({
              model: model
            }));
        }
      }
    );
  }
  private registerEditorValueChanged() {
    this.onEditorValueChanged.register((e) => {
      if (!e.binding || !e.binding.dataContext) {
        return Promise.resolve();
      }

      const model = this.models.getInfo(e.binding.dataContext, false);
      if (model) {
        this.models.setDataChanged(model);
      }

      return Promise.resolve();
    });
  }
  private registerRouterCurrentViewItemChanged() {
    if (this.isEditPopup || this.isNestedForm) {
      return;
    }

    this._routerCurrentViewItemChangedSubscription = this.formBaseImport.eventAggregator.subscribe("router:current-view-item-changed", e => {
      this.canGoBack = e.currentViewItem.model == this.viewItemInfo
        && this.formBaseImport.router.viewStack.length > 1;

      this.isCurrentForm = e.currentViewItem.model == this.viewItemInfo;
    });
  }
  private validateViewScrollInfo() {
    if (!this.viewScrollInfo) {
      return;
    }
    if (this.viewScrollInfo.resultKeyResolver) {
      return;
    }
    if (this.viewScrollInfo.getNextKey) {
      return;
    }

    const model = this.models.modelWithKeyId;
    const isSameUrl = model
      && model.webApiAction
      && this.rest.getWebApiUrl(model.webApiAction) == this.viewScrollInfo.lastLoadInfo.url

    if (!isSameUrl) {
      this.viewScrollInfo = null;
    }
  }

  private dispose() {
    this.scopeContainer.disposeAll();
    this.scope = null;

    this.formBaseImport.dispose();
  }
}
