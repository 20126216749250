import { autoinject } from "aurelia-framework";
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class AlertService {
  constructor(
    private eventAggregator: EventAggregator
  ) {
    this.eventAggregator.subscribe("notify", (e) => {
      DevExpress.ui.notify(e.message, e.type || "success", e.displayTime || 3000);
    });
    this.eventAggregator.subscribe("alert", (e) => {
      DevExpress.ui.dialog.alert(e.message, e.title || "Information");
    });
  }
}
