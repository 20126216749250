import * as fwx from "../../../../framework/forms/form-export";
import {
    EventTeilnehmerReadUtils
} from "./event-teilnehmer-read-utils";

@fwx.autoinject
export class EventTeilnehmerReadForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_EventTeilnehmerReadUtils: EventTeilnehmerReadUtils) {
        super(element, formBaseImport);
        this.id = "event-teilnehmer-read";
        this.title = "event-teilnehmer-read.event-teilnehmer-read_caption";
        this.addModel({
            "id": "$m_EventTeilnehmer",
            "webApiAction": "ZIT/Objekte/EventTeilnehmer",
            "webApiExpand": {
                'Kopf': null
            },
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EventTeilnehmerReadUtils.actionCommand",
                "bindToFQ": "functions.$f_EventTeilnehmerReadUtils.actionCommand"
            }
        });
        this.addFunction("$f_EventTeilnehmerReadUtils", $f_EventTeilnehmerReadUtils, "functions.$f_EventTeilnehmerReadUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}