import * as fwx from "../../../framework/forms/form-export";
import {
    DashboardUtils
} from "./dashboard-utils";

@fwx.autoinject
export class DashboardFormForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_DashboardUtils: DashboardUtils) {
        super(element, formBaseImport);
        this.id = "dashboard-form";
        this.title = "dashboard-form.dashboard-form_caption";
        this.addFunction("$f_DashboardUtils", $f_DashboardUtils, "functions.$f_DashboardUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}