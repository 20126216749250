import { autoinject } from 'aurelia-framework';
import { ObjektInfoService } from '../../zit/services/export';

@autoinject
export class DefaultShortObjekttypBezeichnungValueConverter {
  constructor(
    private _objektInfoService: ObjektInfoService
  ) {}

  toView(value: string, objektTypShort: string) {
    if (value) {
      return value;
    }

    const r = this._objektInfoService.getObjektByName(objektTypShort);
    if (!r) {
      return objektTypShort;
    }

    return r.Bezeichnung;
  }
}