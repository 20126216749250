import {
  autoinject,
  BindingEngine,
  Disposable,
  Scope,
  LiteralPrimitive,
  TaskQueue,
  Aurelia
} from "aurelia-framework";
import { IBindingObserveArguments } from '../export';


@autoinject
export class BindingService {
  constructor(
    private _bindingEngine: BindingEngine,
    private _taskQueue: TaskQueue,
    private _aurelia: Aurelia
  ) {}

  assign(scope: Scope, expression: string, value: any) {
    this._bindingEngine
      .parseExpression(expression)
      .assign(scope, value, null);
  }
  evaluate(scope: Scope, expression: string): any {
    if (!scope.bindingContext) {
      return null;
    }
    
    return this._bindingEngine
      .parseExpression(expression)
      .evaluate(scope, {
        bindingBehaviors: (name) => this._aurelia.resources.getBindingBehavior(name),
        valueConverters: (name) => this._aurelia.resources.getValueConverter(name)
      });
  }
  execute(scope: Scope, expression: string, ...parameters: any[]): any {
    let context = this.getBindingContext(scope, expression);
    let obj: any = this._bindingEngine.parseExpression(expression);

    if (obj.object) {
      context = obj.object.evaluate({
        bindingContext: context
      });
    }
    
    return obj.evaluate(scope).call(context, ...parameters);
  }
  observe(args: IBindingObserveArguments): Disposable {
    //Prüfen ob es sich um einen Pfad handelt oder einfach nur um einen primitiven Typ
    const parsedExpression = this._bindingEngine.parseExpression(args.expression);
    
    if (args.checkPrimitiveType && parsedExpression instanceof LiteralPrimitive) {
      //Nicht sofort ausführen, sondern so, wie es auch das Binding machen würde - leicht verzögert
      this._taskQueue.queueTask(() => {
        args.callback(parsedExpression.value, null);
      });

      return {
        dispose: () => {} 
      };
    }
    
    const disposable = this._bindingEngine
      .expressionObserver(
        this.getBindingContext(args.customScope || args.scopeContainer.scope, args.expression),
        args.expression
      ).subscribe(args.callback);

      args.scopeContainer.addDisposable(disposable);
      return disposable;
  }
  getBindingContext(scope: Scope, expression: string) {
    let obj: any = this._bindingEngine
      .parseExpression(expression);
    
    while (obj.object) {
      obj = obj.object;
    }

    if (obj.name in scope.bindingContext) {
      return scope.bindingContext;
    } else {
      let ov = scope.overrideContext;

      while (ov) {
        if (obj.name in ov.bindingContext) {
          return ov.bindingContext;
        }

        ov = ov.parentOverrideContext;
      }
    }

    return scope.bindingContext || scope.overrideContext;
  }
}
