import { autoinject, transient } from "aurelia-framework";
import { AuthorizationService, LocalizationService, RestService } from "../../../../../framework/base/export";
import { LocationService } from "../../../../../framework/base/services/location-service";
import { FormBase } from "../../../../../framework/forms/export";
import { BrowserService } from "./../../../../../framework/base/services/browser-service";
import { ICommandData } from "./../../../../../framework/forms/interfaces/command-data";
import { DomaeneService } from "./../../../../services/domaene-service";

@autoinject
@transient()
export class PasswordConfirmFuncs {
  private form: FormBase;
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public browserService: BrowserService,
    private _authorizationService: AuthorizationService,
    private _localizationService: LocalizationService,
    private _restService: RestService,
    private _locationService: LocationService,
    private _domaeneService: DomaeneService) {
    const time = new Date()
      .getTime()
      .toString();
    const randomNumberString = Math.floor((Math.random() * 100))
      .toString();
    const randomKey = time + randomNumberString;
    this.logoSrc = this._restService.getApiUrl(`ZIT/Domaene/Logo?key=${randomKey}`);
  }

  urlImpressum: string;
  farbeDomaene: string;
  bezeichnungLoginMobil: string;
  activationKey: string;
  benutzername: string;
  logoSrc: string;
  isMobile: boolean;

  confirmPasswordCommand: ICommandData = {
    id: "$confirmPassword",
    title: "password-confirm-funcs.confirm_caption",
    execute: async () => {
      if (this.form.models.data.$m_Password.Passwort1 !== this.form.models.data.$m_Password.Passwort2) {
        DevExpress.ui.notify(this._localizationService.translateOnce("password-confirm-funcs.password_missmatch"), "error", this.NOTIFY_TIMEOUT);
        return;
      }

      this.form.models.data.$m_Password.CodePasswortBestaetigung = this.activationKey;

      const r = await this._restService.post({
        url: this._restService.getApiUrl("ZIT/Security/PasswortBestaetigung"),
        data: this.form.models.data.$m_Password,
        increaseLoadingCount: true
      });

      this._authorizationService.login({
        Username: this.benutzername,
        Password: this.form.models.data.$m_Password.Passwort1
      });
    }
  };

  backToLoginCommand: ICommandData = {
    id: "$backToLogin",
    title: "password-reset-funcs.back_to_login_caption",
    execute: () => {
      this.goBackToLogin();
    }
  };

  openImpressumClick() {
    window.open(this.urlImpressum);
  }

  registerForm(form: FormBase) {
    this.form = form;

    form.onActivate.register((r) => {
      if (r && r.parameters) {
        this.activationKey = r.parameters.key;
      }

      return Promise.resolve();
    });
  }

  async bind(form: FormBase) {
    this.form.focusFirstEnabledEditor = () => {
      if (this.form["password"].instance) {
        this.form["password"].instance.focus();
      }
    };

    if (!this.activationKey) {
      this.goBackToLogin();
      return;
    }

    this.form.callOnBind(() => {
      if (!this.form.models.data.$m_Password) {
        this.form.models.data.$m_Password = {};
      }

      this.loadBenutzername();
    });

    this.urlImpressum = this._domaeneService.urlImpressum;
    this.farbeDomaene = this._domaeneService.farbeDomaene;
    this._domaeneService.setDomainColorsStyle();
    this.bezeichnungLoginMobil = this._domaeneService.bezeichnungMobilLogin;
  }

  private async loadBenutzername() {
    if (!this.activationKey) {
      return;
    }

    const result = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Security/PasswortBestaetigungBenutzername"),
      data: {
        CodeAktivierung: this.activationKey
      }
    });

    if (result && result.Benutzername) {
      this.benutzername = result.Benutzername;
    } else {
      this.goBackToLogin();
    }
  }

  private goBackToLogin() {
    this._locationService.goTo({
      url: "Login",
      clearStack: true
    });
  }
}
