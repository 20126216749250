import { autoinject } from 'aurelia-framework';

@autoinject
export class ZitInitializenValueConverter {
  toView(data: string) {
    if (!data || !data.split) {
      return;
    }

    const tokens = data
      .split(" ")
      .filter(c => c);

    if (tokens.length == 0) {
      return "";
    } else if (tokens.length == 1) {
      return tokens[0][0].toUpperCase();
    } else if (tokens.length == 2) {
      return tokens[1][0].toUpperCase();
    } else {
      return (tokens[1][0] + tokens[2][0]).toUpperCase();
    }
  }
}
