import { IBenutzerAccountSperrenData } from './../interfaces/benutzeraccount-sperren-data';
import { autoinject } from 'aurelia-framework';
import { RestService } from '../../framework/base/export';
import {
  IBenutzereinstellungData,
  ILoeschenBenutzerData,
  IPasswortAendernData,
  IBenutzerAccountAktivierenData
} from '../interfaces/export';
import { StartupService } from './startup-service';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class BenutzereinstellungenService {
  constructor(
    private _restService: RestService,
    private _startupService: StartupService,
    private _eventAggregator: EventAggregator
  ) { }

  benutzereinstellungenData: IBenutzereinstellungData

  vorbereitetAccountList(idBenutzerList: number[]): Promise<any> {
    if (!idBenutzerList || !idBenutzerList.length) {
      return;
    }

    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Security/BenutzeraccountListVorbereitet"),
      data: { IdBenutzerList: idBenutzerList },
      increaseLoadingCount: true
    });
  }
  activateAccountList(dataList: IBenutzerAccountAktivierenData[]): Promise<any> {
    if (!dataList || !dataList.length) {
      return;
    }

    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Security/BenutzeraccountListAktivieren"),
      data: { ModelList: dataList },
      increaseLoadingCount: true
    });
  }

  lockAccount(data: IBenutzerAccountSperrenData): Promise<any> {
    if (!data) {
      return;
    }

    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Security/BenutzerSperren"),
      data: data,
      increaseLoadingCount: true
    });
  }

  changePasswort(data: IPasswortAendernData): Promise<any> {
    return this._restService.post({
      url: this._restService.getApiUrl("base/Authorization/ChangePassword"),
      data: data,
      increaseLoadingCount: true
    });
  }

  saveWillkommensseiteGelesen(isDeaktiviert: boolean): Promise<any> {
    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Security/WillkommensseiteGelesen"),
      data: {
        IsWillkommensseiteDeaktiviert: isDeaktiviert
      }
    }).then(r => {
      this.loadBenutzereinstellungen();
    });
  }
  deleteBenutzer(data: ILoeschenBenutzerData): Promise<any> {
    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Security/BenutzerLoeschen"),
      data: data,
      increaseLoadingCount: true
    });
  }

  loadBenutzereinstellungen(): Promise<IBenutzereinstellungData> {
    return this._restService.get({
      url: this._restService.getApiUrl("ZIT/Security/Benutzereinstellungen")
    }).then((r): IBenutzereinstellungData => {
      this.benutzereinstellungenData = r;
      return r;
    });
  }
  async saveBenutzereinstellungen(data: IBenutzereinstellungData): Promise<any> {
    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Security/Benutzereinstellungen"),
      data: data,
      increaseLoadingCount: true
    });
    
    if (!r) {
      return;
    }

    this.benutzereinstellungenData = data;
    
    this._startupService.startupInfo.Benutzer.DMSLinkAvatar = data.DMSLinkAvatar;
    this._eventAggregator.publish("avatar:update", {});

    return this.benutzereinstellungenData;
  }
}
