import { autoinject } from "../../framework/forms/form-export";

@autoinject
export class FarbeService {
  constructor() {}

  convertHexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
  }
  convertHexToCssRgba(hex: string, opacity: number): string {
    const r = this.convertHexToRgb(hex);
    if (!r) {
      return "";
    }

    return `rgba(${r.r}, ${r.g}, ${r.b}, ${opacity})`
  }
}
