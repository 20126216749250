import * as fwx from "../../../../framework/forms/form-export";
import {
    BewerbungReadUtils
} from "./bewerbung-read-utils";

@fwx.autoinject
export class BewerbungReadForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_BewerbungReadUtils: BewerbungReadUtils) {
        super(element, formBaseImport);
        this.id = "bewerbung-read";
        this.title = "bewerbung-read.bewerbung-read_caption";
        this.addModel({
            "id": "$m_Bewerbung",
            "webApiAction": "ZIT/Objekte/Bewerbung",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'Person': {
                            'column': [
                                'Id'
                            ]
                        },
                        'Stelleninserat': {
                            'column': [
                                'Id'
                            ]
                        },
                        'Einsatzbereiche': {
                            'expand': {
                                'Einsatzbereich': null
                            }
                        },
                        'Verwandtschaftsverhaeltnis': null,
                        'Bewerbungsgrund': null,
                        'Bewerbungskanal': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "BewerbungInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "StelleninseratInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddPersonCard",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddStelleninseratCard",
                "webApiCustomValue": "true"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BewerbungReadUtils.actionCommand",
                "bindToFQ": "functions.$f_BewerbungReadUtils.actionCommand"
            }
        });
        this.addFunction("$f_BewerbungReadUtils", $f_BewerbungReadUtils, "functions.$f_BewerbungReadUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_bewerbungEinsatzbereicheListViewToolbarOptions",
                "optionsNameFQ": "r_bewerbungEinsatzbereicheListViewToolbarOptions"
            },
            "binding": {
                "bindTo": "$m_Bewerbung.Kopf.Einsatzbereiche",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Einsatzbereiche"
            },
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewerbungEinsatzbereicheListView",
            "options": {
                "optionsName": "r_bewerbungEinsatzbereicheListViewOptions",
                "optionsNameFQ": "r_bewerbungEinsatzbereicheListViewOptions"
            }
        });
        super.onConstructionFinished();
    }
}