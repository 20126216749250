import { autoinject, TaskQueue } from "aurelia-framework";
import { ScopeContainer } from "./../../../framework/base/classes/scope-container";
import { IDataSourceOptionFilter } from "./../../../framework/base/interfaces/data-source-option-filter";
import { FormBase } from "./../../../framework/forms/classes/form-base";
import { ListView } from "./../../../framework/forms/elements/list-view/list-view";
import { IListViewOptions } from "./../../../framework/forms/elements/list-view/list-view-options";
import { IZitCardOptions, ZitCardTyp } from "./../../../zit-ui/elements/zit-card/zit-card-options";
import { IZitTagOptions } from "./../../../zit-ui/elements/zit-tag/zit-tag-options";
import { IZitToolbarOptions } from "./../../../zit-ui/elements/zit-toolbar/zit-toolbar-options";
import { PersonService } from "./../../services/person-service";
import { StartupService } from "./../../services/startup-service";
import { ZielService } from "../../services/ziel-service";
import { LehrlingAuswaehlen, SelectionModeTyp } from "./../lehrling-auswaehlen/lehrling-auswaehlen";
import { FormEventService } from "../../../framework/forms/services/form-event-service";

@autoinject
export class Entwicklungsziele {
  private _onFormSavedDispose: {(): void};

  constructor(
    private _startupService: StartupService,
    private _personService: PersonService,
    private _taskQueue: TaskQueue,
    private _zieleService: ZielService,
    private _formEventService: FormEventService
  ) { }

  lehrlingList: any[] = [];
  lehrlingAuswaehlenPopup: LehrlingAuswaehlen;

  scopeContainer: ScopeContainer;
  mainForm: FormBase;
  filterData: {
    IdPersonZugeteiltList?: number[];
  } = {};

  personTagOptions: IZitTagOptions = {
    icon:
    {
      text: "P"
    },
    text: "${person.Name}",
    infoList: [{
      icon: {
        faIcon: "fas fa-times"
      },
      onClick: async(e, data) => {
        const indexLehrlingList = this.lehrlingList.indexOf(data);
        if (indexLehrlingList < 0) {
          return;
        }
        this.lehrlingList.splice(indexLehrlingList, 1);

        const indexZugeteiltList = this.filterData.IdPersonZugeteiltList.indexOf(data.Id);
        if (indexZugeteiltList < 0) {
          return;
        }
        this.filterData.IdPersonZugeteiltList.splice(indexZugeteiltList, 1);

        this.reloadDataSources();
      }
    }]
  };

  lehrlingToolbarOptions: IZitToolbarOptions = {
    title: "entwicklungszone.auswahl-lehrling",
    smallToolbar: true,
    items: [{
      id: "showLehrlingToolbarOptionen",
      icon: "fas fa-plus",
      execute: () => {
        this.lehrlingAuswaehlenPopup.show({
          selectionMode: SelectionModeTyp.multipe,
          callback: async(selectedPersons) => {
            if (selectedPersons.length == 0) {
              return;
            }

            for (const idPerson of selectedPersons) {
              await this.setLehrling(idPerson);
            }

            this.reloadDataSources();
          }
        });
      }
    }]
  };

  zieleCardOptions: IZitCardOptions = {
    isClickEnabled: true,
    useDefaultBindings: true,
    typ: ZitCardTyp.Kompakt
  };

  begonneneZieleListView: ListView;
  begonneneZieleListViewOptions: IListViewOptions = {
    useDefaultListItemStyle: false,
    itemClass: "col-xs-12 col-sm-6 col-lg-4 col-xl-3"
  };

  gesetzteZieleListView: ListView;
  gesetzteZieleListViewOptions: IListViewOptions = {
    useDefaultListItemStyle: false,
    itemClass: "col-xs-12 col-sm-6 col-lg-4 col-xl-3"
  };

  erfuellteZieleListView: ListView;
  erfuellteZieleListViewOptions: IListViewOptions = {
    useDefaultListItemStyle: false,
    itemClass: "col-xs-12 col-sm-6 col-lg-4 col-xl-3"
  };

  get isNotBewerber() {
    return this._startupService.startupInfo.Benutzer.CodeBenutzerrolle != "BEWERBER";
  }

  bind(bindingContext) {
    this.mainForm = bindingContext;

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });
    this.filterData.IdPersonZugeteiltList = [];
    this.loadDataSources();

    this._onFormSavedDispose = this._formEventService.onSaved.register(async(ev) => {
      if (ev.form.models.modelWithKeyId?.webApiAction == "ZIT/Objekte/Ziel") {
        this.reload();
      }
    });
  }

  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;

    this._onFormSavedDispose();
    this._onFormSavedDispose = null;
  }

  reloadDataSources() {
    this._taskQueue.queueTask(() => {
      this.begonneneZieleListView.options.dataSource.reload();
      this.gesetzteZieleListView.options.dataSource.reload();
      this.erfuellteZieleListView.options.dataSource.reload();
    });
  }

  private async setLehrling(idPerson: number) {
    const alreadyExists = this.lehrlingList.some((c) => c.Id === idPerson);
    if (alreadyExists) {
      return;
    }
    const lehrling = await this._personService.getPerson(idPerson);
    this.lehrlingList.push(lehrling);
    this.filterData.IdPersonZugeteiltList.push(idPerson);
  }

  private loadDataSources() {
    this.loadBegonneneZieleDataSource();
    this.loadGesetzteZieleDataSource();
    this.loadErfuellteZieleDataSource();
  }

  private loadBegonneneZieleDataSource() {
    this.begonneneZieleListViewOptions.dataSource = this._zieleService.loadZieleDataSource(this.scopeContainer,
      () => {
        const where: any[] = [
          [["Kopf.Einschaetzung", ">", "0"], "and", ["Kopf.Einschaetzung", "<", "10"]]];

        if (this.filterData.IdPersonZugeteiltList.length > 0) {
          const tempIdPersonZugeteiltListWhere = [];
          for (let i = 0; i < this.filterData.IdPersonZugeteiltList.length; i++) {
            tempIdPersonZugeteiltListWhere.push(["Kopf.IdObjekt", this.filterData.IdPersonZugeteiltList[i]]);
            if (i + 1 < this.filterData.IdPersonZugeteiltList.length) {
              tempIdPersonZugeteiltListWhere.push("or");
            }
          }
          where.push(tempIdPersonZugeteiltListWhere);
        }
        return where;
      },
      () => {
        const filter: IDataSourceOptionFilter[] = [];
        filter.push({
          webApiCustomKey: "AddZielCard",
          webApiCustomValue: "true"
        });
        return filter;
      },
      [{ columnName: "Id", sortOrder: 0 }]
    );
  }

  private loadGesetzteZieleDataSource() {
    this.gesetzteZieleListViewOptions.dataSource = this._zieleService.loadZieleDataSource(this.scopeContainer,
      () => {
        const where: any[] = [
          ["Kopf.Einschaetzung", "0"]];

        if (this.filterData.IdPersonZugeteiltList.length > 0) {
          const tempIdPersonZugeteiltListWhere = [];
          for (let i = 0; i < this.filterData.IdPersonZugeteiltList.length; i++) {
            tempIdPersonZugeteiltListWhere.push(["Kopf.IdObjekt", this.filterData.IdPersonZugeteiltList[i]]);
            if (i + 1 < this.filterData.IdPersonZugeteiltList.length) {
              tempIdPersonZugeteiltListWhere.push("or");
            }
          }
          where.push(tempIdPersonZugeteiltListWhere);
        }
        return where;
      },
      () => {
        const filter: IDataSourceOptionFilter[] = [];
        filter.push({
          webApiCustomKey: "AddZielCard",
          webApiCustomValue: "true"
        });
        return filter;
      },
      [{ columnName: "Id", sortOrder: 0 }]
    );
  }

  private loadErfuellteZieleDataSource() {
    this.erfuellteZieleListViewOptions.dataSource = this._zieleService.loadZieleDataSource(this.scopeContainer,
      () => {
        const where: any[] = [
          ["Kopf.IsErfuellt", "true"]];

        if (this.filterData.IdPersonZugeteiltList.length > 0) {
          const tempIdPersonZugeteiltListWhere = [];
          for (let i = 0; i < this.filterData.IdPersonZugeteiltList.length; i++) {
            tempIdPersonZugeteiltListWhere.push(["Kopf.IdObjekt", this.filterData.IdPersonZugeteiltList[i]]);
            if (i + 1 < this.filterData.IdPersonZugeteiltList.length) {
              tempIdPersonZugeteiltListWhere.push("or");
            }
          }
          where.push(tempIdPersonZugeteiltListWhere);
        }
        return where;
      },
      () => {
        const filter: IDataSourceOptionFilter[] = [];
        filter.push({
          webApiCustomKey: "AddZielCard",
          webApiCustomValue: "true"
        });
        return filter;
      },
      [{ columnName: "Kopf.ErfuelltDat", sortOrder: 1 }]
    );
  }

  private reload() {
    (<DevExpress.data.DataSource>this.begonneneZieleListViewOptions.dataSource).reload();
    (<DevExpress.data.DataSource>this.gesetzteZieleListViewOptions.dataSource).reload();
    (<DevExpress.data.DataSource>this.erfuellteZieleListViewOptions.dataSource).reload();
  }
}
