import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class PersonBankverbindungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "person-bankverbindung-edit";
        this.title = "person-bankverbindung-edit.person-bankverbindung-edit_caption";
        this.addModel({
            "id": "$m_Bankverbindung",
            "webApiAction": "ZIT/Objekte/PersonBankverbindung",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "mask": "aaaa aaaa aaaa aaaa aaaa aaaa aaaa aaaa",
            "maskChar": " ",
            "caption": "person-bankverbindung-edit.r_iban_caption",
            "binding": {
                "dataContext": "$m_Bankverbindung",
                "bindTo": "IBAN",
                "bindToFQ": "models.data.$m_Bankverbindung.IBAN"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_iban",
            "options": {
                "optionsName": "r_ibanOptions",
                "optionsNameFQ": "r_ibanOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "person-bankverbindung-edit.r_bic_caption",
            "binding": {
                "dataContext": "$m_Bankverbindung",
                "bindTo": "BIC",
                "bindToFQ": "models.data.$m_Bankverbindung.BIC"
            },
            "validationRules": [{
                "item": {
                    "type": "bic",
                    "parameters": []
                }
            }],
            "id": "r_bic",
            "options": {
                "optionsName": "r_bicOptions",
                "optionsNameFQ": "r_bicOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "person-bankverbindung-edit.r_waehrung_caption",
            "binding": {
                "dataContext": "$m_Bankverbindung",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Bankverbindung.IdWaehrung"
            },
            "validationRules": [],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-bankverbindung-edit.r_bankinstitut_caption",
            "binding": {
                "dataContext": "$m_Bankverbindung",
                "bindTo": "Bankinstitut",
                "bindToFQ": "models.data.$m_Bankverbindung.Bankinstitut"
            },
            "validationRules": [],
            "id": "r_bankinstitut",
            "options": {
                "optionsName": "r_bankinstitutOptions",
                "optionsNameFQ": "r_bankinstitutOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "person-bankverbindung-edit.r_eigentuemer_caption",
            "binding": {
                "dataContext": "$m_Bankverbindung",
                "bindTo": "Eigentuemer",
                "bindToFQ": "models.data.$m_Bankverbindung.Eigentuemer"
            },
            "validationRules": [],
            "id": "r_eigentuemer",
            "options": {
                "optionsName": "r_eigentuemerOptions",
                "optionsNameFQ": "r_eigentuemerOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "person-bankverbindung-edit.r_gueltigvon_caption",
            "binding": {
                "dataContext": "$m_Bankverbindung",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_Bankverbindung.GueltigVon"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigVon",
            "options": {
                "optionsName": "r_gueltigVonOptions",
                "optionsNameFQ": "r_gueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "person-bankverbindung-edit.r_gueltigbis_caption",
            "binding": {
                "dataContext": "$m_Bankverbindung",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_Bankverbindung.GueltigBis"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigBis",
            "options": {
                "optionsName": "r_gueltigBisOptions",
                "optionsNameFQ": "r_gueltigBisOptions"
            }
        });
        super.onConstructionFinished();
    }
}