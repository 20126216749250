import * as fwx from "../../../../framework/forms/form-export";
import {
    BewerbungEditUtils
} from "./bewerbung-edit-utils";

@fwx.autoinject
export class BewerbungLehreEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_BewerbungEditUtils: BewerbungEditUtils) {
        super(element, formBaseImport);
        this.id = "bewerbung-lehre-edit";
        this.title = "bewerbung-lehre-edit.bewerbung-lehre-edit_caption";
        this.addModel({
            "id": "$m_Bewerbung",
            "webApiAction": "ZIT/Objekte/Bewerbung",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'Stelleninserat': {
                            'columns': [
                                'Id',
                                'IdStelleninseratKopfElement'
                            ],
                            'expand': {
                                'Kopf': {
                                    'expand': {
                                        'Geschaeftspartner': {
                                            'expand': {
                                                'Geschaeftspartnerdaten': null
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        'Person': {
                            'columns': [
                                'Id'
                            ]
                        },
                        'Bewerbungsgrund': null,
                        'Bewerbungskanal': null,
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "BewerbungInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "StelleninseratInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddStelleninseratCard",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddPersonCard",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddBewerberSchnuppertermine",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_Person",
            "webApiAction": "ZIT/Objekte/Person",
            "modificationInfoEnabled": true,
            "key": "models.data.$m_Bewerbung.Kopf.IdPerson",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_DokumentRel",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "webApiExpand": {
                'Informationstyp': null
            },
            "webApiWhere": ["IdStelleninserat", {
                "isBound": true,
                "expression": "models.data.$m_Bewerbung.Kopf.IdStelleninserat"
            }],
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_DokumentEdit",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BewerbungEditUtils.actionCommand",
                "bindToFQ": "functions.$f_BewerbungEditUtils.actionCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BewerbungEditUtils.statusAendernPopupCommand",
                "bindToFQ": "functions.$f_BewerbungEditUtils.statusAendernPopupCommand"
            }
        });
        this.addFunction("$f_BewerbungEditUtils", $f_BewerbungEditUtils, "functions.$f_BewerbungEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "person",
            "filters": [],
            "caption": "bewerbung-lehre-edit.r_bewerbungperson_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdPerson",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdPerson",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bewerbungPerson",
            "options": {
                "optionsName": "r_bewerbungPersonOptions",
                "optionsNameFQ": "r_bewerbungPersonOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "stelleninserat",
            "filters": [],
            "caption": "bewerbung-lehre-edit.r_bewerbungstelleninserat_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdStelleninserat",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdStelleninserat",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bewerbungStelleninserat",
            "options": {
                "optionsName": "r_bewerbungStelleninseratOptions",
                "optionsNameFQ": "r_bewerbungStelleninseratOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "bewerbung-lehre-edit.r_taetigkeit_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Taetigkeit",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Taetigkeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_taetigkeit",
            "options": {
                "optionsName": "r_taetigkeitOptions",
                "optionsNameFQ": "r_taetigkeitOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "bewerbungsgrund",
            "filters": [],
            "caption": "bewerbung-lehre-edit.r_bewerbungsgrund_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdBewerbungsgrund",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdBewerbungsgrund",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Bewerbung",
                    "bindTo": "$f_BewerbungEditUtils.requiredValidationRule",
                    "bindToFQ": "functions.$f_BewerbungEditUtils.requiredValidationRule"
                }
            }],
            "id": "r_bewerbungsgrund",
            "options": {
                "optionsName": "r_bewerbungsgrundOptions",
                "optionsNameFQ": "r_bewerbungsgrundOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bewerbung-lehre-edit.r_bewerbungsdatum_caption",
            "isReadOnlyExpression": "models.data.$m_Bewerbung.Kopf.Id > 0",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Bewerbungsdatum",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Bewerbungsdatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_bewerbungsdatum",
            "options": {
                "optionsName": "r_bewerbungsdatumOptions",
                "optionsNameFQ": "r_bewerbungsdatumOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 200,
            "caption": "bewerbung-lehre-edit.r_wunschtermin_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Wunschtermin",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Wunschtermin",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Bewerbung.Kopf && models.data.$m_Bewerbung.Kopf._IsWunschterminPflicht"
                    }]
                }
            }],
            "id": "r_wunschtermin",
            "options": {
                "optionsName": "r_wunschterminOptions",
                "optionsNameFQ": "r_wunschterminOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "schnupperberufGeschaeftspartner",
            "customs": [{
                "key": "IdBewerbungKopfElement",
                "value": "models.data.$m_Bewerbung.Kopf.Id"
            }],
            "filters": [],
            "caption": "bewerbung-lehre-edit.r_schnupperberufgeschaeftspartner1_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdSchnupperberufGeschaeftspartner1",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdSchnupperberufGeschaeftspartner1",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Bewerbung.Kopf && models.data.$m_Bewerbung.Kopf._IsSchnupperberufGeschaeftspartner1Pflicht"
                    }]
                }
            }],
            "id": "r_schnupperberufGeschaeftspartner1",
            "options": {
                "optionsName": "r_schnupperberufGeschaeftspartner1Options",
                "optionsNameFQ": "r_schnupperberufGeschaeftspartner1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "schnupperberufGeschaeftspartner",
            "customs": [{
                "key": "IdBewerbungKopfElement",
                "value": "models.data.$m_Bewerbung.Kopf.Id"
            }],
            "filters": [],
            "caption": "bewerbung-lehre-edit.r_schnupperberufgeschaeftspartner2_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdSchnupperberufGeschaeftspartner2",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdSchnupperberufGeschaeftspartner2",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Bewerbung.Kopf && models.data.$m_Bewerbung.Kopf._IsSchnupperberufGeschaeftspartner2Pflicht"
                    }]
                }
            }],
            "id": "r_schnupperberufGeschaeftspartner2",
            "options": {
                "optionsName": "r_schnupperberufGeschaeftspartner2Options",
                "optionsNameFQ": "r_schnupperberufGeschaeftspartner2Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "ausbildung",
            "customs": [{
                "key": "IdBewerbungKopfElement",
                "value": "models.data.$m_Bewerbung.Kopf.Id"
            }],
            "filters": [],
            "caption": "bewerbung-lehre-edit.r_ausbildung_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdAusbildung",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdAusbildung",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Bewerbung.Kopf && models.data.$m_Bewerbung.Kopf._IsAusbildungPflicht"
                    }]
                }
            }],
            "id": "r_ausbildung",
            "options": {
                "optionsName": "r_ausbildungOptions",
                "optionsNameFQ": "r_ausbildungOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "caption": "bewerbung-lehre-edit.r_bemerkung_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Bemerkung",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Bemerkung",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bemerkung",
            "options": {
                "optionsName": "r_bemerkungOptions",
                "optionsNameFQ": "r_bemerkungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "stelleninseratKlasse",
            "filter": ["StelleninseratKopfElement.IdStelleninserat", {
                "isBound": true,
                "expression": "models.data.$m_Bewerbung.Kopf.IdStelleninserat"
            }],
            "filters": [],
            "caption": "bewerbung-lehre-edit.r_klasse_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdStelleninseratKlasse",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdStelleninseratKlasse",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_klasse",
            "options": {
                "optionsName": "r_klasseOptions",
                "optionsNameFQ": "r_klasseOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "bewerbung-lehre-edit.r_isbewerbungschnuppertermin_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IsBewerbungSchnuppertermin",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IsBewerbungSchnuppertermin",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_isBewerbungSchnuppertermin",
            "options": {
                "optionsName": "r_isBewerbungSchnupperterminOptions",
                "optionsNameFQ": "r_isBewerbungSchnupperterminOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "bewerbung-lehre-edit.r_isbewerbunglehrstelle_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IsBewerbungLehrstelle",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IsBewerbungLehrstelle",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_isBewerbungLehrstelle",
            "options": {
                "optionsName": "r_isBewerbungLehrstelleOptions",
                "optionsNameFQ": "r_isBewerbungLehrstelleOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "bewerbung-lehre-edit.r_hideintrefferliste_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "HideInTrefferliste",
                "bindToFQ": "models.data.$m_Bewerbung.HideInTrefferliste"
            },
            "validationRules": [],
            "id": "r_hideInTrefferliste",
            "options": {
                "optionsName": "r_hideInTrefferlisteOptions",
                "optionsNameFQ": "r_hideInTrefferlisteOptions"
            }
        });
        super.onConstructionFinished();
    }
}