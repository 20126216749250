import { autoinject } from "aurelia-framework";
import { FormPopupService } from '../../services/form-popup-service';
import { SimpleWidgetCreatorService, ICommandData } from '../../../framework/forms/export';
import { ScopeContainer, DataSourceService, IDataSourceOptionFilter, GlobalizationService } from '../../../framework/base/export';
import { IdxPopupComponent, IdxFormComponent } from '../../interfaces/export';

@autoinject
export class FormPopup {
  private _isSubmit: boolean;

  constructor(
    private _formPopupService: FormPopupService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _globalizationService: GlobalizationService,
    private _dataSourceService: DataSourceService
    ) {
      this._formPopupService.formPopup = this;
    }
    
  options: IFormPopupOptions;
  scopeContainer: ScopeContainer;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "auto",
    height: "auto",
    onShown: (e: any) => {
      e.component.repaint();
    },
    onHidden: (e: any) => {
      if (!this._isSubmit && this.options.onFormCancel) {
        this.options.onFormCancel();
      }
    }
  };

  scrollViewOptions: DevExpress.ui.dxScrollViewOptions = {
    height: "100%"
  };

  uebernehmenCommand: ICommandData = {
    id: "uebernehmenCommand",
    icon: "fas fa-check",
    sortIndex: 1000,
    isVisible: true,
    execute: () => {
      if (this.options.validationEnabled) {
        const r = this.form.instance.validate();
        if (!r.isValid) {
          return;
        }
      }

      if (this.options.onFormSubmit) {
        this.options.onFormSubmit(this.options.data);
      }

      this._isSubmit = true;
      this.popup.instance.hide();
    }
  }

  form: IdxFormComponent;
  formOptions: DevExpress.ui.dxFormOptions = {
    showColonAfterLabel: false,
    showRequiredMark: false,
    onInitialized: (ev) => {
      if (this.options.onFormInitialized) {
        this.options.onFormInitialized(ev.component);
      }
    },
    onFieldDataChanged: (ev) => {
      if (this.options.onFormDataChanged) {
        this.options.onFormDataChanged(ev);
      }
    }
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "formPopupToolbar",
      caption: "form-popup.titel",
      scopeContainer: this.scopeContainer,
      options: this.popupOptions,
      commands: [this.uebernehmenCommand]
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }
  
  show(options: IFormPopupOptions) {
    this._isSubmit = false;

    if (!options.data) {
      options.data = {};
    }

    this.options = options;

    if (options.validationEnabled == void(0) && typeof options.formDef === "string") {
      options.validationEnabled = true;
    }

    this.uebernehmenCommand.isVisible = options.onFormSubmit != void(0);

    this.updatePopupOptions();
    this.updateFormOptions();
    this.popup.instance.show();
  }

  private updatePopupOptions() {
    const options = <DevExpress.ui.dxPopupOptions>{
      width: this.options.width || "auto",
      height: this.options.height || "auto"
    };

    if (this.popup && this.popup.instance) {
      this.popup.setOption(options);
    } else {
      Object.assign(this.popupOptions, options);
    }
  }
  private updateFormOptions() {
    const options = <DevExpress.ui.dxFormOptions>{
      formData: this.options.data,
      items: this.getFormItems()
    };

    //OK, nicht ganz optimal, aber reicht für die Zwecke ;-)
    options.colCount = options.items.reduce((p, c) => {
      return c.colSpan && p < c.colSpan
        ? c.colSpan
        : p;
    }, 1);

    if (this.form && this.form.instance) {
      this.form.setOption(options);

      if (this.options.onFormInitialized) {
        this.options.onFormInitialized(this.form.instance);
      }
    } else {
      Object.assign(this.formOptions, options);
    }
  }
  private getFormItems(): DevExpress.ui.dxFormSimpleItem[] {
    if (this.options.formDef == "ansprechperson" || this.options.formDef == "ansprechpersonMitGueltigVon" || this.options.formDef == "ansprechpersonMitGueltigkeit") {      
      const ap = this.createSelectBox("ZIT/Objekte/Ansprechperson", "Titel", ["FunktionTagText"]);

      if (this.options.formDef == "ansprechpersonMitGueltigkeit") {
        delete ap.width;
      }

      const r: DevExpress.ui.dxFormSimpleItem[] = [{
        dataField: "item",
        editorType: "dxSelectBox",
        label: { visible: false },
        isRequired: true,
        colSpan: this.options.formDef == "ansprechpersonMitGueltigkeit" ? 2 : 1,
        editorOptions: ap
      }];

      if (this.options.formDef == "ansprechpersonMitGueltigVon" || this.options.formDef == "ansprechpersonMitGueltigkeit") {
        r.push({
          dataField: "gueltigVon",
          editorType: "dxDateBox",
          label: { text: "Gültig von" },
          isRequired: true,
          editorOptions: <DevExpress.ui.dxDateBoxOptions>{
            displayFormat: this._globalizationService.getFormatterParser("d")
          }
        });
      }
      if (this.options.formDef == "ansprechpersonMitGueltigkeit") {
        r.push({
          dataField: "gueltigBis",
          editorType: "dxDateBox",
          label: { text: "Gültig bis" },
          isRequired: true,
          editorOptions: <DevExpress.ui.dxDateBoxOptions>{
            displayFormat: this._globalizationService.getFormatterParser("d")
          }
        });
      }

      return r;
    } else if (this.options.formDef == "geschaeftspartner") {
      return [{
        dataField: "item",
        editorType: "dxSelectBox",
        label: { visible: false },
        isRequired: true,
        editorOptions: this.createSelectBox("ZIT/Objekte/Geschaeftspartner", "Name1")
      }];
    } else if (this.options.formDef == "person") {
      return [{
        dataField: "item",
        editorType: "dxSelectBox",
        label: { visible: false },
        isRequired: true,
        editorOptions: this.createSelectBox("ZIT/Objekte/Person", "Titel")
      }];
    } else if (this.options.formDef == "stelleninserat") {
      return [{
        dataField: "item",
        editorType: "dxLookup",
        label: { visible: false },
        isRequired: true,
        editorOptions: <DevExpress.ui.dxLookupOptions>{
          closeOnOutsideClick: true,
          searchEnabled: true,
          searchMode: "contains",
          itemTemplate: "zit-stelleninserat-template",
          displayExpr: "Titel",
          width: "500px",
          dataSource: this._dataSourceService.createDataSource(
            this.scopeContainer, {
              webApiAction: "ZIT/Objekte/Stelleninserat",
              webApiColumns: ["Id", "Titel"],   
              webApiSearchtextEnabled: true,
              filters: [{webApiCustomKey: "AddStelleninseratCard", webApiCustomValue: "true"}]
            }
          )
        }
      }];
    } else if (Array.isArray(this.options.formDef)) {
      return this.options.formDef;
    } else {
      return [this.options.formDef];
    }
  }
  private createSelectBox(webApiUrl: string, displayExpr: string, additionalColumns: string[] = []) {
    return <DevExpress.ui.dxSelectBoxOptions>{
      displayExpr: displayExpr,
      searchExpr: displayExpr,
      searchEnabled: true,
      width: "300px",
      dataSource: this.createDataSource(webApiUrl, displayExpr, additionalColumns)
    };
  }
  private createDataSource(webApiUrl: string, displayExpr: string, additionalColumns: string[] = []) {
    return this._dataSourceService.createDataSource(
      this.scopeContainer, {
        webApiAction: webApiUrl,
        webApiColumns: this.options.webApiColumns || ["Id", displayExpr, ...additionalColumns],
        webApiWhere: this.options.webApiWhere,
        keyProperty: "Id",
        filters: this.options.webApiFilters
      }
    );
  }
}

export interface IFormPopupOptions {
  titel: string;
  formDef: any[] | object | "ansprechperson" | "ansprechpersonMitGueltigVon" | "ansprechpersonMitGueltigkeit" | "geschaeftspartner" | "person" | "stelleninserat";
  data?: any;
  webApiWhere?: any[];
  webApiColumns?: string[];
  webApiFilters?: IDataSourceOptionFilter[];
  width?: string;
  height?: string;
  validationEnabled?: boolean;

  onFormInitialized?: {(form: DevExpress.ui.dxForm)};
  onFormDataChanged?: {(e: {component?: DevExpress.ui.dxForm, element?: DevExpress.core.dxElement, model?: any, dataField?: string, value?: any})};

  onFormSubmit?: {(model: any): void};
  onFormCancel?: {(): void};
}
