import { autoinject } from "aurelia-framework";

@autoinject
export class DateiAuswaehlen {
  constructor(
    private _element: Element
  ) {}

  input: HTMLInputElement;

  onMakePhoto(multiple: boolean = false, asGallery: boolean = false) {
    this.onSelect("camera", multiple, asGallery);
  }
  onMakeVideo(multiple: boolean = false, asGallery: boolean = false) {
    this.onSelect("video", multiple, asGallery);
  }
  onOpenGallery(multiple: boolean = false) {
    this.onSelect("camera", multiple, true);
  }
  onOpenFiles(multiple: boolean = false) {
    this.onSelect("file", multiple, false);
  }

  async onFileChange(e) {
    const files = e.target.files;
    if (!files) {
      return;
    }
    if (!files.length) {
      return;
    }

    this.dispatchEvent(files);
  }

  private onSelect(type: "camera" | "file" | "video" = "camera", multiple: boolean, asGallery: boolean) {
    this.input.value = null;
    const input = this._element.querySelector("input");

    input.removeAttribute("capture");
    input.removeAttribute("accept");
    input.removeAttribute("multiple");

    if (type == "camera") {
      input.setAttribute("accept", "image/*");
    }
    if (type == "video") {
      input.setAttribute("accept", "video/*");
    }

    if (!asGallery) {
      if (type == "camera") {
        input.setAttribute("capture", "camera");
      }
      if (type == "video") {
        input.setAttribute("capture", "camcorder");
      }
    }

    if (multiple) {
      input.setAttribute("multiple", "true");
    }

    input.click();
  }
  private dispatchEvent(files: File[]) {
    this._element.dispatchEvent(new CustomEvent(
      "on-datei-ausgewaehlt", {
        bubbles: true,
        detail: {
          files: files
        }
      }
    ));
  }
}