import { BrowserService } from "./../../../framework/base/services/browser-service";
import { LocalizationService } from "./../../../framework/base/services/localization-service";
import { SucheService } from "./../../../zit/services/suche-service";
import { autoinject, computedFrom, TaskQueue } from "aurelia-framework";
import { IdxPopoverComponent, IdxTextBoxComponent } from "../../../zit/interfaces/export";
import { RestService } from "../../../framework/base/services/rest-service";
import { ErweiterteSuche } from "../../../zit/elements/erweiterte-suche/erweiterte-suche";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { SucheViewTyp } from "../../../zit/enumerations/export";

@autoinject
export class Suche {
  private MAX_HISTORIE_LAENGE = 5;

  private _sucheChangeValueSubscription: Subscription;
  private _sucheClearSubscription: Subscription;
  private _onValueChangedTimeout: any;

  constructor(
    public browserService: BrowserService,
    private _eventAggregator: EventAggregator,
    private _localizationService: LocalizationService,
    private _restService: RestService,
    private _sucheService: SucheService,
    private _taskQueue: TaskQueue
  ) {
    this.loadVolltextSuchHistorie();
    this.objektFullName = this._sucheService.lastObjektFullName;
  }

  @computedFrom("objektFullName")
  get isErweiterteSucheEnabled() {
    return this.objektFullName != void 0;
  }
  @computedFrom("erweiterteSuche.wertQueryList")
  get hasErweiterteSucheFilters() {
    return this.erweiterteSuche
      && this.erweiterteSuche.wertQueryList != void 0;
  }

  erweiterteSuche: ErweiterteSuche;
  objektFullName: string;

  sucheHistory: string[];

  sucheHistoryPopover: IdxPopoverComponent;
  sucheHistoryPopoverOptions: DevExpress.ui.dxPopoverOptions = {
    target: "#sucheTextBox",
    contentTemplate: "contentTemplate",
    position: {
      at: {
        x: "left",
        y: "bottom"
      },
      my: {
        x: "left",
        y: "top"
      }
    }
  };

  sucheTextBox: IdxTextBoxComponent;
  sucheTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    placeholder: this._localizationService.translateOnce("base.search"),
    elementAttr: {
      class: "z--suche"
    },
    mode: "search",
    value: this._sucheService.lastSearchValue,
    onValueChangedByUser: (e) => {
      this.onValueChanged(e.value);
    },
    onKeyUp: (e) => {
      if (!e.event) {
        return;
      }
      if ((<any>e.event).key != "Enter") {
        return;
      }

      this.onValueChanged(e.component.option("value"));
    },
    onFocusIn: () => {
      this.sucheHistoryPopover.instance.show();
    },
    bindingOptions: {
      disabled: "!isErweiterteSucheEnabled"
    }
  };

  attached() {
    this._sucheChangeValueSubscription = this._eventAggregator.subscribe("suche:change-value", (e) => {
      if (this._sucheService.lastSearchText != e.value) {
        this.sucheTextBox.setOption({ value: e.value });
      }

      this.executeSuche(e.viewTyp);
    });
    this._sucheClearSubscription = this._eventAggregator.subscribe("suche:clear", () => {
      this.sucheTextBox.setOption({ value: "" });
      this.clearSuche();
    });
  }
  detached() {
    if (this._sucheChangeValueSubscription) {
      this._sucheChangeValueSubscription.dispose();
      this._sucheChangeValueSubscription = null;
    }
    if (this._sucheClearSubscription) {
      this._sucheClearSubscription.dispose();
      this._sucheClearSubscription = null;
    }
  }

  clearSuche() {
    this.objektFullName = null;
    this._sucheService.clearSuche();
  }
  executeSuche(viewTyp: SucheViewTyp = SucheViewTyp.list) {
    const newSearchValue = this.sucheTextBox.instance.option("value");
    const wertQueryList = this.erweiterteSuche.wertQueryList;
    const newObjektFullName = this._sucheService.getObjektFullName(newSearchValue);
    const newSearchText = this._sucheService.getSearchText(newSearchValue);

    if (newObjektFullName != this.objektFullName) {
      this.erweiterteSuche.clearSuche();
      this.objektFullName = newObjektFullName;

      this._taskQueue.queueTask(() => {
        this._sucheService.executeSuche(newSearchValue, this.objektFullName, newSearchText, wertQueryList, viewTyp);
      });
    } else {
      this._sucheService.executeSuche(newSearchValue, this.objektFullName, newSearchText, wertQueryList, viewTyp);
    }
  }

  onValueChanged(value: string) {
    if (this._onValueChangedTimeout) {
      return;
    }

    this._onValueChangedTimeout = setTimeout(() => {
      this._onValueChangedTimeout = null;
    }, 500);

    this.executeSuche();
    this.updateHistorySuche(value);
    this.sucheHistoryPopover.instance.hide();
  }
  onErweiterteSucheClicked(event: Event) {
    if (!this.isErweiterteSucheEnabled) {
      return false;
    }

    this.erweiterteSuche.showPopup();
  }
  onSucheFilterClicked(event: Event) {
    if (!this.isErweiterteSucheEnabled) {
      return false;
    }

    this.erweiterteSuche.showVorlageContextMenu(event, true);
  }
  onSucheHistorySelected(sucheText: string) {
    this.sucheTextBox.setOption({ value: sucheText });
    this.executeSuche();
    this.sucheHistoryPopover.instance.hide();
  }
  onExecuteSucheErweitert(event: any) {
    this.executeSuche();
  }

  private async loadVolltextSuchHistorie() {
    this.sucheHistory = await this._restService.get({
      url: this._restService.getApiUrl("ZIT/Volltext/SuchHistorie")
    });
  }
  private updateHistorySuche(sucheValue: string) {
    if (!sucheValue) {
      return;
    }

    const exists = this.sucheHistory.find((c) => c == sucheValue);
    if (exists) {
      return;
    }
    this.sucheHistory.splice(0, 0, sucheValue);

    if (this.sucheHistory.length > this.MAX_HISTORIE_LAENGE) {
      this.sucheHistory.splice(this.MAX_HISTORIE_LAENGE);
    }

    this._restService.post({
      url: this._restService.getApiUrl("ZIT/Volltext/SuchHistorie"),
      data: {
        Suchtext: sucheValue
      }
    });
  }
}
