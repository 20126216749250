import {
  autoinject,
  bindable,
  customAttribute,
  observable,
  OverrideContext,
  Scope,
  TemplatingEngine,
  transient,
  View
} from "aurelia-framework";
import {
  LocalizationService, TemplatingExService
} from "../../services/export";
import {
  ScopeContainer
} from "../../classes/scope-container"
import { MarkdownService } from '../../services/markdown-service';

@autoinject
@customAttribute("tr")
export class TrCustomAttribute {
  private _createdSpan: Element;
  private _createdView: View;
  private _isBound: boolean = false;

  constructor(
    private element: Element,
    private localization: LocalizationService,
    private templatingEngine: TemplatingEngine,
    private templatingExService: TemplatingExService,
    private markdownService: MarkdownService
  ) { }

  owningView: any;

  @bindable mode: string;
  @bindable @observable key: any;
  @bindable scope: Scope;
  @bindable markdown: boolean;
  @bindable isHtml: boolean;

  created(owningView: any, myView: any) {
    this.owningView = owningView;
  }
  bind(bindingContext: any, overrideContext: OverrideContext) {
    this._isBound = true;

    if (!this.scope) {
      this.scope = {
        bindingContext,
        overrideContext
      };
    }

    this.translate();
  }
  unbind() {
    this._isBound = false;
    this.scope = null;

    this.destroyCreated();
  }

  keyChanged() {
    if (!this._isBound) {
      return;
    }

    this.destroyCreated();
    this.translate();
  }

  private destroyCreated() {
    if (this._createdSpan) {
      this._createdSpan.parentElement.removeChild(this._createdSpan);
      this._createdSpan = null;
    }

    if (this._createdView) {
      this.templatingExService.destroyView(this._createdView);
      this._createdView = null;
    }
  }
  private translate() {
    if (!this.key) {
      return;
    }

    const info = this.localization.getTranslationInfo(this.key);

    if (info.needsBinding) {
      this._createdSpan = document.createElement("span");

      if (this.isHtml) {
        this._createdSpan.setAttribute("innerhtml", this.getText(info.text));
      } else {
        this._createdSpan.innerHTML = this.getText(info.text);
      }
      this.element.appendChild(this._createdSpan);

      const scope = info.scope || this.scope;

      this._createdView = this.templatingEngine.enhance({
        element: this._createdSpan,
        resources: this.owningView.resources,
        bindingContext: scope.bindingContext,
        overrideContext: scope.overrideContext
      });
    } else {
      this.element.innerHTML = this.getText(info.text);
    }
  }

  private getText(text: string) {
    if (!this.markdown) {
      return text;
    }

    return this.markdownService.replace(text);
  }
}
