import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class PersonFuehrerscheinEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "person-fuehrerschein-edit";
        this.title = "person-fuehrerschein-edit.person-fuehrerschein-edit_caption";
        this.addModel({
            "id": "$m_Fuehrerschein",
            "webApiAction": "ZIT/Objekte/PersonFuehrerschein",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "fuehrerscheinklasse",
            "filters": [],
            "caption": "person-fuehrerschein-edit.r_fuehrerscheinklasse_caption",
            "binding": {
                "dataContext": "$m_Fuehrerschein",
                "bindTo": "IdFuehrerscheinklasse",
                "bindToFQ": "models.data.$m_Fuehrerschein.IdFuehrerscheinklasse"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_fuehrerscheinklasse",
            "options": {
                "optionsName": "r_fuehrerscheinklasseOptions",
                "optionsNameFQ": "r_fuehrerscheinklasseOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "person-fuehrerschein-edit.r_gueltigbis_caption",
            "binding": {
                "dataContext": "$m_Fuehrerschein",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_Fuehrerschein.GueltigBis"
            },
            "validationRules": [],
            "id": "r_gueltigBis",
            "options": {
                "optionsName": "r_gueltigBisOptions",
                "optionsNameFQ": "r_gueltigBisOptions"
            }
        });
        super.onConstructionFinished();
    }
}