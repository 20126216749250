import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { transient } from "aurelia-framework";
import { AuthorizationService } from "../../../../../framework/base/export";
import { autoinject, FormBase } from "../../../../../framework/forms/form-export";
import { LoginprofilService } from "../../../../../zit/services/export";
import { BrowserService } from "./../../../../../framework/base/services/browser-service";
import { LocalizationService } from "./../../../../../framework/base/services/localization-service";
import { LocationService } from "./../../../../../framework/base/services/location-service";
import { RestService } from "./../../../../../framework/base/services/rest-service";
import { ICommandData } from "./../../../../../framework/forms/interfaces/command-data";
import { HistoryService } from "./../../../../../framework/forms/services/history-service";
import { DomaeneService } from "./../../../../services/domaene-service";

@autoinject
@transient()
export class LoginTanFuncs {
  private _form: FormBase;
  private _appChangedSubscription: Subscription;

  constructor(
    public browserService: BrowserService,
    private _locationService: LocationService,
    private _localizationService: LocalizationService,
    private _loginprofilService: LoginprofilService,
    private _authorizationService: AuthorizationService,
    private _restService: RestService,
    private _historyService: HistoryService,
    private _eventAggregator: EventAggregator,
    private _domaeneService: DomaeneService) {
    const time = new Date()
      .getTime()
      .toString();
    const randomNumberString = Math.floor((Math.random() * 100))
      .toString();
    const randomKey = time + randomNumberString;
    this.logoSrc = this._restService.getApiUrl(`ZIT/Domaene/Logo?key=${randomKey}`);
  }

  urlImpressum: string;
  farbeDomaene: string;
  bezeichnungLoginMobil: string;
  logoSrc: string;
  goToUrlAfterLogin: string;
  username: string;
  isMobile: boolean;

  loginCommand: ICommandData = {
    id: "$login",
    title: "login-form-funcs.anmelden_caption",
    execute:() => {
      this.doLogin();
    }
  };

  zuerueckCommand: ICommandData = {
    id: "zuerueckZuLogin",
    title: "zit-ui.login-loginprofil-zuerueck",
    execute: () => {
      this.goBackToLogin();
    }
  };

  openImpressumClick() {
    window.open(this.urlImpressum);
  }

  async bind(form: FormBase) {
    this._form = form;

    const hasCustomOptions = this._form.viewItemInfo
      && this._form.viewItemInfo.routeInfo
      && this._form.viewItemInfo.routeInfo.customOptions;

    const hasUsernameInfo = hasCustomOptions
      && this._form.viewItemInfo.routeInfo.customOptions.Username;

    if (hasUsernameInfo) {
      this.username = this._form.viewItemInfo.routeInfo.customOptions.Username;
    } else {
      this.goBackToLogin();
    }

    const hasGoToUrlAfterLogin = hasCustomOptions
      && this._form.viewItemInfo.routeInfo.customOptions.goToUrlAfterLogin;

    if (hasGoToUrlAfterLogin) {
      this.goToUrlAfterLogin = this._form.viewItemInfo.routeInfo.customOptions.goToUrlAfterLogin;
    }

    this._appChangedSubscription = this._eventAggregator.subscribeOnce("authorization:change-app", (args) => {
      args.isHandled = true;
    });

    this.urlImpressum = this._domaeneService.urlImpressum;
    this.farbeDomaene = this._domaeneService.farbeDomaene;
    this._domaeneService.setDomainColorsStyle();
    this.bezeichnungLoginMobil = this._domaeneService.bezeichnungMobilLogin;
  }

  unbind() {
    this._appChangedSubscription.dispose();
    this._appChangedSubscription = null;
  }

  private async doLogin() {
    const smsTan = this._form["tan"]
      .instance
      .element()
      .querySelector("input")
      .value;

    const loginSuccess = await this._authorizationService.login({
      Username: this.username,
      Password: smsTan
    });
    if (!loginSuccess) {
      return;
    }

    const hasMoreThanOne = await this._loginprofilService.hasMultipleLoginprofil();

    this._historyService.pipelineUrl = null;

    if (hasMoreThanOne) {
      this._locationService.goTo({
        url: "Loginprofil",
        clearStack: true
      });

      if (this.goToUrlAfterLogin) {
        this._historyService.pipelineUrl = this.goToUrlAfterLogin;
      }
    } else {
      if (this.goToUrlAfterLogin) {
        this._historyService.pipelineUrl = this.goToUrlAfterLogin;
      }

      this._authorizationService.isLoggedInChanged(true);
    }
  }

  private goBackToLogin() {
    this._locationService.goTo({
      url: "Login",
      clearStack: true
    });
  }
}
