import { autoinject, bindable } from 'aurelia-framework';
import { JsonService, BindingService } from '../../../framework/base/services/export';
import { ErweiterteSucheService } from '../../services/export';
import { ListView } from '../../../framework/forms/export';
import { IListViewOptions } from '../../../framework/forms/elements/list-view/export';
import { IZitTagOptions } from '../../../zit-ui/elements/zit-tag/export';
import { ErweiterteSuche } from '../erweiterte-suche/erweiterte-suche';
import { ScopeContainer } from '../../../framework/base/export';

@autoinject
export class ErweiterteSucheFilterListView {
  private _itemList: any[];

  constructor(
    private element: Element,
    private erweiterteSucheService: ErweiterteSucheService,
    private json: JsonService,
    private bindingService: BindingService
  ) { }

  scopeContainer: ScopeContainer;

  @bindable erweiterteSuche: ErweiterteSuche;

  queryTagOptions: IZitTagOptions = {
    textExpression: "item._bezeichnung",
    onDeleteClick: (e, queryItem) => {
      const indexOf = this.erweiterteSuche.selectedFilterList.findIndex(f => f.filter.Id == queryItem.Id);
      if (indexOf < 0) {
        return;
      }

      this.erweiterteSuche.selectedFilterList.splice(indexOf, 1);
      this.erweiterteSuche.executeSuche();

      const event = new CustomEvent("on-filter-deleted", {
        detail: {
          sender: this,
          wertQueryList: this.erweiterteSuche.wertQueryList
        },
        bubbles: true
      });

      this.element.dispatchEvent(event);
    }
  };

  queryListView: ListView;
  queryListViewOptions: IListViewOptions = {
    showReloadButton: false,
    useDefaultListItemStyle: false,
    itemClass: "z--tag-list-view",
    dataSource: new DevExpress.data.DataSource(
      <any>new DevExpress.data.CustomStore({
        loadMode: "raw",
        load: () => {
          return new Promise((resolve) => {
            resolve(this._itemList);
          });
        }
      })
    )
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.bindingService.observe({
      scopeContainer: this.scopeContainer,
      expression: "erweiterteSuche.wertQueryList",
      callback: () => {
        this.onSucheChanged();
      }
    });

    if (this.erweiterteSuche) {
      this.onSucheChanged();
    }
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  private onSucheChanged() {
    this._itemList = [];

    if (this.erweiterteSuche && this.erweiterteSuche.wertQueryList) {
      this.erweiterteSuche.wertQueryList.forEach(i => {
        const feld = this.erweiterteSuche.filterList.find(f => f.Id == i.Id);
        if (!feld) {
          return;
        }

        const newItem = Object.assign({}, i);
        newItem._bezeichnung = this.erweiterteSucheService.getFilterBezeichnung(feld, i.Operator, i.Value);
        this._itemList.push(newItem);
      });
    }

    this.queryListViewOptions.dataSource.reload();
  }
}
