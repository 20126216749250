import { autoinject, bindable } from 'aurelia-framework';
import { CustomEditPopupService } from '../../services/export';
import { LocalizationService } from '../../../framework/base/export';
import { ICommandData } from '../../../framework/forms/export';
import { IdxContextMenuComponent } from '../../interfaces/export';
@autoinject
export class GeschaeftspartnerErweiterteOptionen {
  constructor(
    private customEditPopup: CustomEditPopupService,
    private localization: LocalizationService
  ) { }

  @bindable idGeschaeftspartner: number;

  erweiterteContextMenu: IdxContextMenuComponent;
  erweiterteContextMenuOptions: DevExpress.ui.dxContextMenuOptions = {
    showEvent: null,
    position: {
      my: "top",
      at: "bottom"
    },
    items: [
      {
        text: this.localization.translateOnce("geschaeftspartner-erweiterte-optionen.entlohnungschema-aendern")
      },
      {
        text: this.localization.translateOnce("geschaeftspartner-erweiterte-optionen.gruppierung-aendern")
      }
    ],
    onItemClick: (e) => {
      if (e.itemIndex === 0) {
        this.showEntlohnungschemaPopup();
      } else {
        this.showGruppierungPopup();
      }
    }
  }

  showPopover(target: EventTarget) {
    this.erweiterteContextMenu.setOption({ "target": target });
    
    this.erweiterteContextMenu.instance.show();
  }

  showGruppierungPopup() {
    this.customEditPopup.geschaeftspartnerGruppierung.show({
      mappings: {
        "$v_idGeschaeftspartner": this.idGeschaeftspartner
      }
    });
  }
  showEntlohnungschemaPopup() {
    this.customEditPopup.geschaeftspartnerEntlohnungsschema.show({
      mappings: {
        "$v_idGeschaeftspartner": this.idGeschaeftspartner
      }
    });
   }
}
