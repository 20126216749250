import { autoinject } from "aurelia-framework";
import { SimpleWidgetCreatorService, ICommandData } from '../../../framework/forms/export';
import { ScopeContainer, FileService } from '../../../framework/base/export';
import { IdxPopupComponent } from '../../interfaces/export';
import { DateiAuswaehlen } from '../datei-auswaehlen/datei-auswaehlen';
import { IDatei } from '../datei/datei';
import { IZitTagOptions } from '../../../zit-ui/elements/zit-tag/zit-tag-options';

@autoinject
export class DateiGalerie {
  private _options: IDateiGalerieShowOptions;
  private _dateiList: IDatei[];

  constructor(
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _fileService: FileService
  ) {}

  scopeContainer: ScopeContainer;
  dateiAuswaehlen: DateiAuswaehlen;
  
  fotoCommand: ICommandData = {
    id: "kameraCommand",
    idCategory: "$start",
    icon: "fas fa-camera",
    sortIndex: 2000,
    execute: () => {
      this.dateiAuswaehlen.onMakePhoto(true, true);
    }
  };
  videoCommand: ICommandData = {
    id: "videoCommand",
    idCategory: "$start",
    icon: "fas fa-video",
    sortIndex: 2001,
    execute: () => {
      this.dateiAuswaehlen.onMakeVideo(true, true);
    }
  };
  saveCommand: ICommandData = {
    id: "saveCommand",
    idCategory: "$start",
    icon: "fas fa-check",
    sortIndex: 3000,
    execute: () => {
      this.save();
    }
  };

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "500px",
    height: "auto"
  };

  bezeichnung: string;
  galerieTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    maxLength: 100,
    bindingOptions: {
      value: "bezeichnung"
    }
  };

  tagItem = {
    text: ""
  }
  dateiTagOptions: IZitTagOptions = {
    icon: {
      text: "D"
    },
    textExpression: "item.text"
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "dateiGaleriePopupToolbar",
      caption: "datei-galerie.galerie",
      options: this.popupOptions,
      commands: [this.fotoCommand, this.videoCommand, this.saveCommand],
      scopeContainer: this.scopeContainer
    });
  }

  show(options: IDateiGalerieShowOptions) {
    this._options = options;
    this.bezeichnung = options.bezeichnung;
    this._dateiList = options.dateiList || [];
    this.refresh();

    this.popup.instance.show();
  }

  save() {
    if (!this.bezeichnung) {
      DevExpress.ui.notify(
        "Bezeichnung ist ein Pflichtfeld",
        "error",
        3000
      );

      return;
    }

    this._dateiList.forEach(d => d.Gruppe = this.bezeichnung);
    this._options.callback(this._dateiList);
    this.popup.instance.hide();
  }

  async onDateiAusgewaehlt(e: CustomEvent) {
    for (let file of <File[]>e.detail.files) {
      const dmsLink = await this._fileService.upload(file);
      if (!dmsLink) {
        return;
      }

      this._dateiList.push({ DMSLink: dmsLink, Dateiname: file.name });
    }
    
    this.refresh();
  }

  private refresh() {
    this.tagItem.text = this._dateiList.length == 0
      ? "Keine Elemente vorhanden"
      : (this._dateiList.length == 1
          ? "1 Element vorhanden"
          : `${this._dateiList.length} Elemente vorhanden`);
  }
}

export interface IDateiGalerieShowOptions {
  bezeichnung?: string;
  dateiList?: IDatei[];

  callback: {(dateiList: IDatei[]): void};
}