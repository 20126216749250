import * as fwx from "../../../../framework/forms/form-export";
import {
    AnsprechpersonReadUtils
} from "./ansprechperson-read-utils";

@fwx.autoinject
export class AnsprechpersonReadForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_AnsprechpersonReadUtils: AnsprechpersonReadUtils) {
        super(element, formBaseImport);
        this.id = "ansprechperson-read";
        this.title = "ansprechperson-read.ansprechperson-read_caption";
        this.addModel({
            "id": "$m_Ansprechperson",
            "webApiAction": "ZIT/Objekte/Ansprechperson",
            "webApiExpand": {
                'Personendaten': null,
                'Ansprechpersondaten': null
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": false,
            "allowNew": "false",
            "allowSave": "false",
            "allowDelete": "false",
            "filters": [{
                "webApiCustomKey": "AddZugeteilteGeschaeftspartner",
                "webApiCustomValue": "true"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AnsprechpersonReadUtils.actionCommand",
                "bindToFQ": "functions.$f_AnsprechpersonReadUtils.actionCommand"
            }
        });
        this.addFunction("$f_AnsprechpersonReadUtils", $f_AnsprechpersonReadUtils, "functions.$f_AnsprechpersonReadUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}