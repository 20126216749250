export interface IZitCardOptions {
  statusColor?: string;
  statusColorExpression?: string;
  onClick?: { (event: MouseEvent, data: any): void };
  isClickEnabled?: boolean;
  isClickEnabledExpression?: string;
  dmsLinkExpression?: string;
  dmsLink?: string;
  cardTemplateName?: string;
  markersCardOptions?: {
    markersList?: any;
    markersListExpression?: string;
    onClick?: { (event: MouseEvent, data: any): void };
  }
  showRibbon?: boolean;
  ribbon?: IZitCardRibbon;
  typ?: ZitCardTyp;
  useDefaultBindings?: boolean;
  isSuche?: boolean;
  showNachrichten?: boolean;
}

interface IZitCardRibbon {
  backgroundColor: string;
  icon: string;
}
export enum ZitCardTyp {
  Auto = "Auto",
  Gross = "Gross",
  Kompakt = "Kompakt",
  Klein = "Klein"
}
