import {
  autoinject,
  bindable,
  bindingMode,
  computedFrom,
  Scope
} from "aurelia-framework";
import { ModelUtilsService, IHtmlEditorOptions } from '../../export';
import { HtmlEditorService } from '../../services/html-editor-service';
import { ScopeContainer } from '../../../base/export';

@autoinject
export class HtmlEditor {
  constructor(
    private element: Element,
    private htmlEditorService: HtmlEditorService,
    private modelUtils: ModelUtilsService
  ) {}

  hasBottomToolbar: boolean;

  @bindable options: IHtmlEditorOptions;
  @bindable mainModel: any;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value: any;

  @computedFrom("options.isReadOnly", "mainModel.CanSave")
  get isReadOnlyChecked() {
    return !this.options
      || this.options.isReadOnly === true
      || (this.mainModel && this.mainModel.CanSave === false);
  }

  htmlEditor: any;
  htmlEditorOptions: DevExpress.ui.dxHtmlEditorOptions;
  scope: Scope;
  scopeContainer: ScopeContainer;

  bind(bindingContext, overrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.htmlEditorOptions = {};
    this.htmlEditorService.configureHtmlEditor(
      this.scopeContainer,
      this.options,
      this.htmlEditorOptions
    );

    if (!this.htmlEditorOptions.bindingOptions) {
      this.htmlEditorOptions.bindingOptions = {};
    }
    this.htmlEditorOptions.bindingOptions.value = "value";
    this.htmlEditorOptions.bindingOptions.readOnly = "isReadOnlyChecked";
    this.htmlEditorOptions.onValueChangedByUser = (e) => {
      if (this.mainModel) {
        this.modelUtils.setDirty(this.mainModel);
      }
    };
  }
  attached() {
    const element: any = this.element;

    const slots = element.au.controller.view.slots;
    this.hasBottomToolbar = slots
      && slots.bottomToolbar
      && slots.bottomToolbar.children
      && slots.bottomToolbar.children.length > 0;
  }
  detached() {
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
    this.scope = null;
  }

  updateVariables(variables: string) {
    if (variables == void(0)) {
      variables = "";
    }

    if (this.htmlEditor && this.htmlEditor.instance) {
      this.htmlEditor.instance.option("variables.dataSource", variables.split(";").filter(c => !!c));
    } else {
      this.options.variables = variables;
    }
  }
}
