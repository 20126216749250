import * as fwx from "../../../../framework/forms/form-export";
import {
    BewerbungEditUtils
} from "./bewerbung-edit-utils";

@fwx.autoinject
export class BewerbungHeartbaseErweiterungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_BewerbungEditUtils: BewerbungEditUtils) {
        super(element, formBaseImport);
        this.id = "bewerbung-heartbase-erweitert-edit";
        this.title = "bewerbung-heartbase-erweitert-edit.bewerbung-heartbase-erweitert-edit_caption";
        this.addModel({
            "id": "$m_Bewerbung",
            "webApiAction": "ZIT/Objekte/Bewerbung",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'BewerbungTrainer': {
                            'expand': {
                                'Ansprechperson': null
                            }
                        },
                        'Stelleninserat': {
                            'columns': [
                                'Id',
                                'IdStelleninseratKopfElement'
                            ],
                            'expand': {
                                'Kopf': {
                                    'expand': {
                                        'Geschaeftspartner': {
                                            'expand': {
                                                'Geschaeftspartnerdaten': null
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        'Person': {
                            'expand': {
                                'Personendaten': {
                                    'expand': {
                                        'Nationalitaet': null,
                                        'Schuhgroesse': null,
                                        'Kleidergroesse': null,
                                        'Familienstand': null
                                    }
                                },
                                'Adressen': {
                                    'expand': {
                                        'Land': null
                                    },
                                    'where': [
                                        [
                                            [
                                                'GueltigVon',
                                                '<=',
                                                '@Today()'
                                            ],
                                            'or',
                                            [
                                                'GueltigVon',
                                                '=',
                                                'null'
                                            ]
                                        ],
                                        [
                                            [
                                                'GueltigBis',
                                                '>=',
                                                '@Today()'
                                            ],
                                            'or',
                                            [
                                                'GueltigBis',
                                                '=',
                                                'null'
                                            ]
                                        ]
                                    ]
                                },
                                'Kontakte': {
                                    'expand': {
                                        'Kontakttyp': null
                                    }
                                },
                                'Sprache': {
                                    'expand': {
                                        'Sprachen': {
                                            'expand': {
                                                'Sprachniveau': null,
                                                'Sprache': null
                                            }
                                        }
                                    }
                                },
                                'Berufserfahrungen': {
                                    'expand': {
                                        'Berufserfahrungen': null
                                    }
                                },
                                'GeschaeftspartnerEinsaetze': {
                                    'expand': {
                                        'Geschaeftspartner': null
                                    }
                                },
                                'Skills': {
                                    'expand': {
                                        'Skill': null
                                    }
                                },
                                'Berufe': {
                                    'expand': {
                                        'Berufe': {
                                            'expand': {
                                                'Beruf': null
                                            }
                                        },
                                        'AusbildungAbschluss': null,
                                        'BerufQualifikation': null
                                    }
                                },
                                'Fuehrerschein': {
                                    'expand': {
                                        'Fuehrerscheine': {
                                            'expand': {
                                                'Fuehrerscheinklasse': null
                                            }
                                        }
                                    }
                                },
                                'Ausbildung': {
                                    'expand': {
                                        'Ausbildungen': {
                                            'expand': {
                                                'Ausbildung': null,
                                                'AusbildungAbschluss': null
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        'Bewerbungsgrund': null,
                        'Bewerbungskanal': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "BewerbungInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "StelleninseratInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddStelleninseratCard",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddBewerberSchnuppertermine",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddPersonCard",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "CanBenutzeraccountAktivieren",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_Person",
            "webApiAction": "ZIT/Objekte/Person",
            "modificationInfoEnabled": true,
            "key": "models.data.$m_Bewerbung.Kopf.IdPerson",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_DokumentRel",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "webApiExpand": {
                'Informationstyp': null
            },
            "webApiWhere": ["IdStelleninserat", {
                "isBound": true,
                "expression": "models.data.$m_Bewerbung.Kopf.IdStelleninserat"
            }],
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_DokumentEdit",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BewerbungEditUtils.actionCommand",
                "bindToFQ": "functions.$f_BewerbungEditUtils.actionCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BewerbungEditUtils.statusAendernPopupCommand",
                "bindToFQ": "functions.$f_BewerbungEditUtils.statusAendernPopupCommand"
            }
        });
        this.addFunction("$f_BewerbungEditUtils", $f_BewerbungEditUtils, "functions.$f_BewerbungEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "person",
            "filters": [],
            "caption": "bewerbung-heartbase-erweitert-edit.r_bewerbungperson_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdPerson",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdPerson",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bewerbungPerson",
            "options": {
                "optionsName": "r_bewerbungPersonOptions",
                "optionsNameFQ": "r_bewerbungPersonOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "stelleninserat",
            "filters": [],
            "caption": "bewerbung-heartbase-erweitert-edit.r_bewerbungstelleninserat_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdStelleninserat",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdStelleninserat",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bewerbungStelleninserat",
            "options": {
                "optionsName": "r_bewerbungStelleninseratOptions",
                "optionsNameFQ": "r_bewerbungStelleninseratOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "bewerbung-heartbase-erweitert-edit.r_taetigkeit_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Taetigkeit",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Taetigkeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_taetigkeit",
            "options": {
                "optionsName": "r_taetigkeitOptions",
                "optionsNameFQ": "r_taetigkeitOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "bewerbungsgrund",
            "filters": [],
            "caption": "bewerbung-heartbase-erweitert-edit.r_bewerbungsgrund_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdBewerbungsgrund",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdBewerbungsgrund",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Bewerbung",
                    "bindTo": "$f_BewerbungEditUtils.requiredValidationRule",
                    "bindToFQ": "functions.$f_BewerbungEditUtils.requiredValidationRule"
                }
            }],
            "id": "r_bewerbungsgrund",
            "options": {
                "optionsName": "r_bewerbungsgrundOptions",
                "optionsNameFQ": "r_bewerbungsgrundOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bewerbung-heartbase-erweitert-edit.r_bewerbungsdatum_caption",
            "isReadOnlyExpression": "models.data.$m_Bewerbung.Kopf.Id > 0",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Bewerbungsdatum",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Bewerbungsdatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_bewerbungsdatum",
            "options": {
                "optionsName": "r_bewerbungsdatumOptions",
                "optionsNameFQ": "r_bewerbungsdatumOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lehrberuf",
            "filters": [],
            "caption": "bewerbung-heartbase-erweitert-edit.r_lehrberuf_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdLehrberuf",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdLehrberuf",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_lehrberuf",
            "options": {
                "optionsName": "r_lehrberufOptions",
                "optionsNameFQ": "r_lehrberufOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "bewerbung-heartbase-erweitert-edit.r_nameerziehungsberechtige_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.NameErziehungsberechtige",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.NameErziehungsberechtige",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_nameErziehungsberechtige",
            "options": {
                "optionsName": "r_nameErziehungsberechtigeOptions",
                "optionsNameFQ": "r_nameErziehungsberechtigeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "landvorwahl",
            "filters": [],
            "caption": "bewerbung-heartbase-erweitert-edit.r_telefonlandvorwahlerziehungsberechtigte_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.TelefonLandvorwahlErziehungsberechtigte",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.TelefonLandvorwahlErziehungsberechtigte",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_telefonLandvorwahlErziehungsberechtigte",
            "options": {
                "optionsName": "r_telefonLandvorwahlErziehungsberechtigteOptions",
                "optionsNameFQ": "r_telefonLandvorwahlErziehungsberechtigteOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "bewerbung-heartbase-erweitert-edit.r_telefonerziehungsberechtigte_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.TelefonErziehungsberechtigte",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.TelefonErziehungsberechtigte",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "telefon",
                    "parameters": []
                }
            }],
            "id": "r_telefonErziehungsberechtigte",
            "options": {
                "optionsName": "r_telefonErziehungsberechtigteOptions",
                "optionsNameFQ": "r_telefonErziehungsberechtigteOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "caption": "bewerbung-heartbase-erweitert-edit.r_bemerkung_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Bemerkung",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Bemerkung",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bemerkung",
            "options": {
                "optionsName": "r_bemerkungOptions",
                "optionsNameFQ": "r_bemerkungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "stelleninseratKlasse",
            "filter": ["StelleninseratKopfElement.IdStelleninserat", {
                "isBound": true,
                "expression": "models.data.$m_Bewerbung.Kopf.IdStelleninserat"
            }],
            "filters": [],
            "caption": "bewerbung-heartbase-erweitert-edit.r_klasse_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdStelleninseratKlasse",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdStelleninseratKlasse",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_klasse",
            "options": {
                "optionsName": "r_klasseOptions",
                "optionsNameFQ": "r_klasseOptions"
            }
        });
        super.onConstructionFinished();
    }
}