import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class PersonSkillEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "person-skill-edit";
        this.title = "person-skill-edit.person-skill-edit_caption";
        this.addModel({
            "id": "$m_Skill",
            "webApiAction": "ZIT/Objekte/PersonSkillElement",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "skill",
            "filters": [],
            "caption": "person-skill-edit.r_skill_caption",
            "binding": {
                "dataContext": "$m_Skill",
                "bindTo": "IdSkill",
                "bindToFQ": "models.data.$m_Skill.IdSkill"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_skill",
            "options": {
                "optionsName": "r_skillOptions",
                "optionsNameFQ": "r_skillOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 500,
            "caption": "person-skill-edit.r_text_caption",
            "binding": {
                "dataContext": "$m_Skill",
                "bindTo": "Text",
                "bindToFQ": "models.data.$m_Skill.Text"
            },
            "validationRules": [],
            "id": "r_text",
            "options": {
                "optionsName": "r_textOptions",
                "optionsNameFQ": "r_textOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "person-skill-edit.r_gueltigvon_caption",
            "binding": {
                "dataContext": "$m_Skill",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_Skill.GueltigVon"
            },
            "validationRules": [],
            "id": "r_gueltigVon",
            "options": {
                "optionsName": "r_gueltigVonOptions",
                "optionsNameFQ": "r_gueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "person-skill-edit.r_gueltigbis_caption",
            "binding": {
                "dataContext": "$m_Skill",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_Skill.GueltigBis"
            },
            "validationRules": [],
            "id": "r_gueltigBis",
            "options": {
                "optionsName": "r_gueltigBisOptions",
                "optionsNameFQ": "r_gueltigBisOptions"
            }
        });
        super.onConstructionFinished();
    }
}