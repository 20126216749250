import {
  autoinject
} from "aurelia-framework";
import {
  FormBase
} from "../classes/form-base";
import {
  BaseWidgetCreatorService
} from "./base-widget-creator-service";
import * as WidgetOptions from "../widget-options/export";
import { ListType } from '../enums/list-type-enum';

@autoinject
export class ListWidgetCreatorService {
  constructor(
    private baseWidgetCreator: BaseWidgetCreatorService
  ) { }

  addList(form: FormBase, options: WidgetOptions.IListOptions) {
    const listOptions: DevExpress.ui.dxListOptions = this.baseWidgetCreator.createWidgetOptions(form, options);

    listOptions.itemTemplate = "itemTemplate";

    if (options.dataModel && !options.binding.bindTo) {
      const dataSource = this.baseWidgetCreator.createListDataSource(form, options, listOptions);
      listOptions.dataSource = dataSource;
    }
    else if (options.binding.bindTo) {
      listOptions.bindingOptions["dataSource"] = options.binding.bindToFQ;
    }

    this.baseWidgetCreator.setListClickActions(form, options);
    const customOptions = this.baseWidgetCreator.getCustomOptions(form, options);

    if (customOptions.hasClickActions > 0) {
      listOptions.onItemClick = (e) => {
        (<any>e).data = e.itemData;

        customOptions.clickActions.forEach(item => {
          item(e, listOptions.dataSource);
        });
        customOptions.openClickActions.forEach(item => {
          item(e, listOptions.dataSource);
        });
      };
    }

    this.baseWidgetCreator.checkListToolbar(form, options, null, ListType.List);
    this.baseWidgetCreator.checkListRelationEdit(form, options);
  }
}
