import { autoinject } from "aurelia-framework";
import { RestService } from '../../base/services/rest-service';

@autoinject
export class LayoutService {
  private _layouts: any = {};

  constructor(
    private restService: RestService
  ) {}

  async initializeLayouts(): Promise<any> {
    this._layouts = {};

    const result = await this.restService.get({
      url: this.restService.getApiUrl("base/Layout/Get")
    });

    for (let item of result) {
      //TODO
      if (item.AssignedType != "global") {
        continue;
      }

      this.getLayoutType(item.Type)[this.getKey(item.Form, item.IdWidget)] = JSON.parse(item.Layout);
    }
  }
  getLayout(type: string, form: string, idWidget: string): any {
    const key = this.getKey(form, idWidget);
    return this.getLayoutType(type)[key] || null;
  }
  async saveLayout(type: string, form: string, idWidget: string, layout: any) {
    await this.restService.post({
      url: this.restService.getApiUrl("base/Layout/Post"),
      data: {
        Form: form,
        IdWidget: idWidget,
        Type: type,
        AssignedType: "global",
        Layout: JSON.stringify(layout || {})
      },
      increaseLoadingCount: true
    });

    this.getLayoutType(type)[this.getKey(form, idWidget)] = layout;
  }
  async deleteLayout(type: string, form: string, idWidget: string) {
    await this.restService.post({
      url: this.restService.getApiUrl("base/Layout/Delete"),
      data: {
        Form: form,
        IdWidget: idWidget,
        Type: type,
        AssignedType: "global"
      },
      increaseLoadingCount: true
    });

    delete this.getLayoutType(type)[this.getKey(form, idWidget)];
  }

  private getKey(form: string, idWidget: string): string {
    return form.concat(";").concat(idWidget);
  }
  private getLayoutType(type: string): any {
    if (!this._layouts[type]) {
      this._layouts[type] = {};
    }

    return this._layouts[type];
  }
}
