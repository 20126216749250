import {
  autoinject,
  processContent,
  bindable,
  Scope,
  TemplatingEngine,
  OverrideContext,
  TaskQueue,
  View
} from "aurelia-framework";
import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  DxTemplateService
} from "../services/dx-template-service";
import * as DxLoader from "../dx-loader";
import {
  BindingService,
  DeepObserverService,
  ScopeContainer
} from "../../base/export";
import { BrowserService } from '../../base/services/browser-service';

@autoinject
@processContent(false)
export class DxWidget {
  private _createdViews: View[] = [];
  private _widgetElement: Element;
  private _valueChangeByCodeCount: number = 0;

  constructor(
    private element: Element,
    private templatingEngine: TemplatingEngine,
    private binding: BindingService,
    private deepObserver: DeepObserverService,
    private dxTemplate: DxTemplateService,
    private eventAggregator: EventAggregator,
    private taskQueue: TaskQueue,
    private browserService: BrowserService) {
  }

  @bindable name: string;
  @bindable options: any;
  @bindable validator: any;

  id: string;
  parentViewUrl: string;

  owningView: any;
  instance: any;
  validatorInstance: any;
  tooltipInstance: any;
  templates = {};
  scopeContainer: ScopeContainer;

  created(owningView: any, myView: any) {
    this.owningView = owningView;

    if (owningView
      && owningView.resources
      && owningView.resources.viewUrl) {
      this.parentViewUrl = owningView.resources.viewUrl;
    }

    this.id = this.element.getAttribute("view-model.ref");
  }
  activate(e) {
    if (e && e.name && e.options) {
      this.name = e.name;
      this.options = e.options;
    }
  }
  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: bindingContext,
      overrideContext: overrideContext
    });
    this.checkMobile();
    this.extractTemplates();
    this.checkBindings();
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }
  attached() {
    this.renderInline();

    const initializeOptions = this.getInitializeOptions();
    this._widgetElement = document.createElement("div");

    //TODO - vermutlich müssen die Kinder im Speicher gemerkt werden und dann von dor hierher geklont werden
    //=> Problem, dass im detached alles verworfen wird und dann nicht mehr da ist ...
    while (this.element.children.length > 0) {
      this._widgetElement.appendChild(this.element.children.item(0));
    }

    this.element.appendChild(this._widgetElement);

    if (!DxLoader.exists(this.name)) {
      throw new Error(`Widget ${this.name} does not exist`);
    }

    this.eventAggregator.publish("dx-widget:attaching", {
      widget: this,
      owningView: this.owningView,
      name: this.name,
      options: initializeOptions
    });

    this.instance = DxLoader.createInstance(this.name, this._widgetElement, initializeOptions);

    let validatorElement;
    if (this.validator) {
      this.validatorInstance = DxLoader.createInstance("dxValidator", this._widgetElement, this.validator);
    } else if (this.options["validators"]) {
      this.validatorInstance = DxLoader.createInstance("dxValidator", this._widgetElement, {
        validationRules: this.options["validators"]
      });
    }

    this.registerBindings();

    this.eventAggregator.publish("dx-widget:attached", {
      widget: this,
      owningView: this.owningView,
      name: this.name,
      options: initializeOptions,
      element: this._widgetElement,
      instance: this.instance
    })
  }
  detached() {
    let mainElement;
    let tooltipElement;

    if (this.instance) {
      mainElement = this.instance.element();
      this.instance.dispose();
      this.instance = null;
    }
    if (this.validatorInstance) {
      this.validatorInstance.dispose();
      this.validatorInstance = null;
    }
    if (this.tooltipInstance) {
      tooltipElement = this.tooltipInstance.element();
      this.tooltipInstance.dispose();
      this.tooltipInstance = null;
    }
    if (mainElement) {
      this.element.removeChild(mainElement);
    }
    if (tooltipElement) {
      this.element.removeChild(tooltipElement);
    }

    if (this.options && this.options.bindingOptions) {
      for (let binding of this.options.bindingOptions) {
        if (binding.deepObserver) {
          binding.deepObserver();
          binding.deepObserver = null;
        }
      }
    }

    this._createdViews.forEach(c => {
      c.detached();
      c.unbind();
    });
    this._createdViews.splice(0);
  }

  createTooltip(tooltip: string) {
    if (this.tooltipInstance) {
      const element: HTMLElement = this.tooltipInstance.element();
      element.querySelector(".dx-popup-content").textContent = tooltip;
      return;
    }

    const tooltipElement = document.createElement("div");
    tooltipElement.innerText = tooltip;
    this.element.appendChild(tooltipElement);

    this.tooltipInstance = DxLoader.createInstance("dxTooltip", tooltipElement, {
      target: this.instance.element(),
      showEvent: "dxhoverstart",
      hideEvent: "dxhoverend"
    });
  }

  resetValidation() {
    if (this.instance.option("isValid") === false) {
      this.setOption({
        isValid: true
      });
    }
  }
  setOption(options: any) {
    let hasValueProperty = false;

    for (let key of Object.getOwnPropertyNames(options)) {
      if ((key === "items" || key === "dataSource") && options[key] == void (0)) {
        options[key] = [];
      }

      if (key != "isValid") {
        const currentValue = this.instance.option(key);
        if (currentValue === options[key]) {
          delete options[key];
          continue;
        }
      }

      if (key === "value") {
        hasValueProperty = true;
      }
    }

    if (Object.getOwnPropertyNames(options).length === 0) {
      return;
    }

    try {
      if (hasValueProperty) {
        this._valueChangeByCodeCount++;
      }

      this.instance.option(options);
    } finally {
      if (hasValueProperty) {
        this._valueChangeByCodeCount--;
      }
    }
  }

  private checkMobile() {
    if (!this.browserService.isMobile) {
      return;
    }

    if (this.name === "dxSelectBox") {
      this.name = "dxLookup";
    } else if (this.name == "dxPopup") {
      this.options.fullScreen = true;

      delete this.options.width;
      delete this.options.height;
      delete this.options.maxWidth;
      delete this.options.maxHeight;
    }
  }
  private modelByElement(element: any): any {
    return this.scopeContainer.scope;
  }
  private extractTemplates(): void {
    const children = Array.from(this.element.children)
      .filter((c) => c.tagName == "DX-TEMPLATE");

    for (let i = 0; i < children.length; i++) {
      const item = <HTMLElement>children[i];

      const name = item.getAttribute("name");
      const alias = item.getAttribute("alias") || "data";

      this.templates[name] = {
        render: (renderData) => {
          const result = this.dxTemplate.render(
            item,
            renderData.container,
            this.owningView.resources,
            this.scopeContainer.scope,
            renderData.model,
            name
          );

          const onRenderedName = `on${this.getPascalCase(name)}Rendered`;
          if (this.options && this.options[onRenderedName]) {
            this.options[onRenderedName](this);
          }

          this._createdViews.push(result.view);
          return result.element;
        }
      };

      item.parentElement.removeChild(item);
    }

    Object.assign(this.templates, this.dxTemplate.getTemplates(this.scopeContainer.scope, this.owningView.resources));
  }
  private getInitializeOptions(): any {
    this.options = this.options || {};

    const initializeOptions = Object.assign({}, this.options);

    const onOptionChangedOld = initializeOptions.onOptionChanged;
    initializeOptions.onOptionChanged = (e) => {
      if (onOptionChangedOld) {
        onOptionChangedOld(e);
      }

      this.onOptionChanged(e);
    };
    
    initializeOptions.modelByElement = this.modelByElement.bind(this);
    initializeOptions.integrationOptions = {
      templates: this.templates
    }

    if (this.options.bindingOptions) {
      for (let property in this.options.bindingOptions) {
        const binding = this.options.bindingOptions[property];
        const value = this.binding.evaluate(this.scopeContainer.scope, binding.expression);

        initializeOptions[property] = value;
      }
    }

    return initializeOptions;
  }
  private registerBindings(): void {
    if (!this.options.bindingOptions) {
      return;
    }

    for (let property in this.options.bindingOptions) {
      const binding = this.options.bindingOptions[property];

      this.binding.observe({
        scopeContainer: this.scopeContainer,
        expression: binding.expression,
        callback: (newValue, oldValue) => {
          const options = {};
          if (newValue === this.instance.option(property)) {
            return;
          }

          options[property] = newValue;
          options["isValid"] = true;

          this.setOption(options);
          this.registerDeepObserver(binding, property, value);
        }
      });

      const value = this.binding.evaluate(this.scopeContainer.scope, binding.expression);

      this.registerDeepObserver(binding, property, value);
    }
  }
  private checkBindings(): void {
    if (!this.options) {
      throw new Error(`Invalid or no options for ${this.name}`);
    }

    if (!this.options.bindingOptions) {
      return;
    }

    for (let property in this.options.bindingOptions) {
      const binding = this.checkBinding(property);
    }
  }
  private checkBinding(property): void {
    const bindingOptions = this.options.bindingOptions;

    if (typeof bindingOptions[property] === "string") {
      bindingOptions[property] = {
        expression: bindingOptions[property]
      }
    }
  }
  private registerDeepObserver(binding, property, value): void {
    if (binding.deepObserver) {
      binding.deepObserver();
      binding.deepObserver = null;
    }

    if (!binding.deep) {
      return;
    }

    binding.deepObserver = this.deepObserver.observe(value, () => {
      const options = {};
      options[property] = value;
      options["isValid"] = true;
      this.setOption(options);
    });
  }
  private onOptionChanged(e): void {
    if (e.name === "value" && !this.isChangeToPublish(e)) {
      return;
    }
    if (this.options.bindingOptions) {
      const binding = this.options.bindingOptions[e.name];
      if (binding && !binding.readOnly) {
        const currValue = this.binding.evaluate(this.scopeContainer.scope, binding.expression);

        if (currValue === e.value) {
          return;
        }

        this.binding.assign(this.scopeContainer.scope, binding.expression, e.value);
      }
    }
    if (e.name === "value" && this.options.onValueChangedByUser && this._valueChangeByCodeCount === 0) {
      this.options.onValueChangedByUser({
        sender: this,
        model: this.scopeContainer.scope,
        value: e.value,
        previousValue: e.previousValue
      })
    }
  }
  private renderInline(): void {
    Array.from(this.element.children)
      .forEach((child): void => {
        const result = this.templatingEngine.enhance({
          element: child,
          resources: this.owningView.resources,
          bindingContext: this.scopeContainer.scope.bindingContext,
          overrideContext: this.scopeContainer.scope.overrideContext
        });
        
        this._createdViews.push(result);
      });
  }
  private getPascalCase(text: string): string {
    return text.substr(0, 1).toUpperCase() + text.substr(1);
  }
  private isChangeToPublish(e): boolean {
    const isSelect = this.name === "dxSelectBox"
      || this.name == "dxLookup";

    if (!isSelect) {
      return true;
    }

    if (e.value != void (0) || e.previousValue != void (0)) {
      return true;
    }

    return false;
  }
}
