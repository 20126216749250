export class ZitBankverbindungValueConverter {
  toView(data: any, funcName: string, args?) {
    switch (funcName) {
      case ("IBAN"):
        return this.getIban(data);
    }
  }

  private getIban(data): string {
    if (!data.IBAN) {
      return "";
    }

    const ibanFormatted = data.IBAN.match(/.{1,4}/g).join(" ");

    return data.Waehrung
      ? `${ibanFormatted} | ${data.Waehrung.Bezeichnung}`
      : ibanFormatted;
  }
}