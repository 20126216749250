import {
  autoinject,
  Scope
} from "aurelia-framework";
import {
  FormBase
} from "../classes/form-base";
import {
  BindingService,
  LocalizationService
} from "../../base/services/export";
import {
  ScopeContainer
} from "../../base/classes/export";
import {
  CommandService
} from "../services/command-service";
import {
  DxTemplateService
} from "../../dx/services/dx-template-service";
import * as Toolbar from "../elements/toolbar/export";
import * as Interfaces from "../interfaces/export";
import * as toolbarButtonTemplate from "html-loader!../templates/toolbar-button-template.xhtml";

@autoinject
export class ToolbarService {
  private titleItemTemplate = "TITEL_ITEM_TEMPLATE";
  private _pages = {};
  private _categories = {};

  constructor(
    private command: CommandService,
    private localization: LocalizationService,
    private binding: BindingService,
    private dxTemplate: DxTemplateService
  ) {
    this.addPage("$start", "toolbar.page_start", 0);
    this.addCategory("$datensatz", "$start", "toolbar.category_datensatz", 10);
    this.addCategory("$export", "$start", "toolbar.category_export", 20);
    this.addCategory("$nav", "$start", "toolbar.nav", 0);
    this.addPage("$after", "", 9999);
    this.addCategory("$close", "$after", "toolbar.close", 9999);
  }

  toolbarModuleId: string = "framework/forms/elements/ribbon-toolbar/ribbon-toolbar";

  addPage(id: string, title: string, sortIndex: number) {
    this._pages[id] = {
      id,
      title,
      sortIndex
    };
  }
  addCategory(id: string, idPage: string, title: string, sortIndex: number) {
    this._categories[id] = {
      id,
      idPage,
      title,
      sortIndex
    }
  }
  createPageCategoryItemStructure(items: Toolbar.IItem[]): Toolbar.IPage[] {
    if (!items || items.length === 0) {
      return [];
    }

    const categories = {};

    for (let item of items) {
      let idCategory = item.idCategory || "$datensatz"
      let category: Toolbar.ICategory = categories[idCategory];

      if (!category) {
        const c = this._categories[idCategory];

        if (!c) {
          throw new Error(`No category ${idCategory}`);
        }

        category = <any>{
          id: c.id,
          idPage: c.idPage,
          title: this.localization.translateOnce(c.title),
          sortIndex: c.sortIndex,
          location: c.location,
          items: []
        };
        categories[idCategory] = category;
      }

      category.items.push(item);
    }

    const result: Toolbar.IPage[] = [];
    const pages = {};
    for (let key in categories) {
      const category = categories[key];

      let page: Toolbar.IPage = pages[category.idPage];

      if (!page) {
        const p = this._pages[category.idPage];

        if (!p) {
          throw new Error(`No page ${category.idPage}`);
        }

        page = <any>{
          id: p.id,
          title: this.localization.translateOnce(p.title),
          sortIndex: p.sortIndex,
          categories: []
        }
        pages[category.idPage] = page;
        result.push(page);
      }

      page.categories.push(category);
    }

    result.sort((a, b) => {
      return (a.sortIndex || 0) - (b.sortIndex || 0);
    })

    for (let page of result) {
      page.categories.sort((a, b) => {
        return (a.sortIndex || 0) - (b.sortIndex || 0);
      })

      for (let category of page.categories) {
        category.items.sort((a, b) => {
          return (a.sortIndex || 0) - (b.sortIndex || 0);
        })
      }
    }

    return result;
  }

  createFormToolbarOptions(form: FormBase): Toolbar.IToolbarOptions {
    const options = this.createToolbarOptions(
      form.scopeContainer,
      form.title,
      form.commands.getCommands());

    this.binding.observe({
      scopeContainer: form.scopeContainer,
      expression: "title", 
      callback: (newValue) => {
        options.title = newValue;
      }
    });

    return options;
  }
  createToolbarOptions(scopeContainer: ScopeContainer, title: string, commands: Interfaces.ICommandData[], smallToolbar: boolean = false, icon: string = null): Toolbar.IToolbarOptions {
    const options: Toolbar.IToolbarOptions = {
      title: title,
      icon: icon,
      smallToolbar: smallToolbar,
      items: [],
      scopeContainer: scopeContainer
    };

    options.items = this.createToolbarItems(scopeContainer, commands);
    return options;
  }
  createToolbarItems(scopeContainer: ScopeContainer, commands: Interfaces.ICommandData[]): Toolbar.IItem[] {
    const items = commands
      .map(i => this.createToolbarItem(scopeContainer, i));

    return items;
  }
  createToolbarItem(scopeContainer: ScopeContainer, command: Interfaces.ICommandData): Toolbar.IItem {
    command.expressionScope = scopeContainer.scope;

    return command;
  }
}
