import {
  autoinject,
  singleton
} from "aurelia-framework";
import {
  FormBase
} from "../classes/form-base";

@autoinject
@singleton(true)
export class Functions {
  private form: FormBase;
  private _functionInstances: any[] = [];

  constructor() {}

  add(id: string, functionInstance: any, namespace: string, customParameters?: any) {
    this[id] = functionInstance;
    this._functionInstances.push(functionInstance);

    if (functionInstance.bind) {
      functionInstance.bind(this.form, namespace, customParameters);
    }
  }
  
  registerForm(form: FormBase) {
    if (this.form) {
      throw new Error("Form was already registered");
    }

    this.form = form;
  }

  dispose() {
    this.unbindAll();
    this._functionInstances.splice(0);
  }
  
  private unbindAll() {
    this._functionInstances.forEach(instance => {
      if (!instance.unbind) {
        return;
      }

      instance.unbind(this.form);
    });
  }
}
