export class ZitAnsprechpersonValueConverter {
  toView(data: any, funcName: string, args?) {
    switch (funcName) {
      case ("aktiv"):
        return this.getAktiv(data.Ansprechpersonen);
    }
  }

  private getAktiv(data: any[]): string {
    if (!data) {
      return "";
    }
    const numberOf = data.length === 1
      ? "Person"
      : "Personen";
    const totalAktv = data.filter(c => { return c.Ansprechperson && c.Ansprechperson.IsInaktiv === false }).length;

    return `${data.length} ${numberOf}, davon ${totalAktv} aktiv`
  }
}
