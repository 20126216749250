import { autoinject } from "aurelia-framework";
import { SucheService } from "../../services/export";
import { IZitCardOptions, ZitCardTyp } from "./../../../zit-ui/elements/zit-card/zit-card-options";

@autoinject
export class DashboardBoxTermin {
  constructor(
    private sucheService: SucheService
  ) { }
  
  item: any;

  terminCardOptions: IZitCardOptions = {
    isClickEnabled: true,
    useDefaultBindings: true,
    typ: ZitCardTyp.Kompakt
  };

  activate(item) {
    this.item = item;
  }

  onBoxHeaderClicked() {
    this.sucheService.activateSuche("TIP.ZIT.Business.Entities.Objekte.Termine.Termin");
  }
}
