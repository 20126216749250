import {
  autoinject
} from "aurelia-framework";
import {
  FormBase
} from "../classes/form-base";
import {
  IListOptionsBase,
  IDataGridOptions
} from "../widget-options/export";
import {
  LocationService,
  LocalizationService,
  PermissionService,
  RestService,
  ShortcutService
} from "../../base/services/export";
import {
  PopupInfoService
} from "./popup-info-service";
import {
  IModel,
  IValidationResult
} from "../interfaces/export";
import * as Interfaces from "../interfaces/export";
import {
  ContextMenu
} from "../classes/context-menu";
import { RouterService } from "./router-service";
import { DialogConfirmService } from './dialog-confirm-service';
import { ListType } from '../enums/list-type-enum';

@autoinject
export class DefaultCommandsService {
  constructor(
    private router: RouterService,
    private location: LocationService,
    private localization: LocalizationService,
    private permission: PermissionService,
    private rest: RestService,
    private shortcut: ShortcutService,
    private popupInfo: PopupInfoService,
    private dialogConfirmService: DialogConfirmService
  ) {
    this.shortcut.bindShortcut("ctrl+shift+enter", "$command", true);
    this.shortcut.bindShortcut("f10", "$save");
    this.shortcut.bindShortcut("ctrl+f10", "$saveAndClose");
    this.shortcut.bindShortcut("shift+f10", "$saveAndAdd");
    this.shortcut.bindShortcut("f9", "$close");
    this.shortcut.bindShortcut("f8", "$delete");
    this.shortcut.bindShortcut("f7", "$add");
    this.shortcut.bindShortcut("ctrl+f11", "$scrollUp");
    this.shortcut.bindShortcut("ctrl+f12", "$scrollDown");
  }

  getFormAddCommand(form: FormBase): Interfaces.ICommandData {
    const cmd: Interfaces.ICommandData = {
      id: "$add",
      icon: "fas fa-plus",
      title: "base.add",
      tooltip: "base.add_tooltip",
      sortIndex: 5,
      isVisible: form.canSave(),
      isEnabled: form.canAdd(),
      execute: () => {
        form.add();
      }
    };

    form.models.onLoaded.register(() => {
      cmd.isEnabled = form.canAdd();
      return Promise.resolve();
    });

    return cmd;
  }
  getFormSaveCommand(form: FormBase): Interfaces.ICommandData {
    const cmd: Interfaces.ICommandData = {
      id: "$save",
      icon: "fas fa-save",
      title: "base.save",
      tooltip: "base.save_tooltip",
      sortIndex: 10,
      isVisible: form.canSave(),
      isEnabled: form.canSaveNow(),
      execute: () => {
        form.save()
          .catch(r => {
            form.error.showAndLogError(r);
          });
      }
    };

    form.models.onLoaded.register(() => {
      cmd.isEnabled = form.canSaveNow();
      return Promise.resolve();
    });

    return cmd;
  }
  getEditPopupSaveAndAddCommand(form: FormBase): Interfaces.ICommandData {
    const cmd: Interfaces.ICommandData = {
      id: "$saveAndAdd",
      title: "base.save_and_add",
      tooltip: "base.save_and_add_tooltip",
      small: true,
      sortIndex: 11,
      isVisible: form.canSave(),
      isEnabled: form.canSaveNow() && form.canAdd(),
      execute: () => {
        form.save().then((r: IValidationResult) => {
          if (r.isValid) {
            form.add();
          }
        });
      }
    };

    form.models.onLoaded.register(() => {
      cmd.isEnabled = form.canSaveNow() && form.canAdd();
      return Promise.resolve();
    });

    return cmd;
  }
  getEditPopupSaveAndCloseCommand(form: FormBase): Interfaces.ICommandData {
    const cmd: Interfaces.ICommandData = {
      id: "$saveAndClose",
      title: "base.save_and_close",
      tooltip: "base.save_and_close_tooltip",
      small: true,
      sortIndex: 11,
      isVisible: form.canSave(),
      isEnabled: form.canSaveNow() && form.canAdd(),
      execute: () => {
        form.save().then((r: IValidationResult) => {
          if (r.isValid) {
            this.popupInfo.closeCurrentPopup();
          }
        });
      }
    };

    form.models.onLoaded.register(() => {
      cmd.isEnabled = form.canSaveNow();
      return Promise.resolve();
    });

    return cmd;
  }
  getFormDeleteCommand(form: FormBase): Interfaces.ICommandData {
    const cmd: Interfaces.ICommandData = {
      id: "$delete",
      icon: "fas fa-times",
      title: "base.delete",
      tooltip: "base.delete_tooltip",
      sortIndex: 20,
      isVisible: form.canSave(),
      isEnabled: form.canDeleteNow(),
      execute: () => {
        this.dialogConfirmService.show({
          title: form.translate("base.question"),
          message: form.translate("base.sure_delete_question")
        }).then(r => {
          if (r) {
            form.delete().then((deleteResult) => {
              if (!deleteResult) {
                return;
              }

              history.back();
            });
          }
        });
      }
    };

    form.models.onLoaded.register(() => {
      cmd.isVisible = form.canSave();
      cmd.isEnabled = form.canDeleteNow();
      return Promise.resolve();
    });

    return cmd;
  }
  getEditPopupDeleteCommand(form: FormBase): Interfaces.ICommandData {
    const cmd = this.getFormDeleteCommand(form);

    cmd.execute = () => {
      this.dialogConfirmService.show({
        title: form.translate("base.question"),
        message: form.translate("base.sure_delete_question")
      }).then(r => {
        if (r) {
          form.delete().then((deleteResult) => {
            if (!deleteResult) {
              return;
            }
            
            this.popupInfo.closeCurrentPopup();
          });
        }
      });
    };

    return cmd;
  }
  getFormGoBackCommand(form: FormBase): Interfaces.ICommandData {
    const cmd: Interfaces.ICommandData = {
      id: "$goBack",
      idCategory: "$nav",
      icon: "fas fa-arrow-left",
      tooltip: "base.back",
      sortIndex: 0,
      isEnabled: true,
      isVisibleExpression: "canGoBack",
      execute: () => {
        this.location.goBack();
      }
    }

    return cmd;
  }
  getListAddCommand(form: FormBase, options: IListOptionsBase): Interfaces.ICommandData {
    const cmd: Interfaces.ICommandData = {
      id: "$add",
      icon: "fas fa-plus",
      title: "base.add",
      tooltip: "base.add_tooltip",
      sortIndex: 5,
      isVisible: false,
      isEnabled: true,
      execute: (e) => {
        this.showEditPopupAdd(form, options, e.event ? e.event.target : null);
      }
    }

    if (options.dataModel) {
      const model = (options.relationBinding && options.relationBinding.dataContext)
        ? options.relationBinding.dataContext
        : options.dataModel;

      const info = form.models.getInfo(model);
      if (info) {
        cmd.isVisible = (info.webApiAction
          && info.keyProperty
          && this.permission.canWebApiNew(info.webApiAction, form.moduleId)
          && !!(options.editUrl || options.idEditPopup || options.listEdits.length > 0)) || false;

        if (info.allowNew) {
          cmd.isEnabledExpression = info.allowNew;
          delete cmd.isEnabled;
        }
      }
    }

    return cmd;
  }
  getListEditCommand(form: FormBase, options: IListOptionsBase): Interfaces.ICommandData {
    const cmd: Interfaces.ICommandData = {
      id: "$listEdit",
      icon: "fas fa-pencil-alt",
      title: "base.edit",
      tooltip: "base.edit",
      sortIndex: 6,
      isVisible: true,
      isEnabled: false,
      execute: () => {
        const customOptions = form[options.options.optionsName]["__customOptions"];

        const args = customOptions["clickArguments"];
        const actions = customOptions["openClickActions"];

        actions.forEach(c => c(args.event, args.dataSource));
      }
    }

    form.binding.observe({
      scopeContainer: form.scopeContainer,
      expression: `${options.options.optionsName}.__customOptions.clickArguments`,
      callback: (newValue, oldValue) => {
        cmd.isEnabled = !!newValue;
      }
    });

    return cmd;
  }
  getListExcelExportCommand(form: FormBase, options: IListOptionsBase): Interfaces.ICommandData {
    const cmd: Interfaces.ICommandData = {
      id: "$listExcelExport",
      idCategory: "$export",
      icon: "far fa-file-excel",
      title: "base.excel-export",
      tooltip: "base.excel-export",
      sortIndex: 7,
      isVisible: true,
      isEnabled: true,
      execute: () => {
        //TODO - es muss nicht unbedingt ein DataGrid sein?
        const dataGrid: DevExpress.ui.dxDataGrid = form[options.id].instance;
        dataGrid.option(
          "export.fileName",
          this.localization.translateOnce(form.title));

        let onlySelected = (dataGrid.getSelectedRowKeys().length > 0);

        dataGrid.exportToExcel(onlySelected);
      }
    }

    return cmd;
  }
  getListCommands(form: FormBase, options: IListOptionsBase, addEditCommand: boolean = false, listType: ListType = null): Interfaces.ICommandData[] {
    const result: Interfaces.ICommandData[] = [];

    const addCmd = this.getListAddCommand(form, options);
    if (addCmd) {
      result.push(addCmd);
    }

    if (addEditCommand) {
      const editCmd = this.getListEditCommand(form, options);
      if (editCmd)
        result.push(editCmd);
    }

    if (options.isMainList && listType == ListType.DataGrid) {
      result.push(this.getListExcelExportCommand(form, options));
    }

    return result;
  }
  getScrollDown(form: FormBase): Interfaces.ICommandData {
    const cmd: Interfaces.ICommandData = {
      id: "$scrollDown",
      idCategory: "$nav",
      icon: "fas fa-chevron-down",
      tooltip: "base.scroll_down_tooltip",
      small: true,
      sortIndex: 2,
      isEnabled: !!form.viewScrollInfo && form.viewScrollInfo.index < form.viewScrollInfo.maxCount - 1,
      isVisible: !!form.viewScrollInfo,
      execute: () => {
        const index = form.viewScrollInfo.index + 1;
        this.scroll(form, index);
      }
    }

    form.binding.observe({
      scopeContainer: form.scopeContainer,
      expression: "viewScrollInfo.index",
      callback: () => {
        cmd.isEnabled = !!form.viewScrollInfo && form.viewScrollInfo.index < form.viewScrollInfo.maxCount - 1
      }
    });
    form.binding.observe({
      scopeContainer: form.scopeContainer,
      expression: "viewScrollInfo",
      callback: () => {
        cmd.isVisible = !!form.viewScrollInfo;
      }
    });

    return cmd;
  }
  getScrollUp(form: FormBase): Interfaces.ICommandData {
    const cmd: Interfaces.ICommandData = {
      id: "$scrollUp",
      idCategory: "$nav",
      icon: "fas fa-chevron-up",
      tooltip: "base.scroll_up_tooltip",
      small: true,
      sortIndex: 1,
      isEnabled: !!form.viewScrollInfo && form.viewScrollInfo.index > 0,
      isVisible: !!form.viewScrollInfo,
      execute: () => {
        const index = form.viewScrollInfo.index - 1;
        this.scroll(form, index);
      }
    }

    form.binding.observe({
      scopeContainer: form.scopeContainer,
      expression: "viewScrollInfo.index",
      callback: () => {
        cmd.isEnabled = !!form.viewScrollInfo && form.viewScrollInfo.index > 0
      }
    });
    form.binding.observe({
      scopeContainer: form.scopeContainer,
      expression: "viewScrollInfo",
      callback: () => {
        cmd.isVisible = !!form.viewScrollInfo;
      }
    });

    return cmd;
  }
  getClosePopupCommand() {
    const cmd: Interfaces.ICommandData = {
      id: "$close",
      idCategory: "$close",
      icon: "fas fa-times",
      tooltip: "base.close_tooltip",
      sortIndex: 999,
      execute: () => {
        this.popupInfo.closeCurrentPopup();
      }
    }

    return cmd;
  }

  private showEditPopupAdd(form: FormBase, options: IListOptionsBase, target: any) {
    if (options.listEdits.length > 0) {
      const ctxMenu = new ContextMenu();

      options.listEdits.forEach(c => {
        ctxMenu.items.push({
          text: form.translate(c.caption),
          execute: () => {
            if (c.editDataContext) {
              const model = form.models.getInfo(c.editDataContext);
              form.models.data[c.editDataContext] = form.models.createNewModelData(model);
            }
            if (c.editUrl) {
              this.location.goTo({
                url: c.editUrl + "/0",
                currentViewModel: form
              });
            }
            if (c.idEditPopup) {
              form.editPopups.show(c.idEditPopup, null);
            }
          }
        });
      });

      ctxMenu.show(target);
    } else {
      if (options.editDataContext) {
        const model = form.models.getInfo(options.editDataContext);
        form.models.data[options.editDataContext] = form.models.createNewModelData(model);
      }
      if (options.editUrl) {
        this.location.goTo({
          url: options.editUrl + "/0",
          currentViewModel: form
        });
      }
      if (options.idEditPopup) {
        form.editPopups.show(options.idEditPopup, null);
      }
    }
  }
  private async scroll(form: FormBase, index: number): Promise<any> {
    const hasHandled = await form.handleChangedData();
    if (!hasHandled) {
      return;
    }

    const viewScrollInfo = form.viewScrollInfo;

    if (viewScrollInfo.getNextKey) {
      const id = viewScrollInfo.getNextKey(index);
      if (!id) {
        return;
      }

      viewScrollInfo.index = index;
      form.loadById(id);
    } else {
      const lastLoadInfo = viewScrollInfo.lastLoadInfo;

      const model = form.models.modelWithKeyId;
      const columns = [model.keyProperty, ...(viewScrollInfo.columns || [])];

      const getOptions = {
        take: 1,
        skip: index,
        where: lastLoadInfo.getOptions.where,
        orderBy: lastLoadInfo.getOptions.orderBy,
        customs: lastLoadInfo.getOptions.customs,
        columns: columns,
        searchtext: lastLoadInfo.getOptions.searchtext,
        requireTotalCount: true
      }

      this.rest.get({
        url: lastLoadInfo.url,
        moduleId: form.moduleId,
        getOptions: getOptions,
        increaseLoadingCount: true,
      }).then(async r => {
        if (r && r.count != void (0)) {
          viewScrollInfo.maxCount = r.count;
        }

        if (r && r.rows && r.rows.length) {
          viewScrollInfo.index = index;

          const row = r.rows[0];
          let id = row[model.keyProperty];

          if (viewScrollInfo.resultKeyResolver) {
            id = await viewScrollInfo.resultKeyResolver(id, row);
          }

          form.loadById(id);
        }
      });
    }
  }
}
