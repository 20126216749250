import {
  autoinject,
  Aurelia,
  observable,
  TaskQueue,
} from "aurelia-framework";
import {
  RestService
} from "./rest-service";
import config from "../../../config";
import { EventAggregator } from 'aurelia-event-aggregator';
//TODO - Übersetzungen!
@autoinject
export class AuthorizationService {
  private readonly X_TIP_AUTH = "X-TIP-AUTH";
  private X_TIP_AUTH_LOCAL_STORAGE = "X-TIP-AUTH";

  constructor(
    private rest: RestService,
    private aurelia: Aurelia,
    private eventAggregator: EventAggregator,
    private taskQueue: TaskQueue
  ) {
    this.X_TIP_AUTH_LOCAL_STORAGE = this.getAuthLocalStorageKey();

    this.rest.getAuthorizationHeader = this.getAuthorizationHeaders.bind(this);
    this.rest.getAuthorizationToken = this.getAuthorizationToken.bind(this);

    this.checkAlive();

    this.eventAggregator.subscribe("rest:unauthorized", () => {
      this.isLoggedIn = false;
    });
  }

  @observable isLoggedIn: boolean = null;

  getAuthorizationKey(): string {
    return localStorage.getItem(this.X_TIP_AUTH_LOCAL_STORAGE);
  }

  openApp() {
    if (this.isLoggedIn) {
      return;
    }
    if (!localStorage.getItem(this.X_TIP_AUTH_LOCAL_STORAGE)) {
      this.isLoggedIn = false;
      return;
    }

    this.rest.get({
      url: this.rest.getApiUrl("base/Authorization/IsLoggedIn"),
      increaseLoadingCount: true
    }).then(r => {
      this.isLoggedIn = r.IsValid;
    }).catch(r => {
      this.eventAggregator.publish("alert", {
        message: "Fehler bei Verbindung mit Datenserver. Bitte versuchen Sie es etwas später noch einmal",
        title: "Fehler"
      });
    });
  }
  async login(data: any, showNotificationOnError: boolean = true): Promise<ILoginResult> {
    const wasLoggedIn = this.isLoggedIn;

    const r = await this.rest.post({
      url: this.rest.getApiUrl("base/Authorization/Login"),
      data: Object.assign({ AppId: config.appId }, data),
      increaseLoadingCount: true
    });
    
    if (r.IsValid) {
      if (r.AuthenticationToken) {
        this.isLoggedIn = true;
        localStorage.setItem(this.X_TIP_AUTH_LOCAL_STORAGE, r.AuthenticationToken);

        if (wasLoggedIn) {
          this.taskQueue.queueMicroTask(() =>
          {
            this.isLoggedInChanged(true);
          })
        }
      }

      if (!this.isLoggedIn && r.NeedsTwoFactorAuthKey && data.TwoFactorAuthKey) {
        if (showNotificationOnError) {
          this.eventAggregator.publish("notify", {
            message: "Code ungültig",
            type: "error"
          });
        }
      }

      return {
        isLoggedIn: this.isLoggedIn,
        isValid: r.isValid,
        needsTwoFactorAuthKey: r.NeedsTwoFactorAuthKey
      };
    }

    if (showNotificationOnError) {
      this.eventAggregator.publish("notify", {
        message: "Benutzer oder Passwort ungültig",
        type: "error"
      });
    }

    return {
      isLoggedIn: false,
      isValid: false,
      needsTwoFactorAuthKey: false
    };
  }
  logout() {
    return this.rest.get({
      url: this.rest.getApiUrl("base/Authorization/Logout"),
      increaseLoadingCount: true
    }).then(() => {
      this.isLoggedIn = false;
      localStorage.removeItem(this.X_TIP_AUTH_LOCAL_STORAGE);
    })
  }
  changePassword(data: any): Promise<any> {
    return this.rest.post({
      url: this.rest.getApiUrl("base/Authorization/ChangePassword"),
      data: data,
      increaseLoadingCount: true
    }).then(r => {
      this.eventAggregator.publish("notify", {
        message: "Passwort wurde erfolgreich geändert",
        type: "success"
      });
    });
  }
  resetPassword(data: any): Promise<boolean> {
    return this.rest.post({
      url: this.rest.getApiUrl("base/Authorization/ResetPassword"),
      data: data,
      increaseLoadingCount: true
    }).then(r => {
      if (r.IsValid) {
        this.isLoggedIn = true;
        this.eventAggregator.publish("notify", {
          message: "Der Aktivierungslink wird in Kürze versendet",
          type: "success"
        });
        return true;
      }

      this.eventAggregator.publish("notify", {
        message: "Für den angegeben Benutzernamen existiert kein Konto",
        type: "error"
      });
      return false;
    });
  }
  activateAccount(data: any): Promise<boolean> {
    return this.rest.post({
      url: this.rest.getApiUrl("base/Authorization/ActivateAccount"),
      data: data,
      increaseLoadingCount: true
    }).then(r => {
      if (r.IsValid) {
        this.isLoggedIn = true;
        this.eventAggregator.publish("notify", {
          message: "Der Account wurde aktiviert",
          type: "success"
        });
        return true;
      }

      this.eventAggregator.publish("notify", {
        message: "Ein Fehler ist aufgetreten",
        type: "success"
      });
      return false;
    });
  }
  isLoggedInChanged(newValue) {
    if (newValue == void (0)) {
      return;
    }

    let app = "/";

    if (newValue && config["mainApp"]) {
      app = config["mainApp"]
    } else if (!newValue && config["loginApp"]) {
      app = config["loginApp"]
    }

    const args = {
      app: app,
      isHandled: false
    };
    this.eventAggregator.publish("authorization:change-app", args);

    if (args.isHandled) {
      return;
    }

    this.eventAggregator.publish("authorization:changing-app", args);

    this.taskQueue.queueMicroTask(() => {
      this.aurelia.setRoot(app);
      this.eventAggregator.publish("authorization:changed-app", args);
    });
  }

  private getAuthLocalStorageKey(): string {
    const keyStr = location.origin.concat(location.pathname);

    let hash = 0;
    for (let i = 0; i < keyStr.length; i++) {
      const character = keyStr.charCodeAt(i);
      hash = ((hash << 5) - hash) + character;
      hash = hash & hash;
    }
    return "TA".concat(hash.toString());
  }
  private getAuthorizationHeaders(): any {
    const headers = {};

    const auth = this.getAuthorizationKey();
    if (auth) {
      headers[this.X_TIP_AUTH] = auth;
    }

    return headers;
  }
  private getAuthorizationToken(): string {
    return this.getAuthorizationKey();
  }
  private checkAlive() {
    setInterval(() => {
      if (!this.isLoggedIn) {
        return;
      }

      this.rest.get({
        url: this.rest.getApiUrl("base/Session/IAmAlive"),
        ignoreErrors: true
      });
    }, 1000 * 60);
  }
}

export interface ILoginResult {
  isValid: boolean;
  needsTwoFactorAuthKey: boolean;
  isLoggedIn: boolean;
}