import * as fwx from "../../../framework/forms/form-export";
import {
    AppLoginFormFuncs
} from "./app-login-form-funcs";

@fwx.autoinject
export class AppLoginForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f: AppLoginFormFuncs) {
        super(element, formBaseImport);
        this.id = "app-login-form";
        this.title = "app-login-form.app-login-form_caption";
        this.addFunction("$f", $f, "functions.$f");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}