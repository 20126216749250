export class ZitPatternValueConverter {
  constructor() { }
  toView(): string {
    let text: string = arguments[0];

    const parameters: string[] = [];
    for (let i = 1, j = arguments.length; i < j; i++) {
      parameters.push(arguments[i]);
    }


    parameters.forEach((val, index) => {
      const regExp = val
        ? new RegExp("\\{" + index + "\\}", "g")
        : new RegExp("[^\s]\\{" + index + "\\}[^\s]", "g");

      text = text.replace(new RegExp("\\{" + index + "\\}", "g"), val || "");
    });

    return text;
  }
}