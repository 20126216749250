import * as moment from "moment";
import { autoinject } from "../../../framework/forms/form-export";
import { ScopeContainer } from "./../../../framework/base/classes/scope-container";
import { GlobalizationService } from "./../../../framework/base/services/globalization-service";
import { RestService } from "./../../../framework/base/services/rest-service";
import { ICommandData } from "./../../../framework/forms/interfaces/command-data";
import { SimpleWidgetCreatorService } from "./../../../framework/forms/widget-services/simple-widget-creator-service";
import { IdxDateBoxComponent, IdxPopupComponent } from "./../../interfaces/dx-components";

@autoinject
export class EventKopierenZeitraum {
  private _options: IEventKopierenZeitraum;

  constructor(
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _restService: RestService,
    private _globalizationService: GlobalizationService
  ) { }

  scopeContainer: ScopeContainer;
  verschiebeDate: Date;
  lastZeitraumDate: Date;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "auto",
    height: "auto"
  };

  kopierenMitZeitraumCommand: ICommandData = {
    id: "save",
    icon: "fas fa-save",
    tooltip: "base.save",
    execute: (e) => {
      const verschiebeDatMoment = moment(this.verschiebeDate);
      const lastZeitraumDateMoment = moment(this.lastZeitraumDate);
      const difference = verschiebeDatMoment.diff(lastZeitraumDateMoment, "days");

      this.popup.instance.hide();
      this._options.callback(difference);
    }
  };

  dateBox: IdxDateBoxComponent;
  dateBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this._globalizationService.getFormatterParser("d"),
    bindingOptions: {
      value: "verschiebeDate"
    }
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });
    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "eventKopierenPopupToolbar",
      caption: "event-kopieren-optionen.datum-waehlen",
      options: this.popupOptions,
      commands: [this.kopierenMitZeitraumCommand],
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  show(options: IEventKopierenZeitraum) {
    this._options = options;
    this.getLastEventZeitraumDatum();
    this.popup.instance.show();
  }
  async getLastEventZeitraumDatum() {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Objekte/EventZeitraum"),
      getOptions: {
        expand: { Kopf: null },
        where: ["IdEvent", this._options.IdEvent],
        orderBy: [{ columnName: "Kopf.StartDatum", sortOrder: 0 }]
      }
    });

    if (r.length == 0) {
      return;
    }
    this.lastZeitraumDate = r[0].Kopf.StartDatum;
    this.verschiebeDate = this.lastZeitraumDate;
  }
}

export interface IEventKopierenZeitraum {
  IdEvent: number;
  callback: { (AnzahlTage: number): void };
}
