import { DataSourceService } from './../../../framework/base/services/data-source-service';
import { bindable } from 'aurelia-framework';
import { Models } from './../../../framework/forms/classes/models';
import { SimpleWidgetCreatorService } from './../../../framework/forms/widget-services/simple-widget-creator-service';
import { IBenutzerAccountSperrenData } from './../../interfaces/benutzeraccount-sperren-data';
import { DxWidget } from './../../../framework/dx/elements/dx-widget';
import { RestService } from './../../../framework/base/services/rest-service';
import { autoinject } from 'aurelia-framework';
import { ScopeContainer, LocalizationService } from '../../../framework/base/export';
import { ICommandData, DialogConfirmService, FormBase } from '../../../framework/forms/export';
import { IdxPopupComponent, IdxValidationGroupComponent, IdxSelectBoxComponent, IdxTextAreaComponent } from '../../interfaces/export';

import * as moment from "moment";

@autoinject
export class BenutzerSperren {
  constructor(
    private localization: LocalizationService,
    private restService: RestService,
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private dataSource: DataSourceService
  ) { }

  @bindable key: number;

  scopeContainer: ScopeContainer;
  form: FormBase;

  popupCommands: ICommandData[] = [
    {
      id: "doBenutzerSperren",
      idCategory: "$start",
      icon: "fas fa-check",
      tooltip: "benutzer-sperren.benutzer-sperren",
      sortIndex: 1600,
      execute: () => {
        this.checkAndDoBenutzerSperren();
      }
    }
  ];

  benutzerSperrenPopup: IdxPopupComponent;
  benutzerSperrenPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    width: "800px",
    maxHeight: "80%",
    maxWidth: "800px"
  }

  grundSelectBox: IdxSelectBoxComponent;
  grundSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id"
  }

  grundBemerkungTextArea: IdxTextAreaComponent;
  grundBemerkungTextAreaOptions: DevExpress.ui.dxTextAreaOptions = {
    height: "200px"
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "benutzerSperrenPopupToolbar",
      caption: "benutzer-sperren.popup-title",
      options: this.benutzerSperrenPopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  showPopup(key: number, form: FormBase) {
    this.key = key;
    this.form = form;

    this.resetInputData();
    this.setGrundDataSource()

    this.benutzerSperrenPopup.instance.show();
  }
  private resetInputData(): void {
    if (this.grundBemerkungTextArea) {
      this.grundBemerkungTextArea.instance.option("value", null);
    } else {
      this.grundBemerkungTextAreaOptions.value = null;
    }
  }
  private async setGrundDataSource() {
    const stichtag = moment().startOf("day").toDate();

    const dataSource = await this.restService.get({
      url: this.restService.getWebApiUrl("ZIT/Stammdaten/Grund"),
      getOptions: {
        where: [["Typ", 0], "and", ["GueltigVon", "<=", stichtag], "and", ["GueltigBis", ">=", stichtag]],
        columns: ["Id", "Bezeichnung"]
      }
    });

    if (this.grundSelectBox) {
      this.grundSelectBox.setOption({
        dataSource: dataSource
      });
    } else {
      this.grundSelectBoxOptions.dataSource = dataSource;
    }
  }

  private checkAndDoBenutzerSperren(): void {
    if (!this.key || this.key == 0) {
      return;
    }

    const data: IBenutzerAccountSperrenData = {
      IdBenutzer: this.key,
      IdGrund: this.grundSelectBox.instance.option("value"),
      Bemerkung: this.grundBemerkungTextArea.instance.option("value")
    };

    if (!this.canBenutzerSperren(data)) {
      return;
    }

    this.doBenutzerSperren(data);
  }
  private canBenutzerSperren(data: IBenutzerAccountSperrenData): boolean {
    if (!data.IdGrund) {
      DevExpress.ui.notify(this.localization.translateOnce("benutzer-sperren.sperrgrund-fehlt"), "error", 3000);
    } else {
      return true;
    }
  }

  private doBenutzerSperren(data: any): void {
    this.restService.post({
      url: this.restService.getApiUrl("ZIT/Security/BenutzerSperren"),
      data: data,
      increaseLoadingCount: true
    }).then((r) => {
      this.benutzerSperrenPopup.instance.hide();
      this.form.models.reloadAll();
    });
  }
}
