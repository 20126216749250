import { autoinject } from "aurelia-framework";
import { EventAggregator } from 'aurelia-event-aggregator';
import { IRouteInfo } from '../../framework/forms/export';
import { LoadingService } from '../../zit-ui/export';
import { RestService } from '../../framework/base/services/rest-service';
import { StartupService } from './startup-service';
import { LayoutTyp } from '../interfaces/layout-typ';

@autoinject
export class FormEvalService {
  constructor(
    private eventAggregator: EventAggregator,
    private loading: LoadingService,
    private rest: RestService,
    private startup: StartupService
  ) {
    this.eventAggregator.subscribe("router:intercept-route", e => {
      const routeInfo: IRouteInfo = e.routeInfo;
      e.moduleIdPromise = this.checkRouteEx(routeInfo);
    });
  }

  checkRoute(route: string, layoutTyp: LayoutTyp, idObjekt: number, createLog: boolean): Promise<string> {
    switch (route) {
      case "Dashboard": {
        return Promise.resolve("zit/views/dashboard/dashboard-form-form");
      }
      case "Objekte/Person/:id": {
        return this.getFormLayoutInfo(
          "TIP.ZIT.Business.Entities.Objekte.Personen.Person",
          idObjekt,
          layoutTyp,
          createLog);
      }
      case "Objekte/Ansprechperson/:id": {
        return this.getFormLayoutInfo(
          "TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson",
          idObjekt,
          layoutTyp,
          createLog);
      }
      case "Objekte/Bewerbung/:id": {
        return this.getFormLayoutInfo(
          "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung",
          idObjekt,
          layoutTyp,
          createLog);
      }
      case "Objekte/DispoPlanstelle/:id": {
        return this.getFormLayoutInfo(
          "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstelle",
          idObjekt,
          layoutTyp,
          createLog);
      }
      case "Objekte/DispoPlanstellePersonenzuteilung/:id": {
        return this.getFormLayoutInfo(
          "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstellePersonenzuteilung",
          idObjekt,
          layoutTyp,
          createLog);
      }
      case "Objekte/Geschaeftspartner/:id": {
        return this.getFormLayoutInfo(
          "TIP.ZIT.Business.Entities.Objekte.Geschaeftspartner.Geschaeftspartner",
          idObjekt,
          layoutTyp,
          createLog);
      }
      case "Objekte/ProfilFirma/:id": {
        return this.getFormLayoutInfo(
          "TIP.ZIT.Business.Entities.Objekte.ProfilFirmen.ProfilFirma",
          idObjekt,
          layoutTyp,
          createLog);
      }
      case "Objekte/Stelleninserat/:id": {
        return this.getFormLayoutInfo(
          "TIP.ZIT.Business.Entities.Objekte.Stelleninserate.Stelleninserat",
          idObjekt,
          layoutTyp,
          createLog);
      }
      case "Objekte/Termin/:id": {
        return this.getFormLayoutInfo(
          "TIP.ZIT.Business.Entities.Objekte.Termine.Termin",
          idObjekt,
          layoutTyp,
          createLog);
      }
      case "Objekte/Event/:id": {
        return this.getFormLayoutInfo(
          "TIP.ZIT.Business.Entities.Objekte.Events.Event",
          idObjekt,
          layoutTyp,
          createLog);
      }
      case "Objekte/EventZeitraum/:id": {
        return this.getFormLayoutInfo(
          "TIP.ZIT.Business.Entities.Objekte.Events.EventZeitraum",
          idObjekt,
          layoutTyp,
          createLog);
      }
      case "Objekte/EventTeilnehmer/:id": {
        return this.getFormLayoutInfo(
          "TIP.ZIT.Business.Entities.Objekte.Events.EventTeilnehmer",
          idObjekt,
          layoutTyp,
          createLog);
      }
      default: {
        return null;
      }
    }
  }
  convertIdFormular(idFormular: string, throwErrorIfNotFound: boolean = true): string {
    switch (idFormular) {
      case "aufgabeEdit": {
        return "zit/views/objekte/aufgaben/aufgabe-edit-form";
      }
      case "zielEdit": {
        return "zit/views/objekte/ziele/ziel-edit-form";
      }
      case "informationRead": {
        return "zit/views/objekte/information/information-read-form";
      }
      case "stelleninseratFerialEdit": {
        return "zit/views/objekte/stelleninserat/stelleninserat-ferial-edit-form";
      }
      case "stelleninseratRead": {
        return "zit/views/objekte/stelleninserat/stelleninserat-read-form";
      }
      case "stelleninseratEmpleoEdit": {
        return "zit/views/objekte/stelleninserat/stelleninserat-empleo-edit-form";
      }
      case "stelleninseratKuMiEdit": {
        return "zit/views/objekte/stelleninserat/stelleninserat-kumi-edit-form";
      }
      case "stelleninseratLehreEdit": {
        return "zit/views/objekte/stelleninserat/stelleninserat-lehre-edit-form"
      }
      case "stelleninseratHeartbaseEdit": {
        return "zit/views/objekte/stelleninserat/stelleninserat-heartbase-edit-form"
      }
      case "stelleninseratLehreKundePackageEdit": {
        return "zit/views/objekte/stelleninserat/stelleninserat-lehre-kunde-package-edit-form"
      }
      case "stelleninseratLehreStandardinseratEdit": {
        return "zit/views/objekte/stelleninserat/stelleninserat-lehre-standardinserat-edit-form"
      }
      case "stelleninseratLehrePersonRead": {
        return "zit/views/objekte/stelleninserat/stelleninserat-lehre-person-read-form"
      }
      case "geschaeftspartnerRead": {
        return "zit/views/objekte/geschaeftspartner/geschaeftspartner-read-form";
      }
      case "geschaeftspartnerEdit": {
        return "zit/views/objekte/geschaeftspartner/geschaeftspartner-edit-form";
      }
      case "geschaeftspartnerHeartbaseEdit": {
        return "zit/views/objekte/geschaeftspartner/geschaeftspartner-heartbase-edit-form";
      }
      case "bewerbungRead": {
        return "zit/views/objekte/bewerbung/bewerbung-read-form";
      }
      case "bewerbungLehreEdit": {
        return "zit/views/objekte/bewerbung/bewerbung-lehre-edit-form";
      }
      case "bewerbungFerialEdit": {
        return "zit/views/objekte/bewerbung/bewerbung-edit-form";
      }
      case "bewerbungLehreErweiterungEdit": {
        return "zit/views/objekte/bewerbung/bewerbung-lehre-erweiterung-edit-form";
      }
      case "bewerbungHeartbaseErweiterungEdit": {
        return "zit/views/objekte/bewerbung/bewerbung-heartbase-erweiterung-edit-form";
      }
      case "bewerbungFerialErweiterungEdit": {
        return "zit/views/objekte/bewerbung/bewerbung-ferialer-erweiterung-edit-form";
      }
      case "BewerbungFerialerErsteinstieg": {
        return "zit/views/objekte/bewerbung/bewerbung-ferialer-ersteinstieg-edit-form";
      }
      case "BewerbungLehreErsteinstieg": {
        return "zit/views/objekte/bewerbung/bewerbung-lehre-ersteinstieg-edit-form";
      }
      case "ansprechpersonRead": {
        return "zit/views/objekte/ansprechperson/ansprechperson-read-form";
      }
      case "ansprechpersonEdit": {
        return "zit/views/objekte/ansprechperson/ansprechperson-edit-form";
      }
      case "ansprechpersonHeartbaseEdit": {
        return "zit/views/objekte/ansprechperson/ansprechperson-heartbase-edit-form";
      }
      case "profilFirmaEdit": {
        return "zit/views/objekte/profilfirma/profil-firma-edit-form";
      }
      case "personFerialerRead": {
        return "zit/views/objekte/person/person-ferial-read-form";
      }
      case "personFerialerErsteinstieg": {
        return "zit/views/objekte/person/person-ferialer-ersteinstieg-edit-form";
      }
      case "personLehreErsteinstieg": {
        return "zit/views/objekte/person/person-lehre-ersteinstieg-edit-form";
      }
      case "personEdit": {
        return "zit/views/objekte/person/person-edit-form";
      }
      case "personHeartbaseEdit": {
        return "zit/views/objekte/person/person-heartbase-edit-form";
      }
      case "personHeartbaseRead": {
        return "zit/views/objekte/person/person-heartbase-read-form";
      }
      case "einsatzplanungEdit": {
        return "zit/views/objekte/disposition/dispo-personenzuteilung-edit-form";
      }
      case "einsatzplanungBestaetigung": {
        return "zit/views/objekte/disposition/dispo-personenzuteilung-bestaetigung-edit-form";
      }
      case "einsatzplanungBestaetigungRead": {
        return "zit/views/objekte/disposition/dispo-personenzuteilung-bestaetigung-read-form";
      }
      case "einsatzplanungPersonEdit": {
        return "zit/views/objekte/disposition/dispo-personenzuteilung-person-edit-form";
      }
      case "dispoPlanstellePersonenzuteilungCheckInEdit": {
        return "zit/views/objekte/disposition/dispo-personenzuteilung-check-in-edit-form";
      }
      case "dispoPersonenzuteilungBestaetigung": {
        return "zit/views/objekte/disposition/dispo-personenzuteilung-bestaetigung-edit-form";
      }
      case "planstelleFerialerEdit": {
        return "zit/views/objekte/disposition/dispo-planstelle-edit-form";
      }
      case "terminEdit": {
        return "zit/views/objekte/termin/termin-edit-form";
      }
      case "terminHeartbaseEdit": {
        return "zit/views/objekte/termin/termin-heartbase-edit-form";
      }
      case "terminRead": {
        return "zit/views/objekte/termin/termin-read-form";
      }
      case "terminHeartbaseRead": {
        return "zit/views/objekte/termin/termin-heartbase-read-form";
      }
      case "eventEdit": {
        return "zit/views/objekte/event/event-edit-form";
      }
      case "eventZeitraumEdit": {
        return "zit/views/objekte/event/event-zeitraum-edit-form";
      }
      case "eventZeitraumRead": {
        return "zit/views/objekte/event/event-zeitraum-read-form";
      }
      case "eventTeilnehmerEdit": {
        return "zit/views/objekte/event/event-teilnehmer-edit-form";
      }
      case "eventTeilnehmerRead": {
        return "zit/views/objekte/event/event-teilnehmer-read-form";
      }
      default: {
        if (throwErrorIfNotFound) {
          throw new Error(`Kein Formular für ${idFormular} hinterlegt`);
        } else {
          return null;
        }
      }
    }
  }

  async hasBerechtigungBearbeitung(objektFullName: string, idObjekt: number): Promise<boolean> {
    const r = await this.rest.get({
      url: this.rest.getApiUrl(`ZIT/Layout/Form?objektFullName=${objektFullName}&idObjekt=${idObjekt}&createLog=false`),
      increaseLoadingCount: true
    });

    if (!r) {
      return false;
    }

    return !!r.IdFormularBearbeitung;
  }

  private checkRouteEx(routeInfo: IRouteInfo): Promise<string> {
    let route = routeInfo.route.route;
    if (Array.isArray(route)) {
      route = route[0];
    }

    const id = routeInfo.parameters.id;

    const layoutTyp = (routeInfo.parameters.mode == "1" || id == 0)
      ? LayoutTyp.Bearbeitung
      : LayoutTyp.Lese;

    return this.checkRoute(route, layoutTyp, id, true);
  }
  private async getFormLayoutInfo(objektFullName: string, idObjekt: number, layoutTyp: LayoutTyp, createLog: boolean): Promise<string> {
    const r = await this.rest.get({
      url: this.rest.getApiUrl(`ZIT/Layout/Form?objektFullName=${objektFullName}&idObjekt=${idObjekt}&createLog=${createLog}`),
      increaseLoadingCount: true
    });

    if (!r) {
      return null;
    }

    let idFormular;
    if (layoutTyp == LayoutTyp.Lese && r.IdFormularLese) {
      idFormular = r.IdFormularLese;
    } else if (layoutTyp == LayoutTyp.Bearbeitung && r.IdFormularBearbeitung) {
      idFormular = r.IdFormularBearbeitung;
    } else if (r.IdFormularLese) {
      idFormular = r.IdFormularLese;
    } else if (r.IdFormularBearbeitung) {
      idFormular = r.IdFormularBearbeitung;
    }

    if (!idFormular) {
      return null;
    }

    return this.convertIdFormular(idFormular);
  }
}
