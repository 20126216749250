import { autoinject } from "aurelia-framework";
import { LocalizationService } from '../../framework/base/export';
import { DialogConfirmService } from '../../framework/forms/export';

@autoinject
export class DialogService {
  constructor(
    private _localizationService: LocalizationService,
    private _dialogConfirmService: DialogConfirmService
  ) {}

  askZuteilungLoeschen() {
    return this._dialogConfirmService.show({
      message: this._localizationService.translateOnce("zit.zuteilung-loeschen"),
      title: this._localizationService.translateOnce("base.question")
    });
  }
}