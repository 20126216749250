import * as fwx from "../../../../../framework/forms/form-export";
import {
    PasswordResetFuncs
} from "./password-reset-form-funcs";

@fwx.autoinject
export class PasswordResetForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f: PasswordResetFuncs) {
        super(element, formBaseImport);
        this.id = "password-recovery-form";
        this.title = "password-recovery-form.password-recovery-form_caption";
        this.addModel({
            "id": "$m_Password",
            "filters": []
        });
        this.addFunction("$f", $f, "functions.$f");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "password-recovery-form.username_caption",
            "binding": {
                "dataContext": "$m_Password",
                "bindTo": "Username",
                "bindToFQ": "models.data.$m_Password.Username"
            },
            "validationRules": [],
            "id": "username",
            "options": {
                "optionsName": "usernameOptions",
                "optionsNameFQ": "usernameOptions"
            }
        });
        this.widgetCreator.addCommand(this, {
            "id": "wd2",
            "options": {
                "optionsName": "wd2Options",
                "optionsNameFQ": "wd2Options"
            },
            "binding": {
                "bindTo": "$f.resetPasswordCommand",
                "bindToFQ": "functions.$f.resetPasswordCommand"
            }
        });
        this.widgetCreator.addCommand(this, {
            "id": "wd3",
            "options": {
                "optionsName": "wd3Options",
                "optionsNameFQ": "wd3Options"
            },
            "binding": {
                "bindTo": "$f.backToLoginCommand",
                "bindToFQ": "functions.$f.backToLoginCommand"
            }
        });
        super.onConstructionFinished();
    }
}