import { FormBase } from './../../../../framework/forms/classes/form-base';
import { transient, TaskQueue } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { IdxSelectBoxComponent } from '../../../interfaces/export';
import { ICommandData } from '../../../../framework/forms/interfaces/command-data';
import { RestService, LocalizationService, FileService } from '../../../../framework/base/export';
import { DialogConfirmService } from '../../../../framework/forms/export';
import { StartupService } from "../../../services/export";

@transient()
@autoinject
export class TerminEmpfEinschaetzungEditUtils {
  constructor(
    private _taskQueue: TaskQueue,
    private _restService: RestService,
    private _dialogConfirmService: DialogConfirmService,
    private _localizationService: LocalizationService,
    private _fileService: FileService,
    private _startupService: StartupService
  ) { 
    this.hasBerechtEinschLoeschen = ["ADMIN", "ADMIN_HB"]
      .indexOf(_startupService.startupInfo.Benutzer.CodeBenutzerrolle) >= 0;
  }

  form: FormBase;

  akkEl: string;
  hasBerechtEinschLoeschen = false;

  pdfGenerierenCommand: ICommandData = {
    id: "pdfGenerierenCommand",
    icon: "far fa-file-pdf",
    title: "termin.einschaetzung-abschicken",
    isVisibleExpression: "!models.data.$m_TerminEmpfEinsch.IsFreigegeben",
    sortIndex: 600,
    execute: async () => {
      if (!this.hasFelderFuerDruck()) {
        DevExpress.ui.notify(
          "PDF-Dokument kann erst erzeugt werden, wenn alle Felder ausgefüllt sind",
          "error",
          3000
        );
        return;
      }

      const okResult = await this._dialogConfirmService.show({
        title: this._localizationService.translateOnce("base.question"),
        message: "Soll die Einschätzung wirklich gedruckt werden? Somit wird das PDF Dokument generiert und verteilt. Änderungen sind ab diesem Zeitpunkt nicht mehr möglich!"
      });

      if (!okResult) {
        return;
      }

      const r = await this.form.saveIfDirty();
      if (!r.isValid) {
        return;
      }

      await this._restService.post({
        url: this._restService.getApiUrl("ZIT/Termin/CreateEinschaetzungPdf"),
        data: {
          IdTerminEmpfEinschaetzung: this.form.models.data.$m_TerminEmpfEinsch.Id
        },
        increaseLoadingCount: true
      });

      this.form.models.reloadAll();
      DevExpress.ui.notify("PDF wird in Kürze erstellt", "success", 3000);
    }
  };
  pdfLoeschenCommand: ICommandData = {
    id: "pdfGenerierenCommand",
    icon: "far fa-trash-alt",
    title: "termin.dokument-loeschen",
    isVisibleExpression: "models.data.$m_TerminEmpfEinsch.IsFreigegeben && functions.$f_TerminEmpfEinschaetzungEditUtils.hasBerechtEinschLoeschen",
    sortIndex: 600,
    execute: async () => {
      const okResult = await this._dialogConfirmService.show({
        title: this._localizationService.translateOnce("base.question"),
        message: "Soll das Einschätzungsdokument wirklich gelöscht werden?"
      });

      if (!okResult) {
        return;
      }

      const r = await this.form.saveIfDirty();
      if (!r.isValid) {
        return;
      }

      await this._restService.post({
        url: this._restService.getApiUrl("ZIT/Termin/DeleteEinschaetzungPdf"),
        data: {
          IdTerminEmpfEinschaetzung: this.form.models.data.$m_TerminEmpfEinsch.Id
        },
        increaseLoadingCount: true
      });

      this.form.models.reloadAll();
    }
  };
  pdfAnzeigenCommand: ICommandData = {
    id: "pdfAnzeigenCommand",
    icon: "far fa-file-pdf",
    title: "termin.einschaetzung-oeffnen",
    isVisibleExpression: "models.data.$m_TerminEmpfEinsch.IsFreigegeben && models.data.$m_TerminEmpfEinsch.DMSLinkPdf",
    sortIndex: 600,
    execute: async () => {
      this._fileService.inline(this.form.models.data.$m_TerminEmpfEinsch.DMSLinkPdf);
    }
  };

  personSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Titel",
    searchEnabled: true,
    searchExpr: "Titel",
    bindingOptions: {
      readOnly: "models.data.$m_TerminEmpfEinsch.Id",
      value: "models.data.$m_TerminEmpfEinsch.IdTerminEmpfaengerElement"
    },
    onValueChanged: () => {
      this._taskQueue.queueMicroTask(() => {
        this.onPersonValueChanged();
      });
    }
  };
  geschaeftspartnerSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "IdGeschaeftspartner",
    displayExpr: "Name1",
    searchEnabled: true,
    searchExpr: "Name1",
    bindingOptions: {
      readOnly: "models.data.$m_TerminEmpfEinsch.Id",
      value: "models.data.$m_TerminEmpfEinsch.IdGeschaeftspartner"
    },
    onValueChanged: () => {
      this._taskQueue.queueMicroTask(() => {
        this.onGeschaeftspartnerValueChanged();
      });
    }
  };
  ansprechpersonSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "IdAnsprechperson",
    displayExpr: "Titel",
    searchEnabled: true,
    searchExpr: "Titel",
    bindingOptions: {
      readOnly: "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
      value: "models.data.$m_TerminEmpfEinsch.IdAnsprechperson"
    },
  };  

  requiredValidatorOptions = {
    validationRules: [{ type: "required", message: "Pflichtfeld" }]
  };

  bind(form: FormBase) {
    this.form = form;

    form.models.onLoaded.register(async (ev) => {
      if (ev.model.id == "$m_TerminEmpfEinsch") {
        if (ev.data) {
          if (!ev.data.IdTermin) {
            ev.data.IdTermin = form.variables.data.$v_idTermin;
          }
        }

        this.updatePersonDataSource();
      }
    });
  }

  private updatePersonDataSource() {
    const data = this.form.models.data.$m_TerminEmpfEinsch;

    let dataSource: any[] = data
      ? data._TerminEmpfList || []
      : [];

    if (dataSource.length > 0 && !data.Id) {
      dataSource = dataSource.filter(i => !i.HasEinschaetzung);
    }

    this.personSelectBoxOptions.dataSource = dataSource;

    const selectBox: IdxSelectBoxComponent = this.form["r_person"];
    if (selectBox && selectBox.instance) {
      selectBox.setOption({
        dataSource: dataSource
      });
    }
  }
  private onPersonValueChanged() {
    const data = this.form.models.data.$m_TerminEmpfEinsch;

    let dataSource: any[] = data
      ? data._GeschaeftspartnerList || []
      : [];

    if (dataSource.length > 0) {
      if (data.IdTerminEmpfaengerElement) {
        const terminEmpf = (data._TerminEmpfList || []).find((i) => i.Id == data.IdTerminEmpfaengerElement);
        const idPerson = terminEmpf
          ? terminEmpf.IdPerson
          : -1;

        dataSource = dataSource.filter(i => i.IdPerson == idPerson);

        if (terminEmpf && !data.Id) {
          data.EinschStaerken = terminEmpf.EinschStaerken;
          data.EinschWeiterentw = terminEmpf.EinschWeiterentw;
          data.EinschDafuerMachen = terminEmpf.EinschDafuerMachen;
          data.VerhaltenTrainingSE = terminEmpf.VerhaltenTrainingSE;
          data.VerhaltenArbeitenSE = terminEmpf.VerhaltenArbeitenSE;
          data.VerhaltenUmgangKollegenSE = terminEmpf.VerhaltenUmgangKollegenSE;
          data.UmgangVerhaltensregelnSE = terminEmpf.UmgangVerhaltensregelnSE;
        }
      } else {
        dataSource = [];
      }
    }

    this.geschaeftspartnerSelectBoxOptions.dataSource = dataSource;

    const selectBox: IdxSelectBoxComponent = this.form["r_geschaeftspartner"];
    if (selectBox && selectBox.instance) {
      selectBox.setOption({
        dataSource: dataSource
      });
    }

    if (data && !data.Id) {
      const newVal = dataSource.length == 1
        ? dataSource[0].IdGeschaeftspartner
        : null;

      if (newVal != data.IdGeschaeftspartner) {
        data.IdGeschaeftspartner = newVal;
      } else {
        this.onGeschaeftspartnerValueChanged();
      }
    }
  }
  private onGeschaeftspartnerValueChanged() {
    const data = this.form.models.data.$m_TerminEmpfEinsch || {};

    const terminEmpf = (data._TerminEmpfList || []).find((i) => i.Id == data.IdTerminEmpfaengerElement);
    const idPerson = terminEmpf
      ? terminEmpf.IdPerson
      : -1;

    let dataSource: any[] = data._AnsprechpersonList || [];

    dataSource = dataSource.filter((i) => {
      return i.IdPerson == idPerson
        && i.IdGeschaeftspartner == data.IdGeschaeftspartner;
    });

    this.geschaeftspartnerSelectBoxOptions.dataSource = dataSource;

    const selectBox: IdxSelectBoxComponent = this.form["r_ansprechperson"];
    if (selectBox && selectBox.instance) {
      selectBox.setOption({
        dataSource: dataSource
      });
    }

    if (data && !data.Id) {
      if (dataSource.length == 1) {
        data.IdAnsprechperson = dataSource[0].IdAnsprechperson;
      } else if (data) {
        data.IdAnsprechperson = null;
      }
    }
  }

  private hasFelderFuerDruck() {
    const data = this.form.models.data.$m_TerminEmpfEinsch;

    const hasAll = !!data.VerhaltenTrainingSE
      && !!data.VerhaltenTrainingFE
      && !!data.VerhaltenTrainingB
      && !!data.VerhaltenTrainingM
      && !!data.VerhaltenArbeitenSE
      && !!data.VerhaltenArbeitenFE
      && !!data.VerhaltenArbeitenB
      && !!data.VerhaltenArbeitenM
      && !!data.VerhaltenUmgangKollegenSE
      && !!data.VerhaltenUmgangKollegenFE
      && !!data.VerhaltenUmgangKollegenB
      && !!data.VerhaltenUmgangKollegenM
      && !!data.UmgangVerhaltensregelnSE
      && !!data.UmgangVerhaltensregelnFE
      && !!data.UmgangVerhaltensregelnB
      && !!data.UmgangVerhaltensregelnM
      && !!data.GesamteintragAmLC
      && !!data.TippsFuerAusbildner
      && !!data.EinschStaerken
      && !!data.EinschWeiterentw
      && !!data.EinschDafuerMachen;

    return hasAll;
  }
}
