import * as fwx from "../../../../framework/forms/form-export";
import {
    TerminReadUtils
} from "./termin-read-utils";

@fwx.autoinject
export class TerminHeartbaseReadForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_TerminReadUtils: TerminReadUtils) {
        super(element, formBaseImport);
        this.id = "termin-heartbase-read";
        this.title = "termin-heartbase-read.termin-heartbase-read_caption";
        this.addModel({
            "id": "$m_Termin",
            "webApiAction": "ZIT/Objekte/Termin",
            "webApiExpand": {
                'Empfaenger': {
                    'expand': {
                        'Person': {
                            'columns': [
                                'Id'
                            ]
                        }
                    }
                },
                'Trainer': {
                    'expand': {
                        'Ansprechperson': {
                            'columns': [
                                'Id'
                            ]
                        }
                    }
                },
                'Kopf': {
                    'expand': {
                        'Benutzer': null,
                        'Geschaeftspartner': null,
                        'TerminDateien': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "AddPersonCard",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddPersonCardKlein",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddPersonCardLehrberuf",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddAnsprechpersonCard",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddAnsprechpersonCardKlein",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "addEinschaetzung",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "addTeilnehmer",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddPersonCardGeschaeftspartner",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddAnsprechpersonCardBeschreibung",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_TerminEmpfEinschEdit",
            "webApiAction": "ZIT/Objekte/TerminEmpfEinschElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_TerminEmpfEinschRel",
            "webApiAction": "ZIT/Objekte/TerminEmpfEinschElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_TerminReadUtils.actionCommand",
                "bindToFQ": "functions.$f_TerminReadUtils.actionCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_TerminReadUtils.saveCommand",
                "bindToFQ": "functions.$f_TerminReadUtils.saveCommand"
            }
        });
        this.addEditPopup({
            "idContent": "terminEmpfEinschEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_TerminEmpfEinschEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_TerminEmpfEinschEdit.Id"
                }
            }, {
                "to": "$v_idTermin",
                "binding": {
                    "dataContext": "$m_Termin",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_Termin.Id"
                }
            }],
            "id": "terminEmpfEinschEditPopup",
            "options": {
                "optionsName": "terminEmpfEinschEditPopupOptions",
                "optionsNameFQ": "terminEmpfEinschEditPopupOptions"
            },
            "height": "90vh",
            "width": "800px",
            "commands": []
        });
        this.addFunction("$f_TerminReadUtils", $f_TerminReadUtils, "functions.$f_TerminReadUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_einschaetzungenListViewToolbarOptions",
                "optionsNameFQ": "r_einschaetzungenListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Termin"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_TerminEmpfEinschRel",
                "bindTo": "IdTermin",
                "bindToFQ": "models.data.$m_TerminEmpfEinschRel.IdTermin"
            },
            "dataModel": "$m_Termin",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_einschaetzungenListView",
            "options": {
                "optionsName": "r_einschaetzungenListViewOptions",
                "optionsNameFQ": "r_einschaetzungenListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "col-xs-12 col-sm-6",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_bewerbungenListViewToolbarOptions",
                "optionsNameFQ": "r_bewerbungenListViewToolbarOptions"
            },
            "binding": {
                "bindTo": "$f_TerminReadUtils.teilnehmerDataSource",
                "bindToFQ": "functions.$f_TerminReadUtils.teilnehmerDataSource"
            },
            "pageSize": 50,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewerbungenListView",
            "options": {
                "optionsName": "r_bewerbungenListViewOptions",
                "optionsNameFQ": "r_bewerbungenListViewOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 10.0,
            "isReadOnlyExpression": "models.data.$m_Termin._Einschaetzung.IsFreigegeben",
            "binding": {
                "bindTo": "$m_Termin._Einschaetzung.VerhaltenTrainingSE",
                "bindToFQ": "models.data.$m_Termin._Einschaetzung.VerhaltenTrainingSE"
            },
            "validationRules": [],
            "id": "r_verhaltenTrainingSE",
            "options": {
                "optionsName": "r_verhaltenTrainingSEOptions",
                "optionsNameFQ": "r_verhaltenTrainingSEOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 10.0,
            "isReadOnlyExpression": "models.data.$m_Termin._Einschaetzung.IsFreigegeben",
            "binding": {
                "bindTo": "$m_Termin._Einschaetzung.VerhaltenArbeitenSE",
                "bindToFQ": "models.data.$m_Termin._Einschaetzung.VerhaltenArbeitenSE"
            },
            "validationRules": [],
            "id": "r_verhaltenArbeitenSE",
            "options": {
                "optionsName": "r_verhaltenArbeitenSEOptions",
                "optionsNameFQ": "r_verhaltenArbeitenSEOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 10.0,
            "isReadOnlyExpression": "models.data.$m_Termin._Einschaetzung.IsFreigegeben",
            "binding": {
                "bindTo": "$m_Termin._Einschaetzung.VerhaltenUmgangKollegenSE",
                "bindToFQ": "models.data.$m_Termin._Einschaetzung.VerhaltenUmgangKollegenSE"
            },
            "validationRules": [],
            "id": "r_verhaltenUmgangKollegenSE",
            "options": {
                "optionsName": "r_verhaltenUmgangKollegenSEOptions",
                "optionsNameFQ": "r_verhaltenUmgangKollegenSEOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 10.0,
            "isReadOnlyExpression": "models.data.$m_Termin._Einschaetzung.IsFreigegeben",
            "binding": {
                "bindTo": "$m_Termin._Einschaetzung.UmgangVerhaltensregelnSE",
                "bindToFQ": "models.data.$m_Termin._Einschaetzung.UmgangVerhaltensregelnSE"
            },
            "validationRules": [],
            "id": "r_umgangVerhaltensregelnSE",
            "options": {
                "optionsName": "r_umgangVerhaltensregelnSEOptions",
                "optionsNameFQ": "r_umgangVerhaltensregelnSEOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-heartbase-read.r_einscheigstaerken_caption",
            "isReadOnlyExpression": "models.data.$m_Termin._Einschaetzung.IsFreigegeben",
            "binding": {
                "bindTo": "$m_Termin._Einschaetzung.EinschStaerken",
                "bindToFQ": "models.data.$m_Termin._Einschaetzung.EinschStaerken"
            },
            "validationRules": [],
            "id": "r_einschEigStaerken",
            "options": {
                "optionsName": "r_einschEigStaerkenOptions",
                "optionsNameFQ": "r_einschEigStaerkenOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-heartbase-read.r_einscheigweiterentw_caption",
            "isReadOnlyExpression": "models.data.$m_Termin._Einschaetzung.IsFreigegeben",
            "binding": {
                "bindTo": "$m_Termin._Einschaetzung.EinschWeiterentw",
                "bindToFQ": "models.data.$m_Termin._Einschaetzung.EinschWeiterentw"
            },
            "validationRules": [],
            "id": "r_einschEigWeiterentw",
            "options": {
                "optionsName": "r_einschEigWeiterentwOptions",
                "optionsNameFQ": "r_einschEigWeiterentwOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-heartbase-read.r_einscheigdafuermachen_caption",
            "isReadOnlyExpression": "models.data.$m_Termin._Einschaetzung.IsFreigegeben",
            "binding": {
                "bindTo": "$m_Termin._Einschaetzung.EinschDafuerMachen",
                "bindToFQ": "models.data.$m_Termin._Einschaetzung.EinschDafuerMachen"
            },
            "validationRules": [],
            "id": "r_einschEigDafuerMachen",
            "options": {
                "optionsName": "r_einschEigDafuerMachenOptions",
                "optionsNameFQ": "r_einschEigDafuerMachenOptions"
            }
        });
        super.onConstructionFinished();
    }
}