import { autoinject } from 'aurelia-framework';
import { RestService } from '../../framework/base/export';
@autoinject
export class StaatService {
  constructor(
    private rest: RestService
  ){}

  loadStaaten(getOptions?: any){
    return this.rest.get({
      url: this.rest.getWebApiUrl("ZIT/Stammdaten/Staat"),
      getOptions: getOptions
    });
  }
}