import { Share } from './../../../elements/share/share';
import { ICommandData } from './../../../../framework/forms/interfaces/command-data';
import { IZitTagOptions } from './../../../../zit-ui/elements/zit-tag/zit-tag-options';
import { autoinject, transient } from 'aurelia-framework';
import { FormBase } from './../../../../framework/forms/classes/form-base';
import { ISubheadlineOptions } from '../../../elements/sub-headline/sub-headline-options';

@autoinject
@transient()
export class GeschaeftspartnerReadUtils {
  constructor() { }

  form: FormBase

  packageTagOptions: IZitTagOptions = {
    icon: {
      text: "P"
    },
    textExpression: `item.Bezeichnung + ", gültig bis: " + globalization.format(item.GueltigBis, "d")`
  };
  adresseTagOptions: IZitTagOptions = {
    icon: {
      text: "A"
    },
    textExpression: `item | zitAddress:"short:street"`
  };
  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    isVisibleExpression: "r_share.canShare",
    sortIndex: 99,
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  }

  subHeadlineOptions: ISubheadlineOptions = {
    getTitel: () => Promise.resolve(this.form.models.data.$m_Geschaeftspartner.CustomSubHeadline)
  }

  bind(form: FormBase) {
    this.form = form;
  }
}
