import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class PersonBerufserfahrungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "person-berufserfahrung-edit";
        this.title = "person-berufserfahrung-edit.person-berufserfahrung-edit_caption";
        this.addModel({
            "id": "$m_Berufserfahrung",
            "webApiAction": "ZIT/Objekte/PersonBerufserfahrung",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-berufserfahrung-edit.r_unternehmen_caption",
            "binding": {
                "dataContext": "$m_Berufserfahrung",
                "bindTo": "Unternehmen",
                "bindToFQ": "models.data.$m_Berufserfahrung.Unternehmen"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_unternehmen",
            "options": {
                "optionsName": "r_unternehmenOptions",
                "optionsNameFQ": "r_unternehmenOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-berufserfahrung-edit.r_taetigkeit_caption",
            "binding": {
                "dataContext": "$m_Berufserfahrung",
                "bindTo": "Taetigkeit",
                "bindToFQ": "models.data.$m_Berufserfahrung.Taetigkeit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_taetigkeit",
            "options": {
                "optionsName": "r_taetigkeitOptions",
                "optionsNameFQ": "r_taetigkeitOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "person-berufserfahrung-edit.r_von_caption",
            "binding": {
                "dataContext": "$m_Berufserfahrung",
                "bindTo": "Von",
                "bindToFQ": "models.data.$m_Berufserfahrung.Von"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_von",
            "options": {
                "optionsName": "r_vonOptions",
                "optionsNameFQ": "r_vonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "person-berufserfahrung-edit.r_bis_caption",
            "binding": {
                "dataContext": "$m_Berufserfahrung",
                "bindTo": "Bis",
                "bindToFQ": "models.data.$m_Berufserfahrung.Bis"
            },
            "validationRules": [],
            "id": "r_bis",
            "options": {
                "optionsName": "r_bisOptions",
                "optionsNameFQ": "r_bisOptions"
            }
        });
        super.onConstructionFinished();
    }
}