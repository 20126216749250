
import { autoinject } from "aurelia-framework";
import { GlobalizationService } from '../../../framework/base/export';

@autoinject
export class DashboardBoxKennzahlen {
  constructor(
    private globalizationService: GlobalizationService
  ) {}

  item: any;
  kennzahlenBarGaugeOptions: DevExpress.viz.gauges.dxBarGaugeOptions = {}

  activate(item) {
    this.item = item;
    
    this.kennzahlenBarGaugeOptions = {
      values : [
        item.DataSource.Aufruf5Wochen,
        item.DataSource.Aufruf1Woche,
        item.DataSource.Mobil5Wochen
      ],
      endValue: (item.DataSource.Aufruf5Wochen * 1.07),
      startValue: 0,
      palette: "Office",
      tooltip: {
        enabled: true,
        customizeTooltip: (e) => {
          return { 
            text: e.index == 0 
            ? 'Seitenaufrufe innerhalb der letzten fünf Wochen' 
            : e.index == 1
            ? 'Seitenaufrufe innerhalb der letzten Woche'
            : e.index == 2
            ? 'mobile Seitenaufrufe in den letzten fünf Wochen'
            :''
          };
        }
      }
    }
  }
}
