import { autoinject } from "aurelia-framework";
import { HtmlEditorExtensionService } from '../../../zit-ui/services/html-editor-extension-service';
import { ICommandData } from '../../../framework/forms/interfaces/command-data';
import { ScopeContainer } from '../../../framework/base/export';
import { SimpleWidgetCreatorService } from '../../../framework/forms/export';
import { IdxPopupComponent } from '../../interfaces/export';

@autoinject
export class HtmlEditorExtension {
  private _callback: { (src: string, width: string, height: string) }

  constructor(
    private _htmlEditorExtensionService: HtmlEditorExtensionService,
    private _simpleWidgetCreator: SimpleWidgetCreatorService
  ) {
    this._htmlEditorExtensionService.element = this;
    this.createDefaultModel();
  }

  typ: string = "";

  scopeContainer: ScopeContainer;
  model: IModel;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "500px",
    height: "auto"
  }
  popupCommands: ICommandData[] = [{
    id: "uebernehmenCommand",
    idCategory: "$start",
    icon: "fas fa-check",
    tooltip: "html-editor-extension.uebernehmen",
    sortIndex: 1600,
    execute: () => {
      if (!this.model.src) {
        DevExpress.ui.notify("Url ist Pflicht", "error", 3000);
        return;
      }

      this.popup.instance.hide();
      this._callback(this.model.src, this.model.width, this.model.height);
    }
  }];

  srcOptions: DevExpress.ui.dxTextBoxOptions = {
    bindingOptions: {
      value: "model.src"
    }
  }
  widthOptions: DevExpress.ui.dxTextBoxOptions = {
    bindingOptions: {
      value: "model.width"
    }
  }
  heightOptions: DevExpress.ui.dxTextBoxOptions = {
    bindingOptions: {
      value: "model.height"
    }
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreator.updatePopupOptions({
      idToolbar: "htmlEditorExtensionPopupToolbar",
      caption: "html-editor-extension.einfuegen",
      options: this.popupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  addImage(callback: { (src: string, width: string, height: string) }) {
    this.typ = "Bild";
    this.show(callback);
  }
  addVideo(callback: { (src: string, width: string, height: string) }) {
    this.typ = "Video";
    this.show(callback);
  }

  private show(callback: { (src: string, width: string, height: string) }) {
    this._callback = callback;
    this.createDefaultModel();

    this.popup.instance.show();
  }
  private createDefaultModel() {
    this.model = {}
  }
}
interface IModel {
  src?: string;
  width?: string;
  height?: string;
}