import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class EventZeitraumKontingentEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "event-zeitraum-kontingent-edit";
        this.title = "event-zeitraum-kontingent-edit.event-zeitraum-kontingent-edit_caption";
        this.addModel({
            "id": "$m_EventZeitraumKontingent",
            "webApiAction": "ZIT/Objekte/EventZeitraumKontingentElement",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "geschaeftspartnerSimple",
            "filter": ["IsGeschaeftspartnerSchule", true],
            "filters": [],
            "caption": "event-zeitraum-kontingent-edit.r_geschaeftspartnerschule_caption",
            "binding": {
                "dataContext": "$m_EventZeitraumKontingent",
                "bindTo": "IdGeschaeftspartnerSchule",
                "bindToFQ": "models.data.$m_EventZeitraumKontingent.IdGeschaeftspartnerSchule"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartnerSchule",
            "options": {
                "optionsName": "r_geschaeftspartnerSchuleOptions",
                "optionsNameFQ": "r_geschaeftspartnerSchuleOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 99999.0,
            "format": "n0",
            "caption": "event-zeitraum-kontingent-edit.r_kontingent_caption",
            "binding": {
                "dataContext": "$m_EventZeitraumKontingent",
                "bindTo": "Kontingent",
                "bindToFQ": "models.data.$m_EventZeitraumKontingent.Kontingent"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_kontingent",
            "options": {
                "optionsName": "r_kontingentOptions",
                "optionsNameFQ": "r_kontingentOptions"
            }
        });
        super.onConstructionFinished();
    }
}