import {
  IContextMenuItem
} from "../interfaces/context-menu-item";
import * as DxLoader from "../../dx/dx-loader";

export class ContextMenu {
  constructor(
    private options?: IContextMenuOptions) { }

  items: IContextMenuItem[] = [];

  show(target) {
    if (this.items.length === 0) {
      return;
    }

    const options: DevExpress.ui.dxContextMenuOptions = {
      target: target,
      position: {
        /*boundary: document.body,
        boundaryOffset: {
          x: 5,
          y: 5
        },*/
        my: "top",
        at: "bottom",
      },
      items: this.items,
      onItemClick: (e) => {
        e.itemData.execute(e);

        if (this.options && this.options.onItemClick) {
          this.options.onItemClick(e.itemData);
        }
      },
      onShown: (e) => {
        const overlay: DevExpress.ui.dxOverlay = (<any>e.component)._overlay;
        if (!overlay) {
          return;
        }
        
        const overlayHeight = overlay.content().clientHeight;
        if (!overlayHeight) {
          return;
        }
        
        if (overlayHeight < window.innerHeight) {
          return;
        }
        
        const content = overlay.content();
        
        content.style.height = `${window.innerHeight}px`;
        DxLoader.createInstance("dxScrollView", content, {});
      },
      onHidden: (e) => {
        element.remove();

        if (instance) {
          instance.dispose();
        }

        if (this.options && this.options.onHidden) {
          this.options.onHidden();
        }
      }
    };

    const element =  document.createElement("div");
    
    document.body.appendChild(element);

    const instance: DevExpress.ui.dxContextMenu = DxLoader.createInstance("dxContextMenu", element, options);
    instance.show();
  }
}
export interface IContextMenuOptions {
  onHidden?(): void;
  onItemClick?(item: any): void;
}
