import { IListViewOptions } from './../../../framework/forms/elements/list-view/list-view-options';
import { ListView } from './../../../framework/forms/elements/list-view/list-view';
import { BenutzerTyp } from './../../enumerations/benutzer-typ';
import { StartupService } from './../../services/startup-service';
import { computedFrom } from 'aurelia-binding';
import {
  autoinject,
  Scope,
  OverrideContext
} from "aurelia-framework";
import {
  ScopeContainer,
  FileService
} from '../../../framework/base/export';
import {
  StaatService,
} from '../../services/export';
import {
  IdxPopupComponent,
  IdxValidationGroupComponent,
  IdxSelectBoxComponent,
  IdxTextBoxComponent
} from '../../interfaces/export';
import { LocalizationService, GlobalizationService } from './../../../framework/base/services/export';
import { ICommandData } from './../../../framework/forms/interfaces/command-data';
import { SimpleWidgetCreatorService, DialogConfirmService } from '../../../framework/forms/export';
import { IBenutzereinstellungData } from '../../interfaces/export';
import { BenutzerLoeschen } from '../benutzer-loeschen/benutzer-loeschen';
import { IdxDateBoxComponent, IdxCheckBoxComponent } from './../../interfaces/dx-components';
import { IZitTagOptions } from '../../../zit-ui/elements/zit-tag/export';
import { WhatsappAktivieren } from '../whatsapp-aktivieren/whatsapp-aktivieren';
import { HeaderService } from '../../../framework/default-ui/services/header-service';
import { PasswortAendern } from '../passwort-aendern/passwort-aendern';
import { BenutzereinstellungenService } from '../../services/benutzereinstellungen-service';
import { AvatarService } from '../../services/avatar-service';

@autoinject
export class Benutzereinstellungen {
  constructor(
    public startupService: StartupService,
    private _headerService: HeaderService,
    private _benutzereinstellungenService: BenutzereinstellungenService,
    private _fileService: FileService,
    private _localizationService: LocalizationService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _staatService: StaatService,
    private _dialogConfirmService: DialogConfirmService,
    private _globalizationService: GlobalizationService,
    private _avatarService: AvatarService
  ) {
    const benutzertyp = startupService.startupInfo.Benutzer.Benutzertyp;

    const isAdminJuristischIntern = benutzertyp == BenutzerTyp.Administrator
      || benutzertyp == BenutzerTyp.InternerBenutzer
      || benutzertyp == BenutzerTyp.JuristischePerson;

    this.isAdminJuristischIntern = isAdminJuristischIntern;
  }

  einstellungen: IBenutzereinstellungData;
  passwortAendern: PasswortAendern;

  whatsappAktivieren: WhatsappAktivieren;
  avatarStyle: any;

  scope: Scope;
  scopeContainer: ScopeContainer;

  benutzerLoeschen: BenutzerLoeschen;
  isJuristischePerson = this.startupService.startupInfo.Benutzer.Benutzertyp === BenutzerTyp.JuristischePerson;
  isAdminJuristischIntern: boolean;

  availableLandvorwahl: any[];
  userImageEditFileInput: HTMLInputElement;

  benutzereinstellungenCommands: ICommandData[] = [
    {
      id: "passwortAendern",
      icon: "fas fa-unlock-alt",
      title: "benutzereinstellungen.passwort-aendern",
      isVisible: true,
      isEnabled: true,
      execute: () => {
        this.passwortAendern.showPopup();
      }
    }, {
      id: "loeschenBenutzer",
      idCategory: "$start",
      icon: "fas fa-ban",
      tooltip: "benutzereinstellungen.benutzer-loeschen",
      sortIndex: 1100,
      execute: () => {
        this.onBenutzerLoeschenClicked();
      }
    }, {
      id: "speichernBenutzereinstellungen",
      idCategory: "$start",
      icon: "fas fa-save",
      tooltip: "base.save",
      sortIndex: 1200,
      execute: () => {
        this.onEinstellungenSpeichernClicked();
      }
    }
  ];
  benutzereinstellungenPopup: IdxPopupComponent;
  benutzereinstellungenPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: () => {
      if (!this.benutzereinstellungenPopup || !this.benutzereinstellungenPopup.instance) {
        return "auto"
      };

      const contentHeight = this.benutzereinstellungenPopup.instance.content().clientHeight;
      const windowHeight = window.innerHeight * 0.8;

      if (contentHeight < windowHeight) {
        return "auto";
      } else {
        return windowHeight;
      }
    },
    maxWidth: "1100px",
    onShown: () => {
      this.benutzereinstellungenPopup.instance.repaint();
    }
  };

  scrollViewOptions: DevExpress.ui.dxScrollViewOptions = {
    height: "100%"
  };

  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {};

  landvorwahlValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{
      type: "pattern",
      message: this._localizationService.translateOnce("benutzereinstellungen.validator_landvorwahl"),
      pattern: "^\\+[0-9]{1,3}$"
    }, {
      type: "custom",
      message: this._localizationService.translateOnce("benutzereinstellungen.validator_require_landvorwal"),
      reevaluate: true,
      validationCallback: (e): boolean => {
        if (this.einstellungen.Mobil && !this.einstellungen.MobilLandvorwahl) {
          return false;
        }

        return true;
      }
    }]
  };
  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };
  emailValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }, { type: "email" }]
  };
  mobilValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{
      type: "custom",
      message: this._localizationService.translateOnce("benutzereinstellungen.validator_telefon"),
      validationCallback: this.telefonValidationCallback.bind(this)
    }]
  };
  telefonValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{
      type: "custom",
      message: this._localizationService.translateOnce("benutzereinstellungen.validator_telefon"),
      validationCallback: this.telefonValidationCallback.bind(this)
    }]
  };
  benutzernameOptions: DevExpress.ui.dxTextBoxOptions = {
    placeholder: this._localizationService.translateOnce("benutzereinstellungen.benutzername"),
    bindingOptions: {
      value: "einstellungen.Benutzername"
    }
  };
  kontaktpersonTypOptions: DevExpress.ui.dxRadioGroupOptions = {
    layout: "horizontal",
    displayExpr: "text",
    valueExpr: "value",
    dataSource: [
      { text: this._localizationService.translateOnce("benutzereinstellungen.natuerliche-person"), value: 0 },
      { text: this._localizationService.translateOnce("benutzereinstellungen.team"), value: 1 }
    ],
    bindingOptions: {
      value: "einstellungen.Typ"
    },
  };
  geschlechtOptions: DevExpress.ui.dxRadioGroupOptions = {
    layout: "horizontal",
    displayExpr: "text",
    valueExpr: "value",
    dataSource: [
      { text: this._localizationService.translateOnce("benutzereinstellungen.weiblich"), value: 1 },
      { text: this._localizationService.translateOnce("benutzereinstellungen.maennlich"), value: 0 },
      { text: this._localizationService.translateOnce("benutzereinstellungen.divers"), value: 2 }
    ],
    bindingOptions: {
      value: "einstellungen.Geschlecht"
    }
  };
  vornameOptions: DevExpress.ui.dxTextBoxOptions = {
    placeholder: this._localizationService.translateOnce("benutzereinstellungen.vorname"),
    bindingOptions: {
      value: "einstellungen.Vorname"
    }
  };
  nachnameOptions: DevExpress.ui.dxTextBoxOptions = {
    placeholder: this._localizationService.translateOnce("benutzereinstellungen.nachname"),
    bindingOptions: {
      value: "einstellungen.Nachname"
    }
  };
  emailOptions: DevExpress.ui.dxTextBoxOptions = {
    placeholder: this._localizationService.translateOnce("benutzereinstellungen.email"),
    bindingOptions: {
      value: "einstellungen.Email"
    }
  };
  mobilLandvorwahl: IdxSelectBoxComponent;
  mobilLandvorwahlOptions: DevExpress.ui.dxSelectBoxOptions = {
    fieldTemplate: "zit-landvorwahl-bezeichnung-field-template",
    itemTemplate: "zit-landvorwahl-bezeichnung-template",
    valueExpr: "Key",
    displayExpr: "Bezeichnung",
    searchEnabled: true,
    showClearButton: true,
    searchExpr: ["Bezeichnung"],
    placeholder: this._localizationService.translateOnce("benutzereinstellungen.landvorwahl"),
    bindingOptions: {
      value: "einstellungen.MobilLandvorwahl",
      dataSource: "availableLandvorwahl"
    }
  }
  mobil: IdxTextBoxComponent;
  mobilOptions: DevExpress.ui.dxTextBoxOptions = {
    placeholder: this._localizationService.translateOnce("benutzereinstellungen.mobil-telefonnummer"),
    bindingOptions: {
      value: "einstellungen.Mobil"
    }
  };

  nachrichtSignaturOptions: DevExpress.ui.dxTextAreaOptions = {
    height: "45px",
    bindingOptions: {
      value: "einstellungen.NachrichtSignatur"
    }
  }

  unternehmensbezeichnungOptions: DevExpress.ui.dxTextBoxOptions = {
    placeholder: this._localizationService.translateOnce("benutzereinstellungen.unternehmensbezeichnung"),
    bindingOptions: {
      value: "einstellungen.Unternehmensbezeichnung"
    }
  };

  telefonLandvorwahl: IdxSelectBoxComponent;
  telefonLandvorwahlOptions: DevExpress.ui.dxSelectBoxOptions = {
    fieldTemplate: "zit-landvorwahl-bezeichnung-field-template",
    itemTemplate: "zit-landvorwahl-bezeichnung-template",
    valueExpr: "Key",
    displayExpr: "Bezeichnung",
    searchEnabled: true,
    showClearButton: true,
    searchExpr: ["Bezeichnung"],
    placeholder: this._localizationService.translateOnce("benutzereinstellungen.landvorwahl"),
    bindingOptions: {
      value: "einstellungen.TelefonLandvorwahl",
      dataSource: "availableLandvorwahl"
    }
  }
  telefonOptions: DevExpress.ui.dxTextBoxOptions = {
    placeholder: this._localizationService.translateOnce("benutzereinstellungen.festnetz-telefonnummer"),
    bindingOptions: {
      value: "einstellungen.Telefon"
    }
  };

  abwesendVon: IdxDateBoxComponent;
  abwesendVonOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this._globalizationService.getFormatterParser("d"),
    bindingOptions: {
      value: "einstellungen.AbwesendVon"
    }
  };

  abwesendBis: IdxDateBoxComponent;
  abwesendBisOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this._globalizationService.getFormatterParser("d"),
    bindingOptions: {
      value: "einstellungen.AbwesendBis"
    }
  };

  keineNachrichtenErhalten: IdxCheckBoxComponent;
  keineNachrichtenErhaltenOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: this._localizationService.translateOnce("benutzereinstellungen.keine_nachrichten_erhalten"),
    bindingOptions: {
      value: "einstellungen.KeineNachrichtenErhalten"
    }
  };

  benachrichtigungsartOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Value",
    displayExpr: "Name",
    bindingOptions: {
      value: "einstellungen.StandardBenachrichtigungsart"
    }
  };
  willkommenseiteOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: this._localizationService.translateOnce("benutzereinstellungen.willkommenseite_deaktivieren"),
    bindingOptions: {
      value: "einstellungen.IsWillkommensseiteDeaktiviert"
    }
  }
  keineExterneInformationVersendenOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: this._localizationService.translateOnce("benutzereinstellungen.keineExterneInformationVersenden"),
    bindingOptions: {
      value: "einstellungen.KeineExterneInformationVersenden"
    }
  }
  keineExterneInformationVersendenBeiAntwortOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: this._localizationService.translateOnce("benutzereinstellungen.keineExterneInformationVersendenBeiAntwort"),
    bindingOptions: {
      value: "einstellungen.KeineExterneInformationVersendenBeiAntwort"
    }
  }
  whatsappChannelTagOptions: IZitTagOptions = {
    icon: {
      text: "W",
      backgroundColorExpression: "einstellungen.IsWhatsAppChannelAktiviert ? 'rgb(0, 128, 0)': 'rgb(212, 68, 59)'"
    },
    textExpression: `einstellungen.IsWhatsAppChannelAktiviert
        ? '${this._localizationService.translateOnce("benutzereinstellungen.whatsapp_tag_aktiviert_text")}' 
        : '${this._localizationService.translateOnce("benutzereinstellungen.whatsapp_tag_nicht_aktiviert_text")}'`,
    backgroundColorExpression: "einstellungen.IsWhatsAppChannelAktiviert ? 'rgba(0, 128, 0, .3)': 'rgba(212, 68, 59, .3)'",
    isClickEnabledExpression: "!einstellungen.IsWhatsAppChannelAktiviert",
    onClick: (event, data) => {
      if (this.einstellungen.IsWhatsAppChannelAktiviert) {
        return;
      }
      this.showWhatsappAktivierenPopup();
    }
  };

  @computedFrom("einstellungen.Typ")
  get kontaktpersonTyp() {
    return this.einstellungen.Typ;
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "dmsViewerPopupToolbar",
      caption: "benutzereinstellungen.popup-titel",
      options: this.benutzereinstellungenPopupOptions,
      commands: this.benutzereinstellungenCommands,
      scopeContainer: this.scopeContainer
    });

    const startup = this.startupService.startupInfo;
    const benachrArtArr = [{
      Name: this._localizationService.translateOnce("benutzereinstellungen.email_art_caption"),
      Value: 0
    }];

    if (startup.Mandant.IsSmsAktiv) {
      benachrArtArr.push({
        Name: this._localizationService.translateOnce("benutzereinstellungen.sms_art_caption"),
        Value: 1
      });
    }
    if (startup.Benutzer.IsWhatsappAktiv) {
      benachrArtArr.push({
        Name: this._localizationService.translateOnce("benutzereinstellungen.whatsapp_art_caption"),
        Value: 2
      });
    }

    this.benachrichtigungsartOptions.dataSource = benachrArtArr;

    this.initializeLandvorwahlDataSource();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  async showPopup() {
    this.avatarStyle = Object.assign({}, this._headerService.avatarStyle);
    this.einstellungen = Object.assign({}, this._benutzereinstellungenService.benutzereinstellungenData);
    this.benutzereinstellungenPopup.instance.show();
  }

  onBenutzerImageClicked() {
    this.userImageEditFileInput.click();
  }
  async onBenutzerImageChanged() {
    const file = this.userImageEditFileInput.files[0];
    const dmsLink = await this._fileService.upload(file);

    this.avatarStyle = {
      "background-image": `url(${this._fileService.getInlineUrl(dmsLink)})`
    };
    this.einstellungen.DMSLinkAvatar = dmsLink;
  }

  onImpressumClick() {
    window.open(this.startupService.startupInfo.Mandant.UrlImpressum, "_blank");
  }
  onDatenschutzClick() {
    window.open(this.startupService.startupInfo.Mandant.UrlDatenschutz, "_blank");
  }
  onAgbClick() {
    window.open(this.startupService.startupInfo.Benutzer.UrlAgb, "_blank");
  }

  private async onEinstellungenSpeichernClicked() {
    const validataionResult = this.validationGroup.instance.validate();
    if (!validataionResult.isValid) {
      return;
    }

    await this._benutzereinstellungenService.saveBenutzereinstellungen(this.einstellungen);

    this.benutzereinstellungenPopup.instance.hide();
    DevExpress.ui.notify(this._localizationService.translateOnce("base.save_success"), "SUCCESS", 3000);
  }
  private onBenutzerLoeschenClicked() {
    const title = this._localizationService.translateOnce("benutzereinstellungen.benutzer-loeschen-frage-title");
    const message = this._localizationService.translateOnce("benutzereinstellungen.benutzer-loeschen-frage-text");
    this._dialogConfirmService.show({
      title: title,
      message: message
    }).then(confirmed => {
      if (!confirmed) {
        return;
      }

      this.benutzerLoeschen.showPopup();
    });
  }
  private async initializeLandvorwahlDataSource() {
    const staatList: any[] = await this._staatService.loadStaaten({
      columns: ["Id", "Bezeichnung", "LandesVorwahl", "DMSLinkIcon"],
      where: [["LandesVorwahl", "!=", "null"]]
    });

    this.availableLandvorwahl = staatList.map((item) => {
      item.Key = item.LandesVorwahl;
      item.Bezeichnung = `${item.Bezeichnung} ( ${item.LandesVorwahl} )`;
      item.DMSLinkIconFQ = this._fileService.getDownloadUrl(item.DMSLinkIcon);
      return item;
    });
  }
  private telefonValidationCallback(e): boolean {
    //Q how to get this froom validaionService??
    if (!e.value) {
      return true;
    }
    let val: string = e.value;

    val = val.replace(/[\\-]/g, ' ');
    val = val.replace(/[\\(\\)]*/g, '');

    const isValid = /^[0-9 ]{4,}$/.test(val);

    if (!isValid) {
      return false;
    }

    const einstellungPropertyName = e.validator.element()[0] === this.mobil.instance.element()[0]
      ? "Mobil"
      : "Telefon";

    if (this.einstellungen[einstellungPropertyName] != val) {
      this.einstellungen[einstellungPropertyName] = val;
    }

    return true;
  }
  private showWhatsappAktivierenPopup() {
    this.whatsappAktivieren.showPopup();
  }
}
