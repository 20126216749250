import { autoinject, bindable, TaskQueue } from "aurelia-framework";
import { ICategory } from "../toolbar/category";
import { IPage } from "../toolbar/page";

@autoinject
export class RibbonToolbarCategory {
  
  constructor(
  ) {}

  @bindable category: ICategory;
  @bindable showCategoryTitle: boolean;
}
