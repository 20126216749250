import {
  PLATFORM
} from "aurelia-framework";

export default {
  appId: "ZIT",
  baseUrl: "http://localhost:5214",
  apiUrl: "api",
  webApiUrl: "api/data",
  webSocketUrl: "api",

  loginApp: PLATFORM.moduleName("zit-ui/views/login/login"),
  mainApp: PLATFORM.moduleName("app")
}
