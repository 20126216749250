import * as fwx from "../../../../../framework/forms/form-export";
import {
    LoginTanFuncs
} from "./login-tan-funcs";

@fwx.autoinject
export class LoginTanForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f: LoginTanFuncs) {
        super(element, formBaseImport);
        this.id = "login-tan-form";
        this.title = "login-tan-form.login-tan-form_caption";
        this.addModel({
            "id": "$m_logintan",
            "filters": []
        });
        this.addFunction("$f", $f, "functions.$f");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "login-tan-form.tan_caption",
            "binding": {
                "dataContext": "$m_logintan",
                "bindTo": "Tan",
                "bindToFQ": "models.data.$m_logintan.Tan"
            },
            "validationRules": [],
            "id": "tan",
            "options": {
                "optionsName": "tanOptions",
                "optionsNameFQ": "tanOptions"
            }
        });
        this.widgetCreator.addCommand(this, {
            "id": "wd2",
            "options": {
                "optionsName": "wd2Options",
                "optionsNameFQ": "wd2Options"
            },
            "binding": {
                "bindTo": "$f.loginCommand",
                "bindToFQ": "functions.$f.loginCommand"
            }
        });
        this.widgetCreator.addCommand(this, {
            "id": "wd3",
            "options": {
                "optionsName": "wd3Options",
                "optionsNameFQ": "wd3Options"
            },
            "binding": {
                "bindTo": "$f.zuerueckCommand",
                "bindToFQ": "functions.$f.zuerueckCommand"
            }
        });
        super.onConstructionFinished();
    }
}