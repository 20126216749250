import {
  autoinject,
  valueConverter
} from "aurelia-framework";
import {
  LocalizationService
} from "../../services/localization-service";

@autoinject
@valueConverter("tr")
export class TranslationValueConverter{
  constructor(
    private localization: LocalizationService
  ) {}

  toView(value: any, parameters: string[] = null) {
    if (value == void(0)) {
      return "";
    }

    return this.localization.translateOnce(value, parameters);
  }
}
