import { autoinject, transient } from "aurelia-framework";
import { FormBase } from "../../../../framework/forms/classes/form-base";

@autoinject
@transient()
export class EventLinkAnmeldungEditUtils {
  constructor() {
  }
  
  form: FormBase;
  
  bind(form: FormBase) {
    this.form = form;
  }
}
