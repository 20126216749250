import { autoinject, bindable, createOverrideContext, observable, BindingEngine, Disposable } from "aurelia-framework";
import { IZitTagOptions } from '../../../zit-ui/elements/zit-tag/export';
import { ScopeContainer } from '../../../framework/base/export';
import { IToolbarOptions } from '../../../framework/forms/elements/toolbar/toolbar-options';
import { IItem } from '../../../framework/forms/elements/toolbar/item';
import { IListViewOptions } from '../../../framework/forms/elements/list-view/list-view-options';
import { ListView } from '../../../framework/forms/export';

@autoinject
export class TagList {
  private _collectionSubscription: Disposable;
  private _dataSource: any[] = [];

  constructor(
    private _element: Element,
    private _bindingEngine: BindingEngine
  ) {}

  @bindable titel: string;
  @bindable @observable dataSource: any[];
  @bindable tagOptions: IZitTagOptions;
  @bindable supportsAdd: boolean;

  scopeContainer: ScopeContainer;
  toolbarOptions: IToolbarOptions;
  listViewOptions: IListViewOptions;
  listView: ListView;

  bind(bindingContext, overrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: createOverrideContext(bindingContext, overrideContext)
    });

    this.toolbarOptions = {
      title: this.titel,
      icon: null,
      smallToolbar: true,
      items: this.getToolbarItems(),
      scopeContainer: this.scopeContainer
    };

    this.listViewOptions = {
      dataSource: new DevExpress.data.DataSource(new DevExpress.data.ArrayStore({ data: this._dataSource })),
      useDefaultListItemStyle: false,
      itemClass: "z--tag-list-view"
    };

    this.updateDataSourceObserver();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;

    this.updateDataSourceObserver(true);
  }

  dataSourceChanged() {
    this.updateDataSource();
    this.updateDataSourceObserver();
  }

  private getToolbarItems() {
    return <IItem[]>[{
      id: "add",
      icon: "fas fa-plus",
      isVisibleExpression: "supportsAdd",
      execute: () => {
        this._element.dispatchEvent(new CustomEvent(
          "on-tag-list-add", {
            bubbles: true
          }
        ))
      }
    }];
  }
  private updateDataSource() {
    this._dataSource.splice(0, this._dataSource.length);
    (this.dataSource || []).forEach(i => this._dataSource.push(i));
    
    this.listViewOptions.dataSource.reload();
  }
  private updateDataSourceObserver(onlyDispose: boolean = false) {
    if (this._collectionSubscription) {
      this._collectionSubscription.dispose(),
      this._collectionSubscription = null;
    }

    if (onlyDispose) {
      return;
    }

    if (!this.dataSource) {
      return;
    }

    this._collectionSubscription = this._bindingEngine.collectionObserver(this.dataSource).subscribe(() => {
      this.updateDataSource();
    });
  }
}