import * as fwx from "../../../../framework/forms/form-export";
import {
    BewerbungEditUtils
} from "./bewerbung-edit-utils";

@fwx.autoinject
export class BewerbungLehreErsteinstiegEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_BewerbungEditUtils: BewerbungEditUtils) {
        super(element, formBaseImport);
        this.id = "bewerbung-lehre-ersteinstieg-edit";
        this.title = "bewerbung-lehre-ersteinstieg-edit.bewerbung-lehre-ersteinstieg-edit_caption";
        this.addModel({
            "id": "$m_Bewerbung",
            "webApiAction": "ZIT/Objekte/Bewerbung",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'Stelleninserat': {
                            'expand': {
                                'Kopf': {
                                    'expand': {
                                        'Ansprechperson': null,
                                        'Lehrberuf': null,
                                        'Geschaeftspartner': {
                                            'expand': {
                                                'Geschaeftspartnerdaten': null,
                                                'ProfilFirma': {
                                                    'expand': {
                                                        'Profil': null
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        'Person': {
                            'expand': {
                                'Personendaten': null
                            }
                        },
                        'Bewerbungsgrund': null,
                        'Bewerbungskanal': null,
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "BewerbungInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "StelleninseratInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddBewerberSchnuppertermine",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_Person",
            "webApiAction": "ZIT/Objekte/Person",
            "modificationInfoEnabled": true,
            "key": "models.data.$m_Bewerbung.Kopf.IdPerson",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_DokumentRel",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "webApiExpand": {
                'Informationstyp': null
            },
            "webApiWhere": ["IdStelleninserat", {
                "isBound": true,
                "expression": "models.data.$m_Bewerbung.Kopf.IdStelleninserat"
            }],
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_DokumentEdit",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Geschaeftspartner",
            "webApiAction": "ZIT/Objekte/Geschaeftspartner",
            "webApiWhere": [],
            "modificationInfoEnabled": true,
            "key": "models.data.$m_Bewerbung.Kopf.Stelleninserat.Kopf.IdGeschaeftspartner",
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "standardAddress",
                "webApiCustomValue": "true"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BewerbungEditUtils.actionCommand",
                "bindToFQ": "functions.$f_BewerbungEditUtils.actionCommand"
            }
        });
        this.addFunction("$f_BewerbungEditUtils", $f_BewerbungEditUtils, "functions.$f_BewerbungEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "bewerbung-lehre-ersteinstieg-edit.r_taetigkeit_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Taetigkeit",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Taetigkeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_taetigkeit",
            "options": {
                "optionsName": "r_taetigkeitOptions",
                "optionsNameFQ": "r_taetigkeitOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "bewerbungsgrund",
            "filters": [],
            "caption": "bewerbung-lehre-ersteinstieg-edit.r_bewerbungsgrund_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdBewerbungsgrund",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdBewerbungsgrund",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bewerbungsgrund",
            "options": {
                "optionsName": "r_bewerbungsgrundOptions",
                "optionsNameFQ": "r_bewerbungsgrundOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bewerbung-lehre-ersteinstieg-edit.r_bewerbungsdatum_caption",
            "isReadOnlyExpression": "models.data.$m_Bewerbung.Kopf.Id > 0",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Bewerbungsdatum",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Bewerbungsdatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_bewerbungsdatum",
            "options": {
                "optionsName": "r_bewerbungsdatumOptions",
                "optionsNameFQ": "r_bewerbungsdatumOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "stelleninseratKlasse",
            "filter": ["StelleninseratKopfElement.IdStelleninserat", {
                "isBound": true,
                "expression": "models.data.$m_Bewerbung.Kopf.IdStelleninserat"
            }],
            "filters": [],
            "caption": "bewerbung-lehre-ersteinstieg-edit.r_klasse_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdStelleninseratKlasse",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdStelleninseratKlasse",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_klasse",
            "options": {
                "optionsName": "r_klasseOptions",
                "optionsNameFQ": "r_klasseOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "bewerbung-lehre-ersteinstieg-edit.r_isbewerbungschnuppertermin_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IsBewerbungSchnuppertermin",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IsBewerbungSchnuppertermin",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_isBewerbungSchnuppertermin",
            "options": {
                "optionsName": "r_isBewerbungSchnupperterminOptions",
                "optionsNameFQ": "r_isBewerbungSchnupperterminOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "bewerbung-lehre-ersteinstieg-edit.r_isbewerbunglehrstelle_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IsBewerbungLehrstelle",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IsBewerbungLehrstelle",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_isBewerbungLehrstelle",
            "options": {
                "optionsName": "r_isBewerbungLehrstelleOptions",
                "optionsNameFQ": "r_isBewerbungLehrstelleOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_bewerbungAbgeschlossenPopup",
            "options": {
                "optionsName": "r_bewerbungAbgeschlossenPopupOptions",
                "optionsNameFQ": "r_bewerbungAbgeschlossenPopupOptions"
            },
            "caption": "bewerbung-lehre-ersteinstieg-edit.r_bewerbungabgeschlossenpopup_caption",
            "height": "auto",
            "width": "450px",
            "showCloseButton": false,
            "commands": [{
                "binding": {
                    "bindTo": "$f_BewerbungEditUtils.abschliessenCommand",
                    "bindToFQ": "functions.$f_BewerbungEditUtils.abschliessenCommand"
                }
            }]
        });
        super.onConstructionFinished();
    }
}