import { bindable } from 'aurelia-framework';
import { IErklaerungsvideo } from './../../interfaces/startup-info';
import { autoinject, Scope, OverrideContext, computedFrom } from 'aurelia-framework';
import { ScopeContainer } from '../../../framework/base/export';
import { ErklaervideoService } from '../../services/erklaervideo-service';

@autoinject
export class ErklaerungsvideoElement {
  constructor(
    private erklaervideo: ErklaervideoService
  ) {
  }

  scope: Scope;
  scopeContainer: ScopeContainer;
  icon: string;
  text: string;

  erklaerungsvideo: IErklaerungsvideo;

  @bindable code: string;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.erklaerungsvideo = this.erklaervideo.getErklaerungsvideo(this.code);
    if (this.erklaerungsvideo == null) {
      return;
    }

    this.text = this.erklaervideo.erklaerungsvideoElementBezeichnung(this.erklaerungsvideo);
    this.icon = this.erklaervideo.erklaerungsvideoElementIcon(this.erklaerungsvideo);
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  public onElementClick() {
    if (!this.code || this.code.length == 0) {
      return;
    }

    const erklaervideoList = this.erklaervideo.getContextMenuItems(this.code);
    if (!erklaervideoList || erklaervideoList.length == 0) {
      return;
    }

    const erklaervideo = erklaervideoList[0];

    const action = this.erklaervideo.erklaerungsvideoAction(erklaervideo);
    if (action == null) {
      return;
    }

    action();
  }
}
