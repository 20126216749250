import { ReportErstellen } from './../../elements/report-erstellen/report-erstellen';
import { ICommandData } from './../../../framework/forms/interfaces/command-data';
import { transient, autoinject } from "../../../../node_modules/aurelia-framework";
import { FormBase } from '../../../framework/forms/export';
import { StartupService } from '../../services/export';
import { BenutzerTyp } from '../../enumerations/export';

@autoinject
@transient()
export class SchnupperterminListUtils {
  constructor(
    private startupService: StartupService
  ) {
    const natuerlicherBenutzer = this.startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.NatuerlichePerson;
    this.isNatuerlicherBenutzer = natuerlicherBenutzer;
  }

  form: FormBase;
  isNatuerlicherBenutzer: boolean;

  schnupperterminReportAuswahlCommand: ICommandData = {
    id: "schnupperterminReportAuswahlCommand",
    icon: "fas fa-print",
    sortIndex: 9999,
    isVisibleExpression: "!functions.$f_SchnupperterminListUtils.isNatuerlicherBenutzer",
    tooltip: "schnuppertermin-utils.report_erstellen",
    execute: () => {
      const report: ReportErstellen = this.form["r_reportErstellenElement"];
      report.reportErstellenPopup.instance.show();
    }
  };

  bind(form: FormBase) {
    this.form = form;
  }
}
