import { autoinject, transient } from "aurelia-framework";
import { ContextMenu, IListOptionsBase } from "../../../../framework/forms/export";
import { IZitToolbarOptions } from "../../../../zit-ui/elements/zit-toolbar/zit-toolbar-options";
import { GeschaeftspartnerErweiterteOptionen } from "../../../elements/geschaeftspartner-erweiterte-optionen/geschaeftspartner-erweiterte-optionen";
import { ISubheadlineOptions } from "../../../elements/sub-headline/sub-headline-options";
import { BenutzerTyp } from "../../../enumerations/benutzer-typ";
import { CodesService } from "../../../services/export";
import { StartupService } from "../../../services/startup-service";
import { LocalizationService, LocationService } from "./../../../../framework/base/services/export";
import { FormBase } from "./../../../../framework/forms/classes/form-base";
import { ListView } from "./../../../../framework/forms/elements/list-view/list-view";
import { ICommandData } from "./../../../../framework/forms/interfaces/command-data";
import { DefaultCommandsService, PopupInfoService } from "./../../../../framework/forms/services/export";
import { IZitTagOptions } from "./../../../../zit-ui/elements/zit-tag/zit-tag-options";
import { Share } from "./../../../elements/share/share";
import { IBenutzerrolleCodeData } from "./../../../interfaces/benutzerrolle-code-data";

@autoinject
@transient()
export class GeschaeftspartnerEditUtils {
  constructor(
    private _codesService: CodesService,
    private _defaultCommandService: DefaultCommandsService,
    private _localizationService: LocalizationService,
    private _startupService: StartupService,
    private _locationService: LocationService,
    private _popupInfoService: PopupInfoService) {
    const benutzertyp = _startupService.startupInfo.Benutzer.Benutzertyp;

    const isAdminOrInternerUser = benutzertyp == BenutzerTyp.Administrator
      || benutzertyp == BenutzerTyp.InternerBenutzer;

    const isAdmin = benutzertyp == BenutzerTyp.Administrator;

    this.canEditDokument = isAdminOrInternerUser;
    this.canEditPackage = isAdminOrInternerUser;
    this.isAdminOrInternerBenutzer = isAdminOrInternerUser;
    this.showSchnupperterminMaske = _startupService.startupInfo.ShowSchnupperterminMaske;
    this.isAdmin = isAdmin;
  }

  form: FormBase;

  canEditPackage: boolean;
  canEditDokument: boolean;
  isAdminOrInternerBenutzer: boolean;
  showSchnupperterminMaske: boolean;
  isAdmin: boolean;
  jetzt: Date = new Date();

  subHeadlineOptions: ISubheadlineOptions = {
    getTitel: () => Promise.resolve(this.form.models.data.$m_Geschaeftspartner.CustomSubHeadline)
  };

  detailToolbarOptions: IZitToolbarOptions = {
    title: "geschaeftspartner.details-toolbar-title",
    smallToolbar: true,
    items: [{
      id: "geschaeftspartnerEditUtilsDetailCommand",
      icon: "fas fa-plus",
      execute: (e) => {
        this.showDetailCtxMenu(e.event);
      }
    }]
  };
  ansprechpersonTagOptions: IZitTagOptions = {
    hasDefaultInfo: true,
    isClickEnabled: true,
    icon: {
      text: "AP"
    },
    textExpression: "item.AnsprechpersonNameGesamt",
    onClick: (e: any, item: any) => {
      const idAnsprechperson = item.IdAnsprechperson;
      if (!idAnsprechperson) {
        return;
      }

      this._locationService.goTo({
        url: `Objekte/Ansprechperson/${idAnsprechperson}?mode=1`,
        currentViewModel: this.form
      });
    }
  };
  dokumentTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    icon: {
      text: "D"
    },
    textExpression: "item.DokumentBezeichnung"
  };
  packageTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    backgroundColorExpression: `item.GueltigVon <= functions.$f_GeschaeftspartnerEditUtils.jetzt 
    && (!item.GueltigBis || item.GueltigBis >= functions.$f_GeschaeftspartnerEditUtils.jetzt) 
    ? 'rgba(0, 128, 0, 0.3)' : ''`,
    icon: {
      text: "P",
      backgroundColorExpression: `item.GueltigVon <= functions.$f_GeschaeftspartnerEditUtils.jetzt 
      && (!item.GueltigBis || item.GueltigBis >= functions.$f_GeschaeftspartnerEditUtils.jetzt) 
      ? 'rgba(0, 128, 0, 1)' : ''`,
    },
    textExpression: `item.GueltigBis 
    ? item.Bezeichnung + ", " + globalization.format(item.GueltigVon, "d") + " - " + globalization.format(item.GueltigBis, "d")
    : item.Bezeichnung + ", " + globalization.format(item.GueltigVon, "d")`
  };
  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    isVisibleExpression: "r_share.canShare",
    sortIndex: 99,
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };
  geschaeftspartnerErweiterteOptionenCommand: ICommandData = {
    id: "gpErweiterteOptioneneCommand",
    icon: "fas fa-cog",
    tooltip: "geschaeftspartner.erweiterte-optionen",
    sortIndex: 995,
    execute: (e) => {
      const gpErweiterte: GeschaeftspartnerErweiterteOptionen = this.form["r_gpErweiterteOptionen"];
      const target = e.event.target;

      gpErweiterte.showPopover(target);
    }
  };

  bind(form: FormBase) {
    this.form = form;

    this.form.editPopups.onEditPopupHidden.register((args) => {
      if (args.editPopup.id == "geschaeftspartnerZuAnsprechpersonEditPopup") {
        this.refreshListView("r_geschaeftspartnerAnsprechpersonListView");
      }

      return Promise.resolve();
    });

    this.form.onReactivated.register((args) => {
      this.refreshListView("r_geschaeftspartnerAnsprechpersonListView");
      return Promise.resolve();
    });
  }

  private showDetailCtxMenu(event: Event) {
    const ctxMenu = new ContextMenu();

    this.loadCtxMenuItems(ctxMenu);

    ctxMenu.show(event.target);
  }

  private loadCtxMenuItems(ctxMenu: ContextMenu) {
    if (!ctxMenu) {
      return;
    }
    ctxMenu.items = [{
      text: this._localizationService.translateOnce("geschaeftspartner.details-toolbar-adressen"),
      execute: () => {
        this.showListEditPopup("$m_GeschaeftspartnerAdresseEdit", "geschaeftspartnerAdresseEditPopup", "r_geschaeftspartnerAdresseListView");
      }
    }, {
      text: this._localizationService.translateOnce("geschaeftspartner.details-toolbar-ansprechpersonen"),
      execute: () => {
        if (this.form.models.data.$m_Geschaeftspartner.Id === null) {
          return;
        }

        this._popupInfoService.closeAllPopups();

        this._locationService.goTo({
          url: `Objekte/Ansprechperson/0`,
          setValuesOnModelWithKeyIdLoaded: { _IdGeschaeftspartner: this.form.models.data.$m_Geschaeftspartner.Id },
          replace: false
        });
      }
    }, {
      text: this._localizationService.translateOnce("geschaeftspartner.details-toolbar-bankverbindungen"),
      execute: () => {
        this.showListEditPopup("$m_GeschaeftspartnerBankverbindungEdit", "geschaeftspartnerBankverbindungEditPopup", "r_geschaeftspartnerBankverbindungListView");
      }
    }];

    if (this.canEditDokument) {
      ctxMenu.items.push({
        text: this._localizationService.translateOnce("geschaeftspartner.details-toolbar-dokumente"),
        execute: () => {
          this.showListEditPopup("$m_GeschaeftspartnerZuDokumentEdit", "geschaeftspartnerDokumentEditPopup", "r_geschaeftspartnerDokumentListView");
        }
      });
    }

    if (this.form.models.data.$m_Geschaeftspartner.SonstigeKontaktdatenVisible) {
      ctxMenu.items.push(
        {
          text: this._localizationService.translateOnce("geschaeftspartner.details-toolbar-kontaktdaten"),
          execute: () => {
            this.showListEditPopup("$m_GeschaeftspartnerKontaktEdit", "geschaeftspartnerKontaktdatenEditPopup", "r_geschaeftspartnerKontaktdatenListView");
          }
        });
    }

    if (this.canEditPackage) {
      ctxMenu.items.push({
        text: this._localizationService.translateOnce("geschaeftspartner.details-toolbar-packages"),
        execute: () => {
          this.showListEditPopup("$m_GeschaeftspartnerPackageEdit", "geschaeftspartnerPackageEditPopup", "r_geschaeftspartnerPackageListView");
        }
      });
    }
  }

  private showListEditPopup(editDataContext: string, idEditPopup: string, idListView: string) {
    const options = this.getListViewBaseOption(editDataContext, idEditPopup, this.form[idListView]);

    const addCommand = this._defaultCommandService.getListAddCommand(this.form, options);
    const event = new MouseEvent("click");
    addCommand.execute({ event: event });
  }

  private getListViewBaseOption(editDataContext: string, idEditPopup: string, options: IListOptionsBase): IListOptionsBase {
    options.editDataContext = editDataContext;
    options.idEditPopup = idEditPopup;
    options.listEdits = options.listEdits || [];
    return options;
  }

  private refreshListView(id: string) {
    const listView: ListView = this.form[id];

    listView.refresh();
  }
}
