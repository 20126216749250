import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class GeschaeftspartnerDokumentEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "geschaeftspartner-dokument-edit";
        this.title = "geschaeftspartner-dokument-edit.geschaeftspartner-dokument-edit_caption";
        this.addModel({
            "id": "$m_Dokument",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerdatenElementZuDokumentHintergrundAttachment",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "dokumentHintergrundAttachment",
            "filter": ["AllowZuteilungImGeschaeftspartner", "true"],
            "filters": [],
            "caption": "geschaeftspartner-dokument-edit.r_dokument_caption",
            "binding": {
                "dataContext": "$m_Dokument",
                "bindTo": "IdDokumentHintergrundAttachment",
                "bindToFQ": "models.data.$m_Dokument.IdDokumentHintergrundAttachment"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_dokument",
            "options": {
                "optionsName": "r_dokumentOptions",
                "optionsNameFQ": "r_dokumentOptions"
            }
        });
        super.onConstructionFinished();
    }
}