import { autoinject, bindable, TaskQueue, observable, computedFrom } from "aurelia-framework";
import { IZitTagOptions } from '../../../zit-ui/elements/zit-tag/zit-tag-options';
import { FileService, BrowserService } from '../../../framework/base/export';
import { DateiService } from '../../services/datei-service';
import { DateiAuswaehlen } from '../datei-auswaehlen/datei-auswaehlen';
import { DateiGalerie } from '../datei-galerie/datei-galerie';
import { DateiGalerieViewer } from '../datei-galerie-viewer/datei-galerie-viewer';
import { IdxScrollViewComponent } from '../../interfaces/export';
import { IframePopupService } from '../../services/iframe-popup-service';

@autoinject
export class Datei {
  constructor(
    private _element: Element,
    private _taskQueue: TaskQueue,
    private _fileService: FileService,
    private _dateiService: DateiService,
    private _iframePopupService: IframePopupService,
    private _browserService: BrowserService
  ) {
    this.isMobile = _browserService.isMobile;
  }

  isMobile: boolean;

  @bindable @observable dateiList: IDatei[];
  @bindable isReadOnly: boolean;
  @bindable showToolbar: boolean;
  
  showBild: boolean;
  showDokument: boolean;

  dateiAuswaehlen: DateiAuswaehlen;
  dateiGalerie: DateiGalerie;
  dateiGalerieViewer: DateiGalerieViewer;

  bildList: IDatei[];
  dokumentList: IDatei[];

  dokumentTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "fas fa-paperclip"
    },
    textExpression: "item.Dateiname",
    onClick: (e, data: IDatei) => {
      e.stopPropagation();

      if (data["_IsGruppe"]) {
        this.dateiGalerieViewer.show({
          popupTitel: data.Dateiname,
          dateiList: data["_GruppeDateiList"]
        });
      } else {
        if (data.VideoUrl) {
          this._iframePopupService.popup.show({
            titel: data.Dateiname,
            src: `https://player.vimeo.com${data.VideoUrl}`,
            width: 400,
            height: 300,
            allow: "autoplay; fullscreen",
            allowFullScreen: true
          });
        } else {
          this._fileService.download(data.DMSLink);
        }
      }
    },
    onDeleteClick: (e, data) => {
      e.stopPropagation();

      const deleteDatei = (d) => {
        const index = this.dateiList.indexOf(d);
        if (index < 0) {
          return;
        }
  
        this.dateiList.splice(index, 1);
        this.dispatchDateiDelete(d.id);
      };

      if (data["_IsGruppe"]) {
        const dataList: any[] = data["_GruppeDateiList"];
        dataList.forEach(d => deleteDatei(d));
      } else {
        deleteDatei(data);
      }
      
      this.refresh();
      this.dispatchDateiListChanged();
    }
  };
  dokumentReadOnlyTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "fas fa-paperclip"
    },
    textExpression: "item.Dateiname",
    onClick: (e, data: IDatei) => {
      e.stopPropagation();

      if (data["_IsGruppe"]) {
        this.dateiGalerieViewer.show({
          popupTitel: data.Dateiname,
          dateiList: data["_GruppeDateiList"]
        });
      } else {
        if (data.VideoUrl) {
          this._iframePopupService.popup.show({
            titel: data.Dateiname,
            src: `https://player.vimeo.com${data.VideoUrl}`,
            width: 400,
            height: 300,
            allow: "autoplay; fullscreen",
            allowFullScreen: true
          });
        } else {
          this._fileService.download(data.DMSLink);
        }
      }
    }
  }

  scroll: IdxScrollViewComponent;
  scrollOptions: DevExpress.ui.dxScrollViewOptions = {
    direction: "horizontal",
    height: "0"
  };

  get dokumentValidatedTagOptions(): IZitTagOptions {
    if (this.isReadOnly) {
      return this.dokumentReadOnlyTagOptions;
    } else {
      return this.dokumentTagOptions;
    }
  }
  @computedFrom("showToolbar", "isReadOnly")
  get showToolbarValidated() {
    return this.showToolbar && !this.isReadOnly;
  }

  bind() {
    this.refresh();
  }

  dateiListChanged() {
    this.refresh();
  }

  refresh() {
    this.dateiList = this.dateiList || [];

    this.showBild = false;
    this.showDokument = false;
    this.bildList = [];
    this.dokumentList = [];

    const gruppeArr = {};

    for (let datei of this.dateiList) {
      if (datei.Gruppe) {
        if (!gruppeArr[datei.Gruppe]) {
          gruppeArr[datei.Gruppe] = [];
        }

        gruppeArr[datei.Gruppe].push(datei);
      } else if (this._dateiService.isBild(datei.Dateiname)) {
        this.showBild = true;
        this.bildList.push(datei);
      } else {
        this.showDokument = true;
        this.dokumentList.push(datei);
      }
    }

    for (let gruppeKey in gruppeArr) {
      const gruppe: Datei[] = gruppeArr[gruppeKey];

      const dummyDatei: IDatei = {
        Dateiname: `${gruppeKey} (${gruppe.length})`,
        DMSLink: null
      };
      dummyDatei["_IsGruppe"] = true;
      dummyDatei["_GruppeDateiList"] = gruppe;

      this.showDokument = true;
      this.dokumentList.push(dummyDatei);
    }

    this.scrollOptions.height = this.showBild ? "100px" : "0";
    if (this.scroll && this.scroll.instance) {
      this.scroll.setOption(this.scrollOptions);
    }

    this._taskQueue.queueMicroTask(() => {
      this._element.dispatchEvent(new CustomEvent(
        "on-datei-refreshed",
        {
          bubbles: true
        }
      ));
    });
    this._taskQueue.queueTask(() => {
      if (this.scroll && this.scroll.instance) {
        this.scroll.instance.update();
      }
    });
  }

  onCameraClick() {
    this.dateiAuswaehlen.onMakePhoto(true, true);
  }
  onVideoClick() {
    this.dateiAuswaehlen.onMakeVideo(true, true);
  }
  onDokumentClick() {
    this.dateiAuswaehlen.onOpenFiles(true);
  }
  onAddToGalerieClick(callback?: {(): void}) {
    this.dateiGalerie.show({
      callback: (dateiList) => {
        dateiList.forEach(d => {
          const datei = this.dateiList.find(dd => dd.DMSLink == d.DMSLink);
          if (datei) {
            datei.Gruppe = d.Gruppe;
          } else {
            this.dateiList.push(d);
          }
        });

        this.refresh();
        this.dispatchDateiListChanged();

        if (callback) {
          callback();
        }
      }
    });
  }
  onBildClick(e: Event, bild: IDatei) {
    e.stopPropagation();
    e.preventDefault();

    this._fileService.inline(bild.DMSLinkDownload || bild.DMSLink);
  }
  onDeleteBild(e: Event, bild: IDatei) {
    e.stopPropagation();
    e.preventDefault();

    const index = this.dateiList.indexOf(bild);
    if (index < 0) {
      return;
    }

    this.dateiList.splice(index, 1);
    this.refresh();
    this.dispatchDateiDelete(bild.Id);
    this.dispatchDateiListChanged();
  }
  async onDateiAusgewaehlt(e: CustomEvent) {
    for (let file of <File[]>e.detail.files) {
      const dmsLink = await this._fileService.upload(file);
      if (!dmsLink) {
        return;
      }

      this.dateiList.push({ DMSLink: dmsLink, Dateiname: file.name });
    }
    
    this.refresh();
    this.dispatchDateiListChanged();
  }

  private dispatchDateiDelete(id: number) {
    if (!id) {
      return;
    }

    this._element.dispatchEvent(new CustomEvent(
      "on-datei-deleted", {
        bubbles: true,
        detail: {
          id: id
        }
      }
    ));
  }
  private dispatchDateiListChanged() {
    this._element.dispatchEvent(new CustomEvent(
      "on-datei-list-changed", {
        bubbles: true, 
        detail: {
          dateiList: this.dateiList
        }
      }
    ));
  }
}

export interface IDatei {
  Id?: number;
  DMSLink: string;
  DMSLinkDownload?: string;
  VideoUrl?: string;
  Dateiname: string;
  Gruppe?: string
  Exists?: boolean;
}