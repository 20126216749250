import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class PersonKontaktEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "person-kontakt-edit";
        this.title = "person-kontakt-edit.person-kontakt-edit_caption";
        this.addModel({
            "id": "$m_PersonKontakt",
            "webApiAction": "ZIT/Objekte/PersonKontaktElement",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_Kontakttyp",
            "webApiAction": "ZIT/Stammdaten/Kontakttyp",
            "key": "models.data.$m_PersonKontakt.IdKontakttyp",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "kontakttyp",
            "customs": [{
                "key": "objektTyp",
                "value": "'TIP.ZIT.Business.Entities.Objekte.Personen.Person'"
            }],
            "filters": [],
            "caption": "person-kontakt-edit.r_kontakttyp_caption",
            "binding": {
                "dataContext": "$m_PersonKontakt",
                "bindTo": "IdKontakttyp",
                "bindToFQ": "models.data.$m_PersonKontakt.IdKontakttyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_kontakttyp",
            "options": {
                "optionsName": "r_kontakttypOptions",
                "optionsNameFQ": "r_kontakttypOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "person-kontakt-edit.r_wichtig_caption",
            "binding": {
                "dataContext": "$m_PersonKontakt",
                "bindTo": "Wichtig",
                "bindToFQ": "models.data.$m_PersonKontakt.Wichtig"
            },
            "validationRules": [],
            "id": "r_wichtig",
            "options": {
                "optionsName": "r_wichtigOptions",
                "optionsNameFQ": "r_wichtigOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "landvorwahl",
            "filters": [],
            "caption": "person-kontakt-edit.r_landvorwahl_caption",
            "binding": {
                "dataContext": "$m_PersonKontakt",
                "bindTo": "Wert1",
                "bindToFQ": "models.data.$m_PersonKontakt.Wert1"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_landvorwahl",
            "options": {
                "optionsName": "r_landvorwahlOptions",
                "optionsNameFQ": "r_landvorwahlOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-kontakt-edit.r_wert2_caption",
            "binding": {
                "dataContext": "$m_PersonKontakt",
                "bindTo": "Wert2",
                "bindToFQ": "models.data.$m_PersonKontakt.Wert2"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_wert2",
            "options": {
                "optionsName": "r_wert2Options",
                "optionsNameFQ": "r_wert2Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-kontakt-edit.r_email_caption",
            "binding": {
                "dataContext": "$m_PersonKontakt",
                "bindTo": "Wert1",
                "bindToFQ": "models.data.$m_PersonKontakt.Wert1"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_email",
            "options": {
                "optionsName": "r_emailOptions",
                "optionsNameFQ": "r_emailOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-kontakt-edit.r_website_caption",
            "binding": {
                "dataContext": "$m_PersonKontakt",
                "bindTo": "Wert1",
                "bindToFQ": "models.data.$m_PersonKontakt.Wert1"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_website",
            "options": {
                "optionsName": "r_websiteOptions",
                "optionsNameFQ": "r_websiteOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 1000,
            "caption": "person-kontakt-edit.r_personkontaktbemerkung_caption",
            "binding": {
                "dataContext": "$m_PersonKontakt",
                "bindTo": "Bemerkung",
                "bindToFQ": "models.data.$m_PersonKontakt.Bemerkung"
            },
            "validationRules": [],
            "id": "r_personKontaktBemerkung",
            "options": {
                "optionsName": "r_personKontaktBemerkungOptions",
                "optionsNameFQ": "r_personKontaktBemerkungOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "person-kontakt-edit.r_persongueltigvon_caption",
            "binding": {
                "dataContext": "$m_PersonKontakt",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_PersonKontakt.GueltigVon"
            },
            "validationRules": [],
            "id": "r_personGueltigVon",
            "options": {
                "optionsName": "r_personGueltigVonOptions",
                "optionsNameFQ": "r_personGueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "person-kontakt-edit.r_persongueltigbis_caption",
            "binding": {
                "dataContext": "$m_PersonKontakt",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_PersonKontakt.GueltigBis"
            },
            "validationRules": [],
            "id": "r_personGueltigBis",
            "options": {
                "optionsName": "r_personGueltigBisOptions",
                "optionsNameFQ": "r_personGueltigBisOptions"
            }
        });
        super.onConstructionFinished();
    }
}