import { autoinject } from 'aurelia-framework';
import { RestService } from './../../framework/base/services/export';

@autoinject
export class GeschaeftspartnerService {
  constructor(
    private rest: RestService
  ) { }

  saveGruppierung(data: any) {
    return this.rest.post({
      url: this.rest.getWebApiUrl("ZIT/Stammdaten/GeschaeftspartnerGruppierung"),
      data: data,
      increaseLoadingCount: true
    });
  }
  saveEntlohnungsschema(data: any) {
    return this.rest.post({
      url: this.rest.getWebApiUrl("ZIT/Stammdaten/Entlohnungsschema"),
      data: data,
      increaseLoadingCount: true
    });
  }
  deleteEntlohnungsschema(id: number) {
    return this.rest.delete({
      url: this.rest.getWebApiUrl("ZIT/Stammdaten/Entlohnungsschema"),
      id: id,
      increaseLoadingCount: true
    });
  }
  deleteGruppierung(id: number) {
    return this.rest.delete({
      url: this.rest.getWebApiUrl("ZIT/Stammdaten/GeschaeftspartnerGruppierung"),
      id: id,
      increaseLoadingCount: true
    });
  }
}
