import * as fwx from "../../../framework/forms/form-export";
import {
    EntwicklungszieleListUtils
} from "./entwicklungsziele-list-utils";

@fwx.autoinject
export class EntwicklungszieleListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_EntwicklungszieleListUtils: EntwicklungszieleListUtils) {
        super(element, formBaseImport);
        this.id = "entwicklungszieleList";
        this.title = "entwicklungszieleList.entwicklungszielelist_caption";
        this.addCommand({
            "binding": {
                "bindTo": "$f_EntwicklungszieleListUtils.activateTableView",
                "bindToFQ": "functions.$f_EntwicklungszieleListUtils.activateTableView"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EntwicklungszieleListUtils.zielErstellen",
                "bindToFQ": "functions.$f_EntwicklungszieleListUtils.zielErstellen"
            }
        });
        this.addFunction("$f_EntwicklungszieleListUtils", $f_EntwicklungszieleListUtils, "functions.$f_EntwicklungszieleListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}