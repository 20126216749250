import { autoinject, bindable } from 'aurelia-framework';
import { IZitMarkersOptions } from './zit-markers-options';
import { CustomEditPopupService, MarkerService } from '../../../zit/services/export';
import { ScopeContainer } from '../../../framework/base/export';
import { BindingService } from '../../../framework/base/services/binding-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';

@autoinject
export class ZitMarkers {
  private _refreshMarkerSubscription: Subscription;

  constructor(
    private customEditPopup: CustomEditPopupService,
    private marker: MarkerService,
    private binding: BindingService,
    private eventAggregator: EventAggregator
  ) { }
  
  @bindable options: IZitMarkersOptions;
  @bindable data: any;
  
  args: any;
  scopeContainer: ScopeContainer;

  activate(args: any) {
    this.args = args;
  }
  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    if (this.args) {
      this.options = {
        markerList: []
      };

      this.binding.observe({
        scopeContainer: this.scopeContainer,
        expression: "args.idObjekt",
        callback: (newVal) => {
          this.loadMarkers();
        }
      });

      if (this.args.idObjekt) {
        this.loadMarkers();
      }
    }
    if (this.options && this.options.idObjekt !== undefined) {
      this.binding.observe({
        scopeContainer: this.scopeContainer,
        expression: "options.idObjekt", 
        callback: (newVal) => {
          this.loadMarkers();
        }
      });

      if (this.options.idObjekt) {
        this.loadMarkers();
      }
    }

    this._refreshMarkerSubscription = this.eventAggregator.subscribe("zit-markers:refresh", e => {
      if (e.idObjekt != this.getIdObjekt()) {
        return;
      }

      this.loadMarkers();
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this._refreshMarkerSubscription.dispose();
  }

  onClick(event: MouseEvent) {
    event.stopPropagation();

    if (this.options.onClick == void 0) {
      const id = this.getIdObjekt();

      this.customEditPopup.markersAuswaehlen.show(
        {
          mappings: {
            "$v_idObjekt": id
          }
        }
      );
      return false;
    }

    this.options.onClick(event, this.data);
  }

  private loadMarkers() {
    this.marker.getObjektZugeordnetMarkers(this.getIdObjekt() || -1)
      .then(r => {
        this.options.markerList = r;

        if (this.data) {
          if (this.data.ObjektMarkerZuordnungen) {
            this.data.ObjektMarkerZuordnungen = r;
          }
          if (this.data.Data.ObjektMarkerZuordnungen) {
            this.data.Data.ObjektMarkerZuordnungen = r;
          }
        }
      });
  }
  private getIdObjekt(): number {
    return this.args
      ? this.args.idObjekt
      : (this.options && this.options.idObjekt !== undefined
        ? this.options.idObjekt
        : (this.data
          ? this.data.Id
          : null));
  }
}
