import { autoinject, transient } from "aurelia-framework";
import { FormBase, HistoryService } from "../../../framework/forms/export";
import { AuthorizationService, LocationService } from "../../../framework/base/export";

@autoinject
@transient()
export class AppLoginFormFuncs {
  private _Form: FormBase;

  constructor(
    private _authorizationService: AuthorizationService,
    private _locationService: LocationService,
    private _historyService: HistoryService
  ) {}

  bind(form: FormBase) {
    this._Form = form;
    this.tryAutoLogin();
  }

  private async tryAutoLogin() {    
    const token = this.getTokenFromQueryString();
    if (!token) {
      this.redirectToDefault();
      return;
    }

    const redirectUrl = this.getRedirectUrlFromQueryString();
    this._historyService.pipelineUrl = redirectUrl || "Dashboard";

    const r = await this._authorizationService.login({
      Username: "$applogin$",
      Password: token
    }, false);

    if (!r.isLoggedIn) {
      this.redirectToDefault();
    }
  }

  private getTokenFromQueryString(): string {
    return this._Form.viewItemInfo?.routeInfo?.parameters?.token;
  }
  private getRedirectUrlFromQueryString(): string {
    const parameters = this._Form.viewItemInfo?.routeInfo?.parameters;
    if (!parameters) {
      return null;
    }

    return parameters["redirect-url"];
  }
  private async redirectToDefault() {
    if (this._authorizationService.isLoggedIn) {
      await this._locationService.goTo({
        url: "Dashboard",
        clearStack: true
      });
    } else {
      await this._locationService.goTo({
        url: "Login",
        clearStack: true
      });
    }
  }
}