import { autoinject, TaskQueue } from "aurelia-framework";
import { RestService, AuthorizationService } from "../../../framework/base/export";
import { BrowserService } from "../../../framework/base/services/browser-service";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";

@autoinject
export class Content {
  private _windowResizeSubscription: Subscription;
  private _routerViewItemCreatedSubscription: Subscription;
  private _routerViewItemRemovedSubscription: Subscription;

  constructor(
    private _element: Element,
    private _restService: RestService,
    private _browserService: BrowserService,
    private _authorizationService: AuthorizationService,
    private _eventAggregator: EventAggregator,
    private _taskQueue: TaskQueue
  ) {
    const randomKey = new Date()
      .getTime()
      .toString() 
      
      + Math
      .floor((Math.random() * 100))
      .toString();

    let url = `ZIT/Domaene/Hintergrundbild?key=${randomKey}`;

    const authKey = this._authorizationService.getAuthorizationKey();
    if (authKey) {
      url += `&auth-token=${encodeURIComponent(authKey)}`;
    }

    const hintergrundUrl = _restService.getApiUrl(url);
    this.contentStyle = {
      "background-image": `url(${hintergrundUrl})`
    };
  }

  contentStyle: any;

  bind() {
    if (this._browserService.isIE) {
      this._windowResizeSubscription = this._eventAggregator.subscribe("window:resize", () => {
        this.refreshHeight();
      });
    }
    if (this._browserService.isIE || this._browserService.isEdge) {
      this._routerViewItemCreatedSubscription = this._eventAggregator.subscribe("router:view-item-created", () => {
        this.publishWindowResize();
      });
      this._routerViewItemRemovedSubscription = this._eventAggregator.subscribe("router:view-item-removed", () => {
        this.publishWindowResize();
      });
    }    
  }
  unbind() {
    if (this._windowResizeSubscription) {
      this._windowResizeSubscription.dispose();
    }
    if (this._routerViewItemCreatedSubscription) {
      this._routerViewItemCreatedSubscription.dispose();
    }
    if (this._routerViewItemRemovedSubscription) {
      this._routerViewItemRemovedSubscription.dispose();
    }
  }

  attached() {
    if (this._browserService.isIE) {
      this._taskQueue.queueTask(() => {
        this.refreshHeight();
      });
    }
  }

  private publishWindowResize() {
    this._taskQueue.queueTask(() => {
      this._eventAggregator.publish("window:resize", {});
    });
  }
  private refreshHeight() {
    const htmlElement: HTMLElement = <any>this._element;

    const height = window.innerHeight;
    const top = htmlElement.offsetTop;

    htmlElement.style.height = (height - top) + "px";
  }
}
