import * as fwx from "../../../../framework/forms/form-export";
import {
    GeschaeftspartnerEditUtils
} from "./geschaeftspartner-edit-utils";

@fwx.autoinject
export class GeschaeftspartnerHeartbaseEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_GeschaeftspartnerEditUtils: GeschaeftspartnerEditUtils) {
        super(element, formBaseImport);
        this.id = "geschaeftspartner-heartbase-edit";
        this.title = "geschaeftspartner-heartbase-edit.geschaeftspartner-heartbase-edit_caption";
        this.addModel({
            "id": "$m_Geschaeftspartner",
            "webApiAction": "ZIT/Objekte/Geschaeftspartner",
            "webApiExpand": {
                'Geschaeftspartnerdaten': {
                    'expand': {
                        'Geschaeftspartnertypen': null,
                        'DsDatenschutz': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "addCustomSubHeadline",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "SonstigeKontaktdatenVisible",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerdatenElement",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerGeschaeftspartnerdatenElement",
            "key": "models.data.$m_Geschaeftspartner.IdGeschaeftspartnerGeschaeftspartnerdatenElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Geschaeftspartnertyp",
            "webApiAction": "ZIT/Stammdaten/Geschaeftspartnertyp",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_AdresseElement",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerAdresseElement",
            "key": "models.data.$m_Geschaeftspartner.IdGeschaeftspartnerAdresseElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerAdresseRel",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerAdresse",
            "webApiExpand": {
                'Adresstyp': null,
                'Land': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerAdresseEdit",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerAdresse",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_KontaktElement",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerKontaktElement",
            "key": "models.data.$m_Geschaeftspartner.IdGeschaeftspartnerKontaktElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerKontaktRel",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerKontakt",
            "webApiExpand": {
                Kontakttyp: null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerKontaktEdit",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerKontakt",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BankverbindungElement",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerBankverbindungElement",
            "key": "models.data.$m_Geschaeftspartner.IdGeschaeftspartnerBankverbindungElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerBankverbindungRel",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerBankverbindung",
            "webApiExpand": {
                Waehrung: null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerBankverbindungEdit",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerBankverbindung",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PackageElement",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerPackageElement",
            "key": "models.data.$m_Geschaeftspartner.IdGeschaeftspartnerPackageElement",
            "keyProperty": "Id",
            "allowNew": "functions.$f_GeschaeftspartnerEditUtils.canEditPackage",
            "allowSave": "functions.$f_GeschaeftspartnerEditUtils.canEditPackage",
            "allowDelete": "functions.$f_GeschaeftspartnerEditUtils.canEditPackage",
            "filters": []
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerPackageRel",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerPackage",
            "webApiColumns": ["Id", "IdPackage", "Bezeichnung", "GueltigBis", "GueltigVon"],
            "webApiOrderBy": [{
                "columnName": "GueltigBis",
                "sortOrder": 1
            }, {
                "columnName": "Bezeichnung",
                "sortOrder": 0
            }],
            "keyProperty": "Id",
            "allowNew": "functions.$f_GeschaeftspartnerEditUtils.canEditPackage",
            "allowSave": "functions.$f_GeschaeftspartnerEditUtils.canEditPackage",
            "allowDelete": "functions.$f_GeschaeftspartnerEditUtils.canEditPackage",
            "filters": []
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerPackageEdit",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerPackage",
            "keyProperty": "Id",
            "allowNew": "functions.$f_GeschaeftspartnerEditUtils.canEditPackage",
            "allowSave": "functions.$f_GeschaeftspartnerEditUtils.canEditPackage",
            "allowDelete": "functions.$f_GeschaeftspartnerEditUtils.canEditPackage",
            "filters": []
        });
        this.addModel({
            "id": "$m_AnsprechpersonZuGeschaeftspartnerEdit",
            "webApiAction": "ZIT/Objekte/AnsprechpersonZuGeschaeftspartner",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_AnsprechpersonZuGeschaeftspartnerRel",
            "webApiAction": "ZIT/Objekte/AnsprechpersonZuGeschaeftspartner",
            "webApiExpand": {},
            "webApiWhere": [
                ["Geschaeftspartner.Id", {
                    "isBound": true,
                    "expression": "models.data.$m_Geschaeftspartner.Id"
                }], "and", ["Ansprechperson.IsInaktiv", false], "and", ["Ansprechperson.Personendaten.ImGeschaeftspartnerAusblenden", false]
            ],
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "onlyGueltig",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerProfilFirma",
            "webApiAction": "ZIT/Objekte/ProfilFirma",
            "webApiWhere": ["Profil.IdGeschaeftspartner", {
                "isBound": true,
                "expression": "models.data.$m_Geschaeftspartner.Id"
            }],
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "AddProfilFirmaCard",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerZuDokumentEdit",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerdatenElementZuDokumentHintergrundAttachment",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerZuDokumentRel",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerdatenElementZuDokumentHintergrundAttachment",
            "webApiWhere": [
                ["IdGeschaeftspartnerGeschaeftspartnerdatenElement", {
                    "isBound": true,
                    "expression": "models.data.$m_Geschaeftspartner.IdGeschaeftspartnerGeschaeftspartnerdatenElement"
                }]
            ],
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_GeschaeftspartnerEditUtils.actionCommand",
                "bindToFQ": "functions.$f_GeschaeftspartnerEditUtils.actionCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_GeschaeftspartnerEditUtils.geschaeftspartnerErweiterteOptionenCommand",
                "bindToFQ": "functions.$f_GeschaeftspartnerEditUtils.geschaeftspartnerErweiterteOptionenCommand"
            }
        });
        this.addEditPopup({
            "idContent": "geschaeftspartnerAdresseEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_GeschaeftspartnerAdresseEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_GeschaeftspartnerAdresseEdit.Id"
                }
            }],
            "id": "geschaeftspartnerAdresseEditPopup",
            "options": {
                "optionsName": "geschaeftspartnerAdresseEditPopupOptions",
                "optionsNameFQ": "geschaeftspartnerAdresseEditPopupOptions"
            },
            "height": "auto",
            "width": "540px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "geschaeftspartnerDokumentEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_GeschaeftspartnerZuDokumentEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_GeschaeftspartnerZuDokumentEdit.Id"
                }
            }],
            "id": "geschaeftspartnerDokumentEditPopup",
            "options": {
                "optionsName": "geschaeftspartnerDokumentEditPopupOptions",
                "optionsNameFQ": "geschaeftspartnerDokumentEditPopupOptions"
            },
            "height": "auto",
            "width": "540px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "geschaeftspartnerBankverbindungEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_GeschaeftspartnerBankverbindungEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_GeschaeftspartnerBankverbindungEdit.Id"
                }
            }],
            "id": "geschaeftspartnerBankverbindungEditPopup",
            "options": {
                "optionsName": "geschaeftspartnerBankverbindungEditPopupOptions",
                "optionsNameFQ": "geschaeftspartnerBankverbindungEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "geschaeftspartnerKontaktdatenEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_GeschaeftspartnerKontaktEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_GeschaeftspartnerKontaktEdit.Id"
                }
            }],
            "id": "geschaeftspartnerKontaktdatenEditPopup",
            "options": {
                "optionsName": "geschaeftspartnerKontaktdatenEditPopupOptions",
                "optionsNameFQ": "geschaeftspartnerKontaktdatenEditPopupOptions"
            },
            "height": "300px",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "geschaeftspartnerPackageEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_GeschaeftspartnerPackageEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_GeschaeftspartnerPackageEdit.Id"
                }
            }],
            "id": "geschaeftspartnerPackageEditPopup",
            "options": {
                "optionsName": "geschaeftspartnerPackageEditPopupOptions",
                "optionsNameFQ": "geschaeftspartnerPackageEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addFunction("$f_GeschaeftspartnerEditUtils", $f_GeschaeftspartnerEditUtils, "functions.$f_GeschaeftspartnerEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 200,
            "caption": "geschaeftspartner-heartbase-edit.r_name1_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.Name1",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.Name1",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_name1",
            "options": {
                "optionsName": "r_name1Options",
                "optionsNameFQ": "r_name1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 200,
            "caption": "geschaeftspartner-heartbase-edit.r_name2_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.Name2",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.Name2",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_name2",
            "options": {
                "optionsName": "r_name2Options",
                "optionsNameFQ": "r_name2Options"
            }
        });
        this.widgetCreator.addTagBox(this, {
            "caption": "geschaeftspartner-heartbase-edit.r_geschaeftspartnertyp_caption",
            "dataContext": "$m_Geschaeftspartner",
            "relationBinding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.Geschaeftspartnertypen",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.Geschaeftspartnertypen",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "relationProperty": "IdGeschaeftspartnertyp",
            "itemDataContext": "$m_Geschaeftspartnertyp",
            "itemValueExpr": "Id",
            "itemDisplayExpr": "Bezeichnung",
            "binding": {
                "dataContext": "$m_Geschaeftspartner"
            },
            "validationRules": [],
            "id": "r_geschaeftspartnertyp",
            "options": {
                "optionsName": "r_geschaeftspartnertypOptions",
                "optionsNameFQ": "r_geschaeftspartnertypOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 20,
            "caption": "geschaeftspartner-heartbase-edit.r_uidnummer_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.UidNummer",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.UidNummer",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_uidNummer",
            "options": {
                "optionsName": "r_uidNummerOptions",
                "optionsNameFQ": "r_uidNummerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "geschaeftspartner-heartbase-edit.r_email_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.Email",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.Email",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_email",
            "options": {
                "optionsName": "r_emailOptions",
                "optionsNameFQ": "r_emailOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "landvorwahl",
            "filters": [],
            "caption": "geschaeftspartner-heartbase-edit.r_telefonlandvorwahl_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.TelefonLandvorwahl",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.TelefonLandvorwahl",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten && models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.Telefon"
                    }]
                }
            }],
            "id": "r_telefonLandvorwahl",
            "options": {
                "optionsName": "r_telefonLandvorwahlOptions",
                "optionsNameFQ": "r_telefonLandvorwahlOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "geschaeftspartner-heartbase-edit.r_telefon_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.Telefon",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.Telefon",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [{
                "item": {
                    "type": "telefon",
                    "parameters": []
                }
            }],
            "id": "r_telefon",
            "options": {
                "optionsName": "r_telefonOptions",
                "optionsNameFQ": "r_telefonOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 500,
            "caption": "geschaeftspartner-heartbase-edit.r_url_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.Url",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.Url",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_url",
            "options": {
                "optionsName": "r_urlOptions",
                "optionsNameFQ": "r_urlOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "tipNetReporttype",
            "filter": ["Entitaet", "TIP.ZIT.Business.Helper.TerminEmpfaengerEinschaetzungPrintHelper"],
            "filters": [],
            "caption": "geschaeftspartner-heartbase-edit.r_tipnetreporttypeeinschaetzung_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.TIPNetReporttypeEinschaetzung",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.TIPNetReporttypeEinschaetzung",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_tipnetReporttypeEinschaetzung",
            "options": {
                "optionsName": "r_tipnetReporttypeEinschaetzungOptions",
                "optionsNameFQ": "r_tipnetReporttypeEinschaetzungOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "geschaeftspartner-heartbase-edit.r_geschaeftspartnerschule_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.IsGeschaeftspartnerSchule",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.IsGeschaeftspartnerSchule",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_geschaeftspartnerSchule",
            "options": {
                "optionsName": "r_geschaeftspartnerSchuleOptions",
                "optionsNameFQ": "r_geschaeftspartnerSchuleOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "geschaeftspartner-heartbase-edit.r_schnupperterminmaske_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.HideInSchnupperterminMaske",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.HideInSchnupperterminMaske",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_schnupperterminMaske",
            "options": {
                "optionsName": "r_schnupperterminMaskeOptions",
                "optionsNameFQ": "r_schnupperterminMaskeOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 0,
            "acceptType": "image/*",
            "height": "250px",
            "showToolbar": true,
            "showClearButton": false,
            "caption": "geschaeftspartner-heartbase-edit.r_dmslinklogo_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.DMSLinkLogo",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.DMSLinkLogo",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_dmsLinkLogo",
            "options": {
                "optionsName": "r_dmsLinkLogoOptions",
                "optionsNameFQ": "r_dmsLinkLogoOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_geschaeftspartnerAdresseListViewToolbarOptions",
                "optionsNameFQ": "r_geschaeftspartnerAdresseListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_AdresseElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_GeschaeftspartnerAdresseRel",
                "bindTo": "IdGeschaeftspartnerAdresseElement",
                "bindToFQ": "models.data.$m_GeschaeftspartnerAdresseRel.IdGeschaeftspartnerAdresseElement"
            },
            "dataModel": "$m_AdresseElement",
            "editDataContext": "$m_GeschaeftspartnerAdresseEdit",
            "idEditPopup": "geschaeftspartnerAdresseEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_geschaeftspartnerAdresseListView",
            "options": {
                "optionsName": "r_geschaeftspartnerAdresseListViewOptions",
                "optionsNameFQ": "r_geschaeftspartnerAdresseListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_geschaeftspartnerDokumentListViewToolbarOptions",
                "optionsNameFQ": "r_geschaeftspartnerDokumentListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_GeschaeftspartnerdatenElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_GeschaeftspartnerZuDokumentRel",
                "bindTo": "IdGeschaeftspartnerGeschaeftspartnerdatenElement",
                "bindToFQ": "models.data.$m_GeschaeftspartnerZuDokumentRel.IdGeschaeftspartnerGeschaeftspartnerdatenElement"
            },
            "dataModel": "$m_GeschaeftspartnerdatenElement",
            "editDataContext": "$m_GeschaeftspartnerZuDokumentEdit",
            "idEditPopup": "geschaeftspartnerDokumentEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_geschaeftspartnerDokumentListView",
            "options": {
                "optionsName": "r_geschaeftspartnerDokumentListViewOptions",
                "optionsNameFQ": "r_geschaeftspartnerDokumentListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_geschaeftspartnerBankverbindungListViewToolbarOptions",
                "optionsNameFQ": "r_geschaeftspartnerBankverbindungListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_BankverbindungElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_GeschaeftspartnerBankverbindungRel",
                "bindTo": "IdGeschaeftspartnerBankverbindungElement",
                "bindToFQ": "models.data.$m_GeschaeftspartnerBankverbindungRel.IdGeschaeftspartnerBankverbindungElement"
            },
            "dataModel": "$m_BankverbindungElement",
            "editDataContext": "$m_GeschaeftspartnerBankverbindungEdit",
            "idEditPopup": "geschaeftspartnerBankverbindungEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_geschaeftspartnerBankverbindungListView",
            "options": {
                "optionsName": "r_geschaeftspartnerBankverbindungListViewOptions",
                "optionsNameFQ": "r_geschaeftspartnerBankverbindungListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_geschaeftspartnerKontaktdatenListViewToolbarOptions",
                "optionsNameFQ": "r_geschaeftspartnerKontaktdatenListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_KontaktElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_GeschaeftspartnerKontaktRel",
                "bindTo": "IdGeschaeftspartnerKontaktElement",
                "bindToFQ": "models.data.$m_GeschaeftspartnerKontaktRel.IdGeschaeftspartnerKontaktElement"
            },
            "dataModel": "$m_KontaktElement",
            "editDataContext": "$m_GeschaeftspartnerKontaktEdit",
            "idEditPopup": "geschaeftspartnerKontaktdatenEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_geschaeftspartnerKontaktdatenListView",
            "options": {
                "optionsName": "r_geschaeftspartnerKontaktdatenListViewOptions",
                "optionsNameFQ": "r_geschaeftspartnerKontaktdatenListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_geschaeftspartnerAnsprechpersonListViewToolbarOptions",
                "optionsNameFQ": "r_geschaeftspartnerAnsprechpersonListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Geschaeftspartner"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_AnsprechpersonZuGeschaeftspartnerRel",
                "bindTo": "IdGeschaeftspartner",
                "bindToFQ": "models.data.$m_AnsprechpersonZuGeschaeftspartnerRel.IdGeschaeftspartner"
            },
            "dataModel": "$m_Geschaeftspartner",
            "editDataContext": "$m_AnsprechpersonZuGeschaeftspartnerEdit",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_geschaeftspartnerAnsprechpersonListView",
            "options": {
                "optionsName": "r_geschaeftspartnerAnsprechpersonListViewOptions",
                "optionsNameFQ": "r_geschaeftspartnerAnsprechpersonListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_geschaeftspartnerPackageListViewToolbarOptions",
                "optionsNameFQ": "r_geschaeftspartnerPackageListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_PackageElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_GeschaeftspartnerPackageRel",
                "bindTo": "IdGeschaeftspartnerPackageElement",
                "bindToFQ": "models.data.$m_GeschaeftspartnerPackageRel.IdGeschaeftspartnerPackageElement"
            },
            "dataModel": "$m_PackageElement",
            "editDataContext": "$m_GeschaeftspartnerPackageEdit",
            "idEditPopup": "geschaeftspartnerPackageEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_geschaeftspartnerPackageListView",
            "options": {
                "optionsName": "r_geschaeftspartnerPackageListViewOptions",
                "optionsNameFQ": "r_geschaeftspartnerPackageListViewOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "geschaeftspartner-heartbase-edit.r_keyunternehmenparameter_caption",
            "isReadOnlyExpression": "!functions.$f_GeschaeftspartnerEditUtils.isAdmin",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.KeyUnternehmenParameter",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.KeyUnternehmenParameter",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_keyUnternehmenParameter",
            "options": {
                "optionsName": "r_keyUnternehmenParameterOptions",
                "optionsNameFQ": "r_keyUnternehmenParameterOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "geschaeftspartner-heartbase-edit.r_messagingnichtanbieten_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.MessagingDienstNichtAnbieten",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.MessagingDienstNichtAnbieten",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_messagingNichtAnbieten",
            "options": {
                "optionsName": "r_messagingNichtAnbietenOptions",
                "optionsNameFQ": "r_messagingNichtAnbietenOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "geschaeftspartner-heartbase-edit.r_dsdatenschutzurl_caption",
            "isReadOnlyExpression": "!functions.$f_GeschaeftspartnerEditUtils.isAdmin",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.DsDatenschutz.Url",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.DsDatenschutz.Url",
                "propertyPrefix": "Geschaeftspartnerdaten.DsDatenschutz"
            },
            "validationRules": [],
            "id": "r_dsDatenschutzUrl",
            "options": {
                "optionsName": "r_dsDatenschutzUrlOptions",
                "optionsNameFQ": "r_dsDatenschutzUrlOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "g",
            "caption": "geschaeftspartner-heartbase-edit.r_dsdatenschutzversion_caption",
            "isReadOnlyExpression": "!functions.$f_GeschaeftspartnerEditUtils.isAdmin",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.DsDatenschutz.VersionDatum",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.DsDatenschutz.VersionDatum",
                "propertyPrefix": "Geschaeftspartnerdaten.DsDatenschutz"
            },
            "validationRules": [],
            "id": "r_dsDatenschutzVersion",
            "options": {
                "optionsName": "r_dsDatenschutzVersionOptions",
                "optionsNameFQ": "r_dsDatenschutzVersionOptions"
            }
        });
        this.widgetCreator.addColorBox(this, {
            "editAlphaChannel": false,
            "caption": "geschaeftspartner-heartbase-edit.r_iframeformularhintergrundfarbe_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.IFrameFormularHintergrundFarbe",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.IFrameFormularHintergrundFarbe",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_iFrameFormularHintergrundFarbe",
            "options": {
                "optionsName": "r_iFrameFormularHintergrundFarbeOptions",
                "optionsNameFQ": "r_iFrameFormularHintergrundFarbeOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "geschaeftspartner-heartbase-edit.r_iframelabelschriftart_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.IFrameLabelSchriftart",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.IFrameLabelSchriftart",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_iFrameLabelSchriftart",
            "options": {
                "optionsName": "r_iFrameLabelSchriftartOptions",
                "optionsNameFQ": "r_iFrameLabelSchriftartOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showClearButton": true,
            "caption": "geschaeftspartner-heartbase-edit.r_iframelabelschriftgroesse_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.IFrameLabelSchriftgroesse",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.IFrameLabelSchriftgroesse",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_iFrameLabelSchriftgroesse",
            "options": {
                "optionsName": "r_iFrameLabelSchriftgroesseOptions",
                "optionsNameFQ": "r_iFrameLabelSchriftgroesseOptions"
            }
        });
        this.widgetCreator.addColorBox(this, {
            "editAlphaChannel": false,
            "caption": "geschaeftspartner-heartbase-edit.r_iframelabelschriftfarbe_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.IFrameLabelSchriftfarbe",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.IFrameLabelSchriftfarbe",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_iFrameLabelSchriftfarbe",
            "options": {
                "optionsName": "r_iFrameLabelSchriftfarbeOptions",
                "optionsNameFQ": "r_iFrameLabelSchriftfarbeOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "geschaeftspartner-heartbase-edit.r_iframecontentschriftart_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.IFrameContentSchriftart",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.IFrameContentSchriftart",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_iFrameContentSchriftart",
            "options": {
                "optionsName": "r_iFrameContentSchriftartOptions",
                "optionsNameFQ": "r_iFrameContentSchriftartOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showClearButton": true,
            "caption": "geschaeftspartner-heartbase-edit.r_iframecontentschriftgroesse_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.IFrameContentSchriftgroesse",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.IFrameContentSchriftgroesse",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_iFrameContentSchriftgroesse",
            "options": {
                "optionsName": "r_iFrameContentSchriftgroesseOptions",
                "optionsNameFQ": "r_iFrameContentSchriftgroesseOptions"
            }
        });
        this.widgetCreator.addColorBox(this, {
            "editAlphaChannel": false,
            "caption": "geschaeftspartner-heartbase-edit.r_iframecontentschriftfarbe_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.IFrameContentSchriftfarbe",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.IFrameContentSchriftfarbe",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_iFrameContentSchriftfarbe",
            "options": {
                "optionsName": "r_iFrameContentSchriftfarbeOptions",
                "optionsNameFQ": "r_iFrameContentSchriftfarbeOptions"
            }
        });
        this.widgetCreator.addColorBox(this, {
            "editAlphaChannel": false,
            "caption": "geschaeftspartner-heartbase-edit.r_iframebuttonfarbe_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.IFrameButtonFarbe",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.IFrameButtonFarbe",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_iFrameButtonFarbe",
            "options": {
                "optionsName": "r_iFrameButtonFarbeOptions",
                "optionsNameFQ": "r_iFrameButtonFarbeOptions"
            }
        });
        this.widgetCreator.addColorBox(this, {
            "editAlphaChannel": false,
            "caption": "geschaeftspartner-heartbase-edit.r_iframefeldrahmenliniefarbe_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.IFrameFeldRahmenlinieFarbe",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.IFrameFeldRahmenlinieFarbe",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_iFrameFeldRahmenlinieFarbe",
            "options": {
                "optionsName": "r_iFrameFeldRahmenlinieFarbeOptions",
                "optionsNameFQ": "r_iFrameFeldRahmenlinieFarbeOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "geschaeftspartner-heartbase-edit.r_keyterminrestservice_caption",
            "isReadOnlyExpression": "!functions.$f_GeschaeftspartnerEditUtils.isAdmin",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.KeyTerminRestService",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.KeyTerminRestService",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_keyTerminRestService",
            "options": {
                "optionsName": "r_keyTerminRestServiceOptions",
                "optionsNameFQ": "r_keyTerminRestServiceOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "geschaeftspartner-heartbase-edit.r_keystelleninseratrestservice_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Geschaeftspartnerdaten.KeyStelleninseratRestService",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Geschaeftspartnerdaten.KeyStelleninseratRestService",
                "propertyPrefix": "Geschaeftspartnerdaten"
            },
            "validationRules": [],
            "id": "r_keyStelleninseratRestService",
            "options": {
                "optionsName": "r_keyStelleninseratRestServiceOptions",
                "optionsNameFQ": "r_keyStelleninseratRestServiceOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "col-xs-12 col-sm-6",
            "useDefaultListItemStyle": false,
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_geschaeftspartnerProfilFirmaListViewToolbarOptions",
                "optionsNameFQ": "r_geschaeftspartnerProfilFirmaListViewToolbarOptions"
            },
            "caption": "geschaeftspartner-heartbase-edit.r_geschaeftspartnerprofilfirmalistview_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerProfilFirma"
            },
            "dataModel": "$m_GeschaeftspartnerProfilFirma",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_geschaeftspartnerProfilFirmaListView",
            "options": {
                "optionsName": "r_geschaeftspartnerProfilFirmaListViewOptions",
                "optionsNameFQ": "r_geschaeftspartnerProfilFirmaListViewOptions"
            }
        });
        super.onConstructionFinished();
    }
}