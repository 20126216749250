import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { ModelUtilsService } from '../../../../framework/forms/export';
import { FormPopupService } from '../../../services/form-popup-service';
import { StartupService } from '../../../services/export';

@autoinject
export class Massnahmen {
  constructor(
    private _modelUtilsService: ModelUtilsService,
    private _formPopupService: FormPopupService,
    private _startupService: StartupService
  ) {
    this.selectedColor = this._startupService.startupInfo.Skin.Farbe20Opacity;
  }

  @bindable mainModel: any;
  massnahmeVisible: any = null;
  selectedColor: string;

  bezeichnungOptions: DevExpress.ui.dxTextBoxOptions = {
    placeholder: "Bezeichnung",
    bindingOptions: {
      value: "massnahme.Bezeichnung"
    }
  };
  isErfuelltOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "erfüllt?",
    bindingOptions: {
      value: "massnahme.IsErfuellt"
    }
  };
  kommentarOptions: DevExpress.ui.dxTextAreaOptions = {
    height: "75px",
    bindingOptions: {
      value: "massnahme.Kommentar"
    }
  };
  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };

  addMassnahme() {
    if (!this.mainModel.Kopf.ZielMassnahmen) {
      this.mainModel.Kopf.ZielMassnahmen = [];
    }

    this._formPopupService.formPopup.show({
      titel: "Neue Maßnahme",
      data: {},
      formDef: <DevExpress.ui.dxFormSimpleItem[]>[{
        dataField: "bezeichnung",
        label: {
          text: "Bezeichnung",
          location: "top"
        },
        isRequired: true,
        editorType: "dxTextBox",
        editorOptions: <DevExpress.ui.dxTextBoxOptions>{
          maxLength: 250
        }
      }],
      onFormSubmit: (d) => {
        this.mainModel.Kopf.ZielMassnahmen.push({
          Bezeichnung: d.bezeichnung,
          IsErfuellt: false
        });
        this._modelUtilsService.setDirty(this.mainModel);
      }
    })
  }

  onHeaderClick(ev: Event, massnahme: any) {
    if (this.massnahmeVisible == massnahme) {
      this.massnahmeVisible = null;
    } else {
      this.massnahmeVisible = massnahme;
    }
  }
  onDateiListChanged() {
    this._modelUtilsService.setDirty(this.mainModel);
  }
  onErfuelltClick(ev: Event, massnahme: any) {
    ev.stopPropagation();
    ev.preventDefault();

    massnahme.IsErfuellt = !massnahme.IsErfuellt;
    this._modelUtilsService.setDirty(this.mainModel);
  }
  onDeleteClick(ev: Event, massnahme: any) {
    ev.stopPropagation();
    ev.preventDefault();

    const massnahmen: any[] = this.mainModel.Kopf.ZielMassnahmen;
    const index = massnahmen.indexOf(massnahme);
    if (index < 0) {
      return;
    }

    massnahmen.splice(index, 1);
    this._modelUtilsService.setDirty(this.mainModel);
  }
}