import { autoinject } from "aurelia-framework";

@autoinject
export class DashboardBoxHtml {
  item: any;

  activate(item) {
    this.item = item;
  }
}
