import { autoinject, transient, TaskQueue, validateBehaviorName } from 'aurelia-framework';
import { IZitTagOptions } from '../../../../zit-ui/elements/zit-tag/export';
import { FileService, BrowserService } from '../../../../framework/base/export';
import { FormBase } from '../../../../framework/forms/form-export';
import { DokumentService, CustomEditPopupService } from '../../../services/export';
import { IdxTextBoxComponent, IdxPopupComponent } from '../../../interfaces/export';
import { ICommandData } from '../../../../framework/forms/export';
@autoinject
@transient()
export class DokumentUnterfertigungUtils {
  constructor(
    private _customEditPopupService: CustomEditPopupService,
    private _dokumentService: DokumentService,
    private _fileService: FileService,
    private _taskQueue: TaskQueue,
    private _browserService: BrowserService
  ) { }

  form: FormBase;

  showDokumentCode: boolean = true;
  showTAN: boolean = false
  isDokumentCodeValid: boolean;
  isTANSend: boolean;

  validierungData = { IdDokumentElement: null, DokumentCode: null };
  unterfertigungData = { IdDokumentElement: null, TAN: null };

  codeKorrektTagOptions: IZitTagOptions = {
    cssClass: "z--tag-info-message z--tag-info-ok-message",
    icon: {
      faIcon: "fas fa-check-circle",
    },
    textExpression: `"dokument-utils.code-ok-info" | tr`
  };
  codeStartTagOptions: IZitTagOptions = {
    cssClass: "z--tag-info-message",
    icon: {
      faIcon: "fas fa-info-circle",
    },
    textExpression: `"dokument-utils.code-start-info" | tr`
  };
  codeFalschTagOptions: IZitTagOptions = {
    cssClass: "z--tag-info-message z--tag-info-falsch-message",
    icon: {
      faIcon: "fas fa-times-circle",
    },
    textExpression: `"dokument-utils.code-falsch-info" | tr`
  };

  anfordernTANButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "TAN anfordern",
    icon: "fas fa-pencil-alt",
    elementAttr: {
      class: "z--button z--button-round"
    },
    onClick: () => {
      if (!this.isDokumentCodeValid) {
        return;
      }
      this.sendTan();
    },
    bindingOptions: {
      disabled: "!functions.$f_DokumentUtils.isDokumentCodeValid"
    }
  };
  dokumentenCodePruefenButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Dokumenten-Code prüfen",
    icon: "fas fa-redo-alt",
    elementAttr: {
      class: "z--button z--button-round"
    },
    onClick: () => {
      this.validateDokumentCode();
    },
    bindingOptions: {
      disabled: "!functions.$f_DokumentUtils.validierungData.DokumentCode"
    }
  };
  unterschreibenDokumentButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Dokument unterschreiben",
    icon: "far fa-check-circle",
    elementAttr: {
      class: "z--button z--button-round"
    },
    onClick: () => {
      this.unterschreibenDokument()
    },
    bindingOptions: {
      disabled: "!functions.$f_DokumentUtils.isDokumentCodeValid"
    }
  };

  dataSavedClosePopupCommand: ICommandData = {
    id: "dataSavedClosePopupCommand",
    icon: "fas fa-check",
    tooltip: "base.save",
    sortIndex: 1010,
    execute: () => {
      const popup: IdxPopupComponent = this.form["dataSavedPopup"];
      popup.instance.hide();
    }
  }

  unterfertigungDokumentTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    hasDefaultInfo: true,
    icon: {
      faIconExpression: `item.Zustand.TagSymbol`,
      textExpression: `item.Zustand.TagSymbol 
      ? ""
      : "D"`
    },
    textExpression: `item.VorlageBezeichnung || item.Dateiname | zitRestrictLength:50`,
    backgroundColorExpression: "item.Informationstyp.FarbeTagText",
    infoList: [
      {
        icon: {
          faIcon: "fas fa-paperclip",
        },
        onClick: (e, data) => {
          e.stopPropagation();
          this._fileService.download(data.DMSLinkDokument);
        }
      }
    ],
    onClick: (e, data) => {
      e.stopPropagation();
      this._fileService.inline(data.DMSLinkDokument);
    }
  };

  bind(form: FormBase) {
    this.form = form;
    form.models.onLoaded.register((args) => {
      if (args.model.id == "$m_Dokument" && args.data) {
        this.isTANSend = false;
        this.showDokumentCode = true;
        this.showTAN = false;
        this.validierungData.DokumentCode = null;
        this.unterfertigungData.TAN = null;
        
        this.setInitialData(args.data);

        this._taskQueue.queueTask(() => {
          this.repaintPopup();
        });
      }
      return Promise.resolve();
    });
  }

  private onDokumentUnterschrieben() {
    const popup: IdxPopupComponent = this.form["dataSavedPopup"];
    
    this._customEditPopupService.dokumentUnterfertigung.hide();
    popup.instance.show();
  }

  private async sendTan() {
    const codeTextBox: IdxTextBoxComponent = this.form["r_dokumentUnterfertigungDokumentenCode"];
    this.validierungData.DokumentCode = codeTextBox.instance.option("value");

    await this._dokumentService.sendTan(this.validierungData);

    this.isTANSend = true;
    codeTextBox.setOption({ "readOnly": true });

    this._taskQueue.queueTask(() => {
      this.repaintPopup();
    });
  }
  private async validateDokumentCode() {
    const validation = await this._dokumentService.validateCode(this.validierungData);

    this.isDokumentCodeValid = validation.IsValid;

    if (validation.IsValid) {
      this.showDokumentCode = !this._browserService.isXS;
      this.showTAN = true;
    }

    this._taskQueue.queueTask(() => {
      this.repaintPopup();
    });
  }
  private async unterschreibenDokument() {
    await this._dokumentService.unterfertigenDokument(this.unterfertigungData);

    this.onDokumentUnterschrieben();
  }

  private setInitialData(data) {
    this.isDokumentCodeValid = data.NeedsDokumentCode ? null : true;

    this.validierungData.IdDokumentElement = data.Id;
    this.unterfertigungData.IdDokumentElement = data.Id;
  }
  private repaintPopup() {
    const popup: DevExpress.ui.dxPopup = this._customEditPopupService.dokumentUnterfertigung.popup.instance;
    if (!popup) {
      return;
    }

    popup.repaint();
  }
}
