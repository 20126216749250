import { autoinject } from 'aurelia-framework';
import { RestService } from './../../framework/base/services/export';

@autoinject
export class AdresseService {
  constructor(
    private rest: RestService
  ) { }

  getOrtFromPLZ(idPlz: number, idLand: number): Promise<{ Ort: string }> {
    return this.rest.post({
      url: this.rest.getApiUrl("ZIT/Adresse/Ort"),
      data: {
        Postleitzahl: idPlz,
        IdLand: idLand
      }
    });
  }
}