import { autoinject } from "aurelia-framework";
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { TooltipService, ITooltipWidget } from '../../services/tooltip-service';

@autoinject
export class TooltipConfig {
  private _tooltipWidgetChangedSubscription: Subscription;

  constructor(
    private eventAggregator: EventAggregator,
    private tooltipService: TooltipService
  ) {}

  currentWidget: ITooltipWidget;
  currentTooltip: string;

  textBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    bindingOptions: {
      value: "currentTooltip",
      hint: "currentWidget.key",
      readOnly: "!currentWidget"
    }
  }
  saveOptions: DevExpress.ui.dxButtonOptions = {
    elementAttr: {
      class: "z--button z--button-round"
    },
    icon: "fas fa-save",
    onClick: () => {
      if (!this.currentWidget) {
        return;
      }

      this.tooltipService.updateTooltip(this.currentWidget, this.currentTooltip);
    },
    bindingOptions: {
      disabled: "!currentWidget"
    }
  }
  
  bind() {
    this._tooltipWidgetChangedSubscription = this.eventAggregator.subscribe("tooltip:widget-changed", e => {
      const widget: ITooltipWidget = e.tooltipWidget;
      if (!widget) {
        return;
      }

      this.currentWidget = widget;
      this.currentTooltip = widget.currentTooltip;
    });
  }
  unbind() {
    this._tooltipWidgetChangedSubscription.dispose();
  }
}
