import * as fwx from "../../../../framework/forms/form-export";
import {
    StelleninseratEditUtils
} from "./stelleninserat-edit-utils";
import {
    StelleninseratLehreKundePackageEditUtils
} from "./stelleninserat-lehre-kunde-package-edit-utils";

@fwx.autoinject
export class StelleninseratHeartbaseEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_Stelleninserat: StelleninseratEditUtils,
        private $f_StelleninseratLehreKundePackage: StelleninseratLehreKundePackageEditUtils) {
        super(element, formBaseImport);
        this.id = "stelleninserat-heartbase-edit";
        this.title = "stelleninserat-heartbase-edit.stelleninserat-heartbase-edit_caption";
        this.addModel({
            "id": "$m_Stelleninserat",
            "webApiAction": "ZIT/Objekte/Stelleninserat",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'TextKopfzeile1': null,
                        'TextFusszeile1': null,
                        'StelleninseratTrainer': {
                            'expand': {
                                'Ansprechperson': null
                            }
                        },
                        'Geschaeftspartner': {
                            'expand': {
                                'Geschaeftspartnerdaten': null
                            }
                        }
                    }
                },
                'FerialeinsatzEinstellungen': null,
                'FerialbewerbungFragebogen': null
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "BewerbungInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "ProfilFirmaInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "StelleninseratVorschauUrl",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_StelleninseratKopfElement",
            "webApiAction": "ZIT/Objekte/StelleninseratKopfElement",
            "webApiColumns": ['Id'],
            "key": "models.data.$m_Stelleninserat.IdStelleninseratKopfElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratGruppeRel",
            "webApiAction": "ZIT/Objekte/StelleninseratGruppe",
            "webApiColumns": ["Id", "SortNr", "Text", "IdStelleninseratGruppentyp"],
            "webApiExpand": {
                'Gruppentyp': null
            },
            "webApiOrderBy": [{
                "columnName": "GruppentypSortNr",
                "sortOrder": 0
            }, {
                "columnName": "GruppentypBezeichnung",
                "sortOrder": 0
            }, {
                "columnName": "SortNr",
                "sortOrder": 0
            }, {
                "columnName": "Text",
                "sortOrder": 0
            }],
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratGruppeEdit",
            "webApiAction": "ZIT/Objekte/StelleninseratGruppe",
            "webApiExpand": {
                'Gruppentyp': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratGruppeElement",
            "webApiAction": "ZIT/Objekte/StelleninseratGruppeElement",
            "key": "models.data.$m_Stelleninserat.IdStelleninseratGruppeElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Bewerbung",
            "webApiAction": "ZIT/Objekte/Bewerbung",
            "webApiColumns": ["Id"],
            "webApiWhere": ["Kopf.IdStelleninserat", {
                "isBound": true,
                "expression": "models.data.$m_Stelleninserat.Id"
            }],
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "AddBewerbungCard",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_Bewerbungstyp",
            "webApiAction": "ZIT/Stammdaten/StelleninseratBewerbungstyp",
            "key": "models.data.$m_Stelleninserat.Kopf.IdBewerbungstyp",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratKlasseRel",
            "webApiAction": "ZIT/Objekte/StelleninseratKlasse",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratKlasseEdit",
            "webApiAction": "ZIT/Objekte/StelleninseratKlasse",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_Stelleninserat.actionCommand",
                "bindToFQ": "functions.$f_Stelleninserat.actionCommand"
            }
        });
        this.addEditPopup({
            "idContent": "stelleninseratGruppeEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_StelleninseratGruppeEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_StelleninseratGruppeEdit.Id"
                }
            }, {
                "to": "$v_idGeschaeftspartner",
                "binding": {
                    "dataContext": "$m_Stelleninserat",
                    "bindTo": "Kopf.IdGeschaeftspartner",
                    "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner",
                    "propertyPrefix": "Kopf"
                }
            }, {
                "to": "$v_idStelleninserat",
                "binding": {
                    "dataContext": "$m_Stelleninserat",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_Stelleninserat.Id"
                }
            }],
            "id": "stelleninseratGruppeEditPopup",
            "options": {
                "optionsName": "stelleninseratGruppeEditPopupOptions",
                "optionsNameFQ": "stelleninseratGruppeEditPopupOptions"
            },
            "caption": "stelleninserat-heartbase-edit.stelleninseratgruppeeditpopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "stelleninseratLehreKlasseEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_StelleninseratKlasseEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_StelleninseratKlasseEdit.Id"
                }
            }],
            "id": "stelleninseratLehreKlasseEditPopup",
            "options": {
                "optionsName": "stelleninseratLehreKlasseEditPopupOptions",
                "optionsNameFQ": "stelleninseratLehreKlasseEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addFunction("$f_Stelleninserat", $f_Stelleninserat, "functions.$f_Stelleninserat");
        this.addFunction("$f_StelleninseratLehreKundePackage", $f_StelleninseratLehreKundePackage, "functions.$f_StelleninseratLehreKundePackage");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "geschaeftspartner",
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_geschaeftspartner_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IdGeschaeftspartner",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartner",
            "options": {
                "optionsName": "r_geschaeftspartnerOptions",
                "optionsNameFQ": "r_geschaeftspartnerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "stelleninserat-heartbase-edit.r_stellenbezeichnungextern_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.StellenbezeichnungExtern",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.StellenbezeichnungExtern",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_stellenbezeichnungExtern",
            "options": {
                "optionsName": "r_stellenbezeichnungExternOptions",
                "optionsNameFQ": "r_stellenbezeichnungExternOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "ansprechperson",
            "customs": [{
                "key": "IdGeschaeftspartner",
                "value": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner"
            }],
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.ansprechpersonselect_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IdAnsprechperson",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdAnsprechperson",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "ansprechpersonSelect",
            "options": {
                "optionsName": "ansprechpersonSelectOptions",
                "optionsNameFQ": "ansprechpersonSelectOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "geschaeftspartner",
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_geschaeftspartnernopackage_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IdGeschaeftspartner",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Stelleninserat",
                    "bindTo": "$f_Stelleninserat.keinPackageRule",
                    "bindToFQ": "functions.$f_Stelleninserat.keinPackageRule"
                }
            }, {
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartnerNoPackage",
            "options": {
                "optionsName": "r_geschaeftspartnerNoPackageOptions",
                "optionsNameFQ": "r_geschaeftspartnerNoPackageOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 0,
            "acceptType": "image/*",
            "placeholderImageExpression": "models.data.$m_Stelleninserat.Kopf.DMSLinkStandardBild",
            "placeholderImageText": "Standardbild laut Lehrberuf",
            "height": "250px",
            "showToolbar": true,
            "showClearButton": false,
            "caption": "stelleninserat-heartbase-edit.r_dmslinkbild_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.DMSLinkBild",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.DMSLinkBild",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_dmslinkBild",
            "options": {
                "optionsName": "r_dmslinkBildOptions",
                "optionsNameFQ": "r_dmslinkBildOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "stelleninserat-heartbase-edit.r_standort_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.Standort",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.Standort",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_standort",
            "options": {
                "optionsName": "r_standortOptions",
                "optionsNameFQ": "r_standortOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 500,
            "caption": "stelleninserat-heartbase-edit.r_googlekarteadresse_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.GoogleKartenAdresse",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.GoogleKartenAdresse",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_googleKarteAdresse",
            "options": {
                "optionsName": "r_googleKarteAdresseOptions",
                "optionsNameFQ": "r_googleKarteAdresseOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "stelleninseratBewerbungstyp",
            "filter": [
                ["IdGeschaeftspartner", "null"], "or", ["IdGeschaeftspartner", {
                    "isBound": true,
                    "expression": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner"
                }]
            ],
            "customs": [{
                "key": "IdGeschaeftspartner",
                "value": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner"
            }, {
                "key": "CheckBenutzerrolle",
                "value": "true"
            }],
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_bewerbungstyp_caption",
            "placeholder": "stelleninserat-heartbase-edit.r_bewerbungstyp_placeholder",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IdBewerbungstyp",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdBewerbungstyp",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_bewerbungstyp",
            "options": {
                "optionsName": "r_bewerbungstypOptions",
                "optionsNameFQ": "r_bewerbungstypOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_lebenslaufanfragen_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.LebenslaufAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.LebenslaufAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_lebenslaufAnfragen",
            "options": {
                "optionsName": "r_lebenslaufAnfragenOptions",
                "optionsNameFQ": "r_lebenslaufAnfragenOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_anschreibenanfragen_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.AnschreibenAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.AnschreibenAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_anschreibenAnfragen",
            "options": {
                "optionsName": "r_anschreibenAnfragenOptions",
                "optionsNameFQ": "r_anschreibenAnfragenOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_zeugnisanfragen_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.LetztesZeugnisAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.LetztesZeugnisAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_zeugnisAnfragen",
            "options": {
                "optionsName": "r_zeugnisAnfragenOptions",
                "optionsNameFQ": "r_zeugnisAnfragenOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_reisepassausweisanfragen_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.ReisepassAusweisAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.ReisepassAusweisAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_reisepassAusweisAnfragen",
            "options": {
                "optionsName": "r_reisepassAusweisAnfragenOptions",
                "optionsNameFQ": "r_reisepassAusweisAnfragenOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_schnuppertermineanzeigen_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.SchnuppertermineAnzeigen",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.SchnuppertermineAnzeigen",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_schnuppertermineAnzeigen",
            "options": {
                "optionsName": "r_schnuppertermineAnzeigenOptions",
                "optionsNameFQ": "r_schnuppertermineAnzeigenOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "stelleninserat-heartbase-edit.r_stelleninserataktiv_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IsAktiv",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IsAktiv",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_stelleninseratAktiv",
            "options": {
                "optionsName": "r_stelleninseratAktivOptions",
                "optionsNameFQ": "r_stelleninseratAktivOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "veroeffentlichungTyp",
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_veroeffentlichentyp_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "VeroeffentlichungTyp",
                "bindToFQ": "models.data.$m_Stelleninserat.VeroeffentlichungTyp"
            },
            "validationRules": [],
            "id": "r_veroeffentlichenTyp",
            "options": {
                "optionsName": "r_veroeffentlichenTypOptions",
                "optionsNameFQ": "r_veroeffentlichenTypOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "stelleninserat-heartbase-edit.r_stellenbezeichnungintern_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.StellenbezeichnungIntern",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.StellenbezeichnungIntern",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_stellenbezeichnungIntern",
            "options": {
                "optionsName": "r_stellenbezeichnungInternOptions",
                "optionsNameFQ": "r_stellenbezeichnungInternOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "stelleninserat-heartbase-edit.r_gueltigvon_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.GueltigVon",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.GueltigVon",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigVon",
            "options": {
                "optionsName": "r_gueltigVonOptions",
                "optionsNameFQ": "r_gueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "stelleninserat-heartbase-edit.r_gueltigbis_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.GueltigBis",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.GueltigBis",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigBis",
            "options": {
                "optionsName": "r_gueltigBisOptions",
                "optionsNameFQ": "r_gueltigBisOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "stelleninserat-heartbase-edit.r_bewerbungvon_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialeinsatzEinstellungen.BewerbungOnlineVon",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialeinsatzEinstellungen.BewerbungOnlineVon",
                "propertyPrefix": "FerialeinsatzEinstellungen"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Stelleninserat",
                    "bindTo": "$f_Stelleninserat.bewerbungVonValidationRule",
                    "bindToFQ": "functions.$f_Stelleninserat.bewerbungVonValidationRule"
                }
            }],
            "id": "r_bewerbungVon",
            "options": {
                "optionsName": "r_bewerbungVonOptions",
                "optionsNameFQ": "r_bewerbungVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "stelleninserat-heartbase-edit.r_bewerbungbis_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialeinsatzEinstellungen.BewerbungOnlineBis",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialeinsatzEinstellungen.BewerbungOnlineBis",
                "propertyPrefix": "FerialeinsatzEinstellungen"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Stelleninserat",
                    "bindTo": "$f_Stelleninserat.bewerbungBisValidationRule",
                    "bindToFQ": "functions.$f_Stelleninserat.bewerbungBisValidationRule"
                }
            }],
            "id": "r_bewerbungBis",
            "options": {
                "optionsName": "r_bewerbungBisOptions",
                "optionsNameFQ": "r_bewerbungBisOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_bisherigeberufserfahrung_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.BisherigeBerufserfahrungAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.BisherigeBerufserfahrungAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_bisherigeBerufserfahrung",
            "options": {
                "optionsName": "r_bisherigeBerufserfahrungOptions",
                "optionsNameFQ": "r_bisherigeBerufserfahrungOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_fuehrerscheinundmobilitaet_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.FuehrerscheinUndMobilitaetAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.FuehrerscheinUndMobilitaetAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_fuehrerscheinUndMobilitaet",
            "options": {
                "optionsName": "r_fuehrerscheinUndMobilitaetOptions",
                "optionsNameFQ": "r_fuehrerscheinUndMobilitaetOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_sprachkenntnisse_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.SprachKenntnisseAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.SprachKenntnisseAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_sprachKenntnisse",
            "options": {
                "optionsName": "r_sprachKenntnisseOptions",
                "optionsNameFQ": "r_sprachKenntnisseOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_ausbildung_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.AusbildungAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.AusbildungAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_ausbildung",
            "options": {
                "optionsName": "r_ausbildungOptions",
                "optionsNameFQ": "r_ausbildungOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-heartbase-edit.r_staplerfuehrerschein_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.StaplerFuehrerscheinAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.StaplerFuehrerscheinAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_staplerfuehrerschein",
            "options": {
                "optionsName": "r_staplerfuehrerscheinOptions",
                "optionsNameFQ": "r_staplerfuehrerscheinOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_stelleninseratKlasseListViewToolbarOptions",
                "optionsNameFQ": "r_stelleninseratKlasseListViewToolbarOptions"
            },
            "caption": "stelleninserat-heartbase-edit.r_stelleninseratklasselistview_caption",
            "binding": {
                "dataContext": "$m_StelleninseratKopfElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_StelleninseratKlasseRel",
                "bindTo": "IdStelleninseratKopfElement",
                "bindToFQ": "models.data.$m_StelleninseratKlasseRel.IdStelleninseratKopfElement"
            },
            "dataModel": "$m_StelleninseratKopfElement",
            "editDataContext": "$m_StelleninseratKlasseEdit",
            "idEditPopup": "stelleninseratLehreKlasseEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_stelleninseratKlasseListView",
            "options": {
                "optionsName": "r_stelleninseratKlasseListViewOptions",
                "optionsNameFQ": "r_stelleninseratKlasseListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "col-xs-12 col-sm-6",
            "useDefaultListItemStyle": false,
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_bewerbungenListViewToolbarOptions",
                "optionsNameFQ": "r_bewerbungenListViewToolbarOptions"
            },
            "caption": "stelleninserat-heartbase-edit.r_bewerbungenlistview_caption",
            "binding": {
                "dataContext": "$m_Bewerbung"
            },
            "dataModel": "$m_Bewerbung",
            "pageSize": 4,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewerbungenListView",
            "options": {
                "optionsName": "r_bewerbungenListViewOptions",
                "optionsNameFQ": "r_bewerbungenListViewOptions"
            }
        });
        super.onConstructionFinished();
    }
}