import * as fwx from "../../../framework/forms/form-export";
import {
    NachrichtenListUtils
} from "./nachrichten-list-utils";

@fwx.autoinject
export class NachrichtenListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_NachrichtenListUtils: NachrichtenListUtils) {
        super(element, formBaseImport);
        this.id = "nachrichtenList";
        this.title = "nachrichtenList.nachrichtenlist_caption";
        this.addCommand({
            "binding": {
                "bindTo": "$f_NachrichtenListUtils.gruppeInfoCommand",
                "bindToFQ": "functions.$f_NachrichtenListUtils.gruppeInfoCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_NachrichtenListUtils.tableauCommand",
                "bindToFQ": "functions.$f_NachrichtenListUtils.tableauCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_NachrichtenListUtils.fotoCommand",
                "bindToFQ": "functions.$f_NachrichtenListUtils.fotoCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_NachrichtenListUtils.videoCommand",
                "bindToFQ": "functions.$f_NachrichtenListUtils.videoCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_NachrichtenListUtils.dokumentCommand",
                "bindToFQ": "functions.$f_NachrichtenListUtils.dokumentCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_NachrichtenListUtils.telefonatCommand",
                "bindToFQ": "functions.$f_NachrichtenListUtils.telefonatCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_NachrichtenListUtils.notizCommand",
                "bindToFQ": "functions.$f_NachrichtenListUtils.notizCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_NachrichtenListUtils.neueNachrichtErstellenCommand",
                "bindToFQ": "functions.$f_NachrichtenListUtils.neueNachrichtErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_NachrichtenListUtils.nachrichtUngelesenCommand",
                "bindToFQ": "functions.$f_NachrichtenListUtils.nachrichtUngelesenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_NachrichtenListUtils.vorlagenCommand",
                "bindToFQ": "functions.$f_NachrichtenListUtils.vorlagenCommand"
            }
        });
        this.addFunction("$f_NachrichtenListUtils", $f_NachrichtenListUtils, "functions.$f_NachrichtenListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}