import { IdxDataGridComponent } from './../../interfaces/dx-components';
import { LocalizationService } from './../../../framework/base/services/localization-service';
import { ErklaervideoService } from '../../services/erklaervideo-service';
import { autoinject } from "aurelia-framework";
import { IErklaerungsvideo } from '../../interfaces/export';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class Erklaervideo {
  constructor(
    private localization: LocalizationService,
    private erklaervideo: ErklaervideoService,
    private eventAggregator: EventAggregator
  ) {
  }

  datasource: IErklaerungsvideo[];

  erklaervideoGrid: IdxDataGridComponent;
  erklaervideoGridOptions: DevExpress.ui.dxDataGridOptions = {
    height: "100%",
    columnAutoWidth: true,
    paging: {
      pageSize: 20
    },
    hoverStateEnabled: true,
    searchPanel: {
      visible: true
    },
    columns: [
      {
        dataField: "Bezeichnung",
        caption: this.localization.translateOnce("erklaervideos.bezeichnung"),
        sortIndex: 0,
        sortOrder: "asc",
        cellTemplate: (cellElement, cellInfo) => {
          const icon = this.erklaervideo.erklaerungsvideoElementIcon(cellInfo.data);

          cellElement.innerHTML = `<i class="${icon}"></i> <span>${cellInfo.data.Bezeichnung}</span>`;
        }
      }
    ],
    bindingOptions: {
      dataSource: "datasource"
    },
    onRowClick: (e) => {
      if (!e || !e.data) {
        return;
      }

      const action = this.erklaervideo.erklaerungsvideoAction(e.data);
      if (!action) {
        return;
      }

      action();
    }
  };

  bind() {
    this.datasource = this.erklaervideo.getErklaerungsvideoDatasource();

    this.eventAggregator.publish("suche:clear", {});
  }
}
