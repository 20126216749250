import * as fwx from "../../../../framework/forms/form-export";
import {
    StelleninseratReadUtils
} from "./stelleninserat-read-utils";

@fwx.autoinject
export class StelleninseratReadForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StelleninseratReadUtils: StelleninseratReadUtils) {
        super(element, formBaseImport);
        this.id = "stelleninserat-ferial-read";
        this.title = "stelleninserat-ferial-read.stelleninserat-ferial-read_caption";
        this.addModel({
            "id": "$m_Stelleninserat",
            "webApiAction": "ZIT/Objekte/Stelleninserat",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'Ansprechperson': null,
                        'Geschaeftspartner': null,
                        'ProfilFirma': {
                            'columns': [
                                'Id',
                                'IsInaktiv'
                            ]
                        }
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "BewerbungInfo",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_Bewerbung",
            "webApiAction": "ZIT/Objekte/Bewerbung",
            "webApiColumns": ["Id"],
            "webApiWhere": ["Kopf.IdStelleninserat", {
                "isBound": true,
                "expression": "models.data.$m_Stelleninserat.Id"
            }],
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "AddBewerbungCard",
                "webApiCustomValue": "true"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_StelleninseratReadUtils.actionCommand",
                "bindToFQ": "functions.$f_StelleninseratReadUtils.actionCommand"
            }
        });
        this.addFunction("$f_StelleninseratReadUtils", $f_StelleninseratReadUtils, "functions.$f_StelleninseratReadUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "col-xs-12 col-sm-6",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_stelleninseratBewerbungenToolbarOptions",
                "optionsNameFQ": "r_stelleninseratBewerbungenToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Bewerbung"
            },
            "dataModel": "$m_Bewerbung",
            "pageSize": 6,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_stelleninseratBewerbungen",
            "options": {
                "optionsName": "r_stelleninseratBewerbungenOptions",
                "optionsNameFQ": "r_stelleninseratBewerbungenOptions"
            }
        });
        super.onConstructionFinished();
    }
}