import {
  autoinject,
  computedFrom
} from "aurelia-framework";
import {
  HttpClient
} from "aurelia-fetch-client";
import {
  JsonService
} from "./json-service";
import Config from "../../../config";
import { ErrorService } from './error-service';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class RestService {
  constructor(
    private jsonService: JsonService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) { }

  loadingCount = 0;
  getAuthorizationHeader: { (): any };
  getAuthorizationToken: { (): string };

  @computedFrom("loadingCount")
  get isLoading(): boolean {
    return this.loadingCount > 0;
  }

  delete(options: IRestDeleteOptions): Promise<any> {
    if (!options.id) {
      throw new Error("Id is missing");
    }

    return this.execute(
      "DELETE",
      `${options.url}/${options.id}`,
      this.createHeaders(options),
      options.increaseLoadingCount,
      RestGetResultType.json,
      false,
      !!options.ignoreErrors);
  }
  get(options: IRestGetOptions): Promise<any> {
    return this.execute(
      "GET",
      options.url,
      this.createHeaders(options),
      options.increaseLoadingCount,
      options.resultType || RestGetResultType.json,
      !!options.includeResponseHeaders,
      !!options.ignoreErrors);
  }
  post(options: IRestPostOptions): Promise<any> {
    return this.execute(
      "POST",
      options.url,
      this.createHeaders(options),
      options.increaseLoadingCount,
      options.resultType || RestGetResultType.json,
      !!options.includeResponseHeaders,
      !!options.ignoreErrors,
      options.data);
  }
  put(options: IRestPostOptions): Promise<any> {
    return this.execute(
      "PUT",
      options.url,
      this.createHeaders(options),
      options.increaseLoadingCount,
      options.resultType || RestGetResultType.json,
      !!options.includeResponseHeaders,
      !!options.ignoreErrors,
      options.data);
  }

  getUrl(suffix: string): string {
    if (typeof Config.baseUrl == "function") {
      return (<any>Config).baseUrl().concat("/").concat(suffix);
    } else if (location.port) {
      return `${Config.baseUrl}/${suffix}`;
    } else {
      let url = `${location.protocol}//${location.host}${location.pathname}`;

      if (url.endsWith("/")) {
        url = url.substr(0, url.length - 1);
      }
      if (suffix) {
        url += "/" + suffix;
      }

      return url;
    }
  }
  getApiUrl(suffix: string): string {
    return `${this.getUrl(Config.apiUrl)}/${suffix}`;
  }
  getWebApiUrl(suffix: string): string {
    return `${this.getUrl(Config.webApiUrl)}/${suffix}`;
  }
  getWebSocketUrl(suffix: string): string {
    let authToken = "";
    if (this.getAuthorizationToken()) {
      authToken = this.getAuthorizationToken();
      authToken = encodeURIComponent(authToken);
    }

    let url = this.getUrl(Config.webSocketUrl);

    const indexOf = url.indexOf("://");
    const prefix = url.startsWith("https") ? "wss" : "ws";
    url = prefix + url.substring(indexOf);

    return `${url}/${suffix}?auth-token=${authToken}`;
  }
  getViewUrl(suffix: string): string {
    let url = `${location.protocol}//${location.host}${location.pathname}`;

    if (url.endsWith("/")) {
      url = url.substr(0, url.length - 1);
    }
    if (suffix) {
      url += "/" + suffix;
    }

    return url;
  }

  private createHeaders(options?: IRestGetOptions | IRestDeleteOptions) {
    const headers: any = {};

    if (options && options["getOptions"]) {
      headers["X-GET-OPTIONS"] = this.jsonService.stringify(options["getOptions"]);
    }

    if (options && options.moduleId) {
      headers["X-MODULE-ID"] = options.moduleId;
    }

    headers["Content-Type"] = "application/json";
    headers["Accept"] = "application/json";

    if (this.getAuthorizationHeader) {
      Object.assign(headers, this.getAuthorizationHeader());
    }

    return headers;
  }
  private async execute(
    method: string,
    url: string,
    headers: any,
    changeLoadingCount: boolean,
    resultType: RestGetResultType,
    includeResponseHeaders: boolean,
    ignoreErrors: boolean,
    body?: any): Promise<any> {
    const client = new HttpClient();

    if (body) {
      if (typeof body !== "string" && !(body instanceof FormData)) {
        body = this.jsonService.stringify(body);
      }

      if (body instanceof FormData) {
        delete headers["Accept"];
        delete headers["Content-Type"];
      }
    }

    if (changeLoadingCount) {
      this.loadingCount++;
    }

    let result: any;
    try {
      await this.checkGetOptions(headers);

      const fetchResult = await client.fetch(url, {
        method: method,
        headers: headers,
        body: body,
      });

      if (!fetchResult.ok) {
        if (ignoreErrors) {
          return null;
        }

        if (fetchResult.status == 401) {
          this.eventAggregator.publish("rest:unauthorized", {
            url: url
          });

          throw {
            isHandled: true,
            message: "401"
          };
        } else if (fetchResult.status == 403) {
          throw {
            isHandled: true,
            message: "403"
          };
        } else if (fetchResult.status == 409) {
          const text = await fetchResult.text();

          this.eventAggregator.publish("notify", {
            message: this.get409Message(text),
            type: "error"
          });

          throw {
            isHandled: true,
            message: "409"
          };
        } else {
          const text = await fetchResult.text();
          throw new Error(text || fetchResult.statusText);
        }
      }

      switch (resultType) {
        case RestGetResultType.blob: {
          result = await fetchResult.blob();
          break;
        }
        default: {
          result = await fetchResult.text();
          result = this.jsonService.parse(result);
          break;
        }
      }

      if (includeResponseHeaders) {
        result = {
          headers: fetchResult.headers,
          data: result
        }
      }
    } catch (error) {
      if (ignoreErrors) {
        return null;
      }

      this.errorService.showAndLogError(error);
      throw error;
    } finally {
      if (changeLoadingCount) {
        this.loadingCount--;
      }
    }

    return result;
  }
  private async checkGetOptions(headers: string[]): Promise<any> {
    if (!headers["X-GET-OPTIONS"]) {
      return;
    }

    const getOptions = headers["X-GET-OPTIONS"];
    if (getOptions.length < 4000 && !this.hasNonAscii(getOptions)) {
      return;
    }

    const client = new HttpClient();
    delete headers["X-GET-OPTIONS"];

    const result = await client
      .fetch(this.getApiUrl("Data/Options"), {
        method: "POST",
        headers: headers,
        body: getOptions
      });

    headers["X-GET-OPTIONS"] = await result.text();
  }
  private hasNonAscii(text: string): boolean {
    return !/^[\u0000-\u007f]*$/.test(text);
  }
  private get409Message(text: string): string {
    if (text.substr(0, 1) == "{" && text.substr(text.length - 1, 1) == "}") {
      const json = JSON.parse(text);
      if (json.Message) {
        return json.Message;
      }
    }

    return text;
  }
}

interface IRestDeleteOptions {
  url: string;
  id: any;
  moduleId?: string;
  increaseLoadingCount?: boolean;
  ignoreErrors?: boolean;
}
interface IRestGetOptions {
  url: string;
  moduleId?: string;
  getOptions?: IGetOptions;
  increaseLoadingCount?: boolean;
  resultType?: RestGetResultType;
  includeResponseHeaders?: boolean;
  ignoreErrors?: boolean;
}
interface IRestPostOptions extends IRestGetOptions {
  data: any;
  increaseLoadingCount?: boolean;
}
interface IGetOptions {
  columns?: string[];
  where?: any[];
  totalSummary?: { selector: string; summaryType: string }[];
  orderBy?: { columnName: string; sortOrder: number }[];
  skip?: number;
  take?: number;
  selectDeleted?: boolean;
  requireTotalCount?: boolean;
  distinct?: boolean;
  changedSince?: Date;
  customs?: { key: string; value: any; }[];
  searchText?: string;
  maxRecords?: number;
  expand?: { [key: string]: IGetOptions };
}
export enum RestGetResultType {
  json = 0,
  blob = 1
}
