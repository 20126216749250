import {
  autoinject,
  valueConverter
} from "aurelia-framework";

@autoinject
@valueConverter("maxTextLength")
export class MaxTextLengthValueConverter{
  constructor(
  ) {}

  toView(value: string, maxLength: number = 50) {
    if (!value || value.length < maxLength) {
      return value;
    }

    return value.substr(0, maxLength).concat(" ...");
  }
}
