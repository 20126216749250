import { PopupInfoService } from './../../../../framework/forms/services/popup-info-service';
import { autoinject, transient } from 'aurelia-framework';
import { FormBase } from '../../../../framework/forms/classes/export';
import { ICommandData } from './../../../../framework/forms/interfaces/export';
import { DataSourceService, LocalizationService, RestService } from '../../../../framework/base/services/export';
import { IdxValidationGroupComponent } from '../../../interfaces/export';

@autoinject
@transient()
export class TerminZustandAendernUtils {
  constructor(
    private dataSource: DataSourceService,
    private localization: LocalizationService,
    private rest: RestService,
    private popupInfoService: PopupInfoService
  ) { }
  
  form: FormBase;

  zustandAendernModel = { 
    IdTermin: null, 
    IdZustandNeu: null, 
    KeineWeitereAenderungen: false 
  };

  zustandValidator: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };

  saveCommand: ICommandData = {
    id: "zustandSaveCommand",
    icon: "fas fa-check",
    tooltip: "base.save",
    sortIndex: 1010,
    execute: () => {
      const validationGroup: IdxValidationGroupComponent = this.form["r_aenderungZustandValidationGroup"];
      const result = validationGroup.instance.validate();
      if (!result.isValid) {
        return;
      }

      this.saveZustandAenderung();
    }
  };

  zustandSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      value: "functions.$f_TerminZustandAendernUtils.zustandAendernModel.IdZustandNeu"
    }
  };
  keineAenderungenZulassenCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "keine weiteren autom. Änderungen zulassen",
    bindingOptions: {
      value: "functions.$f_TerminZustandAendernUtils.zustandAendernModel.KeineWeitereAenderungen"
    }
  };

  bind(form: FormBase) {
    this.form = form;

    this.zustandSelectBoxOptions.dataSource = this.dataSource.createDataSource(
      this.form.scopeContainer,
      {
        webApiAction: "ZIT/Stammdaten/Zustand",
        webApiWhere: [["Bereich", { expression: "models.data.$m_Zustand.Bereich", isBound: true }]]
      });
  }

  private async saveZustandAenderung() {
    this.zustandAendernModel.IdTermin = this.form.variables.data.$v_idTermin;
    await this.rest.post({
      url: this.rest.getApiUrl("ZIT/Termin/ZustandAendern"),
      data: this.zustandAendernModel
    });

    DevExpress.ui.notify(this.localization.translateOnce("base.save_success"), "success", 3000);

    const validationGroup: IdxValidationGroupComponent = this.form["r_aenderungZustandValidationGroup"];
    validationGroup.instance.reset();

    this.popupInfoService.closeCurrentPopup();
  }
}
