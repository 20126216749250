import * as fwx from "../../../../framework/forms/form-export";
import {
    EventZeitraumReadUtils
} from "./event-zeitraum-read-utils";

@fwx.autoinject
export class EventZeitraumReadForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_EventZeitraumReadUtils: EventZeitraumReadUtils) {
        super(element, formBaseImport);
        this.id = "event-zeitraum-read";
        this.title = "event-zeitraum-read.event-zeitraum-read_caption";
        this.addModel({
            "id": "$m_EventZeitraum",
            "webApiAction": "ZIT/Objekte/EventZeitraum",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'Stelleninserate': null
                    }
                },
                'Event': {
                    'expand': {
                        'Kopf': null
                    }
                }
            },
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "CheckIsTeilnehmer",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddEventData",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddHasDokumente",
                "webApiCustomValue": "true"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EventZeitraumReadUtils.actionCommand",
                "bindToFQ": "functions.$f_EventZeitraumReadUtils.actionCommand"
            }
        });
        this.addFunction("$f_EventZeitraumReadUtils", $f_EventZeitraumReadUtils, "functions.$f_EventZeitraumReadUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}