import { autoinject, Scope } from "aurelia-framework";
import { ScopeContainer } from "./../../../framework/base/classes/scope-container";
import { IDataSourceOptionFilter } from "./../../../framework/base/interfaces/data-source-option-filter";
import { DataSourceService } from "./../../../framework/base/services/data-source-service";
import { ICommandData } from "./../../../framework/forms/interfaces/command-data";
import { SimpleWidgetCreatorService } from "./../../../framework/forms/widget-services/simple-widget-creator-service";
import { IdxPopupComponent, IdxListComponent, IdxSelectBoxComponent } from "./../../interfaces/dx-components";

@autoinject
export class LehrlingAuswaehlen {
  private _options: ILehrlingAuswaehlenPopupOptions;

  constructor(
    private _dataSourceService: DataSourceService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService
  ) { }

  idGeschaeftspartner: any;
  idStelleninserat: any;
  personSelected: number[] = [];

  scope: Scope;
  scopeContainer: ScopeContainer;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "350px",
    height: "auto"
  };

  popupCommands: ICommandData[] = [{
    id: "changeBewerbungstyp",
    idCategory: "$start",
    icon: "fas fa-check",
    tooltip: "base.save",
    sortIndex: 1000,
    execute: () => {
      this.popup.instance.hide();
      this._options.callback(this.personSelected);
    }
  }];

  geschaeftspartnerSelectBox: IdxSelectBoxComponent;
  geschaeftspartnerSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Name1",
    searchExpr: "Name1",
    searchEnabled: true,
    showClearButton: true,
    width: "300px",
    bindingOptions: {
      value: "idGeschaeftspartner"
    },
    onValueChangedByUser: (e) => {
      this.loadPersonDataSource();
    }
  };

  stelleninseratSelectBox: IdxSelectBoxComponent;
  stelleninseratSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Titel",
    searchExpr: "Titel",
    searchEnabled: true,
    showClearButton: true,
    width: "300px",
    bindingOptions: {
      value: "idStelleninserat"
    },
    onValueChangedByUser: (e) => {
      this.loadStelleninseratDataSource();
      this.loadPersonDataSource();
    }
  };

  personList: IdxListComponent;
  personListOptions: DevExpress.ui.dxListOptions = {
    searchExpr: "Titel",
    searchEnabled: true,
    keyExpr: "Id",
    displayExpr: "Titel",
    selectionMode: "multiple",
    showSelectionControls: true,
    height: "300px",
    width: "300px",
    onSelectionChanged: (ev) => {
      this.personSelected = ev.component.option("selectedItemKeys") || [];
    }
  };

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: null
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "lehrlingAuswaehlenToolbar",
      caption: "lehrling-auswahl.lehrlinge",
      scopeContainer: this.scopeContainer,
      options: this.popupOptions,
      commands: this.popupCommands
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  show(options: ILehrlingAuswaehlenPopupOptions) {
    this._options = options;
    this.resetData();

    this.personListOptions.selectionMode = options.selectionMode == SelectionModeTyp.multipe
      ? "multiple"
      : "single";

    this.loadGeschaeftspartnerDataSource();
    this.loadPersonDataSource();
    this.loadStelleninseratDataSource();

    this.popup.instance.show();
  }

  private resetData() {
    if (this.personList) {
      this.personList.instance.unselectAll();
    }
    this.idGeschaeftspartner = null;
    this.idStelleninserat = null;
    this.personSelected = [];
  }

  private loadGeschaeftspartnerDataSource() {
    if (this.geschaeftspartnerSelectBoxOptions.dataSource) {
      const geschaeftspartnerDataSource = <DevExpress.data.DataSource>this.geschaeftspartnerSelectBoxOptions.dataSource;
      geschaeftspartnerDataSource.reload();
      return;
    }
    this.geschaeftspartnerSelectBoxOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer, {
      webApiAction: "ZIT/Objekte/Geschaeftspartner",
      webApiColumns: ["Id", "Name1"]
    });
  }

  private loadStelleninseratDataSource() {
    if (this.stelleninseratSelectBoxOptions.dataSource) {
      const stelleninseratDataSource = <DevExpress.data.DataSource>this.stelleninseratSelectBoxOptions.dataSource;
      stelleninseratDataSource.reload();
      return;
    }
    this.stelleninseratSelectBoxOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer, {
      webApiAction: "ZIT/Objekte/Stelleninserat",
      webApiColumns: ["Id", "Titel"]
    }, {
      getCustomWhere: () => {
        const result = [];

        if (this.idGeschaeftspartner) {
          result.push(["Kopf.IdGeschaeftspartner", this.idGeschaeftspartner]);
        }
        return result;
      }
    });
  }

  private loadPersonDataSource() {
    if (this.personListOptions.dataSource) {
      const personDataSource = <DevExpress.data.DataSource>this.personListOptions.dataSource;
      personDataSource.reload();
      return;
    }
    this.personListOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer, {
      webApiAction: "ZIT/Objekte/Person",
      webApiColumns: ["Id", "Titel"]
    }, {
      getCustomFilters: () => {
        const result: IDataSourceOptionFilter[] = [];

        if (this.idStelleninserat) {
          result.push({
            webApiCustomKey: "IdStelleninserat",
            webApiCustomValue: this.idStelleninserat
          });
        } else if (this.idGeschaeftspartner) {
          result.push({
            webApiCustomKey: "IdGeschaeftspartner",
            webApiCustomValue: this.idGeschaeftspartner
          });
        }
        return result;
      }
    });
  }
}

export interface ILehrlingAuswaehlenPopupOptions {
  selectionMode: SelectionModeTyp;
  callback: { (idSelectedPersonList: number[]): void };
}

export enum SelectionModeTyp {
  multipe,
  single
}
