import * as fwx from "../../../framework/forms/form-export";
import {
    EntwicklungszoneListUtils
} from "./entwicklungszone-list-utils";

@fwx.autoinject
export class EntwicklungszoneListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_EntwicklungszoneListUtils: EntwicklungszoneListUtils) {
        super(element, formBaseImport);
        this.id = "entwicklungszoneList";
        this.title = "entwicklungszoneList.entwicklungszonelist_caption";
        this.addCommand({
            "binding": {
                "bindTo": "$f_EntwicklungszoneListUtils.meineAufgabenCommand",
                "bindToFQ": "functions.$f_EntwicklungszoneListUtils.meineAufgabenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EntwicklungszoneListUtils.aufgabeBestaetigenCommand",
                "bindToFQ": "functions.$f_EntwicklungszoneListUtils.aufgabeBestaetigenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EntwicklungszoneListUtils.activateTableView",
                "bindToFQ": "functions.$f_EntwicklungszoneListUtils.activateTableView"
            }
        });
        this.addFunction("$f_EntwicklungszoneListUtils", $f_EntwicklungszoneListUtils, "functions.$f_EntwicklungszoneListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}