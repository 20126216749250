import { autoinject, Scope, OverrideContext } from "aurelia-framework";
import { FormBase } from '../classes/form-base';

@autoinject
export class FormUtilsService {
  constructor() {}

  getMainForm(scope: Scope | OverrideContext, treatEditPopupAsMainForm: boolean = false): FormBase {
    if (scope.bindingContext == null) {
      return null;
    }

    if (scope.bindingContext instanceof FormBase) {
      const form: FormBase = scope.bindingContext;
      if ((!form.isEditPopup || treatEditPopupAsMainForm) && !form.isNestedForm) {
        return form;
      }
    }
    if (scope.bindingContext.mainForm instanceof FormBase) {
      return scope.bindingContext.mainForm;
    }

    if (scope["overrideContext"]) {
      return this.getMainForm(scope["overrideContext"]);
    } else if (scope["parentOverrideContext"]) {
      return this.getMainForm(scope["parentOverrideContext"]);
    }

    return null;
  }
}
