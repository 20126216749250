import { autoinject, transient } from "aurelia-framework";
import { FormBase } from "../../../framework/forms/export";
import { BenutzerTyp, SucheViewTyp } from "../../enumerations/export";
import { ICommandData } from "./../../../framework/forms/interfaces/command-data";
import { Entwicklungszone } from "./../../elements/entwicklungszone/entwicklungszone";
import { StartupService } from "./../../services/startup-service";
import { SucheService } from "../../services/export";
import { BrowserService } from "../../../framework/base/services/browser-service";

@autoinject
@transient()
export class EntwicklungszoneListUtils {

  constructor(
    private _startupService: StartupService,
    private _sucheService: SucheService,
    private _browserService: BrowserService) { }

  form: FormBase;
  currentFilterTyp: EntwicklungszoneFilterTyp;

  get entwicklungszone(): Entwicklungszone {
    return this.form["r_entwicklungszoneElement"];
  }

  meineAufgabenCommand: ICommandData = {
    id: "meineAufgabenShow",
    title: this._browserService.isMobile
      ? null
      : "entwicklungszone.meine-aufgaben",
    icon: "far fa-user-circle",
    sortIndex: 100,
    isVisible: this._startupService.startupInfo.Benutzer.Benutzertyp != BenutzerTyp.Administrator && this._startupService.startupInfo.Benutzer.CodeBenutzerrolle != "BEWERBER",
    classNameExpression: "$f_EntwicklungszoneListUtils.entwicklungszone.currentFilterTyp == 1 ? 'z--button-active' : ''",
    execute: () => {
      if (this.currentFilterTyp == EntwicklungszoneFilterTyp.MeineAufgaben) {
        this.entwicklungszone.setFilterMeineAufgaben(true);
        this.currentFilterTyp = EntwicklungszoneFilterTyp.Keine;
      } else {
        this.entwicklungszone.setFilterMeineAufgaben(false);
        this.entwicklungszone.setFilterZuBestaetigendeAufgaben(true);
        this.currentFilterTyp = EntwicklungszoneFilterTyp.MeineAufgaben;
      }

      this.entwicklungszone.reloadDataSources();
    }
  };

  aufgabeBestaetigenCommand: ICommandData = {
    id: "aufgabeBestaetigenCommand",
    icon: "far fa-check-square",
    title: this._browserService.isMobile
      ? null
      : "entwicklungszone.aufgabe-bestaetigen",
    sortIndex: 102,
    isVisible: this._startupService.startupInfo.Benutzer.Benutzertyp != BenutzerTyp.Administrator	&& this._startupService.startupInfo.Benutzer.CodeBenutzerrolle != "BEWERBER",
    classNameExpression: "$f_EntwicklungszoneListUtils.entwicklungszone.currentFilterTyp == 2 ? 'z--button-active' : ''",
    execute: () => {
      if (this.currentFilterTyp == EntwicklungszoneFilterTyp.ZuBestaetigendeAufgaben) {
        this.entwicklungszone.setFilterZuBestaetigendeAufgaben(true);
        this.currentFilterTyp = EntwicklungszoneFilterTyp.Keine;
      } else {
        this.entwicklungszone.setFilterZuBestaetigendeAufgaben(false);
        this.entwicklungszone.setFilterMeineAufgaben(true);
        this.currentFilterTyp = EntwicklungszoneFilterTyp.ZuBestaetigendeAufgaben;
      }

      this.entwicklungszone.reloadDataSources();
    }
  };

  activateTableView: ICommandData = {
    icon: "fas fa-table",
    id: "sucheTableViewCommand",
    tooltip: "suche-form.show-table-view",
    sortIndex: 1401,
    execute: (event: any) => {
      this._sucheService.activateSuche(
        "TIP.ZIT.Business.Entities.Objekte.Aufgaben.Aufgabe", 
        SucheViewTyp.table);
    }
  };

  bind(form: FormBase) {
    this.form = form;
  }
}

export enum EntwicklungszoneFilterTyp {
  Keine = 0,
  MeineAufgaben = 1,
  ZuBestaetigendeAufgaben = 2,
}
