import { autoinject, transient } from 'aurelia-framework';
import { FormBase } from '../../../../framework/forms/form-export';
import { Kontakttyp } from '../../../enumerations/export';

@autoinject
@transient()
export class AnsprechpersonKontaktUtils {
  constructor() { }

  form: FormBase;
  isTelefonOrDefault: boolean;

  bind(form: FormBase) {
    this.form = form;

    this.form.models.onLoaded.register((args) => {
      if (args.model.id == "$m_Kontakttyp" && args.data != void 0) {
        this.isTelefonOrDefault = (args.data.Typ != Kontakttyp.Hyperlink && args.data.Typ != Kontakttyp.Email);  
      }

      return Promise.resolve();
    })
  }
}