import { autoinject } from 'aurelia-framework';
import { RestService } from './../../framework/base/services/export';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ObjektInfoService } from './objekt-info-service';
import { LocationService } from '../../framework/base/services/location-service';
import { SucheViewTyp } from "../enumerations/export";

@autoinject
export class SucheService {
  constructor(
    private eventAggregator: EventAggregator,
    private objektInfo: ObjektInfoService,
    private rest: RestService,
    private location: LocationService
  ) {
    this.lastSearchValue = localStorage.getItem("currentSuche");
    this.lastObjektFullName = localStorage.getItem("currentObjektFullName");

    if (this.lastSearchValue) {
      this.lastSearchText = this.getSearchText(this.lastSearchValue);
    }

    this.eventAggregator.subscribe("objekt-info:loaded", () => {
      if (this.lastObjektFullName) {
        this.setObjektBezeichnung();
      }
    });

    if (this.lastObjektFullName) {
      if (this.objektInfo.isObjektInfoLoaded()) {
        this.setObjektBezeichnung();
      }
    }
  }

  lastObjektFullName: string;
  lastObjektBezeichnung: string;
  lastSearchText: string;
  lastSearchValue: string;
  lastWertQueryList: any[];

  clearSuche() {
    this.lastObjektFullName = null;
    this.lastObjektBezeichnung = null;
    this.lastSearchText = null;
    this.lastSearchValue = null;
    this.lastWertQueryList = null;

    localStorage.removeItem("currentSuche");
    localStorage.removeItem("currentObjektFullName");
  }
  activateSuche(objektFullName: string, viewTyp: SucheViewTyp = SucheViewTyp.list) {      
    const info = this.objektInfo.getObjektByFullname(objektFullName);

    this.eventAggregator.publish("suche:change-value", {
      value: info.Bezeichnung + ": ",
      viewTyp: viewTyp
    });
  }
  executeSuche(searchValue: string, objektFullName: string, searchText: string, wertQueryList: any[] = null, viewTyp: SucheViewTyp = SucheViewTyp.list) {
    const needsNavigation = !this.isSucheAktiv();

    if (this.lastObjektFullName != objektFullName) {
      this.lastObjektFullName = objektFullName;

      this.setObjektBezeichnung();
    }
    this.lastSearchText = searchText;
    this.lastSearchValue = searchValue;
    this.lastWertQueryList = wertQueryList;
    
    localStorage.setItem("currentSuche", searchValue);
    localStorage.setItem("currentObjektFullName", this.lastObjektFullName);

    if (!this.isSucheAktiv()) {
      this.location.goTo({
        url: "#Suche", 
        clearStack: true,
        customOptions: {
          viewTyp: viewTyp
        }
      });
    } else {
      this.eventAggregator.publish("suche:request");
    }
  }

  getObjektFullName(searchValue: string): string {
    const objektName = searchValue
      ? searchValue.match(/.*:/g)
      : null;

    const bezeichnung = objektName
      ? objektName.toString().slice(0, -1)
      : null;

    const currentObjekt = this.objektInfo.getObjektByBezeichnung(bezeichnung);
    if (!currentObjekt) {
      return null;
    }

    return currentObjekt.Fullname;
  }
  getSearchText(searchValue: string): string {
    const objektName = searchValue
      ? searchValue.match(/.*:/g)
      : null;

    const bezeichnung = objektName
      ? objektName.toString().slice(0, -1)
      : null;

    const currentObjekt = this.objektInfo.getObjektByBezeichnung(bezeichnung);
    if (!currentObjekt) {
      return searchValue;
    }

    return searchValue.substr(objektName.toString().length).trim();
  }

  private isSucheAktiv() {
    return location.hash == "#Suche";
  }
  private setObjektBezeichnung() {
    if (this.lastObjektFullName) {
      const info = this.objektInfo.getObjektByFullname(this.lastObjektFullName);
      this.lastObjektBezeichnung = info ? info.Bezeichnung : null;
    } else {
      this.lastObjektBezeichnung = null;
    }
  }
}
