import * as fwx from "../../../../framework/forms/form-export";
import {
    StelleninseratReadUtils
} from "./stelleninserat-read-utils";

@fwx.autoinject
export class StelleninseratLehrePersonReadForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StelleninseratReadUtils: StelleninseratReadUtils) {
        super(element, formBaseImport);
        this.id = "stelleninserat-lehre-person-read";
        this.title = "stelleninserat-lehre-person-read.stelleninserat-lehre-person-read_caption";
        this.addModel({
            "id": "$m_Stelleninserat",
            "webApiAction": "ZIT/Objekte/Stelleninserat",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'TextKopfzeile1': null,
                        'TextFusszeile1': null,
                        'Ansprechperson': {
                            'expand': {
                                'Personendaten': null
                            }
                        },
                        'Geschaeftspartner': {
                            'expand': {
                                'Geschaeftspartnerdaten': null
                            }
                        }
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratGruppeElement",
            "webApiAction": "ZIT/Objekte/StelleninseratGruppeElement",
            "webApiExpand": {},
            "key": "models.data.$m_Stelleninserat.IdStelleninseratGruppeElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratGruppe",
            "webApiAction": "ZIT/Objekte/StelleninseratGruppe",
            "webApiColumns": ["Id", "SortNr", "Text", "IdStelleninseratGruppentyp"],
            "webApiExpand": {
                'Gruppentyp': null
            },
            "webApiOrderBy": [{
                "columnName": "GruppentypSortNr",
                "sortOrder": 0
            }, {
                "columnName": "GruppentypBezeichnung",
                "sortOrder": 0
            }, {
                "columnName": "SortNr",
                "sortOrder": 0
            }, {
                "columnName": "Text",
                "sortOrder": 0
            }],
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Geschaeftspartner",
            "webApiAction": "ZIT/Objekte/Geschaeftspartner",
            "modificationInfoEnabled": true,
            "key": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner",
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "standardAddress",
                "webApiCustomValue": "true"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_StelleninseratReadUtils.actionCommand",
                "bindToFQ": "functions.$f_StelleninseratReadUtils.actionCommand"
            }
        });
        this.addFunction("$f_StelleninseratReadUtils", $f_StelleninseratReadUtils, "functions.$f_StelleninseratReadUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "groupProperty": "Gruppentyp.Bezeichnung",
            "optionsToolbar": {
                "optionsName": "r_stelleninseratCustomKopftextToolbarOptions",
                "optionsNameFQ": "r_stelleninseratCustomKopftextToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_StelleninseratGruppeElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_StelleninseratGruppe",
                "bindTo": "IdStelleninseratGruppeElement",
                "bindToFQ": "models.data.$m_StelleninseratGruppe.IdStelleninseratGruppeElement"
            },
            "dataModel": "$m_StelleninseratGruppeElement",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_stelleninseratCustomKopftext",
            "options": {
                "optionsName": "r_stelleninseratCustomKopftextOptions",
                "optionsNameFQ": "r_stelleninseratCustomKopftextOptions"
            }
        });
        super.onConstructionFinished();
    }
}