import { autoinject, bindable, bindingMode, observable, TaskQueue } from "aurelia-framework";
import * as moment from "moment";
import { ScopeContainer, DataSourceService } from "../../../framework/base/export";
import { FormBase, FormEventService } from "../../../framework/forms/export";
import { IZitCardOptions, ZitCardTyp } from "../../../zit-ui/elements/zit-card/export";
import { BenutzerTyp } from "../../enumerations/export";
import { IDataSourceOptionFilter } from "./../../../framework/base/interfaces/data-source-option-filter";
import { ListView } from "./../../../framework/forms/elements/list-view/list-view";
import { IListViewOptions } from "./../../../framework/forms/elements/list-view/list-view-options";
import { IZitTagOptions } from "./../../../zit-ui/elements/zit-tag/zit-tag-options";
import { IZitToolbarOptions } from "./../../../zit-ui/elements/zit-toolbar/zit-toolbar-options";
import { PersonService } from "./../../services/person-service";
import { StartupService } from "./../../services/startup-service";
import { EntwicklungszoneFilterTyp } from "./../../views/entwicklungszone/entwicklungszone-list-utils";
import { LehrlingAuswaehlen, SelectionModeTyp } from "./../lehrling-auswaehlen/lehrling-auswaehlen";

@autoinject
export class Entwicklungszone {
  private _onFormSavedDispose: {(): void};

  constructor(
    private _taskQueue: TaskQueue,
    private _startupService: StartupService,
    private _personService: PersonService,
    private _dataSourceService: DataSourceService,
    private _formEventService: FormEventService
  ) {
    this.idBenutzer = this._startupService.startupInfo.Benutzer.IdBenutzer;
    this.idObject = this._startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.JuristischePerson
      || this._startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.InternerBenutzer
      ? this._startupService.startupInfo.Benutzer.IdAnsprechperson
      : this._startupService.startupInfo.Benutzer.IdPerson;
  }

  scopeContainer: ScopeContainer;
  mainForm: FormBase;
  lehrlingAuswaehlenPopup: LehrlingAuswaehlen;
  lehrlingList: any[] = [];
  filterData: {
    IdPersonZugeteiltList?: number[];
    IdPersonBestaetigung?: number;
  } = {};
  idObject: number;
  idBenutzer: number;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) @observable currentFilterTyp: EntwicklungszoneFilterTyp = EntwicklungszoneFilterTyp.Keine;

  personTagOptions: IZitTagOptions = {
    icon:
    {
      text: "P"
    },
    text: "${person.Name}",
    infoList: [{
      icon: {
        faIcon: "fas fa-times"
      },
      onClick: async(e, data) => {
        const indexLehrlingList = this.lehrlingList.indexOf(data);
        if (indexLehrlingList < 0) {
          return;
        }
        this.lehrlingList.splice(indexLehrlingList, 1);

        const indexZugeteiltList = this.filterData.IdPersonZugeteiltList.indexOf(data.Id);
        if (indexZugeteiltList < 0) {
          return;
        }
        this.filterData.IdPersonZugeteiltList.splice(indexZugeteiltList, 1);

        this.reloadDataSources();
      }
    }]
  };

  lehrlingToolbarOptions: IZitToolbarOptions = {
    title: "entwicklungszone.auswahl-lehrling",
    smallToolbar: true,
    items: [{
      id: "showLehrlingToolbarOptionen",
      icon: "fas fa-plus",
      execute: () => {
        this.lehrlingAuswaehlenPopup.show({
          selectionMode: SelectionModeTyp.multipe,
          callback: async(selectedPersons) => {
            if (selectedPersons.length == 0) {
              return;
            }
            this.currentFilterTyp = EntwicklungszoneFilterTyp.Keine;

            for (const idPerson of selectedPersons) {
              await this.setLehrling(idPerson);
            }

            this.reloadDataSources();
          }
        });
      }
    }]
  };

  aufgabeCardOptions: IZitCardOptions = {
    isClickEnabled: true,
    useDefaultBindings: true,
    typ: ZitCardTyp.Kompakt
  };

  begonneneAufgabenListView: ListView;
  begonneneAufgabenListViewOptions: IListViewOptions = {
    useDefaultListItemStyle: false,
    itemClass: "col-xs-12 col-sm-6 col-lg-4 col-xl-3"
  };

  aktuelleAufgabenListView: ListView;
  aktuelleAufgabenListViewOptions: IListViewOptions = {
    useDefaultListItemStyle: false,
    itemClass: "col-xs-12 col-sm-6 col-lg-4 col-xl-3"
  };

  kommendeAufgabenListView: ListView;
  kommendeAufgabenListViewOptions: IListViewOptions = {
    useDefaultListItemStyle: false,
    itemClass: "col-xs-12 col-sm-6 col-lg-4 col-xl-3"
  };

  fertigeAufgabenListView: ListView;
  fertigeAufgabenListViewOptions: IListViewOptions = {
    useDefaultListItemStyle: false,
    itemClass: "col-xs-12 col-sm-6 col-lg-4 col-xl-3"
  };

  get isNotBewerber() {
    return this._startupService.startupInfo.Benutzer.CodeBenutzerrolle != "BEWERBER";
  }

  bind(bindingContext) {
    this.mainForm = bindingContext;

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });
    this.filterData.IdPersonZugeteiltList = [];
    this.loadAufgabenDataSources();

    this._onFormSavedDispose = this._formEventService.onSaved.register(async(ev) => {
      if (ev.form.models.modelWithKeyId?.webApiAction == "ZIT/Objekte/Aufgabe") {
        this.reload();
      }
    });
  }

  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;

    this._onFormSavedDispose();
    this._onFormSavedDispose = null;
  }

  setFilterMeineAufgaben(resetFilter: boolean) {
    this.filterData.IdPersonZugeteiltList = resetFilter
      ? []
      : [this.idObject];

    this.lehrlingList = [];
  }

  setFilterZuBestaetigendeAufgaben(resetFilter: boolean) {
    this.filterData.IdPersonBestaetigung = resetFilter
      ? null
      : this.idBenutzer;
  }

  reloadDataSources() {
    this._taskQueue.queueTask(() => {
      this.begonneneAufgabenListView.options.dataSource.reload();
      this.aktuelleAufgabenListView.options.dataSource.reload();
      this.kommendeAufgabenListView.options.dataSource.reload();
      this.fertigeAufgabenListView.options.dataSource.reload();
    });
  }

  private async setLehrling(idPerson: number) {
    const alreadyExists = this.lehrlingList.some((c) => c.Id === idPerson);
    if (alreadyExists) {
      return;
    }
    const lehrling = await this._personService.getPerson(idPerson);
    this.lehrlingList.push(lehrling);
    this.filterData.IdPersonZugeteiltList.push(idPerson);
  }

  private loadAufgabenDataSources() {
    this.loadBegonneneAufgaben();
    this.loadAktuelleAufgaben();
    this.loadKommendeAufgaben();
    this.loadFertigeAufgaben();
  }

  private loadBegonneneAufgaben() {
    this.begonneneAufgabenListViewOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ZIT/Objekte/Aufgabe",
        webApiOrderBy: [{ columnName: "Id", sortOrder: 0 }]
      }, {
      getCustomWhere: () => {
        const where: any[] = [];

        if (this.filterData.IdPersonZugeteiltList.length > 0) {
          const tempIdPersonZugeteiltListWhere = [];
          for (let i = 0; i < this.filterData.IdPersonZugeteiltList.length; i++) {
            tempIdPersonZugeteiltListWhere.push(["Kopf.IdObjekt", this.filterData.IdPersonZugeteiltList[i]]);
            if (i + 1 < this.filterData.IdPersonZugeteiltList.length) {
              tempIdPersonZugeteiltListWhere.push("or");
            }
          }
          where.push(tempIdPersonZugeteiltListWhere);
        }

        if (this.filterData.IdPersonBestaetigung) {
          where.push([["Kopf.IsErfuellt", true], "and", ["Kopf.IsBestaetigt", false]]);
          where.push(["Kopf.IdBestaetigungBenutzer", this.filterData.IdPersonBestaetigung]);
        } else {
          where.push([["Kopf.IsErfuellt", false], "or", ["Kopf.IsBestaetigt", false]]);
        }

        return where;
      },
      getCustomFilters: () => {
        const filter: IDataSourceOptionFilter[] = [];
        filter.push({
          webApiCustomKey: "AddAufgabeCard",
          webApiCustomValue: "true"
        });
        filter.push({
          webApiCustomKey: "OnlyGestartet",
          webApiCustomValue: "true"
        });
        return filter;
      }      
    });
  }

  private loadAktuelleAufgaben() {
    this.aktuelleAufgabenListViewOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ZIT/Objekte/Aufgabe",
        webApiOrderBy: [{ columnName: "Id", sortOrder: 0 }]
      }, {
        canLoad: () => {
          return !this.filterData.IdPersonBestaetigung;
        },
        getCustomWhere: () => {
          const today = moment()
            .startOf("day")
            .toDate();
  
          const where: any[] = [];
  
          if (this.filterData.IdPersonZugeteiltList.length > 0) {
            const tempIdPersonZugeteiltListWhere = [];
            for (let i = 0; i < this.filterData.IdPersonZugeteiltList.length; i++) {
              tempIdPersonZugeteiltListWhere.push(["Kopf.IdObjekt", this.filterData.IdPersonZugeteiltList[i]]);
              if (i + 1 < this.filterData.IdPersonZugeteiltList.length) {
                tempIdPersonZugeteiltListWhere.push("or");
              }
            }
            where.push(tempIdPersonZugeteiltListWhere);
          }
  
          return where;
        },
        getCustomFilters: () => {
          const filter: IDataSourceOptionFilter[] = [];
          filter.push({
            webApiCustomKey: "AddAufgabeCard",
            webApiCustomValue: "true"
          }, {
            webApiCustomKey: "OnlyAktuell",
            webApiCustomValue: "true"
          });
          return filter;
        }
      });
  }

  private loadKommendeAufgaben() {
    this.kommendeAufgabenListViewOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ZIT/Objekte/Aufgabe",
        webApiOrderBy: [{ columnName: "Kopf.StartDatum", sortOrder: 0 }]
      }, {
        canLoad: () => {
          return !this.filterData.IdPersonBestaetigung;
        },
        getCustomWhere: () => {
          const today = moment()
            .startOf("day")
            .toDate();
  
          const where: any[] = [];
  
          if (this.filterData.IdPersonZugeteiltList.length > 0) {
            const tempIdPersonZugeteiltListWhere = [];
            for (let i = 0; i < this.filterData.IdPersonZugeteiltList.length; i++) {
              tempIdPersonZugeteiltListWhere.push(["Kopf.IdObjekt", this.filterData.IdPersonZugeteiltList[i]]);
              if (i + 1 < this.filterData.IdPersonZugeteiltList.length) {
                tempIdPersonZugeteiltListWhere.push("or");
              }
            }
            where.push(tempIdPersonZugeteiltListWhere);
          }
          
          return where;
        },
        getCustomFilters: () => {
          const filter: IDataSourceOptionFilter[] = [];
          filter.push({
            webApiCustomKey: "AddAufgabeCard",
            webApiCustomValue: "true"
          }, {
            webApiCustomKey: "OnlyKommende",
            webApiCustomValue: "true"
          });
          return filter;
        }
      });
  }

  private loadFertigeAufgaben() {
    this.fertigeAufgabenListViewOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ZIT/Objekte/Aufgabe",
        webApiOrderBy: [{ columnName: "Kopf.ErfuelltDat", sortOrder: 1 }]
      }, {
        canLoad: () => {
          return !this.filterData.IdPersonBestaetigung;
        },
        getCustomWhere: () => {
          const where: any[] = [];
  
          if (this.filterData.IdPersonZugeteiltList.length > 0) {
            const tempIdPersonZugeteiltListWhere = [];
            for (let i = 0; i < this.filterData.IdPersonZugeteiltList.length; i++) {
              tempIdPersonZugeteiltListWhere.push(["Kopf.IdObjekt", this.filterData.IdPersonZugeteiltList[i]]);
              if (i + 1 < this.filterData.IdPersonZugeteiltList.length) {
                tempIdPersonZugeteiltListWhere.push("or");
              }
            }
            where.push(tempIdPersonZugeteiltListWhere);
          }
          
          return where;
        },
        getCustomFilters: () => {
          const filter: IDataSourceOptionFilter[] = [];
          filter.push({
            webApiCustomKey: "AddAufgabeCard",
            webApiCustomValue: "true"
          });
          filter.push({
            webApiCustomKey: "OnlyFertig",
            webApiCustomValue: "true"
          });
          return filter;
        }
      });
  }

  private reload() {
    (<DevExpress.data.DataSource>this.begonneneAufgabenListViewOptions.dataSource).reload();
    (<DevExpress.data.DataSource>this.aktuelleAufgabenListViewOptions.dataSource).reload();
    (<DevExpress.data.DataSource>this.kommendeAufgabenListViewOptions.dataSource).reload();
    (<DevExpress.data.DataSource>this.fertigeAufgabenListViewOptions.dataSource).reload();
  }
}
