import { autoinject } from 'aurelia-framework';
import { RestService, FileService, BrowserService } from './../../framework/base/services/export';
import { CustomEditPopupService } from './custom-edit-popup-service';
import { FormEvalService } from './form-eval-service';

@autoinject
export class DokumentService {
  constructor(
    private _restService: RestService,
    private _fileService: FileService,
    private _customEditPopupService: CustomEditPopupService,
    private _formEvalService: FormEvalService,
    private _browserService: BrowserService
  ) { }

  attachDropDokument(element: HTMLElement, typeName: string, getObjekt: {(): any}): boolean {
    if (!typeName) {
      return false;
    }
    if (this._browserService.isMobile) {
      return false;
    }

    const supportedTypeNameList = [
      "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung",
      "TIP.ZIT.Business.Entities.Objekte.Personen.Person",
      "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstelle",
      "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstellePersonenzuteilung"
    ];

    if (supportedTypeNameList.indexOf(typeName) < 0) {
      return false;
    }

    element.classList.add("z--drag-container")

    const cache = {
      idObjekt: null,
      idStelleninserat: null,
      hasBerechtigung: false
    };

    const getObjektInfo = async () => {
      const obj = getObjekt();
      if (!obj || !obj.Id) {
        return;
      }

      let currentTypeName = typeName
      let idObjekt = obj.Id;
      let idStelleninserat = null;

      if (typeName == "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung") {
        if (!obj.Kopf || !obj.Kopf.IdPerson || !obj.Kopf.IdStelleninserat) {
          return null;
        }

        idObjekt = obj.Kopf.IdPerson;
        idStelleninserat = obj.Kopf.IdStelleninserat;
        currentTypeName = "TIP.ZIT.Business.Entities.Objekte.Personen.Person";
      }

      if (idObjekt != cache.idObjekt || idStelleninserat != cache.idStelleninserat) {
        const hasBerechtigung = await this._formEvalService.hasBerechtigungBearbeitung(
          currentTypeName,
          idObjekt);

        cache.idObjekt = idObjekt;
        cache.idStelleninserat = idStelleninserat;
        cache.hasBerechtigung = hasBerechtigung;
      }
      if (!cache.hasBerechtigung) {
        return null;
      }

      return {
        typeName: currentTypeName,
        idObjekt,
        idStelleninserat
      };
    }

    element.addEventListener("dragenter", async (e) => {
      e.preventDefault();
      e.stopPropagation();
    }, false);
    element.addEventListener("dragleave", async (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.target != element) {
        return;
      }

      element.classList.remove("z--drag-container-active");
    }, false);
    element.addEventListener("dragover", async (e) => {
      e.preventDefault();
      e.stopPropagation();
      
      const objInfo = await getObjektInfo();
      if (objInfo == null) {
        return;
      }

      element.classList.add("z--drag-container-active");
      if (e.target != element) {
      } else {
        return;
      }
    });
    element.addEventListener("drop", async (e) => {
      e.preventDefault();
      e.stopPropagation();

      element.classList.remove("z--drag-container-active");
      
      const hasValidFile = e.dataTransfer
        && e.dataTransfer.files
        && e.dataTransfer.files.length > 0;
  
      if (!hasValidFile) {
        return;
      }

      if (e.dataTransfer.files.length > 1) {
        DevExpress.ui.notify(
          "Es kann max. ein Dokument gleichzeitig hochgeladen werden",
          "error",
          3000
        );

        return;
      }

      const file = e.dataTransfer.files[0];

      const objInfo = await getObjektInfo();
      if (objInfo == null) {
        return;
      }

      const dmsLink = await this._fileService.upload(file);

      this._customEditPopupService.dokumentEdit.show({
        setValuesOnModelWithKeyIdLoaded: {
          IdObjekt: objInfo.idObjekt
        },
        mappings: {
          "$id": 0,
          "$v_dmsLinkDokument": dmsLink,
          "$v_dateiname": file.name,
          "$v_objektTypeFullname": objInfo.typeName,
          "$v_idStelleninserat": objInfo.idStelleninserat,
        }
      });
    });

    return true;
  }

  saveDokument(idObjekt: number, idInformationsTyp: number, dmslink: string) {
    if (!dmslink) {
      return;
    }

    const dokumentToSave = this.getDokumentToSave(idObjekt, idInformationsTyp, dmslink)

    return this._restService.post({
      url: this._restService.getWebApiUrl("ZIT/Objekte/DokumentElement"),
      data: dokumentToSave,
      increaseLoadingCount: true
    });
  }
  sendTan(data: { IdDokumentElement: number, DokumentCode: string }){
    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Dokument/SendTAN"),
      data: data,
      increaseLoadingCount: true
    });
  }
  unterfertigenDokument(data: { IdDokumentElement: number, TAN: string }) {
    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Dokument/DokumentUnterfertigen"),
      data: data,
      increaseLoadingCount: true
    });
  }
  validateCode(data: { IdDokumentElement: number, DokumentCode: string }) {
    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Dokument/DokumentCodeValidieren"),
      data: data,
      increaseLoadingCount: true
    });
  }

  private getDokumentToSave(idObjekt: number, idInformationsTyp: number, dmslink: string) {
    return {
      IdObjekt: idObjekt || null,
      IdInformationstyp: idInformationsTyp || null,
      DMSLinkDokument: dmslink
    };
  }
}
