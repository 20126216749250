import * as fwx from "../../../../framework/forms/form-export";
import {
    AnsprechpersonKontaktUtils
} from "./ansprechperson-kontakt-utils";

@fwx.autoinject
export class AnsprechpersonKontaktEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_AnsprechpersonKontaktUtils: AnsprechpersonKontaktUtils) {
        super(element, formBaseImport);
        this.id = "ansprechperson-kontakt-edit";
        this.title = "ansprechperson-kontakt-edit.ansprechperson-kontakt-edit_caption";
        this.addModel({
            "id": "$m_AnsprechpersonKontakt",
            "webApiAction": "ZIT/Objekte/AnsprechpersonKontakt",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_Kontakttyp",
            "webApiAction": "ZIT/Stammdaten/Kontakttyp",
            "key": "models.data.$m_AnsprechpersonKontakt.IdKontakttyp",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addFunction("$f_AnsprechpersonKontaktUtils", $f_AnsprechpersonKontaktUtils, "functions.$f_AnsprechpersonKontaktUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "kontakttyp",
            "customs": [{
                "key": "objektTyp",
                "value": "'TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson'"
            }],
            "filters": [],
            "caption": "ansprechperson-kontakt-edit.r_kontakttyp_caption",
            "binding": {
                "dataContext": "$m_AnsprechpersonKontakt",
                "bindTo": "IdKontakttyp",
                "bindToFQ": "models.data.$m_AnsprechpersonKontakt.IdKontakttyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_kontakttyp",
            "options": {
                "optionsName": "r_kontakttypOptions",
                "optionsNameFQ": "r_kontakttypOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "landvorwahl",
            "filters": [],
            "caption": "ansprechperson-kontakt-edit.r_landvorwahl_caption",
            "binding": {
                "dataContext": "$m_AnsprechpersonKontakt",
                "bindTo": "Wert1",
                "bindToFQ": "models.data.$m_AnsprechpersonKontakt.Wert1"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_landvorwahl",
            "options": {
                "optionsName": "r_landvorwahlOptions",
                "optionsNameFQ": "r_landvorwahlOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "ansprechperson-kontakt-edit.r_wert2_caption",
            "binding": {
                "dataContext": "$m_AnsprechpersonKontakt",
                "bindTo": "Wert2",
                "bindToFQ": "models.data.$m_AnsprechpersonKontakt.Wert2"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_wert2",
            "options": {
                "optionsName": "r_wert2Options",
                "optionsNameFQ": "r_wert2Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "ansprechperson-kontakt-edit.r_email_caption",
            "binding": {
                "dataContext": "$m_AnsprechpersonKontakt",
                "bindTo": "Wert1",
                "bindToFQ": "models.data.$m_AnsprechpersonKontakt.Wert1"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_email",
            "options": {
                "optionsName": "r_emailOptions",
                "optionsNameFQ": "r_emailOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 1000,
            "caption": "ansprechperson-kontakt-edit.r_ansprechpersonkontaktbemerkung_caption",
            "binding": {
                "dataContext": "$m_AnsprechpersonKontakt",
                "bindTo": "Bemerkung",
                "bindToFQ": "models.data.$m_AnsprechpersonKontakt.Bemerkung"
            },
            "validationRules": [],
            "id": "r_ansprechpersonKontaktBemerkung",
            "options": {
                "optionsName": "r_ansprechpersonKontaktBemerkungOptions",
                "optionsNameFQ": "r_ansprechpersonKontaktBemerkungOptions"
            }
        });
        super.onConstructionFinished();
    }
}