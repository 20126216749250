import * as fwx from "../../../../framework/forms/form-export";
import {
    AufgabeEditUtils
} from "./aufgabe-edit-utils";

@fwx.autoinject
export class AufgabeEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_AufgabeEditUtils: AufgabeEditUtils) {
        super(element, formBaseImport);
        this.id = "aufgabe-obj-edit";
        this.title = "aufgabe-obj-edit.aufgabe-obj-edit_caption";
        this.addModel({
            "id": "$m_Aufgabe",
            "webApiAction": "ZIT/Objekte/Aufgabe",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'StammAufgabensetVersion': null,
                        'AufgabeBeantwortungDateien': null,
                        'AufgabeBestaetigungDateien': null,
                        'StammAufgabeVersion': {
                            'expand': {
                                'AufgabeDateien': null
                            }
                        },
                        'StammAufgabe': {
                            'columns': [
                                'Id'
                            ],
                            'expand': {
                                'AufgabeDateien': null
                            }
                        }
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "AddZielCard",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_AufgabeProtokoll",
            "webApiAction": "ZIT/Objekte/AufgabeProtokoll",
            "webApiWhere": ["IdAufgabe", {
                "isBound": true,
                "expression": "models.data.$m_Aufgabe.Id"
            }],
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AufgabeEditUtils.navPrevCommand",
                "bindToFQ": "functions.$f_AufgabeEditUtils.navPrevCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AufgabeEditUtils.navNextCommand",
                "bindToFQ": "functions.$f_AufgabeEditUtils.navNextCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AufgabeEditUtils.navHistoryCommand",
                "bindToFQ": "functions.$f_AufgabeEditUtils.navHistoryCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AufgabeEditUtils.addZielCommand",
                "bindToFQ": "functions.$f_AufgabeEditUtils.addZielCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AufgabeEditUtils.infoAusbilderCommand",
                "bindToFQ": "functions.$f_AufgabeEditUtils.infoAusbilderCommand"
            }
        });
        this.addFunction("$f_AufgabeEditUtils", $f_AufgabeEditUtils, "functions.$f_AufgabeEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "75px",
            "caption": "aufgabe-obj-edit.r_beantwnotiz_caption",
            "isReadOnlyExpression": "!models.data.$m_Aufgabe._CanErfuellen",
            "binding": {
                "dataContext": "$m_Aufgabe",
                "bindTo": "Kopf.BeantwortungNotiz",
                "bindToFQ": "models.data.$m_Aufgabe.Kopf.BeantwortungNotiz",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_beantwNotiz",
            "options": {
                "optionsName": "r_beantwNotizOptions",
                "optionsNameFQ": "r_beantwNotizOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "75px",
            "caption": "aufgabe-obj-edit.r_bestaetigungnotiz_caption",
            "isReadOnlyExpression": "!models.data.$m_Aufgabe._CanBestaetigen",
            "binding": {
                "dataContext": "$m_Aufgabe",
                "bindTo": "Kopf.BestaetigungNotiz",
                "bindToFQ": "models.data.$m_Aufgabe.Kopf.BestaetigungNotiz",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bestaetigungNotiz",
            "options": {
                "optionsName": "r_bestaetigungNotizOptions",
                "optionsNameFQ": "r_bestaetigungNotizOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_popupInf",
            "options": {
                "optionsName": "r_popupInfOptions",
                "optionsNameFQ": "r_popupInfOptions"
            },
            "caption": "aufgabe-obj-edit.r_popupinf_caption",
            "height": "auto",
            "width": "600px",
            "commands": []
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "aufgabe-obj-edit.r_infobestben_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Aufgabe",
                "bindTo": "Kopf.BestaetigungBenutzerName",
                "bindToFQ": "models.data.$m_Aufgabe.Kopf.BestaetigungBenutzerName",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_infoBestBen",
            "options": {
                "optionsName": "r_infoBestBenOptions",
                "optionsNameFQ": "r_infoBestBenOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "g",
            "caption": "aufgabe-obj-edit.r_infoversionaufgabe_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Aufgabe",
                "bindTo": "Kopf.StammAufgabeDatum",
                "bindToFQ": "models.data.$m_Aufgabe.Kopf.StammAufgabeDatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_infoVersionAufgabe",
            "options": {
                "optionsName": "r_infoVersionAufgabeOptions",
                "optionsNameFQ": "r_infoVersionAufgabeOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_infProDatum",
                "caption": "aufgabe-obj-edit.r_infprodatum_caption",
                "bindTo": "Anlagedatum",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_infProTyp",
                "caption": "aufgabe-obj-edit.r_infprotyp_caption",
                "bindTo": "Typ",
                "sortIndex": 0,
                "sortOrder": "asc",
                "enumTypeName": "TIP.ZIT.Business.Enumerations.AufgabenProtokollTyp"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_infoProtokollToolbarOptions",
                "optionsNameFQ": "r_infoProtokollToolbarOptions"
            },
            "caption": "aufgabe-obj-edit.r_infoprotokoll_caption",
            "binding": {
                "dataContext": "$m_AufgabeProtokoll"
            },
            "dataModel": "$m_AufgabeProtokoll",
            "height": "300px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_infoProtokoll",
            "options": {
                "optionsName": "r_infoProtokollOptions",
                "optionsNameFQ": "r_infoProtokollOptions"
            }
        });
        super.onConstructionFinished();
    }
}