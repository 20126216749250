import { autoinject } from "aurelia-framework";
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class WidgetExtenderService {
  constructor(
    private eventAggregator: EventAggregator
  ) {
    eventAggregator.subscribe("dx-widget:attaching", e => {
      if (e.name == "dxRadioGroup") {
        if (e.options.layout == void(0)) {
          e.options.layout = "horizontal";
        }
      } else if (e.name == "dxDataGrid") {
        if (!e.options.paging || !e.options.paging.pageSize) {
          e.options.paging = e.options.paging || {};
          e.options.paging.pageSize = 30;
        }
        if (!e.options.pager || e.options.pager.showInfo == void(0)) {
          e.options.pager = e.options.pager || {};
          e.options.pager.showInfo = true;
          e.options.pager.visible = true;
        }
      } else if (e.name == "dxLookup") {
        if (e.options.fieldTemplate === "zit-landvorwahl-bezeichnung-field-template") {
          e.options.fieldTemplate = "zit-landvorwahl-bezeichnung-lookup-field-template"
        }
      } else if (e.name == "dxDateBox") {
        if (e.options.width == void(0)) {
          e.options.width = "100%";
        }
      }
    });
  }
}
