import * as fwx from "../../../../framework/forms/form-export";
import {
    GeschaeftspartnerEntlohnungsschemaUtils
} from "./geschaeftspartner-entlohnungsschema";

@fwx.autoinject
export class GeschaeftspartnerEntlohnungsschemaForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_EntlohungsschemaUtils: GeschaeftspartnerEntlohnungsschemaUtils) {
        super(element, formBaseImport);
        this.id = "geschaeftspartner-entlohnungsschema";
        this.title = "geschaeftspartner-entlohnungsschema.geschaeftspartner-entlohnungsschema_caption";
        this.addModel({
            "id": "$m_Entlohnungsschema",
            "webApiAction": "ZIT/Stammdaten/Entlohnungsschema",
            "webApiWhere": ["IdGeschaeftspartner", {
                "isBound": true,
                "expression": "variables.data.$v_idGeschaeftspartner"
            }],
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_EntlohnungsschemaEdit",
            "webApiAction": "ZIT/Stammdaten/Entlohnungsschema",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Geschaeftspartner",
            "webApiAction": "ZIT/Objekte/Geschaeftspartner",
            "webApiColumns": ["Name1"],
            "modificationInfoEnabled": true,
            "key": "variables.data.$v_idGeschaeftspartner",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$v_idGeschaeftspartner"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EntlohungsschemaUtils.addNewEntlohnungsschemaCommand",
                "bindToFQ": "functions.$f_EntlohungsschemaUtils.addNewEntlohnungsschemaCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EntlohungsschemaUtils.saveEntlohungsschemaCommand",
                "bindToFQ": "functions.$f_EntlohungsschemaUtils.saveEntlohungsschemaCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EntlohungsschemaUtils.deleteEnlohnungsschemaCommand",
                "bindToFQ": "functions.$f_EntlohungsschemaUtils.deleteEnlohnungsschemaCommand"
            }
        });
        this.addFunction("$f_EntlohungsschemaUtils", $f_EntlohungsschemaUtils, "functions.$f_EntlohungsschemaUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_entlohnungsschemaGridBezeichnung",
                "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemagridbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_r_entlohnungsschemaGridSortNr",
                "caption": "geschaeftspartner-entlohnungsschema.r_r_entlohnungsschemagridsortnr_caption",
                "bindTo": "SortNr",
                "width": "90px"
            }],
            "optionsToolbar": {
                "optionsName": "r_entlohungsschemaDataGridToolbarOptions",
                "optionsNameFQ": "r_entlohungsschemaDataGridToolbarOptions"
            },
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohungsschemadatagrid_caption",
            "binding": {
                "dataContext": "$m_Entlohnungsschema"
            },
            "dataModel": "$m_Entlohnungsschema",
            "onItemClick": "functions.$f_EntlohungsschemaUtils.onEntlohungsschemaClick($event)",
            "selectionMode": 1,
            "pageSize": 10,
            "showPagerInfo": true,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_entlohungsschemaDataGrid",
            "options": {
                "optionsName": "r_entlohungsschemaDataGridOptions",
                "optionsNameFQ": "r_entlohungsschemaDataGridOptions"
            }
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "r_entlohungsschemaValidationGroup",
            "options": {
                "optionsName": "r_entlohungsschemaValidationGroupOptions",
                "optionsNameFQ": "r_entlohungsschemaValidationGroupOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemabezeichnung_caption",
            "binding": {
                "dataContext": "$m_EntlohnungsschemaEdit",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_EntlohnungsschemaEdit.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_entlohnungsschemaBezeichnung",
            "options": {
                "optionsName": "r_entlohnungsschemaBezeichnungOptions",
                "optionsNameFQ": "r_entlohnungsschemaBezeichnungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showSpinButtons": true,
            "min": 0.0,
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemasortnr_caption",
            "binding": {
                "dataContext": "$m_EntlohnungsschemaEdit",
                "bindTo": "SortNr",
                "bindToFQ": "models.data.$m_EntlohnungsschemaEdit.SortNr"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_entlohnungsschemaSortNr",
            "options": {
                "optionsName": "r_entlohnungsschemaSortNrOptions",
                "optionsNameFQ": "r_entlohnungsschemaSortNrOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemagueltigvon_caption",
            "binding": {
                "dataContext": "$m_EntlohnungsschemaEdit",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_EntlohnungsschemaEdit.GueltigVon"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_entlohnungsschemaGueltigVon",
            "options": {
                "optionsName": "r_entlohnungsschemaGueltigVonOptions",
                "optionsNameFQ": "r_entlohnungsschemaGueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemagueltigbis_caption",
            "binding": {
                "dataContext": "$m_EntlohnungsschemaEdit",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_EntlohnungsschemaEdit.GueltigBis"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_entlohnungsschemaGueltigBis",
            "options": {
                "optionsName": "r_entlohnungsschemaGueltigBisOptions",
                "optionsNameFQ": "r_entlohnungsschemaGueltigBisOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 200,
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemabeschaeftignungsgruppeueberlasser_caption",
            "binding": {
                "dataContext": "$m_EntlohnungsschemaEdit",
                "bindTo": "BeschaeftignungsgruppeUeberlasser",
                "bindToFQ": "models.data.$m_EntlohnungsschemaEdit.BeschaeftignungsgruppeUeberlasser"
            },
            "validationRules": [],
            "id": "r_entlohnungsschemaBeschaeftignungsgruppeUeberlasser",
            "options": {
                "optionsName": "r_entlohnungsschemaBeschaeftignungsgruppeUeberlasserOptions",
                "optionsNameFQ": "r_entlohnungsschemaBeschaeftignungsgruppeUeberlasserOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "max": 99999999.0,
            "format": "n2",
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemastundensatzueberlasser_caption",
            "binding": {
                "dataContext": "$m_EntlohnungsschemaEdit",
                "bindTo": "StundensatzUeberlasser",
                "bindToFQ": "models.data.$m_EntlohnungsschemaEdit.StundensatzUeberlasser"
            },
            "validationRules": [],
            "id": "r_entlohnungsschemaStundensatzUeberlasser",
            "options": {
                "optionsName": "r_entlohnungsschemaStundensatzUeberlasserOptions",
                "optionsNameFQ": "r_entlohnungsschemaStundensatzUeberlasserOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemakollektivvertragbeschaeftiger_caption",
            "binding": {
                "dataContext": "$m_EntlohnungsschemaEdit",
                "bindTo": "KollektivvertragBeschaeftiger",
                "bindToFQ": "models.data.$m_EntlohnungsschemaEdit.KollektivvertragBeschaeftiger"
            },
            "validationRules": [],
            "id": "r_entlohnungsschemaKollektivvertragBeschaeftiger",
            "options": {
                "optionsName": "r_entlohnungsschemaKollektivvertragBeschaeftigerOptions",
                "optionsNameFQ": "r_entlohnungsschemaKollektivvertragBeschaeftigerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 200,
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemabeschaeftigungsgruppebeschaeftiger_caption",
            "binding": {
                "dataContext": "$m_EntlohnungsschemaEdit",
                "bindTo": "BeschaeftigungsgruppeBeschaeftiger",
                "bindToFQ": "models.data.$m_EntlohnungsschemaEdit.BeschaeftigungsgruppeBeschaeftiger"
            },
            "validationRules": [],
            "id": "r_entlohnungsschemaBeschaeftigungsgruppeBeschaeftiger",
            "options": {
                "optionsName": "r_entlohnungsschemaBeschaeftigungsgruppeBeschaeftigerOptions",
                "optionsNameFQ": "r_entlohnungsschemaBeschaeftigungsgruppeBeschaeftigerOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "max": 9999.0,
            "format": "n2",
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemawoechentlichearbeitszeit_caption",
            "binding": {
                "dataContext": "$m_EntlohnungsschemaEdit",
                "bindTo": "WoechentlicheArbeitszeit",
                "bindToFQ": "models.data.$m_EntlohnungsschemaEdit.WoechentlicheArbeitszeit"
            },
            "validationRules": [],
            "id": "r_entlohnungsschemaWoechentlicheArbeitszeit",
            "options": {
                "optionsName": "r_entlohnungsschemaWoechentlicheArbeitszeitOptions",
                "optionsNameFQ": "r_entlohnungsschemaWoechentlicheArbeitszeitOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "max": 99999999.0,
            "format": "n2",
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemaentlohnung_caption",
            "binding": {
                "dataContext": "$m_EntlohnungsschemaEdit",
                "bindTo": "Entlohnung",
                "bindToFQ": "models.data.$m_EntlohnungsschemaEdit.Entlohnung"
            },
            "validationRules": [],
            "id": "r_entlohnungsschemaEntlohnung",
            "options": {
                "optionsName": "r_entlohnungsschemaEntlohnungOptions",
                "optionsNameFQ": "r_entlohnungsschemaEntlohnungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "max": 99999999.0,
            "format": "n2",
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemareferenzzuschlag_caption",
            "binding": {
                "dataContext": "$m_EntlohnungsschemaEdit",
                "bindTo": "Referenzzuschlag",
                "bindToFQ": "models.data.$m_EntlohnungsschemaEdit.Referenzzuschlag"
            },
            "validationRules": [],
            "id": "r_entlohnungsschemaReferenzzuschlag",
            "options": {
                "optionsName": "r_entlohnungsschemaReferenzzuschlagOptions",
                "optionsNameFQ": "r_entlohnungsschemaReferenzzuschlagOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "max": 99999999.0,
            "format": "n2",
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemaschichtzulage_caption",
            "binding": {
                "dataContext": "$m_EntlohnungsschemaEdit",
                "bindTo": "Schichtzulage",
                "bindToFQ": "models.data.$m_EntlohnungsschemaEdit.Schichtzulage"
            },
            "validationRules": [],
            "id": "r_entlohnungsschemaSchichtzulage",
            "options": {
                "optionsName": "r_entlohnungsschemaSchichtzulageOptions",
                "optionsNameFQ": "r_entlohnungsschemaSchichtzulageOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "max": 99999999.0,
            "format": "n2",
            "caption": "geschaeftspartner-entlohnungsschema.r_entlohnungsschemanachtschichtzulage_caption",
            "binding": {
                "dataContext": "$m_EntlohnungsschemaEdit",
                "bindTo": "Nachtschichtzulage",
                "bindToFQ": "models.data.$m_EntlohnungsschemaEdit.Nachtschichtzulage"
            },
            "validationRules": [],
            "id": "r_entlohnungsschemaNachtschichtzulage",
            "options": {
                "optionsName": "r_entlohnungsschemaNachtschichtzulageOptions",
                "optionsNameFQ": "r_entlohnungsschemaNachtschichtzulageOptions"
            }
        });
        super.onConstructionFinished();
    }
}