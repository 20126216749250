import { autoinject } from 'aurelia-framework';
import { RestService } from '../../framework/base/services/rest-service';
import { IObjektExportEigenschaftData } from '../interfaces/export';
import { GlobalizationService } from '../../framework/base/services/globalization-service';
@autoinject
export class ObjektExportEigenschaftService {
  private _objektExportEigenschaftList: IObjektExportEigenschaftData[];
  constructor(
    private rest: RestService
  ) {
    this.loadObjektExportEigenschaftList();
  }

  getExportEigenschaftList(): IObjektExportEigenschaftData[] {
    return this._objektExportEigenschaftList;
  }
  getExportEigenschaftListByFullname(fullname: string): IObjektExportEigenschaftData[] {
    if (!fullname) {
      return null;
    }

    return this._objektExportEigenschaftList
      .filter(c => c.KeyParent == fullname || c.Key == fullname);
  }
  getExportEigenschaftFormat(id: number): string {
    const found = this._objektExportEigenschaftList.find(c => c.Id == id);

    return found ? found.Format : null;
  }

  getExportGridColumns(gridInstance: DevExpress.ui.dxDataGrid): any[] {
    const columns: DevExpress.ui.dxDataGridColumn[] = gridInstance.option("columns");

    return columns.map((col, index) => {
      let width = gridInstance.columnOption(index, "width");
      if (width && width.toString().indexOf("px") < 0) {
        width = `${width}px`;
      }
      
      let format = null;
      if (col.format) {
        if (col.dataField === "Anlagedatum") {
          format = "g";
        } else {
          const id = Number(col.dataField.substring(4));
          format = this.getExportEigenschaftFormat(id);
        }
      }

      return {
        caption: col.caption,
        dataField: col.dataField,
        visibleIndex: gridInstance.columnOption(index, "visibleIndex"),
        sortIndex: gridInstance.columnOption(index, "sortIndex"),
        sortOrder: gridInstance.columnOption(index, "sortOrder"),
        width: width,
        format: format
      }
    });
  }

  getObjektFullNameByIdList(idList: number[]) {
    return this.rest.post({
      url: this.rest.getApiUrl("ZIT/Stammdaten/TypeNameFromObjectIdList"),
      data: idList
    });
  }
  private async loadObjektExportEigenschaftList() {
    this._objektExportEigenschaftList = await this.rest.get({
      url: this.rest.getApiUrl("ZIT/Volltext/ObjektExportEigenschaft")
    });
  }
}
