import { FormEventService } from './../../../framework/forms/services/form-event-service';
import { FormBase } from './../../../framework/forms/classes/form-base';
import { IdxSelectBoxComponent } from './../../interfaces/dx-components';
import { PopupInfoService } from './../../../framework/forms/services/popup-info-service';
import { autoinject, Scope, OverrideContext } from 'aurelia-framework';
import { LocalizationService, ScopeContainer, RestService } from '../../../framework/base/export';
import { SimpleWidgetCreatorService, ICommandData } from '../../../framework/forms/export';
import { IdxPopupComponent, IdxValidationGroupComponent } from '../../interfaces/export';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class BewerbungUmbuchen {

  constructor(
    private simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private popupInfoService: PopupInfoService,
    private localizationService: LocalizationService,
    private restService: RestService,
    private formEventService: FormEventService,
    private eventAggregator: EventAggregator
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  form: FormBase;

  popupCommands: ICommandData[] = [{
    id: "bewerbungUmbuchenSave",
    icon: "fas fa-save",
    tooltip: "base.save",
    sortIndex: 1001,
    execute: () => {
      const result = this.validationGroup.instance.validate();
      if (!result.isValid) {
        return;
      }

      const selectedHelper: any = this.bewerbungUmbuchenStelleninseratSelectBox.instance.option("selectedItem");

      this.restService.post({
        url: this.restService.getApiUrl("ZIT/Objekt/BewerbungUmbuchen"),
        increaseLoadingCount: true,
        data: selectedHelper
      }).then(c => {
        DevExpress.ui.notify(c, "SUCCESS", 3000);
        this.popupInfoService.closeAllPopups();
        this.bewerbungUmbuchenStelleninseratSelectBox.setOption({ value: null });
        this.form.models.reloadAll();

        this.formEventService.onSaved.fire({
          form: this.form
        });

        this.eventAggregator.publish("bewerbung:umgebucht", {
          IdStelleninserat: selectedHelper.IdStelleninseratAlt
        });
      });
    }
  }];
  bewerbungUmbuchenPopup: IdxPopupComponent;
  bewerbungUmbuchenPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "300px",
    maxHeight: "80%",
    maxWidth: "550px"
  };

  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };
  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {};

  bewerbungUmbuchenStelleninseratSelectBox: IdxSelectBoxComponent;
  bewerbungUmbuchenStelleninseratSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "IdStelleninseratNeu",
    displayExpr: "Bezeichnung",
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "bewerbungUmbuchenPopupToolbar",
      caption: "bewerbung-umbuchen.popup-titel",
      options: this.bewerbungUmbuchenPopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  showPopup(idObjekt: number, form: FormBase) {
    this.form = form;

    this.setStelleninseratDataSource(idObjekt);
    this.bewerbungUmbuchenPopup.instance.show();
  }

  async setStelleninseratDataSource(idObjekt: number): Promise<any> {
    return this.restService.get({
      url: `${this.restService.getApiUrl("ZIT/Objekt/BewerbungUmbuchenList")}?idObjekt=${idObjekt}`,
      increaseLoadingCount: true
    }).then(r => {
      if (!r || !r.length) {
        return;
      }

      if (this.bewerbungUmbuchenStelleninseratSelectBox) {
        this.bewerbungUmbuchenStelleninseratSelectBox.instance.option("dataSource", r);
      } else {
        this.bewerbungUmbuchenStelleninseratSelectBoxOptions.dataSource = r;
      }
    });
  }
}
