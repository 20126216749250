import { autoinject } from "aurelia-framework";
import { RestService } from "./../../framework/base/services/export";
import { IBenutzerrolleCodeData } from "../interfaces/export";

@autoinject
export class CodesService {
  constructor(
    private rest: RestService
  ) { }


  getObjektCodes(id: number): Promise<IBenutzerrolleCodeData[]> {
    return this.rest.get({
      url: `${this.rest.getApiUrl("ZIT/Objekt/Codes")}?idObjekt=${id}`
    });
  }
  getBenutzerCodes(id: number): Promise<IBenutzerrolleCodeData[]> {
    return this.rest.get({
      url: `${this.rest.getApiUrl("ZIT/Berechtigung/Codes")}?idBenutzer=${id}`
    });
  }
  saveObjektCodes(id: number, data: IBenutzerrolleCodeData[]) {
    return this.rest.post({
      url: `${this.rest.getApiUrl("ZIT/Objekt/SaveBenutzerrolleCode")}?idObjekt=${id}`,
      data: data
    });
  }
  saveBenutzerCodes(id: number, data: IBenutzerrolleCodeData[]) {
    return this.rest.post({
      url: `${this.rest.getApiUrl("ZIT/Berechtigung/SaveBenutzerrolleCode")}?idBenutzer=${id}`,
      data: data
    });
  }
}