import { autoinject } from "aurelia-framework";
import { RestService, GlobalizationService } from "../../framework/base/services/export";
import { LoginprofilEinstellungenService, LoginprofilEinstellungTyp, ILoginprofilEinstellung } from "./loginprofil-einstellungen-service";
import { IErweiterteSucheObjektFeldData, ITextValue } from "../interfaces/export";
import { ObjektExportEigenschaftDatenTyp } from "./../enumerations/export";

@autoinject
export class ErweiterteSucheService {
  constructor(
    private _loginprofilEinstellungenService: LoginprofilEinstellungenService,
    private _globalizationService: GlobalizationService,
    private _restService: RestService
  ) { }

  private textFilterOperators: ITextValue[] = [
    { text: "enthält", value: "like" },
    { text: "enthält nicht", value: "notlike" },
    { text: "entspricht", value: "=" },
    { text: "entspricht nicht", value: "!=" },
    { text: "nicht definiert", value: "isnull" },
    { text: "definiert", value: "isnotnull" }
  ];
  private dateAndNumberFilterOperators: ITextValue[] = [
    { text: "entspricht", value: "=" },
    { text: "entspricht nicht", value: "!=" },
    { text: "zwischen", value: "between" },
    { text: "größer", value: ">" },
    { text: "größer gleich", value: ">=" },
    { text: "kleiner", value: "<" },
    { text: "kleiner gleich", value: "<=" },
    { text: "definiert", value: "isnotnull" },
    { text: "nicht definiert", value: "isnull" }
  ];
  private markerFilterOperators: ITextValue[] = [
    { text: "einer aus", value: "in" },
    { text: "alle", value: "all" },
    { text: "keiner aus", value: "notin" },
  ];
  private statusFilterOperators: ITextValue[] = [
    { text: "einer aus", value: "in" },
    { text: "keiner aus", value: "notin" },
  ];
  private auswahllisteFilterOperators: ITextValue[] = [
    { text: "einer aus", value: "in" },
    { text: "keiner aus", value: "notin" },
  ];

  async loadEinstellungen(objekttypFullName: string): Promise<ILoginprofilEinstellung[]> {
    if (!objekttypFullName) {
      return [];
    }

    return this._loginprofilEinstellungenService.loadLoginprofilEinstellung({
      Typ: LoginprofilEinstellungTyp.Suche,
      Entitaet: objekttypFullName
    });
  }

  async loadObjektFelder(objekttypFullName: string): Promise<IErweiterteSucheObjektFeldData[]> {
    if (!objekttypFullName) {
      return;
    }

    const r = await this._restService.get({
      url: this._restService.getApiUrl(`ZIT/Volltext/ObjektExportEigenschaftSucheErweitert?typeName=${objekttypFullName}`)
    });

    this.extractFilterSpecial(r, ObjektExportEigenschaftDatenTyp.Marker, -1, "Marker");
    this.extractFilterSpecial(r, ObjektExportEigenschaftDatenTyp.ObjektStatus, -2, "Status");

    for (let i of r) {
      if (i.Datentyp != ObjektExportEigenschaftDatenTyp.Auswahlliste) {
        continue;
      }
      if (!i.AuswahllisteDatenquelle) {
        continue;
      }

      i.DataList = await this.loadAuswahlliste(i.AuswahllisteDatenquelle);
    }

    return r;
  }
  getOperatorDataSource(operatorTyp: ObjektExportEigenschaftDatenTyp) {
    switch (operatorTyp) {
      case ObjektExportEigenschaftDatenTyp.Text:
        return this.textFilterOperators;
      case ObjektExportEigenschaftDatenTyp.Datum:
      case ObjektExportEigenschaftDatenTyp.Nummer:
        return this.dateAndNumberFilterOperators;
      case ObjektExportEigenschaftDatenTyp.Marker:
        return this.markerFilterOperators;
      case ObjektExportEigenschaftDatenTyp.ObjektStatus:
        return this.statusFilterOperators;
      case ObjektExportEigenschaftDatenTyp.Auswahlliste:
        return this.auswahllisteFilterOperators;
      default:
        return [];
    }
  }
  getFilterBezeichnung(filter: IErweiterteSucheObjektFeldData, operator: string, wert: any) {
    switch (operator) {
      case "=":
      case "!=":
      case ">":
      case ">=":
      case "<":
      case "like":
      case "notlike":
      case "<=": {
        return `${filter.Bezeichnung} ${this.getOperatorBezeichnung(filter.Datentyp, operator)} ${this.getWertBezeichnung(filter, wert)}`;
      }
      case "between": {
        return `${filter.Bezeichnung} ${this.getOperatorBezeichnung(filter.Datentyp, operator)} ${this.getWertBezeichnung(filter, wert.wert1)} und ${this.getWertBezeichnung(filter, wert.wert2)}`;
      }
      case "in":
      case "notin":
      case "all": {
        const bez = this.getWertBezeichnungList(filter, wert);
        return `${filter.Bezeichnung} ${this.getOperatorBezeichnung(filter.Datentyp, operator)} ${bez}`;
      }
      case "isnull":
      case "isnotnull": {
        return `${filter.Bezeichnung} ${this.getOperatorBezeichnung(filter.Datentyp, operator)}`;
      }
    }

    return "";
  }

  private extractFilterSpecial(filterList: IErweiterteSucheObjektFeldData[], datentyp: ObjektExportEigenschaftDatenTyp, newId: number, bezeichnung: string) {
    const extractList = filterList.filter(i => i.Datentyp == datentyp);
    if (extractList.length > 0) {
      extractList.forEach(m => {
        const indexOf = filterList.indexOf(m);
        filterList.splice(indexOf, 1);
      });

      const newFilter: IErweiterteSucheObjektFeldData = {
        Id: newId,
        Bezeichnung: bezeichnung,
        Datentyp: datentyp,
        DataList: extractList.map(m => {
          m.Id = m.Id;
          return m;
        }),
        SucheErweitertVorbelegt: true
      };

      filterList.push(newFilter);

      return newFilter;
    }

    return null;
  }
  private getOperatorBezeichnung(datentyp: ObjektExportEigenschaftDatenTyp, operator: string) {
    const r = this.getOperatorDataSource(datentyp);
    const op = r.find(o => o.value == operator);

    if (!op) {
      return "";
    }

    return op.text;
  }
  private getWertBezeichnung(filter: IErweiterteSucheObjektFeldData, wert: any) {
    if (!wert) {
      return "";
    }

    if (filter.Datentyp == ObjektExportEigenschaftDatenTyp.Nummer) {
      return this._globalizationService.format(wert, filter.Format || "d");
    } else if (filter.Datentyp == ObjektExportEigenschaftDatenTyp.Datum) {
      return this._globalizationService.format(wert, filter.Format || "n0");
    } else {
      return wert;
    }
  }
  private getWertBezeichnungList(filter: IErweiterteSucheObjektFeldData, wert: any) {
    if (!Array.isArray(wert)) {
      return "";
    }

    if (!Array.isArray(filter.DataList)) {
      return "";
    }

    const itemList = [];

    for (let w of wert) {
      const i = filter.DataList.find(d => d.Id == w);
      const text = i ? i.Bezeichnung : "n/a";

      itemList.push(text);
    }

    return itemList.join(", ");
  }
  private loadAuswahlliste(webApiAction: string): Promise<any[]> {
    return this._restService.get({
      url: this._restService.getWebApiUrl(webApiAction),
      getOptions: {
        columns: ["Id", "Bezeichnung"]
      }
    });
  }
}
