import * as fwx from "../../../framework/forms/form-export";
import {
    SucheUtils
} from "./suche-utils";

@fwx.autoinject
export class SucheForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_SucheUtils: SucheUtils) {
        super(element, formBaseImport);
        this.id = "suche";
        this.title = "suche.suche_caption";
        this.addModel({
            "id": "$m_Suche",
            "webApiAction": "ZIT/Volltext/Suche",
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "webApiSearchtextEnabled": true,
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "searchtext": "variables.data.$v_searchText",
            "filters": [{
                "webApiCustomKey": "viewType",
                "webApiCustomValue": "functions.$f_SucheUtils.viewTyp"
            }, {
                "webApiCustomKey": "searchStandardFilter",
                "webApiCustomValue": "functions.$f_SucheUtils.searchStandardFilter"
            }, {
                "if": "functions.$f_SucheUtils.objektFullName",
                "webApiCustomKey": "objektFullName",
                "webApiCustomValue": "functions.$f_SucheUtils.objektFullName"
            }, {
                "if": "functions.$f_SucheUtils.wertQueryList",
                "webApiCustomKey": "searchExtended",
                "webApiCustomValue": "functions.$f_SucheUtils.wertQueryList"
            }, {
                "if": "functions.$f_SucheUtils.additionalColumns",
                "webApiCustomKey": "additionalColumns",
                "webApiCustomValue": "functions.$f_SucheUtils.additionalColumns"
            }]
        });
        this.addVariable({
            "id": "$v_searchText"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_SucheUtils.createNewCommand",
                "bindToFQ": "functions.$f_SucheUtils.createNewCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_SucheUtils.columnsCommand",
                "bindToFQ": "functions.$f_SucheUtils.columnsCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_SucheUtils.actionCommand",
                "bindToFQ": "functions.$f_SucheUtils.actionCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_SucheUtils.activateListView",
                "bindToFQ": "functions.$f_SucheUtils.activateListView"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_SucheUtils.activateTableView",
                "bindToFQ": "functions.$f_SucheUtils.activateTableView"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_SucheUtils.activateStandardfilter",
                "bindToFQ": "functions.$f_SucheUtils.activateStandardfilter"
            }
        });
        this.addFunction("$f_SucheUtils", $f_SucheUtils, "functions.$f_SucheUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [],
            "optionsToolbar": {
                "optionsName": "r_sucheGridToolbarOptions",
                "optionsNameFQ": "r_sucheGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Suche"
            },
            "dataModel": "$m_Suche",
            "onItemClick": "functions.$f_SucheUtils.onItemClicked($event)",
            "selectionMode": 2,
            "height": "100%",
            "showPagerInfo": true,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_sucheGrid",
            "options": {
                "optionsName": "r_sucheGridOptions",
                "optionsNameFQ": "r_sucheGridOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "suche-card-item col-xs-12 col-sm-6 col-lg-4 col-xl-3",
            "useDefaultListItemStyle": false,
            "pagingMode": "loadNext",
            "optionsToolbar": {
                "optionsName": "r_sucheListViewToolbarOptions",
                "optionsNameFQ": "r_sucheListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Suche"
            },
            "dataModel": "$m_Suche",
            "onItemClick": "functions.$f_SucheUtils.onItemClicked($event)",
            "selectionMode": 2,
            "pageSize": 16,
            "height": "100%",
            "showPagerInfo": true,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_sucheListView",
            "options": {
                "optionsName": "r_sucheListViewOptions",
                "optionsNameFQ": "r_sucheListViewOptions"
            }
        });
        super.onConstructionFinished();
    }
}