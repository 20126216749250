import { autoinject } from "aurelia-framework";
import { SimpleWidgetCreatorService } from '../../../framework/forms/export';
import { ScopeContainer, FileService, BrowserService } from '../../../framework/base/export';
import { IdxPopupComponent, IdxGalleryComponent } from '../../interfaces/export';
import { IDatei } from '../datei/datei';

@autoinject
export class DateiGalerieViewer {
  private _options: IDateiGalerieViewerShowOptions;

  constructor(
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _fileService: FileService,
    private _browserService: BrowserService
  ) {}

  scopeContainer: ScopeContainer;

  popupTitel: string;
  dateiList: IDatei[];

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: this._browserService.isMobile ? "100%" : "75vw",
    height: this._browserService.isMobile ? "100%" : "75vh",
    onHidden: () => {
      this.dateiList = [];
      this.gallery.instance.option("dataSource", []);
    }
  };

  galleryItemInfo: any = {};

  gallery: IdxGalleryComponent;
  galleryOptions: DevExpress.ui.dxGalleryOptions = {
    itemTemplate: "itemTemplate",
    height: "100%",
    width: "100%",
    showIndicator: false,
    showNavButtons: true,
    onContentReady: (ev) => {
      const selectedItem = ev.component.option("selectedItem");
      if (!selectedItem) {
        return;
      }

      this.checkRender(selectedItem);
    },
    onItemRendered: (ev) => {
      this.galleryItemInfo[ev.itemData.Id || ev.itemData.DMSLink] = <IGalleryItem>{
        data: ev.itemData,
        element: ev.itemElement,
        rendered: false
      }
    },
    onOptionChanged: (ev) => {
      if (ev.name == "selectedItem" && ev.value) {
        this.checkRender(ev.value);
      }
    }
  };

  scrollOptions: DevExpress.ui.dxScrollViewOptions = {
    direction: "horizontal"
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "dateiGalerieViewerPopupToolbar",
      caption: "datei-galerie-viewer.popup-titel",
      options: this.popupOptions,
      commands: [],
      scopeContainer: this.scopeContainer
    });
  }

  async show(options: IDateiGalerieViewerShowOptions) {
    this._options = options;

    this.popupTitel = options.popupTitel;
    this.galleryItemInfo = {};
    this.dateiList = options.dateiList;
    
    await this.popup.instance.show();
    this.popup.instance.repaint();

    this.gallery.instance.option({
      animationEnabled: false,
      dataSource: this.dateiList,
      selectedIndex: 0
    });

    this.gallery.instance.option("animationEnabled", true);
  }

  async onBildClick(bild) {
    const indexOf = this.dateiList.indexOf(bild);
    if (indexOf < 0) {
      return;
    }

    this.gallery.instance.goToItem(indexOf, false);
  }

  private checkRender(datei: IDatei) {
    const galleryItem: IGalleryItem = this.galleryItemInfo[datei.Id || datei.DMSLink];
    if (!galleryItem || galleryItem.rendered) {
      return;
    }

    const container: HTMLDivElement = galleryItem.element.querySelector(".datei-galerie-item");
    const image = <HTMLImageElement>container.firstElementChild;
    const url = this._fileService.getInlineUrl(galleryItem.data.DMSLinkDownload || galleryItem.data.DMSLink);
    image.src = url;
    
    galleryItem.rendered = true;
  }
}

export interface IDateiGalerieViewerShowOptions {
  popupTitel: string;
  dateiList?: IDatei[];
}
interface IGalleryItem {
  data: any;
  element: Element;
  rendered: boolean;
}