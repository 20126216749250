import { autoinject, computedFrom, transient } from "aurelia-framework";
import { IZitToolbarOptions } from "../../../../zit-ui/elements/zit-toolbar/zit-toolbar-options";
import { Datei } from "../../../elements/datei/datei";
import { EventKopierenOptionen } from "../../../elements/event-kopieren-optionen/event-kopieren-optionen";
import { IdxDataGridComponent } from "../../../interfaces/dx-components";
import { BrowserService } from "./../../../../framework/base/services/browser-service";
import { JsonService } from "./../../../../framework/base/services/json-service";
import { LocalizationService } from "./../../../../framework/base/services/localization-service";
import { RestService } from "./../../../../framework/base/services/rest-service";
import { ContextMenu } from "./../../../../framework/forms/classes/context-menu";
import { FormBase } from "./../../../../framework/forms/classes/form-base";
import { ICommandData } from "./../../../../framework/forms/interfaces/command-data";
import { DokumentListView } from "./../../../elements/dokument-list-view/dokument-list-view";
import { ErweiterteSuche } from "./../../../elements/erweiterte-suche/erweiterte-suche";
import { EventTeilnehmerZuteilen } from "./../../../elements/event-teilnehmer-zuteilen/event-teilnehmer-zuteilen";
import { Share } from "./../../../elements/share/share";
import { StatusAendern } from "./../../../elements/status-aendern/status-aendern";
import { IStatusChangeResultData } from "./../../../interfaces/status-change-result-data";
import { AnsprechpersonService } from "./../../../services/ansprechperson-service";
import { EventZeitraumService } from "./../../../services/event-zeitraum-service";
import { StartupService } from "./../../../services/startup-service";

@transient()
@autoinject
export class EventEditUtils {
  private _linkAnmHiddenCols = [
    "Element.Alter",
    "Element.Email",
    "Element.EventAnmeldungsgruppe.Bezeichnung",
    "Element.Ausbildung.Bezeichnung",
    "Element.EventErfahrenDurch"
  ]
  
  constructor(
    private _startupService: StartupService,
    private _browserService: BrowserService,
    private _jsonService: JsonService,
    private _localizationService: LocalizationService,
    private _restService: RestService,
    private _ansprechpersonService: AnsprechpersonService,
    private _eventZeitraumService: EventZeitraumService) {
    this.isGeschaeftspartnerSchule = this._startupService.startupInfo.Benutzer.IsGeschaeftspartnerSchuleBenutzer;
  }

  form: FormBase;
  isGeschaeftspartnerSchule: boolean;
  erweiterteSucheWertQuery: any;
  meldenBeiDataSource: string[];
  selectedKeys: number[];
  idEventZeitraumFilter: number;
  eventZeitraumFilterDataSource: any;
  eventTeilnehmerFilter: string;

  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };
  statusAendernPopupCommand: ICommandData = {
    id: "statusAenderPopupCommand",
    icon: "fas fa-pencil-alt",
    title: "event.status-aendern-popup",
    isVisibleExpression: "functions.$f_EventEditUtils.isStatusAendernVisible",
    sortIndex: 450,
    execute: () => {
      const statusAendern: StatusAendern = this.form["r_statusAendern"];
      this.selectedKeys = this.getSelectedKeys();
      statusAendern.showPopup(this.onStatusGeaendert.bind(this));
    }
  };

  kopierenOptionenCommand: ICommandData = {
    id: "kopierenCommand",
    icon: "fas fa-copy",
    tooltip: "event.event-kopieren",
    isVisibleExpression: "models.data.$m_Event.Id",
    sortIndex: 995,
    execute: (e) => {
      const eventKopieren: EventKopierenOptionen = this.form["r_kopierenOptionen"];
      const target = e.event.target;
      eventKopieren.showPopover(target);
    }
  };

  exportLinkAnmeldungCommand: ICommandData = {
    id: "exportLinkAnmeldungen",
    icon: "far fa-file-excel",
    tooltip: "base.excel-export",
    sortIndex: 7,
    isVisible: true,
    isEnabled: true,
    execute: async() => {
      this.showHideLinkAnmColumns(true);
      
      const dataGrid: DevExpress.ui.dxDataGrid = this.form["r_eventLinkAnmeldungGrid"].instance;
      dataGrid.exportToExcel(false);
      
      //Das Ausblenden der Spalten wird im "exported"-Event des Grids gemacht, da dies hier sonst zu früh ist.
    }
  }

  filterButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fas fa-filter",
    elementAttr: {
      class: "z--button z--button-round"
    },
    onClick: () => {
      this.getErweiterteSuche()
        .showPopup();
    }
  };

  filterErweitertButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fas fa-caret-down",
    elementAttr: {
      class: "z--button z--button-no-round"
    },
    onClick: (e) => {
      this.showErweiterteSucheContextMenu(e.event);
    }
  };

  meldenBeiSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    acceptCustomValue: true,
    bindingOptions: {
      value: "models.data.$m_Event.Kopf.MeldenBei",
      dataSource: "functions.$f_EventEditUtils.meldenBeiDataSource"
    }
  };

  eventZeitraumFilterSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    showClearButton: true,
    displayExpr: "DisplayText",
    valueExpr: "Id",
    bindingOptions: {
      value: "functions.$f_EventEditUtils.idEventZeitraumFilter",
      dataSource: "functions.$f_EventEditUtils.eventZeitraumFilterDataSource"
    }
  };

  eventTeilnehmerTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    bindingOptions: {
      value: "functions.$f_EventEditUtils.eventTeilnehmerFilter"
    }
  };

  dokumentToolbarOptions: IZitToolbarOptions = {
    title: "event.dokument-toolbar-titel",
    smallToolbar: true,
    items: [{
      id: "showDokumentToolbarOptionen",
      icon: "fas fa-plus",
      execute: async (e) => {
        if (!this.form.models.data.$m_Event.Id) {
          await this.form.save();
        }

        const ctxMenu = new ContextMenu();

        if (this.form["r_dokumentElement"]) {
          ctxMenu.items = [{
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-dokument"),
            execute: () => {
              const dokumentListView: DokumentListView = this.form["r_dokumentElement"];
              dokumentListView.onDokumentAddClicked();
            }
          }, {
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-dokument-erstellen"),
            execute: () => {
              const share: Share = this.form["r_share"];
              share.dokumentLautVorlageErstellen.showPopup(
                [this.form.models.data.$m_Event.Id],
                "TIP.ZIT.Business.Entities.Objekte.Events.Event");
            }
          }];
        } else if (this.form["r_dateien"]) {
          ctxMenu.items = [{
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-dokumente"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onDokumentClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-bilder"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onCameraClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-videos"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onVideoClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-galerie"),
            execute: async () => {
              const r = await this.form.saveIfDirty();
              if (!r.isValid) {
                return;
              }

              const datei: Datei = this.form["r_dateien"];
              datei.onAddToGalerieClick(() => {
                this.form.save();
              });
            }
          }];
        }

        ctxMenu.show(event.target);
      }
    }]
  };

  eventTeilnehmerToolbarOptions: IZitToolbarOptions = {
    title: "event.event-teilnehmer-toolbar-title",
    smallToolbar: true,
    items: [{
      id: "eventTeilnehmerCommand",
      icon: "fas fa-plus",
      execute: (e) => {
        const teilnehmerZuteilenPopup: EventTeilnehmerZuteilen = this.form["r_teilnehmerZuteilenOptionen"];
        teilnehmerZuteilenPopup.show({
          callback: () => {
            this.form.models.reloadAll();
          }
        });
      }
    }]
  };

  @computedFrom("erweiterteSucheWertQuery")
  get isTypAuswahlEnabled(): boolean {
    return this.erweiterteSucheWertQuery
      && this.erweiterteSucheWertQuery.length > 0;
  }

  @computedFrom("canChangeStatus")
  get isStatusAendernVisible() {
    return this._startupService.startupInfo.Benutzer.CanStatusAendern
      && this.canChangeStatus
      && !this._browserService.isMobile;
  }
  
  @computedFrom("form.models.data.$m_Event")
  get canChangeStatus(): boolean {
    if (!this.form.models.data.$m_Event) {
      return true;
    }

    return !!this.form.models.data.$m_Event._CanChangeObjektStatus;
  }

  onExecuteSucheErweitert(event: any): void {
    this.erweiterteSucheWertQuery = event.detail.wertQueryList;
  }

  bind(form: FormBase) {
    this.form = form;
    this.form.callOnBind(() => {
      const zeitraumGridOptions = <DevExpress.ui.dxDataGridOptions>form["r_eventZeitraumGridOptions"];
      const zeitraumColumns = <DevExpress.ui.dxDataGridColumn[]>zeitraumGridOptions.columns;

      zeitraumColumns.forEach((column) => {
        let item = null;
        switch (column.dataField) {
          case ("Kopf.KontingentGesamt"): {
            item = document.createElement("i");
            item.title = "Kontingent gebucht/gesamt";
            item.classList.add("far");
            item.classList.add("fa-check-square");
            break;
          }
          case ("Kopf.InSchnupperterminmaskeAnzeigenValidated"): {
            item = document.createElement("i");
            item.title = "sichtbar in Schnupperterminmaske der PTS SchülerInnen";
            item.classList.add("fas");
            item.classList.add("fa-users");
            break;
          }
          case ("Kopf.WebserviceTerminabfrageValidated"): {
            item = document.createElement("i");
            item.title = "Übertragung Webservice";
            item.classList.add("fas");
            item.classList.add("fa-globe-europe");
            break;
          }
          case ("Kopf.BewerbungsformularSchnupperterminauswahlValidated"): {
            item = document.createElement("i");
            item.title = "im Bewerbungformular anzeigen";
            item.classList.add("far");
            item.classList.add("fa-edit");
            break;
          }
          default: {
            if (column.caption == "KontingentSchule") {
              item = document.createElement("i");
              item.title = "Kontingent Schule";
              item.classList.add("fas");
              item.classList.add("fa-graduation-cap");
            }
            break;
          }
        }
        if (!item) {
          return;
        }

        column.headerCellTemplate = (data, index, element) => {
          data.append(item);
        };
      });

      const linkAnmGridOptions = <DevExpress.ui.dxDataGridOptions>form["r_eventLinkAnmeldungGridOptions"];
      if (linkAnmGridOptions) {
        linkAnmGridOptions.onExported = () => {
          this.showHideLinkAnmColumns(false);
        }

        const linkAnmColumns = <DevExpress.ui.dxDataGridColumn[]>linkAnmGridOptions.columns;

        linkAnmColumns.forEach((column) => {
          if (this._linkAnmHiddenCols.indexOf(column.dataField) >= 0) {
            column.visible = false;
          }
        });
      }

      const websiteAnmeldungLinkOptions: DevExpress.ui.dxTextBoxOptions = form["r_websiteEventAnmeldungLinkOptions"];
      if (websiteAnmeldungLinkOptions) {
        websiteAnmeldungLinkOptions.buttons = [{
          location: "after",
          name: "copy",
          options: {
            icon: "fa far fa-copy",
            disabled: false,
            onClick: () => {
              const link = this.form.models.data.$m_Event._WebsiteEventAnmeldungLink;
              navigator.clipboard.writeText(link);
            }
          }
        }, {
          location: "after",
          name: "open",
          options: {
            icon: "fa fas fa-external-link-alt",
            disabled: false,
            onClick: () => {
              const link = this.form.models.data.$m_Event._WebsiteEventAnmeldungLink;
              window.open(link, "_blank");
            }
          }
        }]
      }
    });

    form.models.onLoaded.register((args) => {
      if (args.model.id == "$m_Event" && args.data) {
        if (args.data.Kopf) {
          if (args.data.Kopf.VeroeffentlichenFilter && args.data.Kopf.VeroeffentlichenFilter.length > 0) {
            this.erweiterteSucheWertQuery = this._jsonService.parse(args.data.Kopf.VeroeffentlichenFilter);

            this.getErweiterteSuche()
              .loadFilter(this.erweiterteSucheWertQuery);
          }
        }
        this.setEventZeitraumFilterDataSource(args.data.Id);
      }
      if (args.model.id == "$m_EventTyp" && args.data) {
        this.setVisiblilyZeitraumGridColumns();
      }
      return Promise.resolve();
    });
    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Event") {
        if (args.binding.bindTo == "Kopf.IdGeschaeftspartner") {
          this.setMeldenBeiDataSource(this.form.models.data.$m_Event.Kopf.IdGeschaeftspartner);
          if (!this.form.models.data.$m_Event.Kopf.Ort) {
            this.geschaeftspartnerValueChanged(this.form.models.data.$m_Event.Kopf.IdGeschaeftspartner);
          }
        }

        if (args.binding.bindTo == "Kopf.IdGeschaeftspartnerSchule") {
          this.setMeldenBeiDataSource(this.form.models.data.$m_Event.Kopf.IdGeschaeftspartnerSchule);
        }

        if (args.binding.bindTo == "Kopf.IdEventTyp" && !this.form.models.data.$m_Event.Id && this.form.models.data.$m_Event.Kopf.IdEventTyp) {
          this.setDefaultValuesFromEventTypToEvent(this.form.models.data.$m_Event.Kopf.IdEventTyp);
        }
      }
    });
    form.onValidating.register((r) => {
      const hasLehrberufPflichtError = this.form.models.data.$m_Event.Kopf.InSchnupperterminmaskeAnzeigen
        && this.form.models.data.$m_EventTyp
        && this.form.models.data.$m_EventTyp.ShowZuteilungLehrberufe
        && !this.form.models.data.$m_Event.Kopf.Lehrberufe?.length;

      if (hasLehrberufPflichtError) {
        r.validationResult.isValid = false;
        r.validationResult.messages.push(this._localizationService.translateOnce("event.lehrberuf-validation-error"));
        return;
      }
      return Promise.resolve();
    });
    form.onSaving.register((args) => {
      args.form.models.data.$m_Event.Kopf.VeroeffentlichenFilter = this.erweiterteSucheWertQuery
        ? this._jsonService.stringify(this.erweiterteSucheWertQuery)
        : null;

      return Promise.resolve();
    });
  }

  onZeitraumKontingentCellPrepared(e) {
    if (e.rowType != "data") {
      return;
    }

    if (!e.data.Kopf.KontingentGesamt) {
      return;
    }

    const cell = <HTMLTableCellElement>e.cellElement;
    cell.textContent = (e.data.Kopf.KontingentGesamtBelegt || 0) + "/" + (e.data.Kopf.KontingentGesamt);
  }

  onZeitraumKontingentSchuleCellPrepared(e) {
    if (e.rowType != "data") {
      return;
    }

    if (!e.data.KontingentSchule) {
      return;
    }

    const cell = <HTMLTableCellElement>e.cellElement;
    cell.textContent = e.data.KontingentSchule;
  }

  onSchnupperterminMaskeCellPrepared(e) {
    if (e.rowType != "data") {
      return;
    }

    if (e.data.Kopf.InSchnupperterminmaskeAnzeigenValidated == null) {
      const cell = <Element>e.cellElement;
      if (!cell.firstElementChild) {
        cell.append(this.getCheckboxElement());
      }
      cell.firstElementChild.classList.add("dx-checkbox-checked");
      cell.firstElementChild.classList.remove("dx-checkbox-indeterminate");
    }
  }

  onWebserviceTerminabfrageCellPrepared(e) {
    if (e.rowType != "data") {
      return;
    }

    if (e.data.Kopf.WebserviceTerminabfrageValidated == null) {
      const cell = <Element>e.cellElement;
      if (!cell.firstElementChild) {
        cell.append(this.getCheckboxElement());
      }
      cell.firstElementChild.classList.add("dx-checkbox-checked");
      cell.firstElementChild.classList.remove("dx-checkbox-indeterminate");
    }
  }

  onBewerbungsformularSchnupperterminauswahlCellPrepared(e) {
    if (e.rowType != "data") {
      return;
    }

    if (e.data.Kopf.BewerbungsformularSchnupperterminauswahlValidated == null) {
      const cell = <Element>e.cellElement;
      if (!cell.firstElementChild) {
        cell.append(this.getCheckboxElement());
      }
      cell.firstElementChild.classList.add("dx-checkbox-checked");
      cell.firstElementChild.classList.remove("dx-checkbox-indeterminate");
    }
  }

  isAnsichtDesktop() {
    return !this._browserService.isMobile;
  }

  private getErweiterteSuche(): ErweiterteSuche {
    return this.form["r_erweiterteSuche"];
  }
  private showErweiterteSucheContextMenu(event): void {
    this.getErweiterteSuche()
      .showVorlageContextMenu(event, true);
  }

  private getCheckboxElement() {
    const item = document.createElement("div");
    item.classList.add("dx-datagrid-checkbox-size");
    item.classList.add("dx-show-invalid-badge");
    item.classList.add("dx-checkbox");
    item.classList.add("dx-checkbox-indeterminate");
    item.classList.add("dx-state-readonly");
    item.classList.add("dx-widget");

    const item2 = document.createElement("div");
    item2.classList.add("dx-checkbox-container");

    const item3 = document.createElement("span");
    item3.classList.add("dx-checkbox-icon");

    item2.append(item3);
    item.append(item2);
    return item;
  }

  private async geschaeftspartnerValueChanged(idGeschaeftspartner: number) {
    if (!idGeschaeftspartner) {
      return;
    }

    const r = await this._restService.get({
      url: `${this._restService.getApiUrl("ZIT/Objekt/GeschaeftspartnerStandardStandortAndGoogleMapsAdresse")}?idGeschaeftspartner=${idGeschaeftspartner}`
    });

    if (!r) {
      return;
    }

    this.form.models.data.$m_Event.Kopf.Ort = r.GoogleKartenAdresse;
  }

  private async setDefaultValuesFromEventTypToEvent(id: number) {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Stammdaten/EventTyp/") + id
    });

    if (!r) {
      return;
    }

    const kopf = this.form.models.data.$m_Event.Kopf;
    kopf.ShowVeranstaltungsmaske = r.ShowVeranstaltungsmaskeVorbelegung;
    
    if (!r.ShowVeroeffentlichung) {
      kopf.Veroeffentlichung = r.Veroeffentlichung;
    }
    
    if (!r.ShowTeilnehmerReaktion) {
      kopf.TeilnehmerReaktion = r.TeilnehmerReaktion;
    }
    
    if (r.ShowCheckBoxInSchnupperterminmaskeAnzeigen == EventTypSichtbarEnumTyp.NichtSichtbarAktiviert) {
      kopf.InSchnupperterminmaskeAnzeigen = true;
    } else if (r.ShowCheckBoxInSchnupperterminmaskeAnzeigen == EventTypSichtbarEnumTyp.NichtSichtbar) {
      kopf.InSchnupperterminmaskeAnzeigen = false;
    }
    
    if (r.ShowCheckBoxWebserviceTerminabfrage == EventTypSichtbarEnumTyp.NichtSichtbarAktiviert) {
      kopf.WebserviceTerminabfrage = true;
    } else if (r.ShowCheckBoxWebserviceTerminabfrage == EventTypSichtbarEnumTyp.NichtSichtbar) {
      kopf.WebserviceTerminabfrage = false;
    }
    
    if (r.ShowCheckBoxBewerbungformularSchnupperterminauswahl == EventTypSichtbarEnumTyp.NichtSichtbarAktiviert) {
      kopf.BewerbungsformularSchnupperterminauswahl = true;
    } else if (r.ShowCheckBoxBewerbungformularSchnupperterminauswahl == EventTypSichtbarEnumTyp.NichtSichtbar) {
      kopf.BewerbungsformularSchnupperterminauswahl = false;
    }
    
    if (!r.ShowZuteilungGeschaeftspartner) {
      kopf.IdGeschaeftspartner = null;
    } else {
      if (!kopf.IdGeschaeftspartner && kopf._IdGeschaeftspartner && !this.isGeschaeftspartnerSchule) {
        kopf.IdGeschaeftspartner = kopf._IdGeschaeftspartner;
        this.geschaeftspartnerValueChanged(kopf.IdGeschaeftspartner);
        this.setMeldenBeiDataSource(kopf.IdGeschaeftspartner);
      }
    }
    
    if (!r.ShowZuteilungGeschaeftspartnerSchule) {
      kopf.IdGeschaeftspartnerSchule = null;
    } else {
      if (!kopf.IdGeschaeftspartnerSchule && kopf._IdGeschaeftspartnerSchule) {
        kopf.IdGeschaeftspartnerSchule = kopf._IdGeschaeftspartnerSchule;
        this.setMeldenBeiDataSource(kopf.IdGeschaeftspartnerSchule);
      }
    }
    
    if (!r.ShowZuteilungPerson) {
      kopf.IdPerson = null;
    }
    
    if (!r.ShowDauer) {
      kopf.Standarddauer = null;
      kopf.Mindestdauer = null;
      kopf.Maximaldauer = null;
    }
    
    if (!r.ShowZuteilungLehrberufe) {
      kopf.Lehrberufe = [];
    }
  }

  private setVisiblilyZeitraumGridColumns() {
    const zeitraumDataGrid = <IdxDataGridComponent>this.form["r_eventZeitraumGrid"];
    const zeitraumColumns = <DevExpress.ui.dxDataGridColumn[]>zeitraumDataGrid.instance.getVisibleColumns();

    zeitraumColumns.forEach((column) => {
      switch (column.dataField) {
        case ("Kopf.InSchnupperterminmaskeAnzeigenValidated"): {
          if (this.form.models.data.$m_EventTyp.ShowCheckBoxInSchnupperterminmaskeAnzeigen != EventTypSichtbarEnumTyp.Sichbar) {
            column.visible = false;
          }
          break;
        }
        case ("Kopf.WebserviceTerminabfrageValidated"): {
          if (this.form.models.data.$m_EventTyp.ShowCheckBoxWebserviceTerminabfrage != EventTypSichtbarEnumTyp.Sichbar) {
            column.visible = false;
          }
          break;
        }
        case ("Kopf.BewerbungsformularSchnupperterminauswahlValidated"): {
          if (this.form.models.data.$m_EventTyp.ShowCheckBoxBewerbungformularSchnupperterminauswahl != EventTypSichtbarEnumTyp.Sichbar) {
            column.visible = false;
          }
          break;
        }
        default: {
          break;
        }
      }
    });
    zeitraumDataGrid.instance.option("columns", zeitraumColumns);
  }

  private onStatusGeaendert(r: IStatusChangeResultData) {
    if (!r.IsValid) {
      DevExpress.ui.dialog.alert(r.Message, this._localizationService.translateOnce("event.fehler"));
    } else {
      this.form.models.reloadAll();
    }
  }

  private getSelectedKeys(): number[] {
    if (this.form.models.data.$m_Event) {
      return [this.form.models.data.$m_Event.Id];
    } else {
      return [];
    }
  }

  private async setMeldenBeiDataSource(id: number) {
    const ansprechpersonList = await this._ansprechpersonService
      .loadAnsprechpersonByGeschaeftspartner(id, ["Name"]);

    this.meldenBeiDataSource = ansprechpersonList.map((c) => c.Name);
  }

  private setEventZeitraumFilterDataSource(idEvent: number) {
    this.eventZeitraumFilterDataSource = this._eventZeitraumService.createEventZeitraumDataSource(this.form.scopeContainer,
      () => {
        return ["IdEvent", idEvent];
      },
      () => { },
      [{ columnName: "Id", sortOrder: 0 }]
    );
  }
  
  private showHideLinkAnmColumns(show: boolean) {
    const grid: IdxDataGridComponent = this.form["r_eventLinkAnmeldungGrid"];
    if (!grid || !grid.instance) {
      return;
    }
    
    grid.instance.beginUpdate();
    
    this._linkAnmHiddenCols.forEach(c => {
      grid.instance.columnOption(c, "visible", show);
    });
    
    grid.instance.endUpdate();
  }
}

export enum EventTypSichtbarEnumTyp {
  NichtSichtbar = 0,
  Sichbar = 1,
  NichtSichtbarAktiviert = 2
}
