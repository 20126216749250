import { autoinject, Scope } from "aurelia-framework";
import { EventAggregator } from 'aurelia-event-aggregator';
import { FormUtilsService } from './form-utils-service';

@autoinject
export class FormInfoService {
  constructor(
    private eventAggregator: EventAggregator,
    private formUtilsService: FormUtilsService
  ) {
    this.registerMainFormModuleId();
  }

  getMainFormModuleId(scope: Scope, treatEditPopupAsMainForm?: boolean): string {
    const mainForm = this.formUtilsService.getMainForm(scope, treatEditPopupAsMainForm);
    if (!mainForm) {
      return null;
    }

    return mainForm.moduleId;
  }

  private registerMainFormModuleId() {
    this.eventAggregator.subscribe("form:get-main-form-module-id", (e) => {
      if (!e) {
        return;
      }
      if (!e.scope) {
        return;
      }

      e.moduleId = this.getMainFormModuleId(e.scope, e.treatEditPopupAsMainForm);
    });
  }
}