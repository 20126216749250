import * as fwx from "../../../../framework/forms/form-export";
import {
    DokumentVersionInformationUtils
} from "./dokument-version-information-utils";

@fwx.autoinject
export class DokumentVersionInformationForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_DokumentUtils: DokumentVersionInformationUtils) {
        super(element, formBaseImport);
        this.id = "dokument-version-information";
        this.title = "dokument-version-information.dokument-version-information_caption";
        this.addModel({
            "id": "$m_Dokument",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "webApiExpand": {
                Zustand: null
            },
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "versionKey",
                "webApiCustomValue": "variables.data.$v_versionKey"
            }]
        });
        this.addVariable({
            "id": "$v_versionKey"
        });
        this.addVariable({
            "id": "$v_isReadOnly"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_DokumentUtils.kameraCommand",
                "bindToFQ": "functions.$f_DokumentUtils.kameraCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_DokumentUtils.videoCommand",
                "bindToFQ": "functions.$f_DokumentUtils.videoCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_DokumentUtils.gallerieCommand",
                "bindToFQ": "functions.$f_DokumentUtils.gallerieCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_DokumentUtils.dateiCommand",
                "bindToFQ": "functions.$f_DokumentUtils.dateiCommand"
            }
        });
        this.addFunction("$f_DokumentUtils", $f_DokumentUtils, "functions.$f_DokumentUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "dokumentVersionListViewToolbarOptions",
                "optionsNameFQ": "dokumentVersionListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Dokument"
            },
            "dataModel": "$m_Dokument",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "dokumentVersionListView",
            "options": {
                "optionsName": "dokumentVersionListViewOptions",
                "optionsNameFQ": "dokumentVersionListViewOptions"
            }
        });
        super.onConstructionFinished();
    }
}