import {PLATFORM} from "aurelia-framework";
export class ModuleLoader {
constructor() {
PLATFORM.moduleName("../framework/login/views/activate-account/activate-account-form");
PLATFORM.moduleName("../framework/login/views/login/login-form");
PLATFORM.moduleName("../framework/login/views/password-reset/password-reset-form");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzer/benutzer-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzer/benutzer-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzer/loginprofil-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzergruppe/benutzergruppe-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzergruppe/benutzergruppe-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzergruppe/objekte-zu-benutzergruppe-edit-form");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzerrolle/benutzerrolle-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzerrolle/benutzerrolle-layout-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzerrolle/benutzerrolle-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzerrolle/benutzerrolle-menu-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzerrolle/benutzerrolle-zu-kommunikation-benutzerrolle-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzerrolle/benutzerrolle-zu-layout-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzerrolle/benutzerrolle-zu-objekt-status-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzerrolle/benutzerrolle-zu-standardfilter-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/benutzerrolle/kopfzeile-verknuepfung-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/codes/codes-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/codes/codes-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/layout/layout-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/layout/layout-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/mandant/channel-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/mandant/mandant-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/mandant/mandant-event-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/mandant/mandant-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/skin/skin-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/berechtigungen/skin/skin-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/dashboard/dashboard-form-form");
PLATFORM.moduleName("../zit/views/entwicklungsziele/entwicklungsziele-list-form");
PLATFORM.moduleName("../zit/views/entwicklungszone/entwicklungszone-list-form");
PLATFORM.moduleName("../zit/views/erklaervideo/erklaervideo-list-form");
PLATFORM.moduleName("../zit/views/nachrichten/nachrichten-list-form");
PLATFORM.moduleName("../zit/views/nachrichten-gruppe/nachrichten-gruppe-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/navigation/navigation-form");
PLATFORM.moduleName("../zit/views/objekte/ansprechperson/ansprechperson-edit-form");
PLATFORM.moduleName("../zit/views/objekte/ansprechperson/ansprechperson-geschaeftspartner-edit-form");
PLATFORM.moduleName("../zit/views/objekte/ansprechperson/ansprechperson-heartbase-edit-form");
PLATFORM.moduleName("../zit/views/objekte/ansprechperson/ansprechperson-kontakt-edit-form");
PLATFORM.moduleName("../zit/views/objekte/ansprechperson/ansprechperson-list-form");
PLATFORM.moduleName("../zit/views/objekte/ansprechperson/ansprechperson-read-form");
PLATFORM.moduleName("../zit/views/objekte/aufgabe/aufgabe-edit-form");
PLATFORM.moduleName("../zit/views/objekte/aufgabe/aufgabe-list-form");
PLATFORM.moduleName("../zit/views/objekte/bewerbung/bewerbung-edit-form");
PLATFORM.moduleName("../zit/views/objekte/bewerbung/bewerbung-einsatzbereich-edit-form");
PLATFORM.moduleName("../zit/views/objekte/bewerbung/bewerbung-ferialer-ersteinstieg-edit-form");
PLATFORM.moduleName("../zit/views/objekte/bewerbung/bewerbung-ferialer-erweiterung-edit-form");
PLATFORM.moduleName("../zit/views/objekte/bewerbung/bewerbung-heartbase-erweiterung-edit-form");
PLATFORM.moduleName("../zit/views/objekte/bewerbung/bewerbung-lehre-edit-form");
PLATFORM.moduleName("../zit/views/objekte/bewerbung/bewerbung-lehre-ersteinstieg-edit-form");
PLATFORM.moduleName("../zit/views/objekte/bewerbung/bewerbung-lehre-erweiterung-edit-form");
PLATFORM.moduleName("../zit/views/objekte/bewerbung/bewerbung-list-form");
PLATFORM.moduleName("../zit/views/objekte/bewerbung/bewerbung-read-form");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-list-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-personenzuteilung-ansprechperson-bewertung-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-personenzuteilung-bestaetigung-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-personenzuteilung-bestaetigung-lehre-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-personenzuteilung-bestaetigung-lehre-read-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-personenzuteilung-bestaetigung-read-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-personenzuteilung-check-in-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-personenzuteilung-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-personenzuteilung-einsatzvorbereitung-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-personenzuteilung-entlohnungsschema-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-personenzuteilung-fragebogen-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-personenzuteilung-gruppierung-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-personenzuteilung-person-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-personenzuteilung-zustand-aendern-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-planstelle-ansprechperson-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-planstelle-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-planstelle-gruppierung-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-planstelle-skill-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/disposition/dispo-planstelle-stelleninserat-edit-form","dispo");
PLATFORM.moduleName("../zit/views/objekte/dokument/dokument-edit-form");
PLATFORM.moduleName("../zit/views/objekte/dokument/dokument-read-form");
PLATFORM.moduleName("../zit/views/objekte/dokument/dokument-unterfertigung-form");
PLATFORM.moduleName("../zit/views/objekte/dokument/dokument-version-information-form");
PLATFORM.moduleName("../zit/views/objekte/event/event-edit-form");
PLATFORM.moduleName("../zit/views/objekte/event/event-link-anmeldung-edit-form","event");
PLATFORM.moduleName("../zit/views/objekte/event/event-list-form");
PLATFORM.moduleName("../zit/views/objekte/event/event-teilnehmer-edit-form");
PLATFORM.moduleName("../zit/views/objekte/event/event-teilnehmer-read-form");
PLATFORM.moduleName("../zit/views/objekte/event/event-zeitraum-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/objekte/event/event-zeitraum-kontingent-edit-form","event");
PLATFORM.moduleName("../zit/views/objekte/event/event-zeitraum-read-form");
PLATFORM.moduleName("../zit/views/objekte/event/event-zeitraum-stelleninserat-edit-form");
PLATFORM.moduleName("../zit/views/objekte/geschaeftspartner/geschaeftspartner-adresse-edit-form");
PLATFORM.moduleName("../zit/views/objekte/geschaeftspartner/geschaeftspartner-bankverbindung-edit-form");
PLATFORM.moduleName("../zit/views/objekte/geschaeftspartner/geschaeftspartner-dokument-edit-form");
PLATFORM.moduleName("../zit/views/objekte/geschaeftspartner/geschaeftspartner-edit-form");
PLATFORM.moduleName("../zit/views/objekte/geschaeftspartner/geschaeftspartner-entlohnungsschema-form");
PLATFORM.moduleName("../zit/views/objekte/geschaeftspartner/geschaeftspartner-gruppierung-form");
PLATFORM.moduleName("../zit/views/objekte/geschaeftspartner/geschaeftspartner-heartbase-edit-form");
PLATFORM.moduleName("../zit/views/objekte/geschaeftspartner/geschaeftspartner-kontakt-edit-form");
PLATFORM.moduleName("../zit/views/objekte/geschaeftspartner/geschaeftspartner-list-form");
PLATFORM.moduleName("../zit/views/objekte/geschaeftspartner/geschaeftspartner-package-edit-form");
PLATFORM.moduleName("../zit/views/objekte/geschaeftspartner/geschaeftspartner-read-form");
PLATFORM.moduleName("../zit/views/objekte/information/information-list-form");
PLATFORM.moduleName("../zit/views/objekte/information/information-read-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-adresse-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-ausbildung-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-ausbildung-erweitert-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-bankverbindung-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-beruf-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-berufserfahrung-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-ferialer-ersteinstieg-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-ferial-read-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-fuehrerschein-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-geschaeftspartner-einsatz-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-heartbase-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-heartbase-read-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-kontakt-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-lehre-ersteinstieg-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-list-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-skill-edit-form");
PLATFORM.moduleName("../zit/views/objekte/person/person-sprache-edit-form");
PLATFORM.moduleName("../zit/views/objekte/profilfirma/geschaeftspartner-auswaehlen-edit-form","profil-firma");
PLATFORM.moduleName("../zit/views/objekte/profilfirma/profil-firma-edit-form","profil-firma");
PLATFORM.moduleName("../zit/views/objekte/profilfirma/profil-firma-gallery-edit-form","profil-firma");
PLATFORM.moduleName("../zit/views/objekte/profilfirma/profil-firma-headerbild-edit-form","profil-firma");
PLATFORM.moduleName("../zit/views/objekte/profilfirma/profil-firma-list-form","profil-firma");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-empleo-edit-form");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-ferial-edit-form");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-ferial-fragebogen-einsatzbereich-edit-form");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-gruppe-edit-form");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-heartbase-edit-form");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-kopf-element-channel-edit-form");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-kumi-edit-form");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-lehre-edit-form");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-lehre-klasse-edit-form");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-lehre-kunde-package-edit-form");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-lehre-person-read-form");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-lehre-standardinserat-edit-form");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-list-form");
PLATFORM.moduleName("../zit/views/objekte/stelleninserat/stelleninserat-read-form");
PLATFORM.moduleName("../zit/views/objekte/termin/termin-edit-form");
PLATFORM.moduleName("../zit/views/objekte/termin/termin-empf-einschaetzung-edit-form");
PLATFORM.moduleName("../zit/views/objekte/termin/termin-heartbase-edit-form");
PLATFORM.moduleName("../zit/views/objekte/termin/termin-heartbase-read-form");
PLATFORM.moduleName("../zit/views/objekte/termin/termin-list-form");
PLATFORM.moduleName("../zit/views/objekte/termin/termin-read-form");
PLATFORM.moduleName("../zit/views/objekte/termin/termin-zustand-aendern-edit-form");
PLATFORM.moduleName("../zit/views/objekte/ziel/ziel-edit-form");
PLATFORM.moduleName("../zit/views/objekte/ziel/ziel-list-form");
PLATFORM.moduleName("../zit/views/schnuppertermin/schnuppertermin-list-form");
PLATFORM.moduleName("../zit/views/stammdaten/adresstyp/adresstyp-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/adresstyp/adresstyp-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/app-einstellung/app-einstellung-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/app-einstellung/app-einstellung-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/app-einstellung/app-menu-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/app-einstellung/app-upload-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/aufgabe/aufgabe-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/aufgabe/aufgabe-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/aufgabengruppe/aufgabengruppe-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/aufgabengruppe/aufgabengruppe-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/aufgabenset/aufgabenset-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/aufgabenset/aufgabenset-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/ausbildung/ausbildung-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/ausbildung/ausbildung-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/ausbildung/geschaeftspartner-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/ausbildung-abschluss/ausbildung-abschluss-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/ausbildung-abschluss/ausbildung-abschluss-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/ausbildungsort/ausbildungsort-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/ausbildungsort/ausbildungsort-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/beruf/beruf-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/beruf/beruf-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/beruf-qualifikation/beruf-qualifikation-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/beruf-qualifikation/beruf-qualifikation-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/bewerbungsgrund/bewerbungsgrund-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/bewerbungsgrund/bewerbungsgrund-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/bewerbungskanal/bewerbungskanal-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/bewerbungskanal/bewerbungskanal-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/branche/branche-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/branche/branche-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/dokumente/dokumente-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/dokumente/dokumente-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/dokumenten-extension/dokumenten-extension-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/dokumenten-extension/dokumenten-extension-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/domaene/domaene-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/domaene/domaene-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/druckvorlage/druckvorlage-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/druckvorlage/druckvorlage-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/druckvorlage/druckvorlage-zu-druckvorlage-paket-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/druckvorlage/druckvorlage-zuteilung-durch-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/druckvorlage-paket/druckvorlage-paket-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/druckvorlage-paket/druckvorlage-paket-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/einsatzvorbereitung/einsatzvorbereitung-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/einsatzvorbereitung/einsatzvorbereitung-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/entlohnungsschema/entlohnungsschema-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/entlohnungsschema/entlohnungsschema-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/erklaerungsvideo/erklaerungsvideo-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/erklaerungsvideo/erklaerungsvideo-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/event-anmeldungsgruppe/event-anmeldungsgruppe-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/event-anmeldungsgruppe/event-anmeldungsgruppe-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/event-typ/event-typ-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/event-typ/event-typ-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/familienstand/familienstand-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/familienstand/familienstand-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/fuehrerscheinklasse/fuehrerscheinklasse-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/fuehrerscheinklasse/fuehrerscheinklasse-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/funktion/funktion-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/funktion/funktion-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/geschaeftspartner-gruppierung/geschaeftspartner-gruppierung-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/geschaeftspartner-gruppierung/geschaeftspartner-gruppierung-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/geschaeftspartner-gruppierungstyp/geschaeftspartner-gruppierungstyp-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/geschaeftspartner-gruppierungstyp/geschaeftspartner-gruppierungstyp-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/geschaeftspartnertyp/geschaeftspartnertyp-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/geschaeftspartnertyp/geschaeftspartnertyp-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/groesse/groesse-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/groesse/groesse-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/grund/grund-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/grund/grund-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/informationstyp/informationstyp-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/informationstyp/informationstyp-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/informationstyp/seiten-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/kollektivvertrag/kollektivvertrag-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/kollektivvertrag/kollektivvertrag-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/kommunikationseinstellung/kommunikationseinstellungbild-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/kommunikationseinstellung/kommunikationseinstellung-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/kommunikationseinstellung/kommunikationseinstellung-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/kontakttyp/kontakttyp-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/kontakttyp/kontakttyp-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/kostenstelle/kostenstelle-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/kostenstelle/kostenstelle-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/lehrberuf/lehrberuf-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/lehrberuf/lehrberuf-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-export-eigenschaft/objekt-export-eigenschaft-csharp-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-export-eigenschaft/objekt-export-eigenschaft-expression-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-export-eigenschaft/objekt-export-eigenschaft-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-marker/objekt-marker-auswaehlen-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-marker/objekt-marker-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-marker/objekt-marker-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-marker/objekt-marker-zus-loginprofil-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-status/benutzer-objekt-status-benutzerrolle-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-status/benutzer-objekt-status-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-status/objekt-status-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-status/objekt-status-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-status/schnittstellenbenutzer-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-status-sammlung/objekt-status-sammlung-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/objekt-status-sammlung/objekt-status-sammlung-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/package/package-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/package/package-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/pfad/pfad-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/pfad/pfad-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/pvtext/pvtext-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/pvtext/pvtext-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/regel/regel-aktion-csharp-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/regel/regel-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/regel/regel-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/schnittstellenbenutzer/schnittstellenbenutzer-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/schnittstellenbenutzer/schnittstellenbenutzer-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/schnittstellenbenutzer/webhook-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/schnupperberuf-geschaeftspartner/schnupperberuf-geschaeftspartner-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/schnupperberuf-geschaeftspartner/schnupperberuf-geschaeftspartner-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/schnuppertermin-anfrageart/schnuppertermin-anfrageart-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/schnuppertermin-anfrageart/schnuppertermin-anfrageart-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/skill/skill-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/skill/skill-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/skill-gruppe/skill-gruppe-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/skill-gruppe/skill-gruppe-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/sprache/sprache-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/sprache/sprache-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/sprachniveau/sprachniveau-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/sprachniveau/sprachniveau-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/staat/staat-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/staat/staat-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/statistikdaten/statistikdaten-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/statistikdaten/statistikdaten-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/stelleninserat-bewerbungstyp/stelleninserat-bewerbungstyp-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/stelleninserat-bewerbungstyp/stelleninserat-bewerbungstyp-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/stelleninserat-gruppentyp/stelleninserat-gruppentyp-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/stelleninserat-gruppentyp/stelleninserat-gruppentyp-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/survey-monkey-fragebogen/survey-monkey-fragebogen-edit-form");
PLATFORM.moduleName("../zit/views/stammdaten/survey-monkey-fragebogen/survey-monkey-fragebogen-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/termintyp/termintyp-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/termintyp/termintyp-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/unternehmen/unternehmen-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/unternehmen/unternehmen-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/unternehmensbereich/unternehmensbereich-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/unternehmensbereich/unternehmensbereich-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/updatehinweis/updatehinweis-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/updatehinweis/updatehinweis-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/verwandtschaftsverhaeltnis/verwandtschaftsverhaeltnis-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/verwandtschaftsverhaeltnis/verwandtschaftsverhaeltnis-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/waehrung/waehrung-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/waehrung/waehrung-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/willkommensseite/willkommensseite-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/willkommensseite/willkommensseite-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/zustand/benutzer-zustand-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/zustand/zustand-edit-form","stammdaten");
PLATFORM.moduleName("../zit/views/stammdaten/zustand/zustand-list-form","stammdaten");
PLATFORM.moduleName("../zit/views/suche/suche-form");
PLATFORM.moduleName("../zit/views/tableau/tableau-list-form","tableau");
PLATFORM.moduleName("../zit-ui/views/app-login/app-login-form");
PLATFORM.moduleName("../zit-ui/views/auto-login/auto-login-form");
PLATFORM.moduleName("../zit-ui/views/login/views/activate-account/activate-account-form");
PLATFORM.moduleName("../zit-ui/views/login/views/login/login-form");
PLATFORM.moduleName("../zit-ui/views/login/views/loginprofil/loginprofil-form");
PLATFORM.moduleName("../zit-ui/views/login/views/login-tan/login-tan-form");
PLATFORM.moduleName("../zit-ui/views/login/views/password-confirm/password-confirm-form");
PLATFORM.moduleName("../zit-ui/views/login/views/password-reset/password-reset-form");
}
}
