import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class StelleninseratFerialFragebogenEinsatzbereichEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "stelleninserat-ferial-fragebogen-einsatzbereich-edit";
        this.title = "stelleninserat-ferial-fragebogen-einsatzbereich-edit.stelleninserat-ferial-fragebogen-einsatzbereich-edit_caption";
        this.addModel({
            "id": "$m_Einsatzbereich",
            "webApiAction": "ZIT/Objekte/StelleninseratFerialbewerbungFragebogenEinsatzbereich",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "stelleninserat-ferial-fragebogen-einsatzbereich-edit.r_einsatzbereich_caption",
            "binding": {
                "dataContext": "$m_Einsatzbereich",
                "bindTo": "Einsatzbereich",
                "bindToFQ": "models.data.$m_Einsatzbereich.Einsatzbereich"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_einsatzbereich",
            "options": {
                "optionsName": "r_einsatzbereichOptions",
                "optionsNameFQ": "r_einsatzbereichOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showSpinButtons": true,
            "min": 0.0,
            "caption": "stelleninserat-ferial-fragebogen-einsatzbereich-edit.r_sortnr_caption",
            "binding": {
                "dataContext": "$m_Einsatzbereich",
                "bindTo": "SortNr",
                "bindToFQ": "models.data.$m_Einsatzbereich.SortNr"
            },
            "validationRules": [],
            "id": "r_sortNr",
            "options": {
                "optionsName": "r_sortNrOptions",
                "optionsNameFQ": "r_sortNrOptions"
            }
        });
        super.onConstructionFinished();
    }
}