import { autoinject } from "aurelia-framework";
import { StartupService } from './startup-service';
import { IObjektBerechtigung } from '../interfaces/export';
import { EventAggregator } from 'aurelia-event-aggregator';
import { BenutzerTyp } from '../enumerations/export';
import { RouterService } from '../../framework/forms/export';

@autoinject
export class BerechtigungService {
  constructor(
    private _eventAggregator: EventAggregator,
    private _startupService: StartupService,
    private _routerService: RouterService
  ) {
    this.registerCanNavigate();
  }

  hasBerechtigung(objektFullName: string): boolean {
    const berecht = this.getBerechtigung(objektFullName);
    return !!berecht;
  }
  hasBerechtigungNew(objektFullName: string): boolean {
    const berecht = this.getBerechtigung(objektFullName);

    return berecht
      && berecht.AllowNew;
  }
  hasBerechtigungModul(moduleId: string): boolean {
    const isAdmin = this._startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.Administrator;
    if (isAdmin) {
      return true;
    }

    if (moduleId.startsWith("zit/views/navigation") && !this._startupService.startupInfo.HasStammdatenMaske) {
      return false;
    }
    else if (this.checkStammdatenBerechtigung(moduleId)) {
      if (!this._startupService.startupInfo.HasAlleStammdatenMasken) {
        if (!this._startupService.startupInfo.StammdatenPraefixList) {
          return false;
        }
        if (this._startupService.startupInfo.StammdatenPraefixList.length == 0) {
          return false;
        }

        return this._startupService.startupInfo.StammdatenPraefixList
          .some(c => moduleId.startsWith(c));
      }
    }

    return true;
  }
  hasBerechtigungRoute(url: string): boolean {
    if (url == "Tableau") {
      return this.hasBerechtigungTableau();
    } else if (url == "Schnuppertermin") {
      return this._startupService.startupInfo.ShowSchnupperterminMaske;
    } else if (url == "Dispo") {
      return this.hasBerechtigung("TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstelle")
        && this.hasBerechtigung("TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstellePersonenzuteilung");
    }

    const route = this._routerService.getRoute(url);
    if (!route) {
      return false;
    }

    return this.hasBerechtigungModul(route.route.moduleId);
  }
  hasBerechtigungTableau(): boolean {
    return this.hasBerechtigung("TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstelle")
      || this.hasBerechtigung("TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstellePersonenzuteilung")
      || this.hasBerechtigung("TIP.ZIT.Business.Entities.Objekte.Termine.Termin")
      || this.hasBerechtigungEvent();
  }
  hasBerechtigungEvent(): boolean {
    return this.hasBerechtigung("TIP.ZIT.Business.Entities.Objekte.Events.Event");
  }

  private getBerechtigung(objektFullName: string): IObjektBerechtigung {
    const berechtList = this._startupService.startupInfo.ObjektBerechtigungList;

    return berechtList
      .find(ber => ber.ObjektFullName == objektFullName);
  }
  private registerCanNavigate() {
    this._eventAggregator.subscribe("router:can-navigate", e => {
      if (!this.hasBerechtigungModul(e.moduleId)) {
        e.cancel = true;
        return;
      }
    });
  }
  private checkStammdatenBerechtigung(moduleId: string) {
    return moduleId.startsWith("zit/views/stammdaten") 
      || moduleId.startsWith("zit/views/berechtigungen");
  }
}
