import {
  autoinject, PLATFORM
} from "aurelia-framework";
import {
  EventAggregator,
  Subscription
} from "aurelia-event-aggregator";
import {
  FileService,
  LocalizationService,
  ShortcutService,
  StyleService,
  PermissionService
} from "./framework/base/export";
import {
  IRoute,
  RouterService,
  RoutesCreatorService,
  HistoryService
} from "./framework/forms/export";
import {
  LoginprofilService
} from './zit/services/export';

import * as routesForm from "./framework-data/forms.json";
import * as routesStructure from "./route-info/structure.json";

import {
  ValidationExService,
  TemplateRegisterService
} from './zit/services/export';
import { ToolbarService } from './framework/forms/services/export';
import { StartupService, ObjektInfoService } from './zit/services/export';
import { LayoutService, WidgetExtenderService } from './zit-ui/export';
import { FormSelectItemService } from './zit/services/form-select-item-service';
import { ContainerViewWidthService } from './zit/services/container-view-width-service';
import { Willkommensseite } from './zit/elements/willkommensseite/willkommensseite';
import { FormExtenderService } from './zit/services/form-extender-service';
import { FormEvalService } from './zit/services/form-eval-service';
import { FormRefreshService } from './zit/services/form-refresh-service';
import { HtmlEditorService } from './framework/forms/services/html-editor-service';
import { HtmlEditorExtensionService } from './zit-ui/services/html-editor-extension-service';
import { BenutzereinstellungenService } from './zit/services/benutzereinstellungen-service';

PLATFORM.moduleName("zit-ui/elements/zit-toolbar/zit-toolbar");

@autoinject
export class App {
  private _routes: IRoute[] = [];

  constructor(
    private router: RouterService,
    private routesCreator: RoutesCreatorService,
    private file: FileService,
    private history: HistoryService,
    private shortcut: ShortcutService,
    private startup: StartupService,
    private layout: LayoutService,
    private localization: LocalizationService,
    private style: StyleService,
    private eventAggregator: EventAggregator,
    private templateRegister: TemplateRegisterService,
    private validationEx: ValidationExService,
    private toolbar: ToolbarService,
    private objektInfo: ObjektInfoService,
    private loginprofil: LoginprofilService,
    private formSelectItem: FormSelectItemService,
    private benutzereinstellungen: BenutzereinstellungenService,
    private containerViewWidth: ContainerViewWidthService,
    private formExtender: FormExtenderService,
    private formEval: FormEvalService,
    private widgetExtenderService: WidgetExtenderService,
    private formRefreshService: FormRefreshService,
    private htmlEditorService: HtmlEditorService,
    private htmlEditorExtensionService: HtmlEditorExtensionService,
    private permissionService: PermissionService
  ) {
    this.loadRoutes();
    this.toolbar.toolbarModuleId = "zit-ui/elements/zit-toolbar/zit-toolbar";
    this.htmlEditorService.defaultHeaders = [3];

    templateRegister.register();
  }

  willkommensseite: Willkommensseite

  async activate() {
    this.layout.activateTheme();
    
    this.permissionService.allowAllPermission = true;
    await this.startup.loadStartupInfo();
    await this.objektInfo.loadObjektInfoList();
    await this.benutzereinstellungen.loadBenutzereinstellungen();
    
    this.registerRoutes();
  }
  attached() {
    const showWillkommen = this.benutzereinstellungen.benutzereinstellungenData.IsWillkommensseiteDeaktiviert != true;
    if (showWillkommen) {
      this.willkommensseite.showPopup();
    }

    window.scrollTo(0, 0);
  }

  private loadRoutes() {
    this._routes = this.orderRoutes(this.routesCreator.createRoutes(
      <any>routesStructure,
      routesForm,
      (r) => {
        return true;
      }));
  }
  private registerRoutes() {
    this._routes.push({
      moduleId: "zit-ui/views/auto-login/auto-login-form",
      caption: "zit-ui.auto-login",
      route: "AutoLogin"
    }, {
      moduleId: "zit-ui/views/app-login/app-login-form",
      caption: "zit-ui.app-login",
      route: "app-login"
    });

    this.router.registerRoutes(this._routes, "Dashboard");
  }

  private orderRoutes(routes: IRoute[]): IRoute[] {
    return routes;
  }
}
