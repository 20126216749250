import { autoinject, transient, TaskQueue, computedFrom } from 'aurelia-framework';
import { FormBase } from '../../../../framework/forms/classes/export';
import { LocalizationService } from '../../../../framework/base/services/export';
import { ICommandData, DialogConfirmService } from '../../../../framework/forms/export';
import { GeschaeftspartnerService } from '../../../services/export';
import { IdxValidationGroupComponent, IdxDataGridComponent } from '../../../interfaces/export';

@autoinject
@transient()
export class GeschaeftspartnerEntlohnungsschemaUtils {
  constructor(
    private geschaeftspartner: GeschaeftspartnerService,
    private localization: LocalizationService,
    private taskqueue: TaskQueue,
    private dialogConfirmService: DialogConfirmService
  ) { }

  form: FormBase;

  addNewEntlohnungsschemaCommand: ICommandData = {
    id: "addNewEntlohnungsschemaCommand",
    icon: "fas fa-plus",
    tooltip: "geschaeftspartner.neues-entlohnungsschema",
    execute: () => {
      const grid: IdxDataGridComponent = this.form["r_entlohungsschemaDataGrid"];
      if (grid && grid.instance) {
        grid.instance.deselectAll();
      }

      this.setEditEntlohnungsschemaData(0);
    }
  };
  saveEntlohungsschemaCommand: ICommandData = {
    id: "saveEntlohungsschemaCommand",
    icon: "fas fa-save",
    tooltip: "base.save",
    execute: () => {
      this.saveEntlohnungsschema();
    }
  };
  deleteEnlohnungsschemaCommand: ICommandData = {
    id: "deleteEnlohnungsschemaCommand",
    icon: "far fa-trash-alt",
    tooltip: "base.delete",
    isEnabled: false,
    execute: () => {
      const id = this.form.models.data.$m_EntlohnungsschemaEdit.Id;
      this.deleteEntlohnungsschema(id);
    }
  };

  onEntlohungsschemaClick(e) {
    this.setEditEntlohnungsschemaData(e.data.Id);
  }

  bind(form: FormBase) {
    this.form = form;
    this.setEditEntlohnungsschemaData(0);
  }

  private refreshEntlohnungsschemaGrid() {
    const grid: IdxDataGridComponent = this.form["r_entlohungsschemaDataGrid"];
    if (grid && grid.instance) {
      grid.instance.refresh();
    }
  }
  private setEditEntlohnungsschemaData(id: number) {
    if (id == void 0) {
      return;
    }
    this.deleteEnlohnungsschemaCommand.isEnabled = id && id != 0;

    const model = this.form.models.getInfo("$m_EntlohnungsschemaEdit");
    this.form.models.loadModel(model, id);
  }

  private async saveEntlohnungsschema() {
    const validationGroup: IdxValidationGroupComponent = this.form["r_entlohungsschemaValidationGroup"];
    const result = validationGroup.instance.validate();
    if (result.isValid) {
      const dataToSave = this.form.models.data.$m_EntlohnungsschemaEdit;
      dataToSave.IdGeschaeftspartner = dataToSave.IdGeschaeftspartner || this.form.variables.data.$v_idGeschaeftspartner;

      this.form.models.data.$m_EntlohnungsschemaEdit = await this.geschaeftspartner.saveEntlohnungsschema(dataToSave);

      this.onEntlohnungsschemaSaved();
    }
  }
  private deleteEntlohnungsschema(id: number) {
    if (id == void 0) {
      return;
    }

    const title = this.localization.translateOnce("zit.loeschen-confirm-title");
    const message = this.localization.translateOnce("zit.loeschen-confirm-message", ["das Entlohnungsschema"]);

    this.dialogConfirmService.show({
      title: title,
      message: message
    }).then(async r => {
      if (!r) {
        return;
      }

      await this.geschaeftspartner.deleteEntlohnungsschema(id);

      this.onEntlohungsschemaDeleted();
    });
  }

  private onEntlohungsschemaDeleted() {
    DevExpress.ui.notify(this.localization.translateOnce("zit.geloescht-message", ["Entlohungsschema"]), "info", 3000);

    this.setEditEntlohnungsschemaData(0);
    this.refreshEntlohnungsschemaGrid();
  }
  private onEntlohnungsschemaSaved() {
    this.refreshEntlohnungsschemaGrid();

    DevExpress.ui.notify(this.localization.translateOnce("base.save_success"), "success", 3000);
  }
}
