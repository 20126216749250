import { autoinject, transient } from "aurelia-framework";
import { FormBase } from "../../../framework/forms/export";
import { AuthorizationService, LocationService } from "../../../framework/base/export";

@autoinject
@transient()
export class AutoLoginFormFuncs {
  private _Form: FormBase;

  constructor(
    private _authorizationService: AuthorizationService,
    private _locationService: LocationService
  ) {}

  bind(form: FormBase) {
    this._Form = form;
    this.tryAutoLogin();
  }

  private async tryAutoLogin() {    
    const key = this.getKeyFromQueryString();
    if (!key) {
      this.redirectToDefault();
      return;
    }

    const r = await this._authorizationService.login({
      Username: "$directlogin$",
      Password: key
    }, false);

    if (!r.isLoggedIn) {
      this.redirectToDefault();
    }

    location.reload();
  }

  private getKeyFromQueryString(): string {
    return this._Form.viewItemInfo?.routeInfo?.parameters?.key;
  }
  private redirectToDefault() {
    if   (this._authorizationService.isLoggedIn) {
      this._locationService.goTo({
        url: "Dashboard",
        clearStack: true
      });
    } else {
      this._locationService.goTo({
        url: "Login",
        clearStack: true
      });
    }
  }
}