import * as moment from "moment";
import {
  autoinject
} from "aurelia-framework";

declare const DevExpress: any;

@autoinject
export class GlobalizationService {
  private groupRegex = /\B(?=(\d{3})+(?!\d))/g;
  private escapeRegex = /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g;
  private current: IGlobalizationProvider;
  private formatters = {};
  private parsers = {};

  constructor() {
    this.setProvider(new GermanGlobalizationProvider());
  }

  setProvider(provider: IGlobalizationProvider) {
    this.current = provider;
    this.formatters = {};
    this.parsers = {};

    const devExpress = this.getDevExpress();
    if (devExpress) {
      devExpress.integration.date.inject({
        getMonthNames: (format) => {
          if (format == "abbreviated") {
            return provider.monthsShort;
          } else {
            return provider.months;
          }
        },
        getDayNames: (format) => {
          if (format == "abbreviated") {
            return provider.daysShort;
          } else {
            return provider.days;
          }
        }
      })
    }
  }

  format(value: any, format: string) {
    return this.getFormatter(format)(value);
  }
  getFormatter(format: string) {
    let formatter = this.formatters[format];

    if (formatter == void (0)) {
      formatter = (value: any) => {
        if (value == void (0)) {
          return null;
        }

        if (format.length === 1 || format.startsWith("DT_")) {
          const momentFormat = format.startsWith("DT_")
            ? format.substr(3)
            : this.current[format];

          return moment(value).locale(this.current.culture).format(momentFormat);
        } else {
          const count = parseInt(format.substr(1)) || 0;
          const formatClass = format.substr(0, 1);

          if (formatClass === "p") {
            value = value * 100;
          }

          const prefix = value < 0 
            ? "-" 
            : "";

          const valueInt = parseInt(value = Math.abs(Number(value) || 0).toFixed(count));
          const valueIntAsString = String(valueInt);
          
          let groupRest;
          groupRest = (groupRest = valueIntAsString.length) > 3 ? groupRest % 3 : 0;

          return prefix 
            + (groupRest ? valueIntAsString.substr(0, groupRest) + this.current.groupSeparator : "") 
            + valueIntAsString.substr(groupRest).replace(/(\d{3})(?=\d)/g, "$1" + this.current.groupSeparator)
            + (count ? this.current.commaSeparator + Math.abs(value - valueInt).toFixed(count).slice(2) : "")
            + (formatClass === "p" ? " %" : "");
        }
      };

      this.formatters[format] = formatter;
    }

    return formatter;
  }
  getParser(format: string) {
    let parser = this.parsers[format];

    if (parser == void (0)) {
      parser = (value: string) => {
        if (value == void (0)) {
          return null;
        }

        if (format.length === 1 || format.startsWith("DT_")) {
          const momentFormat = format.startsWith("DT_")
            ? format.substr(3)
            : this.current[format];

          let result: moment.Moment = null;

          if (value === " ") {
            result = moment().startOf("day");
          } else if (value.startsWith("+") || value.startsWith("-")) {
            const numberAsStr = value.substr(1);
            const numb = parseInt(numberAsStr);
            if (isNaN(numb)) {
              return null;
            }

            const isSubstract = value.startsWith("-");
            if (isSubstract) {
              result = moment().startOf("day").subtract(numb, "days");
            } else {
              result = moment().startOf("day").add(numb, "days");
            }
          } else {
            result = moment(value, momentFormat);
          }

          if (result.isValid()) {
            return result.toDate();
          } else {
            return null;
          }
        } else {
          const groupFinder = this.current.groupSeparator.replace(this.escapeRegex, "\\$&");
          value = value
            .replace(new RegExp(groupFinder, "g"), "")
            .replace(new RegExp("%", "g"), "")
            .replace(new RegExp(" ", "g"), "");

          const indexOf = value.indexOf(this.current.commaSeparator);

          let b = value;
          let a = "";
          if (indexOf >= 0) {
            b = value.substr(0, indexOf);
            a = value.substr(indexOf + 1);
          }

          const count = parseInt(format.substr(1));
          const formatClass = format.substr(0, 1);

          switch (formatClass) {
            case "f":
            case "n": {
              return parseInt(b) + this.makeComma(a);
            }
            case "p": {
              return (parseInt(b) + this.makeComma(a)) / 100;
            }
            default: {
              throw new Error(`Not implemented format ${format}`);
            }
          }
        }
      };

      this.parsers[format] = parser;
    }

    return parser;
  }
  getFormatterParser(format: string): any {
    return {
      formatter: this.getFormatter(format),
      parser: this.getParser(format)
    };
  }
  getNumberFormat(format: string): string {
    if (format.length < 2) {
      throw new Error(`Invalid number format ${format}`);
    }

    const f = format.substr(0, 1);
    const n = parseInt(format.substr(1));

    let nk = "";
    if (n > 0) {
      nk = "." + "0".repeat(n);
    }

    switch (f) {
      case "f":
      case "F": {
        return `#0${nk}`;
      }
      case "n":
      case "N": {
        return `#,##0${nk}`;
      }
      case "p":
      case "P": {
        return `#,##0${nk} %`;
      }
      default: {
        throw new Error(`Invalid number format ${format}`);
      }
    }
  }

  private getDevExpress(): any {
    const w: any = window;
    return w.DevExpress;
  }
  private makeComma(value: string) {
    return parseInt(value) / Math.pow(10, value.length);
  }
}

export interface IGlobalizationProvider {
  culture: string;

  d: string;
  D: string;
  e: string;
  E: string;
  f: string;
  F: string;
  g: string;
  G: string;
  t: string;
  T: string;

  commaSeparator: string;
  groupSeparator: string;

  months: string[];
  monthsShort: string[];
  days: string[];
  daysShort: string[];
}

export class GermanGlobalizationProvider implements IGlobalizationProvider {
  culture = "de";

  d = "DD.MM.YYYY";
  D = "dddd, DD. MMM YYYY";
  e = "dd., DD.MM.YYYY";
  E = "dd., DD.MM.YYYY HH:mm";
  f = "dddd, DD. MMM YYYY, HH:mm";
  F = "dddd, DD. MMM yyyy, HH:mm:ss";
  g = "DD.MM.YYYY HH:mm";
  G = "DD.MM.YYYY HH:mm:ss";
  t = "HH:mm";
  T = "HH:mm:ss";

  commaSeparator = ",";
  groupSeparator = ".";

  months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
  monthsShort = ["Jan.", "Feb.", "März", "Apr.", "Mai", "Juni", "Juli", "Aug.", "Sep.", "Okt.", "Nov.", "Dez."];
  days = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
  daysShort = ["SO", "MO", "DI", "MI", "DO", "FR", "SA"]
}
