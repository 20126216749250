import * as fwx from "../../../../framework/forms/form-export";
import {
    TerminEditUtils
} from "./termin-edit-utils";

@fwx.autoinject
export class TerminEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_TerminEditUtils: TerminEditUtils) {
        super(element, formBaseImport);
        this.id = "termin-edit";
        this.title = "termin-edit.termin-edit_caption";
        this.addModel({
            "id": "$m_Termin",
            "webApiAction": "ZIT/Objekte/Termin",
            "webApiExpand": {
                'Empfaenger': {
                    'expand': {
                        'Person': null
                    }
                },
                'Kopf': {
                    'expand': {
                        'Benutzer': null,
                        'TerminBestaetigungZustand': null,
                        'Lehrberufe': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "addVerteilungstypVeroeffentlichenEmpfaenger",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_TerminEmpfaengerElement",
            "webApiAction": "ZIT/Objekte/TerminEmpfaengerElement",
            "webApiExpand": {
                'Person': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ZustandAendern",
            "webApiAction": "ZIT/Stammdaten/Zustand",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Lehrberuf",
            "webApiAction": "ZIT/Stammdaten/Lehrberuf",
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "idTerminKopfElement",
                "webApiCustomValue": "models.data.$m_Termin.IdTerminKopfElement"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_TerminEditUtils.actionCommand",
                "bindToFQ": "functions.$f_TerminEditUtils.actionCommand"
            }
        });
        this.addEditPopup({
            "idContent": "zustandAendernEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_ZustandAendern",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_ZustandAendern.Id"
                }
            }, {
                "to": "$v_zustandDatum",
                "binding": {
                    "dataContext": "$m_Termin",
                    "bindTo": "Kopf.TerminBestaetigungZustandDatum",
                    "bindToFQ": "models.data.$m_Termin.Kopf.TerminBestaetigungZustandDatum",
                    "propertyPrefix": "Kopf"
                }
            }, {
                "to": "$v_idTermin",
                "binding": {
                    "dataContext": "$m_Termin",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_Termin.Id"
                }
            }],
            "id": "zustandAendernEditPopup",
            "options": {
                "optionsName": "zustandAendernEditPopupOptions",
                "optionsNameFQ": "zustandAendernEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addFunction("$f_TerminEditUtils", $f_TerminEditUtils, "functions.$f_TerminEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "terminTyp",
            "filters": [],
            "caption": "termin-edit.r_termintyp_caption",
            "isReadOnlyExpression": "models.data.$m_Termin.Kopf.Id",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.IdTerminTyp",
                "bindToFQ": "models.data.$m_Termin.Kopf.IdTerminTyp",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_terminTyp",
            "options": {
                "optionsName": "r_terminTypOptions",
                "optionsNameFQ": "r_terminTypOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "terminVerteilungstyp",
            "filters": [],
            "caption": "termin-edit.r_verteilungstyp_caption",
            "isReadOnlyExpression": "!!models.data.$m_Termin.Kopf._IsVerteilungstypReadonly || functions.$f_TerminEditUtils.isTypAuswahlEnabled",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.Verteilungstyp",
                "bindToFQ": "models.data.$m_Termin.Kopf.Verteilungstyp",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_verteilungstyp",
            "options": {
                "optionsName": "r_verteilungstypOptions",
                "optionsNameFQ": "r_verteilungstypOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "termin-edit.r_veroeffnetlichenfiltergeschaeftspartner_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.VeroeffentlichenFilterGeschaeftspartner",
                "bindToFQ": "models.data.$m_Termin.Kopf.VeroeffentlichenFilterGeschaeftspartner",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_veroeffnetlichenFilterGeschaeftspartner",
            "options": {
                "optionsName": "r_veroeffnetlichenFilterGeschaeftspartnerOptions",
                "optionsNameFQ": "r_veroeffnetlichenFilterGeschaeftspartnerOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "person",
            "filters": [],
            "caption": "termin-edit.r_empfaengerpersonselect_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "$f_TerminEditUtils.idSelectedEmpfaenger",
                "bindToFQ": "functions.$f_TerminEditUtils.idSelectedEmpfaenger"
            },
            "validationRules": [],
            "id": "r_empfaengerPersonSelect",
            "options": {
                "optionsName": "r_empfaengerPersonSelectOptions",
                "optionsNameFQ": "r_empfaengerPersonSelectOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "empfaengerReaktion",
            "filters": [],
            "caption": "termin-edit.r_empfaengerreaktion_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.EmpfaengerReaktion",
                "bindToFQ": "models.data.$m_Termin.Kopf.EmpfaengerReaktion",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_empfaengerReaktion",
            "options": {
                "optionsName": "r_empfaengerReaktionOptions",
                "optionsNameFQ": "r_empfaengerReaktionOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "geschaeftspartner",
            "filters": [],
            "caption": "termin-edit.r_geschaeftspartner_caption",
            "isReadOnlyExpression": "models.data.$m_Termin.Id",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.IdGeschaeftspartner",
                "bindToFQ": "models.data.$m_Termin.Kopf.IdGeschaeftspartner",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartner",
            "options": {
                "optionsName": "r_geschaeftspartnerOptions",
                "optionsNameFQ": "r_geschaeftspartnerOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "geschaeftspartner",
            "filter": ["IsGeschaeftspartnerSchule", true],
            "filters": [],
            "caption": "termin-edit.r_geschaeftspartnerschule_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.IdGeschaeftspartnerSchule",
                "bindToFQ": "models.data.$m_Termin.Kopf.IdGeschaeftspartnerSchule",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_geschaeftspartnerSchule",
            "options": {
                "optionsName": "r_geschaeftspartnerSchuleOptions",
                "optionsNameFQ": "r_geschaeftspartnerSchuleOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "termin-edit.r_betreff_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.Betreff",
                "bindToFQ": "models.data.$m_Termin.Kopf.Betreff",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_betreff",
            "options": {
                "optionsName": "r_betreffOptions",
                "optionsNameFQ": "r_betreffOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "termin-edit.r_bezeichnungintern_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.BezeichnungIntern",
                "bindToFQ": "models.data.$m_Termin.Kopf.BezeichnungIntern",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bezeichnungIntern",
            "options": {
                "optionsName": "r_bezeichnungInternOptions",
                "optionsNameFQ": "r_bezeichnungInternOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "termin-edit.r_ort_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.Ort",
                "bindToFQ": "models.data.$m_Termin.Kopf.Ort",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_ort",
            "options": {
                "optionsName": "r_ortOptions",
                "optionsNameFQ": "r_ortOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "termin-edit.r_startdatum_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.StartDatum",
                "bindToFQ": "models.data.$m_Termin.Kopf.StartDatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_TerminEditUtils.onStartDatumChanged",
            "id": "r_startDatum",
            "options": {
                "optionsName": "r_startDatumOptions",
                "optionsNameFQ": "r_startDatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "termin-edit.r_startuhrzeit_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.StartUhrzeit",
                "bindToFQ": "models.data.$m_Termin.Kopf.StartUhrzeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_startUhrzeit",
            "options": {
                "optionsName": "r_startUhrzeitOptions",
                "optionsNameFQ": "r_startUhrzeitOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "termin-edit.r_endedatum_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.EndeDatum",
                "bindToFQ": "models.data.$m_Termin.Kopf.EndeDatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_endeDatum",
            "options": {
                "optionsName": "r_endeDatumOptions",
                "optionsNameFQ": "r_endeDatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "termin-edit.r_endeuhrzeit_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.EndeUhrzeit",
                "bindToFQ": "models.data.$m_Termin.Kopf.EndeUhrzeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_endeUhrzeit",
            "options": {
                "optionsName": "r_endeUhrzeitOptions",
                "optionsNameFQ": "r_endeUhrzeitOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "termin-edit.r_meldenum_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.MeldenUm",
                "bindToFQ": "models.data.$m_Termin.Kopf.MeldenUm",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_meldenUm",
            "options": {
                "optionsName": "r_meldenUmOptions",
                "optionsNameFQ": "r_meldenUmOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showClearButton": true,
            "min": 0.0,
            "caption": "termin-edit.r_maxteilnehmerzal_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.MaxTeilnehmerzahl",
                "bindToFQ": "models.data.$m_Termin.Kopf.MaxTeilnehmerzahl",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_maxTeilnehmerzal",
            "options": {
                "optionsName": "r_maxTeilnehmerzalOptions",
                "optionsNameFQ": "r_maxTeilnehmerzalOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "termin-edit.r_ignorebyrestservice_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.IgnoreTerminBeiRestRequest",
                "bindToFQ": "models.data.$m_Termin.Kopf.IgnoreTerminBeiRestRequest",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_ignoreByRestService",
            "options": {
                "optionsName": "r_ignoreByRestServiceOptions",
                "optionsNameFQ": "r_ignoreByRestServiceOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "termin-edit.r_isbewerbungschnupperterminauswahl_caption",
            "isReadOnlyExpression": "models.data.$m_Termin.Kopf.Verteilungstyp != 1",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.IsBewerbungSchnupperterminAuswahl",
                "bindToFQ": "models.data.$m_Termin.Kopf.IsBewerbungSchnupperterminAuswahl",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_isBewerbungSchnupperterminAuswahl",
            "options": {
                "optionsName": "r_isBewerbungSchnupperterminAuswahlOptions",
                "optionsNameFQ": "r_isBewerbungSchnupperterminAuswahlOptions"
            }
        });
        this.widgetCreator.addTagBox(this, {
            "caption": "termin-edit.r_lehrberufe_caption",
            "dataContext": "$m_Termin",
            "batchSelectionEnabled": true,
            "relationBinding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.Lehrberufe",
                "bindToFQ": "models.data.$m_Termin.Kopf.Lehrberufe",
                "propertyPrefix": "Kopf"
            },
            "relationProperty": "IdLehrberuf",
            "itemDataContext": "$m_Lehrberuf",
            "itemValueExpr": "Id",
            "itemDisplayExpr": "Bezeichnung",
            "binding": {
                "dataContext": "$m_Termin"
            },
            "validationRules": [],
            "id": "r_lehrberufe",
            "options": {
                "optionsName": "r_lehrberufeOptions",
                "optionsNameFQ": "r_lehrberufeOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "caption": "termin-edit.r_bemerkung_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.Bemerkung",
                "bindToFQ": "models.data.$m_Termin.Kopf.Bemerkung",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bemerkung",
            "options": {
                "optionsName": "r_bemerkungOptions",
                "optionsNameFQ": "r_bemerkungOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "caption": "termin-edit.r_bemerkungschule_caption",
            "binding": {
                "dataContext": "$m_Termin",
                "bindTo": "Kopf.BemerkungSchule",
                "bindToFQ": "models.data.$m_Termin.Kopf.BemerkungSchule",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bemerkungSchule",
            "options": {
                "optionsName": "r_bemerkungSchuleOptions",
                "optionsNameFQ": "r_bemerkungSchuleOptions"
            }
        });
        super.onConstructionFinished();
    }
}