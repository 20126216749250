import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { RestService } from "../../framework/base/services/rest-service";

@autoinject
export class NachrichtVorlageService {
  private readonly URL = "ZIT/Stammdaten/NachrichtVorlage";
  
  constructor(
    private _eventAggregator: EventAggregator,
    private _restService: RestService
  ) {
  }
  
  getVorlagen(): Promise<INachrichtVorlage[]> {
    return this._restService.get({
      url: this._restService.getWebApiUrl(this.URL),
      getOptions: {
        expand: {
          "Dateien": null
        }
      }
    });
  }
  
  async saveVorlage(vorlage: INachrichtVorlage): Promise<INachrichtVorlage> {
    const r = await this._restService.post({
      url: this._restService.getWebApiUrl(this.URL),
      data: vorlage,
      getOptions: {
        expand: {
          "Dateien": null
        }
      },
      increaseLoadingCount: true
    });
    
    this.refreshVorlagen();
    return r;
  }
  
  async deleteVorlage(vorlage: INachrichtVorlage): Promise<any> {
    const r = await this._restService.delete({
      url: this._restService.getWebApiUrl(this.URL),
      id: vorlage.Id,
      increaseLoadingCount: true
    });

    this.refreshVorlagen();
    return r;
  }
  
  private refreshVorlagen() {
    this._eventAggregator.publish("nachricht:vorlagen-changed", {});
  }
}

export interface INachrichtVorlage {
  Id?: number;
  Bezeichnung?: string;
  Text?: string;
  Signatur?: string;

  Dateien: INachrichtVorlageDatei[];
}
export interface INachrichtVorlageDatei {
  Dateiname: string;
  DMSLink: string;
}
