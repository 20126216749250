import { autoinject, transient, computedFrom } from 'aurelia-framework';
import { FormBase } from './../../../../framework/forms/classes/export';
import { FileService, BrowserService } from '../../../../framework/base/services/export';
import { IZitTagOptions } from '../../../../zit-ui/elements/zit-tag/export';
import { ICommandData, PopupInfoService } from '../../../../framework/forms/export';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DateiAuswaehlen } from '../../../elements/datei-auswaehlen/datei-auswaehlen';

@autoinject
@transient()
export class DokumentVersionInformationUtils {
  constructor(
    private _fileService: FileService,
    private _eventAggregator: EventAggregator,
    private _popupInfoService: PopupInfoService,
    private _browserService: BrowserService,
  ) { }

  form: FormBase;
  isMobil: boolean = this._browserService.isMobile;

  dokumentVersionTagOptions: IZitTagOptions = {
    icon: {
      faIconExpression: `item.Zustand  && item.Zustand.TagSymbol ? item.Zustand.TagSymbol: ""`,
      textExpression: `(!item.Zustand  || !item.Zustand.TagSymbol)? "D": ""`
    },
    textExpression: `item.Dateiname 
      ? globalization.format(item.Anlagedatum, "g") + " " + item.Dateiname
      : globalization.format(item.Anlagedatum, "g")`,
    tooltipExpression: `item.Zustand && item.Zustand.Bezeichnung ? item.Zustand.Bezeichnung : ""`,
    infoList: [
      {
        isVisibleExpression: `item.IsAktuelleVersion`,
        icon: {
          faIcon: "fas fa-chevron-circle-right"
        },
        backgroundColor: "green"
      }
    ],
    onClick: (e, data) => {
      if (!data.DMSLinkDokument) {
        return;
      }

      this._fileService.inline(data.DMSLinkDokument);
    }
  };  

  kameraCommand: ICommandData = {
    id: "kameraCommand",
    icon: "fas fa-camera",
    tooltip: "dokument-utils.kamera",
    isVisibleExpression: "functions.$f_DokumentUtils.isMobil && functions.$f_DokumentUtils.isAddVersionVisible",
    execute: (args) => {
      this.getDateiAuswaehlen().onMakePhoto();
    }
  };
  videoCommand: ICommandData = {
    id: "videoCommand",
    icon: "fas fa-video",
    tooltip: "dokument-utils.video",
    isVisibleExpression: "functions.$f_DokumentUtils.isMobil && !models.data.$m_Dokument.Id",
    execute: (args) => {
      this.getDateiAuswaehlen().onMakeVideo();
    }
  };
  gallerieCommand: ICommandData = {
    id: "gallerieCommand",
    icon: "far fa-image",
    tooltip: "dokument-utils.gallerie",
    isVisibleExpression: "functions.$f_DokumentUtils.isMobil && functions.$f_DokumentUtils.isAddVersionVisible",
    execute: (args) => {
      this.getDateiAuswaehlen().onOpenGallery();
    }
  };
  dateiCommand: ICommandData = {
    id: "dateiCommand",
    icon: "far fa-file-alt",
    tooltip: "dokument-utils.datei-auswaehlen",
    isVisibleExpression: "!functions.$f_DokumentUtils.isMobil && functions.$f_DokumentUtils.isAddVersionVisible",
    execute: (args) => {
      this.getDateiAuswaehlen().onOpenFiles();
    }
  };

  @computedFrom("form.variables.data.$v_isReadOnly", "form.models.data.$m_Dokument.CanSave")
  get isAddVersionVisible() {
    if (!this.form) {
      return false;
    }

    return this.form.variables.data.$v_isReadOnly === false 
      && this.form.models.data.$m_Dokument
      && this.form.models.data.$m_Dokument.Id;
  }

  bind(form: FormBase) {
    this.form = form;
  }

  async onDateiAusgewaehlt(e: CustomEvent) {
    const file: File = e.detail.files[0];
    const model = this.form.models.data.$m_Dokument;

    const dmsLinkDokument = await this._fileService.upload(file);
    if (!dmsLinkDokument) {
      return;
    }

    const oldId = model.Id;
    model.Id = 0;
    model.Dateiname = file.name;
    model.DMSLinkDokument = dmsLinkDokument;

    const r = await this.form.rest.put({
      url: this.form.rest.getWebApiUrl("ZIT/Objekte/DokumentElement"),
      data: model,
      increaseLoadingCount: true
    });

    if (!r || !r.Id) {
      return;
    }

    this._eventAggregator.publish("dokument:version-changed", {
      oldId: oldId,
      newId: r.Id
    });

    this._popupInfoService.closeCurrentPopup();
  }
  private getDateiAuswaehlen(): DateiAuswaehlen {
    return this.form["r_dateiAuswaehlen"];
  }
}
