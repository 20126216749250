import * as fwx from "../../../../framework/forms/form-export";
import {
    ObjekteZuBenutzergruppeUtils
} from "./objekte-zu-benutzergruppe-utils";

@fwx.autoinject
export class ObjekteZuBenutzergruppeEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_ObjekteZuBenutzergruppe: ObjekteZuBenutzergruppeUtils) {
        super(element, formBaseImport);
        this.id = "objekte-zu-berechtigung-edit";
        this.title = "objekte-zu-berechtigung-edit.objekte-zu-berechtigung-edit_caption";
        this.addModel({
            "id": "$m_ObjZuBen",
            "webApiAction": "ZIT/Berechtigungen/ObjektZuBenutzergruppe",
            "webApiExpand": {
                'Benutzergruppe': null
            },
            "webApiWhere": ["IdObjekt", {
                "isBound": true,
                "expression": "variables.data.$v_idObjekt"
            }],
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$v_idObjekt"
        });
        this.addVariable({
            "id": "$v_titel"
        });
        this.addFunction("$f_ObjekteZuBenutzergruppe", $f_ObjekteZuBenutzergruppe, "functions.$f_ObjekteZuBenutzergruppe");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_objZuBenToolbarOptions",
                "optionsNameFQ": "r_objZuBenToolbarOptions"
            },
            "caption": "objekte-zu-berechtigung-edit.r_objzuben_caption",
            "binding": {
                "dataContext": "$m_ObjZuBen"
            },
            "dataModel": "$m_ObjZuBen",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_objZuBen",
            "options": {
                "optionsName": "r_objZuBenOptions",
                "optionsNameFQ": "r_objZuBenOptions"
            }
        });
        super.onConstructionFinished();
    }
}