import { autoinject, bindable, Scope, observable } from "aurelia-framework";

@autoinject
export class ZButton {
  constructor(
    private _element: Element
  ) {}

  @bindable icon: string = "";
  @bindable text: string = "";
  @bindable orientation: "left" | "right" = "left";
  @bindable backgroundColor: string = "";
  @bindable @observable selected: boolean = false;
  @bindable selectedBackgroundColor: string = "";
  @bindable color: string = "";
  @bindable selectedColor: string = "";
  @bindable tooltip: string = "";
  @bindable isRound: boolean = true;

  buttonCss: any = {};

  bind() {
    this.udpateButtonCss();
  }

  onClick() {
    this._element.dispatchEvent(new CustomEvent(
      "on-button-click", {
        bubbles: true
      }
    ));
  }

  selectedChanged() {
    this.udpateButtonCss();
  }

  private udpateButtonCss() {
    const newStyle: any = {};

    if (this.selected && this.selectedBackgroundColor) {
      newStyle["background-color"] = this.selectedBackgroundColor;
    } else if (!this.selected && this.backgroundColor) {
      newStyle["background-color"] = this.backgroundColor;
    }

    if (this.selected && this.selectedColor) {
      newStyle["color"] = this.selectedColor;
    } else if (!this.selected && this.color) {
      newStyle["color"] = this.color;
    }

    this.buttonCss = newStyle;
  }
}