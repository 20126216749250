import { Geschlecht } from '../../zit/enumerations/export';

export class ZitPersonNameValueConverter {
  toView(model: any, format: NameFormat): string {
    if (model == void 0) {
      return "";
    }
    const data = model.Personendaten || model;

    switch (format) {
      case NameFormat.short:
        return this.shortName(data);
      case NameFormat.mitGeschlecht:
        return this.shortMitGeschlecht(data);
      case NameFormat.long:
        return this.longName(data);
      default:
        return data;
    }
  }

  private longName(data: any) {
    const name = this.shortName(data);
    const anrede = this.getGeschlect(data.Geschlecht);

    const result = `${this.getStringValueOrEmpty(anrede)}
    ${this.getStringValueOrEmpty(data.TitelVorne)}
    ${name}
    ${this.getStringValueOrEmpty(data.TitelHinten)}`;

    return result;
  }
  private shortName(data: any) {
    return `${data.Vorname} ${data.Nachname}`;
  }
  private shortMitGeschlecht(data: any) {
    const geschlecht = this.getStringValueOrEmpty(this.getGeschlect(data.Geschlecht), " ", true);

    return `${geschlecht}${data.Vorname} ${data.Nachname}`;
  }

  private getGeschlect(typ: Geschlecht) {
    if (typ == void 0) {
      return "";
    }

    switch (typ) {
      case Geschlecht.Maennlich: {
        return "Herr";
      }
      case Geschlecht.Weiblich: {
        return "Frau";
      }
      default: {
        return "";
      }
    }
  }
  private getStringValueOrEmpty(str: string, suffixValue: string = " ", isPrefix: boolean = false) {
    if (str == void 0) {
      return "";
    }


    return isPrefix
      ? str + suffixValue
      : suffixValue + str;
  }
}

export enum NameFormat {
  short = "short",
  mitGeschlecht = "mitGeschlecht",
  long = "long"
}