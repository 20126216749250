import { autoinject } from 'aurelia-framework';

@autoinject
export class AsyncService {
  constructor() {}

  convertToPromise<T>(p: Promise<T> & JQueryPromise<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!p.then) {
        return resolve(p);
      }

      p.then(r => {
        resolve(r);
      }).catch(e => {
        reject(e);
      });
    });
  }
}
