import { autoinject, transient } from 'aurelia-framework';
import { AdresseService } from '../../../services/adresse-service';

@autoinject
@transient()
export class GeschaeftspartnerAdresseEditUtils {
  constructor(
    private adresse: AdresseService
  ) { }

  onPLZChanged(e) {
    const model = e.model.bindingContext.models.data.$m_GeschaeftspartnerAdresse;
    const idPLZ = e.value;

    if (!model || !model.IdLand) {
      return;
    }

    this.adresse.getOrtFromPLZ(idPLZ, model.IdLand)
      .then(r => {
        if (!r || !r.Ort) {
          return;
        }

        model.Ort = r.Ort;
      });
  }
}
