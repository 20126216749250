import { autoinject } from "aurelia-framework";
import { DataSourceService } from "../../framework/base/services/data-source-service";
import { ScopeContainer } from "../../framework/base/classes/scope-container";

@autoinject
export class ZielService {
  constructor(
    private _dataSourceService: DataSourceService) { }

  loadZieleDataSource(scopeContainer: ScopeContainer, getCustomWhere: { () }, getCustomFilter: {()}, orderBy?: any[]) {
    const data = this._dataSourceService.createDataSource(
      scopeContainer,
      {
        webApiAction: "ZIT/Objekte/Ziel",
        webApiOrderBy: orderBy
      }, {
      getCustomWhere: getCustomWhere,
      getCustomFilters: getCustomFilter
    });
    return data;
  }
}
