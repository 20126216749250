import { StartupService } from './../../../services/startup-service';
import { IEditorValueChangedEventArgs } from './../../../../framework/forms/event-args/editor-value-changed';
import { autoinject, transient } from "aurelia-framework";
import { FormBase } from './../../../../framework/forms/classes/form-base';
import { RestService } from '../../../../framework/base/export';
import { BenutzerTyp } from '../../../enumerations/export';

@autoinject
@transient()
export class StelleninseratLehreKundePackageEditUtils {
  constructor(private restService: RestService, private startupService: StartupService) {
  }

  form: FormBase;

  showAdminEinstellungen = this.startupService.startupInfo.Benutzer.Benutzertyp === BenutzerTyp.Administrator;

  bind(form: FormBase) {
    this.form = form;

    form.models.onLoaded.register((args) => {
      if (args.model.id === "$m_Stelleninserat") {
        if (args.data.Id || args.data.Id > 0 || !args.data.Kopf) {
          return;
        }

        args.data.Kopf.IsAktiv = true;

        if (!args.data.Kopf.IdGeschaeftspartner || args.data.Kopf.IdGeschaeftspartner <= 0) {
          return;
        }

        this.adresseAndGoogleMapsAdresseVorbelegen(args.data.Kopf.IdGeschaeftspartner);
      }

      return Promise.resolve();
    });

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Stelleninserat") {
        if (args.binding.bindTo == "Kopf.IdGeschaeftspartner") {
          this.geschaeftspartnerValueChanged(args);
        }
      }

      return Promise.resolve();
    });
  };

  geschaeftspartnerValueChanged(args: IEditorValueChangedEventArgs): void {
    if (!args.value) {
      return;
    }

    this.adresseAndGoogleMapsAdresseVorbelegen(args.value);
  }

  private adresseAndGoogleMapsAdresseVorbelegen(idGeschaeftspartner: number): void {
    if (!idGeschaeftspartner) {
      return;
    }

    this.restService.get({
      url: `${this.restService.getApiUrl("ZIT/Objekt/GeschaeftspartnerStandardStandortAndGoogleMapsAdresse")}?idGeschaeftspartner=${idGeschaeftspartner}`
    }).then(r => {
      if (!r) {
        return;
      }

      this.form.models.data.$m_Stelleninserat.Kopf.Standort = r.Standort;
      this.form.models.data.$m_Stelleninserat.Kopf.GoogleKartenAdresse = r.GoogleKartenAdresse;
    });
  }
}
