import { SucheSpaltenAuswahl } from "./../suche-spalten-auswahl/suche-spalten-auswahl";
import { autoinject, bindable, bindingMode, observable, OverrideContext, Scope, TaskQueue } from "aurelia-framework";
import { LocalizationService } from "./../../../framework/base/services/export";
import { IdxDataGridComponent, IdxPopupComponent, IdxValidationGroupComponent } from "../../interfaces/export";
import { LoginprofilEinstellungenService } from "../../services/export";
import {
  ContextMenu,
  DialogConfirmService,
  ICommandData,
  SimpleWidgetCreatorService
} from "../../../framework/forms/export";
import { GlobalizationService, ScopeContainer } from "../../../framework/base/export";
import { ObjektExportEigenschaftService } from "../../services/objekt-export-eigenschaft-service";
import { ILoginprofilEinstellung, LoginprofilEinstellungTyp } from "../../services/loginprofil-einstellungen-service";

@autoinject
export class SucheLayoutAuswahl {
  private _savedLayout: ILoginprofilEinstellung[] = [];
  private _saveMode: SaveMode;

  constructor(
    private _element: Element,
    private _globalizationService: GlobalizationService,
    private _localizationService: LocalizationService,
    private _loginprofilEinstellungenService: LoginprofilEinstellungenService,
    private _objektExportEigenschaftService: ObjektExportEigenschaftService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _taskQueue: TaskQueue,
    private _dialogConfirmService: DialogConfirmService
  ) { }

  @bindable dataGrid: IdxDataGridComponent;
  @bindable @observable objektFullName: string;
  @bindable defaultColumns: DevExpress.ui.dxDataGridColumn[];
  @bindable({ defaultBindingMode: bindingMode.twoWay }) additionalColumns: string[];
  @bindable typ: LoginprofilEinstellungTyp = LoginprofilEinstellungTyp.Suchlayout;
  @bindable canSetStandard: boolean = false;
  @bindable canSaveAlways: boolean = false;
  @bindable showResetLayout: boolean = false;

  scope: Scope;
  scopeContainer: ScopeContainer;

  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {};
  
  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [
      { type: "required" }
    ]
  };

  options: IPopupOptions;

  sucheSpaltenAuswahl: SucheSpaltenAuswahl;

  idCurrentLayout: number;
  activeGridLayoutDic: any = {};

  speichernPopupCommands: ICommandData[] = [{
    id: "sucheLayoutSave",
    idCategory: "$start",
    icon: "fas fa-save",
    tooltip: "base.save",
    sortIndex: 0,
    execute: () => {
      this.saveCurrentLayout();
    }
  }, {
    id: "sucheLayoutDelete",
    idCategory: "$start",
    icon: "far fa-trash-alt",
    tooltip: "base.delete",
    sortIndex: 5,
    isEnabledExpression: "idCurrentLayout",
    isVisibleExpression: "options.canLoeschen",
    execute: () => {
      this.deleteCurrentLayout();
    }
  }];
  speichernPopup: IdxPopupComponent;
  speichernPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxHeight: "80%",
    maxWidth: "420px"
  };

  bezeichnungTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    bindingOptions: {
      value: "options.bezeichnung"
    }
  };
  isStandardCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Standardlayout?",
    bindingOptions: {
      value: "options.isStandard"
    }
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "speichernLayoutPopupToolbar",
      caption: "suche-layout-auswahl.popup-title",
      options: this.speichernPopupOptions,
      commands: this.speichernPopupCommands,
      scopeContainer: this.scopeContainer
    });

    if (!this.additionalColumns) {
      this.additionalColumns = [];
    }

    this._taskQueue.queueMicroTask(() => {
      this.dataGrid.setOption({ allowColumnReordering: true });      
      this.objektFullNameChanged(this.objektFullName, null);
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  objektFullNameChanged(newValue, oldValue) {
    if (newValue == oldValue) {
      return;
    }

    const layout = this.objektFullName
      ? this.activeGridLayoutDic[this.objektFullName]
      : null;

    if (!layout || this.typ == LoginprofilEinstellungTyp.Tableau) {
      this.resetCurrentLayout();
      
      if (this.typ != LoginprofilEinstellungTyp.Tableau) {
        this.updateGridColumns(layout);
      }
    } else {
      this.assignLayoutToGrid(layout);
    }
  }

  assignLayoutToGrid(data: ILoginprofilEinstellung, updateColumns: boolean = true) {
    this.activeGridLayoutDic[data.Entitaet] = data;
    this.idCurrentLayout = data.Id;

    if (updateColumns) {
      this.updateGridColumns(data);
    }

    this.dispatchLayoutLoadedEvent(data);
    this.dispatchLayoutChangedEvent();
  }

  getCurrentGridColumns(): any[] {
    const columns: DevExpress.ui.dxDataGridColumn[] = this.dataGrid.instance.option("columns");

    if (columns) {
      columns.map((col, index) => {
        col.visibleIndex = this.dataGrid.instance.columnOption(index, "visibleIndex");
        col.width = this.dataGrid.instance.columnOption(index, "width");
        col.sortIndex = this.dataGrid.instance.columnOption(index, "sortIndex");
        col.sortOrder = this.dataGrid.instance.columnOption(index, "sortOrder");

        if (col.width && col.width.toString().indexOf("px") < 0) {
          col.width = `${col.width}px`;
        }
      });

      return columns;
    }

    return [];
  }
  async getStandardLayout(objektFullName: string) {
    const result: ILoginprofilEinstellung[] = await this._loginprofilEinstellungenService.loadLoginprofilEinstellung({
      Typ: this.typ,
      Entitaet: objektFullName,
      IsStandard: true
    });

    if (result.length === 0) {
      return null;
    }

    return result[0];
  }

  async showCtxMenu(event: Event) {
    const result = await this.loadSavedLayouts();

    const ctxMenu = new ContextMenu();
    this._savedLayout = result;
    
    ctxMenu.items.push({
      text: this._localizationService.translateOnce("suche-layout-auswahl.spaltenauswahl"),
      execute: () => {
        this.showSucheSpaltenAuswahl();
      }
    });

    if (this.showResetLayout) {
      ctxMenu.items.push({
        text: this._localizationService.translateOnce("suche-layout-auswahl.reset_original"),
        execute: () => {
          this.resetLayout();
        }
      });
    }

    if (this.canSaveAlways || this.additionalColumns.length > 0) {
      if (this.idCurrentLayout) {
        ctxMenu.items.push({
          text: this._localizationService.translateOnce("suche-layout-auswahl.layout_speichern_loeschen"),
          execute: () => {
            this.showSpeichernPopup();
          }
        });
      }

      ctxMenu.items.push({
        text: this._localizationService.translateOnce("suche-layout-auswahl.layout_speichern_unter"),
        execute: () => {
          this.showSpeichernUnterPopup();
        }
      });
    }

    const firstGroupIndex = ctxMenu.items.length - 1;

    if (ctxMenu.items.length > 1) {
      ctxMenu.items[1].beginGroup = true;
    }

    const hasSavedLayout = this._savedLayout.length > 0;
    if (hasSavedLayout) {
      this._savedLayout.forEach((layout) => {
        ctxMenu.items.push({
          text: layout.Bezeichnung,
          execute: () => {
            this.assignLayoutToGrid(layout);
          }
        });
      });
  
      ctxMenu.items[firstGroupIndex + 1].beginGroup = true;
    }

    ctxMenu.show(event.target);
  }

  onSpaltenChanged() {
    this.dispatchLayoutChangedEvent();
  }

  private resetLayout() {
    const currentLayout = this.getCurrentLayout(this.objektFullName);
    
    this.assignLayoutToGrid({
      Entitaet: this.objektFullName,
      Typ: this.typ,
      Id: currentLayout?.Id || 0,
      Bezeichnung: currentLayout?.Bezeichnung || "",
      IsStandard: currentLayout?.IsStandard || false,
      Wert: {
        Columns: [],
        Custom: null
      }
    }, true);
  }
  private showSpeichernPopup() {
    this._saveMode = SaveMode.Save;
    const currentLayout = this.getCurrentLayout();

    this.options = {
      bezeichnung: currentLayout
        ? currentLayout.Bezeichnung
        : null,
      canLoeschen: !!this.idCurrentLayout,
      titel: "Layout speichern",
      isStandard: currentLayout
        ? currentLayout.IsStandard
        : false
    };

    this.speichernPopup.instance.show();
  }
  private showSpeichernUnterPopup() {    
    this._saveMode = SaveMode.Create;

    this.options = {
      bezeichnung: null,
      canLoeschen: false,
      titel: "Layout speichern unter",
      isStandard: false
    };
    
    this.speichernPopup.instance.show();
  }
  private showSucheSpaltenAuswahl() {
    this.sucheSpaltenAuswahl.showPopup();
  }
  
  private getCurrentLayout(objektFullName?: string) {
    objektFullName = objektFullName || this.objektFullName;

    if (!objektFullName) {
      return null;
    }

    const current: ILoginprofilEinstellung = this.activeGridLayoutDic[objektFullName];
    return current;
  }
  private resetCurrentLayout() {
    this.idCurrentLayout = null;
    this.options = null;
    this.activeGridLayoutDic[this.objektFullName] = null;
    this.additionalColumns = [];
  }

  private loadSavedLayouts() {
    return this._loginprofilEinstellungenService.loadLoginprofilEinstellung({
      Typ: this.typ,
      Entitaet: this.objektFullName,
    });
  }
  private async saveCurrentLayout() {
    const columns = this._objektExportEigenschaftService.getExportGridColumns(this.dataGrid.instance);

    const data: ILoginprofilEinstellung = {
      Id: this._saveMode == SaveMode.Create ? null : this.idCurrentLayout,
      Bezeichnung: this.options.bezeichnung,
      IsStandard: this.options.isStandard,
      Typ: this.typ,
      Entitaet: this.objektFullName,
      Wert: {
        Columns: columns,
        Custom: null
      }
    };

    this.dispatchLayoutSavingEvent(data);

    const layout = await this._loginprofilEinstellungenService.saveOrCreateEinstellung(data);
    this.assignLayoutToGrid(layout, false);

    DevExpress.ui.notify(
      this._localizationService.translateOnce("suche-layout-auswahl.layout_gespeichert_message"), 
      "success", 
      3000);

    this.speichernPopup.instance.hide();
  }
  private async deleteCurrentLayout() {
    if (!this.idCurrentLayout) {
      return;
    }

    const dialogResult = await this._dialogConfirmService.show({
      title: this._localizationService.translateOnce("base.question"),
      message: this._localizationService.translateOnce("suche-layout-auswahl.layout_loeschen_message")
    });

    if (!dialogResult) {
      return;
    }
    
    await this._loginprofilEinstellungenService.deleteEinstellung(this.idCurrentLayout);

    DevExpress.ui.notify(
      this._localizationService.translateOnce("suche-layout-auswahl.layout_geloescht_message"), 
      "success", 
      3000);

    this.speichernPopup.instance.hide();
    
    this.resetCurrentLayout();
    this.dispatchLayoutDeletedEvent();
  }

  private updateGridColumns(layout: ILoginprofilEinstellung) {
    const layoutColumns = this.getGridColumns(layout);
    
    if (layoutColumns.length === 0) {
      layoutColumns.push(...(this.defaultColumns || []));
    }

    this.additionalColumns = layoutColumns
      .filter((c) => c.dataField.startsWith("Prop"))
      .map((c) => c.dataField.substr(4));

    this.dataGrid.setOption({ columns: layoutColumns });
  }
  private getGridColumns(layout: ILoginprofilEinstellung): DevExpress.ui.dxDataGridColumn[] {
    if (!layout || !layout.Wert) {
      return [];
    }

    return layout.Wert.Columns
      .map((c) => {
        if (c.format) {
          c.format = this._globalizationService.getFormatter(c.format);
        }
        if (c.dataField === "Anlagedatum") {
          c.format = this._globalizationService.getFormatter("g");
        }

        if (c.dataField.startsWith("Prop")) {
          c.allowSorting = false;
        }

        return c;
      });
  }

  private dispatchLayoutChangedEvent() {
    const event = new CustomEvent("on-layout-changed", {
      detail: {
        sender: this
      },
      bubbles: true
    });

    this._element.dispatchEvent(event);
  }
  private dispatchLayoutDeletedEvent() {
    const event = new CustomEvent("on-layout-deleted", {
      detail: {
        sender: this
      },
      bubbles: true
    });

    this._element.dispatchEvent(event);
  }
  private dispatchLayoutLoadedEvent(data: ILoginprofilEinstellung) {
    const event = new CustomEvent("on-layout-loaded", {
      detail: {
        sender: this,
        data: data
      },
      bubbles: true
    });

    this._element.dispatchEvent(event);
  }
  private dispatchLayoutSavingEvent(data: ILoginprofilEinstellung) {
    const event = new CustomEvent("on-layout-saving", {
      detail: {
        sender: this,
        data: data
      },
      bubbles: true
    });

    this._element.dispatchEvent(event);
  }
}

interface IPopupOptions {
  titel: string;
  canLoeschen: boolean;
  bezeichnung: string;
  isStandard: boolean;
}
enum SaveMode {
  Save = 0,
  Create = 1
}
