import { autoinject, bindable } from 'aurelia-framework';

import { DraggingService } from './zit-dragging-service';

import "./drag-and-drop.less";
@autoinject
export class ZitDragAndDropCustomAttribute {
  constructor(
    private dragging: DraggingService,
    private element: Element
  ) { }

  @bindable({ primaryProperty: true }) options: ZitDragAndDropOptions;
  @bindable data: any;

  draggableElement: Element;

  attached() {
    this.draggableElement = this.getClosest(this.element, "list-view-item");

    this.draggableElement.setAttribute("draggable", "true");

    this.draggableElement.addEventListener("dragstart", this.onDragStart.bind(this), false);
    this.draggableElement.addEventListener("dragenter", this.onDragEnter.bind(this), false);
    this.draggableElement.addEventListener("dragover", this.onDragOver.bind(this), false);
    this.draggableElement.addEventListener("dragleave", this.onDragLeave.bind(this), false);
    this.draggableElement.addEventListener("drop", this.onDrop.bind(this), false);

    this.draggableElement.addEventListener("dragend", this.onDragEnd.bind(this), false);
  }

  onDragStart(e: DragEvent) {
    const hasSelected = this.draggableElement.querySelector(".list-view-item-is-selected");

    this.dragging.draggingData = this.data;

    if (hasSelected) {
      const listViewContent = this.getClosest(this.draggableElement, "list-view-content");
      const selected = Array.from(listViewContent.querySelectorAll(".list-view-item-is-selected"));

      selected.forEach(c => c.classList.add("z--drag-origin"));
    } else {
      this.draggableElement.classList.add("z--drag-origin");
    }
  }

  onDragEnter(e: DragEvent) {
    const isAllow = this.options.allowDropFrom.some(c => c === this.dragging.objektTypeFullName);
    if (!isAllow) {
      return true;
    }

    return false;
  }
  onDragOver(e: DragEvent) {
    const isAllow = this.options.allowDropFrom.some(c => c === this.dragging.objektTypeFullName);
    const listViewContent = this.getClosest(this.draggableElement, "list-view-content");

    if (!isAllow) {
      const dropTarget = Array.from(listViewContent.querySelectorAll(".z--drop-target"));

      dropTarget.forEach(c => c.classList.remove("z--drop-target"));
      return true;
    }

    const selected = Array.from(listViewContent.querySelectorAll(".list-view-item-is-selected"));

    if (!selected || !selected.length) {
      this.draggableElement.classList.add("z--drop-target");
    
      e.preventDefault();
      return false;
    }

    const isSelected = this.draggableElement.querySelectorAll(".list-view-item-is-selected").length > 0;

    if (!isSelected) {
      selected.forEach(c => c.classList.remove("z--drop-target"));
      
      return true;
    }

    selected.forEach(c => c.classList.add("z--drop-target"));

    e.preventDefault();
    return false;
  }
  onDragLeave(e: any) {
    const target = this.getClosest(e.srcElement, "list-view-item");

    const isSelected = this.draggableElement.querySelectorAll(".list-view-item-is-selected").length;

    if (isSelected) {
      const listViewContent = this.getClosest(this.draggableElement, "list-view-content");
      const dropTarget = Array.from(listViewContent.querySelectorAll(".z--drop-target"));

      dropTarget.forEach(c => c.classList.remove("z--drop-target"));
    } else {
      this.draggableElement.classList.remove("z--drop-target");
    }

  }
  onDrop(e: DragEvent) {
    if (this.options.dropHandler) {
      this.options.dropHandler(this.data, this.dragging.draggingData);
    }
  }
  onDragEnd(e: DragEvent) {
    const drops = Array.from(document.getElementsByClassName("z--drop-target"));
    const drags = Array.from(document.getElementsByClassName("z--drag-origin"));

    drops.forEach(c => c.classList.remove("z--drop-target"));
    drags.forEach(c => c.classList.remove("z--drag-origin"));

    this.dragging.draggingData = null;
  }

  private getClosest(e: Element, classString: string): Element {
    if (e.classList.contains(classString)) {
      return e;
    }

    let parent = null;
    while (e) {
      parent = e.parentElement;
      if (parent && parent.classList.contains(classString)) {
        return parent;
      }

      e = parent;
    }

    return null;
  }
}

export interface ZitDragAndDropOptions {
  allowDropFrom: string[];
  dropHandler?: { (dropTo: any, dragFrom: any) };
}