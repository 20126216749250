import { JsonService } from './../../framework/base/services/json-service';
import { autoinject } from 'aurelia-framework';
import { RestService } from './../../framework/base/services/export';
import { IPersonDossierData } from '../interfaces/export';
import { RestGetResultType } from '../../framework/base/services/rest-service';

@autoinject
export class DownloadService {
  constructor(
    private json: JsonService,
    private rest: RestService
  ) { }

  downloadExcelFromGrid(data: { IdList: number[], ColumnList: DevExpress.ui.dxDataGridColumn[] }, defaultFileName: string = "data.xlsx"): Promise<any> {
    if (!data || !data.IdList) {
      return Promise.resolve();
    }
    
    return this.download(
      this.rest.getApiUrl("ZIT/Export/Get"),
      data,
      defaultFileName
    );
  }
  downloadExcelFromTableau(tableauQuery: any): Promise<any> {
    return this.download(
      this.rest.getApiUrl("ZIT/Tableau/Export"),
      tableauQuery,
      "export.xlsx"
    );
  }
  downloadPersonDossier(data: IPersonDossierData, defaultFileName: string = "personen-dossier.pdf"): Promise<any> {
    return this.download(
      this.rest.getApiUrl("ZIT/PersonenDossier/PersonenDossier"),
      data,
      defaultFileName
    );
  }
  downloadSchnuppertermine(data: any): Promise<any> {
    return this.download(
      this.rest.getApiUrl("ZIT/Schnuppertermin/SchnupperterminReportGruppiert"),
      data,
      "schnuppertermine.pdf"
    );
  }
  async download(url: string, data: any, filename: string, errorCallback?: {(): void}): Promise<any> {
    if (!data) {
      return Promise.resolve(false);
    }

    try {
      const r = await this.rest.post({
        url: url,
        data: data,
        increaseLoadingCount: true,
        resultType: RestGetResultType.blob,
        includeResponseHeaders: true
      });

      const headers: Headers = r.headers;
      const content: Blob = r.data;
      this.openFile(headers, content, filename);
    } catch {
      if (errorCallback) {
        errorCallback();
      }
    }
  }

  private openFile(headers: Headers, data: Blob, defaultFileName: string) {
    const contentDisposition = headers.get("Content-Disposition");
    const contentType = headers.get("Content-Type");

    let fileName = defaultFileName;

    if (contentDisposition) {
      const indexOf = contentDisposition.indexOf("filename=");
      if (indexOf >= 0) {
        fileName = contentDisposition.substr(indexOf + 9);
        
        if (fileName.includes(";")) {
          const indexOfEnd = fileName.indexOf(";");
          fileName = fileName.substr(0, indexOfEnd);
        }
      }
    }

    fileName = decodeURIComponent(fileName);

    const blob = new Blob([data], { type: contentType });

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }, 1000);
    }
  }
}
