import { autoinject } from "aurelia-framework";
import { RestService } from "./../../framework/base/services/export";

@autoinject
export class ZustandService {
  constructor(
    private _restService: RestService
  ) { }

  getFolgezustandTerminEmpf(keys: number[]): Promise<any> {
    if (!keys || !keys.length) {
      return;
    }

    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Zustand/ZustandFolgeZustand"),
      data: keys,
      increaseLoadingCount: true
    });
  }
  getZustandList(bereich: ZustandBereich, status?: ZustandStatus): Promise<IZustand[]> {
    const where: any[] = [["Bereich", bereich]];

    if (status != void(0)) {
      where.push(["Status", status]);
    }

    return this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Stammdaten/Zustand"),
      getOptions: {
        where: where,
        orderBy: [{columnName: "SortNr", sortOrder: 0}, {columnName: "Bezeichnung", sortOrder: 0}]
      }
    });
  }
}

export interface IZustand {
  Id: number;
  Code: string;
  Bezeichnung: string;
  Bereich: ZustandBereich;
  Status: ZustandStatus;
  Farbe: string;
  TagSymbol: string;
  SortNr: number;
}
export enum ZustandBereich {
  Information = 0,
  Fragebogen = 1,
  Dokument = 2,
  PersonenzuteilungCheckIn = 3,
  PersonenzuteilungFragebogenArbeitssicherheit = 4,
  PersonenzuteilungEinsatzpapiere = 5,
  TerminBestaetigungGeschaeftspartner = 6,
  TerminEmpfaengerStatus = 7
}
export enum ZustandStatus {
  Offen = 0,
  ErledigtPositiv = 1,
  ErledigtNegativ = 2
}