import { FrameworkConfiguration, PLATFORM } from "aurelia-framework";
import "./styles/styles.less";
import "./styles/popup.less";

export function configure(config: FrameworkConfiguration) { 
  config
  .globalResources(PLATFORM.moduleName("./elements/zit-card/zit-card"))
  .globalResources(PLATFORM.moduleName("./elements/zit-tag/zit-tag"))
  .globalResources(PLATFORM.moduleName("./value-converters/address-value-converter"))
  .globalResources(PLATFORM.moduleName("./value-converters/ansprechperson-value-converter"))
  .globalResources(PLATFORM.moduleName("./value-converters/person-name-value-converter"))
  .globalResources(PLATFORM.moduleName("./value-converters/bankverbindung-value-converter"))
  .globalResources(PLATFORM.moduleName("./value-converters/enumeration-value-converter"))
  .globalResources(PLATFORM.moduleName("./value-converters/geschlecht-value-converter"))
  .globalResources(PLATFORM.moduleName("./value-converters/restrict-length-value-converter"))
  .globalResources(PLATFORM.moduleName("./value-converters/moment-value-converter"))
  .globalResources(PLATFORM.moduleName("./value-converters/format-date-ex-value-converter"))
  .globalResources(PLATFORM.moduleName("./value-converters/pattern-value-converter"))
  .globalResources(PLATFORM.moduleName("./value-converters/default-short-objekttyp-bezeichnung-value-converter"))
  .globalResources(PLATFORM.moduleName("./value-converters/title-value-converter"))
  .globalResources(PLATFORM.moduleName("./value-converters/minuten-text-value-converter"))
  .globalResources(PLATFORM.moduleName("./value-converters/initialen-value-converter"));
}
