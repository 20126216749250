import {
  autoinject
} from "aurelia-framework";
import {
  FormBase
} from "../classes/form-base";
import {
  BaseWidgetCreatorService
} from "./base-widget-creator-service";
import {
  GlobalizationService,
  LocalizationService
} from "../../base/services/export";
import {
  SelectionModeEnum
} from "../enums/selection-mode-enum";
import {
  EnumItemService
} from "../services/export";
import {
  IListViewOptions
} from "../elements/list-view/list-view-options";
import * as WidgetOptions from "../widget-options/export";
import { ListViewPagingMode } from '../elements/list-view/list-view-paging-mode';
import { ListType } from '../enums/list-type-enum';

@autoinject
export class ListViewWidgetCreatorService {
  constructor(
    private baseWidgetCreator: BaseWidgetCreatorService,
    private globalization: GlobalizationService,
    private localization: LocalizationService,
    private enumItem: EnumItemService
  ) { }

  addListView(form: FormBase, options: WidgetOptions.IListViewOptions) {
    const listViewOptions: IListViewOptions = this.baseWidgetCreator.createWidgetOptions(form, options);

    if (options.dataModel && !options.binding.bindTo) {
      const dataSource = this.baseWidgetCreator.createListDataSource(form, options, listViewOptions);
      listViewOptions.dataSource = dataSource;
    }
    else if (options.binding.bindToFQ) {
      const dataSource = new DevExpress.data.DataSource(new DevExpress.data.CustomStore({
        cacheRawData: false,
        loadMode: "raw",
        load: () => {
          return form.binding.evaluate(
            form.scope,
            options.binding.bindToFQ
          ) || [];
        }
      }));
      
      dataSource.requireTotalCount(true);
      listViewOptions.dataSource = dataSource;

      form.binding.observe({
        scopeContainer: form.scopeContainer,
        expression: options.binding.bindToFQ,
        callback: (newVal) => {
          dataSource.load();
        }
      });
    }

    listViewOptions.groupProperty = options.groupProperty;

    this.baseWidgetCreator.setListClickActions(form, options);
    const customOptions = this.baseWidgetCreator.getCustomOptions(form, options);

    if (customOptions.hasClickActions > 0) {
      listViewOptions.hoverStateEnabled = true;

      listViewOptions.onItemClick = (e) => {
        customOptions.clickActions.forEach(item => {
          item(e, listViewOptions.dataSource);
        });

        if ((options.selectionMode || "none") === "none") {
          customOptions.openClickActions.forEach(item => {
            item(e, listViewOptions.dataSource);
          });
        }
      };
    }

    if (options.pagingMode) {
      switch (options.pagingMode) {
        case "loadNext": {
          listViewOptions.pagingMode = ListViewPagingMode.loadNext;
          break;
        }
        case "paging": {
          listViewOptions.pagingMode = ListViewPagingMode.paging;
          break;
        }
        default: {
          break;
        }
      }
    }

    if (options.height) {
      listViewOptions.height = options.height;
    }

    if (options.itemClass) {
      listViewOptions.itemClass = options.itemClass;
    }

    if (options.selectionMode) {
      listViewOptions.selectionMode = this.getSelectionMode(options.selectionMode);
    }

    if (options.showPagerInfo) {
      listViewOptions.showPagerInfo = options.showPagerInfo;
    }
    if (options.showReloadButton) {
      listViewOptions.showReloadButton = options.showReloadButton;
    }
    if (options.useDefaultListItemStyle != void (0)) {
      listViewOptions.useDefaultListItemStyle = options.useDefaultListItemStyle;
    }

    if (options.pageSize) {
      if (listViewOptions.dataSource) {
        listViewOptions.dataSource.pageSize(options.pageSize);
      }

      listViewOptions.pageSize = options.pageSize
    }

    this.baseWidgetCreator.checkListToolbar(form, options, null, ListType.ListView);
    this.baseWidgetCreator.checkListRelationEdit(form, options);
  }

  private getSelectionMode(selectionMode: SelectionModeEnum): string {
    switch (selectionMode) {
      case SelectionModeEnum.Multiple:
        return "multiple";
      case SelectionModeEnum.Single:
        return "single";
      default:
        return "none";
    }
  }
} 
