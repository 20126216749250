import { GlobalizationService } from "./../../../../framework/base/services/globalization-service";
import { computedFrom, transient } from "aurelia-framework";
import { autoinject } from "../../../../framework/forms/form-export";
import { JsonService } from "./../../../../framework/base/services/json-service";
import { LocalizationService } from "./../../../../framework/base/services/localization-service";
import { ContextMenu } from "./../../../../framework/forms/classes/context-menu";
import { FormBase } from "./../../../../framework/forms/classes/form-base";
import { ICommandData } from "./../../../../framework/forms/interfaces/command-data";
import { IZitToolbarOptions } from "./../../../../zit-ui/elements/zit-toolbar/zit-toolbar-options";
import { Datei } from "./../../../elements/datei/datei";
import { DokumentListView } from "./../../../elements/dokument-list-view/dokument-list-view";
import { ErweiterteSuche } from "./../../../elements/erweiterte-suche/erweiterte-suche";
import { Share } from "./../../../elements/share/share";
import { AnsprechpersonService } from "./../../../services/ansprechperson-service";

@autoinject
@transient()
export class EventZeitraumEditUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    private _jsonService: JsonService,
    private _localizationService: LocalizationService,
    private _ansprechpersonService: AnsprechpersonService,
    private _globalizationService: GlobalizationService) {
  }

  form: FormBase;
  erweiterteSucheWertQuery: any;
  meldenBeiDataSource: string[];

  startUhrzeitValidationRule: DevExpress.ui.CustomRule = {
    type: "custom",
    reevaluate: true,
    validationCallback: (e) => {
      if (!this.form.models.data.$m_EventZeitraum || !this.form.models.data.$m_Event) {
        return true;
      }
      if (!this.form.models.data.$m_EventZeitraum.Kopf.StartUhrzeit && !this.form.models.data.$m_Event.Kopf.StartUhrzeit) {
        return false;
      }
      return true;
    }
  };
  endeDatumValidationRule: DevExpress.ui.CustomRule = {
    type: "custom",
    reevaluate: true,
    validationCallback: (e) => {
      if (!this.form.models.data.$m_EventZeitraum ) {
        return true;
      }
      if (!this.form.models.data.$m_EventZeitraum.Kopf.StartDatum || !this.form.models.data.$m_EventZeitraum.Kopf.EndeDatum) {
        return true;
      }
      
      const start = this.form.models.data.$m_EventZeitraum.Kopf.StartDatum.getTime(); 
      const ende = this.form.models.data.$m_EventZeitraum.Kopf.EndeDatum.getTime();
      
      if (start > ende) {
        e.rule.message = "Ende-Datum darf nicht vor Start-Datum liegen";
        return false;
      }
        
      return true;
    }
  };
  endeUhrzeitValidationRule: DevExpress.ui.CustomRule = {
    type: "custom",
    reevaluate: true,
    validationCallback: (e) => {
      if (!this.form.models.data.$m_EventZeitraum || !this.form.models.data.$m_Event) {
        return true;
      }
      if (!this.form.models.data.$m_EventZeitraum.Kopf.EndeUhrzeit && !this.form.models.data.$m_Event.Kopf.EndeUhrzeit) {
        return false;
      }
      return true;
    }
  };
  anmeldeschlussDatumValidationRule: DevExpress.ui.CustomRule = {
    type: "custom",
    reevaluate: true,
    message: "Datum und Uhrzeit müssen entweder beide befüllt oder leer sein",
    validationCallback: (e) => {
      if (!this.form.models.data.$m_EventZeitraum) {
        return true;
      }
      
      const isInvalid = !this.form.models.data.$m_EventZeitraum.Kopf.AnmeldeschlussDatum 
        && this.form.models.data.$m_EventZeitraum.Kopf.AnmeldeschlussUhrzeit

      return !isInvalid;
    }
  };
  anmeldeschlussUhrzeitValidationRule: DevExpress.ui.CustomRule = {
    type: "custom",
    reevaluate: true,
    message: "Datum und Uhrzeit müssen entweder beide befüllt oder leer sein",
    validationCallback: (e) => {
      if (!this.form.models.data.$m_EventZeitraum) {
        return true;
      }
      
      const isInvalid = !this.form.models.data.$m_EventZeitraum.Kopf.AnmeldeschlussUhrzeit 
        && this.form.models.data.$m_EventZeitraum.Kopf.AnmeldeschlussDatum;
      
      return !isInvalid;
    }
  };

  nullableBoolRadioDataSource = [
    {
      text: "lt. Event",
      value: null
    }, {
      text: "anzeigen",
      value: true
    }, {
      text: "nicht anzeigen",
      value: false
    }];

  veroeffentlichungRadioDataSource = [
    {
      text: "lt. Event",
      value: null
    }, {
      text: "direkte Teilnehmerzuteilung",
      value: 0
    }, {
      text: "Veröffentlichung",
      value: 1
    }];

  veroeffentlichungRadioGroupOptions: DevExpress.ui.dxRadioGroupOptions = {
    layout: "horizontal",
    valueExpr: "value",
    displayExpr: "text",
    dataSource: this.veroeffentlichungRadioDataSource,
    bindingOptions: {
      value: "models.data.$m_EventZeitraum.Kopf.Veroeffentlichung",
      readOnly: "$f_EventZeitraumEditUtils.isTypAuswahlEnabled"
    }
  };
  checkBoxInSchnupperterminmaskeAnzeigenRadioGroupOptions: DevExpress.ui.dxRadioGroupOptions = {
    layout: "horizontal",
    valueExpr: "value",
    displayExpr: "text",
    dataSource: this.nullableBoolRadioDataSource,
    bindingOptions: {
      value: "models.data.$m_EventZeitraum.Kopf.InSchnupperterminmaskeAnzeigen"
    }
  };
  checkBoxWebserviceTerminabfrageRadioGroupOptions: DevExpress.ui.dxRadioGroupOptions = {
    layout: "horizontal",
    valueExpr: "value",
    displayExpr: "text",
    dataSource: this.nullableBoolRadioDataSource,
    bindingOptions: {
      value: "models.data.$m_EventZeitraum.Kopf.WebserviceTerminabfrage"
    }
  };
  checkBoxBewerbungformularSchnupperterminauswahlRadioGroupOptions: DevExpress.ui.dxRadioGroupOptions = {
    layout: "horizontal",
    valueExpr: "value",
    displayExpr: "text",
    dataSource: this.nullableBoolRadioDataSource,
    bindingOptions: {
      value: "models.data.$m_EventZeitraum.Kopf.BewerbungsformularSchnupperterminauswahl"
    }
  };
  checkBoxOrtFuerSchnuppernNachVereinbarungRadioGroupOptions: DevExpress.ui.dxRadioGroupOptions = {
    layout: "horizontal",
    valueExpr: "value",
    displayExpr: "text",
    dataSource: this.nullableBoolRadioDataSource,
    bindingOptions: {
      value: "models.data.$m_EventZeitraum.Kopf.OrtFuerSchnuppernNachVereinbarung"
    }
  };

  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };

  filterButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fas fa-filter",
    elementAttr: {
      class: "z--button z--button-round"
    },
    onClick: () => {
      this.getErweiterteSuche()
        .showPopup();
    }
  };

  filterErweitertButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fas fa-caret-down",
    elementAttr: {
      class: "z--button z--button-no-round"
    },
    onClick: (e) => {
      this.showErweiterteSucheContextMenu(e.event);
    }
  };

  meldenBeiSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    acceptCustomValue: true,
    bindingOptions: {
      value: "models.data.$m_EventZeitraum.Kopf.MeldenBei",
      dataSource: "functions.$f_EventZeitraumEditUtils.meldenBeiDataSource",
      placeholder: "models.data.$m_Event.Kopf.MeldenBei"
    }
  };

  dokumentToolbarOptions: IZitToolbarOptions = {
    title: "event.dokument-toolbar-titel",
    smallToolbar: true,
    items: [{
      id: "showDokumentToolbarOptionen",
      icon: "fas fa-plus",
      execute: async (e) => {
        if (!this.form.models.data.$m_Event.Id) {
          await this.form.save();
        }

        const ctxMenu = new ContextMenu();

        if (this.form["r_dokumentElement"]) {
          ctxMenu.items = [{
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-dokument"),
            execute: () => {
              const dokumentListView: DokumentListView = this.form["r_dokumentElement"];
              dokumentListView.onDokumentAddClicked();
            }
          }, {
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-dokument-erstellen"),
            execute: () => {
              const share: Share = this.form["r_share"];
              share.dokumentLautVorlageErstellen.showPopup(
                [this.form.models.data.$m_EventZeitraum.Id],
                "TIP.ZIT.Business.Entities.Objekte.Events.EventZeitraum");
            }
          }];
        } else if (this.form["r_dateien"]) {
          ctxMenu.items = [{
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-dokumente"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onDokumentClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-bilder"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onCameraClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-videos"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onVideoClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-galerie"),
            execute: async () => {
              const r = await this.form.saveIfDirty();
              if (!r.isValid) {
                return;
              }

              const datei: Datei = this.form["r_dateien"];
              datei.onAddToGalerieClick(() => {
                this.form.save();
              });
            }
          }];
        }

        ctxMenu.show(event.target);
      }
    }]
  };

  @computedFrom("erweiterteSucheWertQuery")
  get isTypAuswahlEnabled(): boolean {
    return this.erweiterteSucheWertQuery
      && this.erweiterteSucheWertQuery.length > 0;
  }

  onExecuteSucheErweitert(event: any): void {
    this.erweiterteSucheWertQuery = event.detail.wertQueryList;
  }

  onStartDatumChanged() {
    const model = this.form.models.data.$m_EventZeitraum;

    if (!model || !model.Kopf || model.Kopf.EndeDatum) {
      return;
    }

    model.Kopf.EndeDatum = model.Kopf.StartDatum;
  }

  onStelleninseratBezeichnungCellPrepared(e) {
    if (e.rowType != "data") {
      return;
    }

    const cell = <HTMLTableCellElement>e.cellElement;
    cell.textContent = e.data.Stelleninserat.StellenbezeichnungIntern || e.data.Stelleninserat.StellenbezeichnungExtern;
  }

  bind(form: FormBase): void {
    this.form = form;
    form.models.onLoaded.register((args) => {
      if (args.model.id == "$m_EventZeitraum" && args.data) {
        if (args.data.Kopf) {
          if (args.data.Kopf.VeroeffentlichenFilter && args.data.Kopf.VeroeffentlichenFilter.length > 0) {
            this.erweiterteSucheWertQuery = this._jsonService.parse(args.data.Kopf.VeroeffentlichenFilter);

            this.getErweiterteSuche()
              .loadFilter(this.erweiterteSucheWertQuery);
          }
        }
      }
      if (args.model.id == "$m_Event" && args.data) {
        if (args.data.Kopf) {
          form["r_meldenUm"].instance.option(
            "placeholder",
            this._globalizationService.format(args.data.Kopf.MeldenUm, "t"));
          
          form["r_startUhrzeit"].instance.option(
            "placeholder",
            this._globalizationService.format(args.data.Kopf.StartUhrzeit, "t"));
          
          form["r_endeUhrzeit"].instance.option(
            "placeholder",
            this._globalizationService.format(args.data.Kopf.EndeUhrzeit, "t"));

          if (args.data.Kopf.IdGeschaeftspartner) {
            this.setMeldenBeiDataSource(args.data.Kopf.IdGeschaeftspartner);
          } else if (args.data.Kopf.IdGeschaeftspartnerSchule) {
            this.setMeldenBeiDataSource(args.data.Kopf.IdGeschaeftspartnerSchule);
          }
        }
      }
      return Promise.resolve();
    });
    form.callOnBind(() => {
      form["r_betreffOptions"].bindingOptions.placeholder = "models.data.$m_Event.Kopf.Betreff";
      form["r_ortOptions"].bindingOptions.placeholder = "models.data.$m_Event.Kopf.Ort";
      form["r_standardDauerOptions"].bindingOptions.placeholder = "models.data.$m_Event.Kopf.Standarddauer";
      form["r_minimalDauerOptions"].bindingOptions.placeholder = "models.data.$m_Event.Kopf.Mindestdauer";
      form["r_maximalDauerOptions"].bindingOptions.placeholder = "models.data.$m_Event.Kopf.Maximaldauer";
      form["r_bezeichnungInternOptions"].bindingOptions.placeholder = "models.data.$m_Event.Kopf.BezeichnungIntern";
    });
    form.onSaving.register((args) => {
      args.form.models.data.$m_EventZeitraum.Kopf.VeroeffentlichenFilter = this.erweiterteSucheWertQuery
        ? this._jsonService.stringify(this.erweiterteSucheWertQuery)
        : null;

      return Promise.resolve();
    });
  }

  private getErweiterteSuche(): ErweiterteSuche {
    return this.form["r_erweiterteSuche"];
  }
  private showErweiterteSucheContextMenu(event): void {
    this.getErweiterteSuche()
      .showVorlageContextMenu(event, true);
  }

  private async setMeldenBeiDataSource(id: number) {
    const ansprechpersonList = await this._ansprechpersonService
      .loadAnsprechpersonByGeschaeftspartner(id, ["Name"]);

    this.meldenBeiDataSource = ansprechpersonList.map((c) => c.Name);
  }
}
