import * as fwx from "../../../../framework/forms/form-export";
import {
    ZielEditUtils
} from "./ziel-edit-utils";

@fwx.autoinject
export class ZielEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_ZielEditUtils: ZielEditUtils) {
        super(element, formBaseImport);
        this.id = "ziel-edit";
        this.title = "ziel-edit.ziel-edit_caption";
        this.addModel({
            "id": "$m_Ziel",
            "webApiAction": "ZIT/Objekte/Ziel",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'ZielMassnahmen': {
                            'expand': {
                                'ZielMassnahmeDateien': null
                            }
                        },
                        'ZielDateien': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ZielEditUtils.createNachrichtCommand",
                "bindToFQ": "functions.$f_ZielEditUtils.createNachrichtCommand"
            }
        });
        this.addFunction("$f_ZielEditUtils", $f_ZielEditUtils, "functions.$f_ZielEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "person",
            "filters": [],
            "caption": "ziel-edit.r_person_caption",
            "binding": {
                "dataContext": "$m_Ziel",
                "bindTo": "Kopf.IdObjekt",
                "bindToFQ": "models.data.$m_Ziel.Kopf.IdObjekt",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_person",
            "options": {
                "optionsName": "r_personOptions",
                "optionsNameFQ": "r_personOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "ansprechperson",
            "filters": [{
                "webApiCustomKey": "IdPerson",
                "webApiCustomValue": "models.data.$m_Ziel.Kopf.IdObjekt"
            }],
            "caption": "ziel-edit.r_ansprechperson_caption",
            "binding": {
                "dataContext": "$m_Ziel",
                "bindTo": "Kopf.IdAnsprechperson",
                "bindToFQ": "models.data.$m_Ziel.Kopf.IdAnsprechperson",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_ansprechperson",
            "options": {
                "optionsName": "r_ansprechpersonOptions",
                "optionsNameFQ": "r_ansprechpersonOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "ziel-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_Ziel",
                "bindTo": "Kopf.Bezeichnung",
                "bindToFQ": "models.data.$m_Ziel.Kopf.Bezeichnung",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "75px",
            "caption": "ziel-edit.r_kommentar_caption",
            "binding": {
                "dataContext": "$m_Ziel",
                "bindTo": "Kopf.Kommentar",
                "bindToFQ": "models.data.$m_Ziel.Kopf.Kommentar",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_kommentar",
            "options": {
                "optionsName": "r_kommentarOptions",
                "optionsNameFQ": "r_kommentarOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "ZielSichtbarFuerTyp",
            "filters": [],
            "caption": "ziel-edit.r_sichtbarfuer_caption",
            "isReadOnlyExpression": "models.data.$m_Ziel.Id",
            "binding": {
                "dataContext": "$m_Ziel",
                "bindTo": "Kopf.SichtbarFuer",
                "bindToFQ": "models.data.$m_Ziel.Kopf.SichtbarFuer",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sichtbarFuer",
            "options": {
                "optionsName": "r_sichtbarFuerOptions",
                "optionsNameFQ": "r_sichtbarFuerOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "75px",
            "caption": "ziel-edit.r_kommentarausbilder_caption",
            "binding": {
                "dataContext": "$m_Ziel",
                "bindTo": "Kopf.KommentarAusbilder",
                "bindToFQ": "models.data.$m_Ziel.Kopf.KommentarAusbilder",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_kommentarAusbilder",
            "options": {
                "optionsName": "r_kommentarAusbilderOptions",
                "optionsNameFQ": "r_kommentarAusbilderOptions"
            }
        });
        super.onConstructionFinished();
    }
}