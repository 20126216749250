import { 
  FrameworkConfiguration,
  PLATFORM
} from "aurelia-framework";
import {
  DxTemplateService
} from "../dx/services/dx-template-service";
import * as DxLoader from "../dx/dx-loader";
import "./styles/styles.less";

export function configure(config: FrameworkConfiguration) {
  config
    .globalResources(PLATFORM.moduleName("./elements/code-editor/code-editor"))
    .globalResources(PLATFORM.moduleName("./elements/file-uploader-with-viewer/tip-file-uploader-with-viewer"))
    .globalResources(PLATFORM.moduleName("./elements/list-view/list-view"))
    .globalResources(PLATFORM.moduleName("./elements/custom-edit-popup/custom-edit-popup"))
    .globalResources(PLATFORM.moduleName("./elements/toolbar/toolbar"))
    .globalResources(PLATFORM.moduleName("./elements/html-editor/html-editor"))
    .globalResources(PLATFORM.moduleName("./views/stack-router/stack-router"))
    .globalResources(PLATFORM.moduleName("./attributes/stack-router-link/stack-router-link"));

  const dxTemplate: DxTemplateService = config.container.get(DxTemplateService);
}
