import * as fwx from "../../../../framework/forms/form-export";
import {
    EventTeilnehmerEditUtils
} from "./event-teilnehmer-edit-utils";

@fwx.autoinject
export class EventTeilnehmerEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_EventTeilnehmerEditUtils: EventTeilnehmerEditUtils) {
        super(element, formBaseImport);
        this.id = "event-teilnehmer-edit";
        this.title = "event-teilnehmer-edit.event-teilnehmer-edit_caption";
        this.addModel({
            "id": "$m_EventTeilnehmer",
            "webApiAction": "ZIT/Objekte/EventTeilnehmer",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'Bewerbung': null
                    }
                }
            },
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_EventZeitraum",
            "webApiAction": "ZIT/Objekte/EventZeitraum",
            "webApiExpand": {
                'Kopf': null
            },
            "key": "models.data.$m_EventTeilnehmer.IdEventZeitraum",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Event",
            "webApiAction": "ZIT/Objekte/Event",
            "webApiExpand": {
                'Kopf': null
            },
            "modificationInfoEnabled": true,
            "key": "models.data.$m_EventTeilnehmer.IdEvent",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EventTeilnehmerEditUtils.actionCommand",
                "bindToFQ": "functions.$f_EventTeilnehmerEditUtils.actionCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EventTeilnehmerEditUtils.statusAendernPopupCommand",
                "bindToFQ": "functions.$f_EventTeilnehmerEditUtils.statusAendernPopupCommand"
            }
        });
        this.addFunction("$f_EventTeilnehmerEditUtils", $f_EventTeilnehmerEditUtils, "functions.$f_EventTeilnehmerEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "eventZeitraum",
            "filter": ["IdEvent", {
                "isBound": true,
                "expression": "models.data.$m_EventTeilnehmer.IdEvent"
            }],
            "filters": [],
            "caption": "event-teilnehmer-edit.r_eventzeitraum_caption",
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "IdEventZeitraum",
                "bindToFQ": "models.data.$m_EventTeilnehmer.IdEventZeitraum"
            },
            "validationRules": [],
            "id": "r_eventZeitraum",
            "options": {
                "optionsName": "r_eventZeitraumOptions",
                "optionsNameFQ": "r_eventZeitraumOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "event-teilnehmer-edit.r_eventzeitraumbezeichnung_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.EventZeitraumBezeichnung",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.EventZeitraumBezeichnung",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_eventZeitraumBezeichnung",
            "options": {
                "optionsName": "r_eventZeitraumBezeichnungOptions",
                "optionsNameFQ": "r_eventZeitraumBezeichnungOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "event-teilnehmer-edit.r_person_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.PersonName",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.PersonName",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_person",
            "options": {
                "optionsName": "r_personOptions",
                "optionsNameFQ": "r_personOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "event-teilnehmer-edit.r_klasse_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.StelleninseratKlasseBezeichnung",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.StelleninseratKlasseBezeichnung",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_klasse",
            "options": {
                "optionsName": "r_klasseOptions",
                "optionsNameFQ": "r_klasseOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "event-teilnehmer-edit.r_geschaeftspartner_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.GeschaeftspartnerName1",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.GeschaeftspartnerName1",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_geschaeftspartner",
            "options": {
                "optionsName": "r_geschaeftspartnerOptions",
                "optionsNameFQ": "r_geschaeftspartnerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "event-teilnehmer-edit.r_geschaeftspartnerschule_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.GeschaeftspartnerSchuleName1",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.GeschaeftspartnerSchuleName1",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_geschaeftspartnerSchule",
            "options": {
                "optionsName": "r_geschaeftspartnerSchuleOptions",
                "optionsNameFQ": "r_geschaeftspartnerSchuleOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "event-teilnehmer-edit.r_stelleninserat_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.StelleninseratBezeichnung",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.StelleninseratBezeichnung",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_stelleninserat",
            "options": {
                "optionsName": "r_stelleninseratOptions",
                "optionsNameFQ": "r_stelleninseratOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "event-teilnehmer-edit.r_startdatum_caption",
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.StartDatum",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.StartDatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_EventTeilnehmerEditUtils.onStartDatumChanged",
            "id": "r_startDatum",
            "options": {
                "optionsName": "r_startDatumOptions",
                "optionsNameFQ": "r_startDatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "event-teilnehmer-edit.r_startuhrzeit_caption",
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.StartUhrzeit",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.StartUhrzeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_startUhrzeit",
            "options": {
                "optionsName": "r_startUhrzeitOptions",
                "optionsNameFQ": "r_startUhrzeitOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "event-teilnehmer-edit.r_endedatum_caption",
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.EndeDatum",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.EndeDatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_endeDatum",
            "options": {
                "optionsName": "r_endeDatumOptions",
                "optionsNameFQ": "r_endeDatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "event-teilnehmer-edit.r_endeuhrzeit_caption",
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.EndeUhrzeit",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.EndeUhrzeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_endeUhrzeit",
            "options": {
                "optionsName": "r_endeUhrzeitOptions",
                "optionsNameFQ": "r_endeUhrzeitOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "event-teilnehmer-edit.r_meldenum_caption",
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.MeldenUm",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.MeldenUm",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_meldenUm",
            "options": {
                "optionsName": "r_meldenUmOptions",
                "optionsNameFQ": "r_meldenUmOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "caption": "event-teilnehmer-edit.r_bemerkung_caption",
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.Bemerkung",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.Bemerkung",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bemerkung",
            "options": {
                "optionsName": "r_bemerkungOptions",
                "optionsNameFQ": "r_bemerkungOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "caption": "event-teilnehmer-edit.r_bemerkunggeschaeftspartner_caption",
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.BemerkungGeschaeftspartner",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.BemerkungGeschaeftspartner",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bemerkungGeschaeftspartner",
            "options": {
                "optionsName": "r_bemerkungGeschaeftspartnerOptions",
                "optionsNameFQ": "r_bemerkungGeschaeftspartnerOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "caption": "event-teilnehmer-edit.r_bemerkunggeschaeftspartnerschule_caption",
            "binding": {
                "dataContext": "$m_EventTeilnehmer",
                "bindTo": "Kopf.BemerkungGeschaeftspartnerSchule",
                "bindToFQ": "models.data.$m_EventTeilnehmer.Kopf.BemerkungGeschaeftspartnerSchule",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bemerkungGeschaeftspartnerSchule",
            "options": {
                "optionsName": "r_bemerkungGeschaeftspartnerSchuleOptions",
                "optionsNameFQ": "r_bemerkungGeschaeftspartnerSchuleOptions"
            }
        });
        super.onConstructionFinished();
    }
}