import { KontaktpersonTyp } from "./../../enumerations/kontaktperson-typ";
import { BrowserService } from "./../../../framework/base/services/browser-service";
import { Geschlecht } from "./../../enumerations/geschlecht";
import { StartupService } from "./../../services/startup-service";
import { ScopeContainer } from "./../../../framework/base/classes/scope-container";
import { RestService } from "./../../../framework/base/services/rest-service";
import { autoinject, PLATFORM, bindable, computedFrom } from "aurelia-framework";
import { FormBase } from "../../../framework/forms/classes/form-base";
import { AnredeformTyp } from "../../interfaces/export";
import { DashboardService } from "../../services/export";

PLATFORM.moduleName("zit/elements/dashboard-box-bewerbung/dashboard-box-bewerbung");
PLATFORM.moduleName("zit/elements/dashboard-box-einsatz/dashboard-box-einsatz");
PLATFORM.moduleName("zit/elements/dashboard-box-aufgabe/dashboard-box-aufgabe");
PLATFORM.moduleName("zit/elements/dashboard-box-stelleninserat/dashboard-box-stelleninserat");
PLATFORM.moduleName("zit/elements/dashboard-box-termin/dashboard-box-termin");
PLATFORM.moduleName("zit/elements/dashboard-box-html/dashboard-box-html");
PLATFORM.moduleName("zit/elements/dashboard-box-event/dashboard-box-event");
PLATFORM.moduleName("zit/elements/dashboard-box-event-teilnehmer/dashboard-box-event-teilnehmer");
PLATFORM.moduleName("zit/elements/dashboard-box-information/dashboard-box-information");
PLATFORM.moduleName("zit/elements/dashboard-box-kennzahlen/dashboard-box-kennzahlen");

@autoinject
export class DashboardContainer {
  constructor(
    private _restService: RestService,
    private _startupService: StartupService,
    private _browserService: BrowserService,
    private _dashboardService: DashboardService
  ) {
    const browsermobile = this._browserService.isMobile;
    this.isBrowserMobile = browsermobile;
  }

  @bindable mainForm: FormBase;

  dashboardInfoContainer: any;
  scopeContainer: ScopeContainer;
  subHeadline: string;
  isBrowserMobile: boolean;

  @computedFrom("dashboardInfoContainer")
  get headline(): string {
    const benutzer = this._startupService.startupInfo.Benutzer;

    if (this.dashboardInfoContainer && this.dashboardInfoContainer.Anredeform == AnredeformTyp.Du) {
      return `Hallo ${benutzer.Vorname}!`
    } else {
      let anrede = "";

      switch (benutzer.Geschlecht) {
        case Geschlecht.Maennlich: {
          if(benutzer.KontaktpersonTyp == KontaktpersonTyp.NatuerlichePerson) {
            anrede = "Herr";
            break;
          }
        }
        case Geschlecht.Weiblich: {
          if(benutzer.KontaktpersonTyp == KontaktpersonTyp.NatuerlichePerson) {
            anrede = "Frau";
            break;
          }
        }
        case Geschlecht.Maennlich: {
          if(benutzer.KontaktpersonTyp == KontaktpersonTyp.Team) {
            anrede = "Team";
            break;
          }
        }
        case Geschlecht.Weiblich: {
          if(benutzer.KontaktpersonTyp == KontaktpersonTyp.Team) {
            anrede = "Team";
            break;
          }
        }
      }
      return `Guten Tag ${anrede} ${benutzer.Nachname}!`
    }
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.loadDashboardInfo();
  }

  async loadDashboardInfo() {
    this.dashboardInfoContainer = await this._dashboardService.getDashboardInfo();
    this.subHeadline = this.dashboardInfoContainer.SubHeadline;
  }
}
