import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class AnsprechpersonGeschaeftspartnerEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "ansprechperson-geschaeftspartner-edit";
        this.title = "ansprechperson-geschaeftspartner-edit.ansprechperson-geschaeftspartner-edit_caption";
        this.addModel({
            "id": "$m_AnsprechpersonZuGeschaeftspartner",
            "webApiAction": "ZIT/Objekte/AnsprechpersonZuGeschaeftspartner",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "geschaeftspartner",
            "filters": [],
            "caption": "ansprechperson-geschaeftspartner-edit.r_anspechpersonzugpgeschaeftspartner_caption",
            "isReadOnlyExpression": "models.data.$m_AnsprechpersonZuGeschaeftspartner.Id > 0",
            "binding": {
                "dataContext": "$m_AnsprechpersonZuGeschaeftspartner",
                "bindTo": "IdGeschaeftspartner",
                "bindToFQ": "models.data.$m_AnsprechpersonZuGeschaeftspartner.IdGeschaeftspartner"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_AnspechpersonZuGpGeschaeftspartner",
            "options": {
                "optionsName": "r_AnspechpersonZuGpGeschaeftspartnerOptions",
                "optionsNameFQ": "r_AnspechpersonZuGpGeschaeftspartnerOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "ansprechperson-geschaeftspartner-edit.r_anspechpersonzugpgueltigvon_caption",
            "binding": {
                "dataContext": "$m_AnsprechpersonZuGeschaeftspartner",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_AnsprechpersonZuGeschaeftspartner.GueltigVon"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_AnspechpersonZuGpGueltigVon",
            "options": {
                "optionsName": "r_AnspechpersonZuGpGueltigVonOptions",
                "optionsNameFQ": "r_AnspechpersonZuGpGueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "ansprechperson-geschaeftspartner-edit.r_anspechpersonzugpgueltigbis_caption",
            "binding": {
                "dataContext": "$m_AnsprechpersonZuGeschaeftspartner",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_AnsprechpersonZuGeschaeftspartner.GueltigBis"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_AnspechpersonZuGpGueltigBis",
            "options": {
                "optionsName": "r_AnspechpersonZuGpGueltigBisOptions",
                "optionsNameFQ": "r_AnspechpersonZuGpGueltigBisOptions"
            }
        });
        super.onConstructionFinished();
    }
}