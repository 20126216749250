import { autoinject, transient, computedFrom } from 'aurelia-framework';
import { Share } from '../../../elements/share/share';
import { FormBase } from '../../../../framework/forms/form-export';
import { ICommandData } from '../../../../framework/forms/export';

@autoinject
@transient()
export class AnsprechpersonReadUtils {
  constructor(
  ) { }

  form: FormBase;

  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };

  bind(form: FormBase) {
    this.form = form;
  }
}
