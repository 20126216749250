import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class GeschaeftspartnerListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "geschaeftsparter-list";
        this.title = "geschaeftsparter-list.geschaeftsparter-list_caption";
        this.addModel({
            "id": "$m_Geschaeftspartner",
            "webApiAction": "ZIT/Objekte/Geschaeftspartner",
            "webApiExpand": {
                'Geschaeftspartnerdaten': null
            },
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_gespName1",
                "caption": "geschaeftsparter-list.r_gespname1_caption",
                "bindTo": "Geschaeftspartnerdaten.Name1",
                "minWidth": 100
            }],
            "optionsToolbar": {
                "optionsName": "r_geschaeftspartnerGridToolbarOptions",
                "optionsNameFQ": "r_geschaeftspartnerGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Geschaeftspartner"
            },
            "dataModel": "$m_Geschaeftspartner",
            "editUrl": "Objekte/Geschaeftspartner",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_geschaeftspartnerGrid",
            "options": {
                "optionsName": "r_geschaeftspartnerGridOptions",
                "optionsNameFQ": "r_geschaeftspartnerGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}