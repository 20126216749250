import { autoinject, bindable } from "aurelia-framework";
import { GlobalizationService } from "../../../framework/base/services/globalization-service";
import * as moment from "moment";
import { RestService } from "../../../framework/base/services/rest-service";
import { DataSourceService } from "../../../framework/base/services/data-source-service";
import { ScopeContainer } from "../../../framework/base/classes/scope-container";
import { IdxDataGridComponent } from "../../interfaces/dx-components";
import { DialogConfirmService } from "../../../framework/forms/services/dialog-confirm-service";

@autoinject
export class StelleninseratSchnupperterminLight {
  constructor(
    private _element: Element,
    private _dialogConfirmService: DialogConfirmService,
    private _dataSourceService: DataSourceService,
    private _globalizationService: GlobalizationService,
    private _restService: RestService
  ) {

  }

  scopeContainer: ScopeContainer;

  @bindable idStelleninseratKopfElement: number;

  datumVon: Date | undefined;
  datumBis: Date | undefined;

  datumVonOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this._globalizationService.getFormatterParser("d"),
    min: this.getMinDate(),
    max: this.getMaxDate().add(1, "year").subtract(1, "day").toDate(),
    bindingOptions: {
      value: "datumVon"
    }
  };
  datumBisOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this._globalizationService.getFormatterParser("d"),
    min: this.getMinDate(),
    max: this.getMaxDate().add(1, "year").subtract(1, "day").toDate(),
    bindingOptions: {
      value: "datumBis"
    }
  };
  addOptions: DevExpress.ui.dxButtonOptions = {
    text: "Schnuppertermin erstellen",
    onClick: async () => {
      if (!this.datumVon) {
        DevExpress.ui.notify("Datum von ist ein Pflichtfeld", "ERROR", 300);
        return;
      }

      if (!this.datumBis) {
        DevExpress.ui.notify("Datum bis ist ein Pflichtfeld", "ERROR", 300);
        return;
      }

      if (this.datumVon.getTime() > this.datumBis.getTime()) {
        DevExpress.ui.notify("Datum von muss vor Datum bis liegen", "ERROR", 300);
        return;
      }

      await this._restService.post(({
        url: this._restService.getWebApiUrl("ZIT/Objekte/StelleninseratSchnuppertermin"),
        data: {
          IdStelleninseratKopfElement: this.idStelleninseratKopfElement,
          DatumVon: this.datumVon,
          DatumBis: this.datumBis
        },
        increaseLoadingCount: true
      }));

      DevExpress.ui.notify("Schnuppertermin wurde erfolgreich gespeichert", "SUCCESS", 300);

      this.datumVon = undefined;
      this.datumBis = undefined;

      this.dataGrid?.instance?.refresh();
      this._element.dispatchEvent(new CustomEvent("on-schnuppertermin-added", {
        bubbles: true
      }));
    }
  };

  dataGrid: IdxDataGridComponent;
  dataGridOptions: DevExpress.ui.dxDataGridOptions = {
    columns: [{
      caption: "Stellenbezeichnung",
      dataField: "StelleninseratKopfElement.StellenbezeichnungExtern"
    }, {
      caption: "Datum von",
      dataField: "DatumVon",
      format: this._globalizationService.getFormatterParser("d"),
      width: 120
    }, {
      caption: "Datum bis",
      dataField: "DatumBis",
      format: this._globalizationService.getFormatterParser("d"),
      width: 120
    }, {
      caption: "",
      width: 60,
      cellTemplate: (element, info) => {
        const c = document.createElement("div");
        c.className = "stelleninserat-schnuppertermin-light__grid-actions";

        const i = document.createElement("i");
        i.className = "fas fa-trash-alt";
        i.onclick = async () => {
          const result = await this._dialogConfirmService.show({
            title: "Frage",
            message: "Möchten Sie den Schnuppertermin wirklich löschen?",
          });

          if (!result) return;

          await this._restService.delete({
            url: this._restService.getWebApiUrl("ZIT/Objekte/StelleninseratSchnuppertermin"),
            id: info.data.Id,
            increaseLoadingCount: true
          });
          
          this.dataGrid.instance.refresh();
        };

        c.appendChild(i);
        element.appendChild(c);
      }
    }],
    paging: {
      pageSize: 5
    },
    remoteOperations: {
      paging: true,
      filtering: true,
      sorting: true
    }
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.dataGridOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer, {
        keyProperty: "Id",
        webApiAction: "ZIT/Objekte/StelleninseratSchnuppertermin",
        webApiExpand: {
          StelleninseratKopfElement: {
            columns: ["Id", "StellenbezeichnungExtern"]
          }
        },
        webApiOrderBy: [{ columnName: "DatumVon", sortOrder: 0 }]
      }, {
        canLoad: () => {
          return !!this.idStelleninseratKopfElement;
        },
        getCustomWhere: () => {
          return ["IdStelleninseratKopfElement", this.idStelleninseratKopfElement];
        }
      }
    );
  }

  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  private getMinDate() {
    return moment(new Date()).startOf("day").toDate();
  }

  private getMaxDate() {
    const today = moment();
    const start = moment(new Date(today.year(), 8, 1)).startOf("day");

    return start.month() >= 9
      ? start
      : start.subtract(1, "year");
  }
}
