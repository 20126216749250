import { autoinject, OverrideContext, Scope } from 'aurelia-framework';
import { ScopeContainer, LocalizationService, AuthorizationService } from '../../../framework/base/export';
import { SimpleWidgetCreatorService, ICommandData } from '../../../framework/forms/export';
import { DataSourceService } from '../../../framework/base/services/export';
import { ILoeschenBenutzerData } from '../../interfaces/export';
import { BenutzereinstellungenService } from '../../services/benutzereinstellungen-service';
import { IdxValidationGroupComponent, IdxSelectBoxComponent, IdxPopupComponent } from '../../interfaces/export';
import { GrundTyp } from '../../enumerations/export';
import { StartupService } from '../../services/export';
@autoinject
export class BenutzerHilfe {
  constructor(
    private localization: LocalizationService,
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private startup: StartupService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  htmlContent: string;

  benutzerHilfePopup: IdxPopupComponent;
  benutzerHilfePopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxHeight: "80%",
    maxWidth: "420px"
  };


  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "benutzerLoeschenPopupToolbar",
      caption: "benutzer-hilfe.popup-titel",
      options: this.benutzerHilfePopupOptions,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  showPopup() {
    this.htmlContent = this.startup.startupInfo.Benutzer.HtmlTextHilfe;

    this.benutzerHilfePopup.instance.show();
  }
}
