import { observable } from "aurelia-binding";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, computedFrom, transient } from "aurelia-framework";
import { DataSourceService, FileService } from "../../../../framework/base/export";
import { DialogConfirmService, ModelUtilsService } from "../../../../framework/forms/export";
import { IZitTagOptions } from "../../../../zit-ui/elements/zit-tag/export";
import { IZitToolbarOptions } from "../../../../zit-ui/elements/zit-toolbar/zit-toolbar-options";
import { Datei } from "../../../elements/datei/datei";
import { TerminEmpfaenger } from "../../../elements/export";
import { SelectionModeTyp } from "../../../elements/lehrling-auswaehlen/lehrling-auswaehlen";
import { DialogService } from "../../../services/dialog-service";
import { CustomEditPopupService, StartupService } from "../../../services/export";
import { FormPopupService } from "../../../services/form-popup-service";
import { NachrichtService } from "../../../services/nachricht-service";
import { JsonService } from "./../../../../framework/base/services/json-service";
import { LocalizationService } from "./../../../../framework/base/services/localization-service";
import { LocationService } from "./../../../../framework/base/services/location-service";
import { RestService } from "./../../../../framework/base/services/rest-service";
import { ContextMenu } from "./../../../../framework/forms/classes/context-menu";
import { FormBase } from "./../../../../framework/forms/classes/form-base";
import { ICommandData } from "./../../../../framework/forms/interfaces/command-data";
import { DokumentListView } from "./../../../elements/dokument-list-view/dokument-list-view";
import { ErweiterteSuche } from "./../../../elements/erweiterte-suche/erweiterte-suche";
import { LehrlingAuswaehlen } from "./../../../elements/lehrling-auswaehlen/lehrling-auswaehlen";
import { Share } from "./../../../elements/share/share";
import { IdxSelectBoxComponent } from "./../../../interfaces/dx-components";
import { AnsprechpersonService } from "./../../../services/ansprechperson-service";

@transient()
@autoinject
export class TerminEditUtils {
  private _terminChangedSubscription: Subscription;

  constructor(
    private _ansprechpersonService: AnsprechpersonService,
    private _restService: RestService,
    private _jsonService: JsonService,
    private _locationService: LocationService,
    private _localizationService: LocalizationService,
    private _formPopupService: FormPopupService,
    private _modelUtilsService: ModelUtilsService,
    private _dialogService: DialogService,
    private _nachrichtService: NachrichtService,
    private _dialogConfirmService: DialogConfirmService,
    private _startupService: StartupService,
    private _fileService: FileService,
    private _customEditPopupService: CustomEditPopupService,
    private _dataSourceService: DataSourceService,
    private _eventAggregator: EventAggregator
  ) { }

  @observable idSelectedEmpfaenger: number;

  form: FormBase;
  meldenBeiDataSource: string[];
  terminDataSource: string[];
  isEmpfaengergruppeReadOnly: boolean = false;
  selectedEmpfaenger: any;
  selectedEmpfaengerName: any;
  erweiterteSucheWertQuery: any;

  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };

  meldenBeiSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    acceptCustomValue: true,
    bindingOptions: {
      value: "models.data.$m_Termin.Kopf.MeldenBei",
      dataSource: "functions.$f_TerminEditUtils.meldenBeiDataSource"
    }
  };

  hauptterminSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    acceptCustomValue: true,
    bindingOptions: {
      value: "models.data.$m_Termin.Kopf.Haupttermin",
      dataSource: "functions.$f_TerminEditUtils.terminDataSource"
    },
    placeholder: "Auswählen oder neuen Wert erfassen..."
  };

  benutzerTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: "item.Kopf.Benutzer.NameGesamt ? item.Kopf.Benutzer.NameGesamt : item.Kopf.Benutzer.Nachname",
    icon: {
      text: "B"
    }
  };

  empfaengerTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "far fa-address-book"
    },
    textExpression: "item.EmpfaengerName ? item.EmpfaengerName : item.Titel",
    infoList: [
      {
        icon: {
          faIcon: "fas fa-info-circle"
        },
        onClick: (e, data) => {
          e.stopPropagation();
          e.preventDefault();

          this._locationService.goTo({
            url: `#Objekte/Person/${data.IdPerson}`,
            currentViewModel: this.form
          });
        }
      }, {
        icon: {
          faIcon: "fas fa-beer",
          backgroundColor: "#E67F22"
        },
        isVisibleExpression: "item._HasEinsch",
        tooltip: "Einschätzungen des Lehrlings vorhanden",
        onClick: (ev, data) => {
          ev.stopPropagation();
          ev.preventDefault();

          if (data._IdEinsch) {
            this.form.models.data.$m_TerminEmpfEinschEdit = {
              Id: data._IdEinsch
            };
            this.form.editPopups.show("terminEmpfEinschEditPopup", null);
          } else {
            DevExpress.ui.notify(
              "Es wurde noch keine Einschätzung erstellt",
              "info",
              3000
            );
          }
        }
      }, {
        icon: {
          faIconExpression: "item.StatusZustandTagSymbol"
        },
        isVisibleExpression: "item.IdStatusZustand != null",
        backgroundColorExpression: "item.StatusZustandFarbe"
      }],
    onClick: (e, data) => {
      if (this.isHeartbase) {
        e.stopPropagation();
        e.preventDefault();

        this._locationService.goTo({
          url: `#Objekte/Person/${data.IdPerson}`,
          currentViewModel: this.form
        });
      } else {
        const terminEmpfaenger: TerminEmpfaenger = this.form["r_terminEmpfaengerStatusElement"];
        terminEmpfaenger.showStatusPopup(this.form.models.data.$m_Termin.Id, data, this.onEmpfaengerStatusGeaendert.bind(this));
      }
    },
    onDeleteClick: async(e, data) => {
      e.stopPropagation();

      const r = await this._dialogService.askZuteilungLoeschen();
      if (!r) {
        return;
      }

      this.deleteEmpfaenger(data);
    },
  };

  veroeffentlichenEmpfaengerTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "far fa-address-book"
    },
    textExpression: "item.EmpfaengerName ? item.EmpfaengerName : item.Titel",
    infoList: [
      {
        icon: {
          faIcon: "fas fa-info-circle"
        },
        onClick: (e, data) => {
          e.stopPropagation();
          e.preventDefault();

          this._locationService.goTo({
            url: `#Objekte/Person/${data.IdPerson}`,
          });
        }
      }, {
        icon: {
          faIconExpression: "item.StatusZustandTagSymbol"
        },
        isVisibleExpression: "item.IdStatusZustand != null",
        backgroundColorExpression: "item.StatusZustandFarbe"
      }],
    onClick: (e, data) => {
      const terminEmpfaenger: TerminEmpfaenger = this.form["r_terminEmpfaengerStatusElement"];
      terminEmpfaenger.showStatusPopup(this.form.models.data.$m_Termin.Id, data, this.onEmpfaengerStatusGeaendert.bind(this));
    }
  };

  veroeffentlichenEmpfaengerGelesenTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "fas fa-user"
    },
    textExpression: `item`
  };

  zustandTagOptions: IZitTagOptions = {
    icon: {
      faIconExpression: "item.Kopf.TerminBestaetigungZustand.TagSymbol",
      backgroundColorExpression: "item.Kopf.TerminBestaetigungZustand.Farbe"
    },
    textExpression: `item.Kopf.TerminBestaetigungZustand.Bezeichnung | zitMoment:"zustand":item.Kopf.TerminBestaetigungZustandDatum`,
    onClick: (e, data) => {
      this.form.models.data.$m_ZustandAendern = data.Kopf.TerminBestaetigungZustand;
      this.form.editPopups.show("zustandAendernEditPopup", null);
    }
  };

  veroeffentlichenToolbarOptions: IZitToolbarOptions = {
    title: "termin.veroeffentlichen",
    smallToolbar: true,
    items: [{
      id: "terminEditUtilsFilterButtonCommand",
      icon: "fas fa-filter",
      execute: (e) => {
        this.getErweiterteSuche()
          .showPopup();
      }
    }, {
      id: "terminEditUtilsErweiterteSucheFilterButtonCommand",
      icon: "fas fa-caret-down",
      className: "z--button z--button-no-round",
      execute: (e) => {
        this.showErweiterteSucheContextMenu(e.event);
      }
    }]
  };

  dokumentToolbarOptions: IZitToolbarOptions = {
    title: "termin.dokument-toolbar-titel",
    smallToolbar: true,
    items: [{
      id: "showDokumentToolbarOptionen",
      icon: "fas fa-plus",
      execute: async(e) => {
        if (!this.form.models.data.$m_Termin.Id) {
          await this.form.save();
        }

        const ctxMenu = new ContextMenu();

        if (this.form["r_dokumentElement"]) {
          ctxMenu.items = [{
            text: this._localizationService.translateOnce("termin.dokument-toolbar-item-info-dokument"),
            execute: () => {
              const dokumentListView: DokumentListView = this.form["r_dokumentElement"];
              dokumentListView.onDokumentAddClicked();
            }
          }, {
            text: this._localizationService.translateOnce("termin.dokument-toolbar-item-dokument-erstellen"),
            execute: () => {
              const share: Share = this.form["r_share"];
              share.dokumentLautVorlageErstellen.showPopup(
                [this.form.models.data.$m_Termin.Id],
                "TIP.ZIT.Business.Entities.Objekte.Termine.Termin");
            }
          }];
        } else if (this.form["r_dateien"]) {
          ctxMenu.items = [{
            text: this._localizationService.translateOnce("termin.dokument-toolbar-item-info-dokumente"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onDokumentClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("termin.dokument-toolbar-item-info-bilder"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onCameraClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("termin.dokument-toolbar-item-info-videos"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onVideoClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("termin.dokument-toolbar-item-info-galerie"),
            execute: async() => {
              const r = await this.form.saveIfDirty();
              if (!r.isValid) {
                return;
              }

              const datei: Datei = this.form["r_dateien"];
              datei.onAddToGalerieClick(() => {
                this.form.save();
              });
            }
          }];
        }

        ctxMenu.show(event.target);
      }
    }]
  };

  filterButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fas fa-filter",
    elementAttr: {
      class: "z--button z--button-round"
    },
    onClick: () => {
      this.getErweiterteSuche()
        .showPopup();
    }
  };

  filterErweitertButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fas fa-caret-down",
    elementAttr: {
      class: "z--button z--button-no-round"
    },
    onClick: (e) => {
      this.showErweiterteSucheContextMenu(e.event);
    }
  };

  empfaengerZuteilenButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fas fa-plus",
    elementAttr: {
      class: "z--button z--button-round"
    },
    onClick: () => {
      const terminEmpfaenger: TerminEmpfaenger = this.form["r_terminEmpfaengerStatusElement"];

      const data = {
        IdTermin: this.form.models.data.$m_Termin.Id
      };

      terminEmpfaenger.showStatusPopup(this.form.models.data.$m_Termin.Id, data, this.onEmpfaengerStatusGeaendert.bind(this));
    }
  };

  trainerTagOptions: IZitTagOptions = {
    icon: {
      textExpression: "item.Ansprechperson.FunktionTagText"
    },
    textExpression: "item.Ansprechperson.Titel",
    onClick: (_, data) => {
      this._locationService.goTo({
        url: `Objekte/Ansprechperson/${data.IdAnsprechperson}`,
        currentViewModel: this.form
      });
    },
    onDeleteClick: async(ev: Event, data) => {
      ev.preventDefault();
      ev.stopPropagation();

      const r = await this._dialogService.askZuteilungLoeschen();
      if (!r) {
        return;
      }

      const index = this.form.models.data.$m_Termin.Trainer.indexOf(data);
      if (index < 0) {
        return;
      }

      this.form.models.data.$m_Termin.Trainer.splice(index, 1);
      this._modelUtilsService.setDirty(this.form.models.data.$m_Termin);
    }
  };

  nachrichtengruppeToolbarOptions: IZitToolbarOptions = {
    title: "termin.nachrichtengruppe",
    smallToolbar: true,
    items: [{
      id: "addGruppe",
      icon: "fas fa-plus",
      isVisibleExpression: "functions.$f_TerminEditUtils.canNachrichtengruppeErstellen",
      execute: async(e) => {
        const r = await this.form.saveIfDirty();
        if (!r.isValid) {
          return;
        }

        const dialogResult = await this._dialogConfirmService.show({
          title: this._localizationService.translateOnce("base.question"),
          message: this._localizationService.translateOnce("termin.frage-nachrichtengruppe-erstellen")
        });

        if (!dialogResult) {
          return;
        }

        await this._restService.post({
          url: this._restService.getApiUrl("ZIT/Nachricht/GruppeErstellen"),
          data: {
            IdObjekt: this.form.models.data.$m_Termin.Id
          },
          increaseLoadingCount: true
        });

        this.form.models.reloadAll();

        if (this.form.models.data.$m_Termin._NachrichtGruppe && this.form.models.data.$m_Termin._NachrichtGruppe.Id) {
          const info = await this._nachrichtService.getNachrichtGruppeBenutzerInfoForObjekt(
            "TIP.ZIT.Business.Entities.Objekte.Termine.Termin",
            this.form.models.data.$m_Termin.Id
          );

          if (info) {
            this._nachrichtService.goToNachrichtList(info, this.form);
          }
        }
      }
    }]
  };

  nachrichtengruppeTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "far fa-comment-dots"
    },
    textExpression: "item._NachrichtGruppe.Bezeichnung",
    infoList: [{
      icon: {
        faIcon: "fas fa-info-circle"
      },
      onClick: (ev, data) => {
        this._customEditPopupService.nachrichtGruppe.show({
          mappings: {
            "$id": data._NachrichtGruppe.Id
          }
        });
      }
    }],
    onDeleteClick: async(ev: Event, data) => {
      ev.preventDefault();
      ev.stopPropagation();

      const r = await this.form.saveIfDirty();
        if (!r.isValid) {
          return;
        }

        const dialogResult = await this._dialogConfirmService.show({
          title: this._localizationService.translateOnce("base.question"),
          message: this._localizationService.translateOnce("termin.frage-nachrichtengruppe-loeschen")
        });

        if (!dialogResult) {
          return;
        }

        await this._restService.post({
          url: this._restService.getApiUrl("ZIT/Nachricht/GruppeLoeschen"),
          data: {
            IdObjekt: this.form.models.data.$m_Termin.Id
          },
          increaseLoadingCount: true
        });

        this.form.models.reloadAll();
    }
  };

  @computedFrom("form.models.data.$m_Termin.Empfaenger", "erweiterteSucheWertQuery")
  get isTypAuswahlEnabled(): boolean {
    return (this.form.models.data.$m_Termin
      && this.form.models.data.$m_Termin.Empfaenger
      && this.form.models.data.$m_Termin.Empfaenger.length > 0)
      || (this.erweiterteSucheWertQuery
        && this.erweiterteSucheWertQuery.length > 0);
  }

  @computedFrom("form.models.data.$m_Termin._NachrichtGruppe.Id")
  get canNachrichtengruppeErstellen() {
    return !!this.form.models.data.$m_Termin
      && (!this.form.models.data.$m_Termin._NachrichtGruppe
      || !this.form.models.data.$m_Termin._NachrichtGruppe.Id);
  }

  get isHeartbase() {
    return this.form
      && this.form.id == "termin-heartbase-edit";
  }

  bind(form: FormBase) {
    this.form = form;

    this.nachrichtengruppeToolbarOptions.scopeContainer = form.scopeContainer;

    if (this.isHeartbase) {
      this.dokumentToolbarOptions.title = "termin.dokument-bilder-video-toolbar-titel";

      delete this.empfaengerTagOptions.icon.faIcon;
      this.empfaengerTagOptions.icon.text = "L";
    }

    form.models.onLoaded.register((args) => {
      if (args.model.id == "$m_Termin" && args.data) {
        if (args.data.Kopf) {
          if (!args.data.Id && args.data.Kopf && args.data.Kopf._IdGeschaeftspartner) {
            args.data.Kopf.IdGeschaeftspartner = args.data.Kopf._IdGeschaeftspartner;
          }

          if (args.data.Kopf.IdGeschaeftspartner) {
            this.loadMeldenBeiDataSource(args.data.Kopf.IdGeschaeftspartner);
            this.loadTermineDataSource(args.data.Kopf.IdGeschaeftspartner);
          }

          if (args.data.Empfaenger && args.data.Empfaenger.length > 0) {
            this.modifyEmpfaengerList(args.data.Empfaenger);
          }

          if (args.data.Kopf.VeroeffentlichenFilter && args.data.Kopf.VeroeffentlichenFilter.length > 0) {
            this.erweiterteSucheWertQuery = this._jsonService.parse(args.data.Kopf.VeroeffentlichenFilter);

            this.getErweiterteSuche()
              .loadFilter(this.erweiterteSucheWertQuery);
          }
        }
      }
      return Promise.resolve();
    });
    form.onSaving.register((args) => {
      args.form.models.data.$m_Termin.Kopf.VeroeffentlichenFilter = this.erweiterteSucheWertQuery
        ? this._jsonService.stringify(this.erweiterteSucheWertQuery)
        : null;

      return Promise.resolve();
    });
    form.editPopups.onEditPopupHidden.register((args) => {
      if (args.editPopup.id === "zustandAendernEditPopup") {
        this.form.models.loadModelWithKeyId();
      } else if (args.editPopup.id === "terminEmpfEinschEditPopup") {
        this.form.models.reloadAll();
      }

      return Promise.resolve();
    });
    form.onEditorValueChanged.register((args) => {
      if (args.binding.dataContext == "$m_Termin"
        && args.binding.bindTo == "Kopf.IdGeschaeftspartner") {
        const selectBoxMeldenBei: IdxSelectBoxComponent = this.form["r_terminMeldenBei"];
        if (!selectBoxMeldenBei) {
          return;
        }

        this.loadMeldenBeiDataSource(args.value);
        this.loadTermineDataSource(args.value);

        selectBoxMeldenBei.setOption({ value: null });

        const selectBoxHaupttermin: IdxSelectBoxComponent = this.form["r_terminHaupttermin"];
        selectBoxMeldenBei.setOption({ value: null });
      }

      return Promise.resolve();
    });

    this._terminChangedSubscription = this._eventAggregator.subscribe("termin:changed", (ev) => {
      const data = this.form.models.data.$m_Termin;
      if (data && data.Id == ev.idTermin) {
        this.form.models.reloadAll();
      }
    });
  }
  unbind() {
    this._terminChangedSubscription.dispose();
    this._terminChangedSubscription = null;
  }

  async idSelectedEmpfaengerChanged(newVal) {
    if (!newVal) {
      return;
    }

    this.selectedEmpfaenger = {};

    await this.setEmpfaenger(newVal);
  }

  onPersonAddClick() {
    const lehrlingAuswaehlen: LehrlingAuswaehlen = this.form["r_lehrlingAuswaehlen"];
    lehrlingAuswaehlen.show({
      selectionMode: SelectionModeTyp.multipe,
      callback: (selectedPersons) => {
        selectedPersons.forEach(async(idPerson)=> {
          await this.setEmpfaenger(idPerson);
        });
      }
    });
  }
  onTrainerAddClick() {
    this._formPopupService.formPopup.show({
      titel: this._localizationService.translateOnce("zit.trainer"),
      data: {},
      formDef: "ansprechperson",
      onFormSubmit: (data) => {
        if (!this.form.models.data.$m_Termin.Trainer) {
          this.form.models.data.$m_Termin.Trainer = [];
        }

        const exists = this.form.models.data.$m_Termin.Trainer.some((t) => t.IdAnsprechperson == data.item.Id);
        if (exists) {
          return;
        }

        this.form.models.data.$m_Termin.Trainer.push({
          IdAnsprechperson: data.item.Id,
          Ansprechperson: {
            Titel: data.item.Titel,
            FunktionTagText: data.item.FunktionTagText
          }
        });
        this._modelUtilsService.setDirty(this.form.models.data.$m_Termin);
      }
    });
  }
  onStelleninseratAddClick() {
    this._formPopupService.formPopup.show({
      titel: this._localizationService.translateOnce("zit.stelleninserat"),
      data: {},
      formDef: "stelleninserat",
      onFormSubmit: (data) => {
        if (!this.form.models.data.$m_Termin.Kopf.Stelleninserate) {
          this.form.models.data.$m_Termin.Kopf.Stelleninserate = [];
        }

        const exists = this.form.models.data.$m_Termin.Kopf.Stelleninserate.some((t) => t.IdStelleninserat == data.item.Id);
        if (exists) {
          return;
        }

        this.form.models.data.$m_Termin.Kopf.Stelleninserate.push({
          IdStelleninserat: data.item.Id,
          Stelleninserat: {
            Id: data.item.Id,
            Card: data.item.Card
          }
        });

        this._modelUtilsService.setDirty(this.form.models.data.$m_Termin);
      }
    });
  }
  async onStelleninseratDeleteClick(ev: CustomEvent) {
    const r = await this._dialogService.askZuteilungLoeschen();
    if (!r) {
      return;
    }

    const index = this.form.models.data.$m_Termin.Kopf.Stelleninserate.indexOf(ev.detail.item);
    if (index < 0) {
      return;
    }

    this.form.models.data.$m_Termin.Kopf.Stelleninserate.splice(index, 1);
    this._modelUtilsService.setDirty(this.form.models.data.$m_Termin);
  }
  async onTerminPersonAusStelleninseratHinz() {
    const q = await this._dialogConfirmService.show({
      title: this._localizationService.translateOnce("base.question"),
      message: this._localizationService.translateOnce("termin.frage-person-aus-stelleninserat-hinzu")
    });

    if (!q) {
      return;
    }

    const r = await this.form.saveIfDirty();
    if (!r.isValid) {
      return;
    }

    await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Termin/EmpfAusStelleninseratHinzufuegen"),
      data: {
        IdTermin: this.form.models.data.$m_Termin.Id
      },
      increaseLoadingCount: true
    });

    this.form.models.reloadAll();
  }
  async onTerminEinschaetzungErstellenClick() {
    const r = await this.form.saveIfDirty();
    if (!r.isValid) {
      return;
    }

    this.form.models.data.$m_TerminEmpfEinschEdit = {
      Id: 0
    };
    this.form.editPopups.show("terminEmpfEinschEditPopup", null);
  }
  onStartDatumChanged() {
    const model = this.form.models.data.$m_Termin;

    if (!model || !model.Kopf || model.Kopf.EndeDatum) {
      return;
    }

    model.Kopf.EndeDatum = model.Kopf.StartDatum;
  }
  onExecuteSucheErweitert(event: any): void {
    this.erweiterteSucheWertQuery = event.detail.wertQueryList;
  }
  onDateiListChanged(ev: CustomEvent) {
    if (!ev.detail || !ev.detail.dateiList) {
      return;
    }

    const data = this.form.models.data.$m_Termin;
    this._modelUtilsService.setDirty(data);

    if (!data.Kopf.TerminDateien) {
      data.Kopf.TerminDateien = ev.detail.dateiList;
    }
  }

  private getErweiterteSuche(): ErweiterteSuche {
    return this.form["r_erweiterteSuche"];
  }
  private showErweiterteSucheContextMenu(event): void {
    this.getErweiterteSuche()
      .showVorlageContextMenu(event, true);
  }

  private async loadMeldenBeiDataSource(id: number) {
    const ansprechpersonList = await this._ansprechpersonService
      .loadAnsprechpersonByGeschaeftspartner(id, ["Name"]);

    this.meldenBeiDataSource = ansprechpersonList.map((c) => c.Name);
  }

  private async loadTermineDataSource(id: number) {
    this.terminDataSource = await this._restService.get({
      url: `${this._restService.getApiUrl("ZIT/Termin/HauptterminList")}?idGeschaeftspartner=${id}`
    });
  }

  private async modifyEmpfaengerList(empfaenger: any[]) {
    empfaenger.forEach((element) => {
      if (element.Person) {
        element.Titel = element.Person.Titel;
      }
    });
  }

  private deleteEmpfaenger(empfaenger: any) {
    const list: any[] = this.form.models.data.$m_Termin.Empfaenger;
    if (!list || !list.length) {
      return;
    }

    list.splice(list.indexOf(empfaenger), 1);
    this._modelUtilsService.setDirty(this.form.models.data.$m_Termin);
  }

  private async setPersonTitel(terminEmpfaenger: any) {
    if (!terminEmpfaenger.IdPerson) {
      return;
    }
    if (terminEmpfaenger.Titel && terminEmpfaenger.Titel.length > 0) {
      return;
    }

    const person = await this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Objekte/Person/".concat(terminEmpfaenger.IdPerson)),
      getOptions: {
        columns: ["Titel"]
      }
    });

    if (!person) {
      return;
    }

    terminEmpfaenger.Titel = person.Titel;
  }
  private async setEmpfaengerStatus(terminEmpfaenger: any) {
    if (!terminEmpfaenger.IdStatusZustand) {
      return;
    }

    const zustand = await this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Stammdaten/Zustand/".concat(terminEmpfaenger.IdStatusZustand)),
      getOptions: {
        columns: ["Farbe", "TagSymbol"]
      }
    });

    if (!zustand) {
      return;
    }

    terminEmpfaenger.StatusZustandFarbe = zustand.Farbe;
    terminEmpfaenger.StatusZustandTagSymbol = zustand.TagSymbol;
  }
  private async getEmpfaengerData(id: number): Promise<any> {
    if (!id) {
      return;
    }

    const empfaenger: any = {
      IdTermin: this.form.models.data.$m_Termin.Id,
      IdPerson: id
    };

    await this.setPersonTitel(empfaenger);

    if (this.selectedEmpfaenger) {
      empfaenger.Bemerkung = this.selectedEmpfaenger.Bemerkung;
      empfaenger.StatusZustandCode = this.selectedEmpfaenger._StatusZustandCode;
      empfaenger._StatusZustandCode = this.selectedEmpfaenger._StatusZustandCode;
    }

    return empfaenger;
  }

  private async setEmpfaenger(idPerson: number) {
    const list: any[] = this.form.models.data.$m_Termin.Empfaenger || [];
    const alreadyExists = list.some((c) => c.IdPerson === idPerson);
    if (alreadyExists) {
      return;
    }

    const empfaenger = await this.getEmpfaengerData(idPerson);

    list.push(empfaenger);

    this.form.models.data.$m_Termin.Empfaenger = list;
    this._modelUtilsService.setDirty(this.form.models.data.$m_Termin);
  }

  private async onEmpfaengerStatusGeaendert(data) {
    await this.setPersonTitel(data);
    await this.setEmpfaengerStatus(data);

    if (!this.form.models.data.$m_Termin.Empfaenger) {
      this.form.models.data.$m_Termin.Empfaenger = [];
    }

    const list: any[] = this.form.models.data.$m_Termin.Empfaenger;
    const indexOf = list.indexOf(data);
    if (indexOf >= 0) {
      return;
    }

    list.push(data);
  }
}
