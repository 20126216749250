import {
  autoinject, optional, Scope, OverrideContext
} from "aurelia-framework";
import {
  IDataSourceOptions,
  IDataSourceOptionFilter,
  IDataSourceCustomizationOptions,
  IDataSourceLastLoadInfo
} from "../interfaces/export";
import {
  RestService
} from "./rest-service";
import {
  BindingService
} from "./binding-service";
import {
  ScopeContainer
} from "../classes/scope-container";
import { ObjectService } from './object-service';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class DataSourceService {
  static _instance: DataSourceService;

  constructor(
    private rest: RestService,
    private binding: BindingService,
    private objectService: ObjectService,
    private eventAggregator: EventAggregator
  ) { 
    DataSourceService._instance = this;
  }

  //TODO - ein bisschen Refactoren ;-)
  createDataSource(
    scopeContainer: ScopeContainer,
    options: IDataSourceOptions,
    customizationOptions?: IDataSourceCustomizationOptions,
    loadRequiredAction?: { (): void }): DevExpress.data.DataSource {

    const dataSource = new DevExpress.data.DataSource(this.createDataStore(
      scopeContainer,
      options,
      customizationOptions,
      loadRequiredAction,
      (lastLoadInfo) => {
        const currentDataSource: any = dataSource;
        currentDataSource.lastLoadInfo = lastLoadInfo;
      }
    ));

    dataSource.requireTotalCount(true);

    let timeout = null;
    this.addObservers(scopeContainer, options, () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      timeout = setTimeout(() => {
        //nur machen, wenn die options keine id haben. Wenn doch, dann kümmern sich die Models selbst darum,
        //dass es zu einem Reload kommt
        if (options["id"] == void (0)) {
          if (dataSource.pageIndex() === 0) {
            dataSource.reload();
          } else {
            dataSource.pageIndex(0);
          }
        }

        if (loadRequiredAction) {
          loadRequiredAction();
        }
      }, 10);
    });

    return dataSource;
  }
  createDataStore(
    scopeContainer: ScopeContainer,
    options: IDataSourceOptions,
    customizationOptions?: IDataSourceCustomizationOptions,
    loadRequiredAction?: { (): void },
    setLastLoadAction?: { (lastLoadInfo): void }): DevExpress.data.CustomStore {
    let lastAccessInfo = {
      lastLoadInfo: null,
      resultPromise: null
    };

    return new DevExpress.data.CustomStore({
      key: options.keyProperty,
      byKey: (key) => {
        if (!DataSourceService._instance.canLoad(scopeContainer, options, customizationOptions)) {
          return Promise.resolve(null);
        }
  
        const getOptions = DataSourceService._instance.createGetOptions(scopeContainer, options, customizationOptions, true);
  
        //Bei byKey wird kein Where übergeben, da wir bereits auf einen fixen Key einschränken
        if (getOptions) {
          delete getOptions.where;
        }

        return DataSourceService._instance.rest.get({
          url: DataSourceService._instance.rest.getWebApiUrl(`${options.webApiAction}/${key}`),
          moduleId: this.getModuleId(scopeContainer),
          getOptions
        });
      },
      load: (loadOptions) => {
        let canLoad = DataSourceService._instance.canLoad(scopeContainer, options, customizationOptions);
  
        let getOptions: any;
        if (canLoad) {
          getOptions = DataSourceService._instance.createGetOptions(scopeContainer, options, customizationOptions);
          canLoad = getOptions != null;
        }
  
        if (!canLoad) {
          if (loadOptions.requireTotalCount) {
            return Promise.resolve({
              data: [],
              totalCount: 0
            });
          } else {
            return Promise.resolve([]);
          }
        }
  
        if (loadOptions.filter) {
          if (getOptions.where) {
            getOptions.where = [getOptions.where, loadOptions.filter];
          } else {
            getOptions.where = loadOptions.filter;
          }
        }
        if (loadOptions.searchExpr && loadOptions.searchOperation && loadOptions.searchValue) {
          if (options.webApiSearchtextEnabled) {
            getOptions.searchtext = loadOptions.searchValue;
          } else {
            const searchWhere = [loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue];
  
            if (getOptions.where) {
              getOptions.where = [getOptions.where, searchWhere];
            } else {
              getOptions.where = searchWhere;
            }
          }
        }
        if (options.webApiSearchtextEnabled && options.searchtext) {
          getOptions.searchtext = DataSourceService._instance.binding.evaluate(scopeContainer.scope, options.searchtext);
        }
  
        getOptions.skip = loadOptions.skip;
        getOptions.take = loadOptions.take;
        getOptions.requireTotalCount = loadOptions.requireTotalCount;
        
        if (loadOptions.totalSummary) {
          getOptions.totalSummary = loadOptions.totalSummary;
        }
  
        if (loadOptions.sort) {
          getOptions.orderBy = (<any[]>loadOptions.sort).map((data) => {
            return {
              columnName: data.selector,
              sortOrder: (data.desc === true ? 1 : 0)
            }
          });
        };
  
        const loadOptionsDataField = (<any>loadOptions).dataField;
        if (loadOptionsDataField) {
          delete getOptions.skip;
          delete getOptions.take;
          delete getOptions.expand;
          delete getOptions.requireTotalCount;
          delete getOptions.totalSummary;
          getOptions.columns = [loadOptionsDataField];
          getOptions.orderBy = [{ columnName: loadOptionsDataField, sortOrder: 0 }];
          getOptions.distinct = true;
        }
  
        const lastLoadInfo: IDataSourceLastLoadInfo = {
          getOptions: getOptions,
          url: DataSourceService._instance.rest.getWebApiUrl(options.webApiAction)
        };
  
        if (setLastLoadAction) {
          setLastLoadAction(lastLoadInfo);
        }
  
        //Wenn gerade erst ein gleiches Select abgestellt wurde, dann dieses zurückgeben ...
        if (lastAccessInfo.lastLoadInfo
          && JSON.stringify(lastAccessInfo.lastLoadInfo) === JSON.stringify(lastLoadInfo)) {
          return lastAccessInfo.resultPromise;
        } else {
          lastAccessInfo.lastLoadInfo = lastLoadInfo;
  
          const resultPromise = new Promise((resolve, reject) => {
            DataSourceService._instance.rest.get({
              url: lastLoadInfo.url,
              moduleId: this.getModuleId(scopeContainer),
              getOptions: lastLoadInfo.getOptions
            }).then(r => {
              if (loadOptionsDataField) {
                //Nested-Eigenschaften müssen in Objekte umgewandelt werden, damit HeaderFilter richtig funktioniert
                if (loadOptionsDataField.indexOf(".") > 0) {
                  const tokens = loadOptionsDataField.split(".");
  
                  r.forEach(i => {
                    let value = i;
                    tokens.forEach((token, index) => {
                      if (index + 1 === tokens.length) {
                        value[token] = i[loadOptionsDataField];
                      } else {
                        value[token] = {};
                        value = value[token];
                      }
                    });
                  });
                }
              } else {
                if (customizationOptions && customizationOptions.resultInterceptor) {
                  r = customizationOptions.resultInterceptor(r);
                }
              }
  
              let result;
              if (getOptions.requireTotalCount || getOptions.totalSummary) {
                result = {
                  data: r.rows
                };

                if (getOptions.requireTotalCount) {
                  result.totalCount = r.count;
                }
                if (getOptions.totalSummary) {
                  result.summary = r.summary;
                }
              } else {
                result = r;
              }
  
              resolve(result);
            }).catch(reject);
          });
  
          lastAccessInfo.resultPromise = resultPromise;

          if (customizationOptions && customizationOptions.onLoaded) {
            resultPromise.then((r) => {
              customizationOptions.onLoaded(r);
            });
          }
  
          setTimeout(() => {
            if (lastAccessInfo.lastLoadInfo == lastLoadInfo) {
              lastAccessInfo = {
                lastLoadInfo: null,
                resultPromise: null
              }
            }
          }, 500);
  
          return resultPromise;
        }
      }
    });
  }
  createGetOptions(
    scopeContainer: ScopeContainer, 
    options: IDataSourceOptions, 
    customizationOptions?: IDataSourceCustomizationOptions, 
    ignoreWhere?: boolean): any {
    const getOptions: any = {};
    getOptions.columns = options.webApiColumns;
    getOptions.expand = options.webApiExpand;
    getOptions.orderBy = options.webApiOrderBy;

    if (!ignoreWhere && ((options.webApiWhere && options.webApiWhere.length) || (customizationOptions && customizationOptions.getCustomWhere))) {
      const where = [];
      const input = [];

      if (options.webApiWhere) {
        input.push(options.webApiWhere);
      }
      if (customizationOptions && customizationOptions.getCustomWhere) {
        const customWhere = customizationOptions.getCustomWhere();
        if (customWhere) {
          input.push(customWhere);
        }
      }

      if (!this.constructWhere(scopeContainer, input, where)) {
        return null;
      }

      if (where.length > 0) {
        getOptions.where = where;
      }
    }

    if ((options.filters && options.filters.length) || (customizationOptions && customizationOptions.getCustomFilters)) {
      const customs = [];
      const where = [];

      if (!this.constructFilters(scopeContainer, options, customizationOptions, customs, where)) {
        return null;
      }

      if (customs.length > 0) {
        getOptions.customs = customs;
      }
      if (!ignoreWhere && where.length > 0) {
        if (getOptions.where) {
          getOptions.where = [getOptions.where, where];
        } else {
          getOptions.where = where;
        }
      }
    }

    if (customizationOptions && customizationOptions.getSearchText) {
      getOptions.searchtext = customizationOptions.getSearchText();
    }

    if (options.webApiMaxRecords > 0) {
      getOptions.maxRecords = options.webApiMaxRecords;
    }

    return getOptions;
  }

  getDataSourceKeyValues(
    dataSource: DevExpress.data.DataSource, 
    optionsPrepareCallback?: { (options): void },
    scopeContainer?: ScopeContainer): Promise<any[]> {
    const lastLoadInfo = this.getLastLoadInfo(dataSource);
    if (lastLoadInfo == null) {
      return Promise.resolve([]);
    }

    const options = this.objectService.mergeDeep({}, lastLoadInfo.getOptions);
    const key = dataSource.key();

    options.columns = [key];
    delete options.take;
    delete options.skip;
    delete options.expand;
    delete options.orderby;
    delete options.requireTotalCount;

    if (optionsPrepareCallback) {
      optionsPrepareCallback(options);
    }

    return this.rest.get({
      url: lastLoadInfo.url,
      moduleId: this.getModuleId(scopeContainer),
      getOptions: options
    }).then(r => {
      return r.map(item => item[key]);
    });
  }

  addObservers(
    scopeContainer: ScopeContainer, 
    options: IDataSourceOptions, 
    action: { (): void }) {
    const observers = this.getElementsToObserve(options);

    for (let observer of observers) {
      this.binding.observe({
        scopeContainer: scopeContainer,
        expression: observer, 
        callback: action
      });
    }
  }
  getElementsToObserve(options: IDataSourceOptions): string[] {
    const result = [];

    this.evalElementsToObserveWhere(options.webApiWhere, result);
    this.evalElementsToObserveDetail(options.searchtext, result);

    if (options.filters) {
      for (let item of options.filters) {
        if (typeof item.if === "string") {
          this.evalElementsToObserveDetail(item.if, result);
        }
        if (typeof item.webApiCustomValue === "string") {
          this.evalElementsToObserveDetail(item.webApiCustomValue, result);
        }

        this.evalElementsToObserveWhere(item.webApiWhere, result);
      }
    }

    return result;
  }

  getLastLoadInfo(dataSource: DevExpress.data.DataSource) {
    return dataSource["lastLoadInfo"];
  }

  private evalElementsToObserveWhere(data: any, result: string[]): void {
    if (data == void (0)) {
      return;
    }

    if (Array.isArray(data)) {
      (<any[]>data).forEach(item => this.evalElementsToObserveWhere(item, result));
    } else if (typeof data === "object") {
      if (data.isBound === true && data.expression != void (0)) {
        this.evalElementsToObserveDetail(data.expression, result);
      } else {
        for (let property in data) {
          this.evalElementsToObserveWhere(data[property], result);
        }
      }
    }
  }
  private evalElementsToObserveDetail(expression: string, result: string[]): void {
    if (expression == void (0)) {
      return;
    }

    result.push(expression);
  }

  private canLoad(
    scopeContainer: ScopeContainer, 
    options: IDataSourceOptions, 
    customizationOptions: IDataSourceCustomizationOptions) {
    if (!scopeContainer || !scopeContainer.scope) {
      return false;
    }

    if (options && options.allowLoad) {
      const allowLoad = this.binding.evaluate(
        scopeContainer.scope,
        options.allowLoad
      );

      if (!allowLoad) {
        return false;
      }
    }

    return !customizationOptions
      || !customizationOptions.canLoad
      || customizationOptions.canLoad();
  }
  private constructWhere(
    scopeContainer: ScopeContainer, 
    data: any, 
    where: any[]): boolean {
    if (data == void (0)) {
      return true;
    }

    if (Array.isArray(data)) {
      const newArr = [];
      where.push(newArr);

      let cancel = false;
      (<any[]>data).forEach(item => {
        if (!this.constructWhere(scopeContainer, item, newArr)) {
          cancel = true;
        }
      });

      if (cancel) {
        return false;
      }
    } else if (typeof data === "object" && !(data instanceof Date)) {
      if (data.isBound === true && data.expression != void (0)) {
        const val = this.binding.evaluate(scopeContainer.scope, data.expression);
        if (val == void (0)) {
          return false;
        }

        where.push(val);
      } else {
        for (let property in data) {
          if (!this.constructWhere(scopeContainer, data[property], where)) {
            return false;
          }
        }
      }
    } else {
      where.push(data);
    }

    return true;
  }
  private constructFilters(
    scopeContainer: ScopeContainer, 
    options: IDataSourceOptions, 
    customizationOptions: IDataSourceCustomizationOptions, 
    customs: any[], 
    where: any[]): boolean {
    const filters: IDataSourceOptionFilter[] = [];

    if (options.filters) {
      filters.push(...options.filters);
    }
    if (customizationOptions && customizationOptions.getCustomFilters) {
      const customFilters = customizationOptions.getCustomFilters();
      if (customFilters) {
        filters.push(...customFilters);
      }
    }

    for (let item of filters) {
      if (item.if) {
        if (!this.binding.evaluate(scopeContainer.scope, item.if)) {
          continue;
        }
      }

      if (item.webApiCustomKey && item.webApiCustomValue) {
        const value = typeof item.webApiCustomValue === "string"
          ? this.binding.evaluate(scopeContainer.scope, item.webApiCustomValue)
          : item.webApiCustomValue;

        if (value == void (0)) {
          return false;
        }

        customs.push({
          key: item.webApiCustomKey,
          value: value
        });
      } else if (item.webApiWhere) {
        const w = [];
        if (!this.constructWhere(scopeContainer, item.webApiWhere, w)) {
          return false;
        }

        where.push(w);
      }
    }

    return true;
  }

  private getModuleId(scopeContainer: ScopeContainer) {
    if (!scopeContainer) {
      return null;
    }
    if (!scopeContainer.scope) {
      return;
    }
    
    const args = {
      scope: scopeContainer.scope,
      treatEditPopupAsMainForm: true,
      moduleId: null
    };
    
    this.eventAggregator.publish("form:get-main-form-module-id", args);
    return args.moduleId;
  }
}
