import {
  autoinject,
  observable
} from "aurelia-framework";
import {
  StyleService
} from "../../base/services/export";
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';

@autoinject
export class LayoutService {
  constructor(
    private style: StyleService,
    private eventAggregator: EventAggregator
  ) {}

  @observable isSidebarCollapsed = false;
  themeColor = "#396394";

  activateTheme() {
    this.style.addStyles("custom-container", [{
      name: ".t--sidebar .t--sidebar-item:hover",
      properties: [{
        propertyName: "background-color",
        value: this.themeColor
      }]
    }]);
  }
  isSidebarCollapsedChanged() {
    setTimeout(() => {
      this.eventAggregator.publish("window:resize", () => {});
    }, 400);
  }
}
