import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { BrowserService } from "../../framework/base/export";
import { RestService } from "../../framework/base/services/rest-service";
import { IObjektInfoData } from "./../interfaces/export";
import { StartupService } from "./startup-service";
@autoinject
export class ObjektInfoService {
  private _entitaetInfoList: IObjektInfoData[];

  constructor(
    private _eventAggregator: EventAggregator,
    private _restService: RestService,
    private _startupService: StartupService,
    private _browserService: BrowserService
  ) {}

  getObjektByBezeichnung(bezeichnung: string): IObjektInfoData {
    return this._entitaetInfoList.find((e) => e.Bezeichnung === bezeichnung);
  }
  getObjektByFullname(fullname: string): IObjektInfoData {
    return this._entitaetInfoList.find((e) => e.Fullname === fullname);
  }
  getObjektByName(name: string): IObjektInfoData {
    return this._entitaetInfoList.find((e) => e.Fullname.endsWith("." + name));
  }
  getObjektByWebApiUrl(webApiUrl: string): IObjektInfoData {
    return this._entitaetInfoList.find((e) => e.WebApiUrl === webApiUrl);
  }
  getObjektNavigationUrl(fullname: string): string {
    if (!fullname) {
      return null;
    }

    const info = this.getObjektByFullname(fullname);
    if (!info) {
      return null;
    }
    if (!info.NavigationUrl) {
      return null;
    }

    return info.NavigationUrl;
  }

  isObjektInfoLoaded(): boolean {
    return !!this._entitaetInfoList;
  }

  async loadObjektInfoList() {
    this._entitaetInfoList = await this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Berechtigungen/Objekttyp")
    });

    this._entitaetInfoList.forEach((item) => {
      this.updateBezeichnung(item);

      switch (item.Fullname) {
        case "TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson": {
          item.NavigationUrl = "#Objekte/Ansprechperson";
          item.WebApiUrl = "ZIT/Objekte/Ansprechperson";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung": {
          item.NavigationUrl = "#Objekte/Bewerbung";
          item.WebApiUrl = "ZIT/Objekte/Bewerbung";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstelle": {
          item.NavigationUrl = "#Objekte/DispoPlanstelle";
          item.WebApiUrl = "ZIT/Objekte/DispoPlanstelle";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstellePersonenzuteilung": {
          item.NavigationUrl = "#Objekte/DispoPlanstellePersonenzuteilung";
          item.WebApiUrl = "ZIT/Objekte/DispoPlanstellePersonenzuteilung";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.Geschaeftspartner.Geschaeftspartner": {
          item.NavigationUrl = "#Objekte/Geschaeftspartner";
          item.WebApiUrl = "ZIT/Objekte/Geschaeftspartner";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.Personen.Person": {
          item.NavigationUrl = "#Objekte/Person";
          item.WebApiUrl = "ZIT/Objekte/Person";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.Stelleninserate.Stelleninserat": {
          item.NavigationUrl = "#Objekte/Stelleninserat";
          item.WebApiUrl = "ZIT/Objekte/Stelleninserat";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.ProfilFirmen.ProfilFirma": {
          item.NavigationUrl = "#Objekte/ProfilFirma";
          item.WebApiUrl = "ZIT/Objekte/ProfilFirma";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.Termine.Termin": {
          item.NavigationUrl = "#Objekte/Termin";
          item.WebApiUrl = "ZIT/Objekte/Termin";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.Aufgaben.Aufgabe": {
          item.NavigationUrl = "#Objekte/Aufgabe";
          item.WebApiUrl = "ZIT/Objekte/Aufgabe";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.Ziele.Ziel": {
          item.NavigationUrl = "#Objekte/Ziel";
          item.WebApiUrl = "ZIT/Objekte/Ziel";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.Events.Event": {
          item.NavigationUrl = "#Objekte/Event";
          item.WebApiUrl = "ZIT/Objekte/Event";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.Events.EventZeitraum": {
          item.NavigationUrl = "#Objekte/EventZeitraum";
          item.WebApiUrl = "ZIT/Objekte/EventZeitraum";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.Events.EventTeilnehmer": {
          item.NavigationUrl = "#Objekte/EventTeilnehmer";
          item.WebApiUrl = "ZIT/Objekte/EventTeilnehmer";
          break;
        }
        case "TIP.ZIT.Business.Entities.Objekte.Informationen.Information": {
          item.NavigationUrl = "#Objekte/Information";
          item.WebApiUrl = "ZIT/Objekte/Information";
          break;
        }
        default: {
          break;
        }
      }
    });

    this._eventAggregator.publish(
      "objekt-info:loaded", {});
  }
  private updateBezeichnung(objektInfo: IObjektInfoData) {
    const url = "route:Suche?TypeName=".concat(objektInfo.Fullname);
    
    const menu = this._startupService.startupInfo.MenuList.find((m) => m.Route == url);
    if (!menu) {
      return;
    }

    objektInfo.Bezeichnung = this._browserService.isMobile
      ? menu.BezeichnungMobil
      : menu.BezeichnungDesktop;
  }
}
