import { autoinject, bindable, computedFrom, observable } from "aurelia-framework";
import * as moment from "moment";
import { DataSourceService, JsonService, LocalizationService, RestService } from "../../../framework/base/services/export";
import { LocationService } from "../../../framework/base/services/location-service";
import { FormBase } from "../../../framework/forms/classes/form-base";
import { ListView } from "../../../framework/forms/elements/list-view/export";
import { ContextMenu } from "../../../framework/forms/export";
import { BenutzeraccountTyp, BenutzerTyp, SucheViewTyp } from "../../enumerations/export";
import { TerminTyp } from "../../enumerations/termin-typ";
import { IdxDataGridComponent, IStatusChangeResultData } from "../../interfaces/export";
import { AufgabeService } from "../../services/aufgabe-service";
import { BenutzereinstellungenService } from "../../services/benutzereinstellungen-service";
import { CustomEditPopupService } from "../../services/custom-edit-popup-service";
import { BerechtigungService, DownloadService, FormRefreshService, ObjektExportEigenschaftService, StartupService } from "../../services/export";
import { FormPopupService } from "../../services/form-popup-service";
import { StelleninseratService } from "../../services/stelleninserat-service";
import { EinsatzvorbereitungHinzufuegen } from "../einsatzvorbereitung-hinzufuegen/einsatzvorbereitung-hinzufuegen";
import { SchnupperterminTagList } from "../schnuppertermin-tag-list/schnuppertermin-tag-list";
import { StelleninseratAuswahl } from "../stelleninserat-auswahl/stelleninserat-auswahl";
import { TerminReaktion } from "./../../enumerations/termin-reaktion";
import { Terminverteilungstyp } from "./../../enumerations/terminverteilungs-typ";
import { NachrichtService } from "./../../services/nachricht-service";
import { BenutzerSperren } from "./../benutzer-sperren/benutzer-sperren";
import { BewerbungUmbuchen } from "./../bewerbung-umbuchen/bewerbung-umbuchen";
import { BewerbungstypAendern } from "./../bewerbungstyp-aendern/bewerbungstyp-aendern";
import { DokumentLautVorlageErstellen } from "./../dokument-laut-vorlage-erstellen/dokument-laut-vorlage-erstellen";
import { MarkerMultiObjekt } from "./../marker-multi-objekt/marker-multi-objekt";
import { NachrichtErstellen } from "./../nachricht-erstellen/nachricht-erstellen";
import { PersonDossier } from "./../person-dossier/person-dossier";
import { StandardStelleninseratErstellen } from "./../standard-stelleninserat/standard-stelleninserat-erstellen";
import { StatusAendern } from "./../status-aendern/status-aendern";
import { TableauUtils } from "../../views/tableau/tableau-utils";
import { ViewType as TableauViewType } from "../../views/tableau/elements/tableau/tableau";

@autoinject
export class Share {
  private NOTIFY_TIMEOUT= 3000;
  private ID_MANDANT_HEARTBASE = 5;

  constructor(
    private _element: Element,
    private _benutzereinstellungenService: BenutzereinstellungenService,
    private _stelleninseratService: StelleninseratService,
    private _dataSourceService: DataSourceService,
    private _downloadService: DownloadService,
    private _jsonService: JsonService,
    private _localizationService: LocalizationService,
    private _objektExportEigenschaftService: ObjektExportEigenschaftService,
    private _startupService: StartupService,
    private _customEditPopupService: CustomEditPopupService,
    private _locationService: LocationService,
    private _restService: RestService,
    private _nachrichtService: NachrichtService,
    private _formRefreshService: FormRefreshService,
    private _berechtigungService: BerechtigungService,
    private _aufgabeService: AufgabeService,
    private _formPopupService: FormPopupService
  ) { }

  @bindable @observable viewTyp: SucheViewTyp;
  @bindable dataGrid: IdxDataGridComponent;
  @bindable listView: ListView;
  @bindable @observable currentObjekt: any;
  @bindable @observable objektFullName: string;
  @bindable isStatusAendernHidden: boolean;

  markerMultiObjekt: MarkerMultiObjekt;
  personDossier: PersonDossier;
  benutzerSperren: BenutzerSperren;
  statusAendern: StatusAendern;
  bewerbungUmbuchen: BewerbungUmbuchen;
  standardStelleninseratErstellen: StandardStelleninseratErstellen;
  dokumentLautVorlageErstellen: DokumentLautVorlageErstellen;
  nachrichtErstellen: NachrichtErstellen;
  einsatzvorbereitungHinzufuegen: EinsatzvorbereitungHinzufuegen;
  schnupperterminTagList: SchnupperterminTagList;
  stelleninseratAuswahl: StelleninseratAuswahl;
  bewerbungstypAendern: BewerbungstypAendern;

  @bindable form: FormBase;
  selectedKeys: number[];
  canShare: boolean;

  @computedFrom("objektFullName")
  get canCreatePersonenDossier() {
    const canUsePersonenDossier = this._startupService.startupInfo.Benutzer.CanUsePersonenDossier;

    if (!canUsePersonenDossier) {
      return false;
    }

    if (this.isTableau) {
      const tableau: TableauUtils = this.form.functions["$f_TableauUtils"];

      switch (tableau.currentViewType) {
        case TableauViewType.TerminEmpfaenger:
        case TableauViewType.EventTeilnehmer:
        case TableauViewType.EventSchnuppertermin:
        case TableauViewType.Schnuppertermin: {
          return true;
        }
        default: {
          return false;
        }
      }
    } else {
      switch (this.objektFullName) {
        case "TIP.ZIT.Business.Entities.Objekte.Personen.Person":
        case "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung":
        case "TIP.ZIT.Business.Entities.Objekte.Events.EventTeilnehmer":
        case "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstellePersonenzuteilung": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }
  @computedFrom("objektFullName")
  get canBenutzeraccountVorbereitet() {
    const canBenutzeraccountAktivieren = this._startupService.startupInfo.Benutzer.CanBenutzeraccountAktivieren;

    if (!canBenutzeraccountAktivieren) {
      return false;
    }

    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Berechtigungen.Benutzer": {
        break;
      }
      default: {
        return false;
      }
    }

    if (!this.currentObjekt) {
      return false;
    }

    return !!this.currentObjekt.CanBenutzeraccountVorbereitet;
  }
  @computedFrom("objektFullName")
  get canBenutzeraccountAktivieren() {
    const canBenutzeraccountAktivieren = this._startupService.startupInfo.Benutzer.CanBenutzeraccountAktivieren;

    if (!canBenutzeraccountAktivieren) {
      return false;
    }

    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Berechtigungen.Benutzer":
      case "TIP.ZIT.Business.Entities.Objekte.Personen.Person":
      case "TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson":
      case "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung": {
        break;
      }
      default: {
        return false;
      }
    }

    //Hier wird nicht separat geprüft, sondern erst beim Server beim Abarbeiten
    if (this.viewTyp) {
      return true;
    }

    return !!this.currentObjekt.CanBenutzeraccountAktivieren;
  }
  @computedFrom("objektFullName")
  get canBenutzeraccountAktivierenMail() {
    const canBenutzeraccountAktivieren = this._startupService.startupInfo.Benutzer.CanBenutzeraccountAktivieren;

    if (!canBenutzeraccountAktivieren) {
      return false;
    }

    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Berechtigungen.Benutzer":
      case "TIP.ZIT.Business.Entities.Objekte.Personen.Person":
      case "TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson":
      case "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung": {
        break;
      }
      default: {
        return false;
      }
    }

    if (!this.currentObjekt) {
      return false;
    }

    return !!this.currentObjekt.CanBenutzeraccountAktivierenMail
      && this.currentObjekt.IdBenutzer;
  }
  @computedFrom("objektFullName")
  get canBenutzerOeffnen() {
    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Personen.Person":
      case "TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson": {
        break;
      }
      default: {
        return false;
      }
    }

    if (!this.currentObjekt) {
      return false;
    }
    if (!this.currentObjekt.IdBenutzer) {
      return false;
    }

    if (!this._berechtigungService.hasBerechtigungModul("zit/views/berechtigungen/benutzer/benutzer-edit-form")) {
      return false;
    }

    return true;
  }
  @computedFrom("objektFullName")
  get canMarkerSetzen() {
    if (!this._startupService.startupInfo.Benutzer.CanUseMarkers) {
      return false;
    }
    if (!this.objektFullName) {
      return false;
    }
    if (!this.objektFullName.startsWith("TIP.ZIT.Business.Entities.Objekte")) {
      return false;
    }

    return true;
  }
  @computedFrom("objektFullName")
  get canBenutzerZusammenfuehren() {
    if (this.objektFullName != "TIP.ZIT.Business.Entities.Berechtigungen.Benutzer") {
      return false;
    }
    if (!this.currentObjekt) {
      return false;
    }
    if (!this.currentObjekt._HasLoginprofilNatuerlichePerson) {
      return false;
    }

    return true;
  }
  @computedFrom("objektFullName")
  get canBenutzeraccountSperren() {
    const canBenutzeraccountSperren = this._startupService.startupInfo.Benutzer.CanBenutzeraccountSperren;

    if (!canBenutzeraccountSperren) {
      return false;
    }

    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Berechtigungen.Benutzer": {
        break;
      }
      default: {
        return false;
      }
    }

    if (!this.currentObjekt) {
      return true;
    }

    return !!this.currentObjekt.CanBenutzeraccountSperren;
  }
  @computedFrom("objektFullName")
  get canDokumentErstellen() {
    if (!this.objektFullName) {
      return false;
    }

    return this._startupService.startupInfo.Benutzer.DruckvorlageObjektFullNameList
      && this._startupService.startupInfo.Benutzer.DruckvorlageObjektFullNameList.indexOf(this.objektFullName) >= 0;
  }
  @computedFrom("objektFullName")
  get canStelleninseratVorschauAnzeigen() {
    const model = this.form.models.data.$m_Stelleninserat;

    return this.objektFullName
      ? this.objektFullName === "TIP.ZIT.Business.Entities.Objekte.Stelleninserate.Stelleninserat" && model && model.StelleninseratVorschauUrl
      : false;
  }
  @computedFrom("objektFullName")
  get canCreateStandardStelleninserat() {
    const canStandardStelleninseratErstellen = this._startupService.startupInfo.Benutzer.CanStandardStelleninseratErstellen;
    if (!canStandardStelleninseratErstellen) {
      return false;
    }

    return this.objektFullName
      ? this.objektFullName === "TIP.ZIT.Business.Entities.Objekte.Geschaeftspartner.Geschaeftspartner"
      : false;
  }
  @computedFrom("currentObjekt")
  get canChangeStatus(): boolean {
    if (!this.currentObjekt) {
      return true;
    }

    return !!this.currentObjekt._CanChangeObjektStatus;
  }
  @computedFrom("currentObjekt")
  get canCopyStelleninserat(): boolean {
    switch (this._startupService.startupInfo.Benutzer.Benutzertyp) {
      case BenutzerTyp.Administrator:
      case BenutzerTyp.InternerBenutzer: {
        return this.objektFullName === "TIP.ZIT.Business.Entities.Objekte.Stelleninserate.Stelleninserat";
      }
      default: {
        return false;
      }
    }
  }
  @computedFrom("objektFullName")
  get canTerminErstellen() {
    const canTerminErstellen = this._startupService.startupInfo.Benutzer.CanTerminErstellen;

    if (!canTerminErstellen) {
      return false;
    }

    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Stelleninserate.Stelleninserat":
      case "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung":
      case "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstellePersonenzuteilung": {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  @computedFrom("objektFullName")
  get canMassennachrichtenErstellen() {
    return this._nachrichtService.canMassennachrichtErstellen(this.objektFullName);
  }
  @computedFrom("objektFullName")
  get canBewerbungUmbuchen() {
    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Stelleninserate.Stelleninserat":
      case "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung": {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  @computedFrom("objektFullName")
  get canEinsatzvorbereitungHinzufuegen() {
    if (this._startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.NatuerlichePerson) {
      return false;
    }

    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstellePersonenzuteilung": {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  @computedFrom("objektFullName")
  get canShowSchnupperterminTagList() {
    const benutzerrolleList = ["ADMIN", "KUNDE-SCHULE", "KUNDE"];
    if (benutzerrolleList.indexOf(this._startupService.startupInfo.Benutzer.CodeBenutzerrolle) < 0) {
      return false;
    }

    if (!this._startupService.startupInfo.Mandant.ShowSchnupperterminMaske) {
      return false;
    }

    if (!this.isTableau) {
      return false;
    }

    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Termine.Termin": {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  @computedFrom("objektFullName")
  get canBewerbungstypStelleninseratAendern() {
    if (this.objektFullName != "TIP.ZIT.Business.Entities.Objekte.Stelleninserate.Stelleninserat") {
      return false;
    }
    if (this._startupService.startupInfo.Benutzer.CodeBenutzerrolle != "ADMIN") {
      return false;
    }
    return true;
  }
  @computedFrom("objektFullName")
  get canPlanstelleKopieren() {
    if (this._startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.NatuerlichePerson) {
      return false;
    }

    if (!this.form) {
      return false;
    }
    if (this.form.id != "dispo-planstelle-edit") {
      return false;
    }

    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstelle": {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  @computedFrom("objektFullName")
  get canCreateSchueler() {
    const benutzerrolleList = ["ADMIN", "KUNDE-SCHULE", "KUNDE"];
    if (benutzerrolleList.indexOf(this._startupService.startupInfo.Benutzer.CodeBenutzerrolle) < 0) {
      return false;
    }

    if (!this._startupService.startupInfo.Mandant.ShowSchnupperterminMaske) {
      return false;
    }

    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Termine.Termin": {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  @computedFrom("objektFullName")
  get canBewerbungsseiteOeffnen() {
    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Stelleninserate.Stelleninserat": {
        break;
      }
      default: {
        return false;
      }
    }

    if (!this.currentObjekt) {
      return false;
    }
    if (!this.currentObjekt._UrlBewerbung) {
      return false;
    }

    return true;
  }
  @computedFrom("objektFullName")
  get canshowLoginprotokoll() {
    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Berechtigungen.Benutzer": {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  @computedFrom("objektFullName")
  get canTerminEmpfEinschaetzungErstellen() {
    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Termine.Termin": {
        break;
      }
      default: {
        return false;
      }
    }

    if (this.form.id != "termin-heartbase-edit" && this.form.id != "termin-heartbase-read") {
      return false;
    }

    if (!this.currentObjekt) {
      return false;
    }

    if (this.currentObjekt.Kopf.Typ != TerminTyp.College) {
      return false;
    }

    const benutzerrolleCodes = ["ADMIN_HB", "ADMIN", "TRAINER", "TEAM"];
    if (this._startupService.startupInfo.Benutzer.Benutzertyp != BenutzerTyp.Administrator && benutzerrolleCodes.indexOf(this._startupService.startupInfo.Benutzer.CodeBenutzerrolle) < 0) {
      return false;
    }

    return true;
  }
  @computedFrom("objektFullName")
  get canTerminPersonAusStelleninseratHinzu() {
    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Termine.Termin": {
        break;
      }
      default: {
        return false;
      }
    }

    if (!this.currentObjekt) {
      return false;
    }

    if (!this.currentObjekt.Kopf) {
      return false;
    }
    if (!this.currentObjekt.Kopf.Stelleninserate || this.currentObjekt.Kopf.Stelleninserate.length == 0) {
      return false;
    }

    const benutzerrolleCodes = ["ADMIN_HB", "ADMIN", "TRAINER", "TEAM"];
    if (this._startupService.startupInfo.Benutzer.Benutzertyp != BenutzerTyp.Administrator && benutzerrolleCodes.indexOf(this._startupService.startupInfo.Benutzer.CodeBenutzerrolle) < 0) {
      return false;
    }

    return true;
  }
  @computedFrom("objektFullName")
  get canAufgabeZuweisen() {
    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung":
      case "TIP.ZIT.Business.Entities.Objekte.Personen.Person":
      case "TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson":
      case "TIP.ZIT.Business.Entities.Objekte.Stelleninserate.Stelleninserat":
      case "TIP.ZIT.Business.Entities.Objekte.Termine.Termin": {
        break;
      }
      default: {
        return false;
      }
    }

    if (!this._startupService.startupInfo.CanAufgabenZuweisen) {
      return false;
    }
    if (!this._berechtigungService.hasBerechtigung("TIP.ZIT.Business.Entities.Objekte.Aufgaben.Aufgabe")) {
      return false;
    }

    return true;
  }
  @computedFrom("objektFullName")
  get canAusbilderErsetzen() {
    if (this.objektFullName != "TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson") {
      return false;
    }

    if (!this.currentObjekt) {
      return false;
    }
    if (!this.currentObjekt.IsAusbilder) {
      return false;
    }

    if (this._startupService.startupInfo.Mandant.IdMandant != this.ID_MANDANT_HEARTBASE) {
      return false;
    }

    const benutzertyp = this._startupService.startupInfo.Benutzer.Benutzertyp;
    if (benutzertyp != BenutzerTyp.Administrator && benutzertyp != BenutzerTyp.InternerBenutzer) {
      return false;
    }

    return true;
  }
  @computedFrom("objektFullName")
  get canCreateZiel() {
    if (this.objektFullName != "TIP.ZIT.Business.Entities.Objekte.Termine.Termin") {
      return false;
    }
    if (!this.currentObjekt) {
      return false;
    }
    if (!this.currentObjekt.Id) {
      return false;
    }
    if (!this._berechtigungService.hasBerechtigungNew("TIP.ZIT.Business.Entities.Objekte.Ziele.Ziel")) {
      return false;
    }

    return true;
  }

  get isTableau() {
    return this.form.id == "tableauList";
  }

  bind(form: FormBase) {
    if (form instanceof FormBase) {
      this.form = form;
    }
  }
  attached() {
    this.refreshCanShare();
  }
  reloadData(reloadSuche: boolean = true) {
    switch (this.viewTyp) {
      case SucheViewTyp.table: {
        if (reloadSuche) {
          this.dataGrid.instance.refresh();
        }
        break;
      }
      case SucheViewTyp.list: {
        if (reloadSuche) {
          this.listView.refresh();
        }
        break;
      }
      default: {
        if (this.currentObjekt && this.form && this.form.models) {
          this.form.models.reloadAll();
        }
        break;
      }
    }
  }
  showCtxMenu(event: Event) {
    const ctxMenu = new ContextMenu();
    this.loadCtxMenuItems(ctxMenu);

    ctxMenu.show(event.target);
  }
  // tslint:disable-next-line: cyclomatic-complexity
  loadCtxMenuItems(ctxMenu: ContextMenu) {
    if (!ctxMenu) {
      return;
    }

    const benutzer = this._startupService.startupInfo.Benutzer;
    const hasViewTyp = !!this.viewTyp;
    const hasSelection = this.getSelectedKeys().length > 0;

    if (hasViewTyp) {
      ctxMenu.items.push({
        text: this._localizationService.translateOnce("share.alle_auswaehlen"),
        execute: () => {
          this.selectAll();
        }
      });
    }

    if (hasSelection && hasViewTyp) {
      ctxMenu.items.push({
        text: this._localizationService.translateOnce("share.auswahl_entfernen"),
        execute: () => {
          this.unselectAll();
        }
      });
    }
    if (hasSelection && hasViewTyp) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.excel_export"),
        execute: () => {
          this.exportExcel();
        }
      });
    }

    if (hasSelection && this.canStelleninseratVorschauAnzeigen) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.stelleninserat_vorschau_anzeigen"),
        execute: () => {
          this.stelleninseratVorschauAnzeigen();
        }
      });
    }

    if (hasSelection && this.canBenutzeraccountAktivierenMail) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.benutzeraccount-aktivierungsmail-versenden"),
        execute: () => {
          this.benutzeraccountAktivierenMail();
        }
      });
    }

    if (hasSelection && this.canBenutzerOeffnen) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.benutzerdaten"),
        execute: () => {
          this._locationService.goTo({
            url: `Berechtigungen/Benutzer/${this.currentObjekt.IdBenutzer}`,
            currentViewModel: this.form
          });
        }
      });
    }
    if (hasSelection && this.canMassennachrichtenErstellen) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.nachricht_senden"),
        execute: async() => {
          const r = await this.form.saveIfDirty();
          if (!r.isValid) {
            return;
          }

          this._nachrichtService.doNachrichtErstellenFromObjekt(this.getSelectedKeys(), this.objektFullName);
        }
      });
    }
    if (hasSelection && this.canCreatePersonenDossier) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.dossier_export"),
        execute: () => {
          this.showPersonDossierPopup();
        }
      });
    }
    if (hasSelection && this.canMarkerSetzen) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.marker_setzen"),
        execute: () => {
          this.showMarkers();
        }
      });
    }
    if (hasSelection && this.canBenutzeraccountVorbereitet) {
      ctxMenu.items.push({
        beginGroup: true,
        text: "Benutzer auf \"vorbereitet\" zurücksetzen",
        execute: () => {
          this.vorbereitetBenutzeraccountList();
        }
      });
    }
    if (hasSelection && this.canBenutzeraccountAktivieren) {      
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.benutzeraccount_aktiviern"),
        execute: () => {
          this.activateBenutzeraccountList();
        }
      });
    }
    if (hasSelection && this.canBenutzeraccountSperren) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.benutzeraccount_sperren"),
        execute: () => {
          this.showBenutzerSperrenPopup();
        }
      });
    }

    if (hasSelection && this.canBenutzerZusammenfuehren) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.benutzer-zusammenfuehren"),
        execute: () => {
          this._element.dispatchEvent(new CustomEvent(
            "on-benutzer-zusammenfuehren", {
            bubbles: true,
            detail: {
              benutzer: this.currentObjekt
            }
          }
          ));
        }
      });
    }

    if (hasSelection && benutzer.CanStatusAendern && this.canChangeStatus && !this.isStatusAendernHidden) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.status_aendern"),
        execute: () => {
          this.showStatusAendernPopup();
        }
      });
    }
    if (hasSelection && this.canCopyStelleninserat) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.stelleninserat_kopieren"),
        execute: () => {
          this.copyStelleninserate();
        }
      });
    }
    if (hasSelection && this.canDokumentErstellen) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.dokument_erstellen"),
        execute: () => {
          this.showCreateDokumentLautVorlagePopup();
        }
      });
    }
    if (hasSelection && this.canCreateStandardStelleninserat) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.standard_stelleninserat_erstellen"),
        execute: () => {
          this.showCreateStandardStelleninseratPopup();
        }
      });
    }
    if (hasSelection && this.canBewerbungstypStelleninseratAendern) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.bewerbungstyp-aendern"),
        execute: () => {
          this.showBewerbungstypAendernPopup();
        }
      });
    }
    if (hasSelection && this.canTerminErstellen) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.termin_erstellen"),
        execute: () => {
          this.terminErstellen();
        }
      });
    }
    if (this.canBewerbungUmbuchen && !hasViewTyp && (benutzer.Benutzertyp != BenutzerTyp.NatuerlichePerson)) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.bewerbung_umbuchen"),
        execute: () => {
          this.selectedKeys = this.getSelectedKeys();
          this.bewerbungUmbuchen.showPopup(this.selectedKeys[0], this.form);
        }
      });
    }
    if (hasSelection && this.canBewerbungsseiteOeffnen) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.bewerbungsseite-oeffnen"),
        execute: () => {
          window.open(this.currentObjekt._UrlBewerbung, "_blank");
        }
      });
    }
    if (hasSelection && this.canEinsatzvorbereitungHinzufuegen) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.einsatzvorbereitung_hinzufuegen"),
        execute: () => {
          this.selectedKeys = this.getSelectedKeys();
          this.einsatzvorbereitungHinzufuegen.showPopup(this.selectedKeys);
        }
      });
    }
    if (hasSelection && this.canPlanstelleKopieren) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.planstelle_kopieren"),
        execute: () => {
          this.onPlanstelleKopieren();
        }
      });
    }
    if (hasSelection && this.canAusbilderErsetzen) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.ausbilder-ersetzen"),
        execute: () => {
          const date = moment()
            .startOf("day")
            .toDate();

          const initialData: any = {
            gueltigVon: date
          };

          this._formPopupService.formPopup.show({
            titel: this._localizationService.translateOnce("share.ausbilder-ersetzen"),
            data: initialData,
            formDef: "ansprechpersonMitGueltigVon",
            webApiFilters: [{
              webApiCustomKey: "IdAnsprechpersonFuerGP",
              webApiWhere: ["IsAusbilder", true],
              webApiCustomValue: this.currentObjekt.Id
            }],
            onFormSubmit: async(data) => {
              const u = {
                IdAnsprechpersonAlt: this.currentObjekt.Id,
                GueltigVon: data.gueltigVon,
                IdAnsprechpersonNeu: data.item.Id
              };
              const c = await this._restService.post({
                url: this._restService.getApiUrl("ZIT/Ansprechperson/AusbilderAendern"),
                data: u
              });

              if (c) {
                DevExpress.ui.notify(c, "ERROR", this.NOTIFY_TIMEOUT);
              } else {
                DevExpress.ui.notify("Ausbilder wurde erfolgreich ersetzt", "SUCCESS", this.NOTIFY_TIMEOUT);
              }
            }
          });
        }
      });
    }
    if (this.canShowSchnupperterminTagList) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("schnuppertermin.schnuppertermin-tage"),
        execute: () => {
          this.schnupperterminTagList.show({});
        }
      });
    }
    if (hasSelection && this.canAufgabeZuweisen) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.aufgaben-zuweisen"),
        execute: () => {
          this.selectedKeys = this.getSelectedKeys();
          this._aufgabeService.showZuweisen({
            idObjektList: this.selectedKeys
          });
        }
      });
    }
    if (hasSelection && this.canCreateZiel) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.ziel-erstellen"),
        execute: () => {
          this._locationService.goTo({
            url: "Objekte/Ziel/0",
            clearStack: false,
            currentViewModel: this.form,
            customOptions: {
              idUrsprungsobjekt: this.currentObjekt.Id
            },
          });
        }
      });
    }
    if (this.canTerminEmpfEinschaetzungErstellen) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("termin.einschaetzung-erstellen"),
        execute: () => {
          this._element.dispatchEvent(new CustomEvent(
            "on-termin-einschaetzung-erstellen", {
            bubbles: true
          }
          ));
        }
      });
    }
    if (this.canTerminPersonAusStelleninseratHinzu) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("termin.person-aus-stelleninserat-hinzu"),
        execute: () => {
          this._element.dispatchEvent(new CustomEvent(
            "on-termin-person-aus-stelleninserat-hinz", {
            bubbles: true
          }
          ));
        }
      });
    }
    if (this.canCreateSchueler) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.schueler-anlegen"),
        execute: () => {
          this.stelleninseratAuswahl.evalIdStelleninserat({
            onlySchule: true,
            callback: async(idStelleninserat) => {
              const r = await this._restService.get({
                url: this._restService.getWebApiUrl("ZIT/Objekte/Stelleninserat/".concat(idStelleninserat.toString())),
                getOptions: {
                  columns: ["Id"],
                  customs: [{ key: "AddBewerbungUrl", value: true }]
                }
              });

              if (!r || !r._BewerbungUrl) {
                return;
              }

              window.open(r._BewerbungUrl.concat("&NoInfoApplicant"), "_blank");
            }
          });
        }
      });
    }
    if (this.canshowLoginprotokoll) {
      ctxMenu.items.push({
        beginGroup: true,
        text: this._localizationService.translateOnce("share.login-protokoll"),
        execute: () => {
          this._element.dispatchEvent(new CustomEvent(
            "on-show-login-protokoll", {
            bubbles: true
          }
          ));
        }
      });
    }
  }

  viewTypChanged() {
    this.refreshCanShare();
  }
  currentObjektChanged() {
    this.refreshCanShare();
  }
  objektFullNameChanged() {
    this.refreshCanShare();
  }

  private async showMarkers() {
    try {
      this.selectedKeys = this.getSelectedKeys();

      if (this.selectedKeys.length == 1) {
        this._customEditPopupService.markersAuswaehlen.show({
          mappings: {
            "$v_idObjekt": this.selectedKeys[0]
          }
        });
      } else if (this.selectedKeys.length > 1) {
        const r = await this._objektExportEigenschaftService.getObjektFullNameByIdList(this.selectedKeys);
        this.markerMultiObjekt.showPopup(r.namespace, this.onMultiMarkerGesetzt.bind(this));
      }
    } catch (e) {
      //TODO Error (es sollte im FW lösen werden)
      if (!e || !e.message) {
        return;
      }

      const result = this._jsonService.parse(e.message);
      DevExpress.ui.notify(result.Message, "error", this.NOTIFY_TIMEOUT);
    }
  }
  private stelleninseratVorschauAnzeigen() {
    const model = this.form.models.data.$m_Stelleninserat;
    if (!model || !model.StelleninseratVorschauUrl) {
      return;
    }

    window.open(model.StelleninseratVorschauUrl, "_blank");
  }
  private async vorbereitetBenutzeraccountList() {
    if (!this.canBenutzeraccountVorbereitet) {
      return;
    }

    const r = await this._benutzereinstellungenService.vorbereitetAccountList(this.getSelectedKeys());

    if (r && r.Message) {
      DevExpress.ui.notify(r.Message, "info", this.NOTIFY_TIMEOUT);
      this.form.models.reloadAll();
    }
  }
  private async activateBenutzeraccountList() {
    if (!this.canBenutzeraccountAktivieren) {
      return;
    }

    const benutzeraccountTyp = this.getBenutzerTyp();
    const dataList = this.getSelectedKeys()
      .map((k) => {
        return { Id: k, Typ: benutzeraccountTyp };
      });

    const r = await this._benutzereinstellungenService.activateAccountList(dataList);

    if (r && r.Message) {
      DevExpress.ui.notify(r.Message, "info", this.NOTIFY_TIMEOUT);
      this.form.models.reloadAll();
    }
  }
  private async benutzeraccountAktivierenMail() {
    if (!this.canBenutzeraccountAktivierenMail) {
      return;
    }

    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Security/BenutzeraccountAktivierenMail"),
      data: {
        idBenutzer: this.currentObjekt.IdBenutzer
      }
    });

    if (r && r.Message) {
      DevExpress.ui.notify(r.Message, "info", this.NOTIFY_TIMEOUT);
    }
  }
  private async terminErstellen() {
    const idObjektList = this.getSelectedKeys();
    const benutzername = this._startupService.startupInfo.Benutzer;

    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Objekt/TerminEmpfaengerList"),
      data: {
        IdObjektList: idObjektList,
        Fullname: this.objektFullName
      }
    });

    this._locationService.goTo({
      url: `Objekte/Termin/0`,
      replace: false,
      setValuesOnModelWithKeyIdLoaded: {
        "Kopf.EmpfaengerReaktion": TerminReaktion.ZuAbsage,
        "Kopf.Verteilungstyp": Terminverteilungstyp.Empfaenger,
        "Kopf.Benutzer.NameGesamt": benutzername.Vorname.concat(" ")
          .concat(benutzername.Nachname),
        "Kopf._IsVerteilungstypReadonly": true,
        "Empfaenger": r
      }
    });
  }
  private async showPersonDossierPopup() {
    if (this.isTableau) {
      const tableau: TableauUtils = this.form.functions["$f_TableauUtils"];

      switch (tableau.currentViewType) {
        case TableauViewType.TerminEmpfaenger:
        case TableauViewType.EventTeilnehmer:
        case TableauViewType.EventSchnuppertermin:
        case TableauViewType.Schnuppertermin: {
          const rows: any[] = await this.dataGrid.instance.getSelectedRowsData();
          
          const keys = rows
            .map((i) => i.IdPerson)
            .filter((i) => !!i);

          if (keys.length > 0) {
            this.personDossier.showPopup("TIP.ZIT.Business.Entities.Objekte.Personen.Person", keys);
          }

          break;
        }
        default: {
          return;
        }
      }
    } else {
      const keys = this.getSelectedKeys();
      this.personDossier.showPopup(this.objektFullName, keys);
    }
  }
  private showBenutzerSperrenPopup() {
    this.selectedKeys = this.getSelectedKeys();
    if (!this.selectedKeys || this.selectedKeys.length == 0) {
      return;
    }

    this.benutzerSperren.showPopup(this.selectedKeys[0], this.form);
  }
  private showStatusAendernPopup() {
    this.selectedKeys = this.getSelectedKeys();
    this.statusAendern.showPopup(this.onStatusGeaendert.bind(this));
  }
  private showBewerbungstypAendernPopup() {
    this.selectedKeys = this.getSelectedKeys();
    this.bewerbungstypAendern.showPopup(this.selectedKeys);
  }
  private showCreateStandardStelleninseratPopup() {
    this.selectedKeys = this.getSelectedKeys();
    this.standardStelleninseratErstellen.showPopup(this.selectedKeys);
  }
  private showCreateDokumentLautVorlagePopup() {
    this.selectedKeys = this.getSelectedKeys();
    this.dokumentLautVorlageErstellen.showPopup(this.selectedKeys, this.objektFullName);
  }
  private selectAll() {
    switch (this.viewTyp) {
      case SucheViewTyp.table: {
        const dataSource = this.dataGrid.instance.option("dataSource");
        if (Array.isArray(dataSource)) {
          this.dataGrid.instance.selectAll();
        } else {
          this._dataSourceService
            .getDataSourceKeyValues(dataSource, this.prepareOptionsForSelection.bind(this))
            .then((r) => {
              (<DevExpress.ui.dxDataGrid>this.dataGrid.instance).selectRows(r, false);
            });
        }
        break;
      }
      case SucheViewTyp.list: {
        this._dataSourceService
          .getDataSourceKeyValues(this.listView.options.dataSource, this.prepareOptionsForSelection.bind(this))
          .then((r) => {
            this.listView.selectByKeys(r, true);
          });
        break;
      }
      default: {
        return;
      }
    }
  }
  private unselectAll() {
    switch (this.viewTyp) {
      case SucheViewTyp.table: {
        this.dataGrid.instance.deselectAll();
        break;
      }
      case SucheViewTyp.list: {
        this.listView.clearSelection();
        break;
      }
      default: {
        return;
      }
    }
  }
  private exportExcel() {
    const keyList = this.getSelectedKeys();

    if (!keyList || !keyList.length) {
      return;
    }

    const event = new CustomEvent("on-export-excel", {
      detail: {
        sender: this
      },
      cancelable: true,
      bubbles: true
    });

    const result = this._element.dispatchEvent(event);
    if (!result) {
      return;
    }

    this._downloadService.downloadExcelFromGrid({
      IdList: keyList,
      ColumnList: this.getExcelExportedColumns()
    });
  }
  private async copyStelleninserate() {
    this._stelleninseratService.copyStelleninseratList(this.getSelectedKeys());
  }
  private getBenutzerTyp(): BenutzeraccountTyp {
    switch (this.objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Personen.Person": {
        return BenutzeraccountTyp.Person;
      }
      case "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung": {
        return BenutzeraccountTyp.Bewerbung;
      }
      case "TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson": {
        return BenutzeraccountTyp.Ansprechperson;
      }
      case "TIP.ZIT.Business.Entities.Berechtigungen.Benutzer": {
        return BenutzeraccountTyp.Benutzer;
      }
      default: {
        return null;
      }
    }
  }
  private getSelectedKeys(): number[] {
    let selectedRowKeys: any[];

    switch (this.viewTyp) {
      case SucheViewTyp.table: {
        selectedRowKeys = this.dataGrid && this.dataGrid.instance
          ? this.dataGrid.instance.getSelectedRowKeys()
          : [];
        break;
      }
      case SucheViewTyp.list: {
        selectedRowKeys = this.listView
          ? this.listView.getSelectedKeys()
          : [];
        break;
      }
      default: {
        break;
      }
    }

    if (selectedRowKeys) {
      if (selectedRowKeys.length > 0) {
        const first = selectedRowKeys[0];
        if (typeof first === "object") {
          return selectedRowKeys.map((c) => c.Id);
        } else {
          return selectedRowKeys;
        }
      }

      return selectedRowKeys;
    } else if (this.currentObjekt) {
      return [this.currentObjekt.Id];
    } else {
      return [];
    }
  }
  private getExcelExportedColumns(): any[] {
    switch (this.viewTyp) {
      case SucheViewTyp.table: {
        return this._objektExportEigenschaftService.getExportGridColumns(this.dataGrid.instance)
          .map((c) => {
            return {
              dataField: c.dataField,
              visibleIndex: c.visibleIndex
            };
          });
      }
      case SucheViewTyp.list: {
        return [
          { dataField: "ObjektBezeichnung", visibleIndex: 0 },
          { dataField: "Bezeichnung", visibleIndex: 1 },
          { dataField: "Anlagedatum", visibleIndex: 2 }];
      }
      default: {
        return null;
      }
    }
  }
  private prepareOptionsForSelection(options) {
    if (options.customs) {
      options.customs = options.customs.filter((custom) => {
        return custom.key != "viewType"
          && custom.key != "additionalColumns";
      });
    }
  }
  private refreshCanShare() {
    const ctxMenu = new ContextMenu();
    this.loadCtxMenuItems(ctxMenu);

    this.canShare = ctxMenu.items.length > 0;
  }
  private onStatusGeaendert(r: IStatusChangeResultData) {
    if (!r.IsValid) {
      DevExpress.ui.dialog.alert(r.Message, this._localizationService.translateOnce("share.fehler"));
    } else {
      this.reloadData(false);
    }
  }
  private onMultiMarkerGesetzt() {
    this.reloadData();
  }
  private async onPlanstelleKopieren() {
    this.selectedKeys = this.getSelectedKeys();

    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Dispo/CreateDispoPlanstelleKopie"),
      data: {
        Id: this.selectedKeys[0]
      },
      increaseLoadingCount: true
    });

    if (!r || !r.Id) {
      return;
    }

    this._formRefreshService.refreshPrevForm(this.form);

    this._locationService.goTo({
      currentViewModel: this.form,
      replace: true,
      url: "Objekte/DispoPlanstelle/".concat(r.Id)
    });

    DevExpress.ui.notify(this._localizationService.translateOnce("share.kopie_erstellt"), "SUCCESS", this.NOTIFY_TIMEOUT);
  }
}
