import { autoinject, computedFrom, observable } from "aurelia-framework";
import { ZustandService } from './../../services/zustand-service';
import { FormBase } from './../../../framework/forms/classes/form-base';
import { IdxSelectBoxComponent, IdxTextBoxComponent, IdxLookupComponent, IdxTextAreaComponent, IdxDataGridComponent } from './../../interfaces/dx-components';
import { SimpleWidgetCreatorService } from './../../../framework/forms/widget-services/simple-widget-creator-service';
import { ScopeContainer } from './../../../framework/base/classes/scope-container';
import { RestService } from './../../../framework/base/services/rest-service';
import { LocalizationService } from './../../../framework/base/services/localization-service';
import { IdxPopupComponent, IdxValidationGroupComponent } from '../../interfaces/export';
import { ICommandData, PopupInfoService } from '../../../framework/forms/export';
import { GlobalizationService, DataSourceService } from '../../../framework/base/export';
import { StartupService } from '../../services/export';
import { BenutzerTyp } from '../../enumerations/export';
import { EventAggregator } from 'aurelia-event-aggregator';
import { TerminEmpfaengerUmbuchen } from '../termin-empfaenger-umbuchen/termin-empfaenger-umbuchen';

@autoinject
export class TerminEmpfaenger {
  private _terminEmpfaenger: any;
  private _terminEmpfMultiList: any[];
  private _repaintVerlaufGrid: boolean = false;
  private _savedCallBack: { (terminEmpfaenger?: any) };

  constructor(
    private localizationService: LocalizationService,
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private popupInfoService: PopupInfoService,
    private restService: RestService,
    private globalizationService: GlobalizationService,
    private startupService: StartupService,
    private eventAggregator: EventAggregator,
    private zustand: ZustandService,
    private dataSourceService: DataSourceService
  ) {
    this.isAdmin = this.startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.Administrator;
    this.isGeschaeftspartnerSchule = this.startupService.startupInfo.Benutzer.IsGeschaeftspartnerSchuleBenutzer;
  }

  @observable idSelectedGeschaeftspartner: number;

  form: FormBase;
  scopeContainer: ScopeContainer;

  editMode: EditMode;
  idTermin: number;
  isAdmin: boolean;
  isGeschaeftspartnerSchule: boolean;
  termin: any;
  terminEmpfaenger: any;
  selectedEmpfaengerName: any;
  isPersonSelectBoxVisible: boolean = false;
  isPersonTextBoxVisible: boolean = false;
  statusVerlaufDataSource: DevExpress.data.DataSource;
  zustandDataSource: DevExpress.data.DataSource;
  showVerlauf: boolean = false;
  terminEmpfaengerUmbuchen: TerminEmpfaengerUmbuchen;

  @computedFrom("editMode", "idTermin", "termin")
  get canUmbuchen() {
    return this.editMode == EditMode.Single
      && this.idTermin
      && this.termin
      && this.termin.IsSchnuppertermin == false;
  }

  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {}

  notizValidationGroup: IdxValidationGroupComponent;
  notizValidationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {}

  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };

  terminEmpfaengerStatusPopup: IdxPopupComponent;
  terminEmpfaengerStatusPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxWidth: "700px",
    title: this.localizationService.translateOnce("termin-empfaenger-status.termin-empfaenger-status"),
    onShown: (e) => {
      if (this.editMode == EditMode.Single) {
        this.setPersonDataSource();
      }

      this.zustandDataSource = new DevExpress.data.DataSource(new DevExpress.data.CustomStore({
        loadMode: "raw",
        load: async () => {
          if (this.editMode == EditMode.Single) {
            return await this.zustand.getFolgezustandTerminEmpf([this.terminEmpfaenger.Id]);
          } else {
            const idTerminEmpfaengerList: number[] = [];

            for (let i of this._terminEmpfMultiList) {
              const terminEmpf = await this.getTerminEmpfaenger(i.IdTermin, i.IdPerson);
              if (!terminEmpf) {
                continue;
              }

              idTerminEmpfaengerList.push(terminEmpf.Id);
            }

            return await this.zustand.getFolgezustandTerminEmpf(idTerminEmpfaengerList);
          }
        }
      }));

      if (this.showVerlauf) {
        this.statusVerlaufGrid.instance.columnOption("BemerkungGeschaeftspartner", "visible", (this.isAdmin || !this.isGeschaeftspartnerSchule));
        this.statusVerlaufGrid.instance.columnOption("BemerkungSchule", "visible", (this.isAdmin || this.isGeschaeftspartnerSchule));

        this.statusVerlaufDataSource = new DevExpress.data.DataSource(
          <any>new DevExpress.data.CustomStore({
            load: async (e) => {
              const r = await this.restService.get({
                url: this.restService.getApiUrl("ZIT/Termin/TerminEmpfaengerVerlauf") + `?idTerminEmpfaenger=${this.terminEmpfaenger.Id}`
              });

              this._repaintVerlaufGrid = true;

              return {
                data: r
              };
            }
          }));
      } else {
        this.terminEmpfaengerStatusPopup.instance.repaint();
      }
    }
  };

  terminEmpfaengerNotizPopup: IdxPopupComponent;
  terminEmpfaengerNotizPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxWidth: "780px",
    title: this.localizationService.translateOnce("termin-empfaenger-status.notiz"),
    onShowing: () => {
      if (this.textmarkenSelectBox && this.textmarkenSelectBox.instance) {
        this.textmarkenSelectBox.instance.option("value", null);
      }
    }
  };

  personAuswaehlenLookup: IdxLookupComponent;
  personAuswaehlenLookupOptions: DevExpress.ui.dxLookupOptions = {
    valueExpr: "Id",
    displayExpr: "Titel",
    itemTemplate: "zit-person-template",
    bindingOptions: {
      value: "terminEmpfaenger.IdPerson"
    }
  };

  personTextBox: IdxTextBoxComponent
  personTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    readOnly: true,
    bindingOptions: {
      value: "selectedEmpfaengerName"
    }
  };

  bemerkungTextArea: IdxTextAreaComponent
  bemerkungTextAreaOptions: DevExpress.ui.dxTextAreaOptions = {
    height: "100px",
    bindingOptions: {
      value: "terminEmpfaenger.Bemerkung"
    }
  };

  bemerkungSchuleTextArea: IdxTextAreaComponent
  bemerkungSchuleTextAreaOptions: DevExpress.ui.dxTextAreaOptions = {
    height: "100px",
    bindingOptions: {
      value: "terminEmpfaenger.BemerkungSchule"
    }
  };

  textmarken: any[] = [
    { Bezeichnung: "Anrede", Value: "{Anrede}" },
    { Bezeichnung: "Vorname", Value: "{Vorname}" },
    { Bezeichnung: "Nachname", Value: "{Nachname}" }
  ];

  textmarkenSelectBox: IdxSelectBoxComponent;
  textmarkenSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Value",
    displayExpr: "Bezeichnung",
    placeholder: "Auswählen...",
    bindingOptions: {
      dataSource: "textmarken"
    },
    onItemClick: (e) => {
      this.insertTextMarken("notizTextArea", e.itemData.Bezeichnung);
    },
  };

  notizTextArea: IdxTextBoxComponent
  notizTextAreaOptions: DevExpress.ui.dxTextAreaOptions = {
    height: "400px",
    bindingOptions: {
      value: "terminEmpfaenger.Notiz"
    }
  };

  statusAuswaehlenSelectBox: IdxSelectBoxComponent;
  statusAuswaehlenSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "IdZustand",
    displayExpr: "ZustandBezeichnung",
    bindingOptions: {
      dataSource: "zustandDataSource",
      value: "terminEmpfaenger.IdStatusZustand"
    }
  };

  statusVerlaufGrid: IdxDataGridComponent;
  statusVerlaufGridOptions: DevExpress.ui.dxDataGridOptions = {
    paging: {
      pageSize: 10
    },
    hoverStateEnabled: true,
    searchPanel: {
      visible: true
    },
    columns: [
      { dataField: "Zeitpunkt", caption: this.localizationService.translateOnce("termin-empfaenger-status.zeitpunkt"), format: this.globalizationService.getFormatter("g") },
      { dataField: "StatusDisplayName", caption: this.localizationService.translateOnce("termin-empfaenger-status.status") },
      { dataField: "BemerkungGeschaeftspartner", caption: this.localizationService.translateOnce("termin-empfaenger-status.bemerkung-gp") },
      { dataField: "BemerkungSchule", caption: this.localizationService.translateOnce("termin-empfaenger-status.bemerkung-schule") },
      { dataField: "Aenderungsbenutzer", caption: this.localizationService.translateOnce("termin-empfaenger-status.aenderungsbenutzer") }
    ],
    bindingOptions: {
      dataSource: "statusVerlaufDataSource"
    },
    onContentReady: () => {
      if (this._repaintVerlaufGrid) {
        this.terminEmpfaengerStatusPopup.instance.repaint();
      }
    }
  };

  statusPopupCommands: ICommandData[] = [{
    id: "terminEmpfaengerUmbuchenCommand",
    icon: "far fa-share-square",
    title: "termin-empfaenger-status.umbuchen",
    isVisibleExpression: "canUmbuchen",
    sortIndex: 1009,
    execute: async () => {
      this.terminEmpfaengerUmbuchen.showPopup({
        idTermin: this.idTermin, 
        idTerminEmpfaenger: this.terminEmpfaenger.Id,
        doneCallback: () => {
          this.terminEmpfaengerStatusPopup.instance.hide();
        }
      });
    }
  }, {
    id: "terminEmpfaengerSaveCommand",
    icon: "fas fa-check",
    tooltip: "base.save",
    sortIndex: 1010,
    execute: async () => {
      const result = this.validationGroup.instance.validate();
      if (!result.isValid) {
        return;
      }

      await this.showNotizErstellen();
    }
  }];

  notizPopupCommands: ICommandData[] = [
    {
      id: "terminEmpfaengerNotizSaveCommand",
      icon: "fas fa-check",
      tooltip: "base.save",
      sortIndex: 1010,
      execute: async () => {
        const result = this.notizValidationGroup.instance.validate();
        if (!result.isValid) {
          return;
        }

        this.save();

        this.popupInfoService.closeAllPopups();
      }
    }
  ];

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "terminEmpfaengerStatusPopupToolbar",
      caption: "termin-empfaenger-status.termin-empfaenger-status",
      options: this.terminEmpfaengerStatusPopupOptions,
      commands: this.statusPopupCommands,
      scopeContainer: this.scopeContainer
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "terminEmpfaengerNotizPopupToolbar",
      caption: "termin-empfaenger-status.notiz",
      options: this.terminEmpfaengerNotizPopupOptions,
      commands: this.notizPopupCommands,
      scopeContainer: this.scopeContainer
    });
  }

  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async showStatusPopup(idTermin: number, terminEmpfaenger: any | number, savedCallback?: { (terminEmpfaenger: any) }) {
    this.init();
    this.editMode = EditMode.Single;
    this.idTermin = idTermin;

    this.termin = await this.getTermin(idTermin);

    if (typeof terminEmpfaenger == "number") {
      const r = await this.getTerminEmpfaenger(idTermin, terminEmpfaenger);

      if (!r) {
        DevExpress.ui.notify("Es wurde kein Empfängerstatus gefunden", "error", 3000);
        return;
      }

      terminEmpfaenger = r;
    }

    this._terminEmpfaenger = terminEmpfaenger;
    this.terminEmpfaenger = Object.assign({}, terminEmpfaenger);

    if (this.terminEmpfaenger.IdPerson) {
      this.isPersonTextBoxVisible = true;
    } else {
      this.isPersonSelectBoxVisible = true;
    }

    if (this.terminEmpfaenger && this.terminEmpfaenger.Id) {
      this.showVerlauf = true;
    }

    this.selectedEmpfaengerName = terminEmpfaenger.EmpfaengerName ? terminEmpfaenger.EmpfaengerName : terminEmpfaenger.Titel;

    this._savedCallBack = savedCallback;
    this.terminEmpfaengerStatusPopup.instance.show();
  }
  async showStatusPopupMulti(terminEmpfMultiList: any[]) {
    this.init();
    this.editMode = EditMode.Multi;

    this.termin = null;
    this._terminEmpfMultiList = terminEmpfMultiList;

    this._savedCallBack = null;
    this.terminEmpfaengerStatusPopup.instance.show();
  }

  async setPersonDataSource(): Promise<any> {
    const dataSource = this.dataSourceService.createDataSource(
      this.scopeContainer, {
        webApiAction: "ZIT/Objekte/Person",
        webApiColumns: ["Id", "Titel"],
        filters: [{
          webApiCustomKey: "AddPersonCard",
          webApiCustomValue: "true"
        }]
      }
    );

    if (this.personAuswaehlenLookup) {
      this.personAuswaehlenLookup.instance.option("dataSource", dataSource);
    } else {
      this.personAuswaehlenLookupOptions.dataSource = dataSource;
    }
  }

  private init() {
    this.idTermin = null;
    this.showVerlauf = false;
    this.isPersonSelectBoxVisible = false;
    this.isPersonTextBoxVisible = false;
    this._terminEmpfaenger = {};
    this.terminEmpfaenger = {};
  }
  private async getTermin(idTermin: number) {
    if (!idTermin) {
      return null;
    }

    return await this.restService.get({
      url: this.restService.getWebApiUrl(`ZIT/Objekte/Termin/${idTermin}`),
      getOptions: {
        columns: ["Id", "IsSchnuppertermin"]
      },
      increaseLoadingCount: true
    });
  }
  private async getTerminEmpfaenger(idTermin: number, idPerson: number) {
    const r = await this.restService.get({
      url: this.restService.getWebApiUrl("ZIT/Objekte/TerminEmpfaengerElement"),
      getOptions: {
        where: [["IdTermin", idTermin], ["IdPerson", idPerson]]
      },
      increaseLoadingCount: true
    });

    if (r.length == 0) {
      return null;
    }

    return r[0];
  }
  private async save() {
    Object.assign(this._terminEmpfaenger, this.terminEmpfaenger);
    this._terminEmpfaenger._IdStatusZustand = this._terminEmpfaenger.IdStatusZustand;

    if (this.editMode == EditMode.Single) {
      if (this.idTermin) {
        const r = await this.saveData(this._terminEmpfaenger);

        Object.assign(this._terminEmpfaenger, r);
        delete this._terminEmpfaenger._IdStatusZustand;
        DevExpress.ui.notify(this.localizationService.translateOnce("base.save_success"), "SUCCESS", 3000);
      }

      if (this._savedCallBack) {
        this._savedCallBack(this._terminEmpfaenger);
      }
    } else if (this.editMode == EditMode.Multi) {
      for (let i of this._terminEmpfMultiList) {
        const terminEmpf = await this.getTerminEmpfaenger(i.IdTermin, i.IdPerson);
        if (!terminEmpf) {
          continue;
        }

        const data = Object.assign({}, this._terminEmpfaenger, {
          Id: terminEmpf.Id,
          IdTermin: i.IdTermin,
          IdPerson: i.IdPerson
        });

        const r = await this.saveData(data);
      }

      DevExpress.ui.notify(this.localizationService.translateOnce("base.save_success"), "SUCCESS", 3000);
    }

    this.terminEmpfaengerStatusPopup.instance.hide();
  }
  private async saveData(data) {
    const r = await this.restService.post({
      url: this.restService.getWebApiUrl(`ZIT/Objekte/TerminEmpfaengerElement`),
      data: data,
      increaseLoadingCount: true
    });

    if (!r) {
      return;
    }

    this.eventAggregator.publish("termin-empf:changed", {
      idTermin: r.IdTermin,
      idPerson: r.IdPerson
    });

    return r;
  }
  private async showNotizErstellen() {
    const zustand = await this.restService.get({
      url: this.restService.getWebApiUrl("ZIT/Stammdaten/Zustand/".concat(this.terminEmpfaenger.IdStatusZustand)),
      getOptions: {
        columns: ["Id", "Code"]
      }
    });

    if (zustand && zustand.Code == "ZUM_TERMIN_EINLADEN") {
      const doNotizErstellen = await DevExpress.ui.dialog.confirm(
        this.localizationService.translateOnce("termin-empfaenger-status.message_question"),
        this.localizationService.translateOnce("base.question"));

      if (!doNotizErstellen) {
        return this.save();
      }

      this.terminEmpfaengerNotizPopup.instance.show();
    } else {
      this.save();
    }
  }

  private insertTextMarken(ref: string, value: string) {
    const element: Element = this.scopeContainer.scope.bindingContext[ref].element;
    const input = <HTMLInputElement>element.querySelectorAll("input, textarea").item(0);
    input.focus();

    this.insertAtCursor(input, `{{${value}}}`);
  }

  private insertAtCursor(input: HTMLInputElement, value: string) {
    const doc: any = document;

    if (doc.selection) {
      const sel = doc.selection.createRange();
      sel.text = value;
    } else if (input.selectionStart || input.selectionStart == 0) {
      const startPos = input.selectionStart;
      const endPos = input.selectionEnd;

      input.value = input.value.substring(0, startPos)
        + value
        + input.value.substring(endPos);
    } else {
      input.value = (input.value || "") + value;
    }

    input.dispatchEvent(new Event("change"));
  }
}

enum EditMode {
  Single = 0,
  Multi = 1
}
