import { LocalizationService } from "./../../../../framework/base/services/localization-service";
import { Datenschutz } from "./../../../elements/datenschutz/datenschutz";
import { StartupService } from "./../../../services/startup-service";
import { RestService } from "./../../../../framework/base/services/rest-service";
import { GlobalizationService } from "./../../../../framework/base/services/globalization-service";
import { ZitMomentValueConverter } from "./../../../../zit-ui/value-converters/moment-value-converter";
import { FormBase } from "./../../../../framework/forms/classes/form-base";
import { Share } from "./../../../elements/share/share";
import { ICommandData } from "./../../../../framework/forms/interfaces/command-data";
import { IZitTagOptions } from "./../../../../zit-ui/elements/zit-tag/zit-tag-options";
import { transient } from "aurelia-framework";
import { autoinject } from "../../../../framework/forms/form-export";
import * as moment from "moment";

@transient()
@autoinject
export class EventZeitraumReadUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    private _zitMoment: ZitMomentValueConverter,
    private _localizationService: LocalizationService,
    private _startupService: StartupService,
    private _restService: RestService,
    private _globalizationService: GlobalizationService) {
  }

  form: FormBase;
  eventTeilnehmerData: any;
  datenschutzList: any[];

  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };

  vonDatumTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `functions.$f_EventZeitraumReadUtils.datumTagString(item)`,
    icon: {
      faIcon: "far fa-calendar-alt"
    }
  };

  verfuegbarePlaetzeTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    icon: {
      text: "P"
    },
    textExpression: `item.KontingentGesamtFrei`
  };

  meldenBeiUmTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item.Treffpunkt`,
    icon: {
      text: "T"
    }
  };

  adresseTagOptions: IZitTagOptions = {
    textExpression: `item.Adresse`,
    icon: {
      faIcon: "fas fa-map-marker-alt"
    },
    onClick: () => {
      this.openGoogleMaps();
    }
  };

  textTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item.Text`,
    icon: {
      text: "T"
    }
  };

  interesseButtonTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    cssClass: "z--tag z--zusage-button",
    icon: {
      faIcon: "far fa-check-circle"
    },
    text: "Ja, ich interessiere mich für dieses Event",
    onClick: () => {
      this.eventInteressieren();
    }
  };

  anmeldenButtonTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    cssClass: "z--tag z--zusage-button",
    icon: {
      faIcon: "far fa-check-circle"
    },
    text: "Anmeldelink",
    onClick: () => {
      const link = this.form.models.data.$m_EventZeitraum._WebsiteEventAnmeldungLink;
      window.open(link, "_blank");
    }
  };

  eventZeitraumDownloadTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    cssClass: "z--tag z--download-button",
    icon: {
      faIcon: "far fa-calendar-alt"
    },
    text: "Event in Kalender eintragen",
    onClick: () => {
      this.downloadEventICSFile();
    }
  };

  bind(form) {
    this.form = form;

    form.models.onLoaded.register(async (args) => {
      if (args.model.id == "$m_EventZeitraum" && args.data) {
        this.datenschutzList = args.data.DatenschutzList
          ? args.data.DatenschutzList
          : [];
      }
      return Promise.resolve();
    });

  }

  datumTagString(item: any): string {
    if (!item) {
      return "";
    }

    if (!item.StartDatum || !item.EndeDatum) {
      return "";
    }

    if (moment(item.StartDatum).isSame(moment(item.EndeDatum))) {
      return this._globalizationService.format(item.StartDatum, "DT_DD.MM.YYYY (dd)")
          .toUpperCase()
        + " "
        + this._zitMoment.getZeit(item.StartUhrzeitValidated, "{0} Uhr")
        + " - "
        + this._zitMoment.getZeit(item.EndeUhrzeitValidated, "{0} Uhr");
    } else {
      return this._globalizationService.format(item.StartDatum, "DT_DD.MM.YYYY (dd)")
          .toUpperCase()
        + " "
        + this._zitMoment.getZeit(item.StartUhrzeitValidated, "{0} Uhr")
        + " - "
        + this._globalizationService.format(item.EndeDatum, "DT_DD.MM.YYYY (dd)")
          .toUpperCase()
        + " "
        + this._zitMoment.getZeit(item.EndeUhrzeitValidated, "{0} Uhr");
    }
  }

  isInteresseButtonVisible(): boolean {
    const model = this.form.models.data.$m_EventZeitraum;
    if (!model) {
      return false;
    }

    if (this.form.models.data.$m_EventZeitraum.IsTeilnehmer == undefined) {
      return false;
    }
    if (this.form.models.data.$m_EventZeitraum._WebsiteEventAnmeldungLink) {
      return false;
    }

      if (model.Event && model.Event.Kopf.TeilnehmerReaktion == 2) {
        if (!this.form.models.data.$m_EventZeitraum.IsTeilnehmer) {
          return true;
        }
      }

    return false;
  }
  isAnmeldenButtonVisible(): boolean {
    const model = this.form.models.data.$m_EventZeitraum;
    if (!model) {
      return false;
    }

    if (this.form.models.data.$m_EventZeitraum.IsTeilnehmer == undefined) {
      return false;
    }
    if (!this.form.models.data.$m_EventZeitraum._WebsiteEventAnmeldungLink) {
      return false;
    }
    
    return true;
  }
  
  isInteresseEinverstaendniserklaerungVisible(): boolean {
    const isVisible = this.isInteresseButtonVisible();
    if (!isVisible) {
      return false;
    }

    return this.form.models.data.$m_EventZeitraum.IsTeilnehmer;
  }

  private async downloadEventICSFile() {
    window.open(this.getICSFileDownloadUrl(this.form.models.data.$m_EventZeitraum.TeilnehmerWebsiteKey), "_self");
  }

  private getICSFileDownloadUrl(key: string): string {
    return `${this._restService.getApiUrl("ZIT/WebsiteTermin/ICSFile")}?key=${key}`;
  }

  private async eventInteressieren() {
    if (this.isInteresseEinverstaendniserklaerungVisible()) {
      const datenschutz: Datenschutz = this.form["r_datenschutz"];
      if (!datenschutz.hasAlleBestaetigt()) {
        return;
      }
    }

    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Event/CreateTeilnehmer"),
      data: {
        IdEventZeitraum: this.form.models.data.$m_EventZeitraum.Id,
        IdPerson: this._startupService.startupInfo.Benutzer.IdPerson
      }
    });

    DevExpress.ui.notify(this._localizationService.translateOnce("base.save_success"), "success", this.NOTIFY_TIMEOUT);
    this.form.models.reloadAll();
  }
  private openGoogleMaps() {
    const resultGoogleMaps = this.form.models.data.$m_EventZeitraum.Kopf.Adresse;
    if (!resultGoogleMaps) {
      return;
    }
    window.open("https://www.google.com/maps/place/" + resultGoogleMaps.replace(" ", "+"), "_blank");
  }
}
