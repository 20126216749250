import { computedFrom } from "aurelia-binding";
import { autoinject, transient } from "aurelia-framework";
import * as moment from "moment";
import { BrowserService } from "./../../../../framework/base/services/browser-service";
import { LocalizationService } from "./../../../../framework/base/services/localization-service";
import { RestService } from "./../../../../framework/base/services/rest-service";
import { ContextMenu } from "./../../../../framework/forms/classes/context-menu";
import { FormBase } from "./../../../../framework/forms/classes/form-base";
import { ICommandData } from "./../../../../framework/forms/interfaces/command-data";
import { IZitToolbarOptions } from "./../../../../zit-ui/elements/zit-toolbar/zit-toolbar-options";
import { Datei } from "./../../../elements/datei/datei";
import { DokumentListView } from "./../../../elements/dokument-list-view/dokument-list-view";
import { Share } from "./../../../elements/share/share";
import { StatusAendern } from "./../../../elements/status-aendern/status-aendern";
import { IStatusChangeResultData } from "./../../../interfaces/status-change-result-data";
import { StartupService } from "./../../../services/startup-service";
import { AnsprechpersonService } from "../../../services/ansprechperson-service";

@autoinject
@transient()
export class EventTeilnehmerEditUtils {

  constructor(
    public startupService: StartupService,
    private _ansprechpersonService: AnsprechpersonService,
    private _browserService: BrowserService,
    private _localizationService: LocalizationService,
    private _restService: RestService
  ) { }

  form: FormBase;
  selectedKeys: number[];
  eventTeilnehmerValidator: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };
  meldenBeiDataSource: string[];

  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };

  statusAendernPopupCommand: ICommandData = {
    id: "statusAenderPopupCommand",
    icon: "fas fa-pencil-alt",
    title: "event.status-aendern-popup",
    isVisibleExpression: "functions.$f_EventTeilnehmerEditUtils.isStatusAendernVisible",
    sortIndex: 450,
    execute: () => {
      const statusAendern: StatusAendern = this.form["r_statusAendern"];
      this.selectedKeys = this.getSelectedKeys();
      statusAendern.showPopup(this.onStatusGeaendert.bind(this));
    }
  };

  dokumentToolbarOptions: IZitToolbarOptions = {
    title: "event.dokument-toolbar-titel",
    smallToolbar: true,
    items: [{
      id: "showDokumentToolbarOptionen",
      icon: "fas fa-plus",
      execute: async (e) => {
        if (!this.form.models.data.$m_EventTeilnehmer.Id) {
          await this.form.save();
        }

        const ctxMenu = new ContextMenu();

        if (this.form["r_dokumentElement"]) {
          ctxMenu.items = [{
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-dokument"),
            execute: () => {
              const dokumentListView: DokumentListView = this.form["r_dokumentElement"];
              dokumentListView.onDokumentAddClicked();
            }
          }, {
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-dokument-erstellen"),
            execute: () => {
              const share: Share = this.form["r_share"];
              share.dokumentLautVorlageErstellen.showPopup(
                [this.form.models.data.$m_EventZeitraum.Id],
                "TIP.ZIT.Business.Entities.Objekte.Events.EventTeilnehmer");
            }
          }];
        } else if (this.form["r_dateien"]) {
          ctxMenu.items = [{
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-dokumente"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onDokumentClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-bilder"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onCameraClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-videos"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onVideoClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("event.dokument-toolbar-item-info-galerie"),
            execute: async () => {
              const r = await this.form.saveIfDirty();
              if (!r.isValid) {
                return;
              }

              const datei: Datei = this.form["r_dateien"];
              datei.onAddToGalerieClick(() => {
                this.form.save();
              });
            }
          }];
        }

        ctxMenu.show(event.target);
      }
    }]
  };

  meldenBeiSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    acceptCustomValue: true,
    bindingOptions: {
      value: "models.data.$m_EventTeilnehmer.Kopf.MeldenBei",
      dataSource: "functions.$f_EventTeilnehmerEditUtils.meldenBeiDataSource",
    }
  };

  @computedFrom("canChangeStatus")
  get isStatusAendernVisible() {
    return this.startupService.startupInfo.Benutzer.CanStatusAendern
      && this.canChangeStatus
      && !this._browserService.isMobile;
  }

  @computedFrom("form.models.data.$m_EventTeilnehmer")
  get canChangeStatus(): boolean {
    if (!this.form.models.data.$m_EventTeilnehmer) {
      return true;
    }

    return !!this.form.models.data.$m_EventTeilnehmer._CanChangeObjektStatus;
  }

  onStartDatumChanged() {
    const model = this.form.models.data.$m_EventTeilnehmer;

    if (!model || !model.Kopf || model.Kopf.EndeDatum) {
      return;
    }

    model.Kopf.EndeDatum = model.Kopf.StartDatum;
  }

  isAnsichtDesktop() {
    return !this._browserService.isMobile;
  }

  bind(form) {
    this.form = form;

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_EventTeilnehmer") {
        if (args.binding.bindTo == "IdEventZeitraum") {
          this.setDatumFromZeitraum(this.form.models.data.$m_EventTeilnehmer.IdEventZeitraum);
        }
      }
    });
    
    form.models.onLoaded.register((args) => {
      if (args.model.id == "$m_Event" && args.data) {
        if (args.data.Kopf) {
          if (args.data.Kopf.IdGeschaeftspartner) {
            this.setMeldenBeiDataSource(args.data.Kopf.IdGeschaeftspartner);
          } else if (args.data.Kopf.IdGeschaeftspartnerSchule) {
            this.setMeldenBeiDataSource(args.data.Kopf.IdGeschaeftspartnerSchule);
          }
        }
      }
      return Promise.resolve();
    });

    form.onValidating.register((r) => {
      if (this.form.models.data.$m_EventTeilnehmer.Kopf.StartDatum && this.form.models.data.$m_EventTeilnehmer.Kopf.EndeDatum) {
        if (moment(this.form.models.data.$m_EventZeitraum.Kopf.StartDatum)
          .isSameOrBefore(this.form.models.data.$m_EventTeilnehmer.Kopf.StartDatum)
          && moment(this.form.models.data.$m_EventZeitraum.Kopf.EndeDatum)
            .isSameOrAfter(this.form.models.data.$m_EventTeilnehmer.Kopf.StartDatum)
          && moment(this.form.models.data.$m_EventZeitraum.Kopf.StartDatum)
            .isSameOrBefore(this.form.models.data.$m_EventTeilnehmer.Kopf.EndeDatum)
          && moment(this.form.models.data.$m_EventZeitraum.Kopf.EndeDatum)
            .isSameOrAfter(this.form.models.data.$m_EventTeilnehmer.Kopf.EndeDatum)) {
          const dauer = moment(this.form.models.data.$m_EventTeilnehmer.Kopf.EndeDatum).diff(this.form.models.data.$m_EventTeilnehmer.Kopf.StartDatum, "days") + 1;

          if (this.form.models.data.$m_EventZeitraum.Kopf.Maximaldauer && this.form.models.data.$m_EventZeitraum.Kopf.Mindestdauer) {
            if (dauer > this.form.models.data.$m_EventZeitraum.Kopf.Maximaldauer) {
              r.validationResult.isValid = false;
              r.validationResult.messages.push(this._localizationService.translateOnce("event.event-teilnehmer-maximaldauer-ueberschritten"));
              return;
            }
            if (dauer < this.form.models.data.$m_EventZeitraum.Kopf.Mindestdauer) {
              r.validationResult.isValid = false;
              r.validationResult.messages.push(this._localizationService.translateOnce("event.event-teilnehmer-mindestdauer-unterschreiten"));
              return;
            }
          }
        } else {
          r.validationResult.isValid = false;
          r.validationResult.messages.push("Der ausgewählte Zeitraum muss sich innerhalb von "
            + moment(this.form.models.data.$m_EventZeitraum.Kopf.StartDatum)
              .format("DD.MM.YYYY")
            + " - "
            + moment(this.form.models.data.$m_EventZeitraum.Kopf.EndeDatum)
              .format("DD.MM.YYYY")
            + " befinden.");
          return;
        }
      }
      return Promise.resolve();
    });
  }

  async onDokumentChanged() {
    await this.form.models.reloadAll();
    this.form.dispatchOnSaved();
  }

  private async setDatumFromZeitraum(id: number) {
    if (!id) {
      return;
    }

    const r = await this._restService.get({
      url: this._restService.getWebApiUrl(`ZIT/Objekte/EventZeitraum/${id}`),
      getOptions: {
        expand: { Kopf: null }
      }
    });

    if (!r) {
      return;
    }

    this.form.models.data.$m_EventTeilnehmer.Kopf.StartDatum = r.Kopf.StartDatum;
    this.form.models.data.$m_EventTeilnehmer.Kopf.StartUhrzeit = r.Kopf.StartUhrzeitValidated;
    this.form.models.data.$m_EventTeilnehmer.Kopf.EndeDatum = r.Kopf.EndeDatum;
    this.form.models.data.$m_EventTeilnehmer.Kopf.EndeUhrzeit = r.Kopf.EndeUhrzeitValidated;
  }

  private onStatusGeaendert(r: IStatusChangeResultData) {
    if (!r.IsValid) {
      DevExpress.ui.dialog.alert(r.Message, this._localizationService.translateOnce("event.fehler"));
    } else {
      this.form.models.reloadAll();
    }
  }

  private getSelectedKeys(): number[] {
    if (this.form.models.data.$m_EventTeilnehmer) {
      return [this.form.models.data.$m_EventTeilnehmer.Id];
    } else {
      return [];
    }
  }

  private async setMeldenBeiDataSource(id: number) {
    const ansprechpersonList = await this._ansprechpersonService
      .loadAnsprechpersonByGeschaeftspartner(id, ["Name"]);

    this.meldenBeiDataSource = ansprechpersonList.map((c) => c.Name);
  }
}
