import * as fwx from "../../../framework/forms/form-export";
import {
    AutoLoginFormFuncs
} from "./auto-login-form-funcs";

@fwx.autoinject
export class AutoLoginForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f: AutoLoginFormFuncs) {
        super(element, formBaseImport);
        this.id = "auto-account-form";
        this.title = "auto-account-form.auto-account-form_caption";
        this.addFunction("$f", $f, "functions.$f");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}