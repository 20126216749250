import * as fwx from "../../../../framework/forms/form-export";
import {
    StelleninseratEditUtils
} from "./stelleninserat-edit-utils";
import {
    StelleninseratLehreKundePackageEditUtils
} from "./stelleninserat-lehre-kunde-package-edit-utils";

@fwx.autoinject
export class StelleninseratLehreStandardinseratEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_Stelleninserat: StelleninseratEditUtils,
        private $f_StelleninseratLehreKundePackageEditUtils: StelleninseratLehreKundePackageEditUtils) {
        super(element, formBaseImport);
        this.id = "stelleninserat-lehre-standardinserat-edit";
        this.title = "stelleninserat-lehre-standardinserat-edit.stelleninserat-lehre-standardinserat-edit_caption";
        this.addModel({
            "id": "$m_Stelleninserat",
            "webApiAction": "ZIT/Objekte/Stelleninserat",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'TextKopfzeile1': null,
                        'TextFusszeile1': null,
                        'Bewerbungstyp': null,
                        'Geschaeftspartner': {
                            'expand': {
                                'Geschaeftspartnerdaten': null
                            }
                        }
                    }
                },
                'FerialeinsatzEinstellungen': null,
                'FerialbewerbungFragebogen': null
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "BewerbungInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "ProfilFirmaInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "StelleninseratVorschauUrl",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_Bewerbungstyp",
            "webApiAction": "ZIT/Stammdaten/StelleninseratBewerbungstyp",
            "key": "models.data.$m_Stelleninserat.Kopf.IdBewerbungstyp",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_Stelleninserat.actionCommand",
                "bindToFQ": "functions.$f_Stelleninserat.actionCommand"
            }
        });
        this.addFunction("$f_Stelleninserat", $f_Stelleninserat, "functions.$f_Stelleninserat");
        this.addFunction("$f_StelleninseratLehreKundePackageEditUtils", $f_StelleninseratLehreKundePackageEditUtils, "functions.$f_StelleninseratLehreKundePackageEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lehrberuf",
            "filters": [],
            "caption": "stelleninserat-lehre-standardinserat-edit.r_lehrberuf_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IdLehrberuf",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdLehrberuf",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lehrberuf",
            "options": {
                "optionsName": "r_lehrberufOptions",
                "optionsNameFQ": "r_lehrberufOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "stelleninserat-lehre-standardinserat-edit.r_firmenbezeichnung_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.Subheadline",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.Subheadline",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firmenbezeichnung",
            "options": {
                "optionsName": "r_firmenbezeichnungOptions",
                "optionsNameFQ": "r_firmenbezeichnungOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "ansprechperson",
            "customs": [{
                "key": "IdGeschaeftspartner",
                "value": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner"
            }],
            "filters": [],
            "caption": "stelleninserat-lehre-standardinserat-edit.ansprechpersonselect_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IdAnsprechperson",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdAnsprechperson",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "ansprechpersonSelect",
            "options": {
                "optionsName": "ansprechpersonSelectOptions",
                "optionsNameFQ": "ansprechpersonSelectOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "stelleninserat-lehre-standardinserat-edit.r_nuronlinebewerbungerwuenscht_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.NurOnlineBewerbungErwuenscht",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.NurOnlineBewerbungErwuenscht",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_nurOnlineBewerbungErwuenscht",
            "options": {
                "optionsName": "r_nurOnlineBewerbungErwuenschtOptions",
                "optionsNameFQ": "r_nurOnlineBewerbungErwuenschtOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "stelleninseratBewerbungstyp",
            "filter": [
                ["IdGeschaeftspartner", "null"], "or", ["IdGeschaeftspartner", {
                    "isBound": true,
                    "expression": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner"
                }]
            ],
            "customs": [{
                "key": "IdGeschaeftspartner",
                "value": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner"
            }, {
                "key": "CheckBenutzerrolle",
                "value": "true"
            }],
            "filters": [],
            "caption": "stelleninserat-lehre-standardinserat-edit.r_bewerbungstyp_caption",
            "placeholder": "stelleninserat-lehre-standardinserat-edit.r_bewerbungstyp_placeholder",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IdBewerbungstyp",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdBewerbungstyp",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_bewerbungstyp",
            "options": {
                "optionsName": "r_bewerbungstypOptions",
                "optionsNameFQ": "r_bewerbungstypOptions"
            }
        });
        super.onConstructionFinished();
    }
}