import { autoinject, Scope } from "aurelia-framework";
import { IdxPopupComponent, IdxDataGridComponent } from '../../interfaces/dx-components';
import { ScopeContainer, GlobalizationService, DataSourceService } from '../../../framework/base/export';
import { SimpleWidgetCreatorService, ICommandData } from '../../../framework/forms/export';
import * as moment from "moment";
import { StartupService } from '../../services/export';
import { SchnupperterminTag } from '../schnuppertermin-tag/schnuppertermin-tag';

@autoinject
export class SchnupperterminTagList {
  constructor(
    private simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private globalizationService: GlobalizationService,
    private dataSourceService: DataSourceService,
    private startupService: StartupService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;

  schnupperterminTag: SchnupperterminTag;
  model: IModel;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "1100px",
    height: "auto",
    maxHeight: "90vh"
  };

  schuleOptions: DevExpress.ui.dxSelectBoxOptions = {
    searchEnabled: true,
    showClearButton: true,
    displayExpr: "Name1",
    valueExpr: "Id",
    bindingOptions: {
      readOnly: "model.isSchuleReadOnly",
      value: "model.idSchule"
    },
    onValueChangedByUser: () => this.tagGrid.instance.refresh()
  };
  geschaeftspartnerOptions: DevExpress.ui.dxSelectBoxOptions = {
    searchEnabled: true,
    showClearButton: true,
    displayExpr: "Name1",
    valueExpr: "Id",
    bindingOptions: {
      readOnly: "model.isGeschaeftspartnerReadOnly",
      value: "model.idGeschaeftspartner"
    },
    onValueChangedByUser: () => this.tagGrid.instance.refresh()
  };
  personOptions: DevExpress.ui.dxSelectBoxOptions = {
    searchEnabled: true,
    showClearButton: true,
    displayExpr: "Name",
    valueExpr: "Id",
    bindingOptions: {
      value: "model.idPerson"
    },
    onValueChangedByUser: () => this.tagGrid.instance.refresh()
  };
  vonOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this.globalizationService.getFormatterParser("d"),
    showClearButton: true,
    bindingOptions: {
      value: "model.von"
    },
    onValueChangedByUser: () => this.tagGrid.instance.refresh()
  };
  bisOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this.globalizationService.getFormatterParser("d"),
    showClearButton: true,
    bindingOptions: {
      value: "model.bis"
    },
    onValueChangedByUser: () => this.tagGrid.instance.refresh()
  };
  tagGrid: IdxDataGridComponent;
  tagGridOptions: DevExpress.ui.dxDataGridOptions = {
    allowColumnResizing: true,
    columns: [{
      dataField: "Bezeichnung",
      caption: "Bezeichnung"
    }, {
      dataField: "_Schule",
      caption: "Schule",
      allowSorting: false
    }, {
      dataField: "_Geschaeftspartner",
      caption: "Geschäftspartner",
      allowSorting: false
    }, {
      dataField: "Person.Name",
      caption: "Person"
    }, {
      dataField: "Start",
      caption: "Start",
      width: "120px",
      sortIndex: 0,
      sortOrder: "asc",
      cellTemplate: (element, info) => {
        const val = this.globalizationService.format(info.data.Start, "DT_DD.MM.YYYY (dd)").toUpperCase();
        element.innerHTML = val;
      }
    }, {
      dataField: "Ende",
      caption: "Ende",
      width: "120px",
      cellTemplate: (element, info) => {
        const val = this.globalizationService.format(info.data.Ende, "DT_DD.MM.YYYY (dd)").toUpperCase();
        element.innerHTML = val;
      }
    }],
    keyExpr: "Id",
    height: "400px",
    remoteOperations: {
      filtering: true,
      paging: true,
      sorting: true
    },
    onRowClick: (e) => {
      this.schnupperterminTag.show({
        idSchnupperterminTag: e.data.Id,
        callbackOnChanges: () => this.tagGrid.instance.refresh()
      });
    }
  }

  schnupperterminTagNeuCommand: ICommandData = {
    id: "schnupperterminTagNeuCommand",
    icon: "fas fa-plus",
    sortIndex: 1000,
    tooltip: "base.add",
    execute: () => {
      this.onNeuClick();
    }
  };

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: null
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "schnupperterminTagToolbar",
      caption: "schnuppertermin.schnuppertermin-tage-uebersicht",
      scopeContainer: this.scopeContainer,
      options: this.popupOptions,
      commands: [this.schnupperterminTagNeuCommand]
    });

    this.createSchuleDataSource();
    this.createGeschaeftspartnerDataSource();
    this.createPersonDataSource();
    this.createSchnupperterminTagDataSource();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async show(options: ISchnupperterminTagListShowOptions) {
    this.resetModel();

    const isKunde = this.startupService.startupInfo.Benutzer.CodeBenutzerrolle == "KUNDE";
    const isKundeSchule = this.startupService.startupInfo.Benutzer.CodeBenutzerrolle == "KUNDE-SCHULE";

    this.model.isGeschaeftspartnerReadOnly = isKunde;
    this.model.isSchuleReadOnly = isKundeSchule;

    if (options.idGeschaeftspartner) {
      this.model.idGeschaeftspartner = options.idGeschaeftspartner;
    }
    else if (isKunde) {
      this.model.idGeschaeftspartner = this.startupService.startupInfo.Benutzer.IdGeschaeftspartner;
    } 
    
    if (isKundeSchule) {
      this.model.idSchule = this.startupService.startupInfo.Benutzer.IdGeschaeftspartner;
    }

    await this.popup.instance.show();
    this.popup.instance.repaint();
  }

  onNeuClick() {
    this.schnupperterminTag.show({
      start: this.model.von,
      ende: this.model.bis,
      idGeschaeftspartner: this.model.idGeschaeftspartner,
      idSchule: this.model.idSchule,
      idPerson: this.model.idPerson,
      callbackOnChanges: () => this.tagGrid.instance.refresh()
    });
  }

  private createSchuleDataSource() {
    this.schuleOptions.dataSource = this.dataSourceService.createDataSource(
      this.scopeContainer, {
        webApiAction: "ZIT/Simple/Geschaeftspartner",
        webApiWhere: ["IsGeschaeftspartnerSchule", true],
        webApiOrderBy: [{ columnName: "Name1", sortOrder: 0 }]
      }
    );
  }
  private createGeschaeftspartnerDataSource() {
    this.geschaeftspartnerOptions.dataSource = this.dataSourceService.createDataSource(
      this.scopeContainer, {
        webApiAction: "ZIT/Simple/Geschaeftspartner",
        webApiOrderBy: [{ columnName: "Name1", sortOrder: 0 }]
      }
    );
  }
  private createPersonDataSource() {
    this.personOptions.dataSource = this.dataSourceService.createDataSource(
      this.scopeContainer, {
        webApiAction: "ZIT/Objekte/Person",
        webApiColumns: ["Id", "Name"],
        webApiOrderBy: [{ columnName: "Name", sortOrder: 0 }],
        filters: [{
          webApiCustomKey: "MitBewerbungGeschaeftspartnerSchule", webApiCustomValue: "true"
        }]
      }
    );
  }
  private createSchnupperterminTagDataSource() {
    this.tagGridOptions.dataSource = this.dataSourceService.createDataSource(
      this.scopeContainer, {
        webApiAction: "ZIT/Stammdaten/SchnupperterminTag",
        webApiExpand: {
          Person: {
            columns: ["Id", "Name"]
          }
        },
        filters: [{
          webApiCustomKey: "InklSchuleUndGP", webApiCustomValue: "true"
        }]
      }, {
        getCustomWhere: () => {
          const where = [];

          if (this.model.von) {
            where.push(["Ende", ">=", this.model.von]);
          }
          if (this.model.bis) {
            where.push(["Start", "<=", this.model.bis]);
          }
          if (this.model.idGeschaeftspartner) {
            where.push(["IdGeschaeftspartner", this.model.idGeschaeftspartner]);
          }
          if (this.model.idPerson) {
            where.push(["IdPerson", this.model.idPerson]);
          }
          if (this.model.idSchule) {
            where.push(["SchnupperterminTagZuSchulen", ["IdGeschaeftspartnerSchule", this.model.idSchule]]);
          }

          return where;
        }
      }
    );
  }
  private resetModel() {
    this.model = {
      von: moment().startOf("day").toDate()
    }
  }
}

export interface ISchnupperterminTagListShowOptions {
  idGeschaeftspartner?: number
}
interface IModel {
  idSchule?: number;
  isSchuleReadOnly?: boolean;
  idGeschaeftspartner?: number;
  isGeschaeftspartnerReadOnly?: boolean;
  idPerson?: number;

  von?: Date;
  bis?: Date;
}