import { Subscription } from "aurelia-event-aggregator";
import { BenutzerTyp } from './../../enumerations/benutzer-typ';
import { StartupService } from './../../services/startup-service';
import {
  NachrichtService,
  NachrichtErstellTyp,
  INachricht,
  NachrichtTyp,
  INachrichtTextmarke
} from "./../../services/nachricht-service";
import { computedFrom } from 'aurelia-binding';
import { IZitTagOptions } from './../../../zit-ui/elements/zit-tag/zit-tag-options';
import { SimpleWidgetCreatorService } from './../../../framework/forms/widget-services/simple-widget-creator-service';
import { IdxTextAreaComponent, IdxSelectBoxComponent } from './../../interfaces/dx-components';
import { ScopeContainer } from './../../../framework/base/classes/scope-container';
import { ICommandData } from './../../../framework/forms/interfaces/command-data';
import { RestService } from './../../../framework/base/services/rest-service';
import { LocalizationService } from './../../../framework/base/services/localization-service';
import { autoinject } from "aurelia-framework";
import { IdxPopupComponent } from '../../interfaces/export';
import { EventAggregator } from '../../../../node_modules/aurelia-event-aggregator';
import { BrowserService, FileService, GlobalizationService } from '../../../framework/base/export';
import { DateiAuswaehlen } from '../datei-auswaehlen/datei-auswaehlen';
import { Datei } from '../datei/datei';
import { NachrichtVorlage } from "../nachricht-vorlage/nachricht-vorlage";
import { INachrichtVorlage, NachrichtVorlageService } from "../../services/nachricht-vorlage-service";

@autoinject
export class NachrichtErstellen {
  private _reloadVorlagenSubscription: Subscription;
  
  constructor(
    public startupService: StartupService,
    private _nachrichtService: NachrichtService,
    private _nachrichtVorlageService: NachrichtVorlageService,
    private _localizationService: LocalizationService,
    private _restService: RestService,
    private _simpleWidgetCreator: SimpleWidgetCreatorService,
    private _eventAggregator: EventAggregator,
    private _browserService: BrowserService,
    private _fileService: FileService,
    private _globalizationService: GlobalizationService
  ) {
    const isAdmin = this.startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.Administrator;
    const isNatPerson = this.startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.NatuerlichePerson;
    this.isBenutzerAdmin = isAdmin;
    this.isBenutzerNatPerson = isNatPerson;
  }

  popupTitel: string = "Nachricht erstellen";

  scopeContainer: ScopeContainer;
  nachricht: INachricht;
  textmarken: INachrichtTextmarke[];
  empfaengerList: string[];
  isBenutzerAdmin: boolean;
  isBenutzerNatPerson: boolean;
  isEmpfGruppe: boolean = false;
  empfScrollView: any;

  datei: Datei;

  nachrichtErstellenPopup: IdxPopupComponent;
  nachrichtErstellenPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxWidth: "750px",
    maxHeight: "100vh",
    title: this._localizationService.translateOnce("nachricht-erstellen.popup-titel"),
    onShown: () => {
      if (this.textTextArea
        && this.textTextArea.instance
        && (this.nachricht.erstellTyp == NachrichtErstellTyp.Weiterleiten
          || this.nachricht.erstellTyp == NachrichtErstellTyp.Umleiten)) {
        this.textTextArea.instance.option("readOnly", true);
      }

      if (this.textTextArea
        && this.textTextArea.instance
        && this.nachricht.erstellTyp != NachrichtErstellTyp.Weiterleiten
        && this.nachricht.erstellTyp != NachrichtErstellTyp.Umleiten) {
        this.textTextArea.instance.option("readOnly", false);

        this.textTextArea.instance.focus();
      }
    },
    onHidden: () => {
      this.empfScrollView.instance.option("height", 0);
    }
  }

  vorlagenCommand: ICommandData = {
    id: "vorlagenCommand",
    idCategory: "$start",
    title: "nachricht-erstellen.vorlagen",
    sortIndex: 1100,
    execute: (e) => {
      this.nachrichtVorlage.showContextMenu(e.event.target, (v) => {});
    }
  };
  fotoCommand: ICommandData = {
    id: "kameraCommand",
    idCategory: "$start",
    icon: "fas fa-camera",
    sortIndex: 1200,
    execute: () => {
      this.dateiAuswaehlen.onMakePhoto(true, true);
    }
  };
  videoCommand: ICommandData = {
    id: "videoCommand",
    idCategory: "$start",
    icon: "fas fa-video",
    sortIndex: 1201,
    execute: () => {
      this.dateiAuswaehlen.onMakeVideo(true, true);
    }
  };
  dokumentCommand: ICommandData = {
    id: "dokumentCommand",
    idCategory: "$start",
    icon: "fas fa-paperclip",
    sortIndex: 1202,
    execute: () => {
      this.dateiAuswaehlen.onOpenFiles(true);
    }
  };
  nachrichtErstellenCommand: ICommandData = {
    id: "nachrichtErstellen",
    idCategory: "$start",
    icon: "far fa-paper-plane",
    tooltip: "nachricht-erstellen.senden",
    title: this._browserService.isMobile ? null : "nachricht-erstellen.senden",
    sortIndex: 1300,
    isVisible: true,
    execute: async () => {
      this.save();
    }
  };
  saveCommand: ICommandData = {
    id: "saveCommand",
    idCategory: "$start",
    icon: "fas fa-save",
    tooltip: "base.save_tooltip",
    sortIndex: 1400,
    isVisible: false,
    execute: () => {
      this.save();
    }
  }
  deleteCommand: ICommandData = {
    id: "deleteCommand",
    idCategory: "$start",
    icon: "far fa-trash-alt",
    tooltip: "base.delete_tooltip",
    sortIndex: 1401,
    isVisible: false,
    execute: async () => {
      const r = await this._nachrichtService.showDeleteDialog(this.nachricht.key);
      if (!r) {
        return;
      }

      this._eventAggregator.publish("nachricht:erstellt", {
        all: true
      });

      this.nachrichtErstellenPopup.instance.hide();
    }
  }

  empfScrollViewOptions: DevExpress.ui.dxScrollViewOptions = {};

  empfaengerTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "far fa-address-book"
    },
    textExpression: "item.Name",
    onDeleteClick: (e, data) => {
      e.stopPropagation();
      this.deleteEmpfaenger(data);
    }
  };

  empfaengerReadOnlyTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "far fa-address-book"
    },
    textExpression: "item.Name",
  };

  massennachrichtTagOptions: IZitTagOptions = {
    icon: {
      textExpression: "nachricht.empfContainer.NachrichtEmpfaengerList.length"
    },
    text: "Massennachricht",
  };

  telefonatTypOptions: DevExpress.ui.dxRadioGroupOptions = {
    valueExpr: "Typ",
    displayExpr: "Text",
    items: [
      { Typ: 0, Text: "erreicht" },
      { Typ: 1, Text: "nicht erreicht" },
      { Typ: 2, Text: "eingehendes Telefonat" }
    ],
    bindingOptions: {
      value: "nachricht.telefonatTyp"
    }
  };

  textTextArea: IdxTextAreaComponent;
  textTextAreaOptions: DevExpress.ui.dxTextAreaOptions = {
    height: "200px",
    bindingOptions: {
      value: "nachricht.nachrichtText"
    }
  };

  signaturTextAreaOptions: DevExpress.ui.dxTextAreaOptions = {
    height: "45px",
    bindingOptions: {
      value: "nachricht.nachrichtSignatur"
    }
  };

  vorlagen: INachrichtVorlage[] = [];
  vorlagenSelectBox: IdxSelectBoxComponent;
  vorlagenSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Bezeichnung",
    placeholder: "Auswählen...",
    bindingOptions: {
      dataSource: "vorlagen"
    },
    onItemClick: (e) => {
      this.copyFromVorlage(e.itemData);
    }
  }

  textmarkenSelectBox: IdxSelectBoxComponent;
  textmarkenSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Value",
    displayExpr: "Bezeichnung",
    placeholder: "Auswählen...",
    bindingOptions: {
      dataSource: "textmarken"
    },
    onItemClick: (e) => {
      this.insertTextMarken("textTextArea", e.itemData.Bezeichnung);
    }
  };

  empfaengerSelectBox: IdxSelectBoxComponent;
  empfaengerSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Name",
    placeholder: "Empfänger auswählen",
    searchEnabled: true,
    dataSource: this._nachrichtService.getEmpfaengerDataSource()
  };

  vollstaendigNachrichtentextCheckboxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "vollständiger Nachrichtentext an den Empfänger per WhatsApp/SMS/E-Mail übermitteln",
    value: false,
    bindingOptions: {
      value: "nachricht.doVollstaendigeNachrichtVersenden"
    }
  };

  anlagedatumOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this._globalizationService.getFormatterParser("g"),
    bindingOptions: {
      value: "nachricht.anlagedatum"
    }
  }

  dateiAuswaehlen: DateiAuswaehlen;
  nachrichtVorlage: NachrichtVorlage;

  @computedFrom("nachricht.empfContainer", "nachricht.empfContainer.NachrichtEmpfaengerList.length")
  get isMassennachrichtTagVisible(): boolean {
    if (!this.nachricht.empfContainer) {
      return false;
    }

    return this.nachricht.empfContainer.NachrichtEmpfaengerList.length > 1
  }

  @computedFrom("nachricht.erstellTyp")
  get isEmpfaengerSelectBoxVisible(): boolean {
    if (!this.nachricht.erstellTyp) {
      return false;
    }

    return this.nachricht.erstellTyp == NachrichtErstellTyp.Weiterleiten
      || this.nachricht.erstellTyp == NachrichtErstellTyp.Umleiten;
  }

  @computedFrom("isEmpfaengerSelectBoxVisible", "isNachrichtentypAntworten", "isEmpfaengerReadOnlyListVisible")
  get isEmpfaengerListVisible(): boolean {
    return !this.isEmpfaengerSelectBoxVisible
      && !this.isEmpfaengerReadOnlyListVisible
      && this.nachricht.typ == NachrichtTyp.Nachricht;
  }

  @computedFrom("nachricht.erstellTyp", "isEmpfGruppe")
  get isEmpfaengerReadOnlyListVisible(): boolean {
    return (this.nachricht.erstellTyp == NachrichtErstellTyp.Antworten
      || this.isEmpfGruppe)
      && this.nachricht.typ == NachrichtTyp.Nachricht;
  }

  @computedFrom("isBenutzerNatPerson", "isEmpfGruppe", "nachricht.typ")
  get isVorlageVisible(): boolean {
    return !this.isBenutzerNatPerson
      && !this.isEmpfGruppe
      && this.nachricht.typ == NachrichtTyp.Nachricht
      && (this.nachricht.erstellTyp == NachrichtErstellTyp.NeuOderBearbeiten || this.nachricht.erstellTyp == NachrichtErstellTyp.Antworten);
  }

  @computedFrom("isBenutzerNatPerson", "isEmpfGruppe", "nachricht.typ")
  get isTextmarkeVisible(): boolean {
    return !this.isBenutzerNatPerson
      && !this.isEmpfGruppe
      && this.nachricht.typ == NachrichtTyp.Nachricht
      && (this.nachricht.erstellTyp == NachrichtErstellTyp.NeuOderBearbeiten || this.nachricht.erstellTyp == NachrichtErstellTyp.Antworten);
  }

  @computedFrom("nachricht.typ", "nachricht.erstellTyp", "nachricht.key", "startupService.startupInfo.Benutzer.NachrichtSignatur")
  get isSignaturVisible(): boolean {
    return this.nachricht.typ == NachrichtTyp.Nachricht
      && this.nachricht.erstellTyp == NachrichtErstellTyp.NeuOderBearbeiten
      && !this.nachricht.key
      && !!this.startupService.startupInfo.Benutzer.NachrichtSignatur;
  }

  @computedFrom("isBenutzerAdmin", "nachricht.typ", "nachricht.key")
  get isVollstaendigNachrichtentextVisible(): boolean {
    return this.isBenutzerAdmin
      && this.nachricht.typ != NachrichtTyp.Notiz
      && this.nachricht.typ != NachrichtTyp.Telefonat
      && !this.nachricht.key;
  }

  @computedFrom("nachricht.typ")
  get isAnlagedatumVisible(): boolean {
    return this.nachricht.typ == NachrichtTyp.Notiz
      || this.nachricht.typ == NachrichtTyp.Telefonat;
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    const commands: ICommandData[] = [
      this.fotoCommand
    ];

    if (this._browserService.isMobile) {
      commands.push(this.videoCommand);
    } else {
      commands.push(this.dokumentCommand);
    }
    
    if (this.startupService.startupInfo.Benutzer.Benutzertyp != BenutzerTyp.NatuerlichePerson) {
      commands.push(this.vorlagenCommand);
    }

    commands.push(this.nachrichtErstellenCommand);
    commands.push(this.saveCommand);
    commands.push(this.deleteCommand);

    this._simpleWidgetCreator.updatePopupOptions({
      idToolbar: "nachrichtErstellenPopupToolbar",
      caption: "nachricht-erstellen.popup-titel",
      options: this.nachrichtErstellenPopupOptions,
      commands: commands,
      scopeContainer: this.scopeContainer
    });

    this.loadTextmarken();
    this.loadVorlagen();

    this._reloadVorlagenSubscription = this._eventAggregator.subscribe("nachricht:vorlagen-changed", () => {
      this.loadVorlagen();
    });
  }
  attached() {
    this._nachrichtService.nachrichtErstellen = this;
  }
  unbind() {
    this._reloadVorlagenSubscription?.dispose();
    this._reloadVorlagenSubscription = null;
    
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  show(nachricht: INachricht) {
    this.nachricht = nachricht;

    this.isEmpfGruppe = this.nachricht.empfContainer
      && this.nachricht.empfContainer.NachrichtEmpfaengerList
      && this.nachricht.empfContainer.NachrichtEmpfaengerList.some(f => f.IsGruppe);

    if (nachricht.typ == NachrichtTyp.Nachricht) {
      this.nachrichtErstellenCommand.isVisible = true;
      this.saveCommand.isVisible = false;
      this.deleteCommand.isVisible = false;
      this.vorlagenCommand.isVisible = true;
    } else {
      this.nachrichtErstellenCommand.isVisible = false;
      this.saveCommand.isVisible = true;
      this.deleteCommand.isVisible = !!nachricht.key;
      this.vorlagenCommand.isVisible = false;
    }
    
    if (this.vorlagenSelectBox && this.vorlagenSelectBox.instance) {
      this.vorlagenSelectBox.setOption({ value: null });
    }

    this.nachrichtErstellenPopup.instance.show();
  }

  async onDateiAusgewaehlt(e: CustomEvent) {
    for (let file of <File[]>e.detail.files) {
      const dmsLink = await this._fileService.upload(file);
      if (!dmsLink) {
        return;
      }

      this.nachricht.dateiList.push({ DMSLink: dmsLink, Dateiname: file.name });
    }
    
    this.datei.refresh();
  }
  onNachrichtDetailRefreshed() {
    this.nachrichtErstellenPopup.instance.repaint();
  }
  onEmpfRepeatFinished(e: CustomEvent) {
    const el: HTMLElement = e.detail.element;

    const options = {
      height: "0"
    };

    const scrollHeight = el.parentElement.parentElement.offsetHeight;
    if (scrollHeight >= 91) {
      options.height = "90px";
    }
    else {
      options.height = `${scrollHeight + 6}px`;
    }

    if (this.empfScrollView && this.empfScrollView.instance) {
      this.empfScrollView.setOption(options);
    } else {
      Object.assign(this.empfScrollViewOptions, options);
    }

    this.nachrichtErstellenPopup.instance.repaint();
  }
  onDateiDeleted(e: CustomEvent) {
    this.nachricht.idDateiLoeschList.push(e.detail.id);
  }

  private async save() {
    switch (this.nachricht.erstellTyp) {
      case NachrichtErstellTyp.NeuOderBearbeiten: {
        await this.doNachrichtErstellen();
        break;
      }
      case NachrichtErstellTyp.Antworten: {
        await this.doAntworten();
        break;
      }
      case NachrichtErstellTyp.Weiterleiten: {
        await this.doWeiterleiten();
        break;
      }
      case NachrichtErstellTyp.Umleiten: {
        await this.doUmleiten();
        break;
      }
    }

    this.nachrichtErstellenPopup.instance.hide();
    if (this.textmarkenSelectBox) {
      this.textmarkenSelectBox.setOption({ value: null });
    }
    
    DevExpress.ui.notify(this._localizationService.translateOnce("nachricht-erstellen.nachrichten-erfolgreich-erstellt"), "SUCCESS", 3000);
  }
  private async doNachrichtErstellen(): Promise<any> {
    if (!this.nachricht.nachrichtText) {
      const fehlerText = this._localizationService.translateOnce("nachricht-erstellen.fehlende-texteingabe");
      DevExpress.ui.notify(fehlerText, "ERROR", 5000)
      return Promise.reject(fehlerText);
    }
    if (!this.nachricht.key && this.nachricht.empfContainer.NachrichtEmpfaengerList.length == 0) {
      const fehlerText = this._localizationService.translateOnce("nachricht-erstellen.empfaenger-angeben");
      DevExpress.ui.notify(fehlerText, "ERROR", 5000)
      return Promise.reject(fehlerText);
    }

    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Nachricht/NachrichtErstellen"),
      increaseLoadingCount: true,
      data: {
        KeyNachricht: this.nachricht.key,
        NachrichtObjektBenutzerContainerHelper: this.nachricht.empfContainer,
        Text: this.nachricht.nachrichtText,
        Signatur: this.nachricht.nachrichtSignatur,
        Typ: this.nachricht.typ,
        TelefonatTyp: this.nachricht.telefonatTyp,
        DoVollstaendigeNachrichtVersenden: this.nachricht.doVollstaendigeNachrichtVersenden,
        Anlagedatum: this.nachricht.anlagedatum,
        DateiList: this.nachricht.dateiList.map(d => {
          return {
            DMSLink: d.DMSLink,
            Dateiname: d.Dateiname,
            Exists: d.Exists
          };
        }),
        IdDateiLoeschList: this.nachricht.idDateiLoeschList
      }
    });

    if (this.nachricht.empfContainer) {
      const idEmpfaengerList = this.nachricht
        .empfContainer
        .NachrichtEmpfaengerList
        .map(c => c.Id);
  
      this._eventAggregator.publish("nachricht:erstellt", {
        idEmpfaengerList: idEmpfaengerList
      });
    } else {
      this._eventAggregator.publish("nachricht:erstellt", {
        all: true
      });
    }

    return r;
  }
  private async doAntworten(): Promise<any> {
    if (!this.nachricht.nachrichtText) {
      const fehlerText = this._localizationService.translateOnce("nachricht-erstellen.fehlende-texteingabe");
      DevExpress.ui.notify(fehlerText, "ERROR", 5000)
      return Promise.reject(fehlerText);
    }

    const idEmpfaenger = this.nachricht.empfContainer.NachrichtEmpfaengerList[0].Id;

    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Nachricht/Antworten"),
      increaseLoadingCount: true,
      data: {
        KeyNachricht: this.nachricht.keyReferenzNachricht,
        IdEmpfaenger: idEmpfaenger,
        Text: this.nachricht.nachrichtText,
        DateiList: this.nachricht.dateiList.map(d => {
          return {
            DMSLink: d.DMSLink,
            Dateiname: d.Dateiname
          };
        })
      }
    });

    this._eventAggregator.publish("nachricht:erstellt", {
      idEmpfaengerList: [idEmpfaenger]
    });

    return r;
  }
  private async doWeiterleiten(): Promise<any> {
    if (!this.empfaengerSelectBox.instance.option("value")) {
      const fehlerText = this._localizationService.translateOnce("nachricht-erstellen.empfaenger-auswaehlen");
      DevExpress.ui.notify(fehlerText, "ERROR", 5000)
      return Promise.reject(fehlerText);
    }

    const idEmpfaenger = this.empfaengerSelectBox.instance.option("value");

    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Nachricht/Weiterleiten"),
      increaseLoadingCount: true,
      data: {
        KeyNachricht: this.nachricht.keyReferenzNachricht,
        IdEmpfaenger: idEmpfaenger
      }
    });

    this._eventAggregator.publish("nachricht:erstellt", {
      idEmpfaengerList: [idEmpfaenger, r.IdEmpfaengerAlt]
    });

    return r;
  }
  private async doUmleiten(): Promise<any> {
    if (!this.empfaengerSelectBox.instance.option("value")) {
      const fehlerText = this._localizationService.translateOnce("nachricht-erstellen.empfaenger-auswaehlen");
      DevExpress.ui.notify(fehlerText, "ERROR", 5000)
      return Promise.reject(fehlerText);
    }

    const idEmpfaenger = this.empfaengerSelectBox.instance.option("value")

    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Nachricht/Umleiten"),
      increaseLoadingCount: true,
      data: {
        KeyNachricht: this.nachricht.keyReferenzNachricht,
        IdEmpfaenger: idEmpfaenger
      }
    });

    this._eventAggregator.publish("nachricht:erstellt", {
      idEmpfaengerList: [idEmpfaenger, r.IdEmpfaengerAlt]
    });

    return r;
  }

  private deleteEmpfaenger(empfaenger: any) {
    const list: any[] = this.nachricht.empfContainer.NachrichtEmpfaengerList;
    if (!list || !list.length) {
      return;
    }

    list.splice(list.indexOf(empfaenger), 1);
  }

  private insertTextMarken(ref: string, value: string) {
    const element: Element = this.scopeContainer.scope.bindingContext[ref].element;
    const input = <HTMLInputElement>element.querySelectorAll("input, textarea").item(0);
    input.focus();

    this.insertAtCursor(input, `{{${value}}}`);
  }
  private insertAtCursor(input: HTMLInputElement, value: string) {
    const doc: any = document;

    if (doc.selection) {
      const sel = doc.selection.createRange();
      sel.text = value;
    } else if (input.selectionStart || input.selectionStart == 0) {
      const startPos = input.selectionStart;
      const endPos = input.selectionEnd;

      input.value = input.value.substring(0, startPos)
        + value
        + input.value.substring(endPos);
    } else {
      input.value = (input.value || "") + value;
    }

    input.dispatchEvent(new Event("change"));
  }
  private async loadTextmarken() {
    this.textmarken = await this._nachrichtService.getTextmarken();
  }
  
  private async loadVorlagen() {
    this.vorlagen = await this._nachrichtVorlageService.getVorlagen();
  }
  private copyFromVorlage(vorlage: INachrichtVorlage) {
    this.nachricht.nachrichtText = vorlage.Text;
    this.nachricht.nachrichtSignatur = vorlage.Signatur || this.startupService.startupInfo.Benutzer.NachrichtSignatur;
    
    this.nachricht.dateiList = [...vorlage.Dateien.map(d => {
      return {
        DMSLink: d.DMSLink,
        Dateiname: d.Dateiname
      };
    })];
    
    this.datei.refresh();
  }
}
