import { autoinject, noView } from "aurelia-framework";
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { StartupService } from '../../services/export';
import { RestService } from '../../../framework/base/export';
import { HistoryService } from '../../../framework/forms/export';

@autoinject
@noView
export class CheckBenutzer {
  private _windowActivatedSubscription: Subscription;
  private _timeout: any;

  constructor(
    private _eventAggregator: EventAggregator,
    private _startupService: StartupService,
    private _restService: RestService,
    private _historyService: HistoryService
  ) {}

  bind() {
    this._windowActivatedSubscription = this._eventAggregator.subscribe("window:activated", () => {
      this.checkBenutzer();
      
      this._startupService.startupInfo.Mandant.IdMandant
    });

    this._timeout = setTimeout(() => {
      this.checkBenutzer();
    }, 30000);
  }
  unbind() {
    this._windowActivatedSubscription.dispose();
    this._windowActivatedSubscription = null;

    clearTimeout(this._timeout);
  }

  private async checkBenutzer() {
    const r = await this._restService.get({
      url: this._restService.getApiUrl("ZIT/Security/CurrentBenutzerData")
    });

    const isEqual = r.IdBenutzer == this._startupService.startupInfo.Benutzer.IdBenutzer
      && r.IdLoginprofil == this._startupService.startupInfo.Benutzer.IdLoginprofil
      && r.IdMandant == this._startupService.startupInfo.Mandant.IdMandant;
      
    if (isEqual) {
      return;
    }

    this._historyService.checkHasChangedDataBeforeUnload = false;
    window.location.reload();
  }
}