import { autoinject, OverrideContext, Scope } from 'aurelia-framework';
import { ScopeContainer, LocalizationService, AuthorizationService } from '../../../framework/base/export';
import { SimpleWidgetCreatorService, ICommandData, DialogConfirmService } from '../../../framework/forms/export';
import { DataSourceService } from '../../../framework/base/services/export';
import { ILoeschenBenutzerData } from '../../interfaces/export';
import { BenutzereinstellungenService } from '../../services/benutzereinstellungen-service';
import { IdxValidationGroupComponent, IdxSelectBoxComponent, IdxPopupComponent } from '../../interfaces/export';
import { GrundTyp } from '../../enumerations/export';
@autoinject
export class BenutzerLoeschen {
  constructor(
    private authorization: AuthorizationService,
    private benutzereinstellungen: BenutzereinstellungenService,
    private dataSource: DataSourceService,
    private localization: LocalizationService,
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private dialogConfirmService: DialogConfirmService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  loeschenBenutzerData: ILoeschenBenutzerData;

  popupCommands: ICommandData[] = [
    {
      id: "loeschenBenutzerprofil",
      idCategory: "$start",
      icon: "fas fa-save",
      tooltip: "benutzer-loeschen.benutzer-loeschen",
      sortIndex: 0,
      execute: () => {
        this.onBenutzerLoeschenClicked();
      }
    }
  ];

  benutzerLoeschenPopup: IdxPopupComponent;
  benutzerLoeschenPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxHeight: "80%",
    maxWidth: "420px"
  }
  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };
  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {};

  loeschenGrundOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Bezeichnung",
    bindingOptions: {
      value: "loeschenBenutzerData.IdGrund"
    }
  };
  loeschenBemerkungOptions: DevExpress.ui.dxTextAreaOptions = {
    height: 60,
    maxLength: 500,
    bindingOptions: {
      value: "loeschenBenutzerData.Bemerkung"
    }
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.loeschenGrundOptions.dataSource = this.dataSource.createDataSource(this.scopeContainer, {
      webApiAction: "ZIT/Stammdaten/Grund",
      webApiWhere: [["Typ", GrundTyp.BenutzerprofilLoeschen]]
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "benutzerLoeschenPopupToolbar",
      caption: "benutzereinstellungen.popup-titel",
      options: this.benutzerLoeschenPopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  showPopup() {
    this.benutzerLoeschenPopup.instance.show();
    this.validationGroup.instance.reset();
  }

  private onBenutzerLoeschenClicked() {
    const title = this.localization.translateOnce("benutzer-loeschen.popup-title");
    const message = this.localization.translateOnce("benutzer-loeschen.popup-text");

    this.dialogConfirmService.show({
      title: title,
      message: message
    }).then(confirmed => {
      if (!confirmed) {
        return;
      }

      const validationResult = this.validationGroup.instance.validate();
      if (!validationResult.isValid) {
        return;
      }

      this.benutzereinstellungen.deleteBenutzer(this.loeschenBenutzerData).then(r => {
        this.authorization.logout();
      });
    });
  }
}
