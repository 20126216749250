import { BrowserService } from './../../../framework/base/services/browser-service';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { FileService, RestService } from '../../../framework/base/export';
import { ListView } from '../../../framework/forms/export';
import { IListViewOptions } from './../../../framework/forms/elements/list-view/list-view-options';
import { IZitTagOptions } from '../../../zit-ui/elements/zit-tag/export';
import { CustomEditPopupService } from './../../services/export';
import { IZitToolbarOptions } from '../../../zit-ui/elements/zit-toolbar/zit-toolbar-options';
import { Subscription, EventAggregator } from 'aurelia-event-aggregator';
import { DateiService } from '../../services/datei-service';

@autoinject
export class DokumentListView {
  private _dokumentUpdatedSubscription: Subscription;

  constructor(
    private _element: Element,
    private _customEditPopupService: CustomEditPopupService,
    private _browserService: BrowserService,
    private _fileService: FileService,
    private _eventAggregator: EventAggregator,
    private _restService: RestService,
    private _dateiService: DateiService
  ) { }

  @bindable @observable idObjekt: number;
  @bindable @observable idStelleninserat: number;
  @bindable showToolbar: boolean;
  @bindable isReadOnly: boolean = false;
  @bindable allowTypes: string;
  @bindable objektTypeFullname: string;
  @bindable showGallerie: boolean = false;

  bildList: any[] = [];
  dokumentList: any[] = [];

  tagOptions: IZitTagOptions;

  toolbarOptions: IZitToolbarOptions = {
    title: "dokument-list-view.dokumente",
    smallToolbar: true,
    items: [{
      icon: "fas fa-plus",
      execute: () => {
        this.onDokumentAddClicked();
      }
    }]
  };

  dokumentListView: ListView;
  dokumentListViewOptions: IListViewOptions = {
    showReloadButton: false,
    useDefaultListItemStyle: false,
    itemClass: "z--list-view-inline-item",
    dataSource: new DevExpress.data.DataSource(new DevExpress.data.ArrayStore({data: this.dokumentList}))
  };

  dokumentTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    hasDefaultInfo: true,
    icon: {
      faIconExpression: `item.Informationstyp.TagSymbol`,
      textExpression: `item.Informationstyp.TagSymbol 
      ? ""
      : item.Informationstyp.TagText || item.Informationstyp.Bezeichnung[0].toUpperCase()`,
      backgroundColorExpression: "item.Informationstyp.FarbeTagSymbol"
    },
    textExpression: `item.Informationstyp.Bezeichnung | zitRestrictLength:50`,
    backgroundColorExpression: "item.Informationstyp.FarbeTagText",
    infoList: [
      {
        isVisibleExpression: `(item.NeedsUnterfertigung || item.IsUnterfertigt)`,
        icon: {
          faIconExpression: `item.IsUnterfertigt ? "fas fa-check-circle" : "fas pen-square"`,
          backgroundColorExpression: `item.IsUnterfertigt ? "green" : "red"`,
        },
        onClick: (e, data) => {
          e.stopPropagation();

          this._customEditPopupService.dokumentUnterfertigung.show({
            mappings: {
              "$id": data.Id
            },
            closeCallback: () => {
              this.dokumentListView.refresh();
            }
          });
        }
      }, {
        isClickEnabled: true,
        icon: {
          faIcon: "fas fa-paperclip",
        },
        onClick: (e, data) => {
          e.stopPropagation();
          this._fileService.download(data.DMSLinkDokument);
        }
      }, {
        icon: {
          faIcon: "fas fa-info-circle"
        },
        onClick: (e, data) => {
          e.stopPropagation();
          this.showDokumentEditPopup(data);
        }
      }
    ],
    onClick: (e, data) => {
      e.stopPropagation();
      this.downloadDokument(data);
    }
  };
  dokumentTagReadOptions: IZitTagOptions = {
    isClickEnabled: true,
    hasDefaultInfo: true,
    icon: {
      faIconExpression: `item.Informationstyp.TagSymbol`,
      textExpression: `item.Informationstyp.TagSymbol 
      ? ""
      : item.Informationstyp.TagText || item.Informationstyp.Bezeichnung[0].toUpperCase()`,
      backgroundColorExpression: "item.Informationstyp.FarbeTagSymbol"
    },
    textExpression: `item.Informationstyp.Bezeichnung | zitRestrictLength:50`,
    backgroundColorExpression: "item.Informationstyp.FarbeTagText",
    infoList: [
      {
        isVisibleExpression: `(item.NeedsUnterfertigung || item.IsUnterfertigt)`,
        icon: {
          faIconExpression: `item.IsUnterfertigt ? "fas fa-check-circle" : "fas fa-pencil-square"`,
          backgroundColorExpression: `item.IsUnterfertigt ? "green" : "red"`,
        },
        onClick: (e, data) => {
          e.stopPropagation();

          this._customEditPopupService.dokumentUnterfertigung.show({
            mappings: {
              "$id": data.Id
            },
            closeCallback: () => {
              this.dokumentListView.refresh();
            }
          });
        }
      }, {
        icon: {
          faIcon: "fas fa-paperclip",
        }
      }, {
        icon: {
          faIcon: "fas fa-info-circle"
        },
        onClick: (e, data) => {
          e.stopPropagation();
          this.showDokumentReadPopup(data);
        }
      }
    ],
    onClick: (e, data) => {
      e.stopPropagation();
      this.downloadDokument(data);
    }
  };

  bind() {
    this.tagOptions = this.isReadOnly ? this.dokumentTagReadOptions : this.dokumentTagOptions;

    this.showToolbar = this.showToolbar == void 0 ? true : this.showToolbar;

    this._dokumentUpdatedSubscription = this._eventAggregator.subscribe("dokument:updated", (e) => {
      if (this.idObjekt != e.idObjekt) {
        return;
      }

      this.loadData();
      this.dispatchDokumentChanged();
    });

    this.loadData();
  }
  unbind() {
    this._dokumentUpdatedSubscription.dispose();
    this._dokumentUpdatedSubscription = null;
  }

  idObjektChanged(newVal) {
    this.toolbarOptions.items[0].isEnabled = newVal != void 0;
    this.loadData();
  }
  idStelleninseratChanged() {
    this.loadData();
  }

  onDokumentAddClicked() {
    if (this.idObjekt == void(0)) {
      return;
    }

    this.showDokumentEditPopup({ Id: 0 });
  }
  onBildClick(ev: MouseEvent, bild) {
    ev.stopPropagation();
    ev.preventDefault();

    this.tagOptions.onClick(ev, bild);
  }
  onBildInfoClick(ev: MouseEvent, bild) {
    ev.stopPropagation();
    ev.preventDefault();

    if (this.isReadOnly) {
      this.showDokumentReadPopup(bild);
    } else {      
      this.showDokumentEditPopup(bild);
    }
  }
  onBildDownloadClick(ev: MouseEvent, bild) {
    ev.stopPropagation();
    ev.preventDefault();

    this.downloadDokument(bild);
  }

  private async loadData() {
    let data: any[];

    if (this.idObjekt) {
      const where: any[] = [["IdObjekt", this.idObjekt]];

      if (this.idStelleninserat != void 0) {
        where.push([["IdStelleninserat", this.idStelleninserat], "or", ["IdStelleninserat", "null"]]);
      }
      if (this.allowTypes != void 0) {
        const allowList = this.allowTypes.split(",");
        allowList.forEach((t) => {
          where.push(["Informationstyp.Code", t])
        });
      }
  
      data = await this._restService.get({
        url: this._restService.getWebApiUrl("ZIT/Objekte/DokumentElement"),
        getOptions: {
          expand: { Informationstyp: null },
          where: where,
          orderBy: [{columnName: "Id", sortOrder: 0}]
        }
      });
    } else {
      data = [];
    }

    this.dokumentList.splice(0, this.dokumentList.length);
    this.bildList.splice(0, this.bildList.length);

    data.forEach(i => {
      const isBild = this.showGallerie
        && this._dateiService.isBild(i.Dateiname);

      if (isBild) {
        this.bildList.push(i);
      } else {
        this.dokumentList.push(i);
      }
    });

    if (this.dokumentListView.options) {
      this.dokumentListView.refresh();
    }
  }
  
  private showDokumentEditPopup(data: any) {
    const mappings = {
      "$id": data.Id
    };
    const onModelLoaded = {
      IdObjekt: this.idObjekt
    }
    if (this.idStelleninserat != void 0) {
      mappings["$v_idStelleninserat"] = this.idStelleninserat;
      onModelLoaded["IdStelleninserat"] = this.idStelleninserat;
    }

    if (this.allowTypes != void 0) {
      mappings["$v_allowTypes"] = this.allowTypes;
    }

    if (this.objektTypeFullname && this.objektTypeFullname.length > 0) {
      mappings["$v_objektTypeFullname"] = this.objektTypeFullname;
    }

    this._customEditPopupService.dokumentEdit.show({
      mappings: mappings,
      setValuesOnModelWithKeyIdLoaded: onModelLoaded
    });
  }
  private showDokumentReadPopup(data: any) {
    this._customEditPopupService.dokumentRead.show({
      mappings: {
        "$id": data.Id
      }
    });
  }
  private downloadDokument(data) {
    if (this._browserService.isIE) {
      this._fileService.download(data.DMSLinkDokument);
    } else {
      this._fileService.inline(data.DMSLinkDokument);
    }
  }
  private dispatchDokumentChanged() {
    const event = new CustomEvent("on-dokument-changed", {
      detail: {
        sender: this,
        element: this._element
      },
      bubbles: true
    });

    this._element.dispatchEvent(event);
  }
}
