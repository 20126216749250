import * as fwx from "../../../../framework/forms/form-export";
import {
    StelleninseratEditUtils
} from "./stelleninserat-edit-utils";

@fwx.autoinject
export class StelleninseratKumiEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_Stelleninserat: StelleninseratEditUtils) {
        super(element, formBaseImport);
        this.id = "stelleninserat-kumi-edit";
        this.title = "stelleninserat-kumi-edit.stelleninserat-kumi-edit_caption";
        this.addModel({
            "id": "$m_Stelleninserat",
            "webApiAction": "ZIT/Objekte/Stelleninserat",
            "webApiExpand": {
                'Kopf': null,
                'FerialeinsatzEinstellungen': null,
                'FerialbewerbungFragebogen': null
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "BewerbungInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "ProfilFirmaInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "StelleninseratVorschauUrl",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_StelleninseratKopfElement",
            "webApiAction": "ZIT/Objekte/StelleninseratKopfElement",
            "webApiColumns": ['Id'],
            "key": "models.data.$m_Stelleninserat.IdStelleninseratKopfElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratGruppeRel",
            "webApiAction": "ZIT/Objekte/StelleninseratGruppe",
            "webApiColumns": ["Id", "SortNr", "Text", "IdStelleninseratGruppentyp"],
            "webApiExpand": {
                'Gruppentyp': null
            },
            "webApiOrderBy": [{
                "columnName": "GruppentypSortNr",
                "sortOrder": 0
            }, {
                "columnName": "GruppentypBezeichnung",
                "sortOrder": 0
            }, {
                "columnName": "SortNr",
                "sortOrder": 0
            }, {
                "columnName": "Text",
                "sortOrder": 0
            }],
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratGruppeEdit",
            "webApiAction": "ZIT/Objekte/StelleninseratGruppe",
            "webApiExpand": {
                'Gruppentyp': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratGruppeElement",
            "webApiAction": "ZIT/Objekte/StelleninseratGruppeElement",
            "key": "models.data.$m_Stelleninserat.IdStelleninseratGruppeElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_STFBFB",
            "webApiAction": "ZIT/Objekte/StelleninseratFerialbewerbungFragebogenElement",
            "key": "models.data.$m_Stelleninserat.IdStelleninseratFerialbewerbungFragebogenElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_STFBFBEinsatzbereich",
            "webApiAction": "ZIT/Objekte/StelleninseratFerialbewerbungFragebogenEinsatzbereich",
            "webApiColumns": ["Id", "IdStelleninseratFerialbewerbungFragebogenElement", "Einsatzbereich", "SortNr"],
            "webApiOrderBy": [{
                "columnName": "SortNr",
                "sortOrder": 0
            }, {
                "columnName": "Einsatzbereich",
                "sortOrder": 1
            }],
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_STFBFBEinsatzbereichE",
            "webApiAction": "ZIT/Objekte/StelleninseratFerialbewerbungFragebogenEinsatzbereich",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Bewerbung",
            "webApiAction": "ZIT/Objekte/Bewerbung",
            "webApiColumns": ["Id"],
            "webApiWhere": ["Kopf.IdStelleninserat", {
                "isBound": true,
                "expression": "models.data.$m_Stelleninserat.Id"
            }],
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "AddBewerbungCard",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_Bewerbungstyp",
            "webApiAction": "ZIT/Stammdaten/StelleninseratBewerbungstyp",
            "key": "models.data.$m_Stelleninserat.Kopf.IdBewerbungstyp",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratKopfElementChannelRel",
            "webApiAction": "ZIT/Objekte/StelleninseratKopfElementZuChannel",
            "webApiExpand": {
                'Channel': null,
                'StelleninseratBewerbungstyp': null
            },
            "keyProperty": "Id",
            "allowNew": "functions.$f_Stelleninserat.canAddChannel",
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratKopfElementChannelEdit",
            "webApiAction": "ZIT/Objekte/StelleninseratKopfElementZuChannel",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_Stelleninserat.actionCommand",
                "bindToFQ": "functions.$f_Stelleninserat.actionCommand"
            }
        });
        this.addEditPopup({
            "idContent": "stelleninseratGruppeEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_StelleninseratGruppeEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_StelleninseratGruppeEdit.Id"
                }
            }, {
                "to": "$v_idGeschaeftspartner",
                "binding": {
                    "dataContext": "$m_Stelleninserat",
                    "bindTo": "Kopf.IdGeschaeftspartner",
                    "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner",
                    "propertyPrefix": "Kopf"
                }
            }, {
                "to": "$v_idStelleninserat",
                "binding": {
                    "dataContext": "$m_Stelleninserat",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_Stelleninserat.Id"
                }
            }],
            "id": "stelleninseratGruppeEditPopup",
            "options": {
                "optionsName": "stelleninseratGruppeEditPopupOptions",
                "optionsNameFQ": "stelleninseratGruppeEditPopupOptions"
            },
            "caption": "stelleninserat-kumi-edit.stelleninseratgruppeeditpopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "stelleninseratFerialbewerbungFragebogenEinsatzbereichEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_STFBFBEinsatzbereichE",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_STFBFBEinsatzbereichE.Id"
                }
            }],
            "id": "stelleninseratFerialbewerbungFragebogenEinsatzbereichEditPopup",
            "options": {
                "optionsName": "stelleninseratFerialbewerbungFragebogenEinsatzbereichEditPopupOptions",
                "optionsNameFQ": "stelleninseratFerialbewerbungFragebogenEinsatzbereichEditPopupOptions"
            },
            "caption": "stelleninserat-kumi-edit.stelleninseratferialbewerbungfragebogeneinsatzbereicheditpopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "stelleninseratKopfElementChannelEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_StelleninseratKopfElementChannelEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_StelleninseratKopfElementChannelEdit.Id"
                }
            }],
            "id": "stelleninseratKopfElementChannelEditPopup",
            "options": {
                "optionsName": "stelleninseratKopfElementChannelEditPopupOptions",
                "optionsNameFQ": "stelleninseratKopfElementChannelEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addFunction("$f_Stelleninserat", $f_Stelleninserat, "functions.$f_Stelleninserat");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "geschaeftspartner",
            "filters": [],
            "caption": "stelleninserat-kumi-edit.r_geschaeftspartner_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IdGeschaeftspartner",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartner",
            "options": {
                "optionsName": "r_geschaeftspartnerOptions",
                "optionsNameFQ": "r_geschaeftspartnerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "stelleninserat-kumi-edit.r_stellenbezeichnungextern_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.StellenbezeichnungExtern",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.StellenbezeichnungExtern",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_stellenbezeichnungExtern",
            "options": {
                "optionsName": "r_stellenbezeichnungExternOptions",
                "optionsNameFQ": "r_stellenbezeichnungExternOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showSpinButtons": true,
            "min": 0.0,
            "caption": "stelleninserat-kumi-edit.r_sortnr_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.SortNr",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.SortNr",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_sortNr",
            "options": {
                "optionsName": "r_sortNrOptions",
                "optionsNameFQ": "r_sortNrOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "stelleninserat-kumi-edit.r_stellenbezeichnungintern_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.StellenbezeichnungIntern",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.StellenbezeichnungIntern",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_stellenbezeichnungIntern",
            "options": {
                "optionsName": "r_stellenbezeichnungInternOptions",
                "optionsNameFQ": "r_stellenbezeichnungInternOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "stelleninserat-kumi-edit.r_gueltigvon_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.GueltigVon",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.GueltigVon",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigVon",
            "options": {
                "optionsName": "r_gueltigVonOptions",
                "optionsNameFQ": "r_gueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "stelleninserat-kumi-edit.r_gueltigbis_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.GueltigBis",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.GueltigBis",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigBis",
            "options": {
                "optionsName": "r_gueltigBisOptions",
                "optionsNameFQ": "r_gueltigBisOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "branche",
            "filters": [],
            "caption": "stelleninserat-kumi-edit.r_branche_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IdBranche",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdBranche",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_branche",
            "options": {
                "optionsName": "r_brancheOptions",
                "optionsNameFQ": "r_brancheOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "geschaeftspartner",
            "filters": [],
            "caption": "stelleninserat-kumi-edit.r_geschaeftspartnernopackage_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IdGeschaeftspartner",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Stelleninserat",
                    "bindTo": "$f_Stelleninserat.keinPackageRule",
                    "bindToFQ": "functions.$f_Stelleninserat.keinPackageRule"
                }
            }, {
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartnerNoPackage",
            "options": {
                "optionsName": "r_geschaeftspartnerNoPackageOptions",
                "optionsNameFQ": "r_geschaeftspartnerNoPackageOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "ansprechperson",
            "customs": [{
                "key": "IdGeschaeftspartner",
                "value": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner"
            }],
            "filters": [],
            "caption": "stelleninserat-kumi-edit.ansprechpersonselect_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IdAnsprechperson",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdAnsprechperson",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "ansprechpersonSelect",
            "options": {
                "optionsName": "ansprechpersonSelectOptions",
                "optionsNameFQ": "ansprechpersonSelectOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 0,
            "acceptType": "image/*",
            "placeholderImageExpression": "models.data.$m_Stelleninserat.Kopf.DMSLinkStandardBild",
            "placeholderImageText": "Standardbild laut Lehrberuf",
            "height": "250px",
            "showToolbar": true,
            "showClearButton": false,
            "caption": "stelleninserat-kumi-edit.r_dmslinkbild_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.DMSLinkBild",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.DMSLinkBild",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_dmslinkBild",
            "options": {
                "optionsName": "r_dmslinkBildOptions",
                "optionsNameFQ": "r_dmslinkBildOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "stelleninserat-kumi-edit.r_stelleninserataktiv_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IsAktiv",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IsAktiv",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_stelleninseratAktiv",
            "options": {
                "optionsName": "r_stelleninseratAktivOptions",
                "optionsNameFQ": "r_stelleninseratAktivOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "stelleninserat-kumi-edit.r_nuronlinebewerbungerwuenscht_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.NurOnlineBewerbungErwuenscht",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.NurOnlineBewerbungErwuenscht",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_nurOnlineBewerbungErwuenscht",
            "options": {
                "optionsName": "r_nurOnlineBewerbungErwuenschtOptions",
                "optionsNameFQ": "r_nurOnlineBewerbungErwuenschtOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "stelleninseratBewerbungstyp",
            "filter": [
                ["IdGeschaeftspartner", "null"], "or", ["IdGeschaeftspartner", {
                    "isBound": true,
                    "expression": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner"
                }]
            ],
            "customs": [{
                "key": "IdGeschaeftspartner",
                "value": "models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner"
            }, {
                "key": "CheckBenutzerrolle",
                "value": "true"
            }],
            "filters": [],
            "caption": "stelleninserat-kumi-edit.bewerbungstyp_caption",
            "placeholder": "stelleninserat-kumi-edit.bewerbungstyp_placeholder",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.IdBewerbungstyp",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.IdBewerbungstyp",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "bewerbungstyp",
            "options": {
                "optionsName": "bewerbungstypOptions",
                "optionsNameFQ": "bewerbungstypOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "stelleninserat-kumi-edit.r_bewerbungvon_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialeinsatzEinstellungen.BewerbungOnlineVon",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialeinsatzEinstellungen.BewerbungOnlineVon",
                "propertyPrefix": "FerialeinsatzEinstellungen"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Stelleninserat",
                    "bindTo": "$f_Stelleninserat.bewerbungVonValidationRule",
                    "bindToFQ": "functions.$f_Stelleninserat.bewerbungVonValidationRule"
                }
            }],
            "id": "r_bewerbungVon",
            "options": {
                "optionsName": "r_bewerbungVonOptions",
                "optionsNameFQ": "r_bewerbungVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "stelleninserat-kumi-edit.r_bewerbungbis_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialeinsatzEinstellungen.BewerbungOnlineBis",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialeinsatzEinstellungen.BewerbungOnlineBis",
                "propertyPrefix": "FerialeinsatzEinstellungen"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Stelleninserat",
                    "bindTo": "$f_Stelleninserat.bewerbungBisValidationRule",
                    "bindToFQ": "functions.$f_Stelleninserat.bewerbungBisValidationRule"
                }
            }],
            "id": "r_bewerbungBis",
            "options": {
                "optionsName": "r_bewerbungBisOptions",
                "optionsNameFQ": "r_bewerbungBisOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "stelleninserat-kumi-edit.r_standort_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.Standort",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.Standort",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_standort",
            "options": {
                "optionsName": "r_standortOptions",
                "optionsNameFQ": "r_standortOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 500,
            "caption": "stelleninserat-kumi-edit.r_googlekarteadresse_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.GoogleKartenAdresse",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.GoogleKartenAdresse",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_googleKarteAdresse",
            "options": {
                "optionsName": "r_googleKarteAdresseOptions",
                "optionsNameFQ": "r_googleKarteAdresseOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "stelleninserat-kumi-edit.r_subheadline_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.Subheadline",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.Subheadline",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_subheadline",
            "options": {
                "optionsName": "r_subheadlineOptions",
                "optionsNameFQ": "r_subheadlineOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 500,
            "caption": "stelleninserat-kumi-edit.r_weiterleitungslink_caption",
            "isReadOnlyExpression": "!models.data.$m_Bewerbungstyp || !models.data.$m_Bewerbungstyp.WebsiteUmleitungLautWeiterleitungslink",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "Kopf.Weiterleitungslink",
                "bindToFQ": "models.data.$m_Stelleninserat.Kopf.Weiterleitungslink",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_weiterleitungslink",
            "options": {
                "optionsName": "r_weiterleitungslinkOptions",
                "optionsNameFQ": "r_weiterleitungslinkOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-kumi-edit.r_lebenslaufanfragen_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.LebenslaufAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.LebenslaufAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_lebenslaufAnfragen",
            "options": {
                "optionsName": "r_lebenslaufAnfragenOptions",
                "optionsNameFQ": "r_lebenslaufAnfragenOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-kumi-edit.r_anschreibenanfragen_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.AnschreibenAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.AnschreibenAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_anschreibenAnfragen",
            "options": {
                "optionsName": "r_anschreibenAnfragenOptions",
                "optionsNameFQ": "r_anschreibenAnfragenOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-kumi-edit.r_zeugnisanfragen_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.LetztesZeugnisAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.LetztesZeugnisAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_zeugnisAnfragen",
            "options": {
                "optionsName": "r_zeugnisAnfragenOptions",
                "optionsNameFQ": "r_zeugnisAnfragenOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-kumi-edit.r_reisepassausweisanfragen_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.ReisepassAusweisAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.ReisepassAusweisAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_reisepassAusweisAnfragen",
            "options": {
                "optionsName": "r_reisepassAusweisAnfragenOptions",
                "optionsNameFQ": "r_reisepassAusweisAnfragenOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-kumi-edit.r_zertifikat_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.ZertifikatAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.ZertifikatAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_zertifikat",
            "options": {
                "optionsName": "r_zertifikatOptions",
                "optionsNameFQ": "r_zertifikatOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-kumi-edit.r_sprachkenntnisse_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.SprachKenntnisseAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.SprachKenntnisseAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_sprachkenntnisse",
            "options": {
                "optionsName": "r_sprachkenntnisseOptions",
                "optionsNameFQ": "r_sprachkenntnisseOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-kumi-edit.r_bisherigeberufserfahrung_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.BisherigeBerufserfahrungAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.BisherigeBerufserfahrungAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_bisherigeBerufserfahrung",
            "options": {
                "optionsName": "r_bisherigeBerufserfahrungOptions",
                "optionsNameFQ": "r_bisherigeBerufserfahrungOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-kumi-edit.r_fuehrerscheinundmobilitaet_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.FuehrerscheinUndMobilitaetAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.FuehrerscheinUndMobilitaetAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_fuehrerscheinUndMobilitaet",
            "options": {
                "optionsName": "r_fuehrerscheinUndMobilitaetOptions",
                "optionsNameFQ": "r_fuehrerscheinUndMobilitaetOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "pflichtfeld",
            "filters": [],
            "caption": "stelleninserat-kumi-edit.r_staplerfuehrerschein_caption",
            "binding": {
                "dataContext": "$m_Stelleninserat",
                "bindTo": "FerialbewerbungFragebogen.StaplerFuehrerscheinAnfragen",
                "bindToFQ": "models.data.$m_Stelleninserat.FerialbewerbungFragebogen.StaplerFuehrerscheinAnfragen",
                "propertyPrefix": "FerialbewerbungFragebogen"
            },
            "validationRules": [],
            "id": "r_staplerfuehrerschein",
            "options": {
                "optionsName": "r_staplerfuehrerscheinOptions",
                "optionsNameFQ": "r_staplerfuehrerscheinOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_moeglicheEinsatzbereicheToolbarOptions",
                "optionsNameFQ": "r_moeglicheEinsatzbereicheToolbarOptions"
            },
            "caption": "stelleninserat-kumi-edit.r_moeglicheeinsatzbereiche_caption",
            "binding": {
                "dataContext": "$m_STFBFB"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_STFBFBEinsatzbereich",
                "bindTo": "IdStelleninseratFerialbewerbungFragebogenElement",
                "bindToFQ": "models.data.$m_STFBFBEinsatzbereich.IdStelleninseratFerialbewerbungFragebogenElement"
            },
            "dataModel": "$m_STFBFB",
            "editDataContext": "$m_STFBFBEinsatzbereichE",
            "idEditPopup": "stelleninseratFerialbewerbungFragebogenEinsatzbereichEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_moeglicheEinsatzbereiche",
            "options": {
                "optionsName": "r_moeglicheEinsatzbereicheOptions",
                "optionsNameFQ": "r_moeglicheEinsatzbereicheOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "col-xs-12 col-sm-6",
            "useDefaultListItemStyle": false,
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_bewerbungenListViewToolbarOptions",
                "optionsNameFQ": "r_bewerbungenListViewToolbarOptions"
            },
            "caption": "stelleninserat-kumi-edit.r_bewerbungenlistview_caption",
            "binding": {
                "dataContext": "$m_Bewerbung"
            },
            "dataModel": "$m_Bewerbung",
            "pageSize": 4,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewerbungenListView",
            "options": {
                "optionsName": "r_bewerbungenListViewOptions",
                "optionsNameFQ": "r_bewerbungenListViewOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_channelBezeichnung",
                "caption": "stelleninserat-kumi-edit.r_channelbezeichnung_caption",
                "bindTo": "Channel.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_channelVeroeffentlichungtyp",
                "caption": "stelleninserat-kumi-edit.r_channelveroeffentlichungtyp_caption",
                "bindTo": "VeroeffentlichungTyp",
                "minWidth": 100,
                "enumTypeName": "TIP.ZIT.Business.Enumerations.VeroeffentlichungTypEnum"
            }, {
                "id": "r_channelBewerbungstyp",
                "caption": "stelleninserat-kumi-edit.r_channelbewerbungstyp_caption",
                "bindTo": "StelleninseratBewerbungstyp.Bezeichnung",
                "minWidth": 100
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_StelleninseratKopfElementChannelGridToolbarOptions",
                "optionsNameFQ": "r_StelleninseratKopfElementChannelGridToolbarOptions"
            },
            "caption": "stelleninserat-kumi-edit.r_stelleninseratkopfelementchannelgrid_caption",
            "binding": {
                "dataContext": "$m_StelleninseratKopfElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_StelleninseratKopfElementChannelRel",
                "bindTo": "IdStelleninseratKopfElement",
                "bindToFQ": "models.data.$m_StelleninseratKopfElementChannelRel.IdStelleninseratKopfElement"
            },
            "dataModel": "$m_StelleninseratKopfElement",
            "editDataContext": "$m_StelleninseratKopfElementChannelEdit",
            "idEditPopup": "stelleninseratKopfElementChannelEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_StelleninseratKopfElementChannelGrid",
            "options": {
                "optionsName": "r_StelleninseratKopfElementChannelGridOptions",
                "optionsNameFQ": "r_StelleninseratKopfElementChannelGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}