import {
  autoinject, Scope
} from "aurelia-framework";
import {
  RestService
} from "./rest-service";
import {
  BindingService
} from "./binding-service";
import {
  ScopeContainer
} from "../classes/scope-container";
import {
  ILocalizationItem,
  ILocalizationTranslationInfo
} from "../interfaces/export";

import * as localizationNeutral from "../../../framework-data/localization-neutral.json";

@autoinject
export class LocalizationService {
  private isInitialized: boolean;

  constructor(
    private rest: RestService,
    private binding: BindingService
  ) { }

  addLocalizationNeutral(namespace: string, key: string, translation: string) {
    const n = localizationNeutral[namespace];
    if (!n) {
      localizationNeutral[namespace] = {};
    }

    localizationNeutral[namespace][key] = translation;
  }
  getTranslationInfo(key: string | ILocalizationItem): ILocalizationTranslationInfo {
    if (!key) {
      return {
        needsBinding: false,
        text: ""
      };
    }

    let item: ILocalizationItemInfo;

    try {
      item = typeof key === "string"
        ? this.getLocalizationItem(key)
        : this.getLocalizationItem(key.key);

    }
    catch (e) {
      throw new Error(`No localization found for ${key}`);
    }

    if (!item) {
      throw new Error(`No localization found for ${key}`);
    }

    let text = item.text;
    let needsBinding = false;
    let scope: Scope = null;

    if (typeof key === "string") {
      needsBinding = item.parameters
        && item.parameters.length > 0;

      item.parameters.forEach((expr, index) => {
        text = text.replace(new RegExp("\\{" + index + "\\}", "g"), "${" + expr + "}");
      });
    } else if (key.parameters && key.parameters.length > 0) {
      needsBinding = true;
      scope = {
        bindingContext: key,
        overrideContext: null
      };
      key.parameters.forEach((expr, index) => {
        text = text.replace(new RegExp("\\{" + index + "\\}", "g"), "${parameters[" + index + "]}");
      });
    }

    return {
      text: text,
      needsBinding: needsBinding,
      scope: scope
    };
  }
  translateOnce(key: string | ILocalizationItem, parameters?: string[] | ScopeContainer): string {
    if (!key) {
      return "";
    }

    let item: ILocalizationItemInfo;

    try {
      item = typeof key === "string"
        ? this.getLocalizationItem(key)
        : this.getLocalizationItem(key.key);

    }
    catch (e) {
      throw new Error(`No localization found for ${key}`);
    }

    if (!item) {
      throw new Error(`No localization found for ${key}`);
    }

    if (!(typeof key === "string")) {
      parameters = key.parameters;
    }

    if (!item) {
      throw new Error(`No localization found for ${key}`);
    }

    return this.translateItem(item, parameters);
  }
  private getLocalizationItem(key: string): ILocalizationItemInfo {
    const items = key.split(".");

    let item: any = localizationNeutral;
    items.forEach(i => {
      if (!item) {
        return;
      }

      item = item[i];
    });

    if (typeof item === "string") {
      return {
        key: key,
        text: item,
        parameters: []
      }
    } else {
      return {
        key: key,
        text: item.text,
        parameters: item.parameters
      }
    }
  }
  private translateItem(item: ILocalizationItemInfo, parameters?: string[] | ScopeContainer): string {
    let text = item.text;

    if (parameters != void (0)) {
      if (Array.isArray(parameters)) {
        parameters.forEach((val, index) => {
          text = text.replace(new RegExp("\\{" + index + "\\}", "g"), val);
        });
      } else {
        item.parameters.forEach((expr, index) => {
          let val = this.binding.evaluate(parameters.scope, expr);

          if (val == void (0)) {
            val = "";
          }

          text = text.replace(new RegExp("\\{" + index + "\\}", "g"), val);
        });
      }
    }

    return text;
  }
}

interface ILocalizationItemInfo {
  key: string;
  text: string;
  parameters: string[];
}
