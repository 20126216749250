import { autoinject } from "aurelia-framework";
import { IHtmlEditorOptions } from '../widget-options/html-editor-options';

import * as Quill from "quill";
import { ICommandData } from '../interfaces/export';
import { CommandService } from './command-service';
import { ScopeContainer, BindingService } from '../../base/export';
import { EventAggregator } from 'aurelia-event-aggregator';
const Embed = Quill.import("blots/embed");

export class CustomTabBlot extends Embed {
  static create() {
    const node = super.create();
    node.innerText = "\t";

    return node;
  }
  static value(node) {
    return {
      value: node.innerText || null,
    };
  }

  static blotName = "custom-tab";
  static tagName = "span";
  static className = "t--html-custom-tab";
}

Quill.register("formats/custom-tab", CustomTabBlot);

@autoinject
export class HtmlEditorService {
  constructor(
    private commandService: CommandService,
    private bindingService: BindingService,
    private eventAggregator: EventAggregator
  ) { }

  defaultHeaders = [1, 2];

  configureHtmlEditor(scopeContainer: ScopeContainer, options: IHtmlEditorOptions, editorOptions: DevExpress.ui.dxHtmlEditorOptions) {
    let onInitialized = editorOptions.onInitialized;
    let onContentReady = editorOptions.onContentReady;
    let onDisposing = editorOptions.onDisposing;
    let htmlEditor: DevExpress.ui.dxHtmlEditor;

    editorOptions.onInitialized = (e) => {
      if (onInitialized) {
        onInitialized(e);
      }

      htmlEditor = e.component;
    }
    editorOptions.onContentReady = (e) => {
      if (onContentReady) {
        onContentReady(e);
      }

      const quill = e.component.getQuillInstance();
      this.configurePasteWithoutFont(quill);
      this.rebindTabKey(quill);

    };
    editorOptions.onDisposing = (e) => {
      if (onDisposing) {
        onDisposing(e);
        onDisposing = null;
      }

      htmlEditor = null;
    }

    if (options.height) {
      editorOptions.height = options.height;
    } else {
      editorOptions.height = "150px";
    }

    if (options.showToolbar == void (0)) {
      options.showToolbar = true;
    }

    if (options.mentionKey) {
      const args = {
        mentionKey: options.mentionKey,
        mention: null
      };

      this.eventAggregator.publish("html-editor:mention", args);

      if (args.mention) {
        editorOptions.mentions = [args.mention];
      }
    }

    if (options.showToolbar) {
      const toolbarItems: any[] = ["bold", "italic", "underline", "orderedList", "bulletList", "link"];

      if (options.allowImages) {
        this.addImageButton(toolbarItems, () => htmlEditor);
      }
      if (options.allowHeaders) {
        toolbarItems.push({
          formatName: "header",
          formatValues: (<any[]>[false]).concat(...this.defaultHeaders)
        });
      }
      if (options.allowColors) {
        toolbarItems.push("color", "background");
      }
      if (options.variables != void (0)) {
        editorOptions.variables = {
          dataSource: options.variables.split(";").filter(c => !!c)
        };

        if (options.variableEscapeChars) {
          editorOptions.variables.escapeChar = options.variableEscapeChars.split(";");
        } else {
          editorOptions.variables.escapeChar = ["${", "}"]
        }

        toolbarItems.push("variable");
      }
      if (options.commandData && options.commandData.length > 0) {
        this.addCommandDataToToolbar(scopeContainer, toolbarItems, options.commandData, () => htmlEditor);
      }

      toolbarItems.push("clear");

      editorOptions.toolbar = {
        items: toolbarItems
      };
    }
  }

  private configurePasteWithoutFont(quill: any) {
    quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      delta.ops = delta.ops.map(op => {
        const attributes = Object.assign({}, op.attributes || {});
        delete attributes.font;
        delete attributes.size;

        return {
          insert: op.insert,
          attributes: attributes
        };
      });

      return delta;
    });
  }
  private rebindTabKey(quill) {
    const tabBindings = quill.keyboard.bindings[9];
    if (tabBindings) {
      for (let i = 0; i < tabBindings.length; i++) {
        const tabBinding = tabBindings[i];
        if (tabBinding.format) {
          continue;
        }

        tabBindings.splice(i, 1);
        i--;
      }
    }

    quill.keyboard.addBinding({
      key: 9,
    }, (r, e) => {
      quill.insertEmbed(r.index, "custom-tab", "");
      quill.setSelection(r.index + 1)
    });
  }
  private addImageButton(toolbarItems: any[], getHtmlEditor: { (): DevExpress.ui.dxHtmlEditor }) {
    //TODO DXUPDATE
    toolbarItems.push({
      widget: "dxButton",
      options: <DevExpress.ui.dxButtonOptions>{
        icon: "far fa-image",
        hint: "Bild einfügen",
        stylingMode: "text",
        onClick: (e) => {
          e.event.stopPropagation();
          e.event.preventDefault();

          const htmlEditor = getHtmlEditor();
          if (!htmlEditor) {
            return;
          }

          const editor: any = htmlEditor;
          if (!editor._imgUpload) {
            const input = document.createElement("input");
            input.type = "file";
            input.accept = "image/png, image/gif, image/jpeg";
            input.hidden = true;

            input.addEventListener("change", (e: any) => {
              const files = e.target.files;
              if (!files || files.length == 0 || !files[0]) {
                return;
              }

              let reader = new FileReader();
              reader.onload = (x: any) => {
                const selection = htmlEditor.getSelection();
                const pasteIndex = selection ? selection.index : htmlEditor.getLength();
                htmlEditor.insertEmbed(pasteIndex, "extendedImage", { src: x.target.result });
                htmlEditor.setSelection(pasteIndex + 1, 0);
                e.target.value = "";
              };
              reader.readAsDataURL(e.target.files[0]);
            });

            editor._imgUpload = input;
            htmlEditor.element().append(input);
          }

          editor._imgUpload.click();
        }
      }
    });
  }
  private addCommandDataToToolbar(scopeContainer: ScopeContainer, toolbarItems: any[], commandData: ICommandData[], getHtmlEditor: { (): DevExpress.ui.dxHtmlEditor }) {
    for (let item of commandData) {

      toolbarItems.push({
        widget: "dxButton",
        options: <DevExpress.ui.dxButtonOptions>{
          icon: item.icon,
          text: item.title,
          hint: item.tooltip,
          stylingMode: "text",
          disabled: !this.commandService.isEnabled(item),
          visible: this.commandService.isVisible(item),
          _commandData: item,
          onInitialized: (e) => {
            if (item.isEnabledExpression) {
              this.bindingService.observe({
                scopeContainer: scopeContainer,
                expression: item.isEnabledExpression,
                callback: (newValue) => {
                  e.component.option("disabled", !newValue);
                }
              });
            }
            if (item.isVisibleExpression) {
              this.bindingService.observe({
                scopeContainer: scopeContainer,
                expression: item.isVisibleExpression,
                callback: (newValue) => {
                  e.component.option("visible", newValue);
                }
              });
            }
          },
          onClick: (e) => {
            e.event.stopPropagation();
            e.event.preventDefault();

            this.commandService.execute(scopeContainer.scope, item, {
              event: <any>e.event,
              details: {
                htmlEditor: getHtmlEditor()
              }
            });
          }
        }
      });
    }
  }
}