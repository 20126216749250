
import {
  autoinject
} from "aurelia-framework";
import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  RestService, StyleService
} from "../../framework/base/export";
import {
  IStartupInfo, ICardStartupInfo
} from "../interfaces/export";
import { FarbeService } from './farbe-service';

@autoinject
export class StartupService {

  constructor(
    private rest: RestService,
    private eventAggregator: EventAggregator,
    private farbeService: FarbeService,
    private styleService: StyleService
  ) { }
  
  startupInfo: IStartupInfo;

  getCardInfoByObjektFullName(objektFullName: string): ICardStartupInfo {
    if (!objektFullName) {
      return null;
    }

    const found = this.startupInfo.Layout.CardList.find(c => c.Objekttyp == objektFullName);

    return found || null;
  }

  loadStartupInfo(): Promise<IStartupInfo> {
    return this.rest.get({
      url: this.rest.getApiUrl("ZIT/Startup/Get")
    }).then(r => {
      this.startupInfo = r;

      if (this.startupInfo && this.startupInfo.Skin && this.startupInfo.Skin.Farbe) {
        this.startupInfo.Skin.Farbe20Opacity = this.farbeService.convertHexToCssRgba(this.startupInfo.Skin.Farbe, .2);
      }

      this.eventAggregator.publish(
        "startup-info:loaded", {
          startupInfo: r
        });

      return r;
    });
  }
}
