import { StartupService } from './../../../services/startup-service';
import { DxWidget } from './../../../../framework/dx/elements/dx-widget';
import { Share } from './../../../elements/share/share';
import { ICommandData } from './../../../../framework/forms/interfaces/command-data';
import { IBenutzerrolleCodeData } from './../../../interfaces/benutzerrolle-code-data';
import { IZitTagOptions } from './../../../../zit-ui/elements/zit-tag/zit-tag-options';
import { autoinject, transient, computedFrom } from 'aurelia-framework';
import { FormBase } from './../../../../framework/forms/classes/form-base';
import { IZitToolbarOptions } from '../../../../zit-ui/elements/zit-toolbar/zit-toolbar-options';
import { LocalizationService, FileService, GlobalizationService, LocationService } from './../../../../framework/base/services/export';
import { DefaultCommandsService } from './../../../../framework/forms/services/export';
import { ContextMenu, IListOptionsBase } from '../../../../framework/forms/export';
import { CustomEditPopupService } from '../../../services/export';
import { ListView } from '../../../../framework/forms/elements/list-view/export';
import { DokumentListView } from '../../../elements/dokument-list-view/dokument-list-view';
import { IdxValidationGroupComponent } from '../../../interfaces/export';
import { RestService } from '../../../../framework/base/services/export';
import { Geschlecht } from '../../../enumerations/export';
import { IdxPopupComponent } from './../../../interfaces/export';

@autoinject
@transient()
export class PersonenEditUtils {
  constructor(
    private customEditPopup: CustomEditPopupService,
    private defaultCommand: DefaultCommandsService,
    private localization: LocalizationService,
    private location: LocationService,
    private file: FileService,
    private globalization: GlobalizationService,
    private rest: RestService,
    private startupService: StartupService
  ) { }

  form: FormBase;
  requiredValidationRule = { type: "required" };

  detailToolbarOptions: IZitToolbarOptions = {
    title: "person.details-toolbar-title",
    smallToolbar: true,
    items: [{
      id: "personEditUtilsDetailCommand",
      icon: "fas fa-plus",
      execute: (e) => {
        this.showDetailCtxMenu(e.event);
      }
    }]
  };

  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };

  personSkillTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    hasDefaultInfo: true,
    textExpression: `item.Text 
            ? item.Skill.Bezeichnung + ': ' + item.Text 
                : item.Skill.Bezeichnung | zitRestrictLength:50`,
    icon: {
      textExpression: "!item.Skill.TagSymbol ? 'S/H':''",
      faIconExpression: "item.Skill.TagSymbol"
    }
  };

  personGeschaeftspartnerEinsatzTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    hasDefaultInfo: true,
    textExpression: `item.Jahr 
         ? item.Geschaeftspartner.Name1 + ': ' + item.TaetigkeitAbteilung + ' (' + item.Jahr + ')'
         : item.Geschaeftspartner.Name1 + ': ' + item.TaetigkeitAbteilung`,
    icon: {
      text: "BE"
    }
  };

  personSpracheTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    hasDefaultInfo: true,
    textExpression: `item.Sprache.Bezeichnung + " | " + item.Sprachniveau.Bezeichnung`,
    icon: {
      text: "S"
    },
    infoList: [{
      isVisibleExpression: "item.Sprachniveau && item.Sprachniveau.TagSymbol",
      icon: {
        textExpression: "item.Sprachniveau.TagSymbol"
      }
    }]
  };

  personBerufTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    hasDefaultInfo: true,
    textExpression: `item.Beruf.Bezeichnung && item.Erfahrungsjahre && item.ZuletztTaetig
                ? item.Beruf.Bezeichnung + ' | EJ: ' + item.Erfahrungsjahre + ' | ZT: ' + item.ZuletztTaetig
                : item.Erfahrungsjahre
                ? item.Beruf.Bezeichnung + ' | EJ: ' + item.Erfahrungsjahre
                : item.ZuletztTaetig
                ? item.Beruf.Bezeichnung + ' | ZT: ' + item.ZuletztTaetig
                 : item.Beruf.Bezeichnung`,
    icon: {
      text: "B"
    },
    infoList: [{
      isVisibleExpression: "item.IdBerufQualifikation",
      icon: {
        textExpression: "item.BerufQualifikation.TagText ? item.BerufQualifikation.TagText : null",
        faIconExpression: "item.BerufQualifikation.TagSymbol ? item.BerufQualifikation.TagSymbol : null"
      }
    },
    {
      isVisibleExpression: "item.IdAusbildungAbschluss",
      icon: {
        faIconExpression: "item.AusbildungAbschluss.TagSymbol ? item.AusbildungAbschluss.TagSymbol : null"
      }
    }]
  };

  personFuehrerscheinTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    hasDefaultInfo: true,
    textExpression: `item.Fuehrerscheinklasse.Bezeichnung 
            ? item.Fuehrerscheinklasse.Bezeichnung 
                : ' ' | zitRestrictLength:50`,
    icon: {
      textExpression: `item.Fuehrerscheinklasse.Tagtext 
                    ? item.Fuehrerscheinklasse.Tagtext : 'F'`,
      faIconExpression: "item.Fuehrerscheinklasse.TagSymbol ? item.Fuehrerscheinklasse.TagSymbol : null"
    }
  };

  personBerufserfahrungTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    hasDefaultInfo: true,
    textExpression: `item.Bis 
        ? item.Unternehmen + ": " + globalization.format(item.Von, "d") + " - " + globalization.format(item.Bis, "d") 
        : item.Unternehmen + ": " + "seit " + globalization.format(item.Von, "d")`,
    icon: {
      text: "E"
    }
  };

  personAbschliessenCommand: ICommandData = {
    id: "personAbschliessenCommand",
    icon: "fas fa-check",
    sortIndex: 1600,
    execute: () => {
      this.onPersonAbschliessen();
    }
  };

  svNummerValidationRule = {
    type: "custom",
    reevaluate: true,
    isValid: true,
    message: "",
    validationCallback: (e) => {
      let model = this.form.models.data.$m_Person;
      
      const svNr = model.Personendaten.SVNr;
      const ahvNrSchweiz = model.Personendaten.AHVNrSchweiz;
      const ahbNrLiechtenschein = model.Personendaten.AHVNrLiechtenstein;

      if ((svNr && svNr.length > 0) || (ahvNrSchweiz && ahvNrSchweiz.length > 0) || (ahbNrLiechtenschein && ahbNrLiechtenschein.length > 0)) {
        return true;
      }

      this.svNummerValidationRule.message = " Es muss mindestens eine SV- oder AHV Nummer ausgefüllt sein.";
      return false;
    }
  };

  bind(form: FormBase) {
    this.form = form;
    form.models.onSaved.register((args) => {
      if (args && args.model && args.model.id === "$m_Person") {
        const popup = this.form["r_personAbgeschlossenPopup"];
        if (!popup || !popup.instance) {
          return;
        }
        popup.instance.show()
      }
      return Promise.resolve();
    });
  }

  private showDetailCtxMenu(event: Event) {
    const ctxMenu = new ContextMenu();

    this.loadCtxMenuItems(ctxMenu);

    ctxMenu.show(event.target);
  }
  private loadCtxMenuItems(ctxMenu: ContextMenu) {
    if (!ctxMenu) {
      return;
    }
    ctxMenu.items = [{
      text: this.localization.translateOnce("person.details-toolbar-adressen"),
      execute: () => {
        this.showListEditPopup("$m_PersonAdresseEdit", "personAdresseEditPopup", "r_personAdresseListView");
      }
    }, {
      text: this.localization.translateOnce("person.details-toolbar-ausbildungen"),
      execute: () => {
        this.showListEditPopup("$m_PersonAusbildungEdit", "personAusbildungEditPopup", "r_personAusbildungListView");
      }
    }, {
      text: this.localization.translateOnce("person.details-toolbar-bankverbindungen"),
      execute: () => {
        this.showListEditPopup("$m_PersonBankverbindungEdit", "personBankverbindungEditPopup", "r_personBankverbindungListView");
      }
    }, {
      text: this.localization.translateOnce("person.details-toolbar-berufserfahrung"),
      execute: () => {
        this.showListEditPopup("$m_PersonBerufserfahrungEdit", "personBerufserfahrungEditPopup", "r_personBerufserfahrungListView");
      }
    }];

    if (this.showBisherigeEinsaetze()) {
      ctxMenu.items.push({
        text: this.localization.translateOnce("person.details-toolbar-bisherige-einsaetze"),
        execute: () => {
          this.showListEditPopup("$m_PersonGeschaeftspartnerEinsatzEdit", "personGeschaeftspartnerEinsatzEditPopup", "r_personGeschaeftspartnerEinsatzListView");
        }
      });
    }

    ctxMenu.items.push({
      text: this.localization.translateOnce("person.details-toolbar-berufe"),
      execute: () => {
        this.showListEditPopup("$m_PersonBerufEdit", "personBerufEditPopup", "r_personBerufListView");
      }
    }, {
        text: this.localization.translateOnce("person.details-toolbar-dokumente"),
        execute: () => {
          this.customEditPopup.dokumentEdit.show(
            {
              mappings: { "$id": 0 , "$v_objektTypeFullname": "TIP.ZIT.Business.Entities.Objekte.Personen.Person"},
              setValuesOnModelWithKeyIdLoaded: { IdObjekt: this.form.models.data.$m_Person.Id },
              closeCallback: () => {
                const listView: DokumentListView = this.form["r_dokumentElement"];

                listView.dokumentListView.refresh();
              }
            });
        }
      }, {
        text: this.localization.translateOnce("person.details-toolbar-fuehrerscheine"),
        execute: () => {
          this.showListEditPopup("$m_PersonFuehrerscheinEdit", "personFuehrerscheinEditPopup", "r_personFuehrerscheinListView");
        }
      }, {
        text: this.localization.translateOnce("person.details-toolbar-sprachkenntnisse"),
        execute: () => {
          this.showListEditPopup("$m_PersonSpracheEdit", "personSpracheEditPopup", "r_personSpracheListView");
        }
      }, {
        text: this.localization.translateOnce("person.details-toolbar-skills"),
        execute: () => {
          this.showListEditPopup("$m_PersonSkillEdit", "personSkillEditPopup", "r_personSkillListView");
        }
      });

    if (this.form.models.data.$m_Person.SonstigeKontaktdatenVisible) {
      ctxMenu.items.push(
        {
          text: this.localization.translateOnce("person.details-toolbar-kontaktdaten"),
          execute: () => {
            this.showListEditPopup("$m_PersonKontaktEdit", "personKontaktEditPopup", "r_personKontaktdatenListView");
          }
        }
      );
    }
  }
  private showListEditPopup(editDataContext: string, idEditPopup: string, idListView: string) {
    const options = this.getListViewBaseOption(editDataContext, idEditPopup, this.form[idListView]);

    const addCommand = this.defaultCommand.getListAddCommand(this.form, options);
    const event = new MouseEvent("click");
    addCommand.execute({ event: event });
  }
  private getListViewBaseOption(editDataContext: string, idEditPopup: string, options: IListOptionsBase): IListOptionsBase {
    options.editDataContext = editDataContext;
    options.idEditPopup = idEditPopup
    options.listEdits = options.listEdits || [];
    return options;
  }
  private showPersonAusbildungNeuPopup() {
    this.customEditPopup.personAusbildungNeu.show({
      mappings: {
        "$id": 0
      },
      setValuesOnModelWithKeyIdLoaded: {
        _ManuelleAusbildung: true
      },
      closeCallback: (form) => {
        this.form.models.data.$m_Ausbildung.IdAusbildung = form.models.data.$m_AusbildungNeu.Id;
        this.form.models.data.$m_Ausbildung.IdAusbildungAbschluss = form.models.data.$m_AusbildungNeu.IdAusbildungAbschluss;
        form.models.data.$m_AusbildungNeu.IdAusbildungAbschluss = null;
        (<any>form).functions.$f_PersonAusbildungErweitert.idAusbildungAbschluss = null;
      }
    });
  }
  private async onEinstellungenSpeichernClicked() {
    const validationGroup: IdxValidationGroupComponent = this.form["r_personWeitereEinstellungenValidationGroup"];
    const result = validationGroup.instance.validate();
    if (this.form.models.data.$m_Person.Id == null)
      return;

    if (!result.isValid)
      return;

    this.form["personWeitereEinstellungenPopup"].instance.hide();
  }
  private onPersonAbschliessen() {
    const personAbgeschlossenPopup: IdxPopupComponent = this.form["r_personAbgeschlossenPopup"];
    personAbgeschlossenPopup.instance.hide();
    this.goToLatestBewerbung();
  }
  private goToLatestBewerbung() {
    return this.rest.get({
      url: this.rest.getWebApiUrl("ZIT/Objekte/Bewerbung/"),
      getOptions: {
        where: ["Kopf.IdPerson", this.form.models.data.$m_Person.Id],
        columns: ["Id"],
        orderBy: [{ "columnName": "Id", "sortOrder": 1 }]
      }
    }).then((r) => {
      if (!r || !r.length) {
        return;
      }
      this.location.goTo({
        url: `${"Objekte/Bewerbung"}/${r[0].Id}?mode=1`,
        replace: true
      });
    });
  }
  @computedFrom("form.models.data.$m_Person.Personendaten.Geschlecht",
    "form.models.data.$m_Person.Personendaten.TitelVorne",
    "form.models.data.$m_Person.Personendaten.Vorname",
    "form.models.data.$m_Person.Personendaten.Nachname",
    "form.models.data.$m_Person.Personendaten.TitelHinten")
  get personPersonalienHeader(): string {
    const model = this.form.models.data.$m_Person;
    if (!model) {
      return null;
    }

    let anrede = "";
    const vorname = model.Personendaten.Vorname ? model.Personendaten.Vorname : "";
    const nachname = model.Personendaten.Nachname ? model.Personendaten.Nachname : "";
    const titelVorne = model.Personendaten.TitelVorne ? model.Personendaten.TitelVorne : "";
    const titelHinten = model.Personendaten.TitelHinten ? model.Personendaten.TitelHinten : "";

    switch (model.Personendaten.Geschlecht) {
      case Geschlecht.Maennlich: {
        anrede = "Herr";
        break;
      }
      case Geschlecht.Weiblich: {
        anrede = "Frau";
        break;
      }
    }
    return `${anrede} ${titelVorne} ${vorname} ${nachname} ${titelHinten}`
  }

  showBisherigeEinsaetze(): boolean {
    return this.startupService.startupInfo.Mandant.IdMandant === 2;
  }
}
