import {
  autoinject,
  bindable,
  computedFrom,
  createOverrideContext,
  observable,
  OverrideContext,
  processContent
} from "aurelia-framework";
import {
  LocalizationService
} from "../../../base/services/export";
import {
  IListViewOptions
} from "./list-view-options";
import { BindingService } from '../../../base/services/binding-service';
import { IListViewItemsOptions } from './list-view-items-options';
import { ListViewPagingMode } from './list-view-paging-mode';

@autoinject
@processContent(false)
export class ListView {
  private _pageIndex: number;

  constructor(
    private element: Element,
    private binding: BindingService,
    private localization: LocalizationService
  ) { }

  @bindable options: IListViewOptions;

  pagerInfoText: string;
  isLoading: boolean;

  viewClass: string;
  viewStyle: any;

  scrollView: any;

  pages: Pages;

  itemsOptions: IListViewItemsOptions = {
    selectedItems: <any>{},
    hasSelection: false,
    items: <any[]>[],
    totalItems: 0,
    totalPages: 0,
    showLoadNextButton: false,

    itemAlias: null,
    itemClass: null,
    itemTemplate: null,
    itemOverrideContext: null,

    getKeyProperty: this.getKeyProperty.bind(this),
    getItemKey: this.getItemKey.bind(this),

    goToNextPage: this.goToNextPage.bind(this),

    selectByKey: this.selectByKeys.bind(this),
    isSelectedByKey: this.isSelectedByKey.bind(this),

    onItemClick: this.onItemClick.bind(this)
  }

  reloadButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this.localization.translateOnce("list_view.reload"),
    onClick: () => {
      this.goToPage(0, true);
    }
  }

  @computedFrom("options.showReloadButton", "options.pagingMode")
  get showReloadButton(): boolean {
    return !!this.options.showReloadButton
      && this.options.pagingMode == ListViewPagingMode.loadNext;
  }
  @computedFrom("showReloadButton", "options.showPagerInfo")
  get infoVisible(): boolean {
    return this.showReloadButton
      || this.options.showPagerInfo;
  }

  bind(bindingContext: any, overrideContext: OverrideContext): void {
    this.itemsOptions.itemOverrideContext = createOverrideContext(bindingContext, overrideContext);

    this.extractTemplates();

    if (this.options.pageSize) {
      this.options.dataSource.pageSize(this.options.pageSize);
    }

    if (this.options.pagingMode == void (0)) {
      this.options.pagingMode = ListViewPagingMode.loadNext;
    }

    if (this.options.onInitialized) {
      this.options.onInitialized({
        component: this,
        element: this.element
      });
    }
    
    this.options.dataSource.on("changed", this.handleDataSourceChanged.bind(this));
    this.options.dataSource.on("loadingChanged", this.handleDataSourceLoadingChanged.bind(this));

    this.createViewClass();
    this.createItemClass();

    this.goToPage(0);
  }
  unbind() {
    this.options.dataSource.off("changed");
    this.options.dataSource.off("loadingChanged");
  }

  goToNextPage(): void {
    this.goToPage(this._pageIndex + 1)
  }
  goToPage(pageIndex: number, scrollToTop: boolean = null): void {
    if (pageIndex == void (0)) {
      return;
    }

    if (scrollToTop == void (0)) {
      scrollToTop = this.options.pagingMode == ListViewPagingMode.paging;
    }

    this.options.dataSource.pageIndex(pageIndex);
    this._pageIndex = pageIndex;

    this.options.dataSource.load().then(() => {
      if (this.scrollView && this.scrollView.instance) {
        const scrollView: DevExpress.ui.dxScrollView = this.scrollView.instance;
        scrollView.update();
      }
    });

    if (scrollToTop) {
      this.scrollToTop();
    }
  }
  refresh(onlyCurrentPage?: boolean): void {
    if (!onlyCurrentPage) {
      if (this.options.dataSource.pageIndex() > 0) {
        this.goToPage(0, true);
        return;
      }
    }

    this.goToPage(this.options.dataSource.pageIndex(), !onlyCurrentPage);
  }
  scrollToTop(): void {
    if (!this.scrollView || !this.scrollView.instance) {
      return;
    }

    const scrollView: DevExpress.ui.dxScrollView = this.scrollView.instance;
    scrollView.scrollTo(0);
  }
  searchByText(text: string): void {
    this.options.dataSource.searchOperation("contains");
    this.options.dataSource.searchValue(text);
    this.goToPage(0, true);
  }
  setTotalCount() {
    this.itemsOptions.totalItems = this.options.dataSource.totalCount();

    if (this.itemsOptions.totalItems < 0) {
      this.itemsOptions.totalItems = this.options.dataSource.items().length;
    }

    this.itemsOptions.totalPages = Math.ceil(this.itemsOptions.totalItems / this.options.dataSource.pageSize());

    this.itemsOptions.showLoadNextButton = this.options.pagingMode == ListViewPagingMode.loadNext
      && this.itemsOptions.totalItems > this.itemsOptions.items.length;

    if (this.options.pagingMode == ListViewPagingMode.paging) {
      this.calcPageInfo();

      this.pagerInfoText = this.localization.translateOnce(
        "list_view.pager_info",
        [this.itemsOptions.totalItems.toString()]
      );
    } else {
      this.pagerInfoText = this.localization.translateOnce(
        "list_view.pager_info_with_load",
        [this.itemsOptions.totalItems.toString(), this.itemsOptions.items.length.toString()]
      );
    }
  }

  getKeyProperty(): string {
    return this.options.dataSource.key() || "Id";
  }
  getItemKey(item): any {
    const key = this.getKeyProperty();

    if (key) {
      return this.binding.evaluate({
        bindingContext: item,
        overrideContext: null
      }, key);
    } else {
      return item;
    }
  }

  clearSelection(): void {
    this.itemsOptions.selectedItems = {};
    this.itemsOptions.hasSelection = false;
  }
  getSelectedKeys(): any[] {
    const result = [];

    for (let key in this.itemsOptions.selectedItems) {
      const val = this.itemsOptions.selectedItems[key];
      if (val == void(0)) {
        continue;
      }

      result.push(val);
    }

    return result;
  }
  isSelectedByKey(key: any): boolean {
    return !!this.itemsOptions.selectedItems[key];
  }
  selectByKeys(keys: any | any[], set: boolean): void {
    if (!Array.isArray(keys)) {
      keys = [keys];
    }

    if (set && this.options.selectionMode == "single") {
      this.clearSelection();

      if (keys.length > 1) {
        return;
      }
    }

    keys.forEach(key => {
      if (set) {
        this.itemsOptions.selectedItems[key] = key;
      } else {
        this.itemsOptions.selectedItems[key] = undefined;
      }
    });

    this.itemsOptions.hasSelection = this.getSelectedKeys().length > 0;
  }

  replaceDataSource(dataSource: DevExpress.data.DataSource) {
    if (this.options.dataSource) {
      this.options.dataSource.off("changed");
      this.options.dataSource.off("loadingChanged");
    }

    this.options.dataSource = dataSource;
    
    this.options.dataSource.on("changed", this.handleDataSourceChanged.bind(this));
    this.options.dataSource.on("loadingChanged", this.handleDataSourceLoadingChanged.bind(this));

    this.refresh(false);
  }

  onItemClick(item: any, event: Event, rowIndex: number): void {
    if (item.__isGroup) {
      return;
    }
    if (!this.options.onItemClick) {
      return;
    }

    this.options.onItemClick({
      item: item,
      data: item,
      event: event,
      rowIndex: rowIndex,
      hasAllPages: (this.options.pagingMode == ListViewPagingMode.loadNext)
    });
  }

  private createViewClass() {
    const viewClasses = [];
    const viewStyle = {};

    if (this.options.height) {
      viewStyle["height"] = this.options.height;
    }
    if (this.options.useDefaultListItemStyle == void (0) || this.options.useDefaultListItemStyle) {
      viewClasses.push("list-view-item-style-default");
    }
    if (this.options.hoverStateEnabled) {
      viewClasses.push("list-view-item-clickable");
    }
    if (this.options.selectionMode == "multiple") {
      viewClasses.push("list-view-item-selectable");
    }

    this.viewStyle = viewStyle;
    this.viewClass = viewClasses.join(" ");
  }
  private createItemClass() {
    const itemClasses = [];
    itemClasses.push(this.options.itemClass ? this.options.itemClass : "col-xs-12");

    this.itemsOptions.itemClass = itemClasses.join(" ");
  }
  private extractTemplates() {
    const children = Array.from(this.element.children)
    .filter((c) => c.tagName == "CUSTOM-TEMPLATE");;

    for (let i = 0; i < children.length; i++) {
      const item = children[i];
      const name = item.getAttribute("name");
      const alias = item.getAttribute("alias") || "item";

      switch (name) {
        case "item": {
          this.itemsOptions.itemTemplate = item;
          this.itemsOptions.itemAlias = alias;
          break;
        }
        default: {
          break;
        }
      }

      item.parentElement.removeChild(item);
    }
  }
  private handleDataSourceChanged() {
    const items = this.options.dataSource.items();

    this._pageIndex = this.options.dataSource.pageIndex();

    this.insertItems(items, this._pageIndex);
    this.setTotalCount();
  }
  private handleDataSourceLoadingChanged(isLoading) {
    this.isLoading = isLoading;
  }
  private insertItems(items: any[], pageIndex: number) {
    const pageSize = this.options.dataSource.pageSize();
    const itemCount = pageIndex * pageSize;

    if (this.options.pagingMode == ListViewPagingMode.paging) {
      this.itemsOptions.items.splice(0);
    }
    else if (this.itemsOptions.items.length > itemCount) {
      let c = itemCount;

      if (this.options.groupProperty && itemCount > 0) {
        let realItemCount = 0;

        for (let i = 0; i < this.itemsOptions.items.length; i++) {
          if (!this.itemsOptions.items[i].__isGroup) {
            realItemCount++;
          }

          c = i;

          if (realItemCount >= itemCount) {
            if (i > 0 && this.itemsOptions.items[i - 1].__isGroup) {
              c = i - 1;
            }
            break;
          }
        }
      }

      this.itemsOptions.items.splice(c);
    }

    this.checkGroupProperty(items);
    this.itemsOptions.items.push(...items);
  }
  private checkGroupProperty(items: any[]) {
    if (!this.options.groupProperty) {
      return;
    }

    let last = this.getLastGroup();
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      const val = this.binding.evaluate({
        bindingContext: item,
        overrideContext: null
      }, this.options.groupProperty);

      if (val != last) {
        items.splice(i, 0, {
          "__isGroup": true,
          "__groupValue": val
        });

        last = val;
      }
    }
  }
  private getLastGroup(): any {
    if (!this.itemsOptions.items) {
      return null;
    }

    for (let i = this.itemsOptions.items.length - 1; i >= 0; i--) {
      let item = this.itemsOptions.items[i];
      if (!item.__isGroup) {
        continue;
      }

      return item.__groupValue;
    }

    return null;
  }
  private calcPageInfo(): void {
    if (!this.pages) {
      this.pages = {
        currentPage: null,
        pages: [],
        totalPages: 0
      };
    }

    const totalPages = this.itemsOptions.totalPages;
    const currentPage = this._pageIndex;

    if (this.pages && this.pages.totalPages == totalPages && this.pages.currentPage == this._pageIndex) {
      return;
    }

    this.pages.totalPages = totalPages;
    this.pages.currentPage = currentPage;

    const arr: PageInfo[] = [];
    if (!totalPages) {
      this.pages.pages = arr;
      return;
    }

    let arrIndex = [0, currentPage, totalPages - 1];
    if (currentPage == 0) {
      arrIndex.push(1, 2);
    }
    if (currentPage == totalPages - 1) {
      arrIndex.push(totalPages - 3, totalPages - 2);
    }

    arrIndex.push(currentPage - 1, currentPage + 1);
    arrIndex = arrIndex.filter((v, i) => {
      return v >= 0
        && v < totalPages
        && arrIndex.indexOf(v) === i;
    });

    arrIndex.sort((a, b) => {
      return a - b;
    });

    let lastIndex = -1;
    for (let index of arrIndex) {
      if (lastIndex + 1 != index) {
        arr.push({ text: "...", index: null });
      }
      lastIndex = index;

      arr.push({ index: index, text: (index + 1).toString() });
    }

    this.pages.pages = arr;
  }
}

interface PageInfo {
  index: number;
  text: string;
}
interface Pages {
  pages: PageInfo[];
  totalPages: number;
  currentPage: number;
}
