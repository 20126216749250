import { autoinject, computedFrom, bindable } from "aurelia-framework";
import { RouterService } from '../../../framework/forms/export';
import { IMenuItem } from './nav-bar';
import { SucheService } from '../../../zit/services/export';

@autoinject
export class NavBarItem {
  constructor(
    public routerService: RouterService,
    public sucheService: SucheService
  ) {}

  @bindable item: IMenuItem;

  @computedFrom("routerService.currentViewItem.model.routeInfo.url")
  get currentUrl(): string {
    if (!this.routerService.currentViewItem
      || !this.routerService.currentViewItem.model
      || !this.routerService.currentViewItem.model.routeInfo) {
      return null;
    }

    return `route:${this.routerService.currentViewItem.model.routeInfo.url}`;
  }

  @computedFrom("currentUrl", "sucheService.lastObjektFullName")
  get isCurrent(): boolean {
    if (this.currentUrl == this.item.item.Route) {
      return true;
    }

    if (this.currentUrl == "route:Suche") {
      return this.item.item.Route == this.currentUrl.concat("?TypeName=").concat(this.sucheService.lastObjektFullName);
    }

    return false;
  }
}