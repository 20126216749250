import { autoinject, transient } from "aurelia-framework";
import { LocalizationService, RestService } from "../../../framework/base/export";
import { DialogConfirmService, FormBase, ICommandData } from "../../../framework/forms/export";
import { SchnupperterminErstellen } from "../../elements/schnuppertermin-erstellen/schnuppertermin-erstellen";
import { IdxPopupComponent, IdxValidationGroupComponent } from "../../interfaces/export";
import { StartupService } from "../../services/export";
import { NachrichtService } from "./../../services/nachricht-service";

@autoinject
@transient()
export class SchnupperterminAnlageGpUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public startupService: StartupService,
    private _restService: RestService,
    private _dialogConfirmService: DialogConfirmService,
    private _localizationService: LocalizationService,
    private _nachrichtService: NachrichtService
  ) {
    this.isKundeSchule = startupService.startupInfo.Benutzer.CodeBenutzerrolle == "ADMIN"
      || startupService.startupInfo.Benutzer.CodeBenutzerrolle == "KUNDE-SCHULE";
  }

  isKundeSchule: boolean = false;
  model: any;
  form: FormBase;

  aehnlicheGpList: any[] = [];

  anlageGpCommand: ICommandData = {
    id: "anlageGpCommand",
    icon: "fas fa-plus",
    sortIndex: 9000,
    isVisibleExpression: "functions.$f_SchnupperterminAnlageGpUtils.isKundeSchule",
    execute: async() => {
      this.resetModel();
      this.aehnlicheGpList = [];

      const r = await this._restService.get({
        url: this._restService.getApiUrl("ZIT/Stammdaten/AnlageGpStelleninseratRequest"),
        increaseLoadingCount: true
      });

      if (r) {
        if (r.Landvorwahl) {
          this.model.Vorwahl = r.Landvorwahl;
          this.model.ApVorwahl = r.Landvorwahl;
          this.model.ApMobilVorwahl = r.Landvorwahl;
        }
        if (r.IdLand) {
          this.model.IdLand = r.IdLand;
        }
      }

      const popup: IdxPopupComponent = this.form["r_gpAnlage"];
      popup.instance.show();
    }
  };
  saveGpCommand: ICommandData = {
    id: "saveGpCommand",
    icon: "fas fa-save",
    sortIndex: 9000,
    execute: async() => {
      const validationGroup: IdxValidationGroupComponent = this.form["r_gpValidationGroup"];
      const validationResult = validationGroup.instance.validate();

      if (!validationResult || !validationResult.isValid) {
        DevExpress.ui.notify(
          validationResult.brokenRules[0].message,
          "ERROR",
          this.NOTIFY_TIMEOUT
        );

        return;
      }

      const r = await this._restService.post({
        url: this._restService.getApiUrl("ZIT/Stammdaten/AnlageGpStelleninserat"),
        data: this.model,
        increaseLoadingCount: true
      });

      if (r.IdStelleninserat) {
        const dialogResult = await this._dialogConfirmService.show({
          title: this._localizationService.translateOnce("base.question"),
          message: this._localizationService.translateOnce("schnuppertermin.frage-schnuppertermin-erstellen")
        });

        if (dialogResult) {
          const schnupperterminErstellen: SchnupperterminErstellen = this.form["r_schnupperterminErstellenElement"];
          schnupperterminErstellen.show({
            idStelleninserat: r.IdStelleninserat
          });
        }
      }

      const popup: IdxPopupComponent = this.form["r_gpAnlage"];
      popup.instance.hide();
    }
  };

  bind(form: FormBase) {
    this.form = form;
    this.resetModel();

    form.callOnBind(() => {
      const strasseOptions: DevExpress.ui.dxTextBoxOptions = form["r_gpStrasseOptions"];
      strasseOptions.onValueChangedByUser = () => this.checkAehnlicheGeschaeftspartner();

      const plzOptions: DevExpress.ui.dxTextBoxOptions = form["r_gpPlzOptions"];
      plzOptions.onValueChangedByUser = () => this.checkAehnlicheGeschaeftspartner();
    });
  }

  onNachrichtErstellenTeamClick() {
    this._nachrichtService.doNachrichtErstellenFromObjekt([{
      IdObjekt: 0,
      IdObjektRef: 0
    }], "TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson");
  }

  private async checkAehnlicheGeschaeftspartner() {
    this.aehnlicheGpList = [];

    if (!this.model.Strasse || !this.model.Plz) {
      return;
    }

    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Stammdaten/GeschaeftspartnerAehnlich"),
      data: {
        Strasse: this.model.Strasse,
        Plz: this.model.Plz
      }
    });

    if (r && r.ItemList) {
      this.aehnlicheGpList = r.ItemList;
    }
  }
  private resetModel() {
    this.model = {
      ApTeam: "Personalabteilung"
    };
  }
}
