import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, transient } from "../../../../node_modules/aurelia-framework";
import { BrowserService, FileService, LocationService, RestService } from "../../../framework/base/export";
import { FormBase } from "../../../framework/forms/export";
import { DateiAuswaehlen } from "../../elements/datei-auswaehlen/datei-auswaehlen";
import { IDatei } from "../../elements/datei/datei";
import { BenutzerTyp } from "../../enumerations/export";
import { BerechtigungService, CustomEditPopupService, StartupService } from "../../services/export";
import { INachrichtBenutzerInfo, NachrichtAnzeigeTyp, NachrichtService, NachrichtTyp } from "../../services/nachricht-service";
import { LocalizationService } from "./../../../framework/base/services/localization-service";
import { ICommandData } from "./../../../framework/forms/interfaces/command-data";

@autoinject
@transient()
export class NachrichtenListUtils {
  constructor(
    private _nachrichtService: NachrichtService,
    private _localizationService: LocalizationService,
    private _fileService: FileService,
    private _browserService: BrowserService,
    private _startupService: StartupService,
    private _customEditPopupService: CustomEditPopupService,
    private _eventAggregator: EventAggregator,
    private _berechtigungService: BerechtigungService,
    private _restService: RestService,
    private _locationService: LocationService
  ) {}

  form: FormBase;
  formTitel = "Nachrichten";
  benutzerInfo: INachrichtBenutzerInfo;
  benutzerInfoFix: INachrichtBenutzerInfo;
  
  idPerson: number;

  vorlagenCommand: ICommandData = {
    id: "vorlagenCommand",
    sortIndex: 1700,
    title: "nachricht-erstellen.vorlagen",
    tooltip: "nachricht-erstellen.vorlagen",
    isVisible: false,
    execute: (e) => {
      this.form["r_nachrichtVorlage"].showContextMenu(e.event.target);
    }
  };
  gruppeInfoCommand: ICommandData = {
    id: "gruppeInfoCommand",
    icon: "fas fa-info-circle",
    sortIndex: 1800,
    tooltip: "nachricht-list-utils.info_gruppe",
    isVisible: false,
    execute: async () => {
      this._customEditPopupService.nachrichtGruppe.show({
        mappings: {
          "$id": this.benutzerInfoValidated.idBenutzer
        }
      });
    }
  };
  tableauCommand: ICommandData = {
    id: "tableauCommand",
    idCategory: "$start",
    icon: "fas fa-exchange-alt",
    sortIndex: 1900,
    isVisible: false,
    execute: () => {
      if (!this.idPerson) {
        return;
      }
      
      this._locationService.goTo({
        url: `Tableau?view=7&idPerson=${this.idPerson}`,
        currentViewModel: this.form,
        clearStack: true
      });
    }
  };
  fotoCommand: ICommandData = {
    id: "kameraCommand",
    idCategory: "$start",
    icon: "fas fa-camera",
    sortIndex: 2000,
    isVisible: false,
    execute: () => {
      (<DateiAuswaehlen>this.form["r_dateiAuswaehlen"]).onMakePhoto(true, true);
    }
  };
  videoCommand: ICommandData = {
    id: "videoCommand",
    idCategory: "$start",
    icon: "fas fa-video",
    sortIndex: 2001,
    isVisible: false,
    execute: () => {
      (<DateiAuswaehlen>this.form["r_dateiAuswaehlen"]).onMakeVideo(true, true);
    }
  };
  dokumentCommand: ICommandData = {
    id: "dokumentCommand",
    idCategory: "$start",
    icon: "fas fa-paperclip",
    sortIndex: 2002,
    isVisible: false,
    execute: () => {
      (<DateiAuswaehlen>this.form["r_dateiAuswaehlen"]).onOpenFiles(true);
    }
  };
  telefonatCommand: ICommandData = {
    id: "telefonatCommand",
    idCategory: "$start",
    icon: "fas fa-phone-alt",
    tooltip: "nachricht-list-utils.telefonat",
    sortIndex: 2003,
    isVisible: false,
    execute: () => {
      this._nachrichtService.doNachrichtErstellen(this.benutzerInfoValidated, NachrichtTyp.Telefonat);
    }
  };
  notizCommand: ICommandData = {
    id: "notizCommand",
    idCategory: "$start",
    icon: "far fa-sticky-note",
    tooltip: "nachricht-list-utils.notiz",
    sortIndex: 2004,
    isVisible: false,
    execute: () => {
      this._nachrichtService.doNachrichtErstellen(this.benutzerInfoValidated, NachrichtTyp.Notiz);
    }
  };
  neueNachrichtErstellenCommand: ICommandData = {
    id: "neueNachrichtErstellenCommand",
    icon: "far fa-comment-dots",
    sortIndex: 9999,
    title: this._browserService.isMobile ? null : "nachricht-list-utils.neue_nachricht",
    tooltip: "nachricht-list-utils.neue_nachricht",
    isVisible: false,
    execute: async () => {
      if(!this.benutzerInfoValidated) {
        DevExpress.ui.notify(this._localizationService.translateOnce("nachricht-list-utils.kein-empfaenger-ausgewaehlt"), "ERROR", 3000);
        return;
      }
      
      this._nachrichtService.doNachrichtErstellen(this.benutzerInfoValidated);
    }
  };
  nachrichtUngelesenCommand: ICommandData = {
    id: "nachrichtUngelesenCommand",
    icon: "fas fa-low-vision",
    sortIndex: 10000,
    title: this._browserService.isMobile ? null : "nachricht-list-utils.nachricht_ungelesen",
    tooltip: "nachricht-list-utils.nachricht_ungelesen",
    isVisible: false,
    execute: async () => {
      const args = {
        isHandled: false
      }
      
      this._eventAggregator.publish("nachricht:set-unglesen", args);
      
      if (!args.isHandled) {
        DevExpress.ui.notify("Es wurde keine Nachricht gefunden, die auf ungelesen gesetzt werden konnte", "ERROR", 3000);
      }
    }
  };

  get benutzerInfoValidated() {
    return this.benutzerInfoFix || this.benutzerInfo;
  }

  bind(form: FormBase) {
    this.form = form;

    const hasBenutzerInfo = this.form.viewItemInfo
       && this.form.viewItemInfo.routeInfo
       && this.form.viewItemInfo.routeInfo.customOptions
       && this.form.viewItemInfo.routeInfo.customOptions.benutzerInfo;

    if (hasBenutzerInfo) {
      this.benutzerInfoFix = this.form.viewItemInfo.routeInfo.customOptions.benutzerInfo;
      this.formTitel = this.benutzerInfoFix.name;
    }
    
    this.vorlagenCommand.isVisible = !hasBenutzerInfo
      && this._startupService.startupInfo.Benutzer.Benutzertyp != BenutzerTyp.NatuerlichePerson;
  }

  onBenutzerClick(e) {
    this.benutzerInfo = e.detail.benutzerInfo;

    this.neueNachrichtErstellenCommand.isVisible = !!this.benutzerInfo;
    this.nachrichtUngelesenCommand.isVisible = !!this.benutzerInfo;
    this.fotoCommand.isVisible = !!this.benutzerInfo;
    this.videoCommand.isVisible = !!this.benutzerInfo && this._browserService.isMobile;
    this.dokumentCommand.isVisible = !!this.benutzerInfo && !this._browserService.isMobile;
    
    this.gruppeInfoCommand.isVisible = !!this.benutzerInfo 
      && this.benutzerInfo.anzeigeTyp == NachrichtAnzeigeTyp.Gruppe
      && (this._startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.Administrator || this.benutzerInfo.isGruppeAdmin);

    this.telefonatCommand.isVisible = !!this.benutzerInfo
      && this.benutzerInfo.anzeigeTyp == NachrichtAnzeigeTyp.Benutzer
      && this._startupService.startupInfo.Benutzer.HasNachrichtInterneNotiz
      && !this._browserService.isMobile;

    this.notizCommand.isVisible = !!this.benutzerInfo
      && this.benutzerInfo.anzeigeTyp == NachrichtAnzeigeTyp.Benutzer
      && this._startupService.startupInfo.Benutzer.HasNachrichtInterneNotiz
      && !this._browserService.isMobile;

    this.vorlagenCommand.isVisible = false;
    
    this.updateTableauCommand(this.benutzerInfo);
  }
  onBenutzerCleared(e) {
    this.benutzerInfo = null;
    this.neueNachrichtErstellenCommand.isVisible = false;
    this.fotoCommand.isVisible = false;
    this.videoCommand.isVisible = false;
    this.dokumentCommand.isVisible = false;
    this.gruppeInfoCommand.isVisible = false;
    this.telefonatCommand.isVisible = false;
    this.notizCommand.isVisible = false;
    this.tableauCommand.isVisible = false;
  }
  async onDateiAusgewaehlt(e: CustomEvent) {
    const dateiList: IDatei[] = [];

    for (let file of <File[]>e.detail.files) {
      const dmsLink = await this._fileService.upload(file);
      if (!dmsLink) {
        return;
      }

      dateiList.push({ DMSLink: dmsLink, Dateiname: file.name });
    }

    this._nachrichtService.doNachrichtErstellen(
      this.benutzerInfo,
      NachrichtTyp.Nachricht,
      dateiList);
  }
  
  private async updateTableauCommand(benutzerInfo: INachrichtBenutzerInfo) {
    this.tableauCommand.isVisible = false;
    this.idPerson = null;
    
    const codeBenutzerrolle = this._startupService.startupInfo.Benutzer.CodeBenutzerrolle;
    const isPremium = [
      "ADMIN",
      "KUNDE",
      "VERTRIEB"
    ].indexOf(codeBenutzerrolle) >= 0;
    
    const isVisible = !!benutzerInfo
      && benutzerInfo.anzeigeTyp == NachrichtAnzeigeTyp.Benutzer
      && isPremium
      && !this._browserService.isMobile
      && this._berechtigungService.hasBerechtigungTableau()
      && this._berechtigungService.hasBerechtigungEvent();
    
    if (!isVisible) {
      return;
    }
    
    const r = await this._restService.get({
      url: this._restService.getApiUrl(`ZIT/Berechtigung/Person?idBenutzer=${this.benutzerInfo.idBenutzer}`)
    });
    
    if (!r || !r.IdPerson) {
      return;
    }
    
    this.idPerson = r.IdPerson;
    this.tableauCommand.isVisible = true;
  }
}
