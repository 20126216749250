import { FileService } from './../../framework/base/services/file-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import {
  autoinject,
  observable
} from "aurelia-framework";
import { StyleService, BrowserService } from '../../framework/base/export';
import { IStartupInfo } from '../../zit/interfaces/export';

@autoinject
export class LayoutService {
  private _resizeContainerTimeout: any;

  constructor(
    private eventAggregator: EventAggregator,
    private file: FileService,
    private style: StyleService,
    private browserService: BrowserService
  ) { 
    if (browserService.isIOS) {
      this.eventAggregator.subscribe("window:resize", () => {
        this.resizeContainer();
      });
    }
  }

  @observable isSidebarCollapsed = true;
  themeColor = "#396394";
  logoUrl: string;
  hintergrundUrl: string;

  activateTheme() {
    this.registerStartupInfoLoaded();

    this.style.addStyles("custom-container", [{
      name: ".t--sidebar .t--sidebar-item:hover",
      properties: [{
        propertyName: "background-color",
        value: this.themeColor
      }]
    }]);
  }
  isSidebarCollapsedChanged() {
    setTimeout(() => {
      this.eventAggregator.publish("window:resize", {});
    }, 400);
  }
  resizeContainer() {
    if (!this.browserService.isIOS) {
      return;
    }

    if (this._resizeContainerTimeout) {
      clearTimeout(this._resizeContainerTimeout);
      this._resizeContainerTimeout = null;
    }

    this._resizeContainerTimeout = setTimeout(() => {
      const container: any = document.querySelector("container");
      if (!container) {
        return;
      }

      container.style.minHeight = window.innerHeight + "px";
      window.scrollTo(0, 0);
    }, 100);
  }
  
  private registerStartupInfoLoaded() {
    this.eventAggregator.subscribe("startup-info:loaded", (r) => {
      const startupInfo: IStartupInfo = r.startupInfo;

      this.themeColor = startupInfo.Skin.Farbe || this.themeColor;
      this.logoUrl = this.file.getInlineUrl(startupInfo.Skin.DMSLinkLogoKlein);
      this.hintergrundUrl = this.file.getInlineUrl(startupInfo.Skin.DMSLinkHintergrundbild);

      this.style.addStyles("zit--layout-theme-color", [
        {
          name: ".z--nav-bar-item:hover .z--nav-bar-item-border-bottom, .z--nav-bar-item-selected .z--nav-bar-item-border-bottom",
          properties: [{
            propertyName: "background-color",
            value: this.themeColor
          }]
        }, {
          name: ".z--button:not(.z--disabled):not(.z--button-active):hover",
          properties: [{
            propertyName: "border-color",
            value: this.themeColor
          }, {
            propertyName: "color",
            value: this.themeColor
          }]
        }, {
          name: "body h3, body .z--toolbar .z--toolbar-title, body .z--toolbar .z--toolbar-icon, .z--subheadline-titel, .z--theme-color",
          properties: [{
            propertyName: "color",
            value: this.themeColor
          }]
        }, {
          name: "body .z--timeline:before",
          properties: [{
            propertyName: "background-color",
            value: this.themeColor
          }]
        }, {
          name: "body .z--timeline li .z--timeline-item-icon",
          properties: [{
            propertyName: "border-color",
            value: this.themeColor
          }]
        }, {
          name: "body .z--timeline li .z--timeline-item-icon i",
          properties: [{
            propertyName: "color",
            value: this.themeColor
          }]
        },
        {
          name: "body .z--item-border-light > div",
          properties: [
            {
              propertyName: "border",
              value: "1px solid" + this.themeColor
            }]
        },
        {
          name: "body .dx-gallery .dx-gallery-nav-button-prev.dx-state-hover:after, body .dx-gallery .dx-gallery-nav-button-next.dx-state-hover:after, body .dx-gallery .dx-gallery-nav-button-prev.dx-state-active:after, body .dx-gallery .dx-gallery-nav-button-next.dx-state-active:after",
          properties: [
            {
              propertyName: "background-color",
              value: this.themeColor
            }]
        },
        {
          name: "body .dx-slider-range.dx-slider-range-visible",
          properties: [
            {
              propertyName: "background-color",
              value: this.themeColor
            }, 
            {
              propertyName: "border-color",
              value: this.themeColor
            }]
        },
        {
          name: "body .dx-slider-handle, body .dx-slider .dx-state-active.dx-slider-handle, body .dx-slider .dx-state-hover.dx-slider-handle:not(.dx-state-active), body .dx-slider .dx-state-hover.dx-slider-handle:not(.dx-state-active), body .dx-slider .dx-state-focused.dx-slider-handle:not(.dx-state-active)",
          properties: [
            {
              propertyName: "background-color",
              value: this.themeColor
            }]
        }
      ]);

      this.style.addStyles("zit-button-active", [
        {
          name: ".z--button-active",
          properties: [{
            propertyName: "background-color",
            value: this.themeColor
          }, {
            propertyName: "border-color",
            value: this.themeColor
          }],
        }, {
          name: ".z--button-active:hover",
          properties: [{
            propertyName: "border-color",
            value: this.themeColor
          }]
        }
      ]);

      this.style.addStyles("zit-tag", [
        {
          name: ".z--tag .z--tag-icon.z--clickable:hover, .z--clickable.z--tag-icon-delete:hover",
          properties: [{
            propertyName: "background-color",
            value: this.themeColor + "!important" //zitTag InfoList auch onHover einfarben
          }]
        }
      ])
    });
  }
}
