import { BrowserService } from './../../../framework/base/services/browser-service';
import { IContextMenuItem } from './../../../framework/forms/interfaces/context-menu-item';
import { ContextMenu } from './../../../framework/forms/classes/context-menu';
import { FileService } from './../../../framework/base/services/file-service';
import { IErklaerungsvideo } from './../../interfaces/startup-info';
import { autoinject, Scope, OverrideContext, computedFrom } from 'aurelia-framework';
import { IdxPopupComponent } from '../../interfaces/export';
import { SimpleWidgetCreatorService } from '../../../framework/forms/export';
import { ScopeContainer } from '../../../framework/base/export';
import { ErklaerungsvideoInhaltArtEnum } from '../../enumerations/erklaerungsvideo-inhalt-art-enum';
import { ErklaervideoService } from '../../services/erklaervideo-service';

@autoinject
export class Erklaerungsvideo {
  constructor(
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private erklaervideo: ErklaervideoService,
    private file: FileService,
    private browserService: BrowserService
  ) {
    this.erklaervideo.element = this;
  }
  scope: Scope;
  scopeContainer: ScopeContainer;
  vimeoHtml: string;
  popupTitel: string;
  isMobil: boolean;

  erklaerungsvideoPopup: IdxPopupComponent;
  erklaerungsvideoPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    width: "auto"
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.isMobil = this.browserService.isMobile;

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "erklaerungsvideoPopupToolbar",
      caption: "erklaerungsvideo.popup-titel",
      options: this.erklaerungsvideoPopupOptions,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  public show(moduleId: string, target: any) {
    const erklaervideoList = this.erklaervideo.getContextMenuItems(moduleId);
    if (!erklaervideoList || erklaervideoList.length == 0) {
      return;
    }

    const menuItemList: IContextMenuItem[] = [];

    erklaervideoList.forEach(element => {
      menuItemList.push({
        text: this.erklaerungsvideoElementBezeichnung(element),
        icon: this.erklaerungsvideoElementIcon(element),
        execute: this.erklaerungsvideoAction(element)
      });
    });

    const ctx = new ContextMenu();
    ctx.items = menuItemList;
    ctx.show(target);
  }

  public erklaerungsvideoElementBezeichnung(erklaerungsvideo: IErklaerungsvideo): string {
    switch (erklaerungsvideo.InhaltArt) {
      case ErklaerungsvideoInhaltArtEnum.File:
      case ErklaerungsvideoInhaltArtEnum.Url:
        return `weitere Informationen: ${erklaerungsvideo.Bezeichnung}`;
      case ErklaerungsvideoInhaltArtEnum.VimeoEinbettungslink:
        return `Erklärvideo: ${erklaerungsvideo.Bezeichnung}`;
    }
  }

  public erklaerungsvideoElementIcon(erklaerungsvideo: IErklaerungsvideo): string {
    switch (erklaerungsvideo.InhaltArt) {
      case ErklaerungsvideoInhaltArtEnum.File:
        return "fas fa-paperclip";
      case ErklaerungsvideoInhaltArtEnum.Url:
        return "fas fa-external-link-alt";
      case ErklaerungsvideoInhaltArtEnum.VimeoEinbettungslink:
        return "fas fa-video";
    }
  }

  public erklaerungsvideoAction(erklaerungsvideo: IErklaerungsvideo): any {
    switch (erklaerungsvideo.InhaltArt) {
      case ErklaerungsvideoInhaltArtEnum.File:
        return () => this.downloadFile(erklaerungsvideo);
      case ErklaerungsvideoInhaltArtEnum.Url:
        return () => this.openUrl(erklaerungsvideo);
      case ErklaerungsvideoInhaltArtEnum.VimeoEinbettungslink:
        return () => this.showPopup(erklaerungsvideo);
    }
  }

  private openUrl(erklaerungsvideo: IErklaerungsvideo): void {
    if (erklaerungsvideo.InhaltArt != ErklaerungsvideoInhaltArtEnum.Url) {
      return;
    }

    let url = erklaerungsvideo.Content;
    if (!url || url.length == 0) {
      return;
    }

    if (!url.match(/^https?:\/\//i)) {
      url = `http://${url}`;
    }
    window.open(url, "_blank");
  }

  private downloadFile(erklaerungsvideo: IErklaerungsvideo): void {
    if (erklaerungsvideo.InhaltArt != ErklaerungsvideoInhaltArtEnum.File) {
      return;
    }

    if (!erklaerungsvideo.Content || erklaerungsvideo.Content.length == 0) {
      return;
    }

    this.file.download(erklaerungsvideo.Content);
  }

  private showPopup(erklaerungsvideo: IErklaerungsvideo): void {
    if (erklaerungsvideo.InhaltArt != ErklaerungsvideoInhaltArtEnum.VimeoEinbettungslink) {
      return;
    }

    if (!erklaerungsvideo.Content || erklaerungsvideo.Content.length == 0) {
      return;
    }

    this.popupTitel = erklaerungsvideo.Bezeichnung;
    this.vimeoHtml = erklaerungsvideo.Content;
    this.erklaerungsvideoPopup.instance.show();
  }
}
