import * as fwx from "../../../../framework/forms/form-export";
import {
    StelleninseratKopfElementChannelEditUtils
} from "./stelleninserat-kopf-element-channel-edit-utils";

@fwx.autoinject
export class StelleninseratKopfElementChannelEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StelleninseratKopfElementZuChannel: StelleninseratKopfElementChannelEditUtils) {
        super(element, formBaseImport);
        this.id = "stelleninserat-kopf-element-channel-edit";
        this.title = "stelleninserat-kopf-element-channel-edit.stelleninserat-kopf-element-channel-edit_caption";
        this.addModel({
            "id": "$m_StelleninseratKopfElementZuChannel",
            "webApiAction": "ZIT/Objekte/StelleninseratKopfElementZuChannel",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "functions.$f_StelleninseratKopfElementZuChannel.canModifyChannel",
            "allowSave": "functions.$f_StelleninseratKopfElementZuChannel.canModifyChannel",
            "allowDelete": "functions.$f_StelleninseratKopfElementZuChannel.canModifyChannel",
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratBewerbungstyp",
            "webApiAction": "ZIT/Stammdaten/StelleninseratBewerbungstyp",
            "keyProperty": "Id",
            "filters": []
        });
        this.addFunction("$f_StelleninseratKopfElementZuChannel", $f_StelleninseratKopfElementZuChannel, "functions.$f_StelleninseratKopfElementZuChannel");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "channel",
            "filter": [
                ["GueltigVon", "<=", "@Today()"],
                ["GueltigBis", ">=", "@Today()"]
            ],
            "filters": [{
                "webApiCustomKey": "IdStelleninseratKopfElement",
                "webApiCustomValue": "models.data.$m_StelleninseratKopfElementZuChannel.IdStelleninseratKopfElement"
            }, {
                "webApiCustomKey": "IdStelleninseratKopfElement",
                "webApiCustomValue": "models.data.$m_StelleninseratKopfElementZuChannel.IdStelleninseratKopfElement"
            }],
            "caption": "stelleninserat-kopf-element-channel-edit.r_stelleninseratkopfelementzuchannelchannel_caption",
            "binding": {
                "dataContext": "$m_StelleninseratKopfElementZuChannel",
                "bindTo": "IdChannel",
                "bindToFQ": "models.data.$m_StelleninseratKopfElementZuChannel.IdChannel"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_stelleninseratKopfElementZuChannelChannel",
            "options": {
                "optionsName": "r_stelleninseratKopfElementZuChannelChannelOptions",
                "optionsNameFQ": "r_stelleninseratKopfElementZuChannelChannelOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "stelleninseratBewerbungstyp",
            "customs": [{
                "key": "IdStelleninseratKopfElement",
                "value": "models.data.$m_StelleninseratKopfElementZuChannel.IdStelleninseratKopfElement"
            }, {
                "key": "CheckBenutzerrolle",
                "value": "true"
            }],
            "filters": [],
            "caption": "stelleninserat-kopf-element-channel-edit.r_stelleninseratkopfelementzuchannelstelleninseratbewerbungstyp_caption",
            "binding": {
                "dataContext": "$m_StelleninseratKopfElementZuChannel",
                "bindTo": "IdStelleninseratBewerbungstyp",
                "bindToFQ": "models.data.$m_StelleninseratKopfElementZuChannel.IdStelleninseratBewerbungstyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_stelleninseratKopfElementZuChannelStelleninseratBewerbungstyp",
            "options": {
                "optionsName": "r_stelleninseratKopfElementZuChannelStelleninseratBewerbungstypOptions",
                "optionsNameFQ": "r_stelleninseratKopfElementZuChannelStelleninseratBewerbungstypOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "stelleninserat-kopf-element-channel-edit.r_stelleninseratkopfelementzuchannelgueltigvon_caption",
            "binding": {
                "dataContext": "$m_StelleninseratKopfElementZuChannel",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_StelleninseratKopfElementZuChannel.GueltigVon"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_stelleninseratKopfElementZuChannelGueltigVon",
            "options": {
                "optionsName": "r_stelleninseratKopfElementZuChannelGueltigVonOptions",
                "optionsNameFQ": "r_stelleninseratKopfElementZuChannelGueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "stelleninserat-kopf-element-channel-edit.r_stelleninseratkopfelementzuchannelgueltigbis_caption",
            "binding": {
                "dataContext": "$m_StelleninseratKopfElementZuChannel",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_StelleninseratKopfElementZuChannel.GueltigBis"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_stelleninseratKopfElementZuChannelGueltigBis",
            "options": {
                "optionsName": "r_stelleninseratKopfElementZuChannelGueltigBisOptions",
                "optionsNameFQ": "r_stelleninseratKopfElementZuChannelGueltigBisOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "veroeffentlichungTyp",
            "filters": [],
            "caption": "stelleninserat-kopf-element-channel-edit.r_stelleninseratkopfelementzuchannelveroeffentlichungtyp_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_StelleninseratKopfElementZuChannel",
                "bindTo": "VeroeffentlichungTyp",
                "bindToFQ": "models.data.$m_StelleninseratKopfElementZuChannel.VeroeffentlichungTyp"
            },
            "validationRules": [],
            "id": "r_stelleninseratKopfElementZuChannelVeroeffentlichungTyp",
            "options": {
                "optionsName": "r_stelleninseratKopfElementZuChannelVeroeffentlichungTypOptions",
                "optionsNameFQ": "r_stelleninseratKopfElementZuChannelVeroeffentlichungTypOptions"
            }
        });
        super.onConstructionFinished();
    }
}