import * as fwx from "../../../../framework/forms/form-export";
import {
    TerminEmpfEinschaetzungEditUtils
} from "./termin-empf-einschaetzung-edit-utils";

@fwx.autoinject
export class TerminEmpfEinschaetzungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_TerminEmpfEinschaetzungEditUtils: TerminEmpfEinschaetzungEditUtils) {
        super(element, formBaseImport);
        this.id = "termin-empf-einschaetzung-edit";
        this.title = "termin-empf-einschaetzung-edit.termin-empf-einschaetzung-edit_caption";
        this.addModel({
            "id": "$m_TerminEmpfEinsch",
            "webApiAction": "ZIT/Objekte/TerminEmpfEinschElement",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowSave": "!models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "allowDelete": "!models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "filters": [{
                "webApiCustomKey": "IdTermin",
                "webApiCustomValue": "variables.data.$v_idTermin"
            }]
        });
        this.addVariable({
            "id": "$v_idTermin"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_TerminEmpfEinschaetzungEditUtils.pdfGenerierenCommand",
                "bindToFQ": "functions.$f_TerminEmpfEinschaetzungEditUtils.pdfGenerierenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_TerminEmpfEinschaetzungEditUtils.pdfAnzeigenCommand",
                "bindToFQ": "functions.$f_TerminEmpfEinschaetzungEditUtils.pdfAnzeigenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_TerminEmpfEinschaetzungEditUtils.pdfLoeschenCommand",
                "bindToFQ": "functions.$f_TerminEmpfEinschaetzungEditUtils.pdfLoeschenCommand"
            }
        });
        this.addFunction("$f_TerminEmpfEinschaetzungEditUtils", $f_TerminEmpfEinschaetzungEditUtils, "functions.$f_TerminEmpfEinschaetzungEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 10.0,
            "format": "f0",
            "caption": "termin-empf-einschaetzung-edit.r_verhaltentrainingse_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_verhaltentrainingse_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "VerhaltenTrainingSE",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.VerhaltenTrainingSE"
            },
            "validationRules": [],
            "id": "r_verhaltenTrainingSE",
            "options": {
                "optionsName": "r_verhaltenTrainingSEOptions",
                "optionsNameFQ": "r_verhaltenTrainingSEOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 10.0,
            "format": "f0",
            "caption": "termin-empf-einschaetzung-edit.r_verhaltentrainingfe_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_verhaltentrainingfe_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "VerhaltenTrainingFE",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.VerhaltenTrainingFE"
            },
            "validationRules": [],
            "id": "r_verhaltenTrainingFE",
            "options": {
                "optionsName": "r_verhaltenTrainingFEOptions",
                "optionsNameFQ": "r_verhaltenTrainingFEOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-empf-einschaetzung-edit.r_verhaltentrainingb_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_verhaltentrainingb_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "VerhaltenTrainingB",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.VerhaltenTrainingB"
            },
            "validationRules": [],
            "id": "r_verhaltenTrainingB",
            "options": {
                "optionsName": "r_verhaltenTrainingBOptions",
                "optionsNameFQ": "r_verhaltenTrainingBOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-empf-einschaetzung-edit.r_verhaltentrainingm_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_verhaltentrainingm_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "VerhaltenTrainingM",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.VerhaltenTrainingM"
            },
            "validationRules": [],
            "id": "r_verhaltenTrainingM",
            "options": {
                "optionsName": "r_verhaltenTrainingMOptions",
                "optionsNameFQ": "r_verhaltenTrainingMOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 10.0,
            "format": "f0",
            "caption": "termin-empf-einschaetzung-edit.r_verhaltenarbeitense_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_verhaltenarbeitense_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "VerhaltenArbeitenSE",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.VerhaltenArbeitenSE"
            },
            "validationRules": [],
            "id": "r_verhaltenArbeitenSE",
            "options": {
                "optionsName": "r_verhaltenArbeitenSEOptions",
                "optionsNameFQ": "r_verhaltenArbeitenSEOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 10.0,
            "format": "f0",
            "caption": "termin-empf-einschaetzung-edit.r_verhaltenarbeitenfe_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_verhaltenarbeitenfe_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "VerhaltenArbeitenFE",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.VerhaltenArbeitenFE"
            },
            "validationRules": [],
            "id": "r_verhaltenArbeitenFE",
            "options": {
                "optionsName": "r_verhaltenArbeitenFEOptions",
                "optionsNameFQ": "r_verhaltenArbeitenFEOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-empf-einschaetzung-edit.r_verhaltenarbeitenb_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_verhaltenarbeitenb_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "VerhaltenArbeitenB",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.VerhaltenArbeitenB"
            },
            "validationRules": [],
            "id": "r_verhaltenArbeitenB",
            "options": {
                "optionsName": "r_verhaltenArbeitenBOptions",
                "optionsNameFQ": "r_verhaltenArbeitenBOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-empf-einschaetzung-edit.r_verhaltenarbeitenm_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_verhaltenarbeitenm_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "VerhaltenArbeitenM",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.VerhaltenArbeitenM"
            },
            "validationRules": [],
            "id": "r_verhaltenArbeitenM",
            "options": {
                "optionsName": "r_verhaltenArbeitenMOptions",
                "optionsNameFQ": "r_verhaltenArbeitenMOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 10.0,
            "format": "f0",
            "caption": "termin-empf-einschaetzung-edit.r_verhaltenumgangkollegense_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_verhaltenumgangkollegense_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "VerhaltenUmgangKollegenSE",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.VerhaltenUmgangKollegenSE"
            },
            "validationRules": [],
            "id": "r_verhaltenUmgangKollegenSE",
            "options": {
                "optionsName": "r_verhaltenUmgangKollegenSEOptions",
                "optionsNameFQ": "r_verhaltenUmgangKollegenSEOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 10.0,
            "format": "f0",
            "caption": "termin-empf-einschaetzung-edit.r_verhaltenumgangkollegenfe_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_verhaltenumgangkollegenfe_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "VerhaltenUmgangKollegenFE",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.VerhaltenUmgangKollegenFE"
            },
            "validationRules": [],
            "id": "r_verhaltenUmgangKollegenFE",
            "options": {
                "optionsName": "r_verhaltenUmgangKollegenFEOptions",
                "optionsNameFQ": "r_verhaltenUmgangKollegenFEOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-empf-einschaetzung-edit.r_verhaltenumgangkollegenb_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_verhaltenumgangkollegenb_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "VerhaltenUmgangKollegenB",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.VerhaltenUmgangKollegenB"
            },
            "validationRules": [],
            "id": "r_verhaltenUmgangKollegenB",
            "options": {
                "optionsName": "r_verhaltenUmgangKollegenBOptions",
                "optionsNameFQ": "r_verhaltenUmgangKollegenBOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-empf-einschaetzung-edit.r_verhaltenumgangkollegenm_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_verhaltenumgangkollegenm_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "VerhaltenUmgangKollegenM",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.VerhaltenUmgangKollegenM"
            },
            "validationRules": [],
            "id": "r_verhaltenUmgangKollegenM",
            "options": {
                "optionsName": "r_verhaltenUmgangKollegenMOptions",
                "optionsNameFQ": "r_verhaltenUmgangKollegenMOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 10.0,
            "format": "f0",
            "caption": "termin-empf-einschaetzung-edit.r_umgangverhaltensregelnse_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_umgangverhaltensregelnse_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "UmgangVerhaltensregelnSE",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.UmgangVerhaltensregelnSE"
            },
            "validationRules": [],
            "id": "r_umgangVerhaltensregelnSE",
            "options": {
                "optionsName": "r_umgangVerhaltensregelnSEOptions",
                "optionsNameFQ": "r_umgangVerhaltensregelnSEOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 10.0,
            "format": "f0",
            "caption": "termin-empf-einschaetzung-edit.r_umgangverhaltensregelnfe_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_umgangverhaltensregelnfe_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "UmgangVerhaltensregelnFE",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.UmgangVerhaltensregelnFE"
            },
            "validationRules": [],
            "id": "r_umgangVerhaltensregelnFE",
            "options": {
                "optionsName": "r_umgangVerhaltensregelnFEOptions",
                "optionsNameFQ": "r_umgangVerhaltensregelnFEOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-empf-einschaetzung-edit.r_umgangverhaltensregelnb_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_umgangverhaltensregelnb_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "UmgangVerhaltensregelnB",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.UmgangVerhaltensregelnB"
            },
            "validationRules": [],
            "id": "r_umgangVerhaltensregelnB",
            "options": {
                "optionsName": "r_umgangVerhaltensregelnBOptions",
                "optionsNameFQ": "r_umgangVerhaltensregelnBOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-empf-einschaetzung-edit.r_umgangverhaltensregelnm_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "placeholder": "termin-empf-einschaetzung-edit.r_umgangverhaltensregelnm_placeholder",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "UmgangVerhaltensregelnM",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.UmgangVerhaltensregelnM"
            },
            "validationRules": [],
            "id": "r_umgangVerhaltensregelnM",
            "options": {
                "optionsName": "r_umgangVerhaltensregelnMOptions",
                "optionsNameFQ": "r_umgangVerhaltensregelnMOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "caption": "termin-empf-einschaetzung-edit.r_gesamteintragamlc_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "GesamteintragAmLC",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.GesamteintragAmLC"
            },
            "validationRules": [],
            "id": "r_gesamteintragAmLC",
            "options": {
                "optionsName": "r_gesamteintragAmLCOptions",
                "optionsNameFQ": "r_gesamteintragAmLCOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "caption": "termin-empf-einschaetzung-edit.r_tippsfuerausbildner_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "TippsFuerAusbildner",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.TippsFuerAusbildner"
            },
            "validationRules": [],
            "id": "r_tippsFuerAusbildner",
            "options": {
                "optionsName": "r_tippsFuerAusbildnerOptions",
                "optionsNameFQ": "r_tippsFuerAusbildnerOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-empf-einschaetzung-edit.r_einschstaerken_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "EinschStaerken",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.EinschStaerken"
            },
            "validationRules": [],
            "id": "r_einschStaerken",
            "options": {
                "optionsName": "r_einschStaerkenOptions",
                "optionsNameFQ": "r_einschStaerkenOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-empf-einschaetzung-edit.r_einschweiterentw_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "EinschWeiterentw",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.EinschWeiterentw"
            },
            "validationRules": [],
            "id": "r_einschWeiterentw",
            "options": {
                "optionsName": "r_einschWeiterentwOptions",
                "optionsNameFQ": "r_einschWeiterentwOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 1000,
            "caption": "termin-empf-einschaetzung-edit.r_einschdafuermachen_caption",
            "isReadOnlyExpression": "models.data.$m_TerminEmpfEinsch.IsFreigegeben",
            "binding": {
                "dataContext": "$m_TerminEmpfEinsch",
                "bindTo": "EinschDafuerMachen",
                "bindToFQ": "models.data.$m_TerminEmpfEinsch.EinschDafuerMachen"
            },
            "validationRules": [],
            "id": "r_einschDafuerMachen",
            "options": {
                "optionsName": "r_einschDafuerMachenOptions",
                "optionsNameFQ": "r_einschDafuerMachenOptions"
            }
        });
        super.onConstructionFinished();
    }
}