import { autoinject } from "aurelia-framework";

import * as Quill from "quill";
import { EventAggregator } from "aurelia-event-aggregator";
import { HtmlEditorExtension } from '../../zit/elements/html-editor-extension/html-editor-extension';
import { FormBase } from '../../framework/forms/export';

var BlockEmbed = Quill.import("blots/block/embed");

class Video extends BlockEmbed {
  static create(value: IVideoOptions) {
    const node = super.create(value);

    node.setAttribute("src", value.src);
    node.setAttribute("frameborder", "0");
    node.setAttribute("allowfullscreen", "");
    node.setAttribute("allow", "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture");
    node.setAttribute("width", value.width);
    node.setAttribute("height", value.height);
    return node;
  }

  static value(node): IVideoOptions {
    return {
      src: node.getAttribute("src"),
      height: node.getAttribute("height"),
      width: node.getAttribute("width")
    };
  }

  static blotName = "video";
  static tagName = "iframe";
  static className = "z--video";
}

Quill.register(Video, true);

@autoinject
export class HtmlEditorExtensionService {
  constructor(
    private _eventAggregator: EventAggregator
  ) {
    this.changeQuillBlockModifier();
    this.registerHtmlEditorToolbarButtons();
    this.registerHtmlEditorHeaderName();
  }

  element: HtmlEditorExtension;

  private changeQuillBlockModifier() {
    const Block = Quill.import("blots/block");
    Block.tagName = "DIV";
    Quill.register(Block, true);
  }
  private registerHtmlEditorHeaderName() {
    this._eventAggregator.subscribe("dx-widget:attaching", (e) => {
      if (e.name != "dxHtmlEditor") {
        return;
      }

      let onContentReadyDone = false;
      const onContentReady = e.options.onContentReady;
      e.options.onContentReady = (ev) => {
        if (onContentReady) {
          onContentReady(ev);
        }

        if (onContentReadyDone) {
          return;
        }
        onContentReadyDone = true;

        const toolbar = ev.component._quillInstance.getModule("toolbar");

        const widgetHeader = toolbar._toolbarWidgets._collection.find(c => c.name === "header");
        if (!widgetHeader) {
          return;
        }

        const displayExpr = widgetHeader.instance.option("displayExpr");
        widgetHeader.instance.option("displayExpr", (item) => {
          if (item == 3) {
            return "Überschrift";
          } else {
            return displayExpr(item);
          }
        });
      }
    });
  }
  private registerHtmlEditorToolbarButtons() {
    this._eventAggregator.subscribe("dx-widget:attaching", (e) => {
      if (e.name != "dxHtmlEditor") {
        return;
      }

      let htmlEditor: DevExpress.ui.dxHtmlEditor;
      const onInitialized = e.options.onInitialized;
      e.options.onInitialized = (ev) => {
        if (onInitialized) {
          onInitialized(ev);
        }

        htmlEditor = ev.component;
      };

      const formId = e.owningView?.container?.viewModel?.scope?.bindingContext?.id;

      const isStelleninseratLehre = [
        "stelleninserat-lehre-edit",
        "stelleninserat-lehre-kunde-package-edit"].includes(formId);

      const canAddImageAndView = !isStelleninseratLehre;

      if (canAddImageAndView) {
        this.addImageButton(e.options.toolbar.items, () => htmlEditor);
      }

      this.addVideoButton(e.options.toolbar.items, () => htmlEditor);
    });
  }

  private addImageButton(toolbarItems: any[], getHtmlEditor: { (): DevExpress.ui.dxHtmlEditor }) {
    //TODO DXUPDATE
    toolbarItems.push({
      widget: "dxButton",
      options: <DevExpress.ui.dxButtonOptions>{
        icon: "far fa-image",
        hint: "Bild einfügen",
        stylingMode: "text",
        onClick: (e) => {
          e.event.stopPropagation();
          e.event.preventDefault();

          const htmlEditor = getHtmlEditor();
          if (!htmlEditor) {
            return;
          }

          const selection = htmlEditor.getSelection();
          const pasteIndex = selection ? selection.index : htmlEditor.getLength();

          this.element.addImage((src, width, height) => {

            if (!width && !height) {
              width = "100%";
            }

            htmlEditor.insertEmbed(pasteIndex, "extendedImage", { src, width, height });
            htmlEditor.setSelection(pasteIndex + 1, 0);
          });
        }
      }
    });
  }
  private addVideoButton(toolbarItems: any[], getHtmlEditor: { (): DevExpress.ui.dxHtmlEditor }) {
    //TODO DXUPDATE
    toolbarItems.push({
      widget: "dxButton",
      options: <DevExpress.ui.dxButtonOptions>{
        icon: "fas fa-video",
        hint: "Video einfügen",
        stylingMode: "text",
        onClick: (e) => {
          e.event.stopPropagation();
          e.event.preventDefault();

          const htmlEditor = getHtmlEditor();
          if (!htmlEditor) {
            return;
          }

          const selection = htmlEditor.getSelection();
          const pasteIndex = selection ? selection.index : htmlEditor.getLength();

          this.element.addVideo((src, width, height) => {
            htmlEditor.insertEmbed(pasteIndex, "video", { src, width: width || "100%", height: height || "315" });
            htmlEditor.setSelection(pasteIndex + 1, 0);
          });
        }
      }
    });
  }
}


interface IVideoOptions {
  src: string;
  width: string;
  height: string;
}