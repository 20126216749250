import { 
  Aurelia,
  PLATFORM
} from "aurelia-framework"
import { 
  AuthorizationService 
} from "./framework/base/services/authorization-service";
import {
  ModuleLoader
} from "./framework-data/modules";
import environment from "./environment";
import "whatwg-fetch";
import "../resources/grid.less";
import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
import * as Bluebird from "bluebird";
import { AlertService } from './framework/base/services/alert-service';
import { SettingService } from './framework/forms/export';

Bluebird.config({ 
  longStackTraces: false,
  warnings: { wForgottenReturn: false }
});

export function configure(aurelia: Aurelia) {
  aurelia.use
    .basicConfiguration()
    .feature(PLATFORM.moduleName("framework/base/index"))
    .feature(PLATFORM.moduleName("framework/dx/index"))
    .feature(PLATFORM.moduleName("framework/forms/index"))
    .feature(PLATFORM.moduleName("framework/security/index"))
    .feature(PLATFORM.moduleName("zit-ui/index"))
    .feature(PLATFORM.moduleName("zit/index"));

  if (environment.debug) {
    aurelia.use.developmentLogging();
  }

  const moduleLoader = new ModuleLoader();

  return aurelia.start().then(() => {

    const alertService: AlertService = aurelia.container.get(AlertService);
    
    const settingService: SettingService = aurelia.container.get(SettingService);
    settingService.instance.IsLabelBeforeEditor = false;

    const authorization: AuthorizationService = aurelia.container.get(AuthorizationService);
    authorization.openApp();

    return Promise.resolve();
  });
}
