import { autoinject, computedFrom, TaskQueue } from "aurelia-framework";
import { ISelectedFilter } from './erweiterte-suche';
import { ErweiterteSucheService } from '../../services/export';
import { ObjektExportEigenschaftDatenTyp } from '../../enumerations/export';
import { IdxTextBoxComponent } from '../../interfaces/export';

@autoinject
export class ErweiterteSucheText {
  constructor(
    private erweiterteSucheService: ErweiterteSucheService,
    private taskQueue: TaskQueue
  ) {}

  filter: ISelectedFilter;

  operatorSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "value",
    displayExpr: "text",
    bindingOptions: {
      value: "filter.operator"
    }
  }
  textBox: IdxTextBoxComponent;
  textBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    onValueChangedByUser: () => {
      this.checkIsValid();
    },
    bindingOptions: {
      value: "filter.wert"
    }
  };

  @computedFrom("filter.operator")
  get wertVisible() {
    return this.filter.operator != "isnull"
      && this.filter.operator != "isnotnull";
  }

  activate(filter) {
    this.filter = filter;
    this.operatorSelectBoxOptions.dataSource = this.erweiterteSucheService.getOperatorDataSource(ObjektExportEigenschaftDatenTyp.Text);

    this.checkIsValid();
  }
  attached() {
    if (this.filter.focus) {
      this.taskQueue.queueTask(() => {
        if (this.textBox && this.textBox.instance) {
          this.textBox.instance.focus();
        }
      });
    }
  }
  
  checkIsValid() {
    this.filter.isValid = this.filter.operator == "isnull"
      || this.filter.operator == "isnotnull"
      || !!this.filter.wert;

    if (this.filter.isValid) {
      this.filter.bezeichnung = this.erweiterteSucheService.getFilterBezeichnung(this.filter.filter, this.filter.operator, this.filter.wert);
    } else {
      this.filter.bezeichnung = null;
    }
  }
}
