import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class PersonBerufEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "person-beruf-edit";
        this.title = "person-beruf-edit.person-beruf-edit_caption";
        this.addModel({
            "id": "$m_Beruf",
            "webApiAction": "ZIT/Objekte/PersonBeruf",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "beruf",
            "filters": [],
            "caption": "person-beruf-edit.r_beruf_caption",
            "binding": {
                "dataContext": "$m_Beruf",
                "bindTo": "IdBeruf",
                "bindToFQ": "models.data.$m_Beruf.IdBeruf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_beruf",
            "options": {
                "optionsName": "r_berufOptions",
                "optionsNameFQ": "r_berufOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "berufQualifikation",
            "filters": [],
            "caption": "person-beruf-edit.r_berufqualifikation_caption",
            "binding": {
                "dataContext": "$m_Beruf",
                "bindTo": "IdBerufQualifikation",
                "bindToFQ": "models.data.$m_Beruf.IdBerufQualifikation"
            },
            "validationRules": [],
            "id": "r_berufQualifikation",
            "options": {
                "optionsName": "r_berufQualifikationOptions",
                "optionsNameFQ": "r_berufQualifikationOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "ausbildungAbschluss",
            "filters": [],
            "caption": "person-beruf-edit.r_ausbildungabschluss_caption",
            "binding": {
                "dataContext": "$m_Beruf",
                "bindTo": "IdAusbildungAbschluss",
                "bindToFQ": "models.data.$m_Beruf.IdAusbildungAbschluss"
            },
            "validationRules": [],
            "id": "r_ausbildungAbschluss",
            "options": {
                "optionsName": "r_ausbildungAbschlussOptions",
                "optionsNameFQ": "r_ausbildungAbschlussOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "person-beruf-edit.r_erfahrungsjahre_caption",
            "binding": {
                "dataContext": "$m_Beruf",
                "bindTo": "Erfahrungsjahre",
                "bindToFQ": "models.data.$m_Beruf.Erfahrungsjahre"
            },
            "validationRules": [],
            "id": "r_erfahrungsjahre",
            "options": {
                "optionsName": "r_erfahrungsjahreOptions",
                "optionsNameFQ": "r_erfahrungsjahreOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "person-beruf-edit.r_zuletzttaetig_caption",
            "binding": {
                "dataContext": "$m_Beruf",
                "bindTo": "ZuletztTaetig",
                "bindToFQ": "models.data.$m_Beruf.ZuletztTaetig"
            },
            "validationRules": [],
            "id": "r_zuletztTaetig",
            "options": {
                "optionsName": "r_zuletztTaetigOptions",
                "optionsNameFQ": "r_zuletztTaetigOptions"
            }
        });
        super.onConstructionFinished();
    }
}