import { autoinject, transient, TaskQueue, computedFrom } from 'aurelia-framework';
import { FormBase } from '../../../../framework/forms/classes/export';
import { LocalizationService } from '../../../../framework/base/services/export';
import { ICommandData, DialogConfirmService } from '../../../../framework/forms/export';
import { GeschaeftspartnerService } from '../../../services/export';
import { IdxValidationGroupComponent, IdxDataGridComponent } from '../../../interfaces/export';

@autoinject
@transient()
export class GeschaeftspartnerGruppierungUtils {
  constructor(
    private geschaeftspartner: GeschaeftspartnerService,
    private localization: LocalizationService,
    private taskqueue: TaskQueue,
    private dialogConfirmService: DialogConfirmService
  ) { }

  form: FormBase;

  addNewGruppierungCommand: ICommandData = {
    id: "addNewGruppierungCommand",
    icon: "fas fa-plus",
    tooltip: "geschaeftspartner.neue-gruppierung",
    execute: () => {
      const grid: IdxDataGridComponent = this.form["r_GeschaeftspartnerGruppierungDataGrid"];
      if (grid && grid.instance) {
        grid.instance.deselectAll();
      }

      this.setEditGruppierungData(0);
    }
  };
  saveGruppierungCommand: ICommandData = {
    id: "addNewGruppierungCommand",
    icon: "fas fa-save",
    tooltip: "base.save",
    execute: () => {
      this.saveGruppierung();
    }
  };
  deleteGruppierungCommand: ICommandData = {
    id: "addNewGruppierungCommand",
    icon: "far fa-trash-alt",
    tooltip: "base.delete",
    isEnabled: false,
    execute: () => {
      const id = this.form.models.data.$m_GeschaeftspartnerGruppierungEdit.Id;
      this.deleteGruppierung(id);
    }
  };

  onGruppierungClick(e) {
    this.setEditGruppierungData(e.data.Id);
  }

  bind(form: FormBase) {
    this.form = form;
    this.setEditGruppierungData(0);
  }

  private refreshGruppierungGrid() {
    const grid: IdxDataGridComponent = this.form["r_GeschaeftspartnerGruppierungDataGrid"];
    if (grid && grid.instance) {
      grid.instance.refresh();
    }
  }
  private setEditGruppierungData(id: number) {
    if (id == void 0) {
      return;
    }
    this.deleteGruppierungCommand.isEnabled = id && id != 0;

    const model = this.form.models.getInfo("$m_GeschaeftspartnerGruppierungEdit");
    this.form.models.loadModel(model, id);
  }

  private async saveGruppierung() {
    const validationGroup: IdxValidationGroupComponent = this.form["r_geschaeftspartnerGruppierungValidationGroup"];
    const result = validationGroup.instance.validate();
    if (result.isValid) {
      const dataToSave = this.form.models.data.$m_GeschaeftspartnerGruppierungEdit;
      dataToSave.IdGeschaeftspartner = dataToSave.IdGeschaeftspartner || this.form.variables.data.$v_idGeschaeftspartner;

      this.form.models.data.$m_GeschaeftspartnerGruppierungEdit = await this.geschaeftspartner.saveGruppierung(dataToSave);

      this.onGruppierungSaved();
    }
  }
  private deleteGruppierung(id: number) {
    if (id == void 0) {
      return;
    }

    const title = this.localization.translateOnce("zit.loeschen-confirm-title");
    const message = this.localization.translateOnce("zit.loeschen-confirm-message", ["die Gruppierung"]);

    this.dialogConfirmService.show({
      title: title,
      message: message
    }).then(async r => {
        if (!r) {
          return;
        }

        await this.geschaeftspartner.deleteGruppierung(id);

        this.onGruppierungDeleted();
      });
  }

  private onGruppierungDeleted() {
    DevExpress.ui.notify(this.localization.translateOnce("zit.geloescht-message", ["Gruppierung"]), "info", 3000);

    this.setEditGruppierungData(0);
    this.refreshGruppierungGrid();
  }
  private onGruppierungSaved() {
    this.refreshGruppierungGrid();

    DevExpress.ui.notify(this.localization.translateOnce("base.save_success"), "success", 3000);
  }
}
