import { IZitCardOptions, ZitCardTyp } from "./../../../zit-ui/elements/zit-card/zit-card-options";
import { autoinject } from "aurelia-framework";
import { SucheService } from "../../services/export";

@autoinject
export class DashboardBoxEventTeilnehmer {
  constructor(
    private sucheService: SucheService
  ) { }

  item: any;

  eventTeilnehmerCardOptions: IZitCardOptions = {
    isClickEnabled: true,
    useDefaultBindings: true,
    typ: ZitCardTyp.Kompakt
  };

  activate(item) {
    this.item = item;
  }

  onBoxHeaderClicked() {
    this.sucheService.activateSuche("TIP.ZIT.Business.Entities.Objekte.Events.EventTeilnehmer");
  }
}
