import { autoinject } from "aurelia-framework";
import { RestService } from '../../framework/base/services/rest-service';

@autoinject
export class DashboardService {
  constructor(
    private _restService: RestService
  ) {}

  getDashboardInfo() {
    return this._restService.get({
      url: this._restService.getApiUrl(`ZIT/Dashboard/Info`),
      increaseLoadingCount: true
    })
  }
  getAufgabeBox() {
    return this._restService.get({
      url: this._restService.getApiUrl(`ZIT/Dashboard/AufgabeBox`),
      increaseLoadingCount: true
    })
  }
}
