import { autoinject, Scope, OverrideContext, bindable, TaskQueue } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { LocalizationService, ScopeContainer, DataSourceService, JsonService, GlobalizationService } from "../../../framework/base/export";
import { SimpleWidgetCreatorService, ICommandData } from "../../../framework/forms/export";
import { IdxPopupComponent, IdxValidationGroupComponent, IStatusChangeData, IStatusBenutzerAvailableData, IStatusChangeResultData } from "../../interfaces/export";
import { StatusService } from "../../services/export";
import { GrundTyp } from "../../enumerations/export";

@autoinject
export class StatusAendern {
  private _savedCallBack: { (r: IStatusChangeResultData) };

  constructor(
    private _dataSourceService: DataSourceService,
    private _jsonService: JsonService,
    private _localizationService: LocalizationService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _statusService: StatusService,
    private _globalizationService: GlobalizationService,
    private _taskqueue: TaskQueue,
    private _eventAggregator: EventAggregator
  ) {
    this.resetMainModel();
   }

  @bindable keyList: number[];

  canChangeStatus = true;
  mainModel: IStatusChangeData;

  isGrundPflicht: boolean;
  isBemerkungPflicht: boolean;

  scope: Scope;
  scopeContainer: ScopeContainer;

  verlaufDataSource: [];

  popupCommands: ICommandData[] = [
    {
      id: "statusAendernSave",
      idCategory: "$start",
      icon: "fas fa-save",
      tooltip: "base.save",
      sortIndex: 0,
      isEnabledExpression: "canChangeStatus",
      execute: () => {
        this.changeStatus();
      }
    }];
  statusAendernPopup: IdxPopupComponent;
  statusAendernPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxHeight: "100vh",
    maxWidth: "700px"
  };

  grundAenderungValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{
      type: "custom",
      reevaluate: true,
      message: this._localizationService.translateOnce("forms.validator_required", [this._localizationService.translateOnce("status-aendern.grund-aenderung-caption")]),
      validationCallback: (e) => {
        if(e.value) {
          return true;
        }
      }
    }]
  };
  bemerkungValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{
      type: "custom",
      reevaluate: true,
      message: this._localizationService.translateOnce("forms.validator_required", [this._localizationService.translateOnce("status-aendern.bemerkung-caption")]),
      validationCallback: (e) => {
        if(e.value || !this.isBemerkungPflicht) {
          return true;
        }
      }
    }]
  };
  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };
  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {};

  benutzerStatusDataSource: IStatusBenutzerAvailableData[];
  benutzerStatusOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "IdObjektStatus",
    displayExpr: "ObjektStatusBezeichnung",
    onItemClick: (e) => {
      this.isGrundPflicht = e.itemData.IsGrundPflicht;
      this.mainModel.IdGrund = null;

      this.repaintPopup();
    },
    bindingOptions: {
      value: "mainModel.IdObjektStatus",
      dataSource: "benutzerStatusDataSource"
    }
  };

  grundAenderungOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Bezeichnung",
    onItemClick: (e) => {
      this.isBemerkungPflicht = e.itemData.IsBemerkungPflicht;
    },
    bindingOptions: {
      value: "mainModel.IdGrund"
    }
  };

  bemerkungOptions: DevExpress.ui.dxTextAreaOptions = {
    height: 60,
    maxLength: 500,
    bindingOptions: {
      value: "mainModel.Bemerkung"
    }
  };

  verlaufOptions: DevExpress.ui.dxDataGridOptions = {
    width: "100%",
    height: "250px",
    columnAutoWidth: true,
    columns: [{
      dataField: "Aenddat",
      caption: "Zeitpunkt",
      format: this._globalizationService.getFormatter("g")
    }, {
      dataField: "Status",
      caption: "Status"
    }, {
      dataField: "Bemerkung",
      caption: "Bemerkung"
    }, {
      dataField: "Aendsb",
      caption: "Änderungsbenutzer"
    }],
    bindingOptions: {
      "dataSource": "verlaufDataSource"
    }
  };

  showPopup(savedCallback?: { (r: IStatusChangeResultData) }) {
    this._taskqueue.queueMicroTask(async () => {
      const r = await this._statusService.allowUpdateObjektStatus(this.keyList);

      this.verlaufDataSource = [];
      this.statusAendernPopup.instance.show();
      
      this.resetMainModel(r);
      this._savedCallBack = savedCallback;

      this.loadAvailableObjektStatus(r);
      this.loadVerlauf(r);

      this.repaintPopup();
    });
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "statusAendernPopupToolbar",
      caption: "status-aendern.popup-titel",
      options: this.statusAendernPopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });

    this.grundAenderungOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ZIT/Stammdaten/Grund",
        webApiWhere: [["Typ", GrundTyp.ObjektstatusSetzen], ["IdObjektStatus", { "expression": "mainModel.IdObjektStatus", "isBound": true }]]
      }
    )
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  private changeStatus() {
    const validationResult = this.validationGroup.instance.validate();
    if (!validationResult.isValid) {
      return;
    }

    const title = this._localizationService.translateOnce("status-aendern.bestaetigung-titel");
    const message = this._localizationService.translateOnce("status-aendern.bestaetigung-text");

    DevExpress.ui.dialog
      .confirm(message, title)
      .then(async (confirmed) => {
        if (!confirmed) {
          return;
        }

        this.canChangeStatus = false;
        const r = await this._statusService.changeObjektStatus(this.mainModel);

        if (r && !r.IsValid) {
          DevExpress.ui.notify(r.Message, "error", 3000);
          return;
        }

        if (this._savedCallBack != void 0) {
          this._savedCallBack(r);
        }

        this._eventAggregator.publish("objekt-status:changed", {
          idObjektList: this.keyList
        });

        this.canChangeStatus = true;
        this.statusAendernPopup.instance.hide();
      });
  }
  private resetMainModel(idObjektStatusElementList: any = null) {
    this.mainModel = { 
      IdGrund: null,
      IdObjektStatus: null, 
      IdObjektStatusElementList: idObjektStatusElementList, 
      Bemerkung: null 
    };
  }
  private async loadVerlauf(keys: number[]) {
    this.verlaufDataSource = await this._statusService.loadStatusHistorie(keys);
  }
  private async loadAvailableObjektStatus(keys: number[]) {
    this.benutzerStatusDataSource = await this._statusService.loadBenutzerAvailableObjektStatus(keys);
  }

  private repaintPopup() {
    this._taskqueue.queueTask(() => {
      if (!this.statusAendernPopup) {
        return;
      }
      if (!this.statusAendernPopup.instance) {
        return;
      }

      this.statusAendernPopup.instance.repaint();
    });
  }
}
