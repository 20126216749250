import {
  autoinject
} from "aurelia-framework";
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class ErrorService {
  constructor(
    private eventAggregator: EventAggregator
  ) {}

  showError(error: any) {
    let message = error;

    if (error instanceof Error || error.message) {
      message = error.message;
    }

    this.eventAggregator.publish("alert", {
      message: message,
      title: "Fehler"
    });
  }
  logError(error: any) {

  }
  showAndLogError(error: any) {
    if (!error) {
      return;
    }
    if (error.isHandled === true) {
      return;
    }

    this.logError(error);
    this.showError(error);
  } 
}
