import {
  autoinject,
  bindable,
  createOverrideContext,
  inlineView,
  OverrideContext,
  TemplatingEngine,
  resource,
  computedFrom,
  View
} from "aurelia-framework";
import { BindingService } from '../../../base/services/binding-service';
import { ScopeContainer } from '../../../base/classes/scope-container';
import { IListViewItemsOptions } from './list-view-items-options';
import { TemplatingExService } from '../../../base/export';

@autoinject
export class ListViewItem {
  private _itemNode: HTMLElement;
  private _itemView: View;

  constructor(
    private element: Element,
    private binding: BindingService,
    private templatingEngine: TemplatingEngine,
    private templatingExService: TemplatingExService
  ) { }

  @bindable data: any;
  @bindable options: IListViewItemsOptions;

  isSelected: boolean = false;

  scopeContainer: ScopeContainer;
  owningView: any;
  key: any;

  created(owningView: any, myView: any) {
    this.owningView = owningView;
  }
  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.key = this.options.getItemKey(this.data);
    this.isSelected = !!this.options.selectedItems[this.key];

    if (typeof this.key === "string" || typeof this.key === "number") {
      const key = typeof this.key === "string"
        ? `"${this.key}"`
        : this.key

      this.binding.observe({
        scopeContainer: this.scopeContainer,
        expression: `!!options.selectedItems[${key}]`, 
        callback: (newValue) => {
          this.isSelected = newValue;
        }
      });
    }
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }
  attached() {
    this._itemNode = this.options.itemTemplate.cloneNode(true)
    const container = this.element.children[0];
    const selection = container.children[0];
    container.insertBefore(this._itemNode, selection);

    const bindingContext = {};
    bindingContext[this.options.itemAlias] = this.data;

    //= Resourcen von dem Element, welches das list-view Element enthält ;-)
    let resources = null;
    let parent = this.owningView.container;
    let isListView = false;
    while (parent) {
      if (isListView && parent.viewResources && parent.viewResources.viewUrl != "framework/forms/elements/list-view/list-view.html") {
        resources = parent.viewResources;
        break;
      } else if (parent.viewResources && parent.viewResources.viewUrl == "framework/forms/elements/list-view/list-view.html") {
        isListView = true;
      }

      parent = parent.parent;
    }

    this._itemView = this.templatingEngine.enhance({
      element: this._itemNode,
      resources: resources || this.owningView.resources,
      bindingContext: bindingContext,
      overrideContext: this.options.itemOverrideContext
    });
  }
  detached() {
    if (this._itemNode) {
      this._itemNode.parentElement.removeChild(this._itemNode);
      this._itemNode = null;
    }

    if (this._itemView) {
      this.templatingExService.destroyView(this._itemView);
      this._itemView = null;
    }
  }

  changeSelection(event: Event) {
    const isSelected = this.options.isSelectedByKey(this.key);
    this.options.selectByKey(this.key, !isSelected);
    
    event.stopPropagation();
  }
}
