import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class InformationListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "information-list";
        this.title = "information-list.information-list_caption";
        this.addModel({
            "id": "$m_Information",
            "webApiAction": "ZIT/Objekte/Information",
            "webApiExpand": {
                Kopf: null
            },
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_infoUeberschrift",
                "caption": "information-list.r_infoueberschrift_caption",
                "bindTo": "Kopf.Ueberschrift"
            }],
            "optionsToolbar": {
                "optionsName": "r_informationGridToolbarOptions",
                "optionsNameFQ": "r_informationGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Information"
            },
            "dataModel": "$m_Information",
            "editUrl": "Objekte/Information",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_informationGrid",
            "options": {
                "optionsName": "r_informationGridOptions",
                "optionsNameFQ": "r_informationGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}