import { IHistoryState } from "./../interfaces/history-state";
import {
  autoinject,
  TaskQueue
} from "aurelia-framework";
import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  LocationService,
  LocalizationService
} from "../../base/services/export";
import {
  ILocationGoToEventArgs
} from "../../base/event-args/export"
import {
  RouterService
} from "./router-service";
import * as Interfaces from "../interfaces/export";

@autoinject
export class HistoryService {
  private isActive = false;

  constructor(
    private eventAggregator: EventAggregator,
    private taskQueue: TaskQueue,
    private router: RouterService,
    private location: LocationService,
    private localization: LocalizationService
  ) {
    this.register();
  }

  pipelineUrl: string;
  lastRequestUrl: string;
  checkHasChangedDataBeforeUnload: boolean = true;

  clearAndGoToFallback() {
    //Damit keine Frage nach Änderungen speichern kommt
    this.router.currentViewItem = null;

    this.navigate({
      url: "",
      clearStack: true
    });
  }
  goBack(): Promise<any> {
    history.back();
    return Promise.resolve();
  }
  getUrl(url?: string): string {
    let hash = url || location.hash;

    if (!hash) {
      return "";
    }

    if (hash.substr(0, 1) === "#") {
      return hash.substr(1);
    } else {
      return hash;
    }
  }
  getUrlWithHash(url?: string): string {
    return "#".concat(this.getUrl(url));
  }
  navigateCurrentOrInPipeline() {
    if (this.pipelineUrl) {
      this.guardedNavigate(() => {
        return this.navigate({
          url: this.pipelineUrl,
          clearStack: true
        }).then(() => {
          this.pipelineUrl = null;
        });
      });
    } else {
      this.guardedNavigate(() => {
        return this.navigate({
          url: this.getUrl()
        });
      });
    }
  }
  //TODO - ev. in LocationService übernehmen, da es dort besser passt
  setUrlWithoutNavigation(url: string, replace: boolean = false) {
    this.guardedNavigate(() => {
      let currentStateHistory: Interfaces.IHistoryState = history.state;

      const urlWithHash = this.getUrlWithHash(url);
      this.router.updateCurrentUrl(urlWithHash);      

      if (currentStateHistory) {
        currentStateHistory.url = url;

        history.replaceState(
          currentStateHistory,
          currentStateHistory.caption,
          urlWithHash);
      } else {        
        const currentStateRouter = this.router.getCurrentHistoryState();

        history.pushState(
          currentStateRouter,
          currentStateRouter.caption,
          urlWithHash);
      }
      return Promise.resolve();
    });
  }

  private register() {
    window.addEventListener("popstate", (e: any) => {
      this.guardedNavigate(async () => {
        const state: IHistoryState = e.state;
        let hasRoute = false;
        let isForward = false;
        if (state && state.id != void (0)) {
          //Prüfen ob dieser State existiert
          //Wenn ja, dann wurde ein Back ausgeführt, ansonsten ein Forward
          hasRoute = this.router.hasRouteWithId(state.id);

          if (hasRoute) {
            const canDeactivate = await this.router.canDeactivateCurrentView();
            if (!canDeactivate) {
              //Wenn nicht deaktiviert werden kann, dann wird der History-State verwendet
              //und wieder neu hinzugefügt

              const currentState = this.router.getCurrentHistoryState();

              history.pushState(
                currentState,
                currentState.caption,
                this.getUrlWithHash(currentState.url));

              return;
            }
          } else {
            const currentState = this.router.getCurrentHistoryState();
            if (currentState && currentState.id < state.id) {
              isForward = true;
            }
          }
        }

        return this.navigate({
          historyState: e.state,
          clearStack: !e.state || e.state.id == void (0) || (!isForward && !hasRoute),
          url: this.getUrl(),
          isPopState: true
        });
      });
    });
    window.addEventListener("beforeunload", (e: any) => {
      if (!this.checkHasChangedDataBeforeUnload) {
        return;
      }

      const args = {
        hasChangedData: false
      };
      this.eventAggregator.publish("window:beforeunload", args);

      if (args.hasChangedData) {
        e.returnValue = this.localization.translateOnce("stack-router.changed-data-found");
        return e.returnValue;
      }
    });

    this.eventAggregator.subscribe("location:go-back", e => {
      this.goBack();
    });
    this.eventAggregator.subscribe("location:go-to", e => {
      e.promise = this.guardedNavigate(async () => {
        const isHandled = await this.navigateByLocation(e.args);
        e.isHandled = isHandled;
      });
    });
  }
  private async guardedNavigate(action: { (): Promise<any> }) {
    if (this.isActive) {
      return;
    }

    this.isActive = true;
    try {
      return await action();
    } finally {
      this.isActive = false;
    }
  }
  private async navigate(navigationArgs: Interfaces.INavigationArgs): Promise<boolean> {
    this.lastRequestUrl = navigationArgs.url;

    const hasNavigated = await this.router.navigate(navigationArgs);

    if (hasNavigated) {
      this.setCurrentHistory(navigationArgs.didReplace || navigationArgs.isPopState);
      return true;
    } else {
      return false;
    }
  }
  private async navigateByLocation(locationGoTo: ILocationGoToEventArgs): Promise<boolean> {
    let replace = locationGoTo.replace || false;

    if (this.router.viewStack.length > 1
      && this.router.viewStack[this.router.viewStack.length - 2].controller["currentViewModel"] === locationGoTo.currentViewModel) {
      replace = true;
    }

    const args: Interfaces.INavigationArgs = {
      url: this.getUrl(locationGoTo.url),
      viewScrollInfo: locationGoTo.viewScrollInfo,
      replace: replace,
      clearStack: locationGoTo.clearStack,
      setValuesOnModelWithKeyIdLoaded: locationGoTo.setValuesOnModelWithKeyIdLoaded,
      customOptions: locationGoTo.customOptions
    };

    await this.navigate(args);
    return true;
  }
  private setCurrentHistory(replace: boolean) {
    const currentHistoryState = this.router.getCurrentHistoryState();

    if (replace) {
      history.replaceState(
        currentHistoryState,
        currentHistoryState.caption,
        this.getUrlWithHash(currentHistoryState.url));
    } else {
      history.pushState(
        currentHistoryState,
        currentHistoryState.caption,
        this.getUrlWithHash(currentHistoryState.url));
    }
  }
}
