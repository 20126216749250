import { GlobalizationService } from "./../../../../framework/base/services/globalization-service";
import { ZitMomentValueConverter } from "./../../../../zit-ui/value-converters/moment-value-converter";
import { LocalizationService } from "./../../../../framework/base/services/localization-service";
import { RestService } from "./../../../../framework/base/services/rest-service";
import { IZitTagOptions } from "./../../../../zit-ui/elements/zit-tag/zit-tag-options";
import { ICommandData } from "./../../../../framework/forms/interfaces/command-data";
import { Share } from "./../../../elements/share/share";
import { FormBase } from "./../../../../framework/forms/classes/form-base";
import { transient } from "aurelia-framework";
import { autoinject } from "aurelia-framework";
import { Datenschutz } from "../../../elements/datenschutz/datenschutz";
import { FileService } from "../../../../framework/base/export";
import { StartupService } from "../../../services/export";
import { IZitCardButtonOptions } from "../../../../zit-ui/elements/zit-card/zit-card-button-options";
import { IZitToolbarOptions } from "../../../../zit-ui/elements/zit-toolbar/zit-toolbar-options";
import { ContextMenu } from "../../../../framework/forms/classes/context-menu";
import { Datei } from "../../../elements/datei/datei";
import { FormPopupService } from "../../../services/form-popup-service";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";

@transient()
@autoinject
export class TerminReadUtils {
  private _terminChangedSubscription: Subscription;

  constructor(
    private _zitMoment: ZitMomentValueConverter,
    private _restService: RestService,
    private _localizationService: LocalizationService,
    private _globalizationService: GlobalizationService,
    private _fileService: FileService,
    private _startupService: StartupService,
    private _formPopupService: FormPopupService,
    private _eventAggregator: EventAggregator
  ) { }

  form: FormBase;
  hauptterminText: any;
  verfuegbarePlaetze: number;
  datenschutzList: any[];
  teilnehmerDataSource: any[] = [];

  saveCommand: ICommandData = {
    id: "save",
    icon: "fas fa-save",
    tooltip: "base.save_tooltip",
    sortIndex: 90,
    isVisible: false,
    execute: async() => {
      const einschEigen = this.form.models.data.$m_Termin._Einschaetzung;
      if (einschEigen) {
        await this._restService.post({
          url: this._restService.getApiUrl("ZIT/Termin/UpdateEinschaetzungEigen"),
          data: Object.assign({
            IdTermin: this.form.models.data.$m_Termin.Id,
          }, this.form.models.data.$m_Termin._Einschaetzung),
          increaseLoadingCount: true
        });

        DevExpress.ui.notify(
          this._localizationService.translateOnce("base.save_success"), 
          "success", 
          3000);
      }
    }
  };

  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };

  betreffTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item.Betreff`,
    icon: {
      text: "B"
    }
  };

  ortTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item.Ort`,
    icon: {
      text: "B"
    }
  };

  geschaeftspartnerTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item.Geschaeftspartner.Name1`,
    icon: {
      text: "G"
    }
  };

  startDatumTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `functions.$f_TerminReadUtils.datumTagString(item)`,
    icon: {
      faIcon: "far fa-calendar-alt"
    }
  };

  ansprechpersonTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item.Ansprechperson`,
    icon: {
      faIcon: "fas fa-user"
    }
  };

  meldenBeiUmTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `item.Treffpunkt`,
    icon: {
      text: "T"
    }
  };

  adresseTagOptions: IZitTagOptions = {
    textExpression: `item.Adresse`,
    icon: {
      faIcon: "fas fa-map-marker-alt"
    },
    onClick: () => {
      this.openGoogleMaps();
    }
  };

  zusageButtonTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    cssClass: "z--tag z--zusage-button",
    icon: {
      faIcon: "far fa-check-circle"
    },
    textExpression: `item.Verteilungstyp == 0 && item.StatusZustandCode != "ZUM_TERMIN_EINLADEN"
      ? "Ja, ich möchte den Termin zusagen"
      : item.StatusZustandCode == "ZUM_TERMIN_EINLADEN" 
      ? "Ja, ich nehme an dem Termin teil"
      : "Ja, ich möchte der Veranstaltung zusagen"`,
    onClick: () => {
      this.terminZusagen();
    }
  };

  absagenButtonTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    cssClass: "z--tag z--absage-button",
    icon: {
      faIcon: "fas fa-ban"
    },
    textExpression: `item.StatusZustandCode == "ZUM_TERMIN_EINLADEN" 
      ? "Nein, ich kann leider nicht teilnehmen" 
      : "Nein, ich muss leider absagen"`,
    onClick: () => {
      this.terminAbsagen();
    }
  };

  interesseButtonTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    cssClass: "z--tag z--zusage-button",
    icon: {
      faIcon: "far fa-check-circle"
    },
    textExpression: `item.Verteilungstyp == 0 
      ? "Ja, ich interessiere mich für diesen Termin" 
      : "Ja, ich interessiere mich für diese Veranstaltung"`,
    onClick: () => {
      this.terminInteressieren();
    }
  };

  terminDownloadTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    cssClass: "z--tag z--download-button",
    icon: {
      faIcon: "far fa-calendar-alt"
    },
    text: "Termin in Kalender eintragen",
    onClick: () => {
      this.downloadTerminICSFile();
    }
  };

  doShowTerminAbsagenControlsTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    cssClass: "z--tag z--download-button",
    icon: {
      faIcon: "fas fa-undo"
    },
    text: "Zusage rückgängig machen",
    onClick: () => {
      this.form.models.data.$m_Termin.ShowTerminAbsageControls = true;
    }
  };

  terminZusageAbsagenButtonTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    cssClass: "z--tag z--absage-button",
    icon: {
      faIcon: "fas fa-ban"
    },
    text: "Termin absagen",
    onClick: () => {
      this.terminZusageAbsagen();
    }
  };

  infotextTagOptions: IZitTagOptions = {
    textExpression: `item.Informationstext`,
    icon: {
      faIcon: "fas fa-exclamation"
    }
  };

  verfuegbarePlaetzeTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    icon: {
      text: "P"
    },
    textExpression: "functions.$f_TerminReadUtils.verfuegbarePlaetze"
  };

  einschaetzungTagOptions: IZitTagOptions = {
    icon: {
      text: "E"
    },
    infoList: [{
      icon: {
        faIcon: "fas fa-paperclip",
        backgroundColor: "#22B002"
      },
      isVisibleExpression: "item.DMSLinkPdf",
      onClick: (e, data) => {
        e.stopPropagation();
        e.preventDefault();

        this._fileService.inline(data.DMSLinkPdf);
      }
    }],
    textExpression: `item.PersonTitel + ", " + globalization.format(item.Anlagedatum, "d")`,
    isClickEnabledExpression: "!item._DisableEdit",
    onClick: (ev: Event, data) => {
      ev.preventDefault();
      ev.stopPropagation();

      if (data._DisableEdit) {
        return;
      }

      this.form.models.data.$m_TerminEmpfEinschEdit = data;
      this.form.editPopups.show("terminEmpfEinschEditPopup", null);
    }
  };

  dokumentToolbarOptions: IZitToolbarOptions = {
    title: "termin.informationen",
    smallToolbar: true,
    items: [{
      id: "showDokumentToolbarOptionen",
      icon: "fas fa-plus",
      isVisible: ["ADMIN", "TRAINER", "TEAM", "ADMIN_HB"].indexOf(this._startupService.startupInfo.Benutzer.CodeBenutzerrolle) >= 0,
      execute: async(e) => {
        const ctxMenu = new ContextMenu();

        ctxMenu.items = [{
            text: this._localizationService.translateOnce("termin.dokument-toolbar-item-info-dokumente"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onDokumentClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("termin.dokument-toolbar-item-info-bilder"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onCameraClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("termin.dokument-toolbar-item-info-videos"),
            execute: () => {
              setTimeout(() => {
                const datei: Datei = this.form["r_dateien"];
                datei.onVideoClick();
              }, 0);
            }
          }, {
            text: this._localizationService.translateOnce("termin.dokument-toolbar-item-info-galerie"),
            execute: async() => {
              const r = await this.form.saveIfDirty();
              if (!r.isValid) {
                return;
              }

              const datei: Datei = this.form["r_dateien"];
              datei.onAddToGalerieClick(() => {
                this.form.save();
              });
            }
          }];
          
        ctxMenu.show(event.target);
      }
    }]
  };

  get canTerminAbsagen(): boolean {
    const termin = this.form.models.data.$m_Termin;
    if (!termin || !termin.CanTerminAbsagen) {
      return false;
    }

    if (termin.Kopf.StatusZustandCode != "ZUGESAGT") {
      return false;
    }

    return true;
  }
  get isHeartbase() {
    return this.form
      && this.form.id == "termin-heartbase-read";
  }

  isZuAbsagenButtonVisible(): boolean {
    const model = this.form.models.data.$m_Termin;

    if (!model) {
      return false;
    }

    const kopf = model.Kopf;

    if (!kopf) {
      return false;
    }

    if (kopf.StatusZustandCode == "ZUM_TERMIN_EINLADEN") {
      if (kopf.EmpfaengerReaktion == 1) {
        return false;
      }

      return true;
    }

    if (kopf.Informationstext && kopf.Informationstext.length > 0) {
      return false;
    }

    if (kopf.StatusZustandCode == "ZUGESAGT" || kopf.StatusZustandCode == "ABGESAGT") {
      return false;
    }

    if (kopf.IdHaupttermin) {
      return false;
    }

    if (kopf.EmpfaengerReaktion != 0) {
      return false;
    }

    if (kopf.MaxTeilnehmerzahl > kopf.AnzahlTeilnehmer) {
      return true;
    }

    if (!kopf.MaxTeilnehmerzahl) {
      return true;
    }

    return false;
  }
  isHauptterminVorhanden(): boolean {
    const model = this.form.models.data.$m_Termin;

    if (!model) {
      return false;
    }

    const kopf = model.Kopf;
    if (!kopf) {
      return false;
    }

    if (kopf.Informationstext && kopf.Informationstext.length > 0) {
      return false;
    }

    if (kopf.IdHaupttermin) {
      return true;
    }

    if (kopf.ZusagedatumHaupttermin) {
      return true;
    }

    return false;
  }
  isInteresseEinverstaendniserklaerungVisible(): boolean {
    const isVisible = this.isInteresseButtonVisible();
    if (!isVisible) {
      return false;
    }

    return this.form.models.data.$m_Termin.IsInteresseEinverstaendniserklaerungVisible;
  }
  isInteresseButtonVisible(): boolean {
    const model = this.form.models.data.$m_Termin;

    if (!model) {
      return false;
    }

    const kopf = model.Kopf;
    if (!kopf) {
      return false;
    }

    if (kopf.StatusZustandCode == "ZUM_TERMIN_EINLADEN") {
      return false;
    }

    if (kopf.Informationstext && kopf.Informationstext.length > 0) {
      return false;
    }

    if (kopf.EmpfaengerReaktion != 2) {
      return false;
    }

    if (!kopf.MaxTeilnehmerzahl) {
      return true;
    }

    if (kopf.MaxTeilnehmerzahl > kopf.AnzahlTeilnehmer) {
      return true;
    }

    return false;
  }

  bind(form: FormBase) {
    this.form = form;

    this.form.models.onLoaded.register((r) => {
      if (r.model.id == "$m_Termin") {
        this.hauptterminText = `<span>Du hast bereits bei einem anderen Termin zu dieser Veranstaltungsserie (<a href="${`/#Objekte/Termin/${r.data.Kopf.IdHaupttermin}`}">${r.data.Kopf.HauptterminBetreff + " " + r.data.Kopf.HauptterminDatumUhrzeit}</a>) am ${r.data.Kopf.ZusagedatumHaupttermin} zugesagt.</span>`;
        
        if (r.data.Kopf && r.data.Kopf.MaxTeilnehmerzahl) {
          const zugesagteEmpfaenger = r.data.Empfaenger.filter((c) => c.StatusZustandCode === "ZUGESAGT");
          this.verfuegbarePlaetze = Math.max(0, (r.data.Kopf.MaxTeilnehmerzahl - zugesagteEmpfaenger.length));
        }

        this.datenschutzList = r.data.DatenschutzList
          ? r.data.DatenschutzList
          : [];
  
        if (r.data._Einschaetzung) {
          this.saveCommand.isVisible = true;
        }

        this.updateTeilnehmerDataSource();
      }

      return Promise.resolve();
    });

    this._terminChangedSubscription = this._eventAggregator.subscribe("termin:changed", (ev) => {
      const data = this.form.models.data.$m_Termin;
      if (data && data.Id == ev.idTermin) {
        this.form.models.reloadAll();
      }
    });
  }
  unbind() {
    this._terminChangedSubscription.dispose();
    this._terminChangedSubscription = null;
  }

  datumTagString(item: any): string {
    if (!item) {
      return "";
    }

    if (!item.StartDatum || !item.EndeDatum) {
      return "";
    }

    if (item.StartUhrzeit && item.EndeUhrzeit) {
      if (item.StartDatum.getTime() === item.StartUhrzeit.getTime() && item.EndeDatum.getTime() === item.EndeUhrzeit.getTime()) {
        return this._globalizationService.format(item.StartDatum, "DT_DD.MM.YYYY (dd)").toUpperCase()
          + " - "
          + this._globalizationService.format(item.EndeDatum, "DT_DD.MM.YYYY (dd)").toUpperCase();
      }
    }

    return this._globalizationService.format(item.StartDatum, "DT_DD.MM.YYYY (dd)").toUpperCase()
      + " "
      + this._zitMoment.getZeit(item.StartUhrzeit)
      + " - "
      + this._globalizationService.format(item.EndeDatum, "DT_DD.MM.YYYY (dd)").toUpperCase()
      + " "
      + this._zitMoment.getZeit(item.EndeUhrzeit);
  }

  onTerminEinschaetzungErstellenClick() {
    this.form.models.data.$m_TerminEmpfEinschEdit = {
      Id: 0
    };
    this.form.editPopups.show("terminEmpfEinschEditPopup", null);
  }

  private openGoogleMaps() {
    const resultGoogleMaps = this.form.models.data.$m_Termin.Kopf.Adresse;
    if (!resultGoogleMaps) {
      return;
    }
    window.open("https://www.google.com/maps/place/" + resultGoogleMaps.replace(" ", "+"), "_blank");
  }

  private getICSFileDownloadUrl(key: string): string {
    return `${this._restService.getApiUrl("ZIT/WebsiteTermin/ICSFile")}?key=${key}`;
  }
  private async downloadTerminICSFile() {
    window.open(this.getICSFileDownloadUrl(this.form.models.data.$m_Termin.Kopf.ICSDownloadKey), "_self");
  }

  private async terminZusagen() {
    const kopf = this.form.models.data.$m_Termin.Kopf;
    const result = await this.setTerminZuAbsagen(kopf.IdPerson, kopf.TerminCustomId, "ZUGESAGT")
      .then((r) => {
        this.form.models.reloadAll();
      });
  }
  private async terminAbsagen() {
    const kopf = this.form.models.data.$m_Termin.Kopf;
    const result = await this.setTerminZuAbsagen(kopf.IdPerson, kopf.TerminCustomId, "ABGESAGT")
      .then((r) => {
        this.form.models.reloadAll();
      });
  }
  private async terminZusageAbsagen() {
    const termin = this.form.models.data.$m_Termin;

    if (!termin.AbsageGrund || termin.AbsageGrund.length <= 0) {
      DevExpress.ui.notify(this._localizationService.translateOnce("termin.kein_absagegrund_vorhanden"), "ERROR", 3000);
      return;
    }

    this._restService.post({
      url: this._restService.getApiUrl("ZIT/Termin/TerminZusageAbsagen"),
      data: {
        IdPerson: termin.Kopf.IdPerson,
        TerminCustomId: termin.Kopf.TerminCustomId,
        Bemerkung: termin.AbsageGrund
      }
    }).then((r) => {
      this.form.models.reloadAll();
    });
  }
  private async terminInteressieren() {
    const kopf = this.form.models.data.$m_Termin.Kopf;
    if (this.isInteresseEinverstaendniserklaerungVisible()) {
      const datenschutz: Datenschutz = this.form["r_datenschutz"];
      if (!datenschutz.hasAlleBestaetigt()) {
        return;
      }
    }

    const result = await this.setTerminZuAbsagen(kopf.IdPerson, kopf.TerminCustomId, "INTERESSIERT")
      .then((r) => {
        this.form.models.reloadAll();
      });
  }

  private async setTerminZuAbsagen(idPerson: number, terminCustomId: any, statusZustandCode: any) {
    const kopf = this.form.models.data.$m_Termin.Kopf;

    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Termin/TerminZuAbsagen"),
      data: {
        IdPerson: idPerson,
        TerminCustomId: terminCustomId,
        StatusZustandCode: statusZustandCode
      }
    });
  }

  private updateTeilnehmerDataSource() {
    if (!this.isHeartbase) {
      return;
    }

    const dataSource = [];

    if (this.form.models.data.$m_Termin.Trainer) {
      const arr: any[] = this.form.models.data.$m_Termin.Trainer;

      arr.map((i) => {
        const data: any = {
          TypeName: "TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson",
          Data: i.Ansprechperson.Card,
          showNachrichten: true
        };

        if (i.Ansprechperson.Card.Beschreibung) {
          data.buttons = <IZitCardButtonOptions[]>[{
            icon: "fas fa-info-circle",
            onClick: (ev) => {
              ev.preventDefault();
              ev.stopPropagation();

              this._formPopupService.formPopup.show({
                titel: `Info über ${i.Ansprechperson.Card.Name}`,
                formDef: [{
                  name: "info",
                  template: (_, itemElement: HTMLElement) => {
                    itemElement.style.maxWidth = "400px";
                    itemElement.innerHTML = i.Ansprechperson.Card.Beschreibung;
                  }
                }]
              });
            }
          }];
        }

        return data;
      }).forEach((i) => dataSource.push(i));
    }
    if (this.form.models.data.$m_Termin.Empfaenger) {
      const arr: any[] = this.form.models.data.$m_Termin.Empfaenger;

      arr.map((i) => {
        return {
          TypeName: "TIP.ZIT.Business.Entities.Objekte.Personen.Person",
          Data: i.Person.Card,
          showNachrichten: true
        };
      }).forEach((i) => dataSource.push(i));
    }

    this.teilnehmerDataSource = dataSource;
  }
}
