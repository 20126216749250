import { IdxLookupComponent } from './../../../interfaces/dx-components';
import { RestService } from './../../../../framework/base/services/rest-service';
import { BenutzerTyp } from './../../../enumerations/benutzer-typ';
import { StartupService } from './../../../services/startup-service';
import { autoinject, transient, computedFrom } from 'aurelia-framework';
import { FormBase, ContextMenu, IListOptionsBase, ICommandData } from '../../../../framework/forms/export';
import { IZitToolbarOptions } from '../../../../zit-ui/elements/zit-toolbar/zit-toolbar-options';
import { DefaultCommandsService } from './../../../../framework/forms/services/export';
import { LocalizationService } from '../../../../framework/base/services/export';
import { IZitTagOptions } from '../../../../zit-ui/elements/zit-tag/export';
import { ListView } from '../../../../framework/forms/elements/list-view/export';
import { Geschlecht } from '../../../enumerations/export';
import { Share } from '../../../elements/share/share';

@autoinject
@transient()
export class AnsprechpersonEditUtils {
  constructor(
    private defaultCommand: DefaultCommandsService,
    private localization: LocalizationService,
    private startupService: StartupService,
    private restService: RestService
  ) { }

  form: FormBase;
  showKeinNewsletterErwuenscht: boolean = false;

  @computedFrom("form.models.data.$m_Ansprechperson.Personendaten.Geschlecht",
    "form.models.data.$m_Ansprechperson.Personendaten.Vorname",
    "form.models.data.$m_Ansprechperson.Personendaten.Nachname")
  get ansprechpersonPersonalienHeader(): string {
    const model = this.form.models.data.$m_Ansprechperson;
    if (!model) {
      return null;
    }

    let anrede = "";
    const vorname = model.Personendaten.Vorname ? model.Personendaten.Vorname : "";
    const nachname = model.Personendaten.Nachname ? model.Personendaten.Nachname : "";

    switch (model.Personendaten.Geschlecht) {
      case Geschlecht.Maennlich: {
        anrede = "Herr";
        break;
      }
      case Geschlecht.Weiblich: {
        anrede = "Frau";
        break;
      }
    }

    return `${anrede} ${vorname} ${nachname}`
  }

  @computedFrom("form.models.data.$m_Ansprechperson.Personendaten.Team")
  get ansprechpersonPersonalienTeamHeader(): string {
    const model = this.form.models.data.$m_Ansprechperson;
    if (!model) {
      return null;
    }

    return model.Personendaten.Team;
  }

  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };
  geschaeftspartnerTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    hasDefaultInfo: true,
    icon: {
      text: "GP"
    },
    textExpression: `item.Geschaeftspartner.Name1 
    ? item.Geschaeftspartner.Adressen.Adressen[0] 
      ? item.Geschaeftspartner.Name1 + ", " + item.Geschaeftspartner.Adressen.Adressen[0].Ort
      : item.Geschaeftspartner.Name1
    : ""`
  };
  kontaktTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    hasDefaultInfo: true,
    icon: {
      faIconExpression: "item.Kontakttyp.TagSymbol ? item.Kontakttyp.TagSymbol : null",
      textExpression: `item.Kontakttyp 
      ? item.Kontakttyp.TagText
        ? item.Kontakttyp.TagText 
        : item.Kontakttyp.Bezeichnung
          ? item.Kontakttyp.Bezeichnung[0] 
          : "K"
      : "K"`
    },
    textExpression: `item.Wert1 
    ? item.Wert2
      ? item.Wert1 + ", " + item.Wert2
      : item.Wert1
    : ""`,
    infoList: [{
      isVisibleExpression: "item.Wichting",
      icon: {
        faIcon: "fas fa-exclamation"
      }
    }]
  };

  detailToolbarOptions: IZitToolbarOptions = {
    title: "ansprechperson.details-toolbar-title",
    smallToolbar: true,
    items: [{
      id: "ansprechpersonEditUtilsDetailCommand",
      icon: "fas fa-plus",
      execute: (e) => {
        this.showDetailCtxMenu(e.event);
      }
    }]
  };

  bind(form: FormBase) {
    this.form = form;

    this.form.models.onLoaded.register((args) => {
      if (args.model.id == "$m_Ansprechperson") {
        const isAdmin = this.startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.Administrator;
        const isInternerBenutzer = this.startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.InternerBenutzer;

        this.setGeschaeftspartnerValue();

        this.showKeinNewsletterErwuenscht = isAdmin || isInternerBenutzer;
      }

      return Promise.resolve();
    });
  }

  private showDetailCtxMenu(event: Event) {
    const ctxMenu = new ContextMenu();

    this.loadCtxMenuItems(ctxMenu);

    ctxMenu.show(event.target);
  }

  private loadCtxMenuItems(ctxMenu: ContextMenu) {
    if (!ctxMenu) {
      return;
    }
    ctxMenu.items = [{
      text: this.localization.translateOnce("ansprechperson.details-toolbar-geschaeftspartner"),
      execute: () => {
        this.showListEditPopup("$m_AnsprechpersonZuGeschaeftspartnerEdit", "ansprechpersonGeschaeftspartnerEditPopup", "r_ansprechpersonGeschaeftspartnerListView");
      }
    }];

    if (this.form.models.data.$m_Ansprechperson.SonstigeKontaktdatenVisible) {
      ctxMenu.items.push({
        text: this.localization.translateOnce("ansprechperson.details-toolbar-kontaktdaten"),

        execute: () => {
          this.showListEditPopup("$m_AnsprechpersonKontaktEdit", "ansprechpersonKontaktEditPopup", "r_ansprechpersonKontaktdatenListView");
        }
      });
    }
  }

  private showListEditPopup(editDataContext: string, idEditPopup: string, idListView: string) {
    const options = this.getListViewBaseOption(editDataContext, idEditPopup, this.form[idListView]);

    const addCommand = this.defaultCommand.getListAddCommand(this.form, options);
    const event = new MouseEvent("click");
    addCommand.execute({ event: event });
  }

  private getListViewBaseOption(editDataContext: string, idEditPopup: string, options: IListOptionsBase): IListOptionsBase {
    options.editDataContext = editDataContext;
    options.idEditPopup = idEditPopup
    options.listEdits = options.listEdits || [];
    return options;
  }

  private setGeschaeftspartnerValue(): void {
    this.restService.get({
      url: `${this.restService.getWebApiUrl("ZIT/Objekte/Geschaeftspartner/")}`,
      getOptions: {
        columns: ['Id']
      }
    }).then(r => {
      if (r.length > 1) {
        return;
      }
      if (r && r.length && r.length === 1) {
        this.form.models.data.$m_Ansprechperson._IdGeschaeftspartner = r[0].Id;
      }
    });
  }
}
