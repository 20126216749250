import { autoinject } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { IdxPopupComponent, IdxValidationGroupComponent } from '../../interfaces/export';
import { ScopeContainer, DataSourceService, RestService, LocalizationService } from '../../../framework/base/export';
import { SimpleWidgetCreatorService, ICommandData, DialogConfirmService } from '../../../framework/forms/export';

@autoinject
export class TerminEmpfaengerUmbuchen {
  private _showOptions: ITerminEmpfaengerUmbuchenShowOptions;

  constructor(
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _dataSourceService: DataSourceService,
    private _restService: RestService,
    private _eventAggregator: EventAggregator,
    private _dialogConfirmService: DialogConfirmService,
    private _localizationService: LocalizationService
  ) {}

  scopeContainer: ScopeContainer;
  idTerminZiel: number;

  validationGroup: IdxValidationGroupComponent;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    maxWidth: "500px",
    height: "auto"
  }
  popupCommands: ICommandData[] = [{
    id: "terminEmpfaengerUmbuchenSaveCommand",
    icon: "fas fa-check",
    tooltip: "base.save",
    sortIndex: 1010,
    execute: async () => {
      const result = this.validationGroup.instance.validate();
      if (!result.isValid) {
        return;
      }

      const dialogResult = await this._dialogConfirmService.show({
        title: this._localizationService.translateOnce("base.question"),
        message: "Wollen Sie die Person wirklich umbuchen?"
      });

      if (!dialogResult) {
        return;
      }

      await this._restService.post({
        url: this._restService.getApiUrl("ZIT/Termin/Umbuchen"),
        data: {
          IdTerminQuelle: this._showOptions.idTermin,
          IdTerminEmpfaenger: this._showOptions.idTerminEmpfaenger,
          IdTerminZiel: this.idTerminZiel,
        },
        increaseLoadingCount: true
      });

      if (this._showOptions.doneCallback) {
        this._showOptions.doneCallback();
      }

      this._eventAggregator.publish("termin:changed", {
        idTermin: this._showOptions.idTermin,
        isQuelle: true
      });

      this._eventAggregator.publish("termin:changed", {
        idTermin: this.idTerminZiel,
        isZiel: true
      });

      DevExpress.ui.notify(
        "Empfänger wurde umgebucht",
        "success",
        3000
      );

      this.popup.instance.hide();
    }
  }];

  terminDataSource: DevExpress.data.DataSource;
  terminSelectOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "DisplayText",
    searchEnabled: true,
    searchExpr: "DisplayText",
    bindingOptions: {
      value: "idTerminZiel"
    }
  }

  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "terminEmpfUmbuchenToolbar",
      caption: "termin-empfaenger-umbuchen.titel",
      options: this.popupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });

    this.terminDataSource = this._dataSourceService.createDataSource(
      this.scopeContainer, {
        webApiAction: "ZIT/Objekte/Termin",
        webApiColumns: ["Id", "DisplayText"],
        webApiOrderBy: [{ columnName: "Kopf.StartDatum", sortOrder: 0 }, { columnName: "DisplayText", sortOrder: 0 }]
      }, {
        canLoad: () => {
          return this._showOptions && this._showOptions.idTermin > 0;
        },
        getCustomFilters: () => {
          return [{webApiCustomKey: "IdTerminUmbuchen", webApiCustomValue: this._showOptions.idTermin.toString()}];
        }
      }
    );
    this.terminSelectOptions.dataSource = this.terminDataSource;
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  showPopup(showOptions: ITerminEmpfaengerUmbuchenShowOptions) {
    this._showOptions = showOptions;
    this.idTerminZiel = null
    
    this.terminDataSource.reload();
    this.popup.instance.show();
  }
}

export interface ITerminEmpfaengerUmbuchenShowOptions {
  idTermin: number;
  idTerminEmpfaenger: number;

  doneCallback?: {(): void};
}