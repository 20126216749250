import { autoinject, Scope } from "aurelia-framework";
import { IdxPopupComponent, IdxDataGridComponent } from '../../interfaces/dx-components';
import { ScopeContainer, RestService } from '../../../framework/base/export';
import { SimpleWidgetCreatorService, ModelEventService, ICommandData } from '../../../framework/forms/export';

@autoinject
export class SchnupperterminPersonAuswahl {
  private _callback: { (personList: { id: number, name: string }[]): void };

  constructor(
    private restService: RestService,
    private simpleWidgetCreatorService: SimpleWidgetCreatorService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;

  model: IModel;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    height: "auto",
    width: "750px"
  };
  nameOptions: DevExpress.ui.dxTextBoxOptions = {
    bindingOptions: {
      value: "model.name"
    },
    onValueChangedByUser: () => this.loadPerson()
  };
  klasseOptions: DevExpress.ui.dxSelectBoxOptions = {
    searchEnabled: true,
    showClearButton: true,
    valueExpr: "Id",
    displayExpr: "Bezeichnung",
    bindingOptions: {
      dataSource: "model.klasseList",
      value: "model.idKlasse"
    },
    onValueChangedByUser: () => this.loadPerson()
  };
  personenGrid: IdxDataGridComponent;
  personenGridOptions: DevExpress.ui.dxDataGridOptions = {
    columns: [{
      dataField: "Vorname",
      caption: "Vorname"
    }, {
      dataField: "Nachname",
      caption: "Nachname"
    }],
    keyExpr: "Id",
    selection: {
      mode: "multiple",
      showCheckBoxesMode: "always"
    },
    height: "400px",
    scrolling: {
      mode: "virtual"
    },
    bindingOptions: {
      dataSource: "model.personList"
    }
  };

  uebernehmenCommandData: ICommandData = {
    id: "uebernehmenCommandData",
    icon: "fas fa-check",
    sortIndex: 1000,
    tooltip: "schnuppertermin.markierte-personen-uebernehmen",
    execute: () => {
      const data = this.personenGrid.instance.getSelectedRowsData();
      if (data.length == 0) {
        DevExpress.ui.notify("Es wurde keine Person markiert", "ERROR", 3000);
        return;
      }

      this._callback(data.map(i => {
        return { id: i.Id, name: i.Name };
      }));

      this.popup.instance.hide();
    }
  };

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: null
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "schnupperterminPersonAuswahlToolbar",
      caption: "schnuppertermin.person-auswahl",
      scopeContainer: this.scopeContainer,
      options: this.popupOptions,
      commands: [this.uebernehmenCommandData]
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async show(callback: { (personList: { id: number, name: string }[]): void }) {
    this._callback = callback;

    this.resetModel();
    await this.loadKlasse();

    this.popup.instance.show();
  }

  private async loadKlasse() {
    this.model.klasseList = await this.restService.get({
      url: this.restService.getApiUrl("ZIT/Schnuppertermin/Klasse"),
      increaseLoadingCount: true
    });
  }
  private async loadPerson() {
    this.model.personList = await this.restService.post({
      url: this.restService.getApiUrl("ZIT/Schnuppertermin/PersonFilter"),
      increaseLoadingCount: true,
      data: {
        IdKlasse: this.model.idKlasse,
        Name: this.model.name
      }
    });
  }
  private resetModel() {
    this.model = {
      klasseList: [],
      personList: []
    };
  }
}

interface IModel {
  name?: string;
  idKlasse?: number;

  klasseList: any[];
  personList: any[];
}