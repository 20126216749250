import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class TerminListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "termin-list";
        this.title = "termin-list.termin-list_caption";
        this.addModel({
            "id": "$m_Termin",
            "webApiAction": "ZIT/Objekte/Termin",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'Geschaeftspartner': {
                            'expand': {
                                'Geschaeftspartnerdaten': null
                            }
                        }
                    }
                }
            },
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_terminGeschaeftspartner",
                "caption": "termin-list.r_termingeschaeftspartner_caption",
                "bindTo": "Kopf.Geschaeftspartner.Geschaeftspartnerdaten.Name1",
                "minWidth": 100
            }, {
                "id": "r_terminBetreff",
                "caption": "termin-list.r_terminbetreff_caption",
                "bindTo": "Kopf.Betreff",
                "minWidth": 100
            }, {
                "id": "r_terminStart",
                "caption": "termin-list.r_terminstart_caption",
                "bindTo": "Kopf.StartDatum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_terminEnde",
                "caption": "termin-list.r_terminende_caption",
                "bindTo": "Kopf.EndeDatum",
                "width": "90px",
                "format": "d"
            }],
            "optionsToolbar": {
                "optionsName": "r_terminGridToolbarOptions",
                "optionsNameFQ": "r_terminGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Termin"
            },
            "dataModel": "$m_Termin",
            "editUrl": "Objekte/Termin",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_terminGrid",
            "options": {
                "optionsName": "r_terminGridOptions",
                "optionsNameFQ": "r_terminGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}