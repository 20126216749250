import { Geschlecht } from '../../zit/enumerations/export';

export class ZitGeschlechtValueConverter {
  toView(typ: Geschlecht): string {
    if (typ == void 0) {
      return "";
    }

    switch (typ) {
      case Geschlecht.Maennlich: {
        return "Herr";
      }
      case Geschlecht.Weiblich: {
        return "Frau";
      }
      default: {
        return "";
      }
    }
  }
}