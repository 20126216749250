import { autoinject } from 'aurelia-framework';
import { RestService } from './../../framework/base/services/export';
import { IMultiMarkerData } from '../interfaces/export';

@autoinject
export class MarkerService {
  constructor(
    private rest: RestService
  ) { }

  deleteObjektMarkerZuord(id: number) {
    return this.rest.delete({
      url: this.rest.getWebApiUrl("ZIT/Objekte/ObjektMarkerZuord"),
      id: id,
      increaseLoadingCount: true
    });
  }
  getObjektZugeordnetMarkers(idObjekt: number): Promise<any[]> {
    return this.rest.get({
      url: this.rest.getWebApiUrl("ZIT/Objekte/ObjektMarkerZuord"),
      getOptions: {
        where: [["IdObjekt", idObjekt]]
      }
    });
  }

  saveObjektMarker(data: { _IdObjekt: number, Bezeichnung: string, Farbe: string }) {
    return this.rest.put({
      url: this.rest.getWebApiUrl("ZIT/Stammdaten/ObjektMarker"),
      data: data,
      increaseLoadingCount: true
    });
  }
  saveObjektMarkerZuord(data: { IdObjektMarker: number, IdObjekt: number }) {
    return this.rest.post({
      url: this.rest.getWebApiUrl("ZIT/Objekte/ObjektMarkerZuord"),
      data: data
    });
  }
  saveObjektMultiMarker(data: { FullName: string, IdObjektList: number[], ObjektMarkerHelperList: any[] }) {
    return this.rest.post({
      url: this.rest.getApiUrl("ZIT/Stammdaten/SaveObjektMultiMarker"),
      data: data
    })
  }
  getMarkersByObjektFullName(objektFullName: string): Promise<IMultiMarkerData[]> {
    return this.rest.get({
      url: `${this.rest.getApiUrl("ZIT/Stammdaten/ObjektMultiMarkerDataSource")}?fullName=${objektFullName}`
    })
  }
}