import { autoinject } from 'aurelia-framework';
import { Setting } from '../interfaces/setting';

@autoinject
export class SettingService {
  constructor() {
    this.instance = {
      IsLabelBeforeEditor: true
    };
  }

  instance: Setting;
}
