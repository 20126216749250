import { autoinject, computedFrom, observable, transient, TaskQueue } from "aurelia-framework";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { GlobalizationService, LocationService } from "../../../framework/base/export";
import { FormBase } from "../../../framework/forms/classes/form-base";
import { ListView } from "../../../framework/forms/elements/list-view/export";
import { ICommandData, IListViewOptions, SelectionModeEnum } from "../../../framework/forms/export";
import { SucheLayoutAuswahl } from "./../../elements/suche-layout-auswahl/suche-layout-auswahl";
import { CustomEditPopupService, SucheService, ObjektInfoService } from "../../services/export";
import { SucheViewTyp, BenutzerTyp } from "../../enumerations/export";
import { IdxDataGridComponent } from "../../interfaces/export";
import { IZitCardOptions, ZitCardTyp } from "../../../zit-ui/elements/zit-card/export";
import { IItemExecuteOptions } from "../../../framework/forms/elements/toolbar/export";
import { Share } from "../../elements/share/share";
import { BerechtigungService } from "../../services/berechtigung-service";
import { StartupService } from "../../services/startup-service";
import { BrowserService } from "../../../framework/base/services/browser-service";
import { ErklaervideoService } from "../../services/erklaervideo-service";

@autoinject
@transient()
export class SucheUtils {
  private _searchSubscription: Subscription;
  private _objektStatusChangedSubscription: Subscription;
  private _lastObjektBezeichnung: string;

  constructor(
    private customEditPopup: CustomEditPopupService,
    private eventAggregator: EventAggregator,
    private location: LocationService,
    private objektInfo: ObjektInfoService,
    private suche: SucheService,
    private berechtigung: BerechtigungService,
    private startup: StartupService,
    private taskQueue: TaskQueue,
    private browser: BrowserService,
    private erklaervideoService: ErklaervideoService,
    private globalizationService: GlobalizationService,
    private browserService: BrowserService
  ) { }

  form: FormBase;

  defaultColumns: DevExpress.ui.dxDataGridColumn[] = [{
    dataField: "ObjektBezeichnung",
    name: "r_sucheObjekttyp",
    minWidth: 100,
    caption: "Objekttyp"
  }, {
    dataField: "Bezeichnung",
    name: "r_sucheBezeichnung",
    minWidth: 100,
    caption: "Bezeichnung"
  }, {
    dataField: "Anlagedatum",
    name: "r_sucheAnlagedatum",
    width: "120px",
    caption: "erstellt am",
    format: this.globalizationService.getFormatterParser("g")
  }];
  additionalColumns: string[] = [];
  searchText: string;
  wertQueryList: any[];
  searchStandardFilter: string = "true";
  erklaervideoCommand: ICommandData;

  createNewCommand: ICommandData = {
    icon: "fas fa-plus",
    id: "sucheCreateNew",
    tooltip: "base.add",
    sortIndex: 1100,
    isVisibleExpression: "functions.$f_SucheUtils.canCreateNew",
    execute: (event: any) => {
      const urlToNavigate = this.objektInfo.getObjektNavigationUrl(this.objektFullName);
      if (!urlToNavigate) {
        return;
      }

      this.location.goTo({
        url: `${urlToNavigate}/0`,
        currentViewModel: this.form
      });
    }
  };
  actionCommand: ICommandData = {
    icon: "fas fa-share-alt",
    id: "sucheExportSelectedItems",
    tooltip: "zit.aktionen",
    isVisibleExpression: "r_share.canShare",
    sortIndex: 1200,
    execute: (args: IItemExecuteOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(args.event);
    }
  };
  columnsCommand: ICommandData = {
    icon: "fas fa-columns",
    id: "sucheColumns",
    tooltip: "suche-form.spaltenauswahl",
    sortIndex: 1300,
    isEnabledExpression: "functions.$f_SucheUtils.viewTyp == 'table' && functions.$f_SucheUtils.objektFullName",
    execute: (args: IItemExecuteOptions) => {
      if (!this.objektFullName || this.viewTyp != SucheViewTyp.table) {
        return;
      }
      const layoutAuswahl: SucheLayoutAuswahl = this.form["r_layout_auswahl"];
      layoutAuswahl.showCtxMenu(args.event);
    },
    isVisibleExpression: "functions.$f_SucheUtils.isTableViewVisible"
  };
  activateListView: ICommandData = {
    icon: "fas fa-th-list",
    id: "sucheListViewCommand",
    tooltip: "suche-form.show-list-view",
    sortIndex: 1400,
    isEnabledExpression: "functions.$f_SucheUtils.viewTyp == 'table'",
    execute: (event: any) => {
      this.viewTyp = SucheViewTyp.list;
    },
    isVisibleExpression: "functions.$f_SucheUtils.isTableViewVisible"
  };
  activateTableView: ICommandData = {
    icon: "fas fa-table",
    id: "sucheTableViewCommand",
    tooltip: "suche-form.show-table-view",
    sortIndex: 1401,
    isEnabledExpression: "functions.$f_SucheUtils.viewTyp == 'list'",
    execute: (event: any) => {
      this.viewTyp = SucheViewTyp.table;
    },
    isVisibleExpression: "functions.$f_SucheUtils.isTableViewVisible"
  };
  activateStandardfilter: ICommandData = {
    id: "sucheStandardfilterCommand",
    title: "suche-form.show-standardfilter",
    classNameExpression: "functions.$f_SucheUtils.searchStandardFilter == 'true' ? 'z--button-active' : ''",
    isVisible: false,
    sortIndex: 1001,
    execute: () => {
      this.searchStandardFilter = this.searchStandardFilter == "true" ? "false" : "true";
      this.loadSearchParamFromSearchService();
    }
  };

  sucheCardOptions: IZitCardOptions = {
    typ: ZitCardTyp.Kompakt,
    isSuche: true,
    statusColorExpression: "item.Data.ObjektStatusSymbolFarbe",
    markersCardOptions: {
      markersListExpression: "item.Data.ObjektMarkerZuordnungen",
      onClick: (e, data) => {
        this.customEditPopup.markersAuswaehlen.show(
          {
            mappings: {
              "$v_idObjekt": data.Id
            },
            closeCallback: () => {
              this.form["r_share"].reloadData();
            }
          }
        );
      }
    }
  };

  @observable({ changeHandler: "addGridColumnsToModel" }) viewTyp: SucheViewTyp = SucheViewTyp.list;

  @computedFrom("suche.lastObjektFullName")
  get objektFullName() {
    return this.suche.lastObjektFullName;
  }
  @computedFrom("suche.lastObjektBezeichnung")
  get sucheTitelSuffix() {
    if (this.form
      && this.form.scopeContainer
      && this.form.scopeContainer.scope
      && this._lastObjektBezeichnung != this.suche.lastObjektBezeichnung) {
      this._lastObjektBezeichnung = this.suche.lastObjektBezeichnung;

      this.eventAggregator.publish("form:title-changed", {
        form: this.form
      });
    }

    if (this.suche.lastObjektBezeichnung) {
      return `: ${this.suche.lastObjektBezeichnung}`;
    } else {
      return "";
    }
  }
  @computedFrom("objektFullName")
  get canCreateNew() {
    const benutzertyp = this.startup.startupInfo.Benutzer.Benutzertyp;

    return this.objektFullName != void 0
      && this.objektFullName != "TIP.ZIT.Business.Entities.Objekte.Aufgaben.Aufgabe"
      && (benutzertyp != BenutzerTyp.NatuerlichePerson || this.objektFullName == "TIP.ZIT.Business.Entities.Objekte.Ziele.Ziel")
      && this.berechtigung.hasBerechtigungNew(this.objektFullName);
  }

  get isTableViewVisible() {
    return !this.browser.isMobile;
  }

  bind(form: FormBase) {
    this.form = form;

    const viewTyp = form.viewItemInfo?.routeInfo?.customOptions?.viewTyp;
    if (viewTyp != void 0) {
      this.viewTyp = viewTyp;
    }

    form.callOnBind(() => {
      if (this.browserService.isMobile) {
        const listViewOptions = <IListViewOptions>form["r_sucheListViewOptions"];
        listViewOptions.selectionMode = SelectionModeEnum.None;
      }
    });

    this.form.models.getInfo("$m_Suche").canLoad = (options) => {
      switch (this.viewTyp) {
        case SucheViewTyp.list: {
          return this.form["r_sucheListViewOptions"] == options;
        }
        case SucheViewTyp.table: {
          return this.form["r_sucheGridOptions"] == options;
        }
        default: {
          return true;
        }
      }
    };

    form.models.onLoaded.register((args) => {
      if (args.model.id === "$m_Suche" && args.data) {
        if (this.browser.isMobile && this.searchStandardFilter === "true") {
          this.searchStandardFilter = "false";
        }

        this.checkFilterListVorhanden();
      }
      return Promise.resolve();
    });

    this.erklaervideoCommand = this.erklaervideoService.getErklaervideoCommandData();
    this.erklaervideoCommand.isVisible = false;
    this.erklaervideoCommand.execute = (e) => {
      this.erklaervideoService.element.show(
        "suche;".concat(this.objektFullName),
        e.event.target
      );
    };
    form.commands.addCommand(this.erklaervideoCommand);

    this.loadSearchParamFromSearchService();
    this.addGridColumnsToModel();

    this._searchSubscription = this.eventAggregator.subscribe(
      "suche:request", 
      () => {
        this.checkFilterListVorhanden();
        this.setStandardfilter();
        this.loadSearchParamFromSearchService();

        this.erklaervideoCommand.isVisible = this.erklaervideoService.getContextMenuItems(
          "suche;".concat(this.objektFullName)
        ).length > 0;
    });

      this._objektStatusChangedSubscription = this.eventAggregator.subscribe(
        "objekt-status:changed",
        (e) => {
          this.reloadIfExists(e.idObjektList);
        }
      );
  }
  unbind() {
    this._searchSubscription.dispose();
    this._objektStatusChangedSubscription.dispose();
  }

  loadSearchParamFromSearchService() {
    const isEqual = this.form.variables.data.$v_searchText == this.suche.lastSearchText
      && JSON.stringify(this.wertQueryList || []) == JSON.stringify(this.suche.lastWertQueryList || []);

    if (!isEqual) {
      this.form.variables.data.$v_searchText = this.suche.lastSearchText;
      this.wertQueryList = this.suche.lastWertQueryList;
    }

    this.taskQueue.queueTask(() => {
      const listView: ListView = this.form["r_sucheListView"];
      if (listView) {
        listView.clearSelection();

        if (isEqual) {
          listView.refresh(false);
        }
      }

      const grid: IdxDataGridComponent = this.form["r_sucheGrid"];
      if (grid && grid.instance) {
        grid.instance.clearSelection();

        if (isEqual) {
          grid.instance.refresh();
        }
      }
    });
  }
  setStandardfilter() {
    const objektTypeName = this.suche.getObjektFullName(this.suche.lastObjektFullName);
    this.searchStandardFilter = !!objektTypeName ? "true" : "";
  }
  checkFilterListVorhanden() {
    if (this.browser.isMobile) {
      return;
    }

    if (this.startup.startupInfo.SucheStandardFilterList && this.startup.startupInfo.SucheStandardFilterList.length > 0) {
      const filterList = this.startup.startupInfo.SucheStandardFilterList.filter((c) => c == this.objektFullName);
      if (filterList && filterList.length > 0) {
        this.activateStandardfilter.isVisible = true;
        return;
      }
    }

    this.activateStandardfilter.isVisible = false;
  }
  onItemClicked(e: any) {
    const objekt = this.objektInfo.getObjektByFullname(e.data.TypeName);
    if (!objekt) {
      return;
    }
    const url = this.objektInfo.getObjektNavigationUrl(objekt.Fullname);
    if (!url) {
      return;
    }

    this.location.goTo({
      url: `${url}/${e.data.Id}`,
      currentViewModel: this.form
    });
  }

  private addGridColumnsToModel() {
    if (!this.form || !this.form.models) {
      return;
    }
    const model = this.form.models.getInfo("$m_Suche");

    if (!model) {
      return;
    }

    model.webApiColumns = this.viewTyp == SucheViewTyp.table
      ? ["Id", "ObjektBezeichnung", "Bezeichnung", "Url", "Anlagedatum", "TypeName"]
      : null;
  }
  private reloadIfExists(idObjektList: number[]) {
    if (!idObjektList) {
      return;
    }

    const check = (i) => {
      if (!i) {
        return;
      }
      if (!Array.isArray(i)) {
        return;
      }

      const exists = i.some(e => idObjektList.indexOf(e["Id"]) >= 0);
      if (!exists) {
        return;
      }

      this.form["r_share"].reloadData();
    };

    switch (this.viewTyp) {
      case SucheViewTyp.list: {
        const listView: ListView = this.form["r_sucheListView"];
        const items = listView.itemsOptions.items;
        check(items);
        break;
      }
      case SucheViewTyp.table: {
        const grid: IdxDataGridComponent = this.form["r_sucheGrid"];
        const items = grid.instance.getDataSource().items();
        check(items);
        break;
      }
      default: {
        break;
      }
    }
  }
}
