import { autoinject } from 'aurelia-framework';
import { RestService } from './../../framework/base/services/export';
import { IStatusChangeData,IStatusChangeResultData } from '../interfaces/export';
import { JsonService } from '../../framework/base/services/export';

@autoinject
export class StatusService {
  constructor(
    private json: JsonService,
    private rest: RestService
  ) { }

  allowUpdateObjektStatus(keys: number[]): Promise<any> {
    if (!keys || !keys.length) {
      return;
    }

    return this.rest.post({
      url: this.rest.getApiUrl("ZIT/Objekt/CanUpdateObjektStatus"),
      data: this.json.stringify(keys),
      increaseLoadingCount: true
    });
  }

  changeObjektStatus(data: IStatusChangeData): Promise<IStatusChangeResultData> {
    if(!data){
      return;
    }
    return this.rest.post({
      url: this.rest.getApiUrl("ZIT/Objekt/ChangeObjektStatus"),
      data: this.json.stringify(data),
      increaseLoadingCount: true
    });
  }

  loadBenutzerAvailableObjektStatus(keys: number[]): Promise<any> {
    if (!keys || !keys.length) {
      return;
    }

    return this.rest.post({
      url: this.rest.getApiUrl("ZIT/Objekt/ObjektStatusFolgeStatus"),
      data: keys,
      increaseLoadingCount: true
    });
  }

  async loadStatusHistorie(keys: number[]): Promise<any> {
    if (!keys || keys.length != 1) {
      return [];
    }

    return await this.rest.post({
      url: this.rest.getApiUrl("ZIT/Objekt/ObjektStatusHistorie"),
      data: {
        IdObjektStatusElement: keys[0]
      },
      increaseLoadingCount: true
    });
  }

  async getObjektStatusSammlungTypList(idObjekttyp: number): Promise<IObjektStatusSammlungTyp[]> {
    return await this.rest.post({
      url: this.rest.getApiUrl("ZIT/Objekt/ObjektStatusSammlungTyp"),
      data: {
        IdObjekttyp: idObjekttyp
      },
      increaseLoadingCount: true
    });
  }
  async getObjektStatusByObjektStatusSammlung(objektFullName: string, objektStatusSammlungTyp: ObjektStatusSammlungTyp) {
    return await this.rest.get({
      url: this.rest.getWebApiUrl("ZIT/Stammdaten/ObjektStatus"),
      getOptions: {
        where: [
          ["Objekttyp.Fullname", objektFullName],
          ["ObjektStatusSammlungen", ["ObjektStatusSammlung.Typ", objektStatusSammlungTyp]]
        ]
      }
    });
  }
}

export interface IObjektStatusSammlungTyp {
  Id: number;
  Bezeichnung: string;
}
export enum ObjektStatusSammlungTyp {
  EventTeilnehmerZugesagt = 7,
  EventTeilnehmerAbgesagt = 8,
  EventTeilnehmerOffen = 9
}