import { autoinject, computedFrom } from "aurelia-framework";
import { LocalizationService, ScopeContainer } from '../../../framework/base/export';
import { DialogConfirmService, ICommandData, SimpleWidgetCreatorService } from '../../../framework/forms/export';
import { IZitTagOptions } from '../../../zit-ui/elements/zit-tag/zit-tag-options';
import { IdxPopupComponent } from '../../interfaces/export';
import { AufgabeService, IAufgabeZuweisenOptions, IZuweisenEvalResult } from '../../services/aufgabe-service';
import { FormPopupService } from '../../services/form-popup-service';
import { AufgabeZuweisenStart } from './aufgabe-zuweisen-start';

@autoinject
export class AufgabeZuweisen {
  private _options: IAufgabeZuweisenOptions;

  constructor(
    private _aufgabeService: AufgabeService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _localizationService: LocalizationService,
    private _dialogConfirmService: DialogConfirmService,
    private _formPopupService: FormPopupService
  ) {
    this._aufgabeService.aufgabeZuweisen = this;
  }

  scopeContainer: ScopeContainer;
  model: IModel;
  aufgabeArtZuweisen: any = 0;
  aufgabeZuweisenStartpopup: AufgabeZuweisenStart;
  loadedData: IZuweisenEvalResult;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "600px",
    height: "530px"
  };

  aufgabeArtRadioGroupOptions: DevExpress.ui.dxRadioGroupOptions = {
    layout: "horizontal",
    displayExpr: "text",
    valueExpr: "value",
    dataSource: [
      { text: "Arbeitsgruppe zuweisen", value: 0 },
      { text: "Aufgabenset zuweisen", value: 1 }
    ],
    bindingOptions: {
      value: "aufgabeArtZuweisen"
    },
    onValueChangedByUser: (e) => {
      this.load();
    }
  }

  aufgabeSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Code",
    displayExpr: "Bezeichnung",
    searchEnabled: true,
    searchExpr: "Bezeichnung",
    bindingOptions: {
      dataSource: "model.aufgabeDataSource",
      value: "model.codeAufgabe"
    }
  };

  objektTagOptions: IZitTagOptions = {
    icon: {
      textExpression: "item.Typ"
    },
    textExpression: "item.Bezeichnung",
    onDeleteClick: (e, data) => {
      e.stopPropagation();

      const index = this.model.objektList.indexOf(data);
      if (index < 0) {
        return;
      }

      this.model.objektList.splice(index, 1);
    }
  };

  aufgabeZuweisenCommand: ICommandData = {
    id: "save",
    icon: "fas fa-save",
    tooltip: "base.save",
    execute: () => {
      this.save();
    }
  };

  @computedFrom("aufgabeArtZuweisen")
  get getAufgabeTypname(): string {
    if (this.aufgabeArtZuweisen == 0) {
      return "Aufgabengruppe";
    }
    return "Aufgabenset";
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "aufgabeZuweisenPopupToolbar",
      caption: "aufgabe-zuweisen.popup-titel",
      options: this.popupOptions,
      commands: [this.aufgabeZuweisenCommand],
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  show(options: IAufgabeZuweisenOptions) {
    this.model = {
      codeAufgabe: null,
      aufgabeDataSource: [],
      objektList: []
    }

    this._options = options;
    this.popup.instance.show();

    this.load();
  }

  private async load() {
    this.loadedData = await this._aufgabeService.zuweisenEval({
      IdObjektList: this._options.idObjektList
    });

    if (this.aufgabeArtZuweisen == 0) {
      this.model.aufgabeDataSource = this.loadedData.AufgabeGruppeList;
    } else {
      this.model.aufgabeDataSource = this.loadedData.AufgabeList;
    }

    this.model.objektList = this.loadedData.ObjektList;
  }
  private async save() {
    if (!this.model.codeAufgabe) {
      DevExpress.ui.notify("Aufgabenset oder Aufgabengruppe ist ein Pflichtfeld", "error", 3000);
      return;
    }

    let aufgabenSetList = [];
    if (this.aufgabeArtZuweisen == 0) {
      const aufgabensetsFromGruppe = this.getAufgabenSetByGruppeCode(this.model.codeAufgabe);

      aufgabensetsFromGruppe.forEach(aufgabenset => {
        aufgabenSetList.push({
          CodeAufgabenSet: aufgabenset.Code,
          AufgabensetBezeichnung: aufgabenset.Bezeichnung
        });
      });
    } else {
      const bezeichnung = this.getAufgabenSetBezeichnungByCode(this.model.codeAufgabe);
      aufgabenSetList.push({
        CodeAufgabenSet: this.model.codeAufgabe,
        AufgabensetBezeichnung: bezeichnung
      });
    }

    this.aufgabeZuweisenStartpopup.show({
      AufgabensetList: aufgabenSetList,
      callback: async (options) => {
        let aufgabeCodeList = [];
        options.forEach(element => {
          aufgabeCodeList.push(element.AufgabeCode);
        });
        const checkResult = await this._aufgabeService.checkZuweisen({
          AufgabeCodeList: aufgabeCodeList,
          ObjektList: this.model.objektList
        });

        const objektList = [];
        for (let objekt of checkResult.ObjektList) {
          if (objekt.Exists) {
            const confirmResult = await this._dialogConfirmService.show({
              title: "Frage",
              message: `${objekt.Bezeichnung} hat die Zuteilung bereits. Trotzdem erstellen?`
            });

            if (!confirmResult) {
              continue;
            }
          }

          const evalBestResult = await this.evalBestaetigung(objekt);
          if (!evalBestResult) {
            return;
          }

          objektList.push(objekt);
        }

        if (objektList.length == 0) {
          return;
        }

        await this._aufgabeService.zuweisen({
          AufgabeList: options,
          ObjektList: objektList
        });

        DevExpress.ui.notify(
          this._localizationService.translateOnce("aufgabe-zuweisen.erstellt"),
          "success",
          3000
        );
        this.popup.instance.hide();
      }
    });
  }
  private getAufgabenSetByGruppeCode(aufgabenGruppeCode: string) {
    const aufgabensets = this.loadedData.AufgabeList.filter(c => {
      return c.IdAufgabengruppe.toString() == aufgabenGruppeCode;
    });
    return aufgabensets;
  }

  private getAufgabenSetBezeichnungByCode(aufgabensetCode: string) {
    const aufgabenset = this.loadedData.AufgabeList.filter(c => {
      return c.Code == aufgabensetCode;
    })[0] || null;

    if (!aufgabenset) {
      return;
    }
    return aufgabenset.Bezeichnung;
  }
  private async evalBestaetigung(objekt: any) {
    if (!objekt.AufgabeBestList) {
      return true;
    }

    for (let aufgabe of objekt.AufgabeBestList) {
      if (aufgabe.IdAnsprechperson) {
        continue;
      }

      const evalBestaetigung = await this.evalBestaetigungAufgabe(objekt, aufgabe);
      if (!evalBestaetigung) {
        return false;
      }
    }

    return true;
  }
  private async evalBestaetigungAufgabe(objekt: any, aufgabe: any) {
    return new Promise((resolve) => {
      this._formPopupService.formPopup.show({
        titel: "Bestätigungsbenutzer auswählen",
        formDef: <DevExpress.ui.dxFormSimpleItem[]>[{
          template: (data, element) => {
            const el = document.createElement("div");
            el.innerHTML = `Bestätigungsbenutzer für die Aufgabe <b>${aufgabe.Bezeichnung}</b> der Person <b>${objekt.Bezeichnung}</b> auswählen`;

            element.appendChild(el);
          }
        }, {
          dataField: "idAnsprechperson",
          editorType: "dxSelectBox",
          label: { text: "Bestätigungsbenutzer" },
          isRequired: true,
          editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
            valueExpr: "Id",
            displayExpr: "Titel",
            searchExpr: "Titel",
            searchEnabled: true,
            showClearButton: true,
            width: "100%",
            dataSource: aufgabe.AnsprechpersonList
          }
        }],
        width: "450px",
        onFormSubmit: (m) => {
          aufgabe.IdAnsprechperson = m.idAnsprechperson;
          resolve(true);
        },
        onFormCancel: () => {
          resolve(false);
        }
      });
    });
  }
}

interface IModel {
  codeAufgabe: string;
  aufgabeDataSource: any[];
  objektList: any[];
}
