import { autoinject, observable, bindable, PLATFORM } from "aurelia-framework";
import { IdxPopupComponent, IdxValidationGroupComponent, IErweiterteSucheObjektFeldData } from "../../interfaces/export";
import { SimpleWidgetCreatorService, ICommandData, ContextMenu, DialogConfirmService } from "../../../framework/forms/export";
import { ScopeContainer, JsonService, LocalizationService } from "../../../framework/base/export";
import { ObjektInfoService } from "../../services/objekt-info-service";
import { ErweiterteSucheService, LoginprofilEinstellungenService } from "../../services/export";
import { ObjektExportEigenschaftDatenTyp } from "../../enumerations/objekt-export-eigenschaft-daten-typ";
import { ErklaervideoService } from "../../services/erklaervideo-service";
import { ILoginprofilEinstellung, LoginprofilEinstellungTyp } from "../../services/loginprofil-einstellungen-service";

@autoinject
export class ErweiterteSuche {
  private _onLoadDataPromise: Promise<any>;

  constructor(
    private element: Element,
    private simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private objektInfoService: ObjektInfoService,
    private erweiterteSucheService: ErweiterteSucheService,
    private jsonService: JsonService,
    private loginprofilEinstellungenService: LoginprofilEinstellungenService,
    private localizationService: LocalizationService,
    private dialogConfirmService: DialogConfirmService,
    private erklaervideoService: ErklaervideoService
  ) { }

  @bindable @observable objektFullName: string;
  
  sucheTitel = "";
  idVorlage: number;
  vorlageBezNeu: string;
  idVorlageErsetzen: number;
  filterList: IErweiterteSucheObjektFeldData[] = [];
  selectedFilterList: ISelectedFilter[] = [];
  vorlageList: ILoginprofilEinstellung[] = [];
  //Wegen altem Code, damit diese in diesem Schritt nicht umgebaut werden muss
  wertQueryList: any[];

  scopeContainer: ScopeContainer;

  erweiterteSuchePopup: IdxPopupComponent;
  erweiterteSuchePopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "450px",
    maxHeight: "80%",
    maxWidth: "750px"
  };
  vorlageSpeichernPopup: IdxPopupComponent;
  vorlageSpeichernPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxHeight: "80%",
    maxWidth: "600px"
  };
  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {
  };
  vorlageValidationGroup: IdxValidationGroupComponent;
  vorlageValidationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {
  };
  vorlageSpeichernTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    bindingOptions: {
      value: "vorlageBezNeu"
    }
  };
  vorlageErsetzenSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Bezeichnung",
    showClearButton: true,
    bindingOptions: {
      dataSource: "vorlageList",
      value: "idVorlageErsetzen"
    }
  };

  popupCommands: ICommandData[] = [{
    id: "vorlageWaehlen",
    idCategory: "$close",
    icon: "fas fa-caret-down",
    sortIndex: 1499,
    title: "erweiterte-suche.vorlage-laden",
    isVisibleExpression: "vorlageList.length > 0",
    className: "erweiterte-suche-vorlage-waehlen",
    execute: (e) => {
      this.showVorlageContextMenu(e.event);
    }
  }, {
      id: "clearErweiterteSuche",
      idCategory: "$close",
      icon: "fas fa-eraser",
      sortIndex: 1500,
      tooltip: "erweiterte-suche.suche-zuruecksetzen",
      isEnabledExpression: "queryItemList.length > 0",
      execute: () => {
        this.clearData();
        this.executeSuche();
        this.erweiterteSuchePopup.instance.hide();
      }
    }, {
      id: "doErweiterteSuche",
      idCategory: "$close",
      icon: "fas fa-search",
      sortIndex: 1600,
      tooltip: "erweiterte-suche.suchen",
      isEnabled: true,
      execute: () => {
        this.executeSuche();
        this.erweiterteSuchePopup.instance.hide();
      }
    }
  ];
  vorlagePopupCommands: ICommandData[] = [{
      id: "clearErweiterteSuche",
      idCategory: "$close",
      icon: "fas fa-save",
      sortIndex: 1500,
      tooltip: "erweiterte-suche.vorlage-speichern",
      execute: () => {
        const isValid = this.vorlageValidationGroup.instance.validate().isValid;
        if (!isValid) {
          return;
        }

        this.doSaveVorlage();
        this.vorlageSpeichernPopup.instance.hide();
      }
    }
  ];

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.erklaervideoService.attachedToolbarButton(
      "zit/elements/erweiterte-suche/erweiterte-suche",
      (i) => this.popupCommands.push(i)
    );

    this.simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "erweiterteSuchePopupToolbar",
      caption: "erweiterte-suche.popup-titel",
      options: this.erweiterteSuchePopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
    this.simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "vorlagePopupToolbar",
      caption: "erweiterte-suche.vorlage-speichern",
      options: this.vorlageSpeichernPopupOptions,
      commands: this.vorlagePopupCommands,
      scopeContainer: this.scopeContainer
    });

    this.vorlageSpeichernTextBoxOptions["validators"] = [{
      type: "required",
      message: this.localizationService.translateOnce("erweiterte-suche.bezeichnung-pflichtfeld")
    }];

    if (this.objektFullName) {
      this.loadData();
    }
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async showPopup() {
    this.idVorlage = null;

    this.setTitel();
    this.loadData();

    this.erweiterteSuchePopup.instance.show();
  }

  clearSuche() {
    this.selectedFilterList.splice(0, this.selectedFilterList.length);
    this.wertQueryList = null;
  }
  executeSuche() {
    this.setWertQueryList();

    const event = new CustomEvent("on-execute-suche", {
      detail: {
        sender: this,
        wertQueryList: this.wertQueryList
      },
      bubbles: true
    });

    this.element.dispatchEvent(event);
  }  
  async loadData() {
    this._onLoadDataPromise = new Promise(async(resolve) => {
      await this.loadFelder();
      await this.loadVorlagen();

      resolve();
      this._onLoadDataPromise = null;
    });
  }
  loadFilter(wertQueryList: any[], executeSuche: boolean = false) {
    if (this._onLoadDataPromise != null) {
      this._onLoadDataPromise.then(() => {
        this.loadFilter(wertQueryList, executeSuche);
      });
      return;
    }

    wertQueryList = wertQueryList || [];
    this.clearData();

    for (const f of wertQueryList) {
      const filter = this.filterList.find((r) => r.Id == f.Id);
      if (!filter) {
        continue;
      }

      this.addFeld(filter, f.Operator, f.Value, true);
    }

    if (executeSuche) {
      this.executeSuche();
    } else {
      this.setWertQueryList();
    }
  }
  showVorlageContextMenu(e: Event, executeSucheAfterSelect: boolean = false) {
    const ctxMenu = new ContextMenu();

    this.vorlageList.forEach((v) => {
      ctxMenu.items.push({
        text: v.Bezeichnung,
        execute: () => {
          this.useVorlage(v.Id);

          if (executeSucheAfterSelect) {
            this.executeSuche();
          }
        }
      });
    });
    
    ctxMenu.show(this.getZButton(<any>e.target));
  }

  async onSaveVorlageClicked() {
    const filter = this.getCheckSelectedFilterList();
    if (filter.length == 0) {
      DevExpress.ui.notify(this.localizationService.translateOnce("erweiterte-suche.keine-filter-zum-speichern"), "error", 3000);
      return;
    }

    this.idVorlageErsetzen = this.idVorlage;

    if (this.idVorlage) {
      const vorlage = this.vorlageList.find((v) => v.Id == this.idVorlage);

      this.vorlageBezNeu = vorlage
        ? vorlage.Bezeichnung
        : null;
    } else {
      this.vorlageBezNeu = null;
    }

    this.vorlageSpeichernPopup.instance.show();
  }
  async onDeleteVorlageClicked() {
    if (!this.idVorlage) {
      return;
    }

    const message = this.localizationService.translateOnce("erweiterte-suche.delete-vorlage-confirmation-message");
    const title = this.localizationService.translateOnce("erweiterte-suche.delete-vorlage-confirmation-title");

    const dialogResult = await this.dialogConfirmService.show({
      title: title,
      message: message
    });
    
    if (!dialogResult) {
      return;
    }

    this.doDeleteVorlage();
  }
  onAddFeldClicked(e: Event) {
    const ctxMenu = new ContextMenu();
    this.filterList.forEach((filter) => {
      ctxMenu.items.push({
        text: filter.Bezeichnung,
        execute: () => {
          this.addFeld(filter, null, null, false, true);
        }
      });
    });

    ctxMenu.show(this.getZButton(<Element>e.target));
  }
  onRemoveFeldClicked(filter: ISelectedFilter) {
    const indexOf = this.selectedFilterList.indexOf(filter);
    if (indexOf < 0) {
      return;
    }

    this.selectedFilterList.splice(indexOf, 1);
  }

  objektFullNameChanged() {
    this.clearData();
    this.wertQueryList = null;
    this.loadData();
  }

  private addFeld(filter: IErweiterteSucheObjektFeldData, operator?: string, wert?: any, isValid?: boolean, focus?: boolean) {
    this.selectedFilterList.push({
      filter: filter,
      editor: this.getEditor(filter),
      operator: operator || this.getDefaultOperator(filter),
      wert: wert,
      isValid: isValid || false,
      focus: focus
    });
  }
  private clearData() {
    this.selectedFilterList.splice(0, this.selectedFilterList.length);
  }
  private async doDeleteVorlage() {
    if (!this.idVorlage) {
      return;
    }

    await this.loginprofilEinstellungenService.deleteEinstellung(this.idVorlage);

    const message = this.localizationService.translateOnce("erweiterte-suche.delete-vorlage-deleted-message");
    const title = this.localizationService.translateOnce("erweiterte-suche.delete-vorlage-deleted-title");

    DevExpress.ui.notify(message, title, 3000);

    this.idVorlage = null;
    this.loadVorlagen();
  }
  private async doSaveVorlage() {
    const einstellung = this.getLoginprofilEinstellungData();

    const vorlage = await this.loginprofilEinstellungenService.saveOrCreateEinstellung(einstellung);

    await this.loadVorlagen();
    this.idVorlage = vorlage.Id;
  }
  private getCheckSelectedFilterList(): any[] {
    const r = [];

    this.selectedFilterList.forEach((s) => {
      if (!s.isValid) {
        return;
      }

      r.push({
        Id: s.filter.Id,
        Operator: s.operator,
        Value: s.wert
      });
    });

    return r;
  }
  private getDefaultOperator(filter: IErweiterteSucheObjektFeldData) {
    switch (filter.Datentyp) {
      case ObjektExportEigenschaftDatenTyp.Text: {
        return "like";
      }
      case ObjektExportEigenschaftDatenTyp.Nummer:
      case ObjektExportEigenschaftDatenTyp.Datum: {
        return "=";
      }
      case ObjektExportEigenschaftDatenTyp.Marker:
      case ObjektExportEigenschaftDatenTyp.ObjektStatus:
      case ObjektExportEigenschaftDatenTyp.Auswahlliste: {
        return "in";
      }
      default: {
        return "=";
      }
    }
  }
  private getEditor(filter: IErweiterteSucheObjektFeldData) {
    switch (filter.Datentyp) {
      case ObjektExportEigenschaftDatenTyp.Text: {
        return PLATFORM.moduleName("zit/elements/erweiterte-suche/erweiterte-suche-text");
      }
      case ObjektExportEigenschaftDatenTyp.Nummer: {
        return PLATFORM.moduleName("zit/elements/erweiterte-suche/erweiterte-suche-nummer");
      }
      case ObjektExportEigenschaftDatenTyp.Datum: {
        return PLATFORM.moduleName("zit/elements/erweiterte-suche/erweiterte-suche-datum");
      }
      case ObjektExportEigenschaftDatenTyp.Marker: {
        return PLATFORM.moduleName("zit/elements/erweiterte-suche/erweiterte-suche-marker");
      }
      case ObjektExportEigenschaftDatenTyp.ObjektStatus: {
        return PLATFORM.moduleName("zit/elements/erweiterte-suche/erweiterte-suche-objekt-status");
      }
      case ObjektExportEigenschaftDatenTyp.Auswahlliste: {
        return PLATFORM.moduleName("zit/elements/erweiterte-suche/erweiterte-suche-auswahl");
      }
      default: {
        return null;
      }
    }
  }
  private getLoginprofilEinstellungData(): ILoginprofilEinstellung {
    return {
      Id: this.idVorlageErsetzen,
      Typ: LoginprofilEinstellungTyp.Suche,
      Entitaet: this.objektFullName,
      Bezeichnung: this.vorlageBezNeu,
      Wert: {
        Columns: this.getCheckSelectedFilterList(),
        Custom: null
      }
    };
  }
  private getZButton(element: Element): Element {
    let parent = element;
    while (parent) {
      if (parent.classList.contains("z--button")) {
        return parent;
      }

      parent = parent.parentElement;
    }

    return element;
  }
  private async loadFelder() {
    if (this.objektFullName) {
      const r = await this.erweiterteSucheService.loadObjektFelder(this.objektFullName);

      this.filterList = r;

      if (this.selectedFilterList.length == 0) {
        for (const feld of r) {
          if (!feld.SucheErweitertVorbelegt) {
            continue;
          }

          this.addFeld(feld);
        }
      }
    } else {
      this.filterList.splice(0, this.filterList.length);
    }
  }
  private async loadVorlagen() {
    this.vorlageList = this.objektFullName
      ? await this.erweiterteSucheService.loadEinstellungen(this.objektFullName)
      : [];
  }
  private setWertQueryList() {
    const r = this.getCheckSelectedFilterList();

    this.wertQueryList = r.length == 0
      ? null
      : r;
  }
  private setTitel() {
    const info = this.objektInfoService.getObjektByFullname(this.objektFullName);
    this.sucheTitel = info.Bezeichnung;
  }
  private useVorlage(id: number) {
    this.idVorlage = id;
    
    const vorlage = this.vorlageList.find((v) => v.Id == id);
    if (!vorlage) {
      return;
    }
    
    this.loadFilter(vorlage.Wert.Columns);
  }
}

export interface ISelectedFilter {
  filter: IErweiterteSucheObjektFeldData;
  editor: string;
  operator: any;
  wert?: any;
  isValid?: boolean;
  bezeichnung?: string;
  focus?: boolean;
}
