import { autoinject, bindable, createOverrideContext, observable, BindingEngine, Disposable } from "aurelia-framework";
import { ScopeContainer, ObjectService } from '../../../framework/base/export';
import { IToolbarOptions } from '../../../framework/forms/elements/toolbar/toolbar-options';
import { IItem } from '../../../framework/forms/elements/toolbar/item';
import { IListViewOptions } from '../../../framework/forms/elements/list-view/list-view-options';
import { ListView } from '../../../framework/forms/export';
import { IZitCardOptions, ZitCardTyp } from '../../../zit-ui/elements/zit-card/export';
import { ListViewPagingMode } from '../../../framework/forms/elements/list-view/list-view-paging-mode';

@autoinject
export class CardList {
  private _collectionSubscription: Disposable;
  private _dataSource: any[] = [];

  constructor(
    private _element: Element,
    private _bindingEngine: BindingEngine,
    private _objectService: ObjectService
  ) {}

  @bindable titel: string;
  @bindable @observable dataSource: any[];
  @bindable supportsAdd: boolean = false;
  @bindable supportsDelete: boolean = false;
  @bindable typeName: string;
  @bindable pageSize: number;
  @bindable cardDataPath: string;
  @bindable cardTyp: ZitCardTyp = ZitCardTyp.Kompakt;

  scopeContainer: ScopeContainer;
  toolbarOptions: IToolbarOptions;
  listViewOptions: IListViewOptions;
  listView: ListView;
  cardOptions: IZitCardOptions = {
    useDefaultBindings: true,
    isClickEnabled: true
  }

  bind(bindingContext, overrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: createOverrideContext(bindingContext, overrideContext)
    });

    this.cardOptions.typ = this.cardTyp;

    this.toolbarOptions = {
      title: this.titel,
      icon: null,
      smallToolbar: true,
      items: this.getToolbarItems(),
      scopeContainer: this.scopeContainer
    };

    this.listViewOptions = {
      dataSource: new DevExpress.data.DataSource({
        store: new DevExpress.data.ArrayStore({ data: this._dataSource }),
        requireTotalCount: true
      }),
      useDefaultListItemStyle: false,
      itemClass: "col-xs-12 col-sm-6",
      pageSize: this.pageSize || 8,
      pagingMode: ListViewPagingMode.loadNext
    };

    this.updateDataSourceObserver();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;

    this.updateDataSourceObserver(true);
  }

  onDeleteClick(ev: Event, item: any) {
    ev.preventDefault();
    ev.stopPropagation();

    this._element.dispatchEvent(new CustomEvent(
      "on-card-list-delete", {
        bubbles: true,
        detail: {
          item: item.itemOrg
        }
      }
    ));
  }

  dataSourceChanged() {
    this.updateDataSource();
    this.updateDataSourceObserver();
  }

  private getToolbarItems() {
    return <IItem[]>[{
      id: "add",
      icon: "fas fa-plus",
      isVisibleExpression: "supportsAdd",
      execute: () => {
        this._element.dispatchEvent(new CustomEvent(
          "on-card-list-add", {
            bubbles: true
          }
        ));
      }
    }];
  }
  private updateDataSource() {
    const dataSource = (this.dataSource || []);

    const dataSourceChecked = dataSource.map(i => {
      return {
        itemOrg: i,
        card: this.cardDataPath
          ? this._objectService.getValue(i, this.cardDataPath.concat(".Card"))
          : this._objectService.getValue(i, "Card")
      }
    });

    this._dataSource.splice(0, this._dataSource.length, ...(dataSourceChecked || []));
    this.listViewOptions.dataSource.reload();
  }
  private updateDataSourceObserver(onlyDispose: boolean = false) {
    if (this._collectionSubscription) {
      this._collectionSubscription.dispose(),
      this._collectionSubscription = null;
    }

    if (onlyDispose) {
      return;
    }

    if (!this.dataSource) {
      return;
    }

    this._collectionSubscription = this._bindingEngine.collectionObserver(this.dataSource).subscribe(() => {
      this.updateDataSource();
    });
  }
}