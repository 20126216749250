import { Subscription, EventAggregator } from 'aurelia-event-aggregator';
import { NachrichtService } from './../../../zit/services/nachricht-service';
import { autoinject, OverrideContext, Scope, computedFrom } from 'aurelia-framework';
import { AuthorizationService, BrowserService } from '../../../framework/base/export';
import { ScopeContainer } from '../../../framework/base/classes/scope-container';
import { ICommandData, ToolbarService } from '../../../framework/forms/export';
import { Benutzereinstellungen, BenutzerWechseln } from '../../../zit/elements/export';
import { LoginprofilService, UpdateHinweisService } from '../../../zit/services/export';
import { IdxPopoverComponent, IKopfzeileVerknuepfung } from '../../../zit/interfaces/export';
import { IZitToolbarOptions } from '../../elements/zit-toolbar/zit-toolbar-options';
import { LayoutService } from '../../export';
import { HeaderService } from '../../../framework/default-ui/export';
import { StartupService } from '../../../zit/services/startup-service';
import { BenutzerTyp } from '../../../zit/enumerations/export';
import { LocationService } from '../../../framework/base/services/location-service';
import { BenutzerHilfe } from '../../../zit/elements/benutzer-hilfe/benutzer-hilfe';
import { TooltipService } from '../../../zit/services/tooltip-service';
import { IZitTagOptions } from '../../elements/zit-tag/export';

@autoinject
export class Header {
  private _benutzereinstellungShowSubscription: Subscription;

  constructor(
    public startupService: StartupService,
    public headerService: HeaderService,
    public updateHinweisService: UpdateHinweisService,
    public nachrichtService: NachrichtService,
    public browserService: BrowserService,
    public layoutService: LayoutService,
    private _authorizationService: AuthorizationService,
    private _loginprofilService: LoginprofilService,
    private _toolbarService: ToolbarService,
    private _locationService: LocationService,
    private _tooltipService: TooltipService,
    private _eventAggregator: EventAggregator
  ) {
    this.initializeCommands();
  }

  @computedFrom("layoutService.isSidebarCollapsed")
  get sideNavbarHeaderIcon(): string {
    if (this.layoutService.isSidebarCollapsed) {
      return "fas fa-bars";
    } else {
      return "fas fa-arrow-circle-left"
    }
  }
  scope: Scope;
  scopeContainer: ScopeContainer;

  isAdmin: boolean = false;
  showHilfe: boolean = false;
  showErklaervideos: boolean = false;

  anzUngelesen: number;
  anzUngelesenAbwesend: number;

  userCommandElement: HTMLElement;
  userLogoUrl: HTMLImageElement;

  benutzereinstellungen: Benutzereinstellungen;
  benutzerHilfe: BenutzerHilfe;
  benutzerWechseln: BenutzerWechseln;

  benutzerCommands: ICommandData[];
  benutzerCommandsToolbarOptions: IZitToolbarOptions;

  benutzerPopover: IdxPopoverComponent;
  benutzerPopoverOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    minWidth: "255px",
    width: "auto",
    deferRendering: false
  };

  updateHinweisInfoTagOptions: IZitTagOptions = {
    cssClass: "z--tag-info-message z--tag-info-update-hinweis",
    icon: {
      faIconExpression: "item.icon"
    },
    textExpression: "item.message"
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.benutzerCommandsToolbarOptions = this._toolbarService.createToolbarOptions(
      this.scopeContainer,
      null,
      this.benutzerCommands
    );

    this.benutzerCommandsToolbarOptions.layout = "vertical";
    this.showHilfe = this.startupService.startupInfo.Benutzer.HtmlTextHilfe != void 0;
    this.isAdmin = this.startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.Administrator;

    this.showErklaervideos = this.startupService.startupInfo.ErklaerungsvideoLayoutList
      && this.startupService.startupInfo.ErklaerungsvideoLayoutList.length > 0;

    const urlVerlinkungWebsite = this.startupService.startupInfo.UrlVerlinkungWebsite
    if (urlVerlinkungWebsite) {
      this.userLogoUrl.classList.add("z--clickable");
    }

    this._benutzereinstellungShowSubscription = this._eventAggregator.subscribe("benutzereinstellungen:show", () => {
      this.benutzereinstellungen.showPopup();
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;

    this._benutzereinstellungShowSubscription.dispose();
    this._benutzereinstellungShowSubscription = null;
  }

  async navigateTo(url: string) {
    await this._locationService.goTo({
      url: url,
      clearStack: true
    });

    this._eventAggregator.publish("suche:clear", {});
  }
  onKopfzeileVerknuepfungClick(kf: IKopfzeileVerknuepfung) {
    if (kf.IdLoginprofil) {
      this._loginprofilService.setLoginProfil(kf.IdLoginprofil, true);
    } else if (kf.Url) {
      window.open(kf.Url, "_blank");
    }
  }
  openUrlVerlinkungWebsite() {
    let urlVerlinkung = this.startupService.startupInfo.UrlVerlinkungWebsite;
    if (!urlVerlinkung) {
      return;
    }

    if (!urlVerlinkung.match(/^https?:\/\//i)) {
      urlVerlinkung = `http://${urlVerlinkung}`;
    }

    window.open(urlVerlinkung, "_blank");
  }

  showUserPopover() {
    this.benutzerPopover.instance.show(this.userCommandElement);
  }
  showHilfePopup() {
    this.benutzerHilfe.showPopup();
  }
  toggleTooltipConfig() {
    if (this._tooltipService.isEnabled) {
      this._tooltipService.disable();
    } else {
      this._tooltipService.enable();
    }
  }
  toogleSideNavigation() {
    this.layoutService.isSidebarCollapsed = !this.layoutService.isSidebarCollapsed;
  }

  private initializeCommands() {
    this.benutzerCommands = [{
      id: "benutzereinstellungen",
      icon: "fas fa-cogs",
      title: "zit-header.benutzereinstellungen",
      isVisible: true,
      isEnabled: true,
      execute: () => {
        this.benutzereinstellungen.showPopup();
      }
    }, {
      id: "zurueckZuBenutzer",
      icon: "fas fa-users",
      title: "zit-header.zurueck-zu-benutzer",
      isVisible: this.startupService.startupInfo.HasUrsprBenutzer,
      execute: () => {
        this._loginprofilService.zurueckZuBenutzer()
          .then(r => {
            window.location.reload();
          });
      }
    }, {
      id: "benutzerWechseln",
      icon: "fas fa-sync",
      title: "zit-header.benutzer-wechseln",
      isVisible: this.startupService.startupInfo.CanChangeLoginprofil || this.startupService.startupInfo.CanChangeUser,
      execute: () => {
        this.benutzerWechseln.showPopup();
      }
    }, {
      id: "logout",
      icon: "fas fa-sign-out-alt",
      title: "base.logout",
      isVisible: true,
      isEnabled: true,
      execute: () => {
        this._authorizationService.logout();
      }
    }]
  }
}
