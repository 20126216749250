import { BerechtigungService } from "./../../services/berechtigung-service";
import { StartupService } from "./../../services/startup-service";
import { autoinject, transient } from "aurelia-framework";
import { SucheViewTyp } from "../../enumerations/export";
import { SucheService } from "../../services/suche-service";
import { LocationService } from "./../../../framework/base/services/location-service";
import { FormBase } from "./../../../framework/forms/classes/form-base";
import { ICommandData } from "./../../../framework/forms/interfaces/command-data";

@autoinject
@transient()
export class EntwicklungszieleListUtils {

  constructor(
    private _sucheService: SucheService,
    private _locationService: LocationService,
    private _berechtigungService: BerechtigungService
  ) { }

  form: FormBase;

  activateTableView: ICommandData = {
    icon: "fas fa-table",
    id: "sucheTableViewCommand",
    tooltip: "suche-form.show-table-view",
    sortIndex: 1401,
    execute: (event: any) => {
      this._sucheService.activateSuche(
        "TIP.ZIT.Business.Entities.Objekte.Ziele.Ziel",
        SucheViewTyp.table
      );
    }
  };
  zielErstellen: ICommandData = {
    icon: "fas fa-plus",
    id: "zielErstellenCommand",
    tooltip: "base.add",
    sortIndex: 1100,
    isVisible: this._berechtigungService.hasBerechtigungNew("TIP.ZIT.Business.Entities.Objekte.Ziele.Ziel"),
    execute: (event: any) => {
      this._locationService.goTo({
        url: "#Objekte/Ziel/0",
        currentViewModel: this.form      
      });
    }
  };

  bind(form: FormBase) {
    this.form = form;
  }
}
