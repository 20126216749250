import {
  FrameworkConfiguration,
  PLATFORM
} from "aurelia-framework";
import "./styles/styles.less";

export function configure(config: FrameworkConfiguration) {
  config
    .globalResources(PLATFORM.moduleName("./elements/dokument-list-view/dokument-list-view"))
    .globalResources(PLATFORM.moduleName("./elements/sub-headline/sub-headline"))
    .globalResources(PLATFORM.moduleName("./elements/z-button/z-button"))
    .globalResources(PLATFORM.moduleName("./elements/datenschutz/datenschutz"))
    .globalResources(PLATFORM.moduleName("./elements/tag-list/tag-list"))
    .globalResources(PLATFORM.moduleName("./elements/card-list/card-list"))
    .globalResources(PLATFORM.moduleName("./elements/codes/codes"))
    .globalResources(PLATFORM.moduleName("./elements/datei/datei"))
    .globalResources(PLATFORM.moduleName("./attributes/zit-drag-and-drop"))
    .globalResources(PLATFORM.moduleName("./attributes/repeat-finished-attribute"));
}
