import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  AuthorizationService,
  LocalizationService
} from "../../../base/export";
import { 
  FormBase,
  ICommandData
} from "../../../forms/export";
import { LocationService } from '../../../base/services/location-service';

@autoinject
@transient()
export class ActivateAccountFuncs {
  private form: FormBase;

  constructor(
    private authorization: AuthorizationService,
    private localization: LocalizationService,
    private locationService: LocationService
  ) { }

  activationKey: string;

  registerForm(form: FormBase) {
    this.form = form;

    form.onActivate.register(r => {
      if (r && r.parameters) {
        this.activationKey = r.parameters.key;
      }

      return Promise.resolve();
    });
  }

  activateCommand: ICommandData = {
    id: "$login",
    title: "activate-account-form-funcs.account_aktivieren_caption",
    execute: (e) => {
      if (this.form.models.data.$m_activateAccount.Password !== this.form.models.data.$m_activateAccount.ConfirmPassword) {
        DevExpress.ui.notify(this.localization.translateOnce("activate-account-form-funcs.password_missmatch"), "error", 3000);
        return;
      }

      this.form.models.data.$m_activateAccount.ActivationKey = this.activationKey;

      this.authorization
        .activateAccount(this.form.models.data.$m_activateAccount)
        .then(r => {
          if (r) {
            this.locationService.goTo({
              url: "login",
              clearStack: true
            });
          }
        });
    }
  };

  backToLoginCommand: ICommandData = {
    id: "$backToLogin",
    title: "activate-account-form-funcs.back_to_login_caption",
    execute: () => {
      this.locationService.goTo({
        url: "login",
        clearStack: true
      });
    }
  };
}
