import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class PersonAdresseEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "person-adresse-edit";
        this.title = "person-adresse-edit.person-adresse-edit_caption";
        this.addModel({
            "id": "$m_Adresse",
            "webApiAction": "ZIT/Objekte/PersonAdresseElement",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-adresse-edit.r_strasse_caption",
            "binding": {
                "dataContext": "$m_Adresse",
                "bindTo": "Strasse",
                "bindToFQ": "models.data.$m_Adresse.Strasse"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_strasse",
            "options": {
                "optionsName": "r_strasseOptions",
                "optionsNameFQ": "r_strasseOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-adresse-edit.r_strassezusatz_caption",
            "binding": {
                "dataContext": "$m_Adresse",
                "bindTo": "StrasseZusatz",
                "bindToFQ": "models.data.$m_Adresse.StrasseZusatz"
            },
            "validationRules": [],
            "id": "r_strasseZusatz",
            "options": {
                "optionsName": "r_strasseZusatzOptions",
                "optionsNameFQ": "r_strasseZusatzOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "staat",
            "filters": [],
            "caption": "person-adresse-edit.r_land_caption",
            "binding": {
                "dataContext": "$m_Adresse",
                "bindTo": "IdLand",
                "bindToFQ": "models.data.$m_Adresse.IdLand"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_land",
            "options": {
                "optionsName": "r_landOptions",
                "optionsNameFQ": "r_landOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 20,
            "caption": "person-adresse-edit.r_postleitzahl_caption",
            "binding": {
                "dataContext": "$m_Adresse",
                "bindTo": "Postleitzahl",
                "bindToFQ": "models.data.$m_Adresse.Postleitzahl"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_postleitzahl",
            "options": {
                "optionsName": "r_postleitzahlOptions",
                "optionsNameFQ": "r_postleitzahlOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-adresse-edit.r_ort_caption",
            "binding": {
                "dataContext": "$m_Adresse",
                "bindTo": "Ort",
                "bindToFQ": "models.data.$m_Adresse.Ort"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_ort",
            "options": {
                "optionsName": "r_ortOptions",
                "optionsNameFQ": "r_ortOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 500,
            "caption": "person-adresse-edit.r_bemerkung_caption",
            "binding": {
                "dataContext": "$m_Adresse",
                "bindTo": "Bemerkung",
                "bindToFQ": "models.data.$m_Adresse.Bemerkung"
            },
            "validationRules": [],
            "id": "r_bemerkung",
            "options": {
                "optionsName": "r_bemerkungOptions",
                "optionsNameFQ": "r_bemerkungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "adresstyp",
            "customs": [{
                "key": "objektTyp",
                "value": "'TIP.ZIT.Business.Entities.Objekte.Personen.Person'"
            }],
            "filters": [],
            "caption": "person-adresse-edit.r_adresstyp_caption",
            "binding": {
                "dataContext": "$m_Adresse",
                "bindTo": "IdAdresstyp",
                "bindToFQ": "models.data.$m_Adresse.IdAdresstyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_adresstyp",
            "options": {
                "optionsName": "r_adresstypOptions",
                "optionsNameFQ": "r_adresstypOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "person-adresse-edit.r_gueltigvon_caption",
            "binding": {
                "dataContext": "$m_Adresse",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_Adresse.GueltigVon"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigVon",
            "options": {
                "optionsName": "r_gueltigVonOptions",
                "optionsNameFQ": "r_gueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "person-adresse-edit.r_gueltigbis_caption",
            "binding": {
                "dataContext": "$m_Adresse",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_Adresse.GueltigBis"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigBis",
            "options": {
                "optionsName": "r_gueltigBisOptions",
                "optionsNameFQ": "r_gueltigBisOptions"
            }
        });
        super.onConstructionFinished();
    }
}