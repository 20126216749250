import { autoinject, Scope } from "aurelia-framework";
import { RestService, LocalizationService, DataSourceService, ScopeContainer } from '../../../framework/base/export';
import * as moment from "moment";
import { IdxPopupComponent, IdxDataGridComponent } from '../../interfaces/dx-components';
import { SimpleWidgetCreatorService } from '../../../framework/forms/export';

@autoinject
export class StelleninseratAuswahl {
  private _options: IStelleninseratAuswahlEvalOptions;

  constructor(
    private restService: RestService,
    private localizationService: LocalizationService,
    private dataSourceService: DataSourceService,
    private simpleWidgetCreatorService: SimpleWidgetCreatorService
  ) {}

  model: IModel;

  scope: Scope;
  scopeContainer: ScopeContainer;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    height: "auto",
    width: "750px"
  };
  stelleninseratGrid: IdxDataGridComponent;
  stelleninseratGridOptions: DevExpress.ui.dxDataGridOptions = {
    columns: [{
      dataField: "Kopf.StellenbezeichnungExtern",
      caption: "Stelleninserat",
      sortIndex: 0,
      sortOrder: "asc"
    }],
    keyExpr: "Id",
    height: "400px",
    hoverStateEnabled: true,
    bindingOptions: {
      dataSource: "model.stelleninseratDataSource"
    },
    onRowClick: (e) => {
      this._options.callback(e.data.Id);
      this.popup.instance.hide();
    }
  };

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: null
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "stelleninseratAuswahlToolbar",
      caption: "stelleninserat-auswahl.popup-header",
      scopeContainer: this.scopeContainer,
      options: this.popupOptions
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  evalIdStelleninserat(options: IStelleninseratAuswahlEvalOptions) {
    this._options = options;
    
    this.resetModel();
    this.checkStelleninserat();
  }

  private async checkStelleninserat() {
    const today = moment().startOf("day").toDate();

    const where: any[] = [
      ["ObjektStatusCode", "AKTIV"],
      ["Kopf.GueltigVon", "<=", today],
      ["Kopf.GueltigBis", ">=", today]
    ];

    if (this._options.onlySchule) {
      where.push(["Kopf.Geschaeftspartner.IsGeschaeftspartnerSchule", true]);
    }

    const r = await this.restService.get({
      url: this.restService.getWebApiUrl("ZIT/Objekte/Stelleninserat"),
      getOptions: {
        columns: ["Id"],
        where: where,
        orderBy: [{columnName: "Id", sortOrder: 0}],
        skip: 0,
        take: 2
      },
      increaseLoadingCount: true
    });

    if (r.length == 0) {
      DevExpress.ui.notify(
        this.localizationService.translateOnce("stelleninserat-auswahl.kein-gueltiges-stelleninserat"), 
        "ERROR", 
        3000
      );;
    }
    else if (r.length == 1) {
      this._options.callback(r[0].Id);
    } else {
      this.model.stelleninseratDataSource = this.dataSourceService.createDataSource(
        this.scopeContainer, {
          webApiAction: "ZIT/Objekte/Stelleninserat",
          webApiWhere: where,
          webApiColumns: ["Id", "IdStelleninseratKopfElement"],
          webApiExpand: {
            Kopf: {
              columns: ["Id", "StellenbezeichnungExtern"]
            }
          },
        }
      );

      this.popup.instance.show();
    }
  }
  private resetModel() {
    this.model = {
      stelleninseratDataSource: []
    };
  }
}
export interface IStelleninseratAuswahlEvalOptions {
  onlySchule?: boolean;

  callback: {(idStelleninserat: number): void};
}
interface IModel {
  stelleninseratDataSource: DevExpress.data.DataSource | any[];
}