import { AuthorizationService } from './../../framework/base/services/authorization-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Subscription } from 'aurelia-event-aggregator';
import { IDialogConfirmOptions, DialogConfirmService } from './../../framework/forms/services/dialog-confirm-service';
import { BenutzerTyp } from './../enumerations/benutzer-typ';
import { autoinject } from 'aurelia-framework';
import { RestService, LocationService } from '../../framework/base/services/export';
import { StartupService } from './startup-service';
import { NachrichtErstellen } from '../elements/nachricht-erstellen/nachricht-erstellen';

import * as moment from "moment";
import { IDatei } from '../elements/datei/datei';
import { FormBase } from '../../framework/forms/export';

@autoinject
export class NachrichtService {
  private _nachrichtStatusInterval: any;
  private _nachrichtGelesenSubscription: Subscription;
  private _nachrichtUngelesenSubscription: Subscription;

  constructor(
    private _restService: RestService,
    private _startupService: StartupService,
    private _dialogConfirmService: DialogConfirmService,
    private _eventAggregator: EventAggregator,
    private _authorizationService: AuthorizationService,
    private _locationService: LocationService
  ) { 
    this._nachrichtStatusInterval = setInterval(() => {
      this.checkNachrichtStatus();
    }, 30000);

    this.checkNachrichtStatus();

    this._nachrichtGelesenSubscription = this._eventAggregator.subscribe("nachricht:gelesen", () => {
      this.checkNachrichtStatus();
    });
    this._nachrichtUngelesenSubscription = this._eventAggregator.subscribe("nachricht:ungelesen", () => {
      this.checkNachrichtStatus();
    });
  }

  nachrichtErstellen: NachrichtErstellen;

  anzUngelesen: number;
  anzUngelesenAbwesend: number;  

  canNachrichtErstellen(objektFullName: string): boolean {
    if (!this._startupService.startupInfo.Benutzer.HasNachrichten) {
      return false;
    }
    if (!objektFullName) {
      return false;
    }

    const canNachrichtenErstellen = this._startupService.startupInfo.Benutzer.HasNachrichten;
    if (!canNachrichtenErstellen) {
      return false;
    }

    return this.canNachrichtErstellenCheckFullName(objektFullName);
  }
  canMassennachrichtErstellen(objektFullName: string): boolean {
    if (!this._startupService.startupInfo.Benutzer.HasNachrichten) {
      return false;
    }
    if (!this._startupService.startupInfo.Benutzer.CanNachrichtMassennachricht) {
      return false;
    }
    if (!objektFullName) {
      return false;
    }

    return this.canNachrichtErstellenCheckFullName(objektFullName);
  }

  async doNachrichtErstellen(benutzerInfo: INachrichtBenutzerInfo, typ: NachrichtTyp = NachrichtTyp.Nachricht, dateiList?: IDatei[]) {
    const nachricht: INachricht = {
      isReadOnly: false,
      dateiList: dateiList || [],
      erstellTyp: NachrichtErstellTyp.NeuOderBearbeiten,
      typ: typ,
      nachrichtSignatur: this._startupService.startupInfo.Benutzer.NachrichtSignatur
    };

    nachricht.empfContainer = {
      NachrichtEmpfaengerList: [{ 
        Id: benutzerInfo.idBenutzer,
        Name: benutzerInfo.name, 
        IsGruppe: benutzerInfo.anzeigeTyp == NachrichtAnzeigeTyp.Gruppe 
      }]
    };

    let titel = "Nachricht erstellen";

    switch (typ) {
      case NachrichtTyp.Telefonat: {
        titel = "Telefonat";
        nachricht.nachrichtSignatur = null;
        nachricht.anlagedatum = moment().toDate();
        break;
      }
      case NachrichtTyp.Notiz: {
        titel = "Notiz";
        nachricht.nachrichtSignatur = null;
        nachricht.anlagedatum = moment().toDate();
        break;
      }
      default: {
        break;
      }
    }

    this.showNachrichtErstellenPopup(titel, nachricht);
  }
  async doNachrichtErstellenFromObjekt(objektList: (number | INachrichtEmpfObjektModel)[], fullName: string) {
    const benutzertyp = await this.getBenutzertyp(fullName);

    const nachricht: INachricht = {
      fullName: fullName,
      isReadOnly: false,
      dateiList: [],
      erstellTyp: NachrichtErstellTyp.NeuOderBearbeiten,
      typ: NachrichtTyp.Nachricht,
      nachrichtSignatur: this._startupService.startupInfo.Benutzer.NachrichtSignatur
    };

    //Konvertieren der Objekt-Liste, falls nur IDs kommen
    objektList = objektList.map(item => {
      if (typeof item == "number") {
        return {
          IdObjekt: item
        };
      } else {
        return item;
      }
    });

    nachricht.empfContainer = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Nachricht/GetBenutzerFromObjekt"),
      increaseLoadingCount: true,
      data: <INachrichtBenutzerFromObjekt>{
        ObjektList: objektList,
        Benutzertyp: benutzertyp,
        FullName: fullName
      }
    });

    const hasNoBerechtigungMassennachricht = nachricht.empfContainer
      && nachricht.empfContainer.NachrichtEmpfaengerList
      && nachricht.empfContainer.NachrichtEmpfaengerList.length > 1
      && !this._startupService.startupInfo.Benutzer.CanNachrichtMassennachricht;

    if (hasNoBerechtigungMassennachricht) {
      DevExpress.ui.notify("Keine Berechtigung zum Versenden von Nachrichten an mehrere Personen.", "error", 3000);
      return;
    }

    this.showNachrichtErstellenPopup("Nachricht erstellen", nachricht);
  }
  async doAntwortNachrichtErstellen(keyReferenzNachricht: string, idEmpfaengerBenutzer: number, empfaengerName: string) {
    const nachricht: INachricht = {
      isReadOnly: false,
      erstellTyp: NachrichtErstellTyp.Antworten,
      keyReferenzNachricht: keyReferenzNachricht,
      dateiList: [],
      typ: NachrichtTyp.Nachricht,
      nachrichtSignatur: this._startupService.startupInfo.Benutzer.NachrichtSignatur
    };

    nachricht.empfContainer = {
      NachrichtEmpfaengerList: [
        { Id: idEmpfaengerBenutzer, Name: empfaengerName }]
    };

    this.showNachrichtErstellenPopup("Antworten", nachricht);
  }
  async doWeiterleitenNachrichtErstellen(keyReferenzNachricht: string, text: string, dateiList?: IDatei[]) {
    const nachricht: INachricht = {
      isReadOnly: true,
      erstellTyp: NachrichtErstellTyp.Weiterleiten,
      keyReferenzNachricht: keyReferenzNachricht,
      nachrichtText: text,
      dateiList: dateiList || [],
      typ: NachrichtTyp.Nachricht
    };

    this.showNachrichtErstellenPopup("Nachricht weiterleiten", nachricht);
  }
  async doUmleitenNachrichtErstellen(keyReferenzNachricht: string, text: string, dateiList?: IDatei[]) {
    const nachricht: INachricht = {
      isReadOnly: true,
      erstellTyp: NachrichtErstellTyp.Umleiten,
      keyReferenzNachricht: keyReferenzNachricht,
      nachrichtText: text,
      dateiList: dateiList || [],
      typ: NachrichtTyp.Nachricht
    };

    this.showNachrichtErstellenPopup("Nachricht umleiten", nachricht);
  }
  async doNachrichtBearbeiten(item: any) {
    const nachricht: INachricht = {
      erstellTyp: NachrichtErstellTyp.NeuOderBearbeiten,
      key: item.KeyNachricht,
      nachrichtText: item.TextOrg,
      typ: item.Typ,
      telefonatTyp: item.TelefonatTyp,
      anlagedatum: item.Anlagedatum,
      dateiList: (item.DateiList || []).map(d => {
        return {
          id: d.Id,
          dmsLink: d.DMSLink,
          dmsLinkDownload: d.DMSLinkDownload,
          dateiname: d.Dateiname,
          exists: true
        };
      })
    };

    let titel = "Nachricht bearbeiten";

    switch (item.Typ) {
      case NachrichtTyp.Telefonat: {
        titel = "Telefonat bearbeiten";
        break;
      }
      case NachrichtTyp.Notiz: {
        titel = "Notiz bearbeiten";
        break;
      }
      default: {
        break;
      }
    }

    this.showNachrichtErstellenPopup(titel, nachricht);
  }
  async doNachrichtUngelesenMarkieren(keyReferenzNachricht: string) {
    return this._restService.get({
      url: `${this._restService.getApiUrl("ZIT/Nachricht/NachrichtUngelesen?keyNachricht=")}${keyReferenzNachricht}`,
      increaseLoadingCount: true
    });
  }
  async showNachrichtErstellenPopup(titel: string, nachricht: INachricht) {
    if (!nachricht.idDateiLoeschList) {
      nachricht.idDateiLoeschList = [];
    }
    if (nachricht.doVollstaendigeNachrichtVersenden == void(0))   {
      nachricht.doVollstaendigeNachrichtVersenden = false;
    }

    this.nachrichtErstellen.popupTitel = titel;
    this.nachrichtErstellen.show(nachricht);
  }

  showDeleteDialog(keyReferenzNachricht: string) {
    return new Promise((resolve, reject) => {
      const dialogConfirmOptions: IDialogConfirmOptions = {
        title: "Löschen",
        message: "Sind Sie sicher, dass sie diese Nachricht löschen wollen?",
        buttons: [{
          id: "",
          text: "Ja",
          onClick: async () => {
            await this._restService.get({
              url: `${this._restService.getApiUrl("ZIT/Nachricht/DeleteNachricht?keyNachricht=")}${keyReferenzNachricht}`,
              increaseLoadingCount: true
            });

            resolve(true);
          }
        }, {
          id: "",
          text: "Nein",
          onClick: () => {
            resolve(false);
          }
        }]
      };
  
      this._dialogConfirmService.show(dialogConfirmOptions);
    });
  }

  async getBenutzerFromObjekt(fullName: string, idObjekt: number) {
    const benutzertyp = await this.getBenutzertyp(fullName);

    const result: INachrichtObjektBenutzerContainer = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Nachricht/GetBenutzerFromObjekt"),
      increaseLoadingCount: true,
      data: <INachrichtBenutzerFromObjekt>{
        ObjektList: [{ IdObjekt: idObjekt }],
        Benutzertyp: benutzertyp,
        FullName: fullName
      }
    });

    if (result.NachrichtEmpfaengerList.length == 0) {
      return null;
    }

    return result.NachrichtEmpfaengerList[0];
  }  
  getEmpfaengerDataSource(): DevExpress.data.DataSource {
    const result = new DevExpress.data.DataSource(
      <any>new DevExpress.data.CustomStore({
        byKey: async (key) => {
          const r = await this._restService.post({
            url: this._restService.getApiUrl("ZIT/Nachricht/BenutzerAuswahlList"),
            data: {
              Id: key
            },
            increaseLoadingCount: true
          });

          if (r && r.length > 0) {
            return r[0];
          } else {
            return null;
          }
        },
        load: async (e) => {
          const r = await this._restService.post({
            url: this._restService.getApiUrl("ZIT/Nachricht/BenutzerAuswahlList"),
            data: {
              Take: e.take,
              Skip: e.skip,
              SearchValue: e.searchValue
            },
            increaseLoadingCount: true
          });

          return {
            data: r
          };
        }
      })
    );
    result.pageSize(20);

    return result;
  }
  async getNachrichtGruppeBenutzerInfoForObjekt(objektFullName: string, idObjekt: number): Promise<INachrichtBenutzerInfo> {
    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Nachricht/NachrichtGruppeBenutzerInfoForObjekt"),
      data: {
        ObjektFullName: objektFullName,
        IdObjekt: idObjekt
      }
    });

    if (!r) {
      return null;
    }

    return {
      anzeigeTyp: r.AnzeigeTyp,
      idBenutzer: r.IdBenutzer,
      isGruppeAdmin: r.IsGruppeAdmin,
      name: r.Name
    }
  }
  async getStatus(): Promise<any> {
    return this._restService.get({
      url: this._restService.getApiUrl("ZIT/Nachricht/Status"),
      increaseLoadingCount: false
    });
  }
  async getTextmarken(): Promise<INachrichtTextmarke[]> {
    return await this._restService.get({
      url: this._restService.getApiUrl("ZIT/Nachricht/TextMarken")
    });
  }

  goToNachrichtList(benutzerInfo: INachrichtBenutzerInfo | INachrichtBenutzerModel, form: FormBase) {
    const model = <INachrichtBenutzerModel>benutzerInfo;
    if (model.Id) {

      benutzerInfo = <INachrichtBenutzerInfo>{
        idBenutzer: model.Id,
        anzeigeTyp: model.IsGruppe ? NachrichtAnzeigeTyp.Gruppe : NachrichtAnzeigeTyp.Benutzer,
        name: model.Name,
        isGruppeAdmin: false
      };
    }

    this._locationService.goTo({
      url: "Nachrichten",
      currentViewModel: form,
      customOptions: {
        benutzerInfo: benutzerInfo
      }
    });
  }

  private canNachrichtErstellenCheckFullName(objektFullName: string): boolean {
    switch (objektFullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Stelleninserate.Stelleninserat":
      case "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung":
      case "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstellePersonenzuteilung":
      case "TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson":
      case "TIP.ZIT.Business.Entities.Objekte.Personen.Person":
      case "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstelle":
      case "TIP.ZIT.Business.Entities.Objekte.Termine.Termin":
        return true;
      default:
        return false;
    }
  }

  private async checkNachrichtStatus() {
    if (!this._authorizationService.isLoggedIn) {
      this.anzUngelesen = null;
      this.anzUngelesenAbwesend = null;

      return;
    }

    const r = await this.getStatus();

    if (!r) {
      this.anzUngelesenAbwesend = null;
      this.anzUngelesen = null;

      return;
    }

    this.anzUngelesenAbwesend = r.AnzUngelesenAbwesend;
    this.anzUngelesen = r.AnzUngelesen;
  }

  private async getBenutzertyp(fullName: string): Promise<BenutzerTyp> {
    let benutzertyp = this._startupService.startupInfo.Benutzer.Benutzertyp;

    if (benutzertyp == BenutzerTyp.Ungueltig) {
      return;
    }

    benutzertyp = this.getBenutzertypFromFullName(fullName, benutzertyp);

    if (benutzertyp == BenutzerTyp.Administrator || benutzertyp == BenutzerTyp.InternerBenutzer) {
      const dialogConfirmOptions: IDialogConfirmOptions = {
        title: "Geschäftspartner/Person",
        message: "Soll die Nachricht an die Geschäftspartner oder die Personen gesendet werden?",
        buttons: [{
          id: "geschaeftspartner",
          text: "Geschäftspartner",
          onClick: () => {
            benutzertyp = BenutzerTyp.NatuerlichePerson;
          }
        }, {
          id: "personen",
          text: "Personen",
          onClick: () => {
            benutzertyp = BenutzerTyp.JuristischePerson;
          }
        }]
      };

      await this._dialogConfirmService.show(dialogConfirmOptions);
    }

    return benutzertyp;
  }
  private getBenutzertypFromFullName(fullName: string, benutzertypDefault: BenutzerTyp): BenutzerTyp {
    switch (fullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Stelleninserate.Stelleninserat":
      case "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung":
      case "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstellePersonenzuteilung":
      case "TIP.ZIT.Business.Entities.Objekte.Disposition.DispoPlanstelle":
        return benutzertypDefault;
      case "TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson":
        return BenutzerTyp.JuristischePerson;
      case "TIP.ZIT.Business.Entities.Objekte.Personen.Person":
      case "TIP.ZIT.Business.Entities.Objekte.Termine.Termin":
        return BenutzerTyp.NatuerlichePerson;
      default:
        return benutzertypDefault;
    }
  }
}

export interface INachrichtEmpfObjektModel {
  IdObjekt: number;
  IdObjektRef?: number;
}
export interface INachrichtBenutzerInfo {
  idBenutzer: number;
  name: string;
  anzeigeTyp: NachrichtAnzeigeTyp;
  isGruppeAdmin: boolean;
}
export interface INachrichtBenutzerModel {
  Id: number;
  Name: string;
  IsGruppe?: boolean;
  IdObjekt?: number;
}
export interface INachrichtBenutzerFromObjekt {
  ObjektList: INachrichtEmpfObjektModel[],
  Benutzertyp: BenutzerTyp;
  FullName: string;
}
export interface INachricht {
  key?: string;
  typ?: NachrichtTyp;
  erstellTyp?: NachrichtErstellTyp;
  empfContainer?: INachrichtObjektBenutzerContainer;
  dateiList?: IDatei[];
  idDateiLoeschList?: number[];
  isReadOnly?: boolean;
  keyReferenzNachricht?: string;
  nachrichtText?: string;
  nachrichtSignatur?: string;
  fullName?: string;
  telefonatTyp?: TelefonatTyp;
  anlagedatum?: Date;
  doVollstaendigeNachrichtVersenden?: boolean;
}
export interface INachrichtObjektBenutzerContainer {
  NachrichtEmpfaengerList: INachrichtBenutzerModel[];
}
export interface INachrichtTextmarke {
  Bezeichnung: string;
  Value: string;
}
export enum NachrichtAnzeigeTyp {
  Benutzer = 0,
  Gruppe = 1
}
export enum NachrichtErstellTyp {
  Antworten = 0,
  Weiterleiten = 1,
  Umleiten = 2,
  NeuOderBearbeiten = 3
}
export enum NachrichtTyp {
  Nachricht = 0,
  Telefonat = 1,
  ExtEmail = 2,
  Notiz = 3
}
export enum TelefonatTyp {
  Erreicht = 0,
  NichtErreicht = 1,
  EingehendesTelefonat = 2
}
