import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class ZielListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "ziel-list";
        this.title = "ziel-list.ziel-list_caption";
        this.addModel({
            "id": "$m_Ziel",
            "webApiAction": "ZIT/Objekte/Ziel",
            "webApiExpand": {
                Kopf: null
            },
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_zielBezeichnung",
                "caption": "ziel-list.r_zielbezeichnung_caption",
                "bindTo": "Kopf.Bezeichnung"
            }],
            "optionsToolbar": {
                "optionsName": "r_zielGridToolbarOptions",
                "optionsNameFQ": "r_zielGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Ziel"
            },
            "dataModel": "$m_Ziel",
            "editUrl": "Objekte/Ziel",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_zielGrid",
            "options": {
                "optionsName": "r_zielGridOptions",
                "optionsNameFQ": "r_zielGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}