import { autoinject, bindable, bindingBehavior } from "aurelia-framework";

@autoinject
export class Datenschutz {
  constructor() {}

  @bindable datenschutzList: any[];

  datenschutzCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "",
    onContentReady: (e) => {
      const container = e.element.querySelector(".dx-checkbox-container");

      const el = document.createElement("span");
      el.className = "dx-checkbox-text";
      el.innerHTML = (<any>e).model.bindingContext.datenschutz.Html;
      container.appendChild(el);

      el.addEventListener("click", (e: Event) => {
        const target = <HTMLElement>e.target;
        if (target.tagName != "A") {
          return;
        }

        e.stopPropagation();
      });
    },
    bindingOptions: {
      value: "datenschutz.IsBestaetigt"
    }
  }

  hasAlleBestaetigt(): boolean {
    if (!this.datenschutzList) {
      return true;
    }

    for (let datenschutz of this.datenschutzList) {
      if (datenschutz.IsBestaetigt) {
        continue;
      }

      DevExpress.ui.notify(
        datenschutz.PflichtfeldText,
        "error",
        3000
      );
      return false;
    }

    return true;
  }
}