import {
  autoinject,
  bindable,
  customAttribute
} from "aurelia-framework";

@autoinject
@customAttribute("animation-tracker")
export class AnimationTrackerAttribute {
  private _eventStart: any;
  private _eventEnd: any;
  private _handlerStart: any;
  private _handlerEnd: any;

  constructor(
    private element: Element
  ) { }

  @bindable propertyName: string;

  bind() {
    this._eventStart = this.getEventStart();
    if (this._eventStart) {
      this._handlerStart = this.handleEnd.bind(this);
      this.element.addEventListener(this._eventStart, this._handlerStart);
    }

    this._eventEnd = this.getEventEnd();
    if (this._eventEnd) {
      this._handlerEnd = this.handleEnd.bind(this);
      this.element.addEventListener(this._eventEnd, this._handlerEnd);
    }

  }
  unbind() {
    if (this._eventStart) {
      this.element.removeEventListener(this._eventStart, this._handlerStart);
    }
    if (this._eventEnd) {
      this.element.removeEventListener(this._eventEnd, this._handlerEnd);
    }
  }

  private getEventStart() {
    const transitions = {
      "transition": "transitionstart",
      "OTransition": "oTransitionStart",
      "MozTransition": "transitionstart",
      "WebkitTransition": "webkitTransitionStart"
    }

    const el: any = this.element;
    for (let t in transitions) {
      if (el.style[t] !== undefined) {
        return transitions[t];
      }
    }
  }
  private getEventEnd() {
    const transitions = {
      "transition": "transitionend",
      "OTransition": "oTransitionEnd",
      "MozTransition": "transitionend",
      "WebkitTransition": "webkitTransitionEnd"
    }

    const el: any = this.element;
    for (let t in transitions) {
      if (el.style[t] !== undefined) {
        return transitions[t];
      }
    }
  }
  private handleStart(e: any) {
    if (!e.propertyName) {
      return;
    }

    if (this.propertyName && this.propertyName != e.propertyName) {
      return;
    }

    const event = new CustomEvent("on-animation-start", {
      detail: {
        sender: this,
        element: this.element,
        propertyName: e.propertyName
      },
      bubbles: true
    });

    this.element.dispatchEvent(event);
  }
  private handleEnd(e: any) {
    if (!e.propertyName) {
      return;
    }

    if (this.propertyName && this.propertyName != e.propertyName) {
      return;
    }

    const event = new CustomEvent("on-animation-end", {
      detail: {
        sender: this,
        element: this.element,
        propertyName: e.propertyName
      },
      bubbles: true
    });

    this.element.dispatchEvent(event);
  }
}
