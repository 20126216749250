import {
  autoinject
} from "aurelia-framework";
import {
  RestService
} from "./rest-service";
import {
  JsonService
} from "./json-service";

@autoinject
export class WebEventService {
  constructor(
    private rest: RestService,
    private json: JsonService
  ) { }

  execute(eventInfo: IEventInfo, showLoading: boolean = false): Promise<any> {
    return this.rest.post({
      url: this.rest.getApiUrl("base/Event/Post"),
      increaseLoadingCount: showLoading,
      data: eventInfo
    });
  }
  executeWithProgress(eventInfo: IEventInfo, callback: {(text: string, percent: number): void}): Promise<any> {
    const webSocket = new WebSocket(this.rest.getWebSocketUrl("base/Event/Socket"));

    return new Promise((resolve, reject) => {
      webSocket.onopen = () => {
        webSocket.send(this.json.stringify(eventInfo));
      };
      webSocket.onmessage = (e) => {
        const data = this.json.parse(e.data);

        switch (data.Type) {
          case "STATUS": {
            if (callback) {
              callback(data.Data.Text, data.Data.Percent);
            }
            break;
          }
          case "RESULT": {
            webSocket.close();
            resolve(data.Data);
            break;
          }
        }
      };
      webSocket.onerror = (e) => {
        reject(e);
      };
    });
  }
}

interface IEventInfo {
  EventName: string;
  data: any;
}
