import {
  BindingEngine,
  Expression,
  Container,
  TaskQueue,
  transient
} from "aurelia-framework";
import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  Models
} from "./models";
import {
  Functions
} from "./functions";
import {
  Commands
} from "./commands";
import {
  Variables
} from "./variables";
import {
  EditPopups
} from "./edit-popups";
import {
  NestedForms
} from "./nested-forms";
import {
  CommandServerData
} from "./command-server-data";
import {
  DefaultCommandsService,
  CommandService,
  FormEventService,
  PopupInfoService,
  ToolbarService,
  RouterService,
  HistoryService,
  DialogConfirmService
} from "../services/export";
import {
  WidgetCreatorService
} from "../widget-services/widget-creator-service";
import {
  BindingService,
  CustomEvent,
  GlobalizationService,
  LocalizationService,
  ErrorService,
  PermissionService,
  RestService,
  WindowService
} from "../../base/export";
import {
  IFormAttachedEventArgs,
  IFormActivateEventArgs,
  IFormReadyEventArgs,
  IFormReactivatedEventArgs,
  IFormValidatingEventArgs,
  IFormDetachedEventArgs,
  IFormBindEventArgs,
  IFormUnbindEventArgs,
  IEditorValueChangedEventArgs,
  IFormSavingEventArgs,
  IFormSavedEventArgs,
  IFormDeletedEventArgs
} from "../event-args/export";
import { FileService } from '../../base/services/file-service';
import { BrowserService } from '../../base/services/browser-service';
import { FormInfoService } from '../services/form-info-service';
import { IFormDeletingEventArgs } from '../event-args/form-deleting';

@transient()
export class FormBaseImport {
  constructor(
    public bindingEngine: BindingEngine,
    public taskQueue: TaskQueue,
    public eventAggregator: EventAggregator,
    public rest: RestService,
    public widgetCreator: WidgetCreatorService,
    public command: CommandService,
    public toolbar: ToolbarService,
    public defaultCommands: DefaultCommandsService,
    public router: RouterService,
    public history: HistoryService,
    public permission: PermissionService,
    public error: ErrorService,
    public file: FileService,
    public models: Models,
    public nestedForms: NestedForms,
    public variables: Variables,
    public functions: Functions,
    public commands: Commands,
    public editPopups: EditPopups,
    public binding: BindingService,
    public globalization: GlobalizationService,
    public localization: LocalizationService,
    public commandServerData: CommandServerData,
    public popupInfo: PopupInfoService,
    public formEvent: FormEventService,
    public browser: BrowserService,
    public windowService: WindowService,
    public dialogConfirm: DialogConfirmService,
    public formInfoService: FormInfoService,
    public onAttached: CustomEvent<IFormAttachedEventArgs>,
    public onDetached: CustomEvent<IFormDetachedEventArgs>,
    public onActivate: CustomEvent<IFormActivateEventArgs>,
    public onBind: CustomEvent<IFormBindEventArgs>,
    public onUnbind: CustomEvent<IFormUnbindEventArgs>,
    public onReady: CustomEvent<IFormAttachedEventArgs>,
    public onReactivated: CustomEvent<IFormReadyEventArgs>,
    public onValidating: CustomEvent<IFormValidatingEventArgs>,
    public onEditorValueChanged: CustomEvent<IEditorValueChangedEventArgs>,
    public onSaving: CustomEvent<IFormSavingEventArgs>,
    public onSaved: CustomEvent<IFormSavedEventArgs>,
    public onDeleting: CustomEvent<IFormDeletingEventArgs>,
    public onDeleted: CustomEvent<IFormDeletedEventArgs>
  ) { }

  dispose() {
    this.onAttached.dispose();
    this.onDetached.dispose();
    this.onActivate.dispose();
    this.onBind.dispose();
    this.onUnbind.dispose();
    this.onReady.dispose();
    this.onReactivated.dispose();
    this.onValidating.dispose();
    this.onEditorValueChanged.dispose();
    this.onSaving.dispose();
    this.onSaved.dispose();
    this.onDeleting.dispose();
    this.onDeleted.dispose();

    this.commandServerData.dispose();
    this.commands.dispose();
    this.editPopups.dispose();
    this.functions.dispose();
    this.models.dispose();
    this.nestedForms.dispose();
    this.variables.dispose();
  }
} 
