import * as fwx from "../../../../framework/forms/form-export";
import {
    TerminZustandAendernUtils
} from "./termin-zustand-aendern-utils";

@fwx.autoinject
export class TerminZustandAendernEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_TerminZustandAendernUtils: TerminZustandAendernUtils) {
        super(element, formBaseImport);
        this.id = "termin-zustand-aendern";
        this.title = "termin-zustand-aendern.termin-zustand-aendern_caption";
        this.addModel({
            "id": "$m_Zustand",
            "webApiAction": "ZIT/Stammdaten/Zustand",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ZustandDataSource",
            "webApiAction": "ZIT/Stammdaten/Zustand",
            "webApiWhere": ["Bereich", {
                "isBound": true,
                "expression": "models.data.$m_Zustand.Bereich"
            }],
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$v_idTermin"
        });
        this.addVariable({
            "id": "$v_zustandDatum"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_TerminZustandAendernUtils.saveCommand",
                "bindToFQ": "functions.$f_TerminZustandAendernUtils.saveCommand"
            }
        });
        this.addFunction("$f_TerminZustandAendernUtils", $f_TerminZustandAendernUtils, "functions.$f_TerminZustandAendernUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "r_aenderungZustandValidationGroup",
            "options": {
                "optionsName": "r_aenderungZustandValidationGroupOptions",
                "optionsNameFQ": "r_aenderungZustandValidationGroupOptions"
            }
        });
        super.onConstructionFinished();
    }
}