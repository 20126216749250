import { autoinject, Scope } from "aurelia-framework";
import { IdxPopupComponent, IdxValidationGroupComponent } from "../../interfaces/dx-components";
import { ScopeContainer, GlobalizationService, DataSourceService, LocalizationService, RestService } from "../../../framework/base/export";
import { SimpleWidgetCreatorService, ICommandData, DialogConfirmService } from "../../../framework/forms/export";
import { StartupService } from "../../services/export";

@autoinject
export class SchnupperterminTag {
  private _callbackOnChanges?: {(): void};

  constructor(
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _globalizationService: GlobalizationService,
    private _dataSourceService: DataSourceService,
    private _startupService: StartupService,
    private _localizationService: LocalizationService,
    private _restService: RestService,
    private _dialogConfirmService: DialogConfirmService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;

  model: IModel;
  privateModel: IPrivateModel;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "750px",
    height: "auto",
    maxHeight: "90vh"
  };

  bezeichnungOptions: DevExpress.ui.dxTextBoxOptions = {
    maxLength: 250,
    bindingOptions: {
      readOnly: "model._IsVerwendet",
      value: "model.Bezeichnung"
    }
  };
  typOptions: DevExpress.ui.dxRadioGroupOptions = {
    valueExpr: "id",
    displayExpr: "text",
    items: [
      { id: SchnupperterminTagTyp.Schule, text: "Schultermin" },
      { id: SchnupperterminTagTyp.Individuell, text: "Individueller Termin" },
      { id: SchnupperterminTagTyp.Geschaeftspartner, text: "Geschäftspartnertermin" },
    ],
    bindingOptions: {
      readOnly: "model._IsVerwendet",
      value: "model.Typ"
    }
  };
  standarddauerOptions: DevExpress.ui.dxNumberBoxOptions = {
    min: 1,
    max: 999,
    format: this._globalizationService.getNumberFormat("f0"),
    bindingOptions: {
      readOnly: "model._IsVerwendet",
      value: "model.Standarddauer"
    }
  };
  mindestdauerOptions: DevExpress.ui.dxNumberBoxOptions = {
    min: 1,
    max: 999,
    format: this._globalizationService.getNumberFormat("f0"),
    bindingOptions: {
      readOnly: "model._IsVerwendet",
      value: "model.Mindestdauer"
    }
  };
  maximaldauerOptions: DevExpress.ui.dxNumberBoxOptions = {
    min: 1,
    max: 999,
    format: this._globalizationService.getNumberFormat("f0"),
    bindingOptions: {
      readOnly: "model._IsVerwendet",
      value: "model.Maximaldauer"
    }
  };
  kontingentOptions: DevExpress.ui.dxNumberBoxOptions = {
    min: 0,
    max: 999,
    format: this._globalizationService.getNumberFormat("f0"),
    bindingOptions: {
      readOnly: "!model.IdGeschaeftspartner",
      value: "model.MaxAnzahlTeilnehmer"
    }
  };
  schuleOptions: DevExpress.ui.dxTagBoxOptions = {
    searchEnabled: true,
    showClearButton: true,
    displayExpr: "Name1",
    valueExpr: "Id",
    bindingOptions: {
      readOnly: "privateModel.isSchuleReadOnly || model._IsVerwendet",
      value: "privateModel.idSchuleList"
    }
  };
  lehrberufOptions: DevExpress.ui.dxTagBoxOptions = {
    searchEnabled: true,
    showClearButton: true,
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "privateModel.lehrberufDataSource",
      readOnly: "!model.IdGeschaeftspartner || model._IsVerwendet",
      value: "privateModel.idLehrberufList"
    }
  };
  geschaeftspartnerOptions: DevExpress.ui.dxSelectBoxOptions = {
    searchEnabled: true,
    showClearButton: true,
    displayExpr: "Name1",
    valueExpr: "Id",
    bindingOptions: {
      readOnly: "privateModel.isGeschaeftspartnerReadOnly || model._IsVerwendet || model.IdPerson",
      value: "model.IdGeschaeftspartner"
    },
    onValueChangedByUser: () => {
      if (!this.model.IdGeschaeftspartner) {
        this.model.MaxAnzahlTeilnehmer = null;
      }
      
      this.createTerminDataSource();
      this.createLehrberufDataSource();
      this.model.IdGeschaeftspartnerTermin = null;
      this.privateModel.idLehrberufList = [];
    }
  };
  terminOptions: DevExpress.ui.dxSelectBoxOptions = {
    searchEnabled: true,
    showClearButton: true,
    displayExpr: "DisplayText",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "privateModel.terminDataSource",
      readOnly: "!model.IdGeschaeftspartner || model._IsVerwendet",
      value: "model.IdGeschaeftspartnerTermin"
    }
  };
  personOptions: DevExpress.ui.dxSelectBoxOptions = {
    searchEnabled: true,
    showClearButton: true,
    displayExpr: "Name",
    valueExpr: "Id",
    bindingOptions: {
      readOnly: "model._IsVerwendet || model.IdGeschaeftspartner",
      value: "model.IdPerson"
    }
  };
  startOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this._globalizationService.getFormatterParser("d"),
    showClearButton: true,
    bindingOptions: {
      readOnly: "model._IsVerwendet",
      value: "model.Start"
    },
    onValueChangedByUser: () => {
      this.createTerminDataSource();
    }
  };
  endeOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this._globalizationService.getFormatterParser("d"),
    showClearButton: true,
    bindingOptions: {
      readOnly: "model._IsVerwendet",
      value: "model.Ende"
    },
    onValueChangedByUser: () => {
      this.createTerminDataSource();
    }
  };
  nichtZurAuswahlOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "nicht mehr zur Auswahl anzeigen",
    bindingOptions: {
      value: "model.NichtMehrZurAuswahl"
    }
  };
  validationGroup: IdxValidationGroupComponent;

  speichernCommand: ICommandData = {
    id: "speichernCommand",
    icon: "fas fa-save",
    tooltip: "base.save",
    sortIndex: 1000,
    isVisible: true,
    execute: () => {
      if (!this.validate()) {
        return;
      }

      this.saveSchnupperterminTag();
    }
  };
  loeschenCommand: ICommandData = {
    id: "loeschenCommand",
    icon: "fas fa-trash-alt",
    tooltip: "base.delete",
    sortIndex: 1050,
    isVisible: true,
    execute: async() => {
      const r = await this._dialogConfirmService.show({
        title: this._localizationService.translateOnce("base.question"),
        message: this._localizationService.translateOnce("base.sure_delete_question")
      });
      
      if (!r) {
        return;
      }

      this.deleteSchnupperterminTag();
    }
  };

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: null
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "schnupperterminTagToolbar",
      caption: "schnuppertermin.schnuppertermin-tage",
      scopeContainer: this.scopeContainer,
      options: this.popupOptions,
      commands: [this.speichernCommand, this.loeschenCommand]
    });

    this.createSchuleDataSource();
    this.createGeschaeftspartnerDataSource();
    this.createPersonDataSource();

    this.bezeichnungOptions["validators"] = [this.createRequiredRule("Bezeichnung")];
    this.typOptions["validators"] = [this.createRequiredRule("Typ")];
    this.startOptions["validators"] = [this.createRequiredRule("Start")];
    this.endeOptions["validators"] = [this.createRequiredRule("Ende")];
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async show(options: ISchnupperterminTagShowOptions) {
    this._callbackOnChanges = options.callbackOnChanges;
    this.resetModel();

    const isKunde = this._startupService.startupInfo.Benutzer.CodeBenutzerrolle == "KUNDE";
    const isKundeSchule = this._startupService.startupInfo.Benutzer.CodeBenutzerrolle == "KUNDE-SCHULE";

    this.privateModel.isGeschaeftspartnerReadOnly = isKunde;
    this.privateModel.isSchuleReadOnly = isKundeSchule;

    if (options.idSchnupperterminTag) {
      await this.loadSchnupperterminTag(options.idSchnupperterminTag);
    } else {
      if (options.idGeschaeftspartner) {
        this.model.IdGeschaeftspartner = options.idGeschaeftspartner;
      }
      if (options.idPerson) {
        this.model.IdPerson = options.idPerson;
      }
      if (options.idSchule) {
        this.privateModel.idSchuleList.push(options.idSchule);
      }
      if (options.start) {
        this.model.Start = options.start;
      }
      if (options.ende) {
        this.model.Ende = options.ende;
      }
    }

    this.loeschenCommand.isVisible = !this.model._IsVerwendet && !!this.model.Id;
    
    this.createTerminDataSource();
    this.createLehrberufDataSource();

    await this.popup.instance.show();
    this.popup.instance.repaint();
  }
  close(hasChangedSomething: boolean = true) {
    if (hasChangedSomething && this._callbackOnChanges) {
      this._callbackOnChanges();
    }

    this.popup.instance.hide();
  }

  private createSchuleDataSource() {
    this.schuleOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer, {
        webApiAction: "ZIT/Simple/Geschaeftspartner",
        webApiWhere: ["IsGeschaeftspartnerSchule", true],
        webApiOrderBy: [{ columnName: "Name1", sortOrder: 0 }]
      }
    );
  }
  private createGeschaeftspartnerDataSource() {
    this.geschaeftspartnerOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer, {
        webApiAction: "ZIT/Simple/Geschaeftspartner",
        webApiOrderBy: [{ columnName: "Name1", sortOrder: 0 }]
      }
    );
  }
  private createTerminDataSource() {
    if (this.model && this.model.IdGeschaeftspartner && this.model.Start && this.model.Ende) {
      this.privateModel.terminDataSource = this._dataSourceService.createDataSource(
        this.scopeContainer, {
          webApiAction: "ZIT/Objekte/Termin",
          webApiColumns: ["Id", "DisplayText"],
          webApiWhere: [
            ["Kopf.IdGeschaeftspartner", this.model.IdGeschaeftspartner], 
            ["IsSchnuppertermin", false],
            ["Kopf.EndeDatum", ">=", this.model.Start],
            ["Kopf.StartDatum", "<=", this.model.Ende],
            ["ObjektStatusCode", "VERTEILT"]
          ],
          webApiOrderBy: [{ columnName: "DisplayText", sortOrder: 0 }]
        }
      );
    } else {
      this.privateModel.terminDataSource = [];
    }    
  }
  private createPersonDataSource() {
    this.personOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer, {
        webApiAction: "ZIT/Objekte/Person",
        webApiColumns: ["Id", "Name"],
        webApiOrderBy: [{ columnName: "Name", sortOrder: 0 }],
        filters: [{
          webApiCustomKey: "MitBewerbungGeschaeftspartnerSchule", webApiCustomValue: "true"
        }]
      }
    );
  }
  private createLehrberufDataSource() {
    if (this.model && this.model.IdGeschaeftspartner) {
      this.privateModel.lehrberufDataSource = this._dataSourceService.createDataSource(
        this.scopeContainer, {
          webApiAction: "ZIT/Stammdaten/Lehrberuf",
          webApiColumns: ["Id", "Bezeichnung"],
          webApiOrderBy: [{ columnName: "SortNr", sortOrder: 0 }, { columnName: "Bezeichnung", sortOrder: 0 }],
          filters: [
            { webApiCustomKey: "IdGeschaeftspartner", webApiCustomValue: this.model.IdGeschaeftspartner.toString() }
          ]
        }
      );
    } else {
      this.privateModel.lehrberufDataSource = [];
    }
  }
  private createRequiredRule(text: string) {
    return <DevExpress.ui.CustomRule>{
      type: "required",
      message: this._localizationService.translateOnce("forms.validator_required", [text])
    };
  }

  private async loadSchnupperterminTag(id: number) {
    this.model = await this._restService.get({
      url: this._restService.getWebApiUrl(`ZIT/Stammdaten/SchnupperterminTag/${id}`),
      getOptions: {
        expand: {
          SchnupperterminTagZuSchulen: null,
          SchnupperterminTagZuLehrberufe: null
        }
      },
      increaseLoadingCount: true
    });

    this.privateModel.idSchuleList = this.model.SchnupperterminTagZuSchulen.map((i) => i.IdGeschaeftspartnerSchule);
    this.privateModel.idLehrberufList = this.model.SchnupperterminTagZuLehrberufe.map((i) => i.IdLehrberuf);
  }
  private async saveSchnupperterminTag() {
    this.convertTagBoxToObject(this.privateModel.idSchuleList, this.model.SchnupperterminTagZuSchulen, "IdGeschaeftspartnerSchule");
    this.convertTagBoxToObject(this.privateModel.idLehrberufList, this.model.SchnupperterminTagZuLehrberufe, "IdLehrberuf");

    await this._restService.post({
      url: this._restService.getWebApiUrl("ZIT/Stammdaten/SchnupperterminTag"),
      data: this.model,
      increaseLoadingCount: true
    });

    DevExpress.ui.notify(
      this._localizationService.translateOnce("base.save_success"),
      "SUCCESS",
      3000
    );

    this.close();
  }
  private async deleteSchnupperterminTag() {
    await this._restService.delete({
      url: this._restService.getWebApiUrl("ZIT/Stammdaten/SchnupperterminTag/"),
      id: this.model.Id,
      increaseLoadingCount: true
    });

    this.close();
  }
  private convertTagBoxToObject(idList: number[], dataList: any[], property: string) {
    for (const id of idList) {
      const exists = dataList.some((i) => i[property] == id);
      if (exists) {
        continue;
      }

      const newObj = {};
      newObj[property] = id;
      dataList.push(newObj);
    }

    const deleteIndex: number[] = [];
    dataList.forEach((item, index) => {
      const exists = idList.some((i) => item[property] == i);
      if (exists) {
        return;
      }

      deleteIndex.push(index);
    });

    for (let i = deleteIndex.length - 1; i >= 0; i--) {
      dataList.splice(i, 1);
    }
  }
  private validate(): boolean {
    const validationResult = this.validationGroup.instance.validate();
    if (!validationResult.isValid) {
      DevExpress.ui.notify(
        validationResult.brokenRules[0].message,
        "ERROR",
        3000
      );

      return false;
    }

    if (this.privateModel.idSchuleList.length == 0) {
      DevExpress.ui.notify(
        "Es muss min. eine Schule ausgewählt werden",
        "ERROR",
        3000
      );

      return false;
    }

    if (this.model.Mindestdauer != void(0) && this.model.Standarddauer != void(0)) {
      if (this.model.Mindestdauer > this.model.Standarddauer) {
        DevExpress.ui.notify(
          "Standarddauer kann nicht kleiner als Mindestdauer sein",
          "ERROR",
          3000
        );
        return false;
      }
    }
    if (this.model.Maximaldauer != void(0) && this.model.Standarddauer != void(0)) {
      if (this.model.Maximaldauer < this.model.Standarddauer) {
        DevExpress.ui.notify(
          "Standarddauer kann nicht größer als Maximaldauer sein",
          "ERROR",
          3000
        );
        return false;
      }
    }
    if (this.model.Mindestdauer != void(0) && this.model.Maximaldauer != void(0)) {
      if (this.model.Mindestdauer > this.model.Maximaldauer) {
        DevExpress.ui.notify(
          "Maximaldauer kann nicht kleiner als Minimaldauer sein",
          "ERROR",
          3000
        );
        return false;
      }
    }

    return true;
  }
  private resetModel() {
    this.model = {
      SchnupperterminTagZuLehrberufe: [],
      SchnupperterminTagZuSchulen: [],
      NichtMehrZurAuswahl: false
    };

    this.privateModel = {
      idSchuleList: [],
      idLehrberufList: [],
      isGeschaeftspartnerReadOnly: false,
      isSchuleReadOnly: false,
      terminDataSource: [],
      lehrberufDataSource: []
    };
  }
}

export interface ISchnupperterminTagShowOptions {
  idSchnupperterminTag?: number;

  idGeschaeftspartner?: number;
  idSchule?: number;
  idPerson?: number;
  start?: Date;
  ende?: Date;

  callbackOnChanges?: {(): void};
}
interface IModel {
  Id?: number;

  Bezeichnung?: string;
  IdGeschaeftspartner?: number;
  IdGeschaeftspartnerTermin?: number;
  IdPerson?: number;
  Typ?: SchnupperterminTagTyp;

  Start?: Date;
  Ende?: Date;

  Standarddauer?: number;
  Mindestdauer?: number;
  Maximaldauer?: number;
  MaxAnzahlTeilnehmer?: number;

  NichtMehrZurAuswahl: boolean;

  SchnupperterminTagZuSchulen?: {Id?: number; IdSchnupperterminTag?: number; IdGeschaeftspartnerSchule?: number}[];
  SchnupperterminTagZuLehrberufe?: {Id?: number; IdSchnupperterminTag?: number; IdLehrberuf?: number}[];

  _IsVerwendet?: boolean;
}
interface IPrivateModel {
  idSchuleList?: number[];
  idLehrberufList?: number[];

  isGeschaeftspartnerReadOnly: boolean;
  isSchuleReadOnly: boolean;

  lehrberufDataSource: DevExpress.data.DataSource | any[];
  terminDataSource: DevExpress.data.DataSource | any[];
}
export enum SchnupperterminTagTyp {
  Schule = 0,
  Individuell = 1,
  Geschaeftspartner = 2
}