import * as fwx from "../../../../framework/forms/form-export";
import {
    GeschaeftspartnerAdresseEditUtils
} from "./geschaeftspartner-adresse-edit-utils";

@fwx.autoinject
export class GeschaeftspartnerAdresseEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_Adresse: GeschaeftspartnerAdresseEditUtils) {
        super(element, formBaseImport);
        this.id = "geschaeftspartner-adresse-edit";
        this.title = "geschaeftspartner-adresse-edit.geschaeftspartner-adresse-edit_caption";
        this.addModel({
            "id": "$m_GeschaeftspartnerAdresse",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerAdresse",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addFunction("$f_Adresse", $f_Adresse, "functions.$f_Adresse");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "geschaeftspartner-adresse-edit.r_geschaeftspartneradressestrasse_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerAdresse",
                "bindTo": "Strasse",
                "bindToFQ": "models.data.$m_GeschaeftspartnerAdresse.Strasse"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartnerAdresseStrasse",
            "options": {
                "optionsName": "r_geschaeftspartnerAdresseStrasseOptions",
                "optionsNameFQ": "r_geschaeftspartnerAdresseStrasseOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "geschaeftspartner-adresse-edit.r_geschaeftspartneradressestrassezusatz_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerAdresse",
                "bindTo": "StrasseZusatz",
                "bindToFQ": "models.data.$m_GeschaeftspartnerAdresse.StrasseZusatz"
            },
            "validationRules": [],
            "id": "r_geschaeftspartnerAdresseStrasseZusatz",
            "options": {
                "optionsName": "r_geschaeftspartnerAdresseStrasseZusatzOptions",
                "optionsNameFQ": "r_geschaeftspartnerAdresseStrasseZusatzOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "staat",
            "filters": [],
            "caption": "geschaeftspartner-adresse-edit.r_geschaeftspartneradresseland_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerAdresse",
                "bindTo": "IdLand",
                "bindToFQ": "models.data.$m_GeschaeftspartnerAdresse.IdLand"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartnerAdresseLand",
            "options": {
                "optionsName": "r_geschaeftspartnerAdresseLandOptions",
                "optionsNameFQ": "r_geschaeftspartnerAdresseLandOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 20,
            "caption": "geschaeftspartner-adresse-edit.r_geschaeftspartneradresseplz_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerAdresse",
                "bindTo": "Postleitzahl",
                "bindToFQ": "models.data.$m_GeschaeftspartnerAdresse.Postleitzahl"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_Adresse.onPLZChanged",
            "id": "r_geschaeftspartnerAdressePlz",
            "options": {
                "optionsName": "r_geschaeftspartnerAdressePlzOptions",
                "optionsNameFQ": "r_geschaeftspartnerAdressePlzOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "geschaeftspartner-adresse-edit.r_geschaeftspartneradresseort_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerAdresse",
                "bindTo": "Ort",
                "bindToFQ": "models.data.$m_GeschaeftspartnerAdresse.Ort"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartnerAdresseOrt",
            "options": {
                "optionsName": "r_geschaeftspartnerAdresseOrtOptions",
                "optionsNameFQ": "r_geschaeftspartnerAdresseOrtOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "adresstyp",
            "customs": [{
                "key": "objektTyp",
                "value": "'TIP.ZIT.Business.Entities.Objekte.Geschaeftspartner.Geschaeftspartner'"
            }],
            "filters": [],
            "caption": "geschaeftspartner-adresse-edit.r_geschaeftspartneradressetyp_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerAdresse",
                "bindTo": "IdAdresstyp",
                "bindToFQ": "models.data.$m_GeschaeftspartnerAdresse.IdAdresstyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartnerAdresseTyp",
            "options": {
                "optionsName": "r_geschaeftspartnerAdresseTypOptions",
                "optionsNameFQ": "r_geschaeftspartnerAdresseTypOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "geschaeftspartner-adresse-edit.r_geschaeftspartneradressegueltigvon_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerAdresse",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_GeschaeftspartnerAdresse.GueltigVon"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartnerAdresseGueltigVon",
            "options": {
                "optionsName": "r_geschaeftspartnerAdresseGueltigVonOptions",
                "optionsNameFQ": "r_geschaeftspartnerAdresseGueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "geschaeftspartner-adresse-edit.r_geschaeftspartneradressegueltigbis_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerAdresse",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_GeschaeftspartnerAdresse.GueltigBis"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartnerAdresseGueltigBis",
            "options": {
                "optionsName": "r_geschaeftspartnerAdresseGueltigBisOptions",
                "optionsNameFQ": "r_geschaeftspartnerAdresseGueltigBisOptions"
            }
        });
        super.onConstructionFinished();
    }
}