import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, TaskQueue } from "aurelia-framework";
import { LocalizationService, ScopeContainer } from "../../../framework/base/export";
import { BenutzerTyp } from "../../enumerations/export";
import { IdxTextBoxComponent, IErklaerungsvideo } from "../../interfaces/export";
import { ErklaervideoService } from "../../services/erklaervideo-service";
import { SchnupperterminAuswahl } from "../schnuppertermin-auswahl/schnuppertermin-auswahl";
import { SchnupperterminErstellen } from "../schnuppertermin-erstellen/schnuppertermin-erstellen";
import { RestService } from "./../../../framework/base/services/rest-service";
import { ListView } from "./../../../framework/forms/elements/list-view/list-view";
import { IListViewOptions } from "./../../../framework/forms/elements/list-view/list-view-options";
import { IdxCheckBoxComponent, IdxNumberBoxComponent, IdxSelectBoxComponent } from "./../../interfaces/dx-components";
import { StartupService } from "./../../services/startup-service";

@autoinject
export class Schnuppertermin {
  private _executeSucheSubscription: Subscription;
  private _DATASOURCEPAGESIZE: number = 48;

  constructor(
    private _taskQueue: TaskQueue,
    private _restService: RestService,
    private _startupService: StartupService,
    private _localizationService: LocalizationService,
    private _erklaervideoService: ErklaervideoService,
    private _eventAggregator: EventAggregator) {
    const benutzertyp = _startupService.startupInfo.Benutzer.Benutzertyp;

    const isAdminOrJuristischePerson = benutzertyp == BenutzerTyp.Administrator
      || benutzertyp == BenutzerTyp.JuristischePerson;

    this.isAdminOrJuristischePerson = isAdminOrJuristischePerson;
  }

  filterData: any;
  lehrberufe: any[];
  dataSource: DevExpress.data.DataSource = null;
  isAdminOrJuristischePerson: boolean;
  totalCount: number;
  erklaervideo: IErklaerungsvideo;
  schnupperterminErstellen: SchnupperterminErstellen;
  schnupperterminAuswahl: SchnupperterminAuswahl;
  scopeContainer: ScopeContainer;

  listView: ListView;
  listViewOptions: IListViewOptions = {
    useDefaultListItemStyle: false,
    itemClass: "col-xs-12 col-sm-6 col-lg-4 col-xl-3"
  };

  geschaeftspartnerTextBox: IdxTextBoxComponent;
  geschaeftspartnerTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    onValueChangedByUser: (e: any) => this.onValueChangedByUser(e.value, "Geschaeftspartner")
  };

  lehrberufSelectBox: IdxSelectBoxComponent;
  lehrberufSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Bezeichnung",
    displayExpr: "Bezeichnung",
    showClearButton: true,
    searchEnabled: true,
    acceptCustomValue: true,
    onValueChangedByUser: (e: any) => this.onValueChangedByUser(e.value, "Lehrberuf"),
    onCustomItemCreating(e: any) {
      e.customItem = {
        Bezeichnung: e.text
      };
    }
  };

  ortTextBox: IdxTextBoxComponent;
  ortTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    onValueChangedByUser: (e: any) => this.onValueChangedByUser(e.value, "Ort")
  };

  umkreisNumberBox: IdxNumberBoxComponent;
  umkreisNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    min: 0,
    max: 10000,
    value: null,
    onValueChangedByUser: (e: any) => this.onValueChangedByUser(e.value, "Umkreis")
  };

  merklisteCheckBox: IdxCheckBoxComponent;
  merklisteCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: this._localizationService.translateOnce("schnuppertermin.merkliste"),
    onValueChangedByUser: (e: any) => { this.onValueChangedByUser(e.value, "Merkliste"); }
  };

  meineSchnuppertermineCheckBox: IdxCheckBoxComponent;
  meineSchnuppertermineCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: this._localizationService.translateOnce("schnuppertermin.meine-schnuppertermine"),
    onValueChangedByUser: (e: any) => { this.onValueChangedByUser(e.value, "MeineSchnuppertermine"); }
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.filterData = {
      Umkreis: 0
    };

    this.createSchnupperterminDataSource();
    this.erklaervideo = this._erklaervideoService.getErklaerungsvideo("SCHNUPPERTERMINMASKE");
    this.loadLehrberufe();
  }

  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  attached() {
    this._executeSucheSubscription = this._eventAggregator.subscribe(
      "schnuppertermin:refresh-list",
      () => this.executeSuche());
  }

  detached() {
    this._executeSucheSubscription.dispose();
    this._executeSucheSubscription = null;
  }

  onShowErstellen(e: CustomEvent) {
    e.stopPropagation();
    e.preventDefault();

    const openSchnuppertermin = (idEventTeilnehmer) => {
      this.schnupperterminErstellen.show({
        idStelleninserat: e.detail.idStelleninserat,
        idEventTeilnehmer: idEventTeilnehmer
      });
    };

    if (e.detail.idEventTeilnehmer) {
      openSchnuppertermin(e.detail.idEventTeilnehmer);
    } else {
      this.schnupperterminAuswahl.evalIdEventTeilnehmer({
        idStelleninserat: e.detail.idStelleninserat,
        callback: (idEventTeilnehmer) => openSchnuppertermin(idEventTeilnehmer)
      });
    }
  }
  
  onHilfeClick() {
    this._erklaervideoService.erklaerungsvideoAction(this.erklaervideo)();
  }

  private executeSuche() {
    this._taskQueue.queueTask(() => {
      this.listView.refresh(false);
    });
  }

  private onValueChangedByUser(value: any, property: string) {
    this.filterData[property] = value;

    this.executeSuche();
  }

  private async createSchnupperterminDataSource() {
    this.dataSource = new DevExpress.data.DataSource(
      <any>new DevExpress.data.CustomStore({
        load: async(e) => {
          this.filterData.Take = e.take;
          this.filterData.Skip = e.skip;

          const data: ISchnupperterminContainer = await this._restService.post({
            url: this._restService.getApiUrl("ZIT/Schnuppertermin/SchnupperterminData"),
            data: this.filterData,
            increaseLoadingCount: true
          });

          this.totalCount = data.TotalElementCount;

          return {
            data: data.SchnupperterminHelperList,
            totalCount: data.TotalElementCount
          };
        }
      })
    );

    this.dataSource.pageSize(this._DATASOURCEPAGESIZE);
    this.listViewOptions.dataSource = this.dataSource;
  }

  private async loadLehrberufe() {
    this.lehrberufe = await this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Stammdaten/Lehrberuf"),
      getOptions: {
        orderBy: [{ columnName: "Bezeichnung", sortOrder: 0 }]
      }
    });

    if (this.lehrberufSelectBox) {
      this.lehrberufSelectBox.instance.option("dataSource", this.lehrberufe);
    } else {
      this.lehrberufSelectBoxOptions.dataSource = this.lehrberufe;
    }
  }
}

export interface ISchnupperterminContainer {
  SchnupperterminHelperList: ISchnuppertermin[];
  TotalElementCount: number;
}
export interface ISchnuppertermin {
  IdStelleninserat: number;
  IdGeschaeftspartner: number;
  IdEventTeilnehmer: number;
  IdStornoGrund: number;
  IdLehrberuf: number;
  IsTerminBestaetigt: boolean;
  TerminBestaetigtDatum: string;
  Geschaeftspartner: string;
  UrlGeschaeftspartner: string;
  Ort: string;
  GoogleKartenAdresse: string;
  BezeichnungExtern: string;
  Lehrberuf: string;
  LehrberufUrl: string;
  Ansprechperson: string;
  AnsprechpersonTelefonnummer: string;
  AnsprechpersonAlternativeTelefonnummer: string;
  Telefonnummer: string;
  TelefonnummerClickToCall: string;
  GeschaeftspartnerHasGebuchtesPackage: boolean;
  ShowKundenlogo: boolean;
  DMSLinkKundenlogo: string;
  UrlStelleninserat: string;
  SortDatum: Date;
  IsMerkerAktiv: boolean;
  HasDokument: boolean;
  SchnupperterminAnfrageart: string;
  KeineDirekteKontaktaufnahmeErforderlich: boolean;
  AnzTermine: number;
  ErstellungSchnupperterminVerhindern: boolean;
  VeroeffentlichungTyp: number;
}
