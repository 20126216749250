import { ErklaerungsvideoInhaltArtEnum } from './../enumerations/erklaerungsvideo-inhalt-art-enum';
import { KontaktpersonTyp } from './../enumerations/kontaktperson-typ';
import { Geschlecht } from './../enumerations/geschlecht';
import { BenutzerTyp } from './../enumerations/export';
import { ErklaerungsvideoAnzeigeEnum } from '../enumerations/erklaerungsvideo-anzeige-enum';

export interface IStartupInfo {
  Benutzer: {
    IdBenutzer: number;
    CodeBenutzerrolle: string;
    IdBenutzerrolle: number;
    IdLoginprofil: number;
    Benutzername: string;
    Benutzertyp: BenutzerTyp;
    KontaktpersonTyp: KontaktpersonTyp;
    Email: string;
    HtmlTextHilfe: string;
    Nachname: string;
    Vorname: string;
    CanBenutzeraccountAktivieren: boolean;
    CanBenutzeraccountSperren: boolean;
    CanStandardStelleninseratErstellen: boolean;
    CanPost: boolean;
    CanStatusAendern: boolean;
    CanTerminErstellen: boolean;
    CanUseMarkers: boolean;
    CanUsePersonenDossier: boolean;
    CanViewDokumentDetails: boolean;
    HasNachrichten: boolean;
    CanNachrichtMassennachricht: boolean;
    HasNachrichtStellvertreter: boolean;
    HasNachrichtInterneNotiz: boolean;
    NachrichtSignatur: string;
    IsWhatsappAktiv: boolean;
    IsGeschaeftspartnerSchuleBenutzer: boolean;
    DruckvorlageObjektFullNameList: string[];
    Geschlecht: Geschlecht;
    IdPerson?: number;
    IdAnsprechperson?: number;
    IdGeschaeftspartner?: number;
    DMSLinkAvatar?: string;
    UrlAgb: string;
  };
  Layout: {
    IdBenutzerrolle: number;
    CardList: ICardStartupInfo[];
  };
  Mandant: {
    IdMandant: number;
    Bezeichnung: string;
    ImpressumHtmlText: string;
    WhatsAppTelefonnummer: string;
    DMSLinkVisitenkarte: string;
    IsWhatsappAktiv: boolean;
    IsSmsAktiv: boolean;
    ShowSchnupperterminMaske: boolean;
    UrlDatenschutz: string;
    UrlImpressum: string;
  };
  Skin: {
    IdBenutzerrolle: number;
    DMSLinkHintergrundbild: string;
    DMSLinkLogoKlein: string;
    Farbe: string;
    Farbe20Opacity?: string;
  };
  KopfzeileVerknuepfungList: IKopfzeileVerknuepfung[];
  ObjektBerechtigungList: IObjektBerechtigung[];
  TooltipList: ITooltip[];
  SucheStandardFilterList: string[];
  ErklaerungsvideoLayoutList: IErklaerungsvideo[];
  MenuList: IBenutzerrolleMenu[];
  CanChangeLoginprofil: boolean;
  CanChangeUser: boolean;
  CanAufgabenZuweisen: boolean;
  HasUrsprBenutzer: boolean;
  UrlVerlinkungWebsite: string;
  ShowSchnupperterminMaske: boolean;
  HasStammdatenMaske?: boolean;
  HasAlleStammdatenMasken?: boolean;
  IsApp: boolean;
  StammdatenPraefixList?: string[];
}

export interface ICardStartupInfo {
  CardGross: string;
  CardGrossHintergrundfarbe: string;
  CardGrossSymbol: string;
  CardKlein: string;
  CardKleinHintergrundfarbe: string;
  CardKleinSymbol: string;
  CardKompakt: string;
  CardKompaktHintergrundfarbe: string;
  CardKompaktSymbol: string;
  Objekttyp: string;
}
export interface IObjektBerechtigung {
  ObjektFullName: string;
  AllowNew: boolean;
}
export interface ITooltip {
  Key: string;
  Tooltip: string;
}
export interface IErklaerungsvideo {
  Key: string;
  Bezeichnung: string;
  InhaltArt: ErklaerungsvideoInhaltArtEnum;
  IsCodeHinterlegt: boolean;
  IdErklaerungsvideo: number;
  Content: string;
  Anzeige?: ErklaerungsvideoAnzeigeEnum;
}
export interface IKopfzeileVerknuepfung {
  Bezeichnung: string;
  Url: string;
  IdMandant: number;
  IdLoginprofil: number;
  DMSLinkLogo: string;
}
export interface IBenutzerrolleMenu {
  Route: string;
  BezeichnungDesktop: string;
  SortNrDesktop: number;
  BezeichnungMobil: string;
  SortNrMobil: number;
  IsShortcutDesktop: boolean;
  ShowInDesktop: boolean;
  ShowInMobil: boolean;
}

export enum AnredeformTyp {
  Sie = 0,
  Du = 1
}
