import * as fwx from "../../../../framework/forms/form-export";
import {
    EventEditUtils
} from "./event-edit-utils";

@fwx.autoinject
export class EventEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_EventEditUtils: EventEditUtils) {
        super(element, formBaseImport);
        this.id = "event-edit";
        this.title = "event-edit.event-edit_caption";
        this.addModel({
            "id": "$m_Event",
            "webApiAction": "ZIT/Objekte/Event",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'Lehrberufe': null,
                        'Anmeldungsgruppen': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_Lehrberuf",
            "webApiAction": "ZIT/Stammdaten/Lehrberuf",
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "IdGeschaeftspartner",
                "webApiCustomValue": "models.data.$m_Event.Kopf.IdGeschaeftspartner"
            }]
        });
        this.addModel({
            "id": "$m_Anmeldungsgruppe",
            "webApiAction": "ZIT/Stammdaten/EventAnmeldungsgruppe",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_EventTyp",
            "webApiAction": "ZIT/Stammdaten/EventTyp",
            "key": "models.data.$m_Event.Kopf.IdEventTyp",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_EventZeitraumRel",
            "webApiAction": "ZIT/Objekte/EventZeitraum",
            "webApiExpand": {
                'Kopf': null
            },
            "webApiOrderBy": [{
                columnName: 'Kopf.StartDatum',
                sortOrder: 0
            }],
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "AddSchulkontingentSum",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_EventZeitraumEdit",
            "webApiAction": "ZIT/Objekte/EventZeitraum",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_EventTeilnehmerEdit",
            "webApiAction": "ZIT/Objekte/EventTeilnehmer",
            "webApiWhere": ["IdEvent", {
                "isBound": true,
                "expression": "models.data.$m_Event.Id"
            }],
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "AddEventTeilnehmerCard",
                "webApiCustomValue": "true"
            }, {
                "if": "functions.$f_EventEditUtils.idEventZeitraumFilter",
                "webApiWhere": ["IdEventZeitraum", {
                    "isBound": true,
                    "expression": "functions.$f_EventEditUtils.idEventZeitraumFilter"
                }]
            }, {
                "if": "functions.$f_EventEditUtils.eventTeilnehmerFilter",
                "webApiCustomKey": "EventTeilnehmerNameFilter",
                "webApiCustomValue": "functions.$f_EventEditUtils.eventTeilnehmerFilter"
            }]
        });
        this.addModel({
            "id": "$m_EventLinkAnmeldung",
            "webApiAction": "ZIT/Objekte/EventLinkAnmeldung",
            "webApiExpand": {
                'Element': {
                    'expand': {
                        'Ausbildung': null,
                        'EventAnmeldungsgruppe': null
                    }
                }
            },
            "webApiWhere": ["IdEvent", {
                "isBound": true,
                "expression": "models.data.$m_Event.Id"
            }],
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_EventLinkAnmeldungEdit",
            "webApiAction": "ZIT/Objekte/EventLinkAnmeldung",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EventEditUtils.actionCommand",
                "bindToFQ": "functions.$f_EventEditUtils.actionCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EventEditUtils.statusAendernPopupCommand",
                "bindToFQ": "functions.$f_EventEditUtils.statusAendernPopupCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EventEditUtils.kopierenOptionenCommand",
                "bindToFQ": "functions.$f_EventEditUtils.kopierenOptionenCommand"
            }
        });
        this.addEditPopup({
            "idContent": "eventZeitraumEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_EventZeitraumEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_EventZeitraumEdit.Id"
                }
            }],
            "id": "eventZeitraumEditPopup",
            "options": {
                "optionsName": "eventZeitraumEditPopupOptions",
                "optionsNameFQ": "eventZeitraumEditPopupOptions"
            },
            "height": "90vh",
            "width": "1000px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "eventLinkAnmeldungEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_EventLinkAnmeldungEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_EventLinkAnmeldungEdit.Id"
                }
            }],
            "id": "eventLinkAnmeldungEditPopup",
            "options": {
                "optionsName": "eventLinkAnmeldungEditPopupOptions",
                "optionsNameFQ": "eventLinkAnmeldungEditPopupOptions"
            },
            "height": "auto",
            "width": "700px",
            "commands": []
        });
        this.addFunction("$f_EventEditUtils", $f_EventEditUtils, "functions.$f_EventEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "eventTyp",
            "filters": [],
            "caption": "event-edit.r_eventtyp_caption",
            "isReadOnlyExpression": "models.data.$m_Event.Kopf.Id > 0",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.IdEventTyp",
                "bindToFQ": "models.data.$m_Event.Kopf.IdEventTyp",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_eventTyp",
            "options": {
                "optionsName": "r_eventTypOptions",
                "optionsNameFQ": "r_eventTypOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "event-edit.r_websiteeventanmeldunglink_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "_WebsiteEventAnmeldungLink",
                "bindToFQ": "models.data.$m_Event._WebsiteEventAnmeldungLink"
            },
            "validationRules": [],
            "id": "r_websiteEventAnmeldungLink",
            "options": {
                "optionsName": "r_websiteEventAnmeldungLinkOptions",
                "optionsNameFQ": "r_websiteEventAnmeldungLinkOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "event-edit.r_showveranstaltungsmaske_caption",
            "isReadOnlyExpression": "!models.data.$m_Event.Kopf.Id",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.ShowVeranstaltungsmaske",
                "bindToFQ": "models.data.$m_Event.Kopf.ShowVeranstaltungsmaske",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_showVeranstaltungsmaske",
            "options": {
                "optionsName": "r_showVeranstaltungsmaskeOptions",
                "optionsNameFQ": "r_showVeranstaltungsmaskeOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "eventVeroeffentlichung",
            "filters": [],
            "caption": "event-edit.r_veroeffentlichung_caption",
            "isReadOnlyExpression": "functions.$f_EventEditUtils.isTypAuswahlEnabled",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.Veroeffentlichung",
                "bindToFQ": "models.data.$m_Event.Kopf.Veroeffentlichung",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_veroeffentlichung",
            "options": {
                "optionsName": "r_veroeffentlichungOptions",
                "optionsNameFQ": "r_veroeffentlichungOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "event-edit.r_veroeffnetlichenfiltergeschaeftspartner_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.VeroeffentlichenFilterGeschaeftspartner",
                "bindToFQ": "models.data.$m_Event.Kopf.VeroeffentlichenFilterGeschaeftspartner",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_veroeffnetlichenFilterGeschaeftspartner",
            "options": {
                "optionsName": "r_veroeffnetlichenFilterGeschaeftspartnerOptions",
                "optionsNameFQ": "r_veroeffnetlichenFilterGeschaeftspartnerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "eventTeilnehmerReaktion",
            "filters": [],
            "caption": "event-edit.r_teilnehmerreaktion_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.TeilnehmerReaktion",
                "bindToFQ": "models.data.$m_Event.Kopf.TeilnehmerReaktion",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_teilnehmerReaktion",
            "options": {
                "optionsName": "r_teilnehmerReaktionOptions",
                "optionsNameFQ": "r_teilnehmerReaktionOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "geschaeftspartner",
            "filter": ["IsGeschaeftspartnerSchule", false],
            "filters": [],
            "caption": "event-edit.r_geschaeftspartner_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.IdGeschaeftspartner",
                "bindToFQ": "models.data.$m_Event.Kopf.IdGeschaeftspartner",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_geschaeftspartner",
            "options": {
                "optionsName": "r_geschaeftspartnerOptions",
                "optionsNameFQ": "r_geschaeftspartnerOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "geschaeftspartner",
            "filter": ["IsGeschaeftspartnerSchule", true],
            "filters": [],
            "caption": "event-edit.r_geschaeftspartnerschule_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.IdGeschaeftspartnerSchule",
                "bindToFQ": "models.data.$m_Event.Kopf.IdGeschaeftspartnerSchule",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_geschaeftspartnerSchule",
            "options": {
                "optionsName": "r_geschaeftspartnerSchuleOptions",
                "optionsNameFQ": "r_geschaeftspartnerSchuleOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "event-edit.r_betreff_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.Betreff",
                "bindToFQ": "models.data.$m_Event.Kopf.Betreff",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_betreff",
            "options": {
                "optionsName": "r_betreffOptions",
                "optionsNameFQ": "r_betreffOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "event-edit.r_bezeichnungintern_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.BezeichnungIntern",
                "bindToFQ": "models.data.$m_Event.Kopf.BezeichnungIntern",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bezeichnungIntern",
            "options": {
                "optionsName": "r_bezeichnungInternOptions",
                "optionsNameFQ": "r_bezeichnungInternOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "event-edit.r_subtitel_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.Subtitel",
                "bindToFQ": "models.data.$m_Event.Kopf.Subtitel",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_subtitel",
            "options": {
                "optionsName": "r_subtitelOptions",
                "optionsNameFQ": "r_subtitelOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "event-edit.r_ort_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.Ort",
                "bindToFQ": "models.data.$m_Event.Kopf.Ort",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_ort",
            "options": {
                "optionsName": "r_ortOptions",
                "optionsNameFQ": "r_ortOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "event-edit.r_ortfuerschnuppernnachvereinbarung_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.OrtFuerSchnuppernNachVereinbarung",
                "bindToFQ": "models.data.$m_Event.Kopf.OrtFuerSchnuppernNachVereinbarung",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_ortFuerSchnuppernNachVereinbarung",
            "options": {
                "optionsName": "r_ortFuerSchnuppernNachVereinbarungOptions",
                "optionsNameFQ": "r_ortFuerSchnuppernNachVereinbarungOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "event-edit.r_meldenum_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.MeldenUm",
                "bindToFQ": "models.data.$m_Event.Kopf.MeldenUm",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_meldenUm",
            "options": {
                "optionsName": "r_meldenUmOptions",
                "optionsNameFQ": "r_meldenUmOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "event-edit.r_startuhrzeit_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.StartUhrzeit",
                "bindToFQ": "models.data.$m_Event.Kopf.StartUhrzeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_startUhrzeit",
            "options": {
                "optionsName": "r_startUhrzeitOptions",
                "optionsNameFQ": "r_startUhrzeitOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "event-edit.r_endeuhrzeit_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.EndeUhrzeit",
                "bindToFQ": "models.data.$m_Event.Kopf.EndeUhrzeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_endeUhrzeit",
            "options": {
                "optionsName": "r_endeUhrzeitOptions",
                "optionsNameFQ": "r_endeUhrzeitOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 0,
            "acceptType": "image/*",
            "height": "250px",
            "showClearButton": false,
            "caption": "event-edit.r_dmslinklogo_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.DMSLinkLogo",
                "bindToFQ": "models.data.$m_Event.Kopf.DMSLinkLogo",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_dMSLinkLogo",
            "options": {
                "optionsName": "r_dMSLinkLogoOptions",
                "optionsNameFQ": "r_dMSLinkLogoOptions"
            }
        });
        this.widgetCreator.addTagBox(this, {
            "caption": "event-edit.r_lehrberufe_caption",
            "dataContext": "$m_Event",
            "batchSelectionEnabled": true,
            "relationBinding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.Lehrberufe",
                "bindToFQ": "models.data.$m_Event.Kopf.Lehrberufe",
                "propertyPrefix": "Kopf"
            },
            "relationProperty": "IdLehrberuf",
            "itemDataContext": "$m_Lehrberuf",
            "itemValueExpr": "Id",
            "itemDisplayExpr": "Bezeichnung",
            "binding": {
                "dataContext": "$m_Event"
            },
            "validationRules": [],
            "id": "r_lehrberufe",
            "options": {
                "optionsName": "r_lehrberufeOptions",
                "optionsNameFQ": "r_lehrberufeOptions"
            }
        });
        this.widgetCreator.addTagBox(this, {
            "caption": "event-edit.r_anmeldungsgruppen_caption",
            "dataContext": "$m_Event",
            "batchSelectionEnabled": true,
            "relationBinding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.Anmeldungsgruppen",
                "bindToFQ": "models.data.$m_Event.Kopf.Anmeldungsgruppen",
                "propertyPrefix": "Kopf"
            },
            "relationProperty": "IdEventAnmeldungsgruppe",
            "itemDataContext": "$m_Anmeldungsgruppe",
            "itemValueExpr": "Id",
            "itemDisplayExpr": "Bezeichnung",
            "binding": {
                "dataContext": "$m_Event"
            },
            "validationRules": [],
            "id": "r_anmeldungsgruppen",
            "options": {
                "optionsName": "r_anmeldungsgruppenOptions",
                "optionsNameFQ": "r_anmeldungsgruppenOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "person",
            "filters": [],
            "caption": "event-edit.r_person_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.IdPerson",
                "bindToFQ": "models.data.$m_Event.Kopf.IdPerson",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_person",
            "options": {
                "optionsName": "r_personOptions",
                "optionsNameFQ": "r_personOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showClearButton": true,
            "min": 1.0,
            "max": 999.0,
            "format": "n0",
            "caption": "event-edit.r_standarddauer_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.Standarddauer",
                "bindToFQ": "models.data.$m_Event.Kopf.Standarddauer",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_standardDauer",
            "options": {
                "optionsName": "r_standardDauerOptions",
                "optionsNameFQ": "r_standardDauerOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showClearButton": true,
            "min": 1.0,
            "max": 999.0,
            "format": "n0",
            "caption": "event-edit.r_minimaldauer_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.Mindestdauer",
                "bindToFQ": "models.data.$m_Event.Kopf.Mindestdauer",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_minimalDauer",
            "options": {
                "optionsName": "r_minimalDauerOptions",
                "optionsNameFQ": "r_minimalDauerOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showClearButton": true,
            "min": 1.0,
            "max": 999.0,
            "format": "n0",
            "caption": "event-edit.r_maximaldauer_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.Maximaldauer",
                "bindToFQ": "models.data.$m_Event.Kopf.Maximaldauer",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_maximalDauer",
            "options": {
                "optionsName": "r_maximalDauerOptions",
                "optionsNameFQ": "r_maximalDauerOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "event-edit.r_webserviceterminabfrage_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.WebserviceTerminabfrage",
                "bindToFQ": "models.data.$m_Event.Kopf.WebserviceTerminabfrage",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_webserviceTerminabfrage",
            "options": {
                "optionsName": "r_webserviceTerminabfrageOptions",
                "optionsNameFQ": "r_webserviceTerminabfrageOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "event-edit.r_inschnupperterminmaskeanzeigen_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.InSchnupperterminmaskeAnzeigen",
                "bindToFQ": "models.data.$m_Event.Kopf.InSchnupperterminmaskeAnzeigen",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_inSchnupperterminmaskeAnzeigen",
            "options": {
                "optionsName": "r_inSchnupperterminmaskeAnzeigenOptions",
                "optionsNameFQ": "r_inSchnupperterminmaskeAnzeigenOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "event-edit.r_bewerbungsformularschnupperterminauswahl_caption",
            "binding": {
                "dataContext": "$m_Event",
                "bindTo": "Kopf.BewerbungsformularSchnupperterminauswahl",
                "bindToFQ": "models.data.$m_Event.Kopf.BewerbungsformularSchnupperterminauswahl",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bewerbungsformularSchnupperterminauswahl",
            "options": {
                "optionsName": "r_bewerbungsformularSchnupperterminauswahlOptions",
                "optionsNameFQ": "r_bewerbungsformularSchnupperterminauswahlOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_zeitraumBetreff",
                "caption": "event-edit.r_zeitraumbetreff_caption",
                "bindTo": "Kopf.BezeichnungInternOderBetreff"
            }, {
                "id": "r_zeitraumStartDatum",
                "caption": "event-edit.r_zeitraumstartdatum_caption",
                "bindTo": "Kopf.StartDatum",
                "width": "85px",
                "format": "d"
            }, {
                "id": "r_zeitraumStartUhrzeit",
                "caption": "event-edit.r_zeitraumstartuhrzeit_caption",
                "bindTo": "Kopf.StartUhrzeitValidated",
                "width": "85px",
                "format": "t"
            }, {
                "id": "r_zeitraumEndeDatum",
                "caption": "event-edit.r_zeitraumendedatum_caption",
                "bindTo": "Kopf.EndeDatum",
                "width": "85px",
                "format": "d"
            }, {
                "id": "r_zeitraumEndeUhrzeit",
                "caption": "event-edit.r_zeitraumendeuhrzeit_caption",
                "bindTo": "Kopf.EndeUhrzeitValidated",
                "width": "87px",
                "format": "t"
            }, {
                "id": "r_zeitraumOrt",
                "caption": "event-edit.r_zeitraumort_caption",
                "bindTo": "Kopf.OrtValidated"
            }, {
                "id": "r_zeitraumKontingentGesamt",
                "caption": "event-edit.r_zeitraumkontingentgesamt_caption",
                "bindTo": "Kopf.KontingentGesamt",
                "width": "50px",
                "format": "n0",
                "onCellPrepared": "functions.$f_EventEditUtils.onZeitraumKontingentCellPrepared"
            }, {
                "id": "r_zeitraumKontingentSchule",
                "caption": "event-edit.r_zeitraumkontingentschule_caption",
                "width": "50px",
                "onCellPrepared": "functions.$f_EventEditUtils.onZeitraumKontingentSchuleCellPrepared"
            }, {
                "id": "r_zeitraumInSchnupperterminmaskeAnzeigen",
                "caption": "event-edit.r_zeitrauminschnupperterminmaskeanzeigen_caption",
                "bindTo": "Kopf.InSchnupperterminmaskeAnzeigenValidated",
                "width": "50px",
                "onCellPrepared": "functions.$f_EventEditUtils.onSchnupperterminMaskeCellPrepared"
            }, {
                "id": "r_zeitraumWebserviceTerminabfrage",
                "caption": "event-edit.r_zeitraumwebserviceterminabfrage_caption",
                "bindTo": "Kopf.WebserviceTerminabfrageValidated",
                "width": "50px",
                "onCellPrepared": "functions.$f_EventEditUtils.onWebserviceTerminabfrageCellPrepared"
            }, {
                "id": "r_zeitraumBewerbungsformularSchnupperterminauswahl",
                "caption": "event-edit.r_zeitraumbewerbungsformularschnupperterminauswahl_caption",
                "bindTo": "Kopf.BewerbungsformularSchnupperterminauswahlValidated",
                "width": "50px",
                "onCellPrepared": "functions.$f_EventEditUtils.onBewerbungsformularSchnupperterminauswahlCellPrepared"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_eventZeitraumGridToolbarOptions",
                "optionsNameFQ": "r_eventZeitraumGridToolbarOptions"
            },
            "caption": "event-edit.r_eventzeitraumgrid_caption",
            "binding": {
                "dataContext": "$m_Event"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_EventZeitraumRel",
                "bindTo": "IdEvent",
                "bindToFQ": "models.data.$m_EventZeitraumRel.IdEvent"
            },
            "dataModel": "$m_Event",
            "editDataContext": "$m_EventZeitraumEdit",
            "idEditPopup": "eventZeitraumEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_eventZeitraumGrid",
            "options": {
                "optionsName": "r_eventZeitraumGridOptions",
                "optionsNameFQ": "r_eventZeitraumGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_linkAnmStartDatum",
                "caption": "event-edit.r_linkanmstartdatum_caption",
                "bindTo": "Element.StartDatum",
                "width": "85px",
                "format": "d"
            }, {
                "id": "r_linkAnmStartUhrzeit",
                "caption": "event-edit.r_linkanmstartuhrzeit_caption",
                "bindTo": "Element.StartUhrzeit",
                "width": "85px",
                "format": "t"
            }, {
                "id": "r_linkAnmEndeDatum",
                "caption": "event-edit.r_linkanmendedatum_caption",
                "bindTo": "Element.EndeDatum",
                "width": "85px",
                "format": "d"
            }, {
                "id": "r_linkAnmEndeUhrzeit",
                "caption": "event-edit.r_linkanmendeuhrzeit_caption",
                "bindTo": "Element.EndeUhrzeit",
                "width": "87px",
                "format": "t"
            }, {
                "id": "r_linkAnmName",
                "caption": "event-edit.r_linkanmname_caption",
                "bindTo": "Element.Name"
            }, {
                "id": "r_linkAnmTelefon",
                "caption": "event-edit.r_linkanmtelefon_caption",
                "bindTo": "Element.TelefonKomplett",
                "width": "120px"
            }, {
                "id": "r_linkAnmAnzahlTeilnehmer",
                "caption": "event-edit.r_linkanmanzahlteilnehmer_caption",
                "bindTo": "Element.AnzahlTeilnehmer",
                "width": "80px"
            }, {
                "id": "r_linkAnmAlter",
                "caption": "event-edit.r_linkanmalter_caption",
                "bindTo": "Element.Alter",
                "width": "80px"
            }, {
                "id": "r_linkAnmEmail",
                "caption": "event-edit.r_linkanmemail_caption",
                "bindTo": "Element.Email",
                "width": "80px"
            }, {
                "id": "r_linkAnmTyp",
                "caption": "event-edit.r_linkanmtyp_caption",
                "bindTo": "Element.EventAnmeldungsgruppe.Bezeichnung",
                "width": "80px"
            }, {
                "id": "r_linkAnmAusbildung",
                "caption": "event-edit.r_linkanmausbildung_caption",
                "bindTo": "Element.Ausbildung.Bezeichnung",
                "width": "80px"
            }, {
                "id": "r_linkAnmEventErfahrenDurch",
                "caption": "event-edit.r_linkanmeventerfahrendurch_caption",
                "bindTo": "Element.EventErfahrenDurch",
                "width": "80px"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_eventLinkAnmeldungGridToolbarOptions",
                "optionsNameFQ": "r_eventLinkAnmeldungGridToolbarOptions"
            },
            "caption": "event-edit.r_eventlinkanmeldunggrid_caption",
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung"
            },
            "dataModel": "$m_EventLinkAnmeldung",
            "editDataContext": "$m_EventLinkAnmeldungEdit",
            "idEditPopup": "eventLinkAnmeldungEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "dataContext": "$m_Event",
                    "bindTo": "$f_EventEditUtils.exportLinkAnmeldungCommand",
                    "bindToFQ": "functions.$f_EventEditUtils.exportLinkAnmeldungCommand"
                }
            }],
            "id": "r_eventLinkAnmeldungGrid",
            "options": {
                "optionsName": "r_eventLinkAnmeldungGridOptions",
                "optionsNameFQ": "r_eventLinkAnmeldungGridOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "col-xs-12 col-sm-6",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_eventTeilnehmerListViewToolbarOptions",
                "optionsNameFQ": "r_eventTeilnehmerListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_EventTeilnehmerEdit"
            },
            "dataModel": "$m_EventTeilnehmerEdit",
            "pageSize": 4,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_eventTeilnehmerListView",
            "options": {
                "optionsName": "r_eventTeilnehmerListViewOptions",
                "optionsNameFQ": "r_eventTeilnehmerListViewOptions"
            }
        });
        super.onConstructionFinished();
    }
}