import {
  autoinject,
  createOverrideContext  
} from "aurelia-framework";
import {
  FormBase
} from "../classes/form-base";
import {
  DataSourceService,
  LocationService
} from "../../base/services/export";
import {
  DefaultCommandsService,
  ToolbarService,
  PopupInfoService
} from "../services/export";
import {
  IDataSourceCustomizationOptions,
  IViewScrollInfo
} from "../../base/interfaces/export";
import * as WidgetOptions from "../widget-options/export";
import { ListType } from '../enums/list-type-enum';
import { AsyncService } from '../../base/services/async-service';

@autoinject
export class BaseWidgetCreatorService {
  constructor(
    private dataSource: DataSourceService,
    private location: LocationService,
    private toolbar: ToolbarService,
    private defaultCommands: DefaultCommandsService,
    private popupService: PopupInfoService,
    private asyncService: AsyncService
  ) { }

  checkListRelationEdit(form: FormBase, options: WidgetOptions.IListOptions) {
    const hasEditPopup = !!options.idEditPopup
      || (options.listEdits && options.listEdits.some(edit => !!edit.idEditPopup));

    if (!hasEditPopup || !options.isRelation) {
      return;
    }

    form.editPopups.onEditPopupModelLoaded.register(e => {
      const isMyEditPopup = options.idEditPopup == e.editPopup.id
      || (options.listEdits && options.listEdits.some(edit => edit.idEditPopup == e.editPopup.id));

      if (!isMyEditPopup) {
        return;
      }
      if (e.model.key !== "variables.data.$id") {
        return;
      }
      if (!e.data) {
        return;
      }
      if (e.data[e.model.keyProperty]) {
        return;
      }

      const info = form.models.getInfo(options.dataModel);
      e.data[options.relationBinding.bindTo] = form.models.data[info.id][info.keyProperty];

      return Promise.resolve();
    });
  }
  checkListToolbar(form: FormBase, options: WidgetOptions.IListOptions, addEditCommand: boolean = false, listType: ListType = null) {
    if (!options.createToolbar && !options.isMainList) {
      return;
    }

    const commands = this.defaultCommands.getListCommands(form, options, addEditCommand, listType);

    if (options.commands && options.commands.length) {
      commands.push(...options.commands.map(i => {
        return form.binding.evaluate(form.scope, i.binding.bindToFQ)
      }));
    }

    if (options.createToolbar) {
      form[options.optionsToolbar.optionsName] = this.toolbar.createToolbarOptions(
        form.scopeContainer,
        options.caption,
        commands,
        true,
        options.icon);
    } else if (options.isMainList) {
      commands.forEach(c => form.commands.addCommand(c));
    }
  }
  createListDataSource(form: FormBase, options: WidgetOptions.IListOptionsBase, widgetOptions: DevExpress.ui.WidgetOptions, customizationOptions?: IDataSourceCustomizationOptions): DevExpress.data.DataSource {
    if (options.dataModel) {
      const model = form.models.getInfo(options.dataModel);
      const relationModel = options.isRelation
        ? form.models.getInfo(options.relationBinding.dataContext)
        : null;

      const mainModel = relationModel || model;

      customizationOptions = customizationOptions || {};
      const canLoadList: { (): boolean }[] = [];

      let onInitialized = widgetOptions.onInitialized;
      let isInitialized = false;

      widgetOptions.onInitialized = (e) => {
        if (onInitialized) {
          onInitialized(e);
        }

        isInitialized = true;
      }

      if (options.isRelation) {
        const getCustomWhere = () => {
          let data = form.models.data && form.models.data[model.id]
            ? form.models.data[model.id][model.keyProperty]
            : "0";

          data = data || "0";

          return [options.relationBinding.bindTo, data];
        };
        const getCustomWhereParam = customizationOptions.getCustomWhere;

        customizationOptions.getCustomWhere = () => {
          const whereParam = getCustomWhereParam ? getCustomWhereParam() : null;
          const where = getCustomWhere();

          const result = [];
          if (whereParam) {
            result.push(whereParam);
          }
          if (where) {
            result.push(where);
          }

          return result;
        }

        canLoadList.push(() => {
          return !!(form.models.data && form.models.data[model.id]
            && form.models.data[model.id][model.keyProperty]);
        });
        form.binding.observe({
          scopeContainer: form.scopeContainer,
          expression: `models.data.${model.id}.${model.keyProperty}`,
          callback: () => {
            if (!isInitialized) {
              return;
            }

            dataSource.reload();
          }
        });
      }

      if (mainModel.canLoad != void(0)) {
        canLoadList.push(() => {
          return mainModel.canLoad(widgetOptions);
        });
      }

      if (canLoadList.length > 0) {
        const canLoad = () => {
          return canLoadList.every(c => c());
        };
        const canLoadParam = customizationOptions.canLoad;

        customizationOptions.canLoad = () => {
          if (!canLoad()) {
            return false;
          }
          if (canLoadParam && !canLoadParam()) {
            return false;
          }

          return true;
        };
      }

      form.models.onLoadRequired.register(r => {
        if (r.model != mainModel) {
          return Promise.resolve();
        }

        return new Promise<any>((resolve, reject) => {
          if (!r.onlyCurrentPage) {
            if (dataSource.pageIndex() > 0) {
              dataSource.pageIndex(0);
            }  
          }

          if (!isInitialized) {
            resolve();
            return;
          }
          
          this.asyncService.convertToPromise(dataSource.reload())
            .then(resolve)
            .catch(reject);
        });
      });

      const onLoaded = (data) => {
        form.models.callOnLoaded(mainModel, data);
      };
      const onLoadedParam = customizationOptions.onLoaded;
      customizationOptions.onLoaded = (data) => {
        onLoaded(data);

        if (onLoadedParam) {
          onLoadedParam(data);
        }
      };

      const dataSource = this.dataSource.createDataSource(form.scopeContainer, mainModel, customizationOptions);
      return dataSource;
    }
  }
  setListClickActions(form: FormBase, options: WidgetOptions.IListOptionsBase): void {
    const clickActions: { (e: any, dataSource?: DevExpress.data.DataSource): void }[] = [];
    const openClickActions: { (e: any, dataSource?: DevExpress.data.DataSource): void }[] = [];

    const getViewScrollInfo = (e: any, dataSource: DevExpress.data.DataSource): IViewScrollInfo => {
      const customDataSource: any = dataSource;
      if (!customDataSource.lastLoadInfo) {
        return null;
      }

      const pageSize = dataSource.pageSize();
      const pageIndex = dataSource.pageIndex();
      const pageStart = pageSize * pageIndex;

      let rowIndex = -1;
      if (e && e.rowIndex != void(0)) {
        rowIndex = e.rowIndex;

        if (!e.hasAllPages) {
          rowIndex = rowIndex + pageStart;
        }
      }

      return {
        lastLoadInfo: customDataSource.lastLoadInfo,
        index: rowIndex,
        maxCount: dataSource.totalCount()
      };
    };

    if (options.editDataContext || options.listEdits.length > 0) {
      if (options.listEdits.length > 0) {
        clickActions.push((e, ds) => {
          const edit = options.listEdits.find(c => c.typeName === e.data.ObjectTypeName);
          if (!edit) {
            return;
          }

          form.models.data[edit.editDataContext] = e.data;
        });
      } else {
        clickActions.push((e, ds) => {
          form.models.data[options.editDataContext] = e.data;
        });
      }
    }
    if (options.onItemClick) {
      clickActions.push((e, ds) => {
        form.binding.evaluate({
          bindingContext: form,
          overrideContext: createOverrideContext({
            "$event": e
          })
        }, options.onItemClick);
      });;
    }
    if ((options.editUrl || options.listEdits.length > 0) && options.dataModel) {
      const model = form.models.getInfo(options.dataModel);

      if (model) {
        if (options.listEdits.length > 0) {
          openClickActions.push(async (e, ds) => {
            const edit = options.listEdits.find(c => c.typeName === e.data.ObjectTypeName);
            if (!edit) {
              return;
            }
            if (!edit.editUrl) {
              return;
            }

            const popupClosed = await this.popupService.closeAllPopups();
            if (!popupClosed) {
              return;
            }       

            this.location.goTo({
              url: `#${edit.editUrl}/${e.data[model.keyProperty]}`,
              currentViewModel: form,
              viewScrollInfo: getViewScrollInfo(e, ds)
            });
          });
        } else {
          openClickActions.push(async (e, ds) => {
            const key = options.editUrlIdProperty
              ? e.data[options.editUrlIdProperty]
              : e.data[model.keyProperty];

            const popupClosed = await this.popupService.closeAllPopups();
            if (!popupClosed) {
              return;
            }
          
            this.location.goTo({
              url: `#${options.editUrl}/${key}`, 
              currentViewModel: form,
              viewScrollInfo: getViewScrollInfo(e, ds)
            });
          });
        }
      }
    }
    if ((options.idEditPopup || options.listEdits.length > 0)) {
      form.editPopups.onEditPopupHidden.register(a => {
        if (a.editPopup.id === options.idEditPopup || options.listEdits.some(c => c.idEditPopup === a.editPopup.id)) {
          const listInstance = form[options.id];
          if (!listInstance) {
            return;
          }

          if (listInstance.instance && listInstance.instance.refresh) {
            listInstance.instance.refresh();
          } else if (listInstance.refresh) {
            listInstance.refresh();
          }
        };

        return Promise.resolve();
      });

      if (options.listEdits.length > 0) {
        //TODO - Info aus DataSource.lastLoadOptions übergeben und verarbeiten
        openClickActions.push((e, ds) => {
          const edit = options.listEdits.find(c => c.typeName === e.data.ObjectTypeName);
          if (!edit) {
            return;
          }
          if (!edit.idEditPopup) {
            return;
          }

          form.editPopups.show(edit.idEditPopup, getViewScrollInfo(e, ds));
        });
      } else {
        openClickActions.push((e, ds) => {
          form.editPopups.show(options.idEditPopup, getViewScrollInfo(e, ds));
        });
      }
    }

    const customOptions = this.getCustomOptions(form, options);
    customOptions.clickActions = clickActions;
    customOptions.openClickActions = openClickActions;
    customOptions.hasClickActions = clickActions.length > 0 || openClickActions.length > 0;

    if (clickActions.length && openClickActions.length) {
      clickActions.push((e, ds) => {
        customOptions.clickArguments = {
          event: e,
          dataSource: ds
        }
      });
    }
  }

  createWidgetOptions(form: FormBase, options: WidgetOptions.IWidgetOptions): any {
    const widgetOptions: DevExpress.ui.WidgetOptions = {
      bindingOptions: {}
    };

    if (options.isDisabled) {
      widgetOptions.disabled = true;
    } else if (options.isDisabledExpression) {
      widgetOptions.bindingOptions["disabled"] = options.isDisabledExpression;
    }

    if (options.tooltip) {
      widgetOptions.hint = options.tooltip;
    }

    const customWidgetOptions: any = widgetOptions;

    widgetOptions.onDisposing = () => {
      widgetOptions.onDisposing = null;

      this.callCustomDisposing(form, options);
    };

    form.onUnbind.register(() => {
      for (let key in widgetOptions) {
        if (key.startsWith("on") && typeof widgetOptions[key] === "function") {
          delete widgetOptions[key];
        }
      };

      return Promise.resolve();
    });

    form[options.options.optionsName] = widgetOptions;

    return widgetOptions;
  }

  callCustomDisposing(form: FormBase, options: any): void {
    const customOptions = this.getCustomOptions(form, options);
    if (!customOptions.disposing) {
      return;
    }

    customOptions.disposing.forEach(c => c());
    delete customOptions.disposing;
    delete options["__customOptions"];
  }
  registerCustomDisposing(form: FormBase, options: any, action: { (): void }) {
    const customOptions = this.getCustomOptions(form, options);
    customOptions.disposing = customOptions.disposing || [];

    customOptions.disposing.push(action);
  }

  getCustomOptions(form: FormBase, options: WidgetOptions.IWidgetOptions) {
    if (!form[options.options.optionsName]["__customOptions"]) {
      form[options.options.optionsName]["__customOptions"] = {};
    }

    return form[options.options.optionsName]["__customOptions"];
  }
}
