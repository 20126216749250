import { autoinject } from "aurelia-framework";
import { ISelectedFilter } from './erweiterte-suche';
import { ErweiterteSucheService } from '../../services/export';
import { ObjektExportEigenschaftDatenTyp } from '../../enumerations/export';

@autoinject
export class ErweiterteSucheMarker {
  constructor(
    private erweiterteSucheService: ErweiterteSucheService
  ) { }

  filter: ISelectedFilter;

  operatorSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "value",
    displayExpr: "text",
    onValueChangedByUser: (e) => {
      this.checkIsValid();
    },
    bindingOptions: {
      value: "filter.operator"
    }
  }
  tagBoxOptions: DevExpress.ui.dxTagBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Bezeichnung",
    showSelectionControls: true,
    applyValueMode: "useButtons",
    onSelectionChanged: () => {
      this.checkIsValid();
    },
    bindingOptions: {
      value: "filter.wert"
    }
  };

  activate(filter) {
    this.filter = filter;
    this.operatorSelectBoxOptions.dataSource = this.erweiterteSucheService.getOperatorDataSource(ObjektExportEigenschaftDatenTyp.Marker);

    if (!this.filter.wert) {
      this.filter.wert = [];
    }

    this.tagBoxOptions.items = this.filter.filter.DataList;
    this.checkIsValid();
  }

  checkIsValid() {
    const wertList = <any[]>this.filter.wert;

    this.filter.isValid = Array.isArray(wertList)
      && wertList.length > 0
      && Array.isArray(this.filter.filter.DataList);

    if (this.filter.isValid) {
      this.filter.bezeichnung = this.erweiterteSucheService.getFilterBezeichnung(this.filter.filter, this.filter.operator, this.filter.wert);
    } else {
      this.filter.bezeichnung = null;
    }
  }
}
