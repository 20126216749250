import {
  autoinject
} from "aurelia-framework";
import {
  DxTemplateService
} from "../../framework/dx/export";

import * as landvorwahlBezeichnung from "html-loader!../templates/landvorwahl-bezeichnung.xhtml";
import * as landvorwahlBezeichnungField from "html-loader!../templates/landvorwahl-bezeichnung-field.xhtml";
import * as landvorwahlBezeichnungLookupField from "html-loader!../templates/landvorwahl-bezeichnung-lookup-field.xhtml";
import * as geschaeftspartner from "html-loader!../templates/geschaeftspartner.xhtml";
import * as ansprechperson from "html-loader!../templates/ansprechperson.xhtml";
import * as dispoPlanstelle from "html-loader!../templates/dispo-planstelle.xhtml";
import * as person from "html-loader!../templates/person.xhtml";
import * as profilFirma from "html-loader!../templates/profil-firma.xhtml";
import * as stelleninserat from "html-loader!../templates/stelleninserat.xhtml";

@autoinject
export class TemplateRegisterService {
  constructor(
    private dxTemplate: DxTemplateService
  ) { }

  register() {
    this.dxTemplate.registerTemplate(
      "zit-landvorwahl-bezeichnung-template",
      <string>landvorwahlBezeichnung);

    this.dxTemplate.registerTemplate(
      "zit-landvorwahl-bezeichnung-field-template",
      <string>landvorwahlBezeichnungField);

    this.dxTemplate.registerTemplate(
      "zit-landvorwahl-bezeichnung-lookup-field-template",
      <string>landvorwahlBezeichnungLookupField);

    this.dxTemplate.registerTemplate(
      "zit-geschaeftspartner-template",
      <string>geschaeftspartner);

    this.dxTemplate.registerTemplate(
      "zit-ansprechperson-template",
      <string>ansprechperson);

    this.dxTemplate.registerTemplate(
      "zit-dispoplanstelle-template",
      <string>dispoPlanstelle);

    this.dxTemplate.registerTemplate(
      "zit-person-template",
      <string>person);

    this.dxTemplate.registerTemplate(
      "zit-profilfirma-template",
      <string>profilFirma);

    this.dxTemplate.registerTemplate(
      "zit-stelleninserat-template",
      <string>stelleninserat);
  }
}
