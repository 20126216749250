import {
  autoinject,
  bindable
} from "aurelia-framework"
import { LocationService } from '../../../base/services/location-service';

@autoinject
export class StackRouterLinkCustomAttribute {
  constructor(
    private element: Element,
    private locationService: LocationService
  ) { }

  @bindable clearStack: boolean;

  bind() {
    this.element.addEventListener("click", (e) => {
      const event: any = e;
      if (!event.ctrlKey
        && !event.altKey
        && !event.shiftKey
        && !event.metaKey) {
        const href = this.element.getAttribute("href");

        if (href) {
          this.locationService.goTo({
            url: this.element.getAttribute("href"),
            clearStack: this.clearStack
          });
        }

        e.preventDefault();
      }
    });
  }
}
