import { autoinject } from "aurelia-framework";
import { ScopeContainer } from "../../../framework/base/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/export";
import { IdxPopupComponent } from "../../interfaces/export";
import { FotoPopupService, IFotoPopupShowOptions } from "../../services/foto-popup-service";
import { FileService } from "./../../../framework/base/services/file-service";

@autoinject
export class FotoPopup {
  private _options: IFotoPopupShowOptions; 

  constructor(
    private _fotoPopupService: FotoPopupService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _fileService: FileService) {
    this._fotoPopupService.popup = this;
  }

  scopeContainer: ScopeContainer;
  imgSource: any;
  popupTitle: string;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    width: "auto",
    height: "auto"
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });
    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "fotoPopupToolbar",
      caption: "foto-popup.popup-title",
      options: this.popupOptions,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  show(options: IFotoPopupShowOptions) {
    this._options = options;
    this.popupTitle = options.name ? "Foto " + options.name : "";
    this.imgSource = this.getImgSource(options.dmsLink);
    if (!this.imgSource) {
      return;
    }
    this.popup.instance.show();
  }

  getImgSource(dmsLink: string) {
    if (dmsLink == void 0) {
      return "";
    }

    return this._fileService.getInlineUrl(dmsLink);
  }
}
