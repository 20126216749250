import { RestService } from "./../../framework/base/services/rest-service";
import { autoinject } from "aurelia-framework";
import { JsonService } from "../../framework/base/export";
import { StartupService } from "./startup-service";

@autoinject
export class LoginprofilEinstellungenService {
  constructor(
    private _restService: RestService,
    private _jsonService: JsonService,
    private _startupService: StartupService
  ) { }

  deleteEinstellung(idEinstellung: number): Promise<any> {
    return this._restService.delete({
      url: this._restService.getWebApiUrl("ZIT/Stammdaten/LoginprofilEinstellung"),
      id: idEinstellung.toString(),
      increaseLoadingCount: true
    });
  }

  async loadLoginprofilEinstellung(data: ILoginprofilEinstellungLoadOptions): Promise<ILoginprofilEinstellung[]> {
    const where: any[] = [["Typ", data.Typ]];

    if (data.Entitaet) {
      where.push(["Entitaet", data.Entitaet]);
    }

    if (data.Typ == LoginprofilEinstellungTyp.Tableau) {
      where.push(["IdLoginprofil", this._startupService.startupInfo.Benutzer.IdLoginprofil]);
    }

    if (data.IsStandard) {
      where.push(["IsStandard", true]);
    }

    const result: any[] = await this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Stammdaten/LoginprofilEinstellung"),
      increaseLoadingCount: true,
      getOptions: {
        where: where
      }
    });

    return result.map((item) => {
      const wert = this._jsonService.parse(item.Wert || "[]");

      let columns = wert.Columns;
      const custom = wert.Custom;

      if (Array.isArray(wert)) {
        columns = wert;
      }

      return <ILoginprofilEinstellung>{
        Id: item.Id,
        Typ: item.Typ,
        Entitaet: item.Entitaet,
        Bezeichnung: item.Bezeichnung,
        IsStandard: item.IsStandard,
        Wert: {
          Columns: columns,
          Custom: custom
        }
      };
    });
  }
  saveOrCreateEinstellung(data: ILoginprofilEinstellung): Promise<ILoginprofilEinstellung> {
    if (!data.Wert) {
      data.Wert = {
        Columns: [],
        Custom: null
      };
    }

    return this._restService.post({
      url: this._restService.getWebApiUrl("ZIT/Stammdaten/LoginprofilEinstellung"),
      increaseLoadingCount: true,
      data: {
        Id: data.Id,
        Bezeichnung: data.Bezeichnung,
        IsStandard: data.IsStandard,
        Typ: data.Typ,
        Entitaet: data.Entitaet,
        Wert: this._jsonService.stringify(data.Wert)
      }
    });
  }
}

export interface ILoginprofilEinstellung {
  Typ: LoginprofilEinstellungTyp;
  Entitaet: string;
  Bezeichnung?: string;
  IsStandard?: boolean;
  Id?: number;
  Wert?: ILoginprofilEinstellungWert;
}
export interface ILoginprofilEinstellungWert {
  Columns?: any[];
  Custom?: any;
}
export enum LoginprofilEinstellungTyp {
  Suche = 0,
  Suchlayout = 1,
  Tableau = 2
}
export interface ILoginprofilEinstellungLoadOptions {
  Typ: LoginprofilEinstellungTyp;
  Entitaet?: string;
  IsStandard?: boolean;
}