import { computedFrom } from 'aurelia-framework';
import { autoinject, OverrideContext, Scope } from 'aurelia-framework';
import { ScopeContainer, LocalizationService, AuthorizationService } from '../../../framework/base/export';
import { SimpleWidgetCreatorService, ICommandData } from '../../../framework/forms/export';
import { DataSourceService, RestService } from '../../../framework/base/services/export';
import { ILoeschenBenutzerData } from '../../interfaces/export';
import { BenutzereinstellungenService } from '../../services/benutzereinstellungen-service';
import { IdxValidationGroupComponent, IdxSelectBoxComponent, IdxPopupComponent } from '../../interfaces/export';
import { GrundTyp } from '../../enumerations/export';
import { FileService } from '../../../framework/base/services/file-service';

@autoinject
export class Willkommensseite {
  constructor(
    private benutzereinstellungen: BenutzereinstellungenService,
    private file: FileService,
    private localization: LocalizationService,
    private rest: RestService,
    private simpleWidgetCreator: SimpleWidgetCreatorService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;

  isWillkommensseiteDeaktiviert: boolean;

  willkommensseiteList: IWillkomensseiteData[];
  myTitel: string;

  popupCommands: ICommandData[] = [
    {
      id: "deaktiviertWillkommensseite",
      idCategory: "$close",
      title: "willkommensseite.deaktiviert-command-titel",
      sortIndex: 1010,
      execute: () => {
        this.isWillkommensseiteDeaktiviert = true;

        this.willkommensseitePopup.instance.hide();
      }
    }
  ];

  willkommenGalleryOptions: DevExpress.ui.dxGalleryOptions = {
    itemTemplate: "willkommensseiteTemplate",
    width: "100%",
    animationDuration: 400,
    slideshowDelay: 5000,
    showNavButtons: true,
    loop: true,
    onSelectionChanged: (e) => {
      this.onGallerySeiteChanged(e.addedItems[0]);
    },
    bindingOptions: {
      dataSource: "willkommensseiteList"
    }
  };

  willkommensseitePopup: IdxPopupComponent;
  willkommensseitePopupOptions: DevExpress.ui.dxPopupOptions = {
    width: "1000px",
    height: "auto",
    maxWidth: "90%",
    closeOnOutsideClick: true,
    onHidden: () => {
      this.handleDeaktiviertChanged();
    }
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "benutzerLoeschenPopupToolbar",
      caption: "willkommensseite.test",
      options: this.willkommensseitePopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  async showPopup() {
    this.isWillkommensseiteDeaktiviert = this.benutzereinstellungen.benutzereinstellungenData.IsWillkommensseiteDeaktiviert;

    this.willkommensseiteList = await this.loadWillkommensseiteList();

    if (!this.willkommensseiteList || !this.willkommensseiteList.length) {
      return;
    }

    this.onGallerySeiteChanged(this.willkommensseiteList[0]);

    this.willkommensseitePopup.instance.show();
  }

  onGallerySeiteChanged(neuSeite: IWillkomensseiteData) {
    if (neuSeite == void 0) {
      return;
    }

    if (neuSeite._inlineLinkBild == void 0) {
      neuSeite._inlineLinkBild = this.file.getInlineUrl(neuSeite.DMSLinkBild)
    }
    
    this.myTitel = neuSeite.Ueberschrift;
  }
  private handleDeaktiviertChanged() {
    if (this.isWillkommensseiteDeaktiviert == this.benutzereinstellungen.benutzereinstellungenData.IsWillkommensseiteDeaktiviert) {
      return
    }

    this.benutzereinstellungen.saveWillkommensseiteGelesen(this.isWillkommensseiteDeaktiviert);
  }

  private async loadWillkommensseiteList() {
    const jetzt = new Date();
    jetzt.setHours(0, 0, 0, 0);

    return this.rest.get({
      url: this.rest.getWebApiUrl("ZIT/Stammdaten/Willkommensseite"),
      getOptions: {
        where: [["GueltigBis", ">=", jetzt], "and", ["GueltigVon", "<=", jetzt]]
      }
    });
  }
}
export interface IWillkomensseiteData {
  DMSLinkBild: string,
  Ueberschrift: string,
  TextFarbe: string,
  TextHintergrundFarbe: string,
  Text: string,
  _inlineLinkBild?: string
}
