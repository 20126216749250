import { autoinject } from 'aurelia-framework';
import { GlobalizationService } from '../../framework/base/services/export';
import * as moment from "moment";
@autoinject
export class ZitMomentValueConverter {
  constructor(
    private globalization: GlobalizationService
  ) { }

  toView(data: any, funcName: string, args?: any) {
    switch (funcName) {
      case "dispoVonBis":
        return this.getDispoVonBis(data, args);
      default: 
        break;
    }

    if (!data) {
      return "";
    }

    switch (funcName) {
      case "alter":
        return this.getAlter(data);
      case "zustand":
        return this.getZustand(data, args);
        case "fragebogenZustand":
        return this.getFragebogenZustand(data);
      case "vonBis":
        return this.getVonBis(data, args);
      case "von":
        return this.getVon(data);
      case "bis":
        return this.getBis(data, args);
      case "wochenTag":
        return this.getWochenUndTag(data, args, true);
      case "wochenTagZeit":
        return this.getWochenUndTagUndZeit(data, args, true);
      case "format":
        return this.globalization.format(data, args);
      case "personzuteilungVonBis":
        return this.getPersonzuteilungVonBis(data);
      case "personzuteilungMelden":
        return this.getPersonzuteilungMelden(data);
      default:
        return this.globalization.format(data, "d");
    }
  }

  getAlter(date: Date) {
    if (!date) {
      return "";
    }

    return moment().diff(date, "y");
  }
  getVon(data: { von: Date, prefix: string, format: string }) {
    if (!data.von) {
      return "";
    }

    const prefix = data.prefix || "von ";

    return `${prefix}${this.globalization.format(data.von, data.format || "d")}`;
  }

  getBis(bis: Date, von: Date) {
    if (!bis) {
      return "";
    }

    const prefix = von ? "" : "bis ";
    return `${prefix}${this.globalization.format(bis, "d")}`;
  }
  getVonBis(data: { von: Date, bis: Date, prefix?: string, prefixSeparator?: string, format?: string }, args?: { separator?: string, vonPrefix?: string, bisPrefix?: string }): string {
    const dataPrefix = data.prefix || "";
    if (!data || !data.von && !data.bis) {
      return dataPrefix;
    }
    const prefix = dataPrefix
      ? `${dataPrefix} ${data.prefixSeparator ? data.prefixSeparator : ", "}`
      : "";

    const separator = args && args.separator
      ? args.separator
      : "-";

    if (data.von && data.bis) {
      return `${prefix}${this.globalization.format(data.von, data.format || "d")} ${separator} ${this.globalization.format(data.bis, data.format || "d")}`
    }
    //TODO use translation
    const datePrefix = data.von
      ? args.vonPrefix || "von"
      : args.bisPrefix || "bis";

    const date = this.globalization.format(data.von || data.bis, "d");

    return `${dataPrefix}${datePrefix} ${date}`;
  }
  getWochenUndTag(date: Date, pattern: string = " ({0})", showDay: boolean = false) {
    //TODO formater = Globalize.dateFormatter({ skeleton: "E" });
    if (!date) {
      return "";
    }

    return showDay
      ? `${this.globalization.format(date, "d")} ${this.getWochenTag(date, pattern).toUpperCase()}`
      : this.getWochenTag(date, pattern).toUpperCase();
  }
  getWochenUndTagUndZeit(date: Date, args: { start: Date, pattern: string }, showDay: boolean = false) {
    if (!date) {
      return "";
    }

    return showDay
      ? `${this.globalization.format(date, "d")} ${this.getWochenTag(date, args.pattern).toUpperCase()} ${this.getZeit(args.start)}`
      : this.getWochenTag(date, args.pattern).toUpperCase();
  }

  getZustand(prefix: string, date: Date, isDateVisible: boolean = true) {
    if (!isDateVisible) {
      return prefix;
    }
    return `${prefix}, am ${this.globalization.format(date, "d")} um ${this.globalization.format(date, "t")} Uhr`
  }
  getFragebogenZustand(data: any) {
    let text = this.getZustand(data.Zustand.Bezeichnung, data.ZustandDatum);

    if (data.ShowProzentRichtig) {
      text += ` (${this.globalization.format(data.ProzentRichtig, "p2")} richtig)`;
    }

    return text;
  }

  getDispoVonBis(von: Date, args: { bis: Date, vonZeit?: Date, bisZeit?: Date }, separator: string = " - ") {
    if (!von && !args.bis) {
      return "";
    }

    const vonText = von
      ? this.getDatumZeitWochenTag(von, args.vonZeit || null)
      : null;

    if (!args.bis) {
      return "von " + vonText;
    }

    const bisText = this.getDatumZeitWochenTag(args.bis, args.bisZeit || null);

    return vonText && bisText
      ? `${vonText}${separator}${bisText}`
      : vonText
        ? vonText
        : `bis: ${bisText}`
  }

  getPersonzuteilungVonBis(data) {
    const von: Date = data.ZeitraumVonDatum || data.DispoPlanstelle.Eigenschaften.ZeitraumVonDatum;
    const bis: Date = data.ZeitraumBisDatum || data.DispoPlanstelle.Eigenschaften.ZeitraumBisDatum;
    const vonZeit: Date = von === data.ZeitraumVonDatum
      ? data.ZeitraumVonZeit
      : data.DispoPlanstelle.Eigenschaften.ZeitraumVonZeit;
    const bisZeit: Date = bis === data.ZeitraumBisDatum
      ? data.ZeitraumBisZeit
      : data.DispoPlanstelle.Eigenschaften.ZeitraumBisZeit;

    return this.getDispoVonBis(von, { bis: bis, vonZeit: vonZeit, bisZeit: bisZeit }, " bis ");
  }

  getPersonzuteilungMelden(data) {
    const am = data.MeldenAm || data.DispoPlanstelle.Eigenschaften.MeldenAm;
    const um = data.MeldenUm || data.DispoPlanstelle.Eigenschaften.MeldenUm;
    const meldenBei = data.MeldenBei || data.DispoPlanstelle.Eigenschaften.MeldenBei;
    const meldenAm = this.getWochenTag(am, " am " + this.globalization.format(am, "d") + " ({0}) ");
    const meldenUm = this.getZeit(um, " {0} Uhr ");

    return `melde dich bitte ${meldenAm}${meldenUm}${meldenBei ? "bei " + meldenBei : ""}`
  }

  getDatumZeitWochenTag(date: Date, zeit?: Date) {
    if (!date) {
      return "";
    }

    const zeitString = zeit ? ` ${this.globalization.format(zeit, "t")} ` : " ";

    return `${this.globalization.format(date, "d")}${zeitString}${this.getWochenUndTag(date)}`
  }

  getZeit(date: Date, pattern: string = "um {0} Uhr") {
    if (!date) {
      return "";
    }

    return `${pattern.replace("{0}", this.globalization.format(date, "t"))}`
  }

  private getWochenTag(date: Date, pattern: string = "({0})") {
    //TODO globalization
    if (!date) {
      return "";
    }

    return `${pattern.replace("{0}", moment(date).locale("de").format("dd").toUpperCase())}`
  }
}
