import {
  autoinject, child
} from "aurelia-framework";
import {
  RouterService
} from '../../../framework/forms/services/export';
import { INavigationRoute } from '../../../framework/forms/export';
import { LocationService } from '../../../framework/base/services/location-service';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class NavigationList {
  constructor(
    private _locationService: LocationService,
    private _routerService: RouterService,
    private _eventAggregator: EventAggregator
  ) { 
    this.initializeRoutes()
  }

  routes: INavigationRoute[];

  onNavigationItemClick(url: string) {
    if (!url) {
      return;
    }

    this._locationService.goTo({
      url: url,
      clearStack: true
    });
  }

  private initializeRoutes() {
    const routes: INavigationRoute[] = [];

    const hideNavigation = [
      "routes.objekte",
      "routes.suche",
      "routes.navigation"
    ]

    this._routerService.navigationRoutes
      .forEach(r => {
        if (hideNavigation.indexOf(r.caption) >= 0) {
          return;
        }

        if (!r.children || r.children.length == 0) {
          return;
        }

        const children = r.children.filter(c => {
          const route = this._routerService.getRoute(c.route);
          if (!route) {
            return false;
          }

          const routerCanNavigateArgs = {
            routeInfo: route,
            moduleId: route.route.moduleId,
            cancel: false
          };
      
          this._eventAggregator.publish("router:can-navigate", routerCanNavigateArgs);
          if (routerCanNavigateArgs.cancel) {
            return false;
          }

          return true;
        });

        if (children.length == 0) {
          return;
        }

        routes.push({
          caption: r.caption,
          navigation: r.navigation,
          route: r.route,
          children: children
        });
      });

    this.routes = routes;
  }
}
