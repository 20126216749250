import {
  autoinject
} from "aurelia-framework";
import {
  FormBase
} from "../classes/form-base";
import {
  BaseWidgetCreatorService
} from "./base-widget-creator-service";
import {
  GlobalizationService,
  LocalizationService
} from "../../base/services/export";
import {
  SelectionModeEnum
} from "../enums/selection-mode-enum";
import {
  EnumItemService
} from "../services/export";
import * as WidgetOptions from "../widget-options/export";
import { EventAggregator } from 'aurelia-event-aggregator';
import { IDataSourceCustomizationOptions } from '../../base/export';
import { ListType } from '../enums/list-type-enum';

@autoinject
export class DataGridWidgetCreatorService {
  constructor(
    private baseWidgetCreator: BaseWidgetCreatorService,
    private globalization: GlobalizationService,
    private localization: LocalizationService,
    private enumItem: EnumItemService,
    private eventAggregator: EventAggregator
  ) {}

  addDataGrid(form: FormBase, options: WidgetOptions.IDataGridOptions) {
    const dataGridOptions: DevExpress.ui.dxDataGridOptions = this.baseWidgetCreator.createWidgetOptions(form, options);

    let registerOnCellPrepared = false;
    if (options.columns) {
      const totalItems = [];

      dataGridOptions.columns = options.columns.map(col => {
        const column: DevExpress.ui.dxDataGridColumn = {};

        if (col.caption) {
          column.caption = this.localization.translateOnce(col.caption, form.scopeContainer);
        }
        if (col.bindTo) {
          column.dataField = col.bindTo;
        }
        if (col.dataType) {
          column.dataType = <any>col.dataType;
        }
        if (col.sortIndex != void (0) && col.sortOrder != void (0)) {
          column.sortIndex = col.sortIndex;
          column.sortOrder = col.sortOrder;
        }
        if (col.width) {
          column.width = col.width;
        }
        if (col.minWidth) {
          column.minWidth = col.minWidth;
        }
        if (col.format) {
          column.format = this.globalization.getFormatterParser(col.format);
        }
        if (col.enumTypeName) {
          column.dataType = "string";
          column.customizeText = (cellInfo) => {
            if (cellInfo.value == void (0)) {
              return "";
            }

            return this.enumItem.getDisplayText(col.enumTypeName, cellInfo.value.toString());
          };
        }
        if (col.onCustomizeText) {
          column.customizeText = (e: any) => {    
            return form.binding.execute(form.scope, col.onCustomizeText, e);
          };
        }
        if (col.onCalculateFilterExpression) {
          column.calculateFilterExpression = (value: any, operator: any, target: any) => {    
            return form.binding.execute(form.scope, col.onCalculateFilterExpression, {
              value: value,
              operator: operator,
              target: target
            });
          };
        }

        if (col.showTotalAggSum) {
          totalItems.push({
            column: col.bindTo,
            summaryType: "sum",
            customizeText: (e) => {
              if (e.value == void(0)) {
                return null;
              }

              return this.globalization.format(e.value, col.format);
            }
          });
        }

        if (col.onCellPrepared) {
          registerOnCellPrepared = true;
        }

        return column;
      });

      if (totalItems.length > 0) {
        dataGridOptions.summary = {
          totalItems: totalItems
        };
      }
    }

    if (options.dataModel && !options.binding.bindTo) {
      const customizationOptions: IDataSourceCustomizationOptions = {};

      this.eventAggregator.publish("data-grid:customize-data-source", {
        form: form,
        options: options,
        dataGridOptions: dataGridOptions,
        customizationOptions: customizationOptions
      });

      const dataSource = this.baseWidgetCreator.createListDataSource(form, options, dataGridOptions, customizationOptions);
      dataGridOptions.dataSource = dataSource;

      dataGridOptions.allowColumnResizing = true;
      dataGridOptions.columnResizingMode = "widget";

      dataGridOptions.remoteOperations = {
        filtering: true,
        paging: true,
        sorting: true,
        summary: true
      };

      if (options.isMainList) {
        form.onReactivated.register(e => {
          dataSource.reload();
          return Promise.resolve();
        });
      }
    }
    else if (options.binding.bindTo) {
      dataGridOptions.bindingOptions["dataSource"] = options.binding.bindToFQ;
    }

    //MainLists erhalten immer eine Filter-Zeile, 
    //da hier die Suche immer sinnvoll ist
    if (options.showFilterRow || (options.showFilterRow == void(0) && options.isMainList)) {
      dataGridOptions.filterRow = {
        visible: true
      };
      dataGridOptions.headerFilter = {
        visible: true,
        allowSearch: true
      };
    }

    this.baseWidgetCreator.setListClickActions(form, options);
    const customOptions = this.baseWidgetCreator.getCustomOptions(form, options);
    const isSelectionModeSingle = (options.selectionMode || SelectionModeEnum.None) == SelectionModeEnum.Single;
    const contentReadyActions: {(e): void}[] = [];

    if (customOptions.hasClickActions > 0) {
      dataGridOptions.hoverStateEnabled = true;

      dataGridOptions.onRowClick = (e) => {
        customOptions.clickActions.forEach(item => {
          item(e, dataGridOptions.dataSource);
        });

        if (!isSelectionModeSingle) {
          customOptions.openClickActions.forEach(item => {
            item(e, dataGridOptions.dataSource);
          });
        }
      };
    }

    if (options.selectionMode) {
      dataGridOptions.selection = {
        mode: <any>this.getSelectionMode(options.selectionMode)
      };

      if (options.selectionMode == SelectionModeEnum.Multiple) {
        dataGridOptions.selection.showCheckBoxesMode = "always";
      }
    }

    if (options.showPagerInfo) {
      dataGridOptions.pager = {
        visible: true,
        showInfo: true
      }
    }

    if (options.pageSize) {
      dataGridOptions.paging = {
        pageSize: options.pageSize,
        enabled: true
      }
    }

    dataGridOptions.sorting = {
      mode: "multiple"
    };

    if (options.height) {
      dataGridOptions.height = options.height;
    }

    if (options.editDataContext) {
      contentReadyActions.push(e => {
        const model = form.models.getInfo(options.editDataContext);

        if (!form.models.data[model.id]) {
          return;
        }
        if (!form.models.data[model.id][model.keyProperty]) {
          return;
        }

        const key = form.models.data[model.id][model.keyProperty];

        const grid: DevExpress.ui.dxDataGrid = e.component;
        const dataSource = grid.getDataSource();

        const exists = dataSource
          .items()
          .some(c => c[model.keyProperty] == key);

        if (exists) {
          return;
        }

        form.models.data[model.id] = null;
      })
    }

    if (registerOnCellPrepared) {      
      dataGridOptions.onCellPrepared = (e: any) => {
        const col = options.columns.find((c) => {
          return c.bindTo == e.column.dataField
            && !!c.onCellPrepared;
        });

        if (!col) {
          return;
        }

        form.binding.execute(form.scope, col.onCellPrepared, e);
      };
    }

    if (contentReadyActions.length > 0) {
      dataGridOptions.onContentReady = (e) => {
        contentReadyActions.forEach(c => c(e));
      }
    }

    this.baseWidgetCreator.checkListToolbar(form, options, isSelectionModeSingle, ListType.DataGrid);
    this.baseWidgetCreator.checkListRelationEdit(form, options);
  }

  private getSelectionMode(selectionMode: SelectionModeEnum): string {
    switch (selectionMode) {
      case SelectionModeEnum.Multiple:
        return "multiple";
      case SelectionModeEnum.Single:
        return "single";
      default:
        return "none";
    }
  }
} 
