import * as fwx from "../../../../framework/forms/form-export";
import {
    PersonReadUtils
} from "./person-read-utils";

@fwx.autoinject
export class PersonFerialReadForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_PersonReadUtils: PersonReadUtils) {
        super(element, formBaseImport);
        this.id = "person-ferial-read";
        this.title = "person-ferial-read.person-ferial-read_caption";
        this.addModel({
            "id": "$m_Person",
            "webApiAction": "ZIT/Objekte/Person",
            "webApiExpand": {
                'Personendaten': {
                    'expand': {
                        'Nationalitaet': null
                    }
                },
                'Adressen': {
                    'expand': {
                        'Land': null
                    },
                    'where': [
                        [
                            [
                                'GueltigVon',
                                '<=',
                                '@Today()'
                            ],
                            'or',
                            [
                                'GueltigVon',
                                '=',
                                'null'
                            ]
                        ],
                        [
                            [
                                'GueltigBis',
                                '>=',
                                '@Today()'
                            ],
                            'or',
                            [
                                'GueltigBis',
                                '=',
                                'null'
                            ]
                        ]
                    ]
                },
                'Kontakte': {
                    'expand': {
                        'Kontakttyp': null
                    }
                },
                'Sprache': {
                    'expand': {
                        'Sprachen': {
                            'expand': {
                                'Sprachniveau': null,
                                'Sprache': null
                            }
                        }
                    }
                },
                'Berufserfahrungen': {
                    'expand': {
                        'Berufserfahrungen': null
                    }
                },
                'Ausbildung': {
                    'expand': {
                        'Ausbildungen': {
                            'expand': {
                                'Ausbildung': null,
                                'AusbildungAbschluss': null
                            }
                        }
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Bewerbung",
            "webApiAction": "ZIT/Objekte/Bewerbung",
            "webApiColumns": ["Id"],
            "webApiWhere": [
                ["Kopf.IdPerson", {
                    "isBound": true,
                    "expression": "models.data.$m_Person.Id"
                }]
            ],
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "AddBewerbungCard",
                "webApiCustomValue": "true"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_PersonReadUtils.actionCommand",
                "bindToFQ": "functions.$f_PersonReadUtils.actionCommand"
            }
        });
        this.addFunction("$f_PersonReadUtils", $f_PersonReadUtils, "functions.$f_PersonReadUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personSpracheListViewToolbarOptions",
                "optionsNameFQ": "r_personSpracheListViewToolbarOptions"
            },
            "binding": {
                "bindTo": "$m_Person.Sprache.Sprachen",
                "bindToFQ": "models.data.$m_Person.Sprache.Sprachen"
            },
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personSpracheListView",
            "options": {
                "optionsName": "r_personSpracheListViewOptions",
                "optionsNameFQ": "r_personSpracheListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personAusbildungenListViewToolbarOptions",
                "optionsNameFQ": "r_personAusbildungenListViewToolbarOptions"
            },
            "binding": {
                "bindTo": "$m_Person.Ausbildung.Ausbildungen",
                "bindToFQ": "models.data.$m_Person.Ausbildung.Ausbildungen"
            },
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personAusbildungenListView",
            "options": {
                "optionsName": "r_personAusbildungenListViewOptions",
                "optionsNameFQ": "r_personAusbildungenListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personBerufListViewToolbarOptions",
                "optionsNameFQ": "r_personBerufListViewToolbarOptions"
            },
            "binding": {
                "bindTo": "$m_Person.Berufserfahrungen.Berufserfahrungen",
                "bindToFQ": "models.data.$m_Person.Berufserfahrungen.Berufserfahrungen"
            },
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personBerufListView",
            "options": {
                "optionsName": "r_personBerufListViewOptions",
                "optionsNameFQ": "r_personBerufListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "col-xs-12 col-sm-6",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personBewerbungListViewToolbarOptions",
                "optionsNameFQ": "r_personBewerbungListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Bewerbung"
            },
            "dataModel": "$m_Bewerbung",
            "pageSize": 8,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personBewerbungListView",
            "options": {
                "optionsName": "r_personBewerbungListViewOptions",
                "optionsNameFQ": "r_personBewerbungListViewOptions"
            }
        });
        super.onConstructionFinished();
    }
}