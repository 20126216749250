import { transient, autoinject, computedFrom } from "aurelia-framework";
import { IZitToolbarOptions } from '../../../../zit-ui/elements/zit-toolbar/zit-toolbar-options';
import { FormBase, ModelUtilsService, ICommandData, DialogConfirmService } from '../../../../framework/forms/export';
import { Massnahmen } from './massnahmen';
import { RestService, LocationService, GlobalizationService } from '../../../../framework/base/export';
import { IZitTagOptions } from '../../../../zit-ui/elements/zit-tag/zit-tag-options';
import { ObjektInfoService, StartupService } from '../../../services/export';
import { NachrichtService } from '../../../services/nachricht-service';
import { BenutzerTyp } from '../../../enumerations/export';

@transient()
@autoinject
export class ZielEditUtils {
  constructor(
    private _restService: RestService,
    private _locationService: LocationService,
    private _modelUtilsService: ModelUtilsService,
    private _objektInfoService: ObjektInfoService,
    private _startupService: StartupService,
    private _nachrichtService: NachrichtService,
    private _dialogConfirmService: DialogConfirmService,
    private _globalizationService: GlobalizationService
  ) {
    this.showAusbilderKommentarEdit = this._startupService.startupInfo.Benutzer.Benutzertyp != BenutzerTyp.NatuerlichePerson;
  }

  form: FormBase;
  showAusbilderKommentarEdit: boolean = false;

  objektTagOptions: IZitTagOptions = {
    icon: {
      faIconExpression: `item._ObjSymbol || 'fas fa-user'`,
      backgroundColorExpression: `item._ObjHintergrundfarbe`
    },
    textExpression: "item.Kopf.ObjektBezeichnung",
    onClick: (e, data) => {
      const url = this._objektInfoService.getObjektNavigationUrl(data.Kopf.ObjektTypeName);
      if (!url) {
        return;
      }

      this._locationService.goTo({
        url: url.concat("/").concat(data.Kopf.IdObjekt),
        currentViewModel: this.form
      });
    }
  };
  ursprungsobjektTagOptions: IZitTagOptions = {
    icon: {
      faIconExpression: `item._UrsprSymbol || 'fas fa-cog'`,
      backgroundColorExpression: `item._UrsprHintergrundfarbe`
    },
    textExpression: "item.Kopf.UrsprungsobjektBezeichnung",
    onClick: (e, data) => {
      const url = this._objektInfoService.getObjektNavigationUrl(data.Kopf.UrsprungsobjektTypeName);
      if (!url) {
        return;
      }

      this._locationService.goTo({
        url: url.concat("/").concat(data.Kopf.IdUrsprungsobjekt),
        currentViewModel: this.form
      });
    }
  };
  einschaetzungOptions: DevExpress.ui.dxSliderOptions = {
    min: 0,
    max: 10,
    step: 1,
    label: {
      visible: true,
      format: (value: number) => {
        return `${this._globalizationService.format(value, "n0")}`;
      }
    },
    onValueChangedByUser: (ev) => {
      this.form.models.data.$m_Ziel.Kopf.IsErfuellt = (ev.value == 10);
    },
    bindingOptions: {
      value: "models.data.$m_Ziel.Kopf.Einschaetzung"
    }
  };
  massnahmenToolbarOptions: IZitToolbarOptions = {
    title: "ziele.massnahmen",
    smallToolbar: true,
    items: [{
      id: "addMassnahme",
      icon: "fas fa-plus",
      execute: async (e) => {
        const massnahme: Massnahmen = this.form["r_massnahmen"];
        massnahme.addMassnahme();
      }
    }]
  };
  createNachrichtCommand: ICommandData = {
    id: "createNachrichtCommand",
    icon: "far fa-comment-dots",
    sortIndex: 295,
    isVisibleExpression: "models.data.$m_Ziel.Id",
    execute: async (ev) => {
      if (this._startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.NatuerlichePerson) {
        this._nachrichtService.doNachrichtErstellenFromObjekt([{
          IdObjekt: this.form.models.data.$m_Ziel.Kopf.IdAnsprechperson,
          IdObjektRef: this.form.models.data.$m_Ziel.Id
        }], "TIP.ZIT.Business.Entities.Objekte.Ansprechpersonen.Ansprechperson");
      } else {
        this._nachrichtService.doNachrichtErstellenFromObjekt([{
          IdObjekt: this.form.models.data.$m_Ziel.Kopf.IdObjekt,
          IdObjektRef: this.form.models.data.$m_Ziel.Id
        }], this.form.models.data.$m_Ziel.Kopf.ObjektTypeName);
      }
    }
  };

  @computedFrom("form.models.data.$m_Ziel.Kopf.Einschaetzung")
  get einschaetzungWert() {
    if (!this.form.models.data.$m_Ziel) {
      return "";
    }

    return `<div class="ziel-einschaetzung-wert">${this.form.models.data.$m_Ziel.Kopf.Einschaetzung}</div>`
  }

  bind(form: FormBase) {
    this.form = form;

    this.form.models.onLoaded.register(async ev => {
      if (ev.model.id == "$m_Ziel" && !ev.data.Id) {
        this.checkObjekt(ev.data);
      }
    });
  }

  onDateiListChanged() {
    this._modelUtilsService.setDirty(this.form.models.data.$m_Ziel);
  }
  async onErfuelltClick() {
    this.form.models.data.$m_Ziel.Kopf.IsErfuellt = true;
    this.form.models.data.$m_Ziel.Kopf.Einschaetzung = 10;
    await this.form.save();
  }

  private async checkObjekt(data: any) {
    const hasUrsprungsobjekt = this.form.viewItemInfo
      && this.form.viewItemInfo.routeInfo
      && this.form.viewItemInfo.routeInfo.customOptions
      && this.form.viewItemInfo.routeInfo.customOptions.idUrsprungsobjekt;

    const idUrsprungsobjekt = hasUrsprungsobjekt
      ? this.form.viewItemInfo.routeInfo.customOptions.idUrsprungsobjekt
      : null;
    
    data.Kopf.IdUrsprungsobjekt = idUrsprungsobjekt;

    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Ziel/EvalObjekt"),
      data: {
        IdUrsprungsobjekt: idUrsprungsobjekt
      },
      increaseLoadingCount: true
    });

    if (r && r.IdObjekt) {
      data.Kopf.IdObjekt = r.IdObjekt;
      data.Kopf.ObjektBezeichnung = r.ObjektBezeichnung;
    } else {
      data._ShowObjektSelectBox = true;
    }

    if (r && r.IdAnsprechperson) {
      data.Kopf.IdAnsprechperson = r.IdAnsprechperson;
    } else {
      data._ShowAnsprechpersonSelectBox = true;
    }
  }
}