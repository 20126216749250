import { autoinject, transient, TaskQueue } from 'aurelia-framework';
import { IZitTagOptions } from '../../../../zit-ui/elements/zit-tag/export';
import { FileService } from '../../../../framework/base/services/export';
import { StartupService } from '../../../services/export';
import { FormBase, PopupInfoService } from '../../../../framework/forms/export';

@autoinject
@transient()
export class DokumentReadUtils {
  constructor(
    private _fileService: FileService,
    private _startupService: StartupService,
    private _popupInfoService: PopupInfoService,
    private _taskQueue: TaskQueue
  ) { }

  form: FormBase;
  
  dokumentTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    icon: {
      faIconExpression: `item.Zustand ? item.Zustand.TagSymbol : ''`,
      textExpression: `!item.Zustand || !item.Zustand.TagSymbol ? 'D':''`
    },
    textExpression: `(item.VorlageBezeichnung || item.Dateiname || item.Bezeichnung) | zitRestrictLength:50`,
    backgroundColorExpression: "item.InformationsTyp.FarbeTagText",
    infoList: [
      {
        isVisibleExpression: `item.DMSLinkDokument`,
        isClickEnabled: true,
        icon: {
          faIcon: "fas fa-paperclip",
        },
        onClick: (e, data) => {
          e.stopPropagation();
          this._fileService.download(data.DMSLinkDokument);
        }
      }, {
        isVisibleExpression: "item.Id > 0 && ".concat(
          this._startupService.startupInfo.Benutzer.CanViewDokumentDetails ? "true" : "false"          
        ),
        icon: {
          faIcon: "far fa-clock"
        },
        onClick: (e, data) => {
          e.stopPropagation();
          this.form.editPopups.show("dokumentVersionInformationEditPopup", null);
        }
      }
    ],
    onClick: (e, data) => {
      e.stopPropagation();
      this._fileService.inline(data.DMSLinkDokument);
    }
  };
  dokumentNeedsUnterfertigungTagMessageOptions: IZitTagOptions = {
    cssClass: "z--tag-info-message z--tag-info-warning-message",
    icon: {
      faIcon: "far fa-check-circle",
    },
    textExpression: `"dokument-utils.needs-unterfertigung-text" | tr`
  };
  gueltigkeitTagOptions: IZitTagOptions = {
    hasDefaultInfo: true, 
    textExpression: "item.GueltigVon | zitMoment:'dispoVonBis':{ bis: item.GueltigBis }"
  }

  bind(form: FormBase) {
    this.form = form;

    if (form.isEditPopup) {
      form.models.onLoaded.register(args => {
        if (args.model.id == "$m_Dokument" && args.data && args.data.Id) {
          this._taskQueue.queueMicroTask(() => {
            const current = this._popupInfoService.getPopup(form);
            if (current && current.popup) {
              current.popup.repaint();
            }
          });
        }
  
        return Promise.resolve();
      });
    }
  }
}
