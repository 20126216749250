import { autoinject } from 'aurelia-framework';
import { RestService } from './../../framework/base/services/export';

@autoinject
export class PersonService {
  constructor(
    private rest: RestService
  ) { }

  getPerson(id: number, getOptions = {}) {
    return this.rest.get({
      url: `${this.rest.getWebApiUrl("ZIT/Objekte/Person")}/${id}`,
      getOptions: getOptions
    });
  }
  save(data: any) {
    return this.rest.post({
      url: this.rest.getWebApiUrl("ZIT/Objekte/Person"),
      data: data,
      increaseLoadingCount: true
    })
  }
}