import {
  autoinject,
} from "aurelia-framework";
import {
  CustomEvent
} from "../classes/export";
import * as mousetrap from "mousetrap";
import "mousetrap/plugins/global-bind/mousetrap-global-bind";
import { EventAggregator } from "aurelia-event-aggregator";
import { WindowService } from './window-service';

@autoinject
export class ShortcutService {
  constructor(
    private eventAggregator: EventAggregator,
    private windowService: WindowService
  ) { }

  bindShortcut(sequence: string, idCommand: string | {(): void}, bindGlobal: boolean = true) {
    if (bindGlobal) {
      mousetrap.bindGlobal(sequence, e => this.fire(idCommand));
    } else {
      mousetrap.bind(sequence, e => this.fire(idCommand));
    }
  }

  private fire(idCommand: string | {(): void}): boolean {
    this.windowService.blurCurrentElement();

    if (typeof idCommand === "function") {
      idCommand();
    } else {
      this.eventAggregator.publish("shortcut:execute", {
        idCommand: idCommand
      });
    }

    return false;
  }
}
