import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class PersonSpracheEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "person-sprache-edit";
        this.title = "person-sprache-edit.person-sprache-edit_caption";
        this.addModel({
            "id": "$m_Sprache",
            "webApiAction": "ZIT/Objekte/PersonSprache",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "sprache",
            "filters": [],
            "caption": "person-sprache-edit.r_sprache_caption",
            "binding": {
                "dataContext": "$m_Sprache",
                "bindTo": "IdSprache",
                "bindToFQ": "models.data.$m_Sprache.IdSprache"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sprache",
            "options": {
                "optionsName": "r_spracheOptions",
                "optionsNameFQ": "r_spracheOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "sprachniveau",
            "filters": [],
            "caption": "person-sprache-edit.r_sprachniveau_caption",
            "binding": {
                "dataContext": "$m_Sprache",
                "bindTo": "IdSprachniveau",
                "bindToFQ": "models.data.$m_Sprache.IdSprachniveau"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sprachniveau",
            "options": {
                "optionsName": "r_sprachniveauOptions",
                "optionsNameFQ": "r_sprachniveauOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 200,
            "caption": "person-sprache-edit.r_bemerkung_caption",
            "binding": {
                "dataContext": "$m_Sprache",
                "bindTo": "Bemerkung",
                "bindToFQ": "models.data.$m_Sprache.Bemerkung"
            },
            "validationRules": [],
            "id": "r_bemerkung",
            "options": {
                "optionsName": "r_bemerkungOptions",
                "optionsNameFQ": "r_bemerkungOptions"
            }
        });
        super.onConstructionFinished();
    }
}