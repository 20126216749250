import { bindable, containerless } from 'aurelia-framework';
import { computedFrom } from 'aurelia-binding';
import { autoinject } from 'aurelia-framework';
import { FileService } from '../../../framework/base/services/file-service';

@autoinject
@containerless
export class ZitImage {
  constructor(
    private file: FileService
  ) { }

  @bindable dmsLink;
  @bindable noImageIcon: string;
  @bindable noImageText: string;

  @computedFrom("dmsLink")
  get imgSrc() {
    if (this.dmsLink == void 0) {
      return "";
    }

    return this.file.getInlineUrl(this.dmsLink);
  }
}