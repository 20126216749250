import { autoinject, transient } from "aurelia-framework";
import { AuthorizationService, LocalizationService, RestService } from "../../../../../framework/base/export";
import { LocationService } from "../../../../../framework/base/services/location-service";
import { FormBase } from "../../../../../framework/forms/export";
import { BrowserService } from "./../../../../../framework/base/services/browser-service";
import { ICommandData } from "./../../../../../framework/forms/interfaces/command-data";
import { DomaeneService } from "./../../../../services/domaene-service";

@autoinject
@transient()
export class PasswordResetFuncs {
  private form: FormBase;
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public browserService: BrowserService,
    private _authorizationService: AuthorizationService,
    private _localizationService: LocalizationService,
    private _restService: RestService,
    private _locationService: LocationService,
    private _domaeneService: DomaeneService) {
    const time = new Date()
      .getTime()
      .toString();
    const randomNumberString = Math.floor((Math.random() * 100))
      .toString();
    const randomKey = time + randomNumberString;
    this.logoSrc = this._restService.getApiUrl(`ZIT/Domaene/Logo?key=${randomKey}`);
  }

  urlImpressum: string;
  farbeDomaene: string;
  bezeichnungLoginMobil: string;
  logoSrc: string;
  isMobile: boolean;

  resetPasswordCommand: ICommandData = {
    id: "$resetPassword",
    title: "password-reset-funcs.reset_caption",
    execute: async () => {
      if (!this.form.models.data.$m_Password.Username) {
        DevExpress.ui.notify(this._localizationService.translateOnce("password-reset-funcs.enter_benutzer"), "error", this.NOTIFY_TIMEOUT);
        return;
      }

      const r = await this._restService.post({
        url: this._restService.getApiUrl("ZIT/Security/PasswortVergessen"),
        data: this.form.models.data.$m_Password,
        increaseLoadingCount: true
      });

     this.goBackToLogin();
    }
  };

  backToLoginCommand: ICommandData = {
    id: "$backToLogin",
    title: "password-reset-funcs.back_to_login_caption",
    execute: () => {
      this.goBackToLogin();
    }
  };

  openImpressumClick() {
    window.open(this.urlImpressum);
  }

  async bind(form: FormBase) {
    this.form = form;

    this.form.focusFirstEnabledEditor = () => {
      if (this.form["username"].instance) {
        this.form["username"].instance.focus();
      }
    };

    form.models.data.$m_Password = {
    };
    
    this.urlImpressum = this._domaeneService.urlImpressum;
    this.farbeDomaene = this._domaeneService.farbeDomaene;
    this._domaeneService.setDomainColorsStyle();
    this.bezeichnungLoginMobil = this._domaeneService.bezeichnungMobilLogin;
  }

  private goBackToLogin() {
    this._locationService.goTo({
      url: "Login",
      clearStack: true
    });
  }
}
