import { autoinject, computedFrom, TaskQueue } from "aurelia-framework";
import { ISelectedFilter } from './erweiterte-suche';
import { GlobalizationService } from '../../../framework/base/export';
import { ErweiterteSucheService } from '../../services/export';
import { ObjektExportEigenschaftDatenTyp } from '../../enumerations/export';
import { IdxDateBoxComponent } from '../../interfaces/export';

@autoinject
export class ErweiterteSucheDatum {
  private _prevOperator: string;

  constructor(
    private globalizationService: GlobalizationService,
    private erweiterteSucheService: ErweiterteSucheService,
    private taskQueue: TaskQueue
  ) {}

  filter: ISelectedFilter;

  operatorSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "value",
    displayExpr: "text",
    onValueChangedByUser: (e) => {
      if (e.value == "between") {
        this.filter.wert = {
          wert1: null,
          wert2: null
        }
      } else if (this._prevOperator == "between") {
        this.filter.wert = null;
      }

      this._prevOperator = e.value;
      this.checkIsValid();
    },
    bindingOptions: {
      value: "filter.operator"
    }
  }
  dateBox: IdxDateBoxComponent;
  dateBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    //TODO Format aus Eigenschaft übernehmen
    onValueChangedByUser: () => {
      this.checkIsValid();
    },
    bindingOptions: {
      value: "filter.wert"
    }
  };
  between1DateBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    onValueChangedByUser: () => {
      this.checkIsValid();
    },
    bindingOptions: {
      value: "filter.wert.wert1"
    }
  };
  between2DateBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    onValueChangedByUser: () => {
      this.checkIsValid();
    },
    bindingOptions: {
      value: "filter.wert.wert2"
    }
  };

  @computedFrom("filter.operator")
  get wertVisible() {
    return this.filter.operator != "isnull"
      && this.filter.operator != "isnotnull"
      && this.filter.operator != "between";
  }
  @computedFrom("filter.operator")
  get isBetweenVisible() {
    return this.filter.operator == "between";
  }

  activate(filter) {
    this.filter = filter;
    this.operatorSelectBoxOptions.dataSource = this.erweiterteSucheService.getOperatorDataSource(ObjektExportEigenschaftDatenTyp.Datum);

    this.dateBoxOptions["calendarOptions"] = {firstDayOfWeek: 1};
    this.dateBoxOptions.displayFormat = this.globalizationService.getFormatterParser(this.filter.filter.Format || "d");

    this.between1DateBoxOptions["calendarOptions"] = {firstDayOfWeek: 1};
    this.between1DateBoxOptions.displayFormat = this.globalizationService.getFormatterParser(this.filter.filter.Format || "d");

    this.between2DateBoxOptions["calendarOptions"] = {firstDayOfWeek: 1};
    this.between2DateBoxOptions.displayFormat = this.globalizationService.getFormatterParser(this.filter.filter.Format || "d");

    this.checkIsValid();
    this._prevOperator = this.filter.operator;
  }
  attached() {
    if (this.filter.focus) {
      this.taskQueue.queueTask(() => {
        if (this.dateBox && this.dateBox.instance) {
          this.dateBox.instance.focus();
        }
      });
    }
  }
  
  checkIsValid() {
    this.filter.isValid = this.filter.operator == "between"
      ? (!!this.filter.wert.wert1 && !!this.filter.wert.wert2)
      : (this.filter.operator == "isnull" || this.filter.operator == "isnotnull" || !!this.filter.wert);

      if (this.filter.isValid) {
        this.filter.bezeichnung = this.erweiterteSucheService.getFilterBezeichnung(this.filter.filter, this.filter.operator, this.filter.wert);
      } else {
        this.filter.bezeichnung = null;
      }
  }
}
