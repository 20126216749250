import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class PersonGeschaeftspartnerEinsatzEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "person-geschaeftspartner-einsatz-edit";
        this.title = "person-geschaeftspartner-einsatz-edit.person-geschaeftspartner-einsatz-edit_caption";
        this.addModel({
            "id": "$m_GeschaeftspartnerEinsatz",
            "webApiAction": "ZIT/Objekte/PersonGeschaeftspartnerEinsatzElement",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "geschaeftspartner",
            "customs": [{
                "key": "IdPerson",
                "value": "models.data.$m_GeschaeftspartnerEinsatz.IdPerson"
            }],
            "filters": [],
            "caption": "person-geschaeftspartner-einsatz-edit.r_geschaeftspartner_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerEinsatz",
                "bindTo": "IdGeschaeftspartner",
                "bindToFQ": "models.data.$m_GeschaeftspartnerEinsatz.IdGeschaeftspartner"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschaeftspartner",
            "options": {
                "optionsName": "r_geschaeftspartnerOptions",
                "optionsNameFQ": "r_geschaeftspartnerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-geschaeftspartner-einsatz-edit.r_taetigkeitabteilung_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerEinsatz",
                "bindTo": "TaetigkeitAbteilung",
                "bindToFQ": "models.data.$m_GeschaeftspartnerEinsatz.TaetigkeitAbteilung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_taetigkeitAbteilung",
            "options": {
                "optionsName": "r_taetigkeitAbteilungOptions",
                "optionsNameFQ": "r_taetigkeitAbteilungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 1900.0,
            "max": 2100.0,
            "caption": "person-geschaeftspartner-einsatz-edit.r_jahr_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerEinsatz",
                "bindTo": "Jahr",
                "bindToFQ": "models.data.$m_GeschaeftspartnerEinsatz.Jahr"
            },
            "validationRules": [],
            "id": "r_jahr",
            "options": {
                "optionsName": "r_jahrOptions",
                "optionsNameFQ": "r_jahrOptions"
            }
        });
        super.onConstructionFinished();
    }
}