import { autoinject, transient } from "aurelia-framework";
import { FormBase } from "../../../../framework/forms/classes/form-base";
import { GlobalizationService } from "./../../../../framework/base/services/globalization-service";
import { IZitTagOptions } from "./../../../../zit-ui/elements/zit-tag/zit-tag-options";
import { InformationService } from "./../../../services/information-service";

@transient()
@autoinject
export class InformationReadUtils {
  constructor(
    private _informationService: InformationService,
    private _globalizationService: GlobalizationService
  ) { }

  form: FormBase;

  ueberschriftTagOptions: IZitTagOptions = {
    icon: {
      text: "Ü"
    },
    textExpression: `item.Ueberschrift`
  };

  zeitraumTagOptions: IZitTagOptions = {
    isClickEnabled: false,
    textExpression: `functions.$f_InformationReadUtils.datumTagString(item)`,
    icon: {
      faIcon: "far fa-calendar-alt"
    }
  };

  geschaeftspartnerTagOptions: IZitTagOptions = {
    icon: {
      text: "U"
    },
    textExpression: `item.DetailGeschaeftspartner`
  };

  personTagOptions: IZitTagOptions = {
    icon: {
      text: "P"
    },
    textExpression: `item.DetailPerson`
  };

  linkTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "fas fa-external-link-square-alt"
    },
    text: "${item.Kopf.LinkUrl}",
    onClick: (e, data) => {
      window.open(data.Kopf.LinkUrl, "_blank");
    }
  };

  bind(form: FormBase) {
    this.form = form;
  }

  datumTagString(item: any): string {
    if (!item) {
      return "";
    }

    if (!item.ZeitraumVon) {
      return this._globalizationService.format(item.Zeitpunkt, "DT_DD.MM.YYYY (dd)")
        .toUpperCase();
    }

    if (!item.ZeitraumBis || item.ZeitraumVon.getTime() == item.ZeitraumBis.getTime()) {
      return this._globalizationService.format(item.ZeitraumVon, "DT_DD.MM.YYYY (dd)")
        .toUpperCase();
    }

    return this._globalizationService.format(item.ZeitraumVon, "DT_DD.MM.YYYY (dd)")
      .toUpperCase()
      + " - "
      + this._globalizationService.format(item.ZeitraumBis, "DT_DD.MM.YYYY (dd)")
        .toUpperCase();
  }
}
