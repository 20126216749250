import { autoinject, computedFrom } from "aurelia-framework";
import { LocationService } from "../../../framework/base/services/location-service";
import { EventAggregator } from "aurelia-event-aggregator";
import { ContextMenu } from "../../../framework/forms/classes/context-menu";
import { RouterService } from "../../../framework/forms/services/router-service";
import { StartupService } from "../../../zit/services/startup-service";
import { BerechtigungService } from "../../../zit/services/berechtigung-service";
import { LayoutService } from "../../export";
import { BrowserService } from "../../../framework/base/export";
import { IBenutzerrolleMenu } from "../../../zit/interfaces/export";
import { SucheService } from "../../../zit/services/export";

@autoinject
export class NavBar {
  private _isContextMenuOpen = false;

  constructor(
    private _routerService: RouterService,
    private _eventAggregator: EventAggregator,
    private _browserService: BrowserService,
    private _locationService: LocationService,
    private _berechtigungService: BerechtigungService,
    private _layoutService: LayoutService,
    private _startupService: StartupService,
    private _sucheService: SucheService
  ) {
    this.isMobile = this._browserService.isMobile;
  }

  isMobile = false;
  currentNavigationItem: any;

  shortcutItems: IMenuItem[];
  items: IMenuItem[];

  bind() {
    this.buildItems();
  }
  attached() {
    window.addEventListener("click", this.onWindowClick.bind(this));
  }
  detached() {
    window.removeEventListener("click", this.onWindowClick.bind(this));
  }

  onContextMenuClick(e: any) {
    if (this._isContextMenuOpen) {
      return;
    }

    const contextMenu = new ContextMenu({
      onHidden: () => this._isContextMenuOpen = false
    });
    
    for (const item of this.items) {
      const html = "<div"
        .concat(this.isCurrent(item) ? " class=\"z--nav-bar-item-selected\"" : "")
        .concat("><div>")
        .concat(item.bezeichnung)
        .concat("</div><div>")
        .concat("<hr class=\"z--nav-bar-item-border-bottom\">")
        .concat("</div></div>");

      contextMenu.items.push({
        html: html,
        execute: () => this.onNavBarItemClick(item)
      });
    }

    this._isContextMenuOpen = true;
    contextMenu.show(this.getClosest(e.target, "z--nav-bar-item"));
  }
  onNavBarItemClick(item: IMenuItem) {
    const data = this.getMenuItemData(item.item);

    switch (data.type) {
      case "event": {
        this._eventAggregator.publish(data.value, {});
        break;
      }
      case "link": {
        window.open(data.value, "_blank");
        break;
      }
      case "route": {
        if (data.value.startsWith("Suche?")) {
          //Type-Name ermitteln
          const entitaet = data.value.substr(15);
          if (entitaet) {
            this._eventAggregator.publish("suche:change-value", {
              value: item.bezeichnung.concat(": ")
            });
          } else {
            this._eventAggregator.publish("suche:clear", {});
          }
        } else {
          this._locationService.goTo({
            url: data.value,
            clearStack: true
          });
          this._eventAggregator.publish("suche:clear", {});
        }

        break;
      }
    }

    this._layoutService.isSidebarCollapsed = true;
  }
  onCloseNavBar() {
    this._layoutService.isSidebarCollapsed = true;
  }

  onWindowClick(e) {
    if (this._layoutService.isSidebarCollapsed) {
      return;
    }

    const target = <Element>e.target;

    if (target.classList.contains("z--button") || this.getClosest(target, "z--button")
      || target.classList.contains("z--nav-bar-item") || this.getClosest(target, "z--nav-bar-item")) {
      return;
    }

    this._layoutService.isSidebarCollapsed = true;
  }

  private buildItems() {
    const items = [];
    const shortcutItems = [];

    const menu = this._startupService.startupInfo.MenuList;
    if (menu) {
      for (const menuItem of menu) {
        const data = this.getMenuItemData(menuItem);

        let hasAccess = false;
        switch (data.type) {
          case "event":
          case "link":{
            hasAccess = true;
            break;
          }
          case "route": {
            hasAccess = this.hasBerechtigungRoute(data.value);
            break;
          }
          default: {
            break;
          }
        }

        if (!hasAccess) {
          continue;
        }

        if (this.isMobile && !menuItem.ShowInMobil) {
          continue;
        }
        if (!this.isMobile && !menuItem.ShowInDesktop) {
          continue;
        }

        const item = {
          bezeichnung: this.isMobile ? menuItem.BezeichnungMobil : menuItem.BezeichnungDesktop,
          sortNr: this.isMobile ? menuItem.SortNrMobil : menuItem.SortNrDesktop,
          item: menuItem
        };
        
        items.push(item);

        if (menuItem.IsShortcutDesktop) {
          shortcutItems.push(item);
        }
      }
    }

    this.items = items.sort((a, b) => (a.sortNr || 0) - (b.sortNr || 0));
    this.shortcutItems = shortcutItems.sort((a, b) => (a.sortNr || 0) - (b.sortNr || 0));
  }
  private getMenuItemData(item: IBenutzerrolleMenu): IMenuItemData {
    const indexOf = item.Route.indexOf(":");
    const type = item.Route.substr(0, indexOf);
    const value = item.Route.substr(indexOf + 1);

    return {
      type,
      value
    };
  }
  private hasBerechtigungRoute(url: string): boolean {
    //spezielles Handling bei Suche
    if (url.startsWith("Suche?")) {
      //Type-Name ermitteln
      const entitaet = url.substr(15);
      return this._berechtigungService.hasBerechtigung(entitaet);
    } else {
      return this._berechtigungService.hasBerechtigungRoute(url);
    }
  }
  private getClosest(e: Element, classString: string) {
    if (e.classList.contains(classString)) {
      return e;
    }

    let parent = null;
    while (e) {
      parent = e.parentElement;
      if (parent && parent.classList.contains(classString)) {
        return parent;
      }

      e = parent;
    }

    return null;
  }
  private isCurrent(item: IMenuItem) {
    if (!this._routerService.currentViewItem
      || !this._routerService.currentViewItem.model
      || !this._routerService.currentViewItem.model.routeInfo) {
      return false;
    }

    const route = `route:${this._routerService.currentViewItem.model.routeInfo.url}`;
    if (route == item.item.Route) {
      return true;
    }

    if (route == "route:Suche") {
      return item.item.Route == route.concat("?TypeName=").concat(this._sucheService.lastObjektFullName);
    }

    return false;
  }
}

export interface IMenuItem {
  bezeichnung: string;
  sortNr: number;
  item: IBenutzerrolleMenu;
}
interface IMenuItemData {
  type: string;
  value: string;
}
