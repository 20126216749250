import {
  autoinject,
  createOverrideContext,
  Scope,
  TemplatingEngine,
  newInstance,
  View
} from "aurelia-framework";
import * as DxLoader from "../dx-loader";

@autoinject
export class DxTemplateService {
  private templates = {};

  constructor(
    private templatingEngine: TemplatingEngine
  ) { }

  registerTemplate(key: string, template: string) {
    this.templates[key] = template;
  }

  getTemplates(scope: Scope, resources: any): any {
    const result = {};

    for (let templateKey in this.templates) {
      result[templateKey] = {
        render: (renderData) => {
          return this.render(
            this.templates[templateKey],
            renderData.container,
            resources,
            scope,
            renderData.model,
            templateKey
          ).element;
        }
      };
    }

    return result;
  }

  render(template: string | Element, container: Element, resources: any, scope: Scope, model?: any, templateKey?: string): IDxWidgetRenderInfo {
    let newElement: Element;

    if (typeof template === "string") {
      newElement = document.createElement("div");
      newElement.innerHTML = template;
    } else {
      newElement = <Element>template.cloneNode(true)
    }

    container.appendChild(newElement);

    let itemBindingContext: any;
    let itemOverrideContext: any;

    //per Konvention muss ein Field-Template mit "-field-template" enden
    const isFieldTemplate = (templateKey || "").endsWith("-field-template");

    if (model || isFieldTemplate) {
      itemBindingContext = {
        data: model
      };

      itemOverrideContext = createOverrideContext(scope.bindingContext, scope.overrideContext);
    } else {
      itemBindingContext = scope.bindingContext;
      itemOverrideContext = scope.overrideContext;
    }

    const view = this.templatingEngine.enhance({
      element: newElement,
      bindingContext: itemBindingContext,
      overrideContext: itemOverrideContext,
      resources: resources
    });

    if (isFieldTemplate) {
      this.extendFieldTemplate(newElement);
    }

    const dxEventOn: any = DevExpress.events.on;
    dxEventOn(<any>newElement, "dxremove", () => {
      view.unbind();
      view.detached();
    });

    return {
      element: newElement,
      view: view
    };
  }
  private extendFieldTemplate(element: Element) {
    const textbox = element.querySelector(".dx-textbox");
    if (!textbox) {
      return;
    }

    let instance = DxLoader.getInstance("dxTextBox", textbox);

    instance.on("focusIn", () => {
      element.classList.add("t--field-template-editor-focused");
    });
    instance.on("focusOut", () => {
      element.classList.remove("t--field-template-editor-focused");
    });
    instance.on("disposing", () => {
      element = null;
      instance = null;
    });
  }
}

export interface IDxWidgetRenderInfo {
  element: Element;
  view: View;
}
