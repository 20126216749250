import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class AnsprechpersonListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "ansprechperson-list";
        this.title = "ansprechperson-list.ansprechperson-list_caption";
        this.addModel({
            "id": "$m_Ansprechperson",
            "webApiAction": "ZIT/Objekte/Ansprechperson",
            "webApiExpand": {
                'Personendaten': null
            },
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_anspVorname",
                "caption": "ansprechperson-list.r_anspvorname_caption",
                "bindTo": "Personendaten.Vorname",
                "minWidth": 100
            }, {
                "id": "r_anspNachname",
                "caption": "ansprechperson-list.r_anspnachname_caption",
                "bindTo": "Personendaten.Nachname",
                "minWidth": 100
            }, {
                "id": "r_anspTeam",
                "caption": "ansprechperson-list.r_anspteam_caption",
                "bindTo": "Personendaten.Team",
                "minWidth": 100
            }, {
                "id": "r_anspGeburtsdatum",
                "caption": "ansprechperson-list.r_anspgeburtsdatum_caption",
                "bindTo": "Personendaten.Geburtsdatum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_anspBemerkung",
                "caption": "ansprechperson-list.r_anspbemerkung_caption",
                "bindTo": "Personendaten.Bemerkung",
                "minWidth": 100
            }],
            "optionsToolbar": {
                "optionsName": "r_ansprechpersonGridToolbarOptions",
                "optionsNameFQ": "r_ansprechpersonGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Ansprechperson"
            },
            "dataModel": "$m_Ansprechperson",
            "editUrl": "Objekte/Ansprechperson",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_ansprechpersonGrid",
            "options": {
                "optionsName": "r_ansprechpersonGridOptions",
                "optionsNameFQ": "r_ansprechpersonGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}