import { autoinject } from 'aurelia-framework';
import { RestService } from './../../framework/base/services/export';

@autoinject
export class DispoPlanstelleService {
  constructor(
    private rest: RestService
  ) { }

  deleteEigenschaftAnsprechperson(id: number) {
    return this.rest.delete({
      url: this.rest.getWebApiUrl("ZIT/Objekte/DispoPlanstelleEigenschaftenElementZuAnsprechperson"),
      id: id
    });
  }

  deleteEigenschaftGruppierung(id: number) {
    return this.rest.delete({
      url: this.rest.getWebApiUrl("ZIT/Objekte/DispoPlanstelleEigenschaftenElementZuGpGruppierung"),
      id: id
    });
  }
  deleteEigenschaftSkill(id: number) {
    return this.rest.delete({
      url: this.rest.getWebApiUrl("ZIT/Objekte/DispoPlanstelleEigenschaftenElementZuSkill"),
      id: id
    });
  }

  deletePersonenzuteilungEigenschaftAnsprechperson(id: number) {
    return this.rest.delete({
      url: this.rest.getWebApiUrl("ZIT/Objekte/DispoPlanstellePersonenzuteilungEigenschaftenElementZuAnsprechperson"),
      id: id
    });
  }
  deletePersonenzuteilungEigenschaftEntlohnungsschema(id: number) {
    return this.rest.delete({
      url: this.rest.getWebApiUrl("ZIT/Objekte/DispoPlanstellePersonenzuteilungEigenschaftenElementZuEntlohnungsschema"),
      id: id
    });
  }
  deletePersonenzuteilungEigenschafEinsatzvorbereitung(id: number) {
    return this.rest.delete({
      url: this.rest.getWebApiUrl("ZIT/Objekte/DispoPlanstellePersonenzuteilungEigenschaftenElementZuEinsatzvorbereitung"),
      id: id
    });
  }
  deletePersonenzuteilungEigenschaftGruppierung(id: number) {
    return this.rest.delete({
      url: this.rest.getWebApiUrl("ZIT/Objekte/DispoPlanstellePersonenzuteilungEigenschaftenElementZuGpGruppierung"),
      id: id
    });
  }

  updatePersonenzuteilungStatus(data: { IdObjekt: number, Code: string, IdGrund?: number, Bemerkung?: string, AnmerkungZusage?: string }) {
    return this.rest.post({
      url: this.rest.getApiUrl("ZIT/Dispo/UpdatePersonenzuteilungStatus"),
      data: data,
      increaseLoadingCount: true
    });
  }
}
