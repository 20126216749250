import {
  autoinject, bindable
} from "aurelia-framework";

@autoinject
export class LoadingSpinner {
  constructor(
    private element: Element
  ) { }

  @bindable fullScreen: boolean;

  bind() {
    this.element.classList.remove("t--loading-active");
  }
  attached() {
    setTimeout(() => {
      this.element.classList.add("t--loading-active");
    }, 500);
  }
}
