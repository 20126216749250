import { FaIconAttribute } from "./../../../base/attributes/icon/fa-icon-attribute";
import { autoinject, bindable } from "aurelia-framework";
import { IItem } from "../toolbar/item";

@autoinject
export class RibbonToolbarItem {
  constructor(
    private element: Element
  ) {}

  @bindable item: IItem;

  onItemClick(item: IItem, event: Event) {
    const args = {
      event: event
    };

    if (!item.isVisible) {
      return;
    }
    if (!item.isEnabled) {
      return;
    }

    if (!item.execute) {
      return;
    }

    item.execute(args);
  }
}
