
import { autoinject } from "aurelia-framework";
import { SucheService, DashboardService } from '../../services/export';
import { IZitCardOptions, ZitCardTyp } from '../../../zit-ui/elements/zit-card/export';
import { FormEventService } from '../../../framework/forms/export';

@autoinject
export class DashboardBoxAufgabe {
  private _onSavedEvent: {(): void};

  constructor(
    private _sucheService: SucheService,
    private _formEventService: FormEventService,
    private _dashboardService: DashboardService
  ) {}

  item: any;

  aufgabeCardOptions: IZitCardOptions = {
    isClickEnabled: true,
    useDefaultBindings: true,
    typ: ZitCardTyp.Kompakt
  };

  activate(item) {
    this.item = item;
  }
  attached() {
    this._onSavedEvent = this._formEventService.onSaved.register(async (e) => {
      if (e.form.id == "aufgabe-obj-edit") {
        this.refreshData();
      }
    });
  }
  detached() {
    if (this._onSavedEvent) {
      this._onSavedEvent();
      this._onSavedEvent = null;
    }
  }

  onBoxHeaderClicked() {
    this._sucheService.activateSuche("TIP.ZIT.Business.Entities.Objekte.Aufgaben.Aufgabe");
  }

  private async refreshData() {
    const box = await this._dashboardService.getAufgabeBox();

    if (box) {
      this.item = box;
    } else {
      this.item = Object.assign({}, this.item, {
        DataSource: [],
        Anzahl: 0,
        AnzahlGesamt: 0
      });
    }
  }
}
