import { autoinject, bindable } from "aurelia-framework";
import { IListViewOptions, ListView } from "../../../framework/forms/elements/list-view/export";
import { RestService } from "../../../framework/base/services/rest-service";
import { GlobalizationService } from "../../../framework/base/export";
import { EventAggregator, Subscription } from "../../../../node_modules/aurelia-event-aggregator";
import { StartupService } from "../../services/export";
import { BenutzerTyp } from "../../enumerations/export";
import { IdxButtonComponent } from "../../interfaces/export";
import { INachrichtBenutzerInfo } from "../../services/nachricht-service";

@autoinject
export class NachrichtBenutzer {
  private _nachrichtErstelltSubscription: Subscription;
  private _setFirstBenutzer: boolean = false;
  private _lastSelectedItem: any;

  constructor(
    public startupService: StartupService,
    private _element: Element,
    private _restService: RestService,
    private _globalizationService: GlobalizationService,
    private _eventAggregator: EventAggregator
  ) {
    if (this.startupService.startupInfo.Skin.Farbe20Opacity) {
      this.backgroundColorAktiv = this.startupService.startupInfo.Skin.Farbe20Opacity;
    }
    this.isNatuerlicheBenutzer = this.startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.NatuerlichePerson;
    this.idBenutzerStartup = this.startupService.startupInfo.Benutzer.IdBenutzer;
  }

  @bindable benutzer: string;
  @bindable isStellvertreter = false;
  @bindable nurUngeleseneNachrichten = false;
  @bindable clickAfterSelect: boolean;

  @bindable benutzerInfo: INachrichtBenutzerInfo;

  idBenutzerStartup: number = 0;
  isNatuerlicheBenutzer = false;
  backgroundColorAktiv = "#dedede;";

  benutzerListView: ListView;
  benutzerListViewOptions: IListViewOptions = {
    useDefaultListItemStyle: false,
    itemClass: "z--nachr-benutzer-item",
    onItemClick: (e) => {
      this.onBenutzerClick(e);
    }
  };
  
  benutzerTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    placeholder: "Person",
    mode: "search",
    elementAttr: {
      class: "z--suche"
    },
    bindingOptions: {
      value: "benutzer"
    }
  };

  bind() {    
    this._nachrichtErstelltSubscription = this._eventAggregator.subscribe("nachricht:erstellt", (e) => {
      if (!this.benutzerListView) {
        return;
      }

      this.reload();
    });

    this.benutzerListViewOptions.dataSource = new DevExpress.data.DataSource(
      <any>new DevExpress.data.CustomStore({
        load: (e) => {
          return new Promise<any>(async(resolve, reject) => {
            const restResult = await this._restService.post({
              url: this._restService.getApiUrl("ZIT/Nachricht/Benutzer"),
              data: {
                Take: e.take,
                Skip: e.skip,
                IsStellvertreter: this.isStellvertreter,
                NurUngeleseneNachrichten: this.nurUngeleseneNachrichten,
                Benutzer: this.benutzer
              },
              increaseLoadingCount: true
            });
            
            resolve({
              data: restResult.BenutzerList,
              totalCount: restResult.Anzahl
            });

            if (this._setFirstBenutzer && this.clickAfterSelect) {  
              this.onBenutzerClick({
                item: restResult.BenutzerList.length > 0
                  ? restResult.BenutzerList[0]
                  : {}
              });
            }

            this._setFirstBenutzer = false;
          });
        }
      }));
  }
  unbind() {
    if (this._nachrichtErstelltSubscription) {
      this._nachrichtErstelltSubscription.dispose();
      this._nachrichtErstelltSubscription = null;
    }
  }
  
  reload(setFirstBenutzer = false) {
    this._setFirstBenutzer = setFirstBenutzer;

    this.benutzerListView.refresh(false);
  }

  onStellvertreterClick() {
    this.isStellvertreter = !this.isStellvertreter;

    this.reload();
  }
  onUngeleseneNachrichtenClick() {
    this.nurUngeleseneNachrichten = !this.nurUngeleseneNachrichten;
  }
  onBenutzerClick(e) {
    if (this._lastSelectedItem) {
      this._lastSelectedItem._isAktiv = false;
    }

    e.item._isAktiv = true;
    this._lastSelectedItem = e.item;

    if (!e.item || !e.item.IdBenutzer) {
      return;
    }

    const event = new CustomEvent("on-benutzer-click", {
      detail: {
        sender: this,
        benutzerInfo: <INachrichtBenutzerInfo> {
          anzeigeTyp: e.item.AnzeigeTyp,
          idBenutzer: e.item.IdBenutzer,
          name: e.item.Name,
          isGruppeAdmin: e.item.IsGruppeAdmin
        }
      },
      bubbles: true
    });

    this._element.dispatchEvent(event);
  }
}
