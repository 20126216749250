import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class SurveyMonkeyFragebogenEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "survey-monkey-fragebogen-edit";
        this.title = "survey-monkey-fragebogen-edit.survey-monkey-fragebogen-edit_caption";
        this.addModel({
            "id": "$m_SurveyMonkeyFragebogen",
            "webApiAction": "ZIT/SurveyMonkey/Survey",
            "webApiExpand": {
                'Questions': {
                    'expand': {
                        'Children': {
                            'expand': {
                                'AnswerChoices': null
                            }
                        },
                        'AnswerChoices': null
                    }
                }
            },
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "allowDelete": "false",
            "filters": [{
                "webApiCustomKey": "Editing",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_SurveyMonkeyFragebogenQuestion",
            "webApiAction": "ZIT/SurveyMonkey/Question",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_SurveyMonkeyFragebogenQuestionChild",
            "webApiAction": "ZIT/SurveyMonkey/Question",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_SurveyMonkeyFragebogenAnswerChoice",
            "webApiAction": "ZIT/SurveyMonkey/AnswerChoice",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_SurveyMonkeyFragebogenAnswerChoiceChild",
            "webApiAction": "ZIT/SurveyMonkey/AnswerChoice",
            "keyProperty": "Id",
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "survey-monkey-fragebogen-edit.r_titel_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_SurveyMonkeyFragebogen",
                "bindTo": "Titel",
                "bindToFQ": "models.data.$m_SurveyMonkeyFragebogen.Titel"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_titel",
            "options": {
                "optionsName": "r_titelOptions",
                "optionsNameFQ": "r_titelOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "survey-monkey-fragebogen-edit.r_isaktiv_caption",
            "binding": {
                "dataContext": "$m_SurveyMonkeyFragebogen",
                "bindTo": "IsAktiv",
                "bindToFQ": "models.data.$m_SurveyMonkeyFragebogen.IsAktiv"
            },
            "validationRules": [],
            "id": "r_isAktiv",
            "options": {
                "optionsName": "r_isAktivOptions",
                "optionsNameFQ": "r_isAktivOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "g",
            "caption": "survey-monkey-fragebogen-edit.r_letzteaktualisierung_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_SurveyMonkeyFragebogen",
                "bindTo": "LetzteAktualisierung",
                "bindToFQ": "models.data.$m_SurveyMonkeyFragebogen.LetzteAktualisierung"
            },
            "validationRules": [],
            "id": "r_letzteAktualisierung",
            "options": {
                "optionsName": "r_letzteAktualisierungOptions",
                "optionsNameFQ": "r_letzteAktualisierungOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "survey-monkey-fragebogen-edit.r_korrektheitpruefen_caption",
            "binding": {
                "dataContext": "$m_SurveyMonkeyFragebogen",
                "bindTo": "KorrektheitPruefen",
                "bindToFQ": "models.data.$m_SurveyMonkeyFragebogen.KorrektheitPruefen"
            },
            "validationRules": [],
            "id": "r_korrektheitPruefen",
            "options": {
                "optionsName": "r_korrektheitPruefenOptions",
                "optionsNameFQ": "r_korrektheitPruefenOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 500,
            "caption": "survey-monkey-fragebogen-edit.r_bezeichnungviewer_caption",
            "binding": {
                "dataContext": "$m_SurveyMonkeyFragebogenQuestion",
                "bindTo": "BezeichnungViewer",
                "bindToFQ": "item.BezeichnungViewer"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 500
                    }]
                }
            }],
            "id": "r_bezeichnungViewer",
            "options": {
                "optionsName": "r_bezeichnungViewerOptions",
                "optionsNameFQ": "r_bezeichnungViewerOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "survey-monkey-fragebogen-edit.r_nichtimvieweranzeigen_caption",
            "binding": {
                "dataContext": "$m_SurveyMonkeyFragebogenQuestion",
                "bindTo": "NichtImViewerAnzeigen",
                "bindToFQ": "item.NichtImViewerAnzeigen"
            },
            "validationRules": [],
            "id": "r_nichtImViewerAnzeigen",
            "options": {
                "optionsName": "r_nichtImViewerAnzeigenOptions",
                "optionsNameFQ": "r_nichtImViewerAnzeigenOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 500,
            "caption": "survey-monkey-fragebogen-edit.r_bezeichungvieweranswer_caption",
            "binding": {
                "dataContext": "$m_SurveyMonkeyFragebogenAnswerChoice",
                "bindTo": "BezeichnungViewer",
                "bindToFQ": "choice.BezeichnungViewer"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 500
                    }]
                }
            }],
            "id": "r_bezeichungViewerAnswer",
            "options": {
                "optionsName": "r_bezeichungViewerAnswerOptions",
                "optionsNameFQ": "r_bezeichungViewerAnswerOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "survey-monkey-fragebogen-edit.r_iscorrectanswer_caption",
            "binding": {
                "dataContext": "$m_SurveyMonkeyFragebogenAnswerChoice",
                "bindTo": "IsCorrect",
                "bindToFQ": "choice.IsCorrect"
            },
            "validationRules": [],
            "id": "r_isCorrectAnswer",
            "options": {
                "optionsName": "r_isCorrectAnswerOptions",
                "optionsNameFQ": "r_isCorrectAnswerOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "survey-monkey-fragebogen-edit.r_nichtimvieweranzeigenanswer_caption",
            "binding": {
                "dataContext": "$m_SurveyMonkeyFragebogenAnswerChoice",
                "bindTo": "NichtImViewerAnzeigen",
                "bindToFQ": "choice.NichtImViewerAnzeigen"
            },
            "validationRules": [],
            "id": "r_nichtImViewerAnzeigenAnswer",
            "options": {
                "optionsName": "r_nichtImViewerAnzeigenAnswerOptions",
                "optionsNameFQ": "r_nichtImViewerAnzeigenAnswerOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "survey-monkey-fragebogen-edit.r_iscorrectanswerchild_caption",
            "binding": {
                "dataContext": "$m_SurveyMonkeyFragebogenAnswerChoiceChild",
                "bindTo": "IsCorrect",
                "bindToFQ": "choice.IsCorrect"
            },
            "validationRules": [],
            "id": "r_isCorrectAnswerChild",
            "options": {
                "optionsName": "r_isCorrectAnswerChildOptions",
                "optionsNameFQ": "r_isCorrectAnswerChildOptions"
            }
        });
        super.onConstructionFinished();
    }
}