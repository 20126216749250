import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class BewerbungEinsatzbereichEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "bewerbung-einsatzbereich-edit";
        this.title = "bewerbung-einsatzbereich-edit.bewerbung-einsatzbereich-edit_caption";
        this.addModel({
            "id": "$m_BewerbungEinsatzbereich",
            "webApiAction": "ZIT/Objekte/BewerbungKopfEinsatzbereich",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "bewerbungEinsatzbereich",
            "customs": [{
                "key": "IdBewerbungKopf",
                "value": "models.data.$m_BewerbungEinsatzbereich.IdBewerbungKopfElement"
            }],
            "filters": [],
            "caption": "bewerbung-einsatzbereich-edit.r_bewerbungeinsatzbereich_caption",
            "binding": {
                "dataContext": "$m_BewerbungEinsatzbereich",
                "bindTo": "IdEinsatzbereich",
                "bindToFQ": "models.data.$m_BewerbungEinsatzbereich.IdEinsatzbereich"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_bewerbungEinsatzbereich",
            "options": {
                "optionsName": "r_bewerbungEinsatzbereichOptions",
                "optionsNameFQ": "r_bewerbungEinsatzbereichOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "bewerbung-einsatzbereich-edit.r_bewerbungwunschbereich_caption",
            "binding": {
                "dataContext": "$m_BewerbungEinsatzbereich",
                "bindTo": "Wunschbereich",
                "bindToFQ": "models.data.$m_BewerbungEinsatzbereich.Wunschbereich"
            },
            "validationRules": [],
            "id": "r_bewerbungWunschbereich",
            "options": {
                "optionsName": "r_bewerbungWunschbereichOptions",
                "optionsNameFQ": "r_bewerbungWunschbereichOptions"
            }
        });
        super.onConstructionFinished();
    }
}