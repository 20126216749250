import { GlobalizationService } from './../../../framework/base/services/globalization-service';
import { autoinject, bindable, OverrideContext, Scope, bindingMode, observable } from 'aurelia-framework';
import { IdxPopupComponent, IdxTreeViewComponent, IdxDataGridComponent, IObjektExportEigenschaftData } from '../../interfaces/export';
import { ICommandData, SimpleWidgetCreatorService } from '../../../framework/forms/export';
import { ScopeContainer } from '../../../framework/base/export';
import { ObjektExportEigenschaftService } from '../../services/export';
import { LoginprofilEinstellungTyp, ILoginprofilEinstellung } from '../../services/loginprofil-einstellungen-service';

@autoinject
export class SucheSpaltenAuswahl {
  constructor(
    private _element: Element,
    private _globalizationService: GlobalizationService,
    private _objektExportEigenschaftService: ObjektExportEigenschaftService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService
  ) { }

  @bindable dataGrid: IdxDataGridComponent;
  @bindable @observable objektFullName: string;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) additionalColumns: string[];
  @bindable typ: LoginprofilEinstellungTyp = LoginprofilEinstellungTyp.Suchlayout;

  scope: Scope;
  scopeContainer: ScopeContainer;

  activeGridLayoutDic: any = {};

  isTreeViewClearEnabled: boolean = false;
  treeViewDataSource: IObjektExportEigenschaftData[];

  popupCommands: ICommandData[] = [{
    id: "sucheSpaltenClear",
    idCategory: "$start",
    icon: "fas fa-eraser",
    tooltip: "suche-spalten-auswahl.demarkieren",
    sortIndex: 1100,
    isEnabledExpression: "isTreeViewClearEnabled",
    execute: () => {
      this.spaltenauswahlTreeView.instance.unselectAll();
    }
  }, {
    id: "sucheSpaltenAuswahl",
    idCategory: "$start",
    icon: "fas fa-check",
    tooltip: "suche-spalten-auswahl.uebernehmen",
    sortIndex: 1200,
    execute: () => {
      this.onSpaltenAuswahlDone();
    }
  }];

  spaltenAuswahlPopup: IdxPopupComponent;
  spaltenAuswahlPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "400px",
    maxHeight: "80%",
    maxWidth: "420px"
  };

  spaltenauswahlTreeView: IdxTreeViewComponent;
  spaltenauswahlTreeViewOptions: DevExpress.ui.dxTreeViewOptions = {
    dataStructure: "plain",
    keyExpr: "Key",
    parentIdExpr: "KeyParent",
    displayExpr: "Bezeichnung",
    expandedExpr: "Expanded",
    selectedExpr: "Selected",
    selectionMode: "multiple",
    selectNodesRecursive: true,
    showCheckBoxesMode: "normal",
    onSelectionChanged: (e) => {
      this.isTreeViewClearEnabled = this.treeViewDataSource.some(c => c.Selected == true);
    },
    bindingOptions: {
      dataSource: "treeViewDataSource"
    }
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "sucheSpaltenAuswahlPopupToolbar",
      caption: "suche-spalten-auswahl.popup-titel",
      options: this.spaltenAuswahlPopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  showPopup() {
    this.loadSpalten();

    this.isTreeViewClearEnabled = this.treeViewDataSource.some(c => c.Selected == true);
    this.spaltenAuswahlPopup.instance.show()
  }

  private onSpaltenAuswahlDone() {
    const selectedSpalten = this.getSelectedSpalten();
    const selectedColumns = this.getSelectedColumns(selectedSpalten);

    this.additionalColumns = selectedSpalten.map(c => c.Key);
    this.dataGrid.setOption({ columns: selectedColumns });
    this.setLayout(selectedColumns);

    this.spaltenAuswahlPopup.instance.hide();
  }
  private loadSpalten() {
    const eigenshaftList = [];
    const currentColumns: DevExpress.ui.dxDataGridColumn[] = this.dataGrid.instance.getVisibleColumns();

    this._objektExportEigenschaftService.getExportEigenschaftListByFullname(this.objektFullName).forEach((c) => {
      c.Selected = currentColumns.some(col => col.dataField === `Prop${c.Id}`)

      eigenshaftList.push(c);
    });

    this.treeViewDataSource = eigenshaftList;
  }

  private getSelectedColumns(selectedSpalten: IObjektExportEigenschaftData[]): DevExpress.ui.dxDataGridColumn[] {
    const currentColumns: DevExpress.ui.dxDataGridColumn[] = this.dataGrid.instance.option("columns");

    const visibleColumns: DevExpress.ui.dxDataGridColumn[] = [];

    currentColumns.forEach((c) => {
      if (!c.dataField.startsWith("Prop")) {
        visibleColumns.push(c);
        return;
      }

      const spalte = selectedSpalten.find(s => c.dataField === `Prop${s.Id}`)
      if (spalte) {
        c.format = spalte.Format ? this._globalizationService.getFormatter(spalte.Format) : null;
        c.caption = spalte.Bezeichnung;
        c.allowSorting = spalte.AllowSorting;
        visibleColumns.push(c);
      }
    });

    selectedSpalten.forEach(s => {
      const isAdded = currentColumns.some(c => c.dataField === `Prop${s.Id}`)
      if (isAdded) {
        return;
      }

      visibleColumns.push({
        caption: s.Bezeichnung,
        dataField: `Prop${s.Id}`,
        format: s.Format ? this._globalizationService.getFormatter(s.Format) : null,
        allowSorting: s.AllowSorting
      })
    });

    return visibleColumns;
  }

  private setLayout(selectedColumns: DevExpress.ui.dxDataGridColumn[]) {
    const layout: ILoginprofilEinstellung = this.activeGridLayoutDic[this.objektFullName];
    if (layout && layout.Id) {
      return;
    }

    const columns = this._objektExportEigenschaftService.getExportGridColumns(this.dataGrid.instance);

    const newLayout: ILoginprofilEinstellung = {
      Id: null,
      Typ: this.typ,
      Bezeichnung: null,
      Entitaet: this.objektFullName,
      Wert: {
        Columns: columns,
        Custom: null
      }
    }

    this.activeGridLayoutDic[this.objektFullName] = newLayout;
    this.dispatchSpaltenChangedEvent();
  }
  private getSelectedSpalten() {
    return this.treeViewDataSource.filter(c => c.KeyParent && c.Selected == true);
  }

  private dispatchSpaltenChangedEvent() {
    const event = new CustomEvent("on-spalten-changed", {
      detail: {
        sender: this
      },
      bubbles: true
    });

    this._element.dispatchEvent(event);
  }
}
