import { autoinject } from 'aurelia-framework';
import { RestService } from './../../framework/base/services/export';

@autoinject
export class UpdateHinweisService {
  constructor(
    private rest: RestService
  ) {
    this.checkHinweis();
  }

  updateGeplant: IUpdateGeplant;

  private checkHinweis() {
    this.updateHinweis();

    setTimeout(() => {
      this.updateHinweis();
    }, 300000);
  }

  private async updateHinweis() {
    const jetzt = new Date();
    
    const result = await this.rest.get({
      url: this.rest.getWebApiUrl("ZIT/Stammdaten/UpdateHinweis"),
      getOptions: {
        where: [["Bis", ">=", jetzt], "and", ["Von", "<=", jetzt]]
      }
    });

    this.updateGeplant = result && result.length
      ? { icon: result[0].Symbol, message: result[0].Bezeichnung }
      : null;
  }
}
interface IUpdateGeplant {
  icon: string;
  message: string;
}