import * as fwx from "../../../../framework/forms/form-export";
import {
    StelleninseratGruppeEditUtils
} from "./stelleninserat-gruppe-edit-utils";

@fwx.autoinject
export class StelleninseratGruppeEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StelleninseratGruppe: StelleninseratGruppeEditUtils) {
        super(element, formBaseImport);
        this.id = "stelleninserat-gruppe-edit";
        this.title = "stelleninserat-gruppe-edit.stelleninserat-gruppe-edit_caption";
        this.addModel({
            "id": "$m_StelleninseratGruppe",
            "webApiAction": "ZIT/Objekte/StelleninseratGruppe",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_StelleninseratGruppentyp",
            "webApiAction": "ZIT/Stammdaten/StelleninseratGruppentyp",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$v_idGeschaeftspartner"
        });
        this.addVariable({
            "id": "$v_idStelleninserat"
        });
        this.addVariable({
            "id": "$id"
        });
        this.addFunction("$f_StelleninseratGruppe", $f_StelleninseratGruppe, "functions.$f_StelleninseratGruppe");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "stelleninseratGruppentyp",
            "filter": [
                ["IdGeschaeftspartner", "null"], "or", ["IdGeschaeftspartner", {
                    "isBound": true,
                    "expression": "variables.data.$v_idGeschaeftspartner"
                }]
            ],
            "filters": [],
            "caption": "stelleninserat-gruppe-edit.r_gruppentypauswahl_caption",
            "binding": {
                "dataContext": "$m_StelleninseratGruppe",
                "bindTo": "IdStelleninseratGruppentyp",
                "bindToFQ": "models.data.$m_StelleninseratGruppe.IdStelleninseratGruppentyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gruppenTypAuswahl",
            "options": {
                "optionsName": "r_gruppenTypAuswahlOptions",
                "optionsNameFQ": "r_gruppenTypAuswahlOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "80px",
            "maxLength": 1000,
            "caption": "stelleninserat-gruppe-edit.r_text_caption",
            "binding": {
                "dataContext": "$m_StelleninseratGruppe",
                "bindTo": "Text",
                "bindToFQ": "models.data.$m_StelleninseratGruppe.Text"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_text",
            "options": {
                "optionsName": "r_textOptions",
                "optionsNameFQ": "r_textOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showSpinButtons": true,
            "min": 0.0,
            "caption": "stelleninserat-gruppe-edit.r_sortnr_caption",
            "binding": {
                "dataContext": "$m_StelleninseratGruppe",
                "bindTo": "SortNr",
                "bindToFQ": "models.data.$m_StelleninseratGruppe.SortNr"
            },
            "validationRules": [],
            "id": "r_sortNr",
            "options": {
                "optionsName": "r_sortNrOptions",
                "optionsNameFQ": "r_sortNrOptions"
            }
        });
        super.onConstructionFinished();
    }
}