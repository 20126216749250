import * as fwx from "../../../../framework/forms/form-export";
import {
    GeschaeftspartnerPackageEditUtils
} from "./geschaeftspartner-package-edit-utils";

@fwx.autoinject
export class GeschaeftspartnerPackageEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_Package: GeschaeftspartnerPackageEditUtils) {
        super(element, formBaseImport);
        this.id = "geschaeftspartner-package-edit";
        this.title = "geschaeftspartner-package-edit.geschaeftspartner-package-edit_caption";
        this.addModel({
            "id": "$m_GeschaeftspartnerPackage",
            "webApiAction": "ZIT/Objekte/GeschaeftspartnerPackage",
            "webApiExpand": {},
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "functions.$f_Package.canEditPackage",
            "allowSave": "functions.$f_Package.canEditPackage",
            "allowDelete": "functions.$f_Package.canEditPackage",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addFunction("$f_Package", $f_Package, "functions.$f_Package");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "package",
            "filters": [],
            "caption": "geschaeftspartner-package-edit.r_package_caption",
            "isReadOnlyExpression": "!functions.$f_Package.canEditPackage",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerPackage",
                "bindTo": "IdPackage",
                "bindToFQ": "models.data.$m_GeschaeftspartnerPackage.IdPackage"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_package",
            "options": {
                "optionsName": "r_packageOptions",
                "optionsNameFQ": "r_packageOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 99999.0,
            "caption": "geschaeftspartner-package-edit.r_anzahlstelleninserate_caption",
            "isReadOnlyExpression": "functions.$f_Package.anzahlStelleninserateUeberschreibenNichtZulaessig",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerPackage",
                "bindTo": "AnzahlStelleninserate",
                "bindToFQ": "models.data.$m_GeschaeftspartnerPackage.AnzahlStelleninserate"
            },
            "validationRules": [],
            "id": "r_anzahlStelleninserate",
            "options": {
                "optionsName": "r_anzahlStelleninserateOptions",
                "optionsNameFQ": "r_anzahlStelleninserateOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "geschaeftspartner-package-edit.r_gueltigvon_caption",
            "isReadOnlyExpression": "!functions.$f_Package.canEditPackage",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerPackage",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_GeschaeftspartnerPackage.GueltigVon"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigVon",
            "options": {
                "optionsName": "r_gueltigVonOptions",
                "optionsNameFQ": "r_gueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "geschaeftspartner-package-edit.r_gueltigbis_caption",
            "isReadOnlyExpression": "!functions.$f_Package.canEditPackage",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerPackage",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_GeschaeftspartnerPackage.GueltigBis"
            },
            "validationRules": [],
            "id": "r_gueltigBis",
            "options": {
                "optionsName": "r_gueltigBisOptions",
                "optionsNameFQ": "r_gueltigBisOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "geschaeftspartner-package-edit.r_kostenpflichtigab_caption",
            "isReadOnlyExpression": "!functions.$f_Package.canEditPackage",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerPackage",
                "bindTo": "KostenpflichtigAb",
                "bindToFQ": "models.data.$m_GeschaeftspartnerPackage.KostenpflichtigAb"
            },
            "validationRules": [],
            "id": "r_kostenpflichtigAb",
            "options": {
                "optionsName": "r_kostenpflichtigAbOptions",
                "optionsNameFQ": "r_kostenpflichtigAbOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "packageLehrlingAnzahlProJahr",
            "filters": [],
            "caption": "geschaeftspartner-package-edit.r_lehrlinganzahlprojahr_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerPackage",
                "bindTo": "LehrlingAnzahlProJahr",
                "bindToFQ": "models.data.$m_GeschaeftspartnerPackage.LehrlingAnzahlProJahr"
            },
            "validationRules": [],
            "id": "r_lehrlingAnzahlProJahr",
            "options": {
                "optionsName": "r_lehrlingAnzahlProJahrOptions",
                "optionsNameFQ": "r_lehrlingAnzahlProJahrOptions"
            }
        });
        super.onConstructionFinished();
    }
}