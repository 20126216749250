import { OverrideContext, Scope } from 'aurelia-binding';
import { autoinject, bindable } from "aurelia-framework";
import { LocalizationService } from "../../../framework/base/export";
import { IdxContextMenuComponent } from "../../interfaces/export";
import { EventKopierenZeitraum } from "../event-kopieren-zeitraum/event-kopieren-zeitraum";
import { ScopeContainer } from './../../../framework/base/classes/scope-container';
import { LocationService } from "./../../../framework/base/services/location-service";
import { RestService } from "./../../../framework/base/services/rest-service";
import { FormUtilsService } from './../../../framework/forms/services/form-utils-service';

@autoinject
export class EventKopierenOptionen {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    private _restService: RestService,
    private _localizationService: LocalizationService,
    private _locationService: LocationService
  ) { }

  @bindable idEvent: number;
  eventKopierenZeitraumPopup: EventKopierenZeitraum;

  kopierenContextMenu: IdxContextMenuComponent;
  kopierenContextMenuOptions: DevExpress.ui.dxContextMenuOptions = {
    showEvent: null,
    position: {
      my: "top",
      at: "bottom"
    },
    items: [
      {
        text: this._localizationService.translateOnce("event-kopieren-optionen.event-zeitraeume")
      },
      {
        text: this._localizationService.translateOnce("event-kopieren-optionen.event-zeitraeume-datum")
      }
    ],
    onItemClick: (e) => {
      if (e.itemIndex === 0) {
        this.kopieren();
      } else {
        this.eventKopierenZeitraumPopup.show({
          IdEvent: this.idEvent,
          callback: (anzahlTage) => {
            this.kopieren(anzahlTage);
          }
        });
      }
    }
  };

  showPopover(target: EventTarget) {
    this.kopierenContextMenu.setOption({ "target": target });
    this.kopierenContextMenu.instance.show();
  }

  async kopieren(tage?: number) {
    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Event/Kopieren"),
      data: {
        IdEvent: this.idEvent,
        TageVerschieben: tage
      },
      increaseLoadingCount: true
    });

    if (!r) {
      return;
    }

    DevExpress.ui.notify(this._localizationService.translateOnce("base.save_success"), "success", this.NOTIFY_TIMEOUT);

    this._locationService.goTo({
      url: `Objekte/Event/${r.IdEvent}`,
      replace: true
    });
  }
}
