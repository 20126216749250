import { autoinject } from 'aurelia-framework';
import { RestService } from '../../framework/base/services/rest-service';
@autoinject
export class StelleninseratService {
  constructor(
    private rest: RestService
  ) { }

  getStelleninserat(id: number, getOptions = {}) {
    return this.rest.get({
      url: `${this.rest.getWebApiUrl("ZIT/Objekte/Stelleninserat")}/${id}`,
      getOptions: getOptions
    });
  }

  copyStelleninseratList(idList: number[]) {
    if (!idList || !idList.length) {
      DevExpress.ui.notify("Keine Stelleninserate ausgewählt", "error", 3000);
      return;
    }

    return this.rest.post({
      url: this.rest.getApiUrl("ZIT/Objekt/CopyStelleninserate"),
      data: idList,
      increaseLoadingCount: true
    }).then(r => {
      if (r) {
        DevExpress.ui.notify(r, "success", 3000);
      }
    });
  }
}
