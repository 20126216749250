import { autoinject, Scope } from "aurelia-framework";
import { ScopeContainer, LocalizationService, RestService, DataSourceService } from "../../../framework/base/export";
import { IdxValidationGroupComponent, IdxPopupComponent } from "../../interfaces/export";
import { ICommandData, SimpleWidgetCreatorService } from "../../../framework/forms/export";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class SchnupperterminStornieren {
  constructor(
    private simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private localizationService: LocalizationService,
    private restService: RestService,
    private eventAggregator: EventAggregator,
    private dataSourceService: DataSourceService
  ) {}

  scope: Scope;
  scopeContainer: ScopeContainer;
  
  showOptions: ISchnupperterminStornierenShowOptions;
  model: IStornierenModel;
  validationGroup: IdxValidationGroupComponent;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "500px",
    height: "auto"
  };

  grundOptions: DevExpress.ui.dxSelectBoxOptions = {
    showClearButton: true,
    searchEnabled: true,
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      value: "model.idGrund"
    }
  };
  stornierenSpeichernCommand: ICommandData = {
    id: "stornierenSpeichernCommand",
    icon: "fas fa-check",
    sortIndex: 1000,
    tooltip: "base.save",
    execute: () => {
      this.validateAndStornieren();
    }
  };

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: null
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "schnupperterminStornierenToolbar",
      caption: "schnuppertermin.stornieren-header",
      scopeContainer: this.scopeContainer,
      options: this.popupOptions,
      commands: [this.stornierenSpeichernCommand]
    });

    this.grundOptions["validators"] = [this.createRequiredRule("Stornierungsgrund")];

    this.createStornierenGrundDataSource();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async show(options: ISchnupperterminStornierenShowOptions) {
    this.showOptions = options;

    this.resetModel();
    this.popup.instance.show();    
  }
  
  private createRequiredRule(text: string) {
    return <DevExpress.ui.CustomRule>{
      type: "required",
      message: this.localizationService.translateOnce("forms.validator_required", [text])
    };
  }
  
  private createStornierenGrundDataSource() {
    this.grundOptions.dataSource = this.dataSourceService.createDataSource(
      this.scopeContainer, {
        webApiAction: "ZIT/Stammdaten/Grund",
        webApiColumns: ["Id", "Bezeichnung"],
        webApiWhere: ["Typ", 2],
        webApiOrderBy: [{ columnName: "SortNr", sortOrder: 0 }, { columnName: "Bezeichnung", sortOrder: 1 }]
      }
    );
  }
  private async validateAndStornieren() {
    const validationResult = this.validationGroup.instance.validate();
    if (!validationResult.isValid) {
      DevExpress.ui.notify(
        validationResult.brokenRules[0].message,
        "ERROR",
        3000
      );

      return;
    }

    await this.restService.post({
      url: this.restService.getApiUrl("ZIT/Schnuppertermin/SchnupperterminStornieren"),
      increaseLoadingCount: true,
      data: {
        IdStornoGrund: this.model.idGrund,
        IdEventTeilnehmer: this.showOptions.idEventTeilnehmer,
        IdStelleninserat: this.showOptions.idStelleninserat
      }
    });

    DevExpress.ui.notify(
      this.localizationService.translateOnce("schnuppertermin-utils.stornierung_erfolgreich"), 
      "SUCCESS", 
      3000);
      
    this.eventAggregator.publish("schnuppertermin:erstellt", {
      IdStelleninserat: this.showOptions.idStelleninserat,
      IdEventTeilnehmer: 0,
      HasDokument: false
    });

    this.popup.instance.hide();

    if (this.showOptions.callback) {
      this.showOptions.callback();
    }
  }
  private resetModel() {
    this.model = {};
  }
}

export interface ISchnupperterminStornierenShowOptions {
  idStelleninserat: number;
  idEventTeilnehmer: number;

  callback: {(): void};
}
interface IStornierenModel {
  idGrund?: number;
}