import { GlobalizationService } from "./../../../../framework/base/services/globalization-service";
import { transient } from "aurelia-framework";
import { autoinject } from "aurelia-framework";
import { RestService } from "./../../../../framework/base/services/rest-service";
import { DataSourceService, LocalizationService } from "./../../../../framework/base/services/export";
import { FormBase } from "../../../../framework/forms/export";

@autoinject
@transient()
export class ObjekteZuBenutzergruppeUtils {
  constructor(
    private dataSourceService: DataSourceService,
    private restService: RestService,
    private globalizationService: GlobalizationService,
    private localizationService: LocalizationService
  ) { }

  form: FormBase;
  objektElementLogList: any[];
  zugriffLogList: any[];

  historyGridOptions: DevExpress.ui.dxDataGridOptions = {
    columnAutoWidth: true,
    paging: {
      pageSize: 5
    },
    hoverStateEnabled: true,
    searchPanel: {
      visible: true
    },
    columns: [
      { dataField: "Benutzer", caption: this.localizationService.translateOnce("objekte-zu-benutzergruppe.history-benutzer") },
      { dataField: "Aenderungstyp", caption: this.localizationService.translateOnce("objekte-zu-benutzergruppe.history-typ") },
      { dataField: "Datum", caption: this.localizationService.translateOnce("objekte-zu-benutzergruppe.history-datum"), format: this.globalizationService.getFormatter("g"), width: "125px" }
    ],
    bindingOptions: {
      dataSource: "functions.$f_ObjekteZuBenutzergruppe.objektElementLogList"
    }
  };

  zugriffGridOptions: DevExpress.ui.dxDataGridOptions = {
    columnAutoWidth: true,
    paging: {
      pageSize: 5
    },
    hoverStateEnabled: true,
    searchPanel: {
      visible: true
    },
    columns: [
      { dataField: "Benutzer", caption: this.localizationService.translateOnce("objekte-zu-benutzergruppe.zugriff-benutzer") },
      { dataField: "Benutzerrolle", caption: this.localizationService.translateOnce("objekte-zu-benutzergruppe.zugriff-benutzerrolle") },
      { dataField: "Loginprofil", caption: this.localizationService.translateOnce("objekte-zu-benutzergruppe.zugriff-loginprofil") },
      { dataField: "Datum", caption: this.localizationService.translateOnce("objekte-zu-benutzergruppe.zugriff-datum"), format: this.globalizationService.getFormatter("g"), width: "125px" }
    ],
    bindingOptions: {
      dataSource: "functions.$f_ObjekteZuBenutzergruppe.zugriffLogList"
    }
  };

  bind(form: FormBase) {

    this.form = form;

    form.models.onLoaded.register(async (args) => {
      if (args.model.id == "$m_ObjZuBen" && args.data) {
        const data = args.data.data;
        if (data && data[0].IdObjekt && data[0].IdObjekt > 0) {
          this.restService.get({
            url: `${this.restService.getApiUrl("ZIT/Objekt/ObjektElementLogList")}?idObjekt=${data[0].IdObjekt}`
          }).then(r => {
            this.objektElementLogList = r;
          }).then(() => {
            this.restService.get({
              url: `${this.restService.getApiUrl("ZIT/Objekt/ObjektZugriffLogList")}?idObjekt=${data[0].IdObjekt}`
            })
              .then(r => {
                this.zugriffLogList = r;
              });
          });
        }
      }

      return Promise.resolve();
    });
  }
}
