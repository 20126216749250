import { autoinject } from 'aurelia-framework';
import { RestService } from './../../framework/base/services/export';
import { HistoryService } from '../../framework/forms/export';
@autoinject
export class LoginprofilService {

  constructor(
    private rest: RestService,
    private historyService: HistoryService
  ) {
  }
  
  getLoginprofile(): Promise<any> {
    return this.rest.get({
      url: this.rest.getWebApiUrl("ZIT/Berechtigungen/Loginprofil"),
      getOptions: {
        customs: [{
          key: "IgnoreMandant",
          value: true
        }]
      }
    });
  }
  async hasMultipleLoginprofil() {
    const result = await this.rest.get({
      url: this.rest.getWebApiUrl("ZIT/Berechtigungen/Loginprofil"),
      getOptions: {
        customs: [
          {key: "IgnoreMandant", value: "true"}, 
          {key: "CheckDomain", value: "true"}
        ]
      }
    });

    return result.length > 1;
  }
  async setLoginProfil(id: number, reload: boolean): Promise<any> {
    if (!id) {
      return;
    }

    this.historyService.checkHasChangedDataBeforeUnload = false;

    await this.rest.post({
      url: this.rest.getApiUrl(`ZIT/Security/SetLoginprofil/${id}`),
      data: null
    });

    if (reload) {
      window.location.reload();
    }
  }
  zurueckZuBenutzer() {
    return this.rest.post({
      url: this.rest.getApiUrl("ZIT/Security/ZurueckZuBenutzer"),
      data: null
    });
  }
}
