import { autoinject } from "aurelia-framework";
import { EventAggregator } from 'aurelia-event-aggregator';
import { FileService } from '../../framework/base/services/file-service';

@autoinject
export class FormSelectItemService {  
  constructor(
    private eventAggregator: EventAggregator,
    private file: FileService
  ) {
    this.eventAggregator.subscribe("form-select-item:data-loaded", (e) => {
      this.onLoaded(e.selectItemId, e.data);
    });
  }

  private onLoaded(id: string, data: any[]) {
    switch (id) {
      case "landvorwahl": {
        this.onLandvorwahlLoaded(data);
        break;
      }
      default:
        break;
    }
  }
  private onLandvorwahlLoaded(data: any[]) {
    data.forEach(item => {
      item.DMSLinkIconFQ = this.file.getDownloadUrl(item.DMSLinkIcon);
    })
  }
}
