import { CustomEditPopup } from './../../../framework/forms/elements/custom-edit-popup/custom-edit-popup';
import { autoinject } from 'aurelia-framework';
import { CustomEditPopupService } from '../../services/export';

@autoinject
export class CustomEditPopupContainer {
  constructor(
    private customEditPopup: CustomEditPopupService
  ) { }

  ansprechpersonRead: CustomEditPopup;
  dokumentEdit: CustomEditPopup;
  dokumentRead: CustomEditPopup;
  dokumentUnterfertigung: CustomEditPopup;
  geschaeftspartnerAnsprechperson: CustomEditPopup;
  geschaeftspartnerEntlohnungsschema: CustomEditPopup;
  geschaeftspartnerAuswaehlen: CustomEditPopup;
  geschaeftspartnerGruppierung: CustomEditPopup;
  markersAuswaehlen: CustomEditPopup;
  personAusbildungNeu: CustomEditPopup;
  objektZuBenutzergruppe: CustomEditPopup;
  nachrichtGruppe: CustomEditPopup;

  attached() {
    this.customEditPopup.ansprechpersonRead = this.ansprechpersonRead;
    this.customEditPopup.dokumentEdit = this.dokumentEdit;
    this.customEditPopup.dokumentRead = this.dokumentRead;
    this.customEditPopup.dokumentUnterfertigung = this.dokumentUnterfertigung;
    this.customEditPopup.geschaeftspartnerAnsprechperson = this.geschaeftspartnerAnsprechperson;
    this.customEditPopup.geschaeftspartnerAuswaehlen = this.geschaeftspartnerAuswaehlen;
    this.customEditPopup.geschaeftspartnerGruppierung = this.geschaeftspartnerGruppierung;
    this.customEditPopup.geschaeftspartnerEntlohnungsschema = this.geschaeftspartnerEntlohnungsschema;
    this.customEditPopup.markersAuswaehlen = this.markersAuswaehlen;
    this.customEditPopup.personAusbildungNeu = this.personAusbildungNeu;
    this.customEditPopup.objektZuBenutzergruppe = this.objektZuBenutzergruppe;
    this.customEditPopup.nachrichtGruppe = this.nachrichtGruppe;
  }
}
