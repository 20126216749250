import { DownloadService } from "../../services/download-service";
import { IdxSelectBoxComponent, IdxDateBoxComponent, IdxTextBoxComponent, IdxCheckBoxComponent } from "../../interfaces/dx-components";
import { observable } from "aurelia-binding";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import { ScopeContainer } from "../../../framework/base/classes/scope-container";
import { RestService } from "../../../framework/base/services/rest-service";
import { LocalizationService } from "../../../framework/base/services/localization-service";
import { autoinject, computedFrom } from "aurelia-framework";
import { IdxPopupComponent, IdxValidationGroupComponent } from "../../interfaces/export";
import { GlobalizationService, DataSourceService } from "../../../framework/base/export";
import { IZitTagOptions } from "../../../zit-ui/elements/zit-tag/export";
import { ICommandData, PopupInfoService } from "../../../framework/forms/export";
import * as moment from "moment";
import { ISchnupperterminZeitraumResult, SchnupperterminService } from "../../services/schnuppertermin-service";
import { StartupService } from "../../services/export";

@autoinject
export class ReportErstellen {
  constructor(
    private _startupService: StartupService,
    private _localizationService: LocalizationService,
    private _simpleWidgetCreator: SimpleWidgetCreatorService,
    private _globalizationService: GlobalizationService,
    private _downloadService: DownloadService,
    private _popupInfoService: PopupInfoService,
    private _restService: RestService,
    private _dataSourceService: DataSourceService,
    private _schnupperterminService: SchnupperterminService
  ) { }

  hasSchnupperterminMaske: boolean = this._startupService.startupInfo.ShowSchnupperterminMaske;
  
  @observable idSelectedGeschaeftspartner: number;
  @observable idSelectedStelleninserat: number;

  eventZeitraumList: ISchnupperterminZeitraumResult[];

  reportEigenschaften = {
    IdStelleninserat: null,
    Typ: null,
    DatumVon: null,
    DatumBis: null,
    IdGeschaeftspartnerList: null,
    OrtPlzList: null,
    NurBestaetigteTermine: true,
    IdKlasse: null
  };

  selectedGeschaeftspartner: any[];

  scopeContainer: ScopeContainer;

  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {};

  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };

  geschaeftspartnerDataSource: DevExpress.data.DataSource;

  datumVon: IdxDateBoxComponent;
  datumVonOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this._globalizationService.getFormatterParser("d"),
    bindingOptions: {
      value: "reportEigenschaften.DatumVon"
    },
    onValueChangedByUser: (e) => {
      this.updateDatumBis();
      this.setGeschaeftspartnerDataSource();
      this.setKlasseDataSource(this.reportEigenschaften.IdStelleninserat);
    }
  };

  datumBis: IdxDateBoxComponent;
  datumBisOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this._globalizationService.getFormatterParser("d"),
    bindingOptions: {
      value: "reportEigenschaften.DatumBis"
    },
    onValueChangedByUser: (e) => {
      this.setGeschaeftspartnerDataSource();
      this.setKlasseDataSource(this.reportEigenschaften.IdStelleninserat);
    }
  };

  reportErstellenPopup: IdxPopupComponent;
  reportErstellenPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "700px",
    maxWidth: "750px",
    title: this._localizationService.translateOnce("report-erstellen.report-erstellen"),
    onShown: () => {
      if (this.reportEigenschaften) {
        this.reportEigenschaften.NurBestaetigteTermine = true;
      }

      const today = moment()
        .startOf("day");

      this.reportEigenschaften.DatumVon = today;
      this.updateDatumBis();

      this.setStelleninseratDataSource();
      this.setGeschaeftspartnerDataSource();
      this.setEventZeitraumList();
    }
  };

  reportAuswaehlenSelectBox: IdxSelectBoxComponent;
  reportAuswaehlenSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    onValueChangedByUser: (e) => {
      const listGp: any[] = this.selectedGeschaeftspartner;
      const listOrte: any[] = this.reportEigenschaften.OrtPlzList;

      if (this.geschaeftspartnerSelectBox) {
        this.geschaeftspartnerSelectBox.setOption({ value: null });
      }

      if (listGp && listGp.length) {
        this.selectedGeschaeftspartner = [];
      }
      if (listOrte && listOrte.length) {
        this.reportEigenschaften.OrtPlzList = [];
      }

      if (this.reportEigenschaften) {
        this.reportEigenschaften.NurBestaetigteTermine = this.reportEigenschaften.Typ == ReportTyp.StatusSchnuppertermin 
          ? false 
          : true;
      }

      this.setKlasseDataSource(this.reportEigenschaften.IdStelleninserat);
      this.setGeschaeftspartnerDataSource();
    },
    dataSource: (() => {
      const dataSource: any[] = [];

      if (this.hasSchnupperterminMaske) {
        dataSource.push(
          { Id: ReportTyp.NachGeschaeftspartner, Bezeichnung: "Nach Geschäftspartner (pdf)" },
          { Id: ReportTyp.BesuchslisteSchuelerKurz, Bezeichnung: "Besuchsliste Schüler - kurz (pdf)" },
          { Id: ReportTyp.BesuchslisteSchuelerNachOrt, Bezeichnung: "Besuchsliste Schüler nach Ort/PLZ (pdf)" },
          { Id: ReportTyp.StatusSchnuppertermin, Bezeichnung: "Status Schulschnuppertermine Schüler (xlsx)" },
          { Id: ReportTyp.UebersichtGrossbetriebe, Bezeichnung: "Übersicht Großbetriebe (pdf)" },
          { Id: ReportTyp.KontingenteGrossbetriebe, Bezeichnung: "Kontingente Großbetriebe (pdf)" });
      } else {
        dataSource.push(
          { Id: ReportTyp.Schnupperer, Bezeichnung: "Auswertung Schnupperlehrlinge (pdf)" }
        )
      }     
      
      const schulschnupperBenutzerrolle = [
        "ADMIN",
        "KUNDE",
        "KUNDE-PLUS",
        "KUNDE-PROFESSIONAL"
      ];
      
      if (schulschnupperBenutzerrolle.indexOf(this._startupService.startupInfo.Benutzer.CodeBenutzerrolle) >= 0) {
        dataSource.push({ Id: ReportTyp.Schulschnuppertermine, Bezeichnung: "Schulschnuppertermine (pdf)" });
      }

      return dataSource;
    })(),
    valueExpr: "Id",
    displayExpr: "Bezeichnung",
    bindingOptions: {
      value: "reportEigenschaften.Typ"
    }
  };

  bestaetigteTermineCheckBox: IdxCheckBoxComponent;
  bestaetigteTermineCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: this._localizationService.translateOnce("report-erstellen.bestaetigte-termine"),
    bindingOptions: {
      value: "reportEigenschaften.NurBestaetigteTermine"
    }
  };

  reportOrtTextBox: IdxTextBoxComponent;
  reportOrtTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    onValueChangedByUser: (e) => {
      const list: any[] = this.reportEigenschaften.OrtPlzList || [];

      if (e.value.length == 0) {
        return;
      }

      list.push(e.value);

      this.reportEigenschaften.OrtPlzList = list;
    },
    onEnterKey: (e) => {
      this.reportOrtTextBox.setOption({ value: null });
    }
  };

  geschaeftspartnerSelectBox: IdxSelectBoxComponent;
  geschaeftspartnerSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Name1",
    searchExpr: "Name1",
    searchEnabled: true,
    bindingOptions: {
      value: "idSelectedGeschaeftspartner",
      dataSource: "geschaeftspartnerDataSource"
    }
  };

  stelleninseratSelectBox: IdxSelectBoxComponent;
  stelleninseratSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "IdStelleninserat",
    displayExpr: "StellenbezeichnungExtern",
    searchExpr: "StellenbezeichnungExtern",
    searchEnabled: true,
    bindingOptions: {
      value: "idSelectedStelleninserat"
    }
  };

  klasseSelectBox: IdxSelectBoxComponent;
  klasseSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    showClearButton: true,
    searchEnabled: true,
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      value: "reportEigenschaften.IdKlasse"
    }
  };

  geschaeftspartnerTagOptions: IZitTagOptions = {
    textExpression: "item.Name",
    icon: {
      faIcon: "fas fa-industry"
    },
    onDeleteClick: (e, data) => {
      e.stopPropagation();
      this.deleteEmpfaenger(data);
    },
  };

  ortTagOptions: IZitTagOptions = {
    textExpression: "item",
    icon: {
      faIcon: "fas fa-map-marker-alt"
    },
    onDeleteClick: (e, data) => {
      e.stopPropagation();
      this.deleteOrte(data);
    },
  };
  
  eventZeitraumTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "far fa-calendar-alt"
    },
    textExpression: "item.Bezeichnung",
    onClick: (ev, tag: ISchnupperterminZeitraumResult) => {
      ev.preventDefault();
      ev.stopPropagation();

      this.reportEigenschaften.DatumVon = tag.Start;
      this.reportEigenschaften.DatumBis = tag.Ende;
    }
  };

  popupCommands: ICommandData[] = [
    {
      id: "reportErstellenCommand",
      icon: "fas fa-check",
      tooltip: "base.save",
      sortIndex: 1010,
      execute: async() => {
        const result = this.validationGroup.instance.validate();
        if (!result.isValid) {
          return;
        }

        await this._downloadService.downloadSchnuppertermine(this.reportEigenschaften);

        this.reportEigenschaften = Object.assign({});
        if (this.geschaeftspartnerSelectBox) {
          this.geschaeftspartnerSelectBox.setOption({ value: null });
        }

        if (this.stelleninseratSelectBox) {
          this.stelleninseratSelectBox.setOption({ value: null });
        }

        DevExpress.ui.notify(this._localizationService.translateOnce("report-erstellen.report-erstellung-erfolgreich"), "SUCCESS", 3000);
        this._popupInfoService.closeAllPopups();
      }
    }
  ];
  
  @computedFrom("hasSchnupperterminMaske", "reportEigenschaften.Typ")
  get showStelleninserat() {
    return this.hasSchnupperterminMaske
      && this.reportEigenschaften.Typ != ReportTyp.Schulschnuppertermine;
  }
  
  @computedFrom("eventZeitraumList", "hasSchnupperterminMaske", "reportEigenschaften.Typ")
  get showEventZeitraeume() {
    return this.eventZeitraumList 
      && this.eventZeitraumList.length > 0
      && this.hasSchnupperterminMaske
      && this.reportEigenschaften.Typ != ReportTyp.Schulschnuppertermine;
  }

  @computedFrom("reportEigenschaften.Typ")
  get showKlasse() {
    if (!this.reportEigenschaften) {
      return false;
    }

    switch (this.reportEigenschaften.Typ) {
      case ReportTyp.BesuchslisteSchuelerNachOrt:
      case ReportTyp.StatusSchnuppertermin:
      case ReportTyp.BesuchslisteSchuelerKurz: {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  
  @computedFrom("reportEigenschaften.Typ")
  get showGeschaeftspartner() {
    if (!this.reportEigenschaften) {
      return false;
    }

    switch (this.reportEigenschaften.Typ) {
      case ReportTyp.NachGeschaeftspartner:
      case ReportTyp.UebersichtGrossbetriebe:
      case ReportTyp.KontingenteGrossbetriebe: {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  
  @computedFrom("reportEigenschaften.Typ")
  get showGeschaeftspartnerTagList() {
    if (!this.reportEigenschaften) {
      return false;
    }

    switch (this.reportEigenschaften.Typ) {
      case ReportTyp.NachGeschaeftspartner: {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  
  @computedFrom("reportEigenschaften.Typ")
  get showOrt() {
    if (!this.reportEigenschaften) {
      return false;
    }

    switch (this.reportEigenschaften.Typ) {
      case ReportTyp.BesuchslisteSchuelerNachOrt:
      case ReportTyp.BesuchslisteSchuelerKurz: {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  
  @computedFrom("hasSchnupperterminMaske", "reportEigenschaften.Typ")
  get showBestaetigteTermine() {
    return this.hasSchnupperterminMaske
      && this.reportEigenschaften.Typ != ReportTyp.Schulschnuppertermine;
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreator.updatePopupOptions({
      idToolbar: "reportErstellenPopupToolbar",
      caption: "report-erstellen.report-erstellen",
      options: this.reportErstellenPopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async idSelectedGeschaeftspartnerChanged(newVal) {
    if (!newVal) {
      return;
    }

    if (this.reportEigenschaften.Typ == ReportTyp.NachGeschaeftspartner) {
      const list: any[] = this.selectedGeschaeftspartner || [];

      const alreadyExists = list
        .some((c) => c.IdGeschaeftspartner === newVal);
      
      if (alreadyExists) {
        return;
      }

      const gp = await this.getEmpfaengerData(newVal);

      list.push(gp);

      this.selectedGeschaeftspartner = list;
      this.reportEigenschaften.IdGeschaeftspartnerList = [];

      list.forEach((c) => this.reportEigenschaften.IdGeschaeftspartnerList.push(c.IdGeschaeftspartner));
    } else {
      this.reportEigenschaften.IdGeschaeftspartnerList = [newVal];
    }
  }

  async idSelectedStelleninseratChanged(newVal, oldVal) {
    this.reportEigenschaften.IdStelleninserat = newVal;

    this.setKlasseDataSource(newVal);
  }
  
  private deleteEmpfaenger(empfaenger: any) {
    const list: any[] = this.selectedGeschaeftspartner;
    if (!list || !list.length) {
      return;
    }

    list.splice(list.indexOf(empfaenger), 1);

    this.reportEigenschaften.IdGeschaeftspartnerList
      .splice(this.reportEigenschaften.IdGeschaeftspartnerList
        .indexOf(empfaenger.IdGeschaeftspartner), 1);
  }
  private deleteOrte(ort: any) {
    const list: any[] = this.reportEigenschaften.OrtPlzList;
    if (!list || !list.length) {
      return;
    }

    list.splice(list.indexOf(ort), 1);
  }

  private async getEmpfaengerData(id: number): Promise<any> {
    if (!id) {
      return;
    }

    const geschaeftspartner = await this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Simple/Geschaeftspartner"),
      getOptions: {
        columns: ["Name1"],
        where: ["Id", id]
      }
    });

    return {
      IdGeschaeftspartner: id,
      Name: geschaeftspartner[0].Name1
    };
  }

  private setGeschaeftspartnerDataSource(): void {
    this.geschaeftspartnerDataSource = this._dataSourceService.createDataSource(this.scopeContainer, {
      webApiAction: "ZIT/Simple/Geschaeftspartner",
      webApiColumns: ["Id", "Name1"],
      webApiOrderBy: [{columnName: "Name1", sortOrder: 0}]
    }, {
        getCustomFilters: () => {
          const filters = [];

          if (this.reportEigenschaften.Typ == ReportTyp.UebersichtGrossbetriebe || this.reportEigenschaften.Typ == ReportTyp.KontingenteGrossbetriebe) {
            filters.push({
              webApiCustomKey: "HasSchnuppertermin",
              webApiCustomValue: "true"
            });

            if (this.reportEigenschaften.DatumVon) {
              filters.push({
                webApiCustomKey: "SchnupperterminTagVon",
                webApiCustomValue: "'" + (<moment.Moment>this.reportEigenschaften.DatumVon).toISOString() + "'"
              });
            }

            if (this.reportEigenschaften.DatumBis) {
              filters.push({
                webApiCustomKey: "SchnupperterminTagBis",
                webApiCustomValue: "'" + (<moment.Moment>this.reportEigenschaften.DatumBis).toISOString() + "'"
              });
            }
          }

          return filters;
        }
      });
  }
  private async setKlasseDataSource(idStelleninserat: any): Promise<any> {
    if (!idStelleninserat) {
      this.setKlasseDataSourceEx([]);

      return Promise.resolve(true);
    }

    const restResult = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Schnuppertermin/Klassen"),
      data: this.reportEigenschaften,
      increaseLoadingCount: true
    });
    
    if (!restResult || !restResult.length) {
      this.setKlasseDataSourceEx([]);
      return;
    }

    this.setKlasseDataSourceEx(restResult);
  }
  private setKlasseDataSourceEx(dataSource: any): void {
    this.reportEigenschaften.IdKlasse = null;

    if (this.klasseSelectBox) {
      this.klasseSelectBox.instance.option("dataSource", dataSource);
    } else {
      this.klasseSelectBoxOptions.dataSource = dataSource;
    }
  }
  private async setStelleninseratDataSource(): Promise<any> {
    const restResult: IStelleninserat[] = await this._restService.get({
      url: this._restService.getApiUrl("ZIT/Schnuppertermin/SchnupperterminStelleninserate"),
      increaseLoadingCount: true
    });
    
    if (!restResult || !restResult.length) {
      return;
    }

    if (this.stelleninseratSelectBox) {
      this.stelleninseratSelectBox.instance.option("dataSource", restResult);
    } else {
      this.stelleninseratSelectBoxOptions.dataSource = restResult;
    }

    const vorb = restResult.find((i) => i.IsVorbelegung);

    if (vorb) {
      this.idSelectedStelleninserat = vorb.IdStelleninserat;
    } else if (restResult && restResult.length == 1) {
      this.idSelectedStelleninserat = restResult[0].IdStelleninserat;
    }
  }
  private async setEventZeitraumList() {    
    if (this._startupService.startupInfo.Benutzer.CodeBenutzerrolle != "KUNDE-SCHULE") {
      return;
    }

    const zeitraum = this._schnupperterminService.getSchnupperterminZeitraum();

    this.eventZeitraumList = await this._schnupperterminService.getEventZeitraumSchnupperterminList(
      zeitraum.von, 
      zeitraum.bis);
  }

  private updateDatumBis() {
    if (!this.reportEigenschaften.DatumVon) {
      return;
    }
    
    if (this.hasSchnupperterminMaske) {
      this.reportEigenschaften.DatumBis = moment(this.reportEigenschaften.DatumVon)
        .add(1, "week")
        .toDate();
    } else {
      this.reportEigenschaften.DatumBis = moment(this.reportEigenschaften.DatumVon)
        .add(6, "month")
        .toDate();
    }
  }
}

enum ReportTyp {
  NachGeschaeftspartner = 0,
  BesuchslisteSchuelerNachOrt = 1,
  StatusSchnuppertermin = 2,
  UebersichtGrossbetriebe = 3,
  KontingenteGrossbetriebe = 4,
  BesuchslisteSchuelerKurz = 5,
  Schnupperer = 6,
  Schulschnuppertermine = 7
}

interface IStelleninserat {
  IdStelleninserat: number;
  StellenbezeichnungExtern: string;
  GueltigVon: Date;
  GueltigBis: Date;
  IsAktiv: boolean;
  IsVorbelegung: boolean;
}
