import { autoinject, bindable } from "aurelia-framework";
import { IItem } from '../toolbar/item';

@autoinject
export class RibbonToolbarItemTmpl {
  constructor(
    private element: Element
  ) {}

  @bindable item: IItem;
}
