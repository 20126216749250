import { autoinject } from "aurelia-framework";
import { INachrichtVorlage, NachrichtVorlageService } from "../../services/nachricht-vorlage-service";
import { ContextMenu } from "../../../framework/forms/classes/context-menu";
import { LocalizationService } from "../../../framework/base/services/localization-service";
import {
  IdxPopupComponent,
  IdxSelectBoxComponent,
  IdxTextAreaComponent,
  IdxTextBoxComponent, IdxValidationGroupComponent
} from "../../interfaces/dx-components";
import { INachrichtTextmarke, NachrichtService } from "../../services/nachricht-service";
import { ScopeContainer } from "../../../framework/base/classes/scope-container";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import { ICommandData } from "../../../framework/forms/interfaces/command-data";
import { DialogConfirmService } from "../../../framework/forms/services/dialog-confirm-service";
import { Datei } from "../datei/datei";
import { DateiAuswaehlen } from "../datei-auswaehlen/datei-auswaehlen";
import { FileService } from "../../../framework/base/services/file-service";
import { BrowserService } from "../../../framework/base/services/browser-service";

@autoinject
export class NachrichtVorlage {
  private _callback: {(vorlage: INachrichtVorlage): void};
  
  constructor(
    private _browserService: BrowserService,
    private _dialogConfirmService: DialogConfirmService,
    private _fileService: FileService,
    private _localizationService: LocalizationService,
    private _nachrichtService: NachrichtService,
    private _nachrichtVorlageService: NachrichtVorlageService,
    private _simpleWidgetCreator: SimpleWidgetCreatorService
  ) {
  }
  
  scopeContainer: ScopeContainer;
  
  vorlage: INachrichtVorlage;
  
  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxWidth: "750px",
    maxHeight: "100vh",
    onShown: () => {
      this.bezeichnungTextBox.instance.focus();
    }
  };

  fotoCommand: ICommandData = {
    id: "kameraCommand",
    idCategory: "$start",
    icon: "fas fa-camera",
    sortIndex: 1200,
    execute: () => {
      this.dateiAuswaehlen.onMakePhoto(true, true);
    }
  };
  videoCommand: ICommandData = {
    id: "videoCommand",
    idCategory: "$start",
    icon: "fas fa-video",
    sortIndex: 1201,
    execute: () => {
      this.dateiAuswaehlen.onMakeVideo(true, true);
    }
  };
  dokumentCommand: ICommandData = {
    id: "dokumentCommand",
    idCategory: "$start",
    icon: "fas fa-paperclip",
    sortIndex: 1202,
    execute: () => {
      this.dateiAuswaehlen.onOpenFiles(true);
    }
  };
  saveCommand: ICommandData = {
    id: "saveCommand",
    idCategory: "$start",
    icon: "fas fa-save",
    tooltip: "base.save_tooltip",
    sortIndex: 1400,
    isVisible: true,
    execute: async () => {
      const r = this.validationGroup.instance.validate();
      if (!r.isValid) {
        DevExpress.ui.notify(r.brokenRules[0].message, "error", 3000);
        return;
      }
      
      this.vorlage = await this._nachrichtVorlageService.saveVorlage(this.vorlage);
      this.popup.instance.hide();
      
      if (this._callback) {
        this._callback(this.vorlage);
      }
    }
  }
  deleteCommand: ICommandData = {
    id: "deleteCommand",
    idCategory: "$start",
    icon: "far fa-trash-alt",
    tooltip: "base.delete_tooltip",
    sortIndex: 1401,
    isVisible: false,
    execute: async () => {
      const c = await this._dialogConfirmService.show({
        title: this._localizationService.translateOnce("base.question"),
        message: this._localizationService.translateOnce("base.sure_delete_question")
      });
      
      if (!c) {
        return;
      }
      
      await this._nachrichtVorlageService.deleteVorlage(this.vorlage);
      this.popup.instance.hide();

      if (this._callback) {
        this._callback(null);
      }
    }
  }

  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {};

  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };

  bezeichnungTextBox: IdxTextBoxComponent;
  bezeichnungOptions: DevExpress.ui.dxTextBoxOptions = {
    maxLength: 250,
    bindingOptions: {
      value: "vorlage.Bezeichnung"
    }
  };

  textmarkeList: INachrichtTextmarke[];
  textmarkenOptions: DevExpress.ui.dxSelectBoxOptions = {
    placeholder: "Auswählen...",
    displayExpr: "Bezeichnung",
    valueExpr: "Value",
    bindingOptions: {
      dataSource: "textmarkeList"
    },
    onItemClick: (e) => {
      this.insertTextmarke(this.textTextArea.instance.element(), e.itemData.Bezeichnung);
    }
  }
  textmarkenSelectBox: IdxSelectBoxComponent;

  textOptions: DevExpress.ui.dxTextAreaOptions = {
    height: "150px",
    bindingOptions: {
      value: "vorlage.Text"
    }
  }
  textTextArea: IdxTextAreaComponent;

  signaturOptions: DevExpress.ui.dxTextAreaOptions = {
    height: "50px",
    bindingOptions: {
      value: "vorlage.Signatur"
    }
  }

  dateiAuswaehlen: DateiAuswaehlen;
  datei: Datei;
  
  bind(bindingContext, overrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: bindingContext,
      overrideContext: overrideContext
    });

    const commands: ICommandData[] = [this.saveCommand, this.deleteCommand, this.fotoCommand];

    if (this._browserService.isMobile) {
      commands.push(this.videoCommand);
    } else {
      commands.push(this.dokumentCommand);
    }
    
    this._simpleWidgetCreator.updatePopupOptions({
      idToolbar: "nachrichtVorlagePopupToolbar",
      caption: "nachricht-vorlage.vorlage-erstellen",
      options: this.popupOptions,
      commands: commands,
      scopeContainer: this.scopeContainer
    });
    
    this.loadTextmarken();
  }
  unbind() {
    this.scopeContainer.disposeAll()
    this.scopeContainer = null;
  }
  
  async showContextMenu(target, callback?: {(vorlage: INachrichtVorlage): void}) {
    this._callback = callback;
    const vorlageList = await this._nachrichtVorlageService.getVorlagen();
    
    const contextMenu = new ContextMenu();
    contextMenu.items.push({
      text: this._localizationService.translateOnce("nachricht-vorlage.neue-vorlage"),
      execute: (ev) => {
        this.vorlage = {
          Dateien: []
        };
        
        this.updateCommandVisibility();
        this.popup.instance.show();
      }
    });

    let beginGroup = true;
    vorlageList.forEach(v =>
    {
      contextMenu.items.push({
        text: v.Bezeichnung,
        beginGroup: beginGroup,
        execute: (ev) => {
          this.vorlage = v;

          this.updateCommandVisibility();
          this.popup.instance.show();
        }
      })
      beginGroup = false;
    });
    
    contextMenu.show(target);
  }

  async onDateiAusgewaehlt(e: CustomEvent) {
    for (let file of <File[]>e.detail.files) {
      const dmsLink = await this._fileService.upload(file);
      if (!dmsLink) {
        return;
      }

      this.vorlage.Dateien.push({ DMSLink: dmsLink, Dateiname: file.name });
    }

    this.datei.refresh();
  }
  
  private updateCommandVisibility() {
    this.deleteCommand.isVisible = !!this.vorlage.Id;
  }

  private async loadTextmarken() {
    this.textmarkeList = await this._nachrichtService.getTextmarken();
  }
  private insertTextmarke(element: Element, value: string) {
    const input = <HTMLInputElement>element.querySelectorAll("input, textarea").item(0);
    input.focus();

    this.insertAtCursor(input, `{{${value}}}`);
  }
  private insertAtCursor(input: HTMLInputElement, value: string) {
    const doc: any = document;

    if (doc.selection) {
      const sel = doc.selection.createRange();
      sel.text = value;
    } else if (input.selectionStart || input.selectionStart == 0) {
      const startPos = input.selectionStart;
      const endPos = input.selectionEnd;

      input.value = input.value.substring(0, startPos)
        + value
        + input.value.substring(endPos);
    } else {
      input.value = (input.value || "") + value;
    }

    input.dispatchEvent(new Event("change"));
  }
}
