import * as fwx from "../../../../framework/forms/form-export";
import {
    DokumentUnterfertigungUtils
} from "./dokument-unterfertigung-utils";

@fwx.autoinject
export class DokumentUnterfertigungForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_DokumentUtils: DokumentUnterfertigungUtils) {
        super(element, formBaseImport);
        this.id = "dokument-unterfertigung";
        this.title = "dokument-unterfertigung.dokument-unterfertigung_caption";
        this.addModel({
            "id": "$m_Dokument",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "filters": []
        });
        this.addFunction("$f_DokumentUtils", $f_DokumentUtils, "functions.$f_DokumentUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "dokument-unterfertigung.r_dokumentunterfertigungdokumentencode_caption",
            "binding": {
                "bindTo": "$f_DokumentUtils.validierungData.DokumentCode",
                "bindToFQ": "functions.$f_DokumentUtils.validierungData.DokumentCode"
            },
            "validationRules": [],
            "id": "r_dokumentUnterfertigungDokumentenCode",
            "options": {
                "optionsName": "r_dokumentUnterfertigungDokumentenCodeOptions",
                "optionsNameFQ": "r_dokumentUnterfertigungDokumentenCodeOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "dokument-unterfertigung.r_dokumentunterfertigungtan_caption",
            "binding": {
                "bindTo": "$f_DokumentUtils.unterfertigungData.TAN",
                "bindToFQ": "functions.$f_DokumentUtils.unterfertigungData.TAN"
            },
            "validationRules": [],
            "id": "r_dokumentUnterfertigungTAN",
            "options": {
                "optionsName": "r_dokumentUnterfertigungTANOptions",
                "optionsNameFQ": "r_dokumentUnterfertigungTANOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "dataSavedPopup",
            "options": {
                "optionsName": "dataSavedPopupOptions",
                "optionsNameFQ": "dataSavedPopupOptions"
            },
            "caption": "dokument-unterfertigung.datasavedpopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_DokumentUtils.dataSavedClosePopupCommand",
                    "bindToFQ": "functions.$f_DokumentUtils.dataSavedClosePopupCommand"
                }
            }]
        });
        super.onConstructionFinished();
    }
}