import { autoinject, bindable, observable } from "aurelia-framework";
import { CodesService } from "../../services/export";
import { IZitTagOptions } from "../../../zit-ui/elements/zit-tag/zit-tag-options";
import { IBenutzerrolleCodeData } from "../../interfaces/export";

@autoinject
export class Codes {
  constructor(
    private _codesService: CodesService
  ) {}

  @bindable showHeader = true;
  @bindable typ: CodesTyp = CodesTyp.Objekt;
  @bindable @observable id: number = void(0);

  codeList: any[] = [];

  tagOptions: IZitTagOptions = {
    icon: {
      faIconExpression: "item.TagSymbol ? item.TagSymbol : ''",
      textExpression: "item.tagText ? item.tagText : ''"
    },
    textExpression: "item.Bezeichnung",
    onClick: (ev, data) => {
      ev.preventDefault();
      ev.stopPropagation();

      data.IsAktiv = !data.IsAktiv;
      this.saveCode(data);      
    },
    infoList: [{
      icon: {
        faIconExpression: "item.IsAktiv ? 'fas fa-check' : 'fas fa-times'",
        backgroundColorExpression: "item.IsAktiv ? 'green':'red'"
      }
    }]
  };

  bind() {
    this.loadCodes();
  }

  idChanged() {
    this.loadCodes();
  }

  private async loadCodes() {
    if (!this.id) {
      this.codeList = [];
      return;
    }

    switch (this.typ) {
      case CodesTyp.Objekt: {
        this.codeList = await this._codesService.getObjektCodes(this.id);
        break;
      }
      case CodesTyp.Benutzer: {
        this.codeList = await this._codesService.getBenutzerCodes(this.id);
        break;
      }
      default: {
        this.codeList = [];
        break;
      }
    }
  }
  private async saveCode(codeData: IBenutzerrolleCodeData) {
    switch (this.typ) {
      case CodesTyp.Objekt: {
        this._codesService.saveObjektCodes(this.id, [codeData]);
        break;
      }
      case CodesTyp.Benutzer: {
        this._codesService.saveBenutzerCodes(this.id, [codeData]);
        break;
      }
      default: {
        break;
      }
    }
  }
}

enum CodesTyp {
  Objekt = 0,
  Benutzer = 1
}