import { autoinject, bindable, observable } from "aurelia-framework";
import { ListView, IListViewOptions } from '../../../framework/forms/elements/list-view/export';
import { RestService } from '../../../framework/base/services/rest-service';
import { EventAggregator, Subscription } from '../../../../node_modules/aurelia-event-aggregator';
import { INachrichtBenutzerInfo, NachrichtAnzeigeTyp, NachrichtService } from '../../services/nachricht-service';
import { StartupService } from "../../services/startup-service";

@autoinject
export class NachrichtNachrichten {
  private _timeoutReload: any;
  private _nachrichtErstelltSubscription: Subscription;
  private _nachrichtGeloeschtSubscription: Subscription;
  private _nachrichtSetUngelesenSubscription: Subscription;

  constructor(
    private _element: Element,
    private _restService: RestService,
    private _startupService: StartupService,
    private _nachrichtService: NachrichtService,
    private _eventAggregator: EventAggregator
  ) { }

  @bindable @observable benutzerInfo: INachrichtBenutzerInfo;
  @bindable @observable isStellvertreter: boolean;

  nachrichtListView: ListView;
  nachrichtListViewOptions: IListViewOptions = {
    useDefaultListItemStyle: false,
    itemClass: "z--nachr-nachrichten-item",
    onItemClick: (e) => {
      this.onNachrichtClick(e);
    }
  };

  scrollView: any;

  isLoading: boolean = false;

  bind() {
    this._nachrichtErstelltSubscription = this._eventAggregator.subscribe("nachricht:erstellt", (e) => {
      if (!e.all && !e.idEmpfaengerList) {
        return;
      }

      const any = e.all || e.idEmpfaengerList.some(i => this.benutzerInfo && i == this.benutzerInfo.idBenutzer);
      if (!any) {
        return;
      }

      if (!this.nachrichtListView) {
        return;
      }

      this.nachrichtListView.refresh(false);
    });

    this._nachrichtGeloeschtSubscription = this._eventAggregator.subscribe("nachricht:geloescht", () => {
      this.reload();
    })
    
    this._nachrichtSetUngelesenSubscription = this._eventAggregator.subscribe("nachricht:set-unglesen", async (args) =>
    {
      const items = this.nachrichtListView.itemsOptions.items;
      if (!items) {
        return;
      }
      
      const item = items.find(c => c.IdAbsender != this._startupService.startupInfo.Benutzer.IdBenutzer);
      if (!item) {
        return;
      }
      
      args.isHandled = true;

      await this._nachrichtService.doNachrichtUngelesenMarkieren(item.KeyNachricht);

      this._eventAggregator.publish("nachricht:ungelesen", {
        keyNachricht: item.KeyNachricht
      });
    });

    this.nachrichtListViewOptions.dataSource = new DevExpress.data.DataSource(
      <any>new DevExpress.data.CustomStore({
        load: (e) => {
          if (!this.benutzerInfo) {
            return Promise.resolve({ data: [], totalCount: 0 });
          }

          return new Promise<any>((resolve, reject) => {
            this._restService.post({
              url: this._restService.getApiUrl("ZIT/Nachricht/Nachricht"),
              data: {
                Take: e.take,
                Skip: e.skip,
                IdBenutzer: this.benutzerInfo.idBenutzer,
                IsGruppe: this.benutzerInfo.anzeigeTyp == NachrichtAnzeigeTyp.Gruppe,
                IsStellvertreter: this.isStellvertreter
              },
              increaseLoadingCount: true
            }).then(r => {
              this.isLoading = false;

              if (r.NachrichtList) {
                const hasChangedGelesen = r.NachrichtList.some(n => n.HasChangedGelesen);
                if (hasChangedGelesen) {
                  this._eventAggregator.publish("nachricht:gelesen", {
                    keyNachricht: "ALL"
                  });
                }
              }

              resolve({
                data: r.NachrichtList,
                totalCount: r.Anzahl
              });
            })
          });
        }
      }));
  }
  unbind() {
    this._nachrichtErstelltSubscription.dispose();
    this._nachrichtErstelltSubscription = null;

    this._nachrichtGeloeschtSubscription.dispose();
    this._nachrichtGeloeschtSubscription = null;
  }

  benutzerInfoChanged() {
    if (!this.nachrichtListView) {
      return;
    }

    this.reload();
  }
  isStellvertreterChanged() {
    if (!this.nachrichtListView) {
      return;
    }

    this.reload();
  }
  reload() {
    this.isLoading = true;

    if (this._timeoutReload) {
      clearTimeout(this._timeoutReload);
    }

    this._timeoutReload = setTimeout(() => {
      if (this.scrollView) {
        const sv: DevExpress.ui.dxScrollView = this.scrollView.instance;
        if (sv) {
          sv.scrollTo(0);
        }
      }

      this.nachrichtListView.refresh(false);
    }, 100);
  }

  onNachrichtClick(e) {
    if (e.event.target.tagName == "A") {
      const element: any = <HTMLElement>e.event.target;
      if (element) {
        const url = element.href;
        const target = element.target;

        window.open(url, target);
      }

      return;
    }

    const event = new CustomEvent("on-nachricht-click", {
      detail: {
        sender: this,
        keyNachricht: e.item.KeyNachricht
      },
      bubbles: true
    });

    this._element.dispatchEvent(event);
  }
}
