import { autoinject } from "../../framework/forms/form-export";
import { RestService } from "./../../framework/base/services/rest-service";

@autoinject
export class DomaeneService {

  constructor(
    private _restService: RestService) { }

  urlImpressum: string;
  farbeDomaene: string;
  bezeichnungMobilLogin: string;
  hasSms: boolean;
  loginHeadline: string;
  loginSubHeadline: string;
  dmsLinkLoginDokButton: string;
  loginDokButtonText: string;

  async loadLoginInformation() {
    const r = await this._restService.get({
      url: this._restService.getApiUrl(`ZIT/Domaene/LoginInformation`),
      increaseLoadingCount: true
    });

    if (!r) {
      return;
    } else {
      this.urlImpressum = r.UrlImpressum;
      this.farbeDomaene = r.Farbe;
      this.bezeichnungMobilLogin = r.BezeichnungMobilLogin;
      this.hasSms = r.HasSms;
      this.loginHeadline = r.LoginHeadline;
      this.loginSubHeadline = r.LoginSubHeadline;
      this.loginDokButtonText = r.LoginDokButtonText;
      this.dmsLinkLoginDokButton = r.DMSLinkLoginDokButton;
    }
  }

  setDomainColorsStyle() {
    const styleTag = document.createElement("style");
    styleTag.type = "text/css";
    styleTag.appendChild(
      document.createTextNode(`
            .t--login--btncolor > * > *{
              background-color: ${this.farbeDomaene};
              color: #fff;
              border: none;
            }
            .t--login-container__link:hover{
              color:${this.farbeDomaene};
            }
            .t--login-logoheader .t--login--logoheader--header {
              background-color: ${this.farbeDomaene};
              color: #fff;
            }`
      ));
    document.head.appendChild(styleTag);
  }
}
