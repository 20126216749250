import { autoinject, transient } from 'aurelia-framework';
import { FormBase } from './../../../../framework/forms/classes/form-base';

@autoinject
@transient()
export class PersonenAdresseUtils {
  constructor() { }

  form: FormBase;

  bind(form: FormBase) {
    this.form = form;
    form.models.onLoaded.register((args) => {
      if (args && args.model && args.model.id === "$m_Person") {
        this.setAdresse(args);
      }
      return Promise.resolve();
    });
  }

  setAdresse(args): void {
    if (args.data) {
      if (!args.data.Adressen) {
        args.data.Adressen = [];
      }
      if (args.data.Adressen.length === 0) {
        const adresse = {};
        args.data.Adressen.push(adresse);
      }
      this.form.models.data.$m_PersonAdresse = args.data.Adressen[0];
    }
  }
}
