import { autoinject, computedFrom } from "aurelia-framework";
import { RestService } from "../../../framework/base/services/rest-service";
import { RouterService } from "../../../framework/forms/export";
import { DomaeneService } from "./../../services/domaene-service";
import { LoginService } from "./services/login-service";

@autoinject
export class Login {

  constructor(
    public routerService: RouterService,
    private _restService: RestService,
    private _loginService: LoginService,
    private _domaeneService: DomaeneService
  ) {
    const time = new Date()
      .getTime()
      .toString();
    const randomNumberString = Math.floor((Math.random() * 100))
      .toString();
    const randomKey = time + randomNumberString;

    this.loginImageStyle = {
      "background-image": `url('${this._restService.getApiUrl("ZIT/Domaene/Hintergrundbild?key=")}${randomKey}')`
    };

    this.checkBenutzerParam();
  }

  loginImageStyle: any;

  @computedFrom("routerService.currentViewItem.controller.currentViewModel.title")
  get title(): string {
    if (!this.routerService.currentViewItem || !this.routerService.currentViewItem.controller) {
      return null;
    }

    const currentViewModel = this.routerService.currentViewItem.controller["currentViewModel"];
    if (!currentViewModel) {
      return;
    }

    return currentViewModel.title;
  }

  @computedFrom("routerService.currentViewItem.controller.currentViewModel.customTitle")
  get customTitle(): string {
    if (!this.routerService.currentViewItem || !this.routerService.currentViewItem.controller) {
      return null;
    }

    const currentViewModel = this.routerService.currentViewItem.controller["currentViewModel"];
    if (!currentViewModel) {
      return;
    }

    return currentViewModel.customTitle;
  }

  @computedFrom("routerService.currentViewItem.controller.currentViewModel.customSubtitle")
  get customSubtitle(): string {
    if (!this.routerService.currentViewItem || !this.routerService.currentViewItem.controller) {
      return null;
    }

    const currentViewModel = this.routerService.currentViewItem.controller["currentViewModel"];
    if (!currentViewModel) {
      return;
    }

    return currentViewModel.customSubtitle;
  }

  async activate() {
    await this._domaeneService.loadLoginInformation();
  }

  attached() {
    this.routerService.registerRoutes([{
      moduleId: "zit-ui/views/login/views/login/login-form",
      caption: "zit-ui.login-anmelden",
      route: "Login"
    }, {
      moduleId: "zit-ui/views/login/views/loginprofil/loginprofil-form",
      caption: "zit-ui.login-anmelden",
      route: "Loginprofil"
    }, {
      moduleId: "zit-ui/views/login/views/password-reset/password-reset-form",
      caption: "zit-ui.login-passwort-vergessen",
      route: "Passwort-Zuruecksetzen"
    }, {
      moduleId: "zit-ui/views/login/views/login-tan/login-tan-form",
      caption: "zit-ui.login-anmelden",
      route: "Login-Tan"
    }, {
      moduleId: "zit-ui/views/login/views/activate-account/activate-account-form",
      caption: "zit-ui.login-account-aktivieren",
      route: "Account-Aktivieren"
    }, {
      moduleId: "zit-ui/views/login/views/password-confirm/password-confirm-form",
      caption: "zit-ui.login-passwort-bestaetigen",
      route: "Passwort-Bestaetigung"
    }, {
      moduleId: "zit-ui/views/auto-login/auto-login-form",
      caption: "zit-ui.auto-login",
      route: "AutoLogin"
    }, {
      moduleId: "zit-ui/views/app-login/app-login-form",
      caption: "zit-ui.app-login",
      route: "app-login"
    }], "Login");
  }

  private checkBenutzerParam() {
    const hash = location.hash;
    if (!hash) {
      return;
    }

    const indexOfQuestion = hash.indexOf("?");
    if (indexOfQuestion < 0) {
      return;
    }

    const params = hash.substr(indexOfQuestion + 1);
    if (!params) {
      return;
    }

    const tokens = params.split("&");
    for (const token of tokens) {
      const keyValue = token.split("=");
      if (keyValue.length != 2) {
        continue;
      }

      if (keyValue[0] != "benutzer") {
        continue;
      }

      this._loginService.benutzer = keyValue[1];
      break;
    }
  }
}
