import { IObjektInfoData } from './../../interfaces/objekt-info-data';
import { IZitTagOptions } from './../../../zit-ui/elements/zit-tag/zit-tag-options';
import { StartupService } from './../../services/startup-service';
import { autoinject, bindingBehavior, bindable } from "aurelia-framework";
import { FormBase } from '../../../framework/forms/classes/form-base';
import { ObjektInfoService } from '../../services/objekt-info-service';
import { IZitMarkersOptions, ZitMarkerDisplayMode } from '../../../zit-ui/elements/zit-markers/export';
import { ISubheadlineOptions } from './sub-headline-options';
import { DokumentService, CustomEditPopupService } from '../../services/export';
import { IModel } from '../../../framework/forms/export';
import { DateiAuswaehlen } from '../datei-auswaehlen/datei-auswaehlen';
import { BrowserService, FileService } from '../../../framework/base/export';

@autoinject
export class SubHeadline {
  constructor(
    private _element: Element,
    private _objektInfoService: ObjektInfoService,
    private _startupService: StartupService,
    private _dokumentService: DokumentService,
    private _customEditPopupService: CustomEditPopupService,
    private _browserService: BrowserService,
    private _fileService: FileService
  ) { }

  form: FormBase;
  model: IModel;

  id: number;
  objektTyp: string;
  objektFullName: string;
  eltern: string;

  status: string;
  symbol: string;
  farbe: string;

  showUploader: boolean = false;
  dateiAuswaehlen: DateiAuswaehlen;

  @bindable options: ISubheadlineOptions;

  titelData = {
    titel: "",
    icon: "",
    backgroundColor: ""
  }

  titelTagOptions: IZitTagOptions = {
    icon: {
      faIconExpression: "item.icon",
      backgroundColorExpression: "item.backgroundColor"
    },
    textExpression: "item.titel"
  }

  markersOptions: IZitMarkersOptions = {
    displayMode: ZitMarkerDisplayMode.kreis,
    idObjekt: null
  };

  bind(bindingContext) {
    if (bindingContext instanceof FormBase) {
      this.form = bindingContext;
      this.form.callOnBind(() => {
        this.prepare();
      });
    }
  }

  onUploadClick() {
    if (this._browserService.isMobile) {
      this.dateiAuswaehlen.onOpenGallery();
    } else {
      this.dateiAuswaehlen.onOpenFiles();
    }
  }
  async onDateiAusgewaehlt(e: CustomEvent) {
    const file = e.detail.files[0];
    const dmsLink = await this._fileService.upload(file);
    if (!dmsLink) {
      return;
    }

    let fullName = this.objektFullName;
    let data = this.form.models.data[this.model.id];
    let idObjekt = data.Id;
    let idStelleninserat = null;

    switch (fullName) {
      case "TIP.ZIT.Business.Entities.Objekte.Bewerbungen.Bewerbung": {
        if (!data.Kopf || !data.Kopf.IdPerson || !data.Kopf.IdStelleninserat) {
          return null;
        }

        idObjekt = data.Kopf.IdPerson;
        idStelleninserat = data.Kopf.IdStelleninserat;
        fullName = "TIP.ZIT.Business.Entities.Objekte.Personen.Person";

        break;
      }
    }

    this._customEditPopupService.dokumentEdit.show({
      setValuesOnModelWithKeyIdLoaded: {
        IdObjekt: idObjekt
      },
      mappings: {
        "$id": 0,
        "$v_dmsLinkDokument": dmsLink,
        "$v_dateiname": file.name,
        "$v_objektTypeFullname": fullName,
        "$v_idStelleninserat": idStelleninserat,
      }
    });
  }

  private prepare() {
    this.model = this.form.models.modelWithKeyId;
    if (!this.model || !this.model.webApiAction) {
      return;
    }

    const objektInfo = this._objektInfoService.getObjektByWebApiUrl(this.model.webApiAction);
    if (objektInfo) {
      this.objektTyp = objektInfo.Bezeichnung;
      this.objektFullName = objektInfo.Fullname;
    }

    this.showUploader = this._dokumentService.attachDropDokument(
      <any>this._element,
      objektInfo.Fullname,
      () => {
        return this.form.models.data[this.model.id];
      }
    );

    this.loadDataTitel(objektInfo);

    this.form.models.onLoaded.register(async(e) => {
      if (e.model.id != this.model.id) {
        return;
      }
      if (!e.data) {
        return;
      }

      this.id = e.data.Id;
      this.status = e.data.ObjektStatus;
      this.symbol = e.data.ObjektStatusSymbol;
      this.farbe = e.data.ObjektStatusSymbolFarbe;
      this.eltern = e.data.BezeichnungEltern;
      this.markersOptions.idObjekt = e.data.Id;
      this.updateTitel();
    });
  }
  private async updateTitel() {
    const arr = [];

    if (this.objektTyp && this.id > 0) {
      arr.push(this.objektTyp);
    }

    if (this.options && this.options.getTitel) {
      arr.push(await this.options.getTitel());
    } else if (this.eltern) {
      arr.push(this.eltern);
    }

    this.titelData.titel = arr.join(": ");
  }

  private loadDataTitel(objektInfo: IObjektInfoData) {
    const cardInfo = this._startupService.getCardInfoByObjektFullName(objektInfo.Fullname);

    this.titelData.backgroundColor = cardInfo.CardKompaktHintergrundfarbe;
    this.titelData.icon = cardInfo.CardKompaktSymbol;

    if (!this.titelData.icon) {
      this.titelTagOptions.icon = null;
    }
  }
}
