import { autoinject, PLATFORM } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { FileService } from "../../framework/base/export";

PLATFORM.moduleName("../card-templates/ansprechperson-kompakt.html");
PLATFORM.moduleName("../card-templates/ansprechperson-klein.html");
PLATFORM.moduleName("../card-templates/bewerbung-kunde-klein.html");
PLATFORM.moduleName("../card-templates/bewerbung-kunde-kompakt.html");
PLATFORM.moduleName("../card-templates/bewerbung-kunde-gross.html");
PLATFORM.moduleName("../card-templates/bewerbung-person-kompakt.html");
PLATFORM.moduleName("../card-templates/bewerbung-person-gross.html");
PLATFORM.moduleName("../card-templates/dispo-person-gross.html");
PLATFORM.moduleName("../card-templates/dispo-personenzuteilung-klein.html");
PLATFORM.moduleName("../card-templates/dispo-personenzuteilung-kompakt.html");
PLATFORM.moduleName("../card-templates/dispo-planstelle-kompakt.html");
PLATFORM.moduleName("../card-templates/dispo-planstelle-gross.html");
PLATFORM.moduleName("../card-templates/geschaeftspartner-kompakt.html");
PLATFORM.moduleName("../card-templates/person-kompakt.html");
PLATFORM.moduleName("../card-templates/person-klein.html");
PLATFORM.moduleName("../card-templates/person-kunde-kompakt.html");
PLATFORM.moduleName("../card-templates/person-gross.html");
PLATFORM.moduleName("../card-templates/profil-firma-kunde-kompakt.html");
PLATFORM.moduleName("../card-templates/profil-firma-kunde-gross.html");
PLATFORM.moduleName("../card-templates/stelleninserat-kunde-kompakt.html");
PLATFORM.moduleName("../card-templates/stelleninserat-kunde-gross.html");
PLATFORM.moduleName("../card-templates/stelleninserat-person-kompakt.html");
PLATFORM.moduleName("../card-templates/stelleninserat-person-gross.html");
PLATFORM.moduleName("../card-templates/termin-kompakt.html");
PLATFORM.moduleName("../card-templates/termin-heartbase-kompakt.html");
PLATFORM.moduleName("../card-templates/aufgabe-kompakt.html");
PLATFORM.moduleName("../card-templates/ziel-kompakt.html");
PLATFORM.moduleName("../card-templates/ziel-klein.html");
PLATFORM.moduleName("../card-templates/information-kompakt.html");
PLATFORM.moduleName("../card-templates/event-kompakt.html");
PLATFORM.moduleName("../card-templates/event-zeitraum-kompakt.html");
PLATFORM.moduleName("../card-templates/event-teilnehmer-kompakt.html");
PLATFORM.moduleName("../card-templates/event-teilnehmer-klein.html");

@autoinject
export class ZitCardService {
  constructor(
    private _eventAggregator: EventAggregator,
    private _fileService: FileService
  ) {
    this.registerCardButtonClickEvents();
  }

  private registerCardButtonClickEvents() {
    this._eventAggregator.subscribe("information:datei-oeffnen", (ev) => {
      const dmsLink = ev.data.DMSLink || ev.data.Data.DMSLink;
      if (!dmsLink) {
        return;
      }

      this._fileService.download(dmsLink);     
    });
    this._eventAggregator.subscribe("information:link-oeffnen", (ev) => {
      const linkUrl = ev.data.LinkUrl || ev.data.Data.LinkUrl;
      if (!linkUrl) {
        return;
      }

      window.open(linkUrl, "_blank");
    });
  }
}
