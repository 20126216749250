import { autoinject } from "aurelia-framework";
import { IframePopup } from '../elements/iframe-popup/iframe-popup';

@autoinject
export class IframePopupService {
  constructor() {}

  popup: IframePopup;
}

export interface IFramePopupShowOptions {
  titel?: string;
  src: string;
  width: number;
  height: number;
  allow?: string;
  allowFullScreen?: boolean;
}