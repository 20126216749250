import {
  autoinject,
  singleton
} from "aurelia-framework";
import {
  FormBase
} from "../classes/form-base";
import * as Interfaces from "../interfaces/export";

@autoinject
@singleton(true)
export class Variables {
  private form: FormBase;
  private info: any;

  constructor() {
    this.data = {};
    this.info = {};
  }

  data: any;

  addInfo(variable: Interfaces.IVariable) {
    this.info[variable.id] = variable;
    this.checkNestedFormMapping(variable);
  }
  getInfo(id: string): Interfaces.IVariable {
    return this.info[id];
  }
  registerForm(form: FormBase) {
    if (this.form) {
      throw new Error("Form was already registered");
    }

    this.form = form;
  }
  reset() {
    for (let key in this.data) {
      this.data[key] = null;
    }
  }

  dispose() {
  }

  private checkNestedFormMapping(variable: Interfaces.IVariable) {
    if (!this.form.isNestedForm) {
      return;
    }

    const id = this.form.element.getAttribute("view-model.ref");
    if (!id) {
      return;
    }

    const info = this.form.parent.nestedForms.getInfo(id);
    if (!info) {
      return;
    }

    variable.isMapped = info.mappings.some(mapping => mapping.to === variable.id);
  }
}
