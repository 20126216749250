import { autoinject, transient } from "aurelia-framework";
import { RestService } from "../../../../../framework/base/export";
import { AuthorizationService } from "../../../../../framework/base/services/authorization-service";
import { LocationService } from "../../../../../framework/base/services/location-service";
import { FormBase } from "../../../../../framework/forms/export";
import { IdxSelectBoxComponent } from "../../../../../zit/interfaces/export";
import { LoginprofilService } from "../../../../../zit/services/loginprofil-service";
import { BrowserService } from "./../../../../../framework/base/services/browser-service";
import { LocalizationService } from "./../../../../../framework/base/services/localization-service";
import { ICommandData } from "./../../../../../framework/forms/interfaces/command-data";
import { DomaeneService } from "./../../../../services/domaene-service";

@autoinject
@transient()
export class LoginprofilFuncs {
  private form: FormBase;
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public browserService: BrowserService,
    private _authorizationService: AuthorizationService,
    private _localizationService: LocalizationService,
    private _loginprofilService: LoginprofilService,
    private _restService: RestService,
    private _locationService: LocationService,
    private _domaeneService: DomaeneService,
    private _browserService: BrowserService) {
    const time = new Date()
      .getTime()
      .toString();
    const randomNumberString = Math.floor((Math.random() * 100))
      .toString();
    const randomKey = time + randomNumberString;
    this.logoSrc = this._restService.getApiUrl(`ZIT/Domaene/Logo?key=${randomKey}`);
  }

  urlImpressum: string;
  farbeDomaene: string;
  bezeichnungLoginMobil: string;
  logoSrc: string;
  isMobile: boolean;

  requiredValidationRule = {
    type: "required",
    message: this._localizationService.translateOnce("zit-ui.login-loginprofil-pflicht")
  };

  loginprofilCommand: ICommandData = {
    id: "loginprofil",
    title: "zit-ui.login-loginprofil-uebernehmen",
    execute: async () => {
      const id = this.form.models.data.$m_Loginprofil.Id;

      const validationResult = this.form.createValidationResult();
      await this.form.validate(validationResult);

      if (!validationResult.isValid) {
        return;
      }

      this._loginprofilService.setLoginProfil(id, false)
        .then((r) => {
          this._authorizationService.isLoggedInChanged(true);
          DevExpress.ui.notify(this._localizationService.translateOnce("zit-ui.login-anmeldung-erfolgreich"), "SUCCESS", this.NOTIFY_TIMEOUT);
        })
        .catch((r) => {
          DevExpress.ui.notify(this._localizationService.translateOnce("zit-ui.login-anmeldung-nicht-erfolgreich"), "ERROR", this.NOTIFY_TIMEOUT);
        });
    }
  };
  zuerueckCommand: ICommandData = {
    id: "zuerueckZuLogin",
    title: "zit-ui.login-loginprofil-zuerueck",
    execute: () => {
      this._locationService.goTo({
        url: "Login",
        clearStack: true
      });
    }
  };

  openImpressumClick() {
    window.open(this.urlImpressum);
  }

  async bind(form: FormBase) {
    this.form = form;

    this.focusFirstEditor();
    this.resetLoginprofilModel();

    this.urlImpressum = this._domaeneService.urlImpressum;
    this.farbeDomaene = this._domaeneService.farbeDomaene;
    this._domaeneService.setDomainColorsStyle();
    this.bezeichnungLoginMobil = this._domaeneService.bezeichnungMobilLogin;
  }

  private focusFirstEditor() {
    this.form.onReady.register((args) => {
      const selectBox: IdxSelectBoxComponent = this.form["r_loginprofil"];

      selectBox.instance.focus();

      return Promise.resolve();
    });
  }
  private resetLoginprofilModel() {
    this.form.models.data.$m_Loginprofil = {
      Id: null
    };
  }
}
