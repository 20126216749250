import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, Scope } from "aurelia-framework";
import * as moment from "moment";
import { GlobalizationService, LocalizationService, LocationService, RestService, ScopeContainer } from "../../../framework/base/export";
import { DialogConfirmService, ICommandData, SimpleWidgetCreatorService } from "../../../framework/forms/export";
import { IZitTagOptions } from "../../../zit-ui/elements/zit-tag/zit-tag-options";
import { IZitToolbarOptions } from "../../../zit-ui/elements/zit-toolbar/zit-toolbar-options";
import { BenutzerTyp } from "../../enumerations/export";
import { IdxPopupComponent, IdxValidationGroupComponent } from "../../interfaces/export";
import { StartupService } from "../../services/export";
import { Datenschutz } from "../datenschutz/datenschutz";
import { DokumentListView } from "../dokument-list-view/dokument-list-view";
import { SchnupperterminPersonAuswahl } from "../schnuppertermin-person-auswahl/schnuppertermin-person-auswahl";
import { SchnupperterminStornieren } from "../schnuppertermin-stornieren/schnuppertermin-stornieren";
import { StelleninseratService } from "./../../services/stelleninserat-service";

@autoinject
export class SchnupperterminErstellen {
  private NOTIFY_TIMEOUT: number = 3000;
  private MAX_PERSONLIST_LENGTH: number = 2;
  private DEFAULT_TAGE: number = 6;
  private DAYS_OF_WEEK: number = 7;

  constructor(
    public globalizationService: GlobalizationService,
    public startupService: StartupService,
    private _restService: RestService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _locationService: LocationService,
    private _localizationService: LocalizationService,
    private _eventAggregator: EventAggregator,
    private _dialogConfirmService: DialogConfirmService) { }

  scope: Scope;
  scopeContainer: ScopeContainer;

  showOptions: ISchnupperterminErstellenShowOptions;
  model: IModel;
  data: IRequestResult;
  kalenderInfo = {};
  canPersonDelete: boolean = false;

  schnupperterminPersonAuswahl: SchnupperterminPersonAuswahl;
  schnupperterminStornieren: SchnupperterminStornieren;
  dokumentListView: DokumentListView;
  datenschutz: Datenschutz;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "750px",
    height: "auto",
    maxHeight: "90vh",
    onShown: (e: any) => {
      e.component.repaint();
    }
  };
  validationGroup: IdxValidationGroupComponent;
  wunschterminOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "data.WunschList",
      value: "model.idWunschtermin"
    },
    onValueChangedByUser: () => this.onWunschterminChanged()
  };
  anzahlTageRadioOptions: DevExpress.ui.dxRadioGroupOptions = {
    displayExpr: "text",
    valueExpr: "value",
    bindingOptions: {
      dataSource: "model.anzahlTageList",
      disabled: "!model.idWunschtermin",
      value: "model.anzahlTageRadio",
    },
    onValueChangedByUser: (e) => {
      this.model.anzahlTage = e.value < 0
        ? this.DEFAULT_TAGE
        : e.value;

      this.checkCanUseSelectedDate();
    }
  };
  anzahlTageBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this.globalizationService.getNumberFormat("f0"),
    width: "70px",
    bindingOptions: {
      disabled: "!model.idWunschtermin",
      min: "model.minTageNumberBox",
      max: "model.maxTageNumberBox",
      value: "model.anzahlTage"
    },
    onValueChangedByUser: () => this.checkCanUseSelectedDate()
  };
  kalendar: DevExpress.ui.dxCalendar;
  kalenderOptions: DevExpress.ui.dxCalendarOptions = {
    cellTemplate: (data, index, element: HTMLTableDataCellElement) => {
      const span = document.createElement("span");
      span.innerText = data.text;

      if (data.view != "month") {
        return span;
      }

      const current = moment(data.date);

      element.style.position = "relative";
      span.style.position = "relative";

      const typ: KalenderInfoTyp = this.kalenderInfo[moment(data.date)
        .startOf("day")
        .toISOString()];

      let isBetween = false;
      if (this.model.startDatum && this.model.anzahlTage) {
        const start = moment(this.model.startDatum);
        const ende = moment(start)
          .add(this.model.anzahlTage, "days");

        isBetween = current.isBetween(start, ende);
      }

      if (isBetween) {
        const border = document.createElement("div");
        border.className = "schnuppertermin-erstellen--calendar schnuppertermin-erstellen--calendar-gewaehlt";
        span.style.color = "white";
        element.appendChild(border);
      }
      if (typ != void (0)) {
        const border = document.createElement("div");
        border.className = "schnuppertermin-erstellen--calendar";

        switch (typ) {
          case KalenderInfoTyp.Zugesagt: {
            border.className += " schnuppertermin-erstellen--calendar-zugesagt";
            break;
          }
          case KalenderInfoTyp.NichtZugesagt: {
            border.className += " schnuppertermin-erstellen--calendar-nicht-zugesagt";
            break;
          }
          case KalenderInfoTyp.NichtZugesagtGleicheGp: {
            border.className += " schnuppertermin-erstellen--calendar-nicht-zugesagt-selbe-gp";
            break;
          }
          case KalenderInfoTyp.Kontingent: {
            border.className += " schnuppertermin-erstellen--calendar-ueberbucht";
            break;
          }
          default: {
            return;
          }
        }

        element.appendChild(border);
      }

      return span;
    },
    bindingOptions: {
      disabled: "!model.idWunschtermin",
      min: "model.minDatum",
      max: "model.maxDatum",
      value: "model.startDatum"
    },
    onInitialized: (e) => {
      this.kalendar = <any>e.component;
    },
    onValueChangedByUser: () => {
      this.checkCanUseSelectedDate();
    }
  };
  einverstaendnisOptions: DevExpress.ui.dxCheckBoxOptions = {
    bindingOptions: {
      value: "model.einverstaendnisAkzeptiert"
    }
  };
  personTagOptions: IZitTagOptions = {
    icon:
    {
      text: "P"
    },
    text: "${person.name}",
    infoList: [{
      icon: {
        faIcon: "fas fa-times"
      },
      isVisibleExpression: "canPersonDelete",
      onClick: async(e, data) => {
        const index = this.model.personList.indexOf(data);
        if (index < 0) {
          return;
        }

        this.model.personList.splice(index, 1);

        if (this.model.personList.length < this.MAX_PERSONLIST_LENGTH) {
          await this.refreshWunschterminList();
          await this.onWunschterminChanged();
        }
      }
    }]
  };
  ortTagOptions: IZitTagOptions = {
    icon: {
      text: "A"
    },
    text: "${data.Ort}",
    infoList: [{
      icon: {
        faIcon: "fas fa-map-marker-alt"
      },
      onClick: () => {
        window.open("https://www.google.com/maps/place/" + this.data.Ort.replace(" ", "+"), "_blank");
      }
    }]
  };
  datumTagOptions: IZitTagOptions = {
    icon: {
      faIcon: "far fa-calendar-alt"
    },
    textExpression: `item.StartDatum && item.EndeDatum
      ? globalizationService.format(item.StartDatum, 'DT_DD.MM.YYYY (dd)').toUpperCase() + ' - ' + globalizationService.format(item.EndeDatum, 'DT_DD.MM.YYYY (dd)').toUpperCase()
      : ''`
  };
  termineUnternehmenTagOptions: IZitTagOptions = {
    textExpression: "item.Bezeichnung",
    icon: {
      faIcon: "far fa-calendar-alt"
    },
    onClick: (e, data) => {
      this.popup.instance.hide();
      this._locationService.goTo({
        url: `#Objekte/EventZeitraum/${data.IdEventZeitraum}`
      });
    }
  };
  dokumentToolbarOptions: IZitToolbarOptions = {
    title: "schnuppertermin-utils.dokument-toolbar-titel",
    smallToolbar: true,
    items: [{
      id: "showDokumentToolbarOptionen",
      icon: "fas fa-plus",
      execute: () => {
        if (!this.dokumentListView) {
          return;
        }

        this.dokumentListView.onDokumentAddClicked();
      }
    }]
  };

  schnupperterminAnfrageTagOptions: IZitTagOptions = {
    icon: {
      faIconExpression: "item.SchnupperterminAnfrageartTagSymbol",
      backgroundColorExpression: "startupService.startupInfo.Skin.Farbe"
    },
    textExpression: "item.SchnupperterminAnfrageartBezeichnung",
    backgroundColorExpression: "startupService.startupInfo.Skin.Farbe"
  };

  neuCommand: ICommandData = {
    id: "neuCommand",
    icon: "fas fa-plus",
    sortIndex: 1000,
    tooltip: "base.add",
    isVisible: false,
    execute: () => {
      this.add();
    }
  };
  speichernCommand: ICommandData = {
    id: "speichernCommand",
    icon: "fas fa-save",
    sortIndex: 1010,
    tooltip: "base.save",
    execute: () => {
      this.validateAndSave();
    }
  };
  stornierenCommand: ICommandData = {
    id: "stornierenCommand",
    icon: "fas fa-ban",
    sortIndex: 1020,
    tooltip: "schnuppertermin-utils.stornieren",
    isVisible: false,
    execute: () => {
      this.schnupperterminStornieren.show({
        idStelleninserat: this.showOptions.idStelleninserat,
        idEventTeilnehmer: this.data.IdEventTeilnehmer,
        callback: () => this.popup.instance.hide()
      });
    }
  };
  stelleninseratStornierenCommand: ICommandData = {
    id: "stornierenStelleninseratCommand",
    icon: "far fa-minus-square",
    sortIndex: 1021,
    isVisible: this.startupService.startupInfo.Benutzer.CodeBenutzerrolle == "ADMIN"
      || this.startupService.startupInfo.Benutzer.CodeBenutzerrolle == "KUNDE-SCHULE",
    tooltip: "schnuppertermin-utils.fuer_alle_stornieren",
    execute: async() => {
      const r = await this._dialogConfirmService.show({
        title: this._localizationService.translateOnce("base.question"),
        message: this._localizationService.translateOnce("schnuppertermin-utils.question_fuer_alle_stornieren"),
      });

      if (r) {
        await this._restService.post({
          url: this._restService.getApiUrl("ZIT/Objekt/DeaktivierenStelleninseratSchnuppertermin"),
          data: {
            IdStelleninserat: this.showOptions.idStelleninserat
          },
          increaseLoadingCount: true
        });

        this._eventAggregator.publish("schnuppertermin:refresh-list");
        this.popup.instance.hide();
      }
    }
  };

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: null
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "schnupperterminToolbar",
      caption: "schnuppertermin.schnuppertermin",
      scopeContainer: this.scopeContainer,
      options: this.popupOptions,
      commands: [this.neuCommand, this.speichernCommand, this.stornierenCommand, this.stelleninseratStornierenCommand]
    });

    this.wunschterminOptions["validators"] = [this.createRequiredRule("Wunschtermin")];
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async show(options: ISchnupperterminErstellenShowOptions) {
    this.showOptions = options;
    this.resetModel();

    const setPerson = () => {
      if (this.data && this.data.IdPerson && this.data.PersonName) {
        this.model.personList.push({
          id: this.data.IdPerson,
          name: this.data.PersonName
        });
      }
    };

    if (options.idEventTeilnehmer) {
      await this.loadBestehend();
      setPerson();
    } else {
      await this.loadErstellen();
      setPerson();

      if (this.data.WunschList.length == 1) {
        this.model.idWunschtermin = this.data.WunschList[0].Id;
        this.onWunschterminChanged();
      } else {
        this.prepareAnzahlTage(1, null, null);
        this.prepareCalendar(null, null);
      }
    }

    this.canPersonDelete = !this.showOptions.idEventTeilnehmer
      && this.model.personList.length == 0;

    this.stornierenCommand.isVisible = this.data.IdEventTeilnehmer > 0
      && !this.data.IsBestaetigt;
    this.neuCommand.isVisible = this.data.IdEventTeilnehmer > 0;

    await this.popup.instance.show();
  }

  onPersonAuswahl() {
    this.schnupperterminPersonAuswahl.show(async(personList) => {
      const count = this.model.personList.length;

      for (const person of personList) {
        const exists = this.model.personList.some((p) => p.id == person.id);
        if (exists) {
          continue;
        }

        this.model.personList.push({
          id: person.id,
          name: person.name
        });
      }

      if (count < this.MAX_PERSONLIST_LENGTH) {
        await this.refreshWunschterminList();
        await this.onWunschterminChanged();
      }

      this.popup.instance.repaint();
    });
  }
  async onWunschterminChanged() {
    const wunsch = this.data.WunschList.find((e) => e.Id == this.model.idWunschtermin);

    this.model.minTageNumberBox = wunsch && wunsch.Minimaldauer
      ? Math.max(this.DEFAULT_TAGE, wunsch.Minimaldauer)
      : null;

    this.model.maxTageNumberBox = wunsch && wunsch.Maximaldauer
      ? wunsch.Maximaldauer
      : null;

    await this.loadKalenderInfo();

    if (wunsch) {
      this.prepareAnzahlTage(wunsch.Standarddauer, wunsch.Minimaldauer, wunsch.Maximaldauer);
      this.prepareCalendar(wunsch.Start, wunsch.Ende);
    } else {
      this.prepareAnzahlTage(1, null, null);
      this.prepareCalendar(null, null);
    }

    this.repaintCalendar();
  }
  onNeuClick() {
    this.add();
  }
  onSpeichernClick() {
    this.validateAndSave();
  }

  private checkCanUseSelectedDate() {
    setTimeout(() => {
      if (!this.checkCanUseDate(this.model.startDatum)) {
        this.model.startDatum = null;

        DevExpress.ui.notify(
          this._localizationService.translateOnce("schnuppertermin.zeitraum-nicht-möglich-da-konflikt"),
          "ERROR",
          this.NOTIFY_TIMEOUT
        );
      }

      this.repaintCalendar();
    }, 0);
  }
  private checkCanUseDate(startDatum: Date): boolean {
    let startCheck = moment(startDatum);
    const endeCheck = moment(startDatum)
      .add(this.model.anzahlTage - 1, "days");

    const maxDateDiff = moment(this.model.maxDatum)
      .diff(endeCheck);

    if (this.model.maxDatum && maxDateDiff < 0) {
      return false;
    }

    while (endeCheck.diff(startCheck, "days") >= 0) {
      const typ: KalenderInfoTyp = this.kalenderInfo[startCheck.toISOString()];

      if (typ != void (0) && typ != KalenderInfoTyp.NichtZugesagt) {
        return false;
      }

      startCheck = moment(startCheck)
        .add(1, "days");
    }

    return true;
  }
  private createRequiredRule(text: string) {
    return <DevExpress.ui.CustomRule>{
      type: "required",
      message: this._localizationService.translateOnce("forms.validator_required", [text])
    };
  }
  private async loadErstellen() {
    this.data = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Schnuppertermin/ErstellenRequest"),
      data: {
        IdStelleninserat: this.showOptions.idStelleninserat,
        IdPerson: this.showOptions.idPerson
      },
      increaseLoadingCount: true
    });
  }
  private async loadBestehend() {
    this.data = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Schnuppertermin/LadenRequest"),
      data: {
        IdEventTeilnehmer: this.showOptions.idEventTeilnehmer,
        IdStelleninserat: this.showOptions.idStelleninserat
      },
      increaseLoadingCount: true
    });
  }
  private async loadKalenderInfo() {
    const load = this.model.personList.length == 1
      && !!this.model.idWunschtermin;

    this.kalenderInfo = {};

    if (!load) {
      return;
    }

    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Schnuppertermin/KalenderInfo"),
      data: {
        IdStelleninserat: this.showOptions.idStelleninserat,
        IdPerson: this.model.personList[0].id,
        IdEventZeitraum: this.model.idWunschtermin
      },
      increaseLoadingCount: true
    });

    for (const i of r) {
      const isoDate = moment(i.Datum)
        .startOf("day")
        .toISOString();

      this.kalenderInfo[isoDate] = i.Typ;
    }
  }
  private prepareAnzahlTage(standard: number, min: number, max: number) {
    const list: IAnzahlTageItem[] = [];

    for (let i = 1; i < this.DAYS_OF_WEEK; i++) {
      if (min != void (0) && i < min) {
        continue;
      }
      if (max != void (0) && i > max) {
        continue;
      }

      if (i < this.DEFAULT_TAGE) {
        list.push({
          value: i,
          text: i.toString()
        });
      } else {
        list.push({
          value: -1,
          text: "länger"
        });

        break;
      }
    }

    this.model.anzahlTageList = list;

    this.model.anzahlTage = standard == void (0)
      ? (list.length > 0 ? list[0].value : null)
      : standard;

    const maxTage = 5;
    this.model.anzahlTageRadio = this.model.anzahlTage > maxTage
      ? -1
      : this.model.anzahlTage;
  }
  private prepareCalendar(start: Date, ende: Date) {
    this.model.minDatum = start;
    this.model.maxDatum = ende;
    this.model.startDatum = null;

    if (start && ende) {
      let startCheck = moment(start);
      const endeCheck = moment(ende);
      while (endeCheck.diff(startCheck, "days") >= 0) {
        if (this.checkCanUseDate(startCheck.toDate())) {
          this.model.startDatum = startCheck.toDate();
          break;
        }

        startCheck = moment(startCheck)
          .add(1, "days");
      }
    }
  }
  private async refreshWunschterminList() {
    const r: IWunsch[] = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Schnuppertermin/WunschlistRequest"),
      data: {
        IdGeschaeftspartner: this.data.IdGeschaeftspartner,
        IdLehrberuf: this.data.IdLehrberuf,
        IdStelleninserat: this.showOptions.idStelleninserat,
        IdPerson: this.model.personList.length === 1
          ? this.model.personList[0].id
          : null
      }
    });

    if (this.model.idWunschtermin) {
      const exists = r.some((w) => w.Id == this.model.idWunschtermin);

      if (!exists) {
        this.model.startDatum = null;
        this.model.idWunschtermin = null;

        this.onWunschterminChanged();
      }
    }

    this.data.WunschList = r;
  }
  private repaintCalendar() {
    if (!this.kalendar) {
      return;
    }

    this.kalendar.repaint();
  }

  private async add() {
    const r = await this._dialogConfirmService.show({
      title: this._localizationService.translateOnce("base.question"),
      message: this.startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.NatuerlichePerson
        ? this._localizationService.translateOnce("schnuppertermin.weiterer-termin-für-person-lehrberuf")
        : this._localizationService.translateOnce("schnuppertermin.weiterer-termin-für-lehrberuf")
    });

    if (!r) {
      return;
    }

    this.popup.instance.hide();
    this.show({
      idStelleninserat: this.showOptions.idStelleninserat,
      idPerson: this.startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.NatuerlichePerson
        ? this.model.personList[0].id
        : null
    });
  }
  private validateAndSave() {
    if (!this.validate()) {
      return;
    }

    this.save();
  }
  private validate() {
    if (!this.showOptions.idEventTeilnehmer) {
      if (this.model.personList.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("schnuppertermin-utils.keine-person-gewaehlt"),
          "ERROR",
          this.NOTIFY_TIMEOUT
        );

        return false;
      }

      if (!this.model.startDatum) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("schnuppertermin.kein-zeitraum-ausgewaehlt"),
          "ERROR",
          this.NOTIFY_TIMEOUT
        );

        return false;
      }

      if (!this.datenschutz.hasAlleBestaetigt()) {
        return false;
      }
    }

    const validationGroupConfig = DevExpress.validationEngine.getGroupConfig(this.validationGroup);
    if (validationGroupConfig) {
      const validationResult = validationGroupConfig.validate();
      if (!validationResult.isValid) {
        DevExpress.ui.notify(
          validationResult.brokenRules[0].message,
          "ERROR",
          this.NOTIFY_TIMEOUT
        );

        return false;
      }
    }

    return true;
  }
  private async save() {
    const r: IErstellenResult = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Schnuppertermin/SchnupperterminErstellen"),
      data: {
        IdEventTeilnehmer: this.showOptions.idEventTeilnehmer,
        IdStelleninserat: this.showOptions.idStelleninserat,
        IdPersonList: this.model.personList.map((p) => p.id),
        IdEventZeitraum: this.model.idWunschtermin,
        StartDatum: this.model.startDatum,
        AnzahlTage: this.model.anzahlTage,
        DatenschutzList: this.data.DatenschutzList
      },
      increaseLoadingCount: true
    });

    if (r.Fehler) {
      DevExpress.ui.notify(
        r.Fehler,
        "ERROR",
        this.NOTIFY_TIMEOUT);

      return;
    }

    DevExpress.ui.notify(
      this._localizationService.translateOnce("schnuppertermin-utils.termin_erstellen"),
      "SUCCESS",
      this.NOTIFY_TIMEOUT);

    this._eventAggregator.publish("schnuppertermin:erstellt", {
      IdStelleninserat: this.showOptions.idStelleninserat,
      IdEventTeilnehmer: r.IdEventTeilnehmer,
      HasDokument: r.HasDokument
    });

    this.popup.instance.hide();
  }

  private resetModel() {
    this.model = {
      personList: [],
      anzahlTageList: []
    };
  }
}

export interface ISchnupperterminErstellenShowOptions {
  idStelleninserat: number;
  idPerson?: number;
  idEventTeilnehmer?: number;
}
interface IModel {
  anzahlTageList: IAnzahlTageItem[];
  minTageNumberBox?: number;
  maxTageNumberBox?: number;
  minDatum?: Date;
  maxDatum?: Date;

  personList: IPerson[];
  idWunschtermin?: number;
  anzahlTage?: number;
  anzahlTageRadio?: number;
  startDatum?: Date;
}
interface IPerson {
  id: number;
  name: string;
}
interface IRequestResult {
  IdEventTeilnehmer: number;
  IdPerson: number;
  PersonName: string;

  IdGeschaeftspartner: number;
  Geschaeftspartner: string;
  Ort: string;

  IdLehrberuf: number;
  Lehrberuf: string;

  StartDatum: Date;
  EndeDatum: Date;
  IsBestaetigt: boolean;

  WunschList: IWunsch[];
  DatenschutzList: any[];
}
interface IWunsch {
  Id: number;
  Bezeichnung: string;
  Start: Date;
  Ende: Date;
  Standarddauer: number;
  Minimaldauer: number;
  Maximaldauer: number;
}
interface IAnzahlTageItem {
  text: string;
  value: number;
}
interface IErstellenResult {
  IdEventTeilnehmer: number;
  HasDokument: boolean;
  Fehler: string;
}
enum KalenderInfoTyp {
  Kontingent = 0,
  NichtZugesagt = 1,
  NichtZugesagtGleicheGp = 2,
  Zugesagt = 3
}
