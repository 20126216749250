import { autoinject } from "aurelia-framework";
import { RestService } from "./../../framework/base/services/export";

@autoinject
export class AnsprechpersonService {
  constructor(
    private _restService: RestService
  ) { }

  loadAnsprechpersonByGeschaeftspartner(idGeschaeftspartner: number, columns?: string[]): Promise<any[]> {
    return this._restService.get({
      url: this._restService.getWebApiUrl("ZIT/Objekte/Ansprechperson"),
      getOptions: {
        columns: columns,
        customs: [{
          key: "IdGeschaeftspartner",
          value: idGeschaeftspartner.toString()
        }]
      }
    });
  }
}
