import { StartupService } from "./../../../services/startup-service";
import { autoinject, computedFrom, transient } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { FormBase } from "../../../../framework/forms/classes/form-base";

@autoinject
@transient()
export class StelleninseratKopfElementChannelEditUtils {
  constructor(
    private _startupService: StartupService,
    private _eventAggregator: EventAggregator) { }

  form: FormBase;

  @computedFrom("form.models.data.$m_StelleninseratKopfElementZuChannel._CanAddChannel")
  get canModifyChannel() {
    return this.form?.models?.data?.$m_StelleninseratKopfElementZuChannel?._CanAddChannel || false;
  }

  bind(form: FormBase) {
    this.form = form;

    this.form.models.onSaved.register(async(args) => {
      this._eventAggregator.publish("stelleninserat-kopf-element-channel:saved", {});
    });
  }
}
