import { DataSourceService, RestService, ScopeContainer } from "../../../framework/base/export";
import { ICommandData } from "../../../framework/forms/export";
import { autoinject } from "../../../framework/forms/form-export";
import { IdxPopupComponent, IdxSelectBoxComponent } from "../../interfaces/export";
import { LocalizationService } from "./../../../framework/base/services/localization-service";
import { SimpleWidgetCreatorService } from "./../../../framework/forms/widget-services/simple-widget-creator-service";

@autoinject
export class BewerbungstypAendern {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    private _restService: RestService,
    private _dataSourceService: DataSourceService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _localizationService: LocalizationService) { }

  bewerbungstypDataSource: DevExpress.data.DataSource;
  scopeContainer: ScopeContainer;
  keyList: number[] = [];

  popupCommands: ICommandData[] = [{
    id: "changeBewerbungstyp",
    idCategory: "$start",
    icon: "fas fa-save",
    tooltip: "base.save",
    sortIndex: 1000,
    execute: () => {
      this.changeBewerbungstyp();
    }
  }];
  bewerbungstypAendernPopup: IdxPopupComponent;
  bewerbungstypAendernPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    width: "800px",
    maxHeight: "80%",
    maxWidth: "800px"
  };

  bewerbungstypSelectBox: IdxSelectBoxComponent;
  bewerbungstypSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    searchEnabled: true
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });
    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "bewerbungstypAendernPopupToolbar",
      caption: "bewerbungstyp-aendern.popup-title",
      options: this.bewerbungstypAendernPopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  showPopup(keyList: number[]) {
    this.keyList = keyList;
    this.bewerbungstypAendernPopup.instance.show();
    this.loadBewerbungstypDataSource();
  }

  private loadBewerbungstypDataSource() {
    this.bewerbungstypDataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ZIT/Stammdaten/StelleninseratBewerbungstyp"
      }
    );

    this.bewerbungstypSelectBox.instance.option("dataSource", this.bewerbungstypDataSource);
    this.bewerbungstypSelectBox.instance.option("value", null);
  }

  private async changeBewerbungstyp() {
    const idBewerbungstyp = this.bewerbungstypSelectBox.instance.option("value");

    const data: IBewerbungstypAendern = {
      IdStelleninseratList: this.keyList,
      IdBewerbungstyp: idBewerbungstyp
    };

    await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Stelleninserat/StelleninseratBewerbungstypAendern"),
      data: data,
      increaseLoadingCount: true
    });

    DevExpress.ui.notify(this._localizationService.translateOnce("bewerbungstyp-aendern.bewerbungstyp-geaendert-confirmation"), "SUCCESS", this.NOTIFY_TIMEOUT);
    this.bewerbungstypAendernPopup.instance.hide();
  }
}

export interface IBewerbungstypAendern {
  IdStelleninseratList: number[];
  IdBewerbungstyp: number;
}
