import { transient, autoinject } from "aurelia-framework";
import { FormBase } from '../../../framework/forms/export';

@autoinject
@transient()
export class DashboardUtils {
  constructor(
  ) {}

  form: FormBase;

  bind(form: FormBase)   {
    this.form = form;
  }
}
