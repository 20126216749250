import { IZitToolbarOptions } from './../../../zit-ui/elements/zit-toolbar/zit-toolbar-options';
import { ToolbarService } from './../../../framework/forms/services/toolbar-service';
import { autoinject, Scope, OverrideContext, bindable, TaskQueue, computedFrom } from 'aurelia-framework';
import { ScopeContainer, DataSourceService } from '../../../framework/base/export';
import { LocalizationService } from '../../../framework/base/services/export';
import { ICommandData, SimpleWidgetCreatorService } from '../../../framework/forms/export';
import { IdxDataGridComponent, IdxPopupComponent, IdxValidationGroupComponent, IMultiMarkerData } from '../../interfaces/export';
import { MarkerService } from '../../services/export';

@autoinject
export class MarkerMultiObjekt {
  private _savedCallBack: { () };
  constructor(
    private dataSource: DataSourceService,
    private localization: LocalizationService,
    private marker: MarkerService,
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private toolbar: ToolbarService
  ) { }

  @bindable keyList: number[];

  @computedFrom("newMarker.Bezeichnung", "newMarker.Farbe")
  get canAddNewMarker() {
    return this.newMarker && this.newMarker.Bezeichnung && this.newMarker.Farbe ?
      true : false;
  }
  newMarker: IMultiMarkerData = {
    IdObjektMarker: -1,
    Assign: true,
    Delete: false,
    Bezeichnung: null,
    Farbe: null
  };

  objektFullName: string;

  scope: Scope;
  scopeContainer: ScopeContainer;

  popupCommands: ICommandData[] = [
    {
      id: "saveMarkerMultiObjekt",
      idCategory: "$start",
      icon: "fas fa-save",
      tooltip: "base.save",
      sortIndex: 0,
      execute: () => {
        this.onSaveMarkersClicked();
      }
    }];
  markerMultiObjektPopup: IdxPopupComponent;
  markerMultiObjektPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxWidth: "420px"
  }

  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };
  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {};

  multiMarkerGridDataSource: IMultiMarkerData[];
  multiMarkerGrid: IdxDataGridComponent;
  multiMarkerGridOptions: DevExpress.ui.dxDataGridOptions = {
    columns: [{
      headerCellTemplate: `<i class="fas fa-plus"></i>`,
      cssClass: "zit-cell-colored",
      width: 35,
      allowSorting: false,
      dataType: "boolean",
      dataField: "Assign"
    }, {
      headerCellTemplate: `<i class="far fa-trash-alt"></i>`,
      cssClass: "zit-cell-colored",
      width: 35,
      allowSorting: false,
      dataType: "boolean",
      dataField: "Delete",
    }, {
      caption: this.localization.translateOnce("marker-multi-objekt.bezeichnung"),
      dataType: "string",
      dataField: "Bezeichnung"
    }],
    showColumnLines: false,
    editing: {
      allowUpdating: true,
      mode: "cell"
    },
    height: "400px",
    onRowPrepared: (e) => {
      if (e.rowType != "data") {
        return;
      }

      const cell:  any = Array.from(e.rowElement.querySelectorAll(".zit-cell-colored"));

      if(!cell || !cell.length){
        return;
      }

      cell.forEach((c: HTMLTableCellElement) => c.style.backgroundColor = e.data.Farbe);
    },
    onRowUpdating: (e) => {
      if (e.newData.Assign) {
        e.key.Delete = false;
      } else if (e.newData.Delete)
        e.key.Assign = false;
    },
    bindingOptions: {
      dataSource: "multiMarkerGridDataSource"
    }
  }

  newMarkerCommands: ICommandData[] = [{
    id: "addNewMarker",
    icon: "fas fa-save",
    tooltip: "base.save",
    isEnabledExpression: "canAddNewMarker",
    execute: () => {
      this.onAddMarkerClicked();
    }
  }];
  newMarkerToolbarOptions: IZitToolbarOptions;
  newMarkerBezeichnungOptions: DevExpress.ui.dxTextBoxOptions = {
    bindingOptions: {
      value: "newMarker.Bezeichnung"
    }
  }
  newMarkerFarbeOptions: DevExpress.ui.dxColorBoxOptions = {
    editAlphaChannel: true,
    bindingOptions: {
      value: "newMarker.Farbe"
    }
  }

  showPopup(objektFullName: string, savedCallBack?: { () }) {
    this.objektFullName = objektFullName;
    this._savedCallBack = savedCallBack;

    this.loadData();

    this.markerMultiObjektPopup.instance.show();
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "benutzerLoeschenPopupToolbar",
      caption: "benutzereinstellungen.popup-titel",
      options: this.markerMultiObjektPopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });

    this.newMarkerToolbarOptions = this.toolbar.createToolbarOptions(
      this.scopeContainer,
      "marker-multi-objekt.neuen-marker-erstellen",
      this.newMarkerCommands,
      true
    );
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  private async onAddMarkerClicked() {
    const validationResult = this.validationGroup.instance.validate();
    if (!validationResult.isValid) {
      return;
    }

    this.multiMarkerGridDataSource.push(this.newMarker);

    await this.saveData();
    this.multiMarkerGrid.instance.refresh();
  }

  private onSaveMarkersClicked() {
    this.saveData().then(r => {
      if (this._savedCallBack) {
        this._savedCallBack();
      }
      this.markerMultiObjektPopup.instance.hide();
    });
  }

  private async loadData() {
    this.multiMarkerGridDataSource = await this.marker.getMarkersByObjektFullName(this.objektFullName);
  }
  private saveData(): Promise<any> {
    return this.marker.saveObjektMultiMarker({
      FullName: this.objektFullName,
      IdObjektList: this.keyList,
      ObjektMarkerHelperList: this.multiMarkerGridDataSource
    });
  }
}
