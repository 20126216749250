import { BrowserService } from './../../../../framework/base/services/browser-service';
import { computedFrom } from 'aurelia-framework';
import { StartupService } from './../../../services/startup-service';
import { StatusAendern } from './../../../elements/status-aendern/status-aendern';
import { autoinject, transient } from 'aurelia-framework';
import { FormBase } from './../../../../framework/forms/classes/form-base';
import { GlobalizationService, LocalizationService, RestService, LocationService } from './../../../../framework/base/services/export';
import { PersonService, StelleninseratService } from './../../../services/export';
import { IZitTagOptions } from '../../../../zit-ui/elements/zit-tag/export';
import { WhatsappAktivieren } from '../../../elements/whatsapp-aktivieren/whatsapp-aktivieren';
import { IdxPopupComponent, IStatusChangeResultData } from './../../../interfaces/export';
import { ICommandData } from './../../../../framework/forms/interfaces/command-data';
import * as moment from "moment";
import { Share } from '../../../elements/share/share';
import { IZitToolbarOptions } from '../../../../zit-ui/elements/zit-toolbar/zit-toolbar-options';
import { BenutzerTyp } from '../../../enumerations/export';
import { ModelUtilsService } from '../../../../framework/forms/export';
import { FormPopupService } from '../../../services/form-popup-service';
import { NachrichtService } from "../../../services/nachricht-service";

@autoinject
@transient()
export class BewerbungEditUtils {
  constructor(
    public startup: StartupService,
    private _globalizationService: GlobalizationService,
    private _localizationService: LocalizationService,
    private _locationService: LocationService,
    private _nachrichtService: NachrichtService,
    private _personService: PersonService,
    private _stelleninseratService: StelleninseratService,
    private _restService: RestService,
    private _browserService: BrowserService,
    private _modelUtilsService: ModelUtilsService,
    private _formPopupService: FormPopupService
  ) { }

  form: FormBase;
  whatsappAktivieren: WhatsappAktivieren;
  isArbeitsbewilligungVorhanden: boolean = true;
  selectedKeys: number[];
  isGeschaeftspartnerSchule: boolean;

  requiredValidationRule = { type: "required" };

  anzahlWunschWochenValidationRule: DevExpress.ui.CustomRule = {
    type: "custom",
    validationCallback: (e) => {
      const mindestWochen = this.form.models.data.$m_Bewerbung.Kopf.Stelleninserat.FerialeinsatzEinstellungen.MindesteinsatzWochen;
      if (!mindestWochen) {
        return true;
      }
      e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-mindesteinsatz-wochen",
        [mindestWochen]);
      return e.value >= mindestWochen;
    }
  };

  einsatzzeitraum1VonCustomValidation: DevExpress.ui.CustomRule = {
    type: "custom",
    validationCallback: (e) => {
      const einstellungen = this.getFerialEinstellungen();
      if (!einstellungen
        || !e.value
        || (!einstellungen.BeschaeftigungVon && !einstellungen.BeschaeftigungBis)) {
        return true;
      }

      const von = moment(e.value);
      const bis = this.form.models.data.$m_Bewerbung.Kopf.Einsatzzeitraum1Bis;
      const isAlterValid = this.validateMindestAlter(von, einstellungen.Mindestalter);

      if (!isAlterValid) {
        e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-mindestalter", [einstellungen.Mindestalter])
        return false;
      }

      if (einstellungen.BeschaeftigungVon && !von.isSameOrAfter(einstellungen.BeschaeftigungVon, "day")) {
        e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-beschaeftigung-vor",
          [this._globalizationService.format(einstellungen.BeschaeftigungVon, "d")]);

        return false;
      } else if (einstellungen.BeschaeftigungBis && von.isSameOrAfter(einstellungen.BeschaeftigungBis, "day")) {
        e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-beschaeftigung-nach",
          [this._globalizationService.format(einstellungen.BeschaeftigungBis, "d")]);
        return false
      } else if (von && bis) {
        const isValid = this.validateMindesteinsatz(von.toDate(), bis);
        if (!isValid) {
          const mindesteinsatz = einstellungen.MindesteinsatzWochen;
          e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-mindesteinsatz",
            [mindesteinsatz, mindesteinsatz == 1 ? "" : "n"]);

          return false;
        }
      }

      return true;
    }
  };
  einsatzzeitraum1BisCustomValidation: DevExpress.ui.CustomRule = {
    type: "custom",
    validationCallback: (e) => {
      const einstellungen = this.getFerialEinstellungen();
      if (!einstellungen
        || !e.value
        || (!einstellungen.BeschaeftigungVon && !einstellungen.BeschaeftigungBis)) {
        return true;
      }

      const bis = moment(e.value);
      const von = this.form.models.data.$m_Bewerbung.Kopf.Einsatzzeitraum1Von;

      if (einstellungen.BeschaeftigungBis && !bis.isSameOrBefore(einstellungen.BeschaeftigungBis, "day")) {
        e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-beschaeftigung-nach",
          [this._globalizationService.format(einstellungen.BeschaeftigungBis, "d")]);

        return false;
      } else if (von && bis.isSameOrBefore(von, "day")) {
        e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-kleiner-als-von");

        return false;
      } else if (von && bis) {
        const isValid = this.validateMindesteinsatz(von, bis.toDate());
        if (!isValid) {
          const mindesteinsatz = einstellungen.MindesteinsatzWochen;
          e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-mindesteinsatz",
            [mindesteinsatz, mindesteinsatz == 1 ? "" : "n"]);

          return false;
        }
      }

      return true;
    }
  };
  einsatzzeitraum2VonCustomValidation: DevExpress.ui.CustomRule = {
    type: "custom",
    validationCallback: (e) => {
      const einstellungen = this.getFerialEinstellungen();
      if (!einstellungen
        || !e.value
        || (!einstellungen.BeschaeftigungVon && !einstellungen.BeschaeftigungBis)) {
        return true;
      }

      const von = moment(e.value);
      const bis = this.form.models.data.$m_Bewerbung.Kopf.Einsatzzeitraum2Bis;
      const isAlterValid = this.validateMindestAlter(von, einstellungen.Mindestalter);

      if (!isAlterValid) {
        e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-mindestalter", [einstellungen.Mindestalter])
        return false;
      }

      if (einstellungen.BeschaeftigungVon && !von.isSameOrAfter(einstellungen.BeschaeftigungVon, "day")) {
        e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-beschaeftigung-vor",
          [this._globalizationService.format(einstellungen.BeschaeftigungVon, "d")]);

        return false;
      } else if (einstellungen.BeschaeftigungBis && von.isSameOrAfter(einstellungen.BeschaeftigungBis, "day")) {
        e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-beschaeftigung-nach",
          [this._globalizationService.format(einstellungen.BeschaeftigungBis, "d")]);
        return false
      } else if (von && bis) {
        const isValid = this.validateMindesteinsatz(von.toDate(), bis);
        if (!isValid) {
          const mindesteinsatz = einstellungen.MindesteinsatzWochen;
          e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-mindesteinsatz",
            [mindesteinsatz, mindesteinsatz == 1 ? "" : "n"]);

          return false;
        }
      }


      return true;
    }
  };
  einsatzzeitraum2BisCustomValidation: DevExpress.ui.CustomRule = {
    type: "custom",
    validationCallback: (e) => {
      const einstellungen = this.getFerialEinstellungen();
      if (!einstellungen
        || !e.value
        || (!einstellungen.BeschaeftigungVon && !einstellungen.BeschaeftigungBis)) {
        return true;
      }

      const bis = moment(e.value);
      const von = this.form.models.data.$m_Bewerbung.Kopf.Einsatzzeitraum2Von;

      if (einstellungen.BeschaeftigungBis && !bis.isSameOrBefore(einstellungen.BeschaeftigungBis, "day")) {
        e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-beschaeftigung-nach",
          [this._globalizationService.format(einstellungen.BeschaeftigungBis, "d")]);

        return false;
      } else if (von && bis.isSameOrBefore(von, "day")) {
        e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-kleiner-als-von");

        return false;
      } else if (von && bis) {
        const isValid = this.validateMindesteinsatz(von, bis.toDate());
        if (!isValid) {
          const mindesteinsatz = einstellungen.MindesteinsatzWochen;
          e.rule.message = this._localizationService.translateOnce("bewerbung-edit-utils.validator-mindesteinsatz",
            [mindesteinsatz, mindesteinsatz == 1 ? "" : "n"]);

          return false;
        }
      }

      return true;
    }
  };

  beschaeftigungTagOptions: IZitTagOptions = {
    cssClass: "z--tag-info-message",
    icon: {
      faIcon: "fas fa-info-circle"
    },
    textExpression: `"bewerbung-edit-utils.einsatzzeitraum-info" 
    | tr:[globalization.format(item.BeschaeftigungVon, "d"), globalization.format(item.BeschaeftigungBis, "d")]`
  };
  einsatzbereichTagOptions: IZitTagOptions = {
    textExpression: "item.Einsatzbereich.Einsatzbereich",
    isClickEnabled: true,
    icon: {
      text: "EB"
    }
  };
  mindestDauerMessageTagOptions: IZitTagOptions = {
    cssClass: "z--tag-info-message",
    icon: {
      faIcon: "fas fa-info-circle"
    },
    textExpression: `"bewerbung-edit-utils.mindestdauer-info" 
    | tr:[item.MindesteinsatzWochen, 
      item.MindesteinsatzWochen == 1 ? '':'n',
      item.MaxEinsatzWochen 
        ? localization.translateOnce("bewerbung-edit-utils.maximaldauer-info", [item.MaxEinsatzWochen, item.MaxEinsatzWochen == 1 ? '':'n'])
        : '']`
  };
  beschaeftigungVonBisTagOptions: IZitTagOptions = {
    cssClass: "z--tag-info-message",
    icon: {
      faIcon: "fas fa-info-circle"
    },
    textExpression: `"bewerbung-edit-utils.beschaeftigung-info" 
    | tr:[globalization.format(item.BeschaeftigungVon, "d"), globalization.format(item.BeschaeftigungBis, "d")]`
  };
  mindestalterTagOptions: IZitTagOptions = {
    cssClass: "z--tag-info-message",
    icon: {
      faIcon: "fas fa-info-circle"
    },
    textExpression: `localization.translateOnce("bewerbung-edit-utils.mindestalter-info", [item.Mindestalter])`
  };
  mindesteinsatzTagOptions: IZitTagOptions = {
    cssClass: "z--tag-info-message",
    icon: {
      faIcon: "fas fa-info-circle"
    },
    textExpression: `localization.translateOnce("bewerbung-edit-utils.mindesteinsatzWochen-info", [item.MindesteinsatzWochen])`
  };
  maxeinsatzTagOptions: IZitTagOptions = {
    cssClass: "z--tag-info-message",
    icon: {
      faIcon: "fas fa-info-circle"
    },
    textExpression: `localization.translateOnce("bewerbung-edit-utils.maxeinsatzwochen-info", [item.MaxEinsatzWochen])`
  };
  adresseTagOptions: IZitTagOptions = {
    icon: {
      text: "A"
    },
    textExpression: `item | zitAddress:"short:street"`
  };
  ausbildungsstartPerTagOptions: IZitTagOptions = {
    icon: {
      text: "AD"
    },
    textExpression: `globalization.format(item.AusbildungsstartPer, "d")`
  };
  unternehmenTagOptions: IZitTagOptions = {
    icon: {
      text: "U"
    },
    textExpression: `item.Subheadline ? item.Subheadline : item.Geschaeftspartner.Geschaeftspartnerdaten.Name1`
  };
  whatsappTagOptions: IZitTagOptions = {
    cssClass: "z--button z--button-round z--button-tag",
    icon: {
      faIcon: "fab fa-whatsapp"
    },
    text: "WhatsApp-Channel aktivieren",
    onClick: (e, data) => {
      const w: WhatsappAktivieren = this.form["r_whatsappAktivieren"];
      w.showPopup();
    }
  };
  bewerbungSchnupperterminTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    textExpression: "item.SchnupperterminDatum | zitMoment:'wochenTag'",
    icon: {
      text: "T"
    }
  };
  bewerbungPersonStatusTagOptions: IZitTagOptions = {
    textExpression: "item.ObjektStatus",
    icon: {
      faIconExpression: "item.ObjektStatusSymbol",
      backgroundColorExpression: "item.ObjektStatusSymbolFarbe",
    },
    backgroundColorExpression: "item.ObjektStatusSymbolFarbe",
    cssClass: "z--tag-objekt-status-custom"
  };
  bewerbungPersonFuehrerscheinTagOptions: IZitTagOptions = {
    textExpression: `item.Fuehrerscheinklasse.Bezeichnung 
            ? item.Fuehrerscheinklasse.Bezeichnung 
                : ' ' | zitRestrictLength:50`,
    icon: {
      textExpression: `item.Fuehrerscheinklasse.Tagtext 
                    ? item.Fuehrerscheinklasse.Tagtext : 'F'`,
      faIconExpression: "item.Fuehrerscheinklasse.TagSymbol ? item.Fuehrerscheinklasse.TagSymbol : null"
    }
  };
  bewerbungPersonGeschaeftspartnerEinsatzTagOptions: IZitTagOptions = {
    textExpression: `item.Jahr 
         ? item.Geschaeftspartner.Name1 + ': ' + item.TaetigkeitAbteilung + ' (' + item.Jahr + ')'
         : item.Geschaeftspartner.Name1 + ': ' + item.TaetigkeitAbteilung`,
    icon: {
      text: "BE"
    }
  };
  bewerbungPersonSkillTagOptions: IZitTagOptions = {
    textExpression: `item.Text 
            ? item.Skill.Bezeichnung + ': ' + item.Text 
                : item.Skill.Bezeichnung | zitRestrictLength:50`,
    icon: {
      textExpression: "!item.Skill.TagSymbol ? 'S/H':''",
      faIconExpression: "item.Skill.TagSymbol"
    }
  };
  bewerbungPersonKontaktTagOptions: IZitTagOptions = {
    icon: {
      faIconExpression: "item.Kontakttyp.TagSymbol ? item.Kontakttyp.TagSymbol : item.Kontakttyp.Bezeichnung",
      textExpression: `item.Kontakttyp 
      ? item.Kontakttyp.TagSymbol
        ? item.Kontakttyp.TagText 
        : item.Kontakttyp.Bezeichnung
          ? item.Kontakttyp.Bezeichnung[0] 
          : "K"
      : "K"`
    },
    textExpression: `item.Wert1 
    ? item.Wert2
      ? item.Wert1 + " " + item.Wert2
      : item.Wert1
    : ""`,
    infoList: [{
      isVisibleExpression: "item.Wichtig",
      icon: {
        faIcon: "fas fa-exclamation"
      }
    }]
  };
  bewerbungPersonBerufTagOptions: IZitTagOptions = {
    textExpression: `item.Beruf.Bezeichnung && item.Erfahrungsjahre && item.ZuletztTaetig
                ? item.Beruf.Bezeichnung + ' | EJ: ' + item.Erfahrungsjahre + ' | ZT: ' + item.ZuletztTaetig
                : item.Erfahrungsjahre
                ? item.Beruf.Bezeichnung + ' | EJ: ' + item.Erfahrungsjahre
                : item.ZuletztTaetig
                ? item.Beruf.Bezeichnung + ' | ZT: ' + item.ZuletztTaetig
                 : item.Beruf.Bezeichnung`,
    icon: {
      text: "B"
    },
    infoList: [{
      isVisibleExpression: "item.IdBerufQualifikation",
      icon: {
        textExpression: "item.BerufQualifikation.TagText ? item.BerufQualifikation.TagText : null",
        faIconExpression: "item.BerufQualifikation.TagSymbol ? item.BerufQualifikation.TagSymbol : null"
      }
    },
    {
      isVisibleExpression: "item.IdAusbildungAbschluss",
      icon: {
        faIconExpression: "item.AusbildungAbschluss.TagSymbol ? item.AusbildungAbschluss.TagSymbol : null"
      }
    }]
  };
  zugeteilteSchnupperterminTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    textExpression: "item.Bezeichnung",
    icon: {
      text: "E"
    },
    onClick: (e, data) => {
      this._locationService.goTo({
        url: `#Objekte/EventTeilnehmer/${data.IdEventTeilnehmer}`
      });
    }
  };
  abschliessenCommand: ICommandData = {
    id: "abschliessenCommand",
    icon: "fas fa-check",
    tooltip: "bewerbung-edit-utils.abschliessen",
    sortIndex: 1600,
    execute: () => {
      this.onBewerbungAbschliessen();
    }
  };
  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };
  bewerbungEinsatzbereichCommand: ICommandData = {
    id: "bewerbungEinsatzbereichCommand",
    tooltip: "bewerbung-edit-utils.uebernehmen",
    icon: "fas fa-check",
    sortIndex: 1600,
    execute: () => {
      this.onBewerbungEinsatzbereichPrüfen();
    }
  };
  statusAendernPopupCommand: ICommandData = {
    id: "statusAenderPopupCommand",
    icon: "fas fa-pencil-alt",
    title: "bewerbung-edit-utils.status-aendern-popup",
    isVisibleExpression: "functions.$f_BewerbungEditUtils.isStatusAendernVisible",
    sortIndex: 450,
    execute: () => {
      const statusAendern: StatusAendern = this.form["r_statusAendern"];
      this.selectedKeys = this.getSelectedKeys();
      statusAendern.showPopup(this.onStatusGeaendert.bind(this));
    }
  };
  personToolbarOptions: IZitToolbarOptions = {
    title: "bewerbung-edit-utils.person-toolbar-title",
    smallToolbar: true,
    items: [{
      id: "bewerbungPersonCommand",
      icon: "fas fa-pencil-alt",
      execute: (e) => {
        this._locationService.goTo({
          url: `#Objekte/Person/${this.form.models.data.$m_Bewerbung.Kopf.Person.Id}?mode=1`
        });
      }
    }]
  };  

  trainerTagOptions: IZitTagOptions = {
    icon: {
      textExpression: "item.Ansprechperson.FunktionTagText"
    },
    textExpression: "item.Ansprechperson.Titel",
    infoList: [{
      backgroundColorExpression: "item.IsGueltig ? 'green' : 'red'",
      color: "transparent",
      icon: {
        faIcon: "fas fa-circle"
      }
    }, {
      icon: {
        faIcon: "fas fa-pencil-alt"
      },
      onClick: (ev, data) => {
        ev.preventDefault();
        ev.stopPropagation();

        this.trainerEdit(data);
      }
    }],
    onClick: (_, data) => {
      this._locationService.goTo({
        url: `Objekte/Ansprechperson/${data.IdAnsprechperson}`,
        currentViewModel: this.form
      })
    }
  }

  @computedFrom("canChangeStatus")
  get isStatusAendernVisible() {
    return this.startup.startupInfo.Benutzer.CanStatusAendern
      && this.canChangeStatus
      && !this._browserService.isMobile;
  }

  @computedFrom("form.models.data.$m_Bewerbung")
  get canChangeStatus(): boolean {
    if (!this.form.models.data.$m_Bewerbung) {
      return true;
    }

    return !!this.form.models.data.$m_Bewerbung._CanChangeObjektStatus;
  }

  @computedFrom("form.models.data.$m_Bewerbung.CanSave")
  get canUpdateTrainer() {
    if (this.startup.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.NatuerlichePerson) {
      return false;
    }

    if (!this.form.models.data.$m_Bewerbung) {
      return false;
    }
    if (!this.form.models.data.$m_Bewerbung.CanSave) {
      return false;
    }

    return true;
  }

  bind(form: FormBase) {
    this.form = form;
    form.models.onLoaded.register((args) => {
      if (args.model && args.model.id == "$m_Bewerbung" && args.data) {
        this.updateTrainerTagDeleteClick();
        this.getArbeitsbewilligung();
        args.data["_showSchnupperLehre"] = this.getSchnupperLehre(args.data);

        this.isGeschaeftspartnerSchule = args.data.Id 
          && args.data.Kopf 
          && args.data.Kopf.Stelleninserat
          && args.data.Kopf.Stelleninserat.Kopf
          && args.data.Kopf.Stelleninserat.Kopf.Geschaeftspartner
          && args.data.Kopf.Stelleninserat.Kopf.Geschaeftspartner.Geschaeftspartnerdaten
          && args.data.Kopf.Stelleninserat.Kopf.Geschaeftspartner.Geschaeftspartnerdaten.IsGeschaeftspartnerSchule;
      }

      return Promise.resolve();
    });

    form.models.onSaved.register((args) => {
      if (args.model.id !== "$m_Bewerbung") {
        return;
      }
      const data = args.data;

      if (data.Kopf
        && data.Kopf.Stelleninserat
        && data.Kopf.Stelleninserat.FerialbewerbungFragebogen
        && data.Kopf.Stelleninserat.FerialbewerbungFragebogen.Einsatzbereich
        && data.Kopf.Einsatzbereiche
        && data.Kopf.Einsatzbereiche.length === 0) {
        this.form["r_bewerbungEinsatzbereichPopup"].instance.show()
      } else if (data.Kopf
        && data.Kopf.Stelleninserat
        && (!data.Kopf.Stelleninserat.FerialbewerbungFragebogen
          || !data.Kopf.Stelleninserat.FerialbewerbungFragebogen.Einsatzbereich
          || !data.Kopf.Einsatzbereiche || data.Kopf.Einsatzbereiche.length > 0)) {
        const popup = this.form["r_bewerbungAbgeschlossenPopup"];
        if (!popup || !popup.instance) {
          return;
        }

        popup.instance.show()
      }
      return Promise.resolve();
    });

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Bewerbung") {
        if (args.binding.bindTo === "Kopf.IdStelleninserat") {
          this.form.models.data.$m_Bewerbung.Kopf.Stelleninserat = await this.loadStelleninseratData(args.value);
        }
        if (args.binding.bindTo === "Kopf.IdPerson") {
          this.form.models.data.$m_Bewerbung.Kopf.Person = await this.loadPersonData(args.value);
        }
      }
      return Promise.resolve();
    });

    if (this.startup.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.NatuerlichePerson) {
      this.form.callOnBind(() => {
        if (this.form["r_geschaeftspartnerPersonalNrOptions"]) {
          this.form["r_geschaeftspartnerPersonalNrOptions"].readOnly = true;
        }
      });
    }
  }

  getSchnupperLehre(data: any): boolean {
    if (data.Kopf.Stelleninserat && data.Kopf.Stelleninserat.Kopf.CanSchnuppertermin) {
      return true;
    }
    if (data.Kopf.IsBewerbungSchnuppertermin || data.Kopf.IsBewerbungLehrstelle) {
      return true;
    }

    return false;
  }

  getArbeitsbewilligung() {
    return this._restService.get({
      url: `${this._restService.getApiUrl("ZIT/Stammdaten/ArbeitsbewilligungVorhanden?idBewerbung")}=${this.form.models.data.$m_Bewerbung.Id}`,
    }).then((r) => {
      this.isArbeitsbewilligungVorhanden = r;
    });
  }
  isAnsichtDesktop() {
    return !this._browserService.isMobile;
  }

  onTrainerAddClick() {
    if (!this.form.models.data.$m_Bewerbung.Kopf.Stelleninserat) {
      DevExpress.ui.notify("Bitte zuerst Stelleninserat auswählen und speichern", "error", 3000);
      return;
    }

    this.trainerEdit();
  }
  async onBenachrichtigungsartClick() {
    const idPerson = this.form.models.data.$m_Bewerbung?.Kopf?.IdPerson;
    if (!idPerson) return;
    
    const info = await this._nachrichtService.getBenutzerFromObjekt(
      "TIP.ZIT.Business.Entities.Objekte.Personen.Person",
      idPerson
    );

    if (!info) return;

    this._nachrichtService.goToNachrichtList(info, this.form);
  }

  private getFerialEinstellungen() {
    const data = this.form.models.data.$m_Bewerbung;
    const einstellungen = data.Kopf.Stelleninserat && data.Kopf.Stelleninserat.FerialeinsatzEinstellungen
      ? data.Kopf.Stelleninserat.FerialeinsatzEinstellungen
      : undefined;

    return einstellungen;
  }
  private goToDashboard() {
    this._locationService.goTo({
      url: "ZIT/Home/Index",
      clearStack: true,
      replace: true
    });
  }
  private trainerEdit(d?: any) {    
    const initialData: any = {
      gueltigVon: moment().startOf("day").toDate(),
      gueltigBis: moment(new Date(2099, 11, 31)).startOf("day").toDate()
    };

    if (d) {
      initialData.gueltigVon = d.GueltigVon;
      initialData.gueltigBis = d.GueltigBis;
      initialData.item = d.Ansprechperson;
    }

    this._formPopupService.formPopup.show({
      titel: this._localizationService.translateOnce("zit.trainer"),
      data: initialData,
      formDef: "ansprechpersonMitGueltigkeit",
      webApiFilters: [{
        webApiCustomKey: "IdGeschaeftspartner",
        webApiCustomValue: this.form.models.data.$m_Bewerbung.Kopf.Stelleninserat.Kopf.IdGeschaeftspartner
      }],
      onFormSubmit: (data) => {
        if (!this.form.models.data.$m_Bewerbung.Kopf.BewerbungTrainer) {
          this.form.models.data.$m_Bewerbung.Kopf.BewerbungTrainer = [];
        }

        const u = {
          IdAnsprechperson: data.item.Id,
          Ansprechperson: {
            Id: data.item.Id,
            Titel: data.item.Titel,
            FunktionTagText: data.item.FunktionTagText
          },
          GueltigVon: data.gueltigVon,
          GueltigBis: data.gueltigBis,
          IsGueltig: data.gueltigVon.getTime() <= new Date().getTime() 
            && data.gueltigBis.getTime() >= new Date().getTime()
        };

        if (d) {
          Object.assign(d, u);
        } else {
          const exists = this.form.models.data.$m_Bewerbung.Kopf.BewerbungTrainer.some(t => t.IdAnsprechperson == data.item.Id);
          if (exists) {
            return;
          }

          this.form.models.data.$m_Bewerbung.Kopf.BewerbungTrainer.push(u);
        }

        this._modelUtilsService.setDirty(this.form.models.data.$m_Bewerbung);
      }
    });
  }

  private onBewerbungEinsatzbereichPrüfen() {
    const bewerbungEinsatzbereichPopup: IdxPopupComponent = this.form["r_bewerbungEinsatzbereichPopup"];
    bewerbungEinsatzbereichPopup.instance.hide();
  }
  private onBewerbungAbschliessen() {
    const bewerbungAbgeschlossenPopup: IdxPopupComponent = this.form["r_bewerbungAbgeschlossenPopup"];
    bewerbungAbgeschlossenPopup.instance.hide();
    this.goToDashboard();
  }

  private validateMindesteinsatz(vonDate: Date, bisDate: Date): boolean {
    const einstellungen = this.getFerialEinstellungen();
    if (!einstellungen) {
      return true;
    }
    const von = moment(vonDate).startOf("day");
    const bis = moment(bisDate).startOf("day");
    const totalWunschTage = bis.diff(von, "days") + 1;

    let minTage = einstellungen.MindesteinsatzWochen * 5;

    minTage += 2 * einstellungen.MindesteinsatzWochen;

    if (von.weekday() == 1) {
      minTage -= 2
    }

    return totalWunschTage >= minTage;
  }
  private validateMindestAlter(mindestAb: moment.Moment, mindestAlter: number): boolean {
    if (!mindestAb) {
      return true;
    }

    const noPersonSelected = this.form.models.data.$m_Bewerbung.Kopf.IdPerson == void 0;
    if (noPersonSelected) {
      return true;
    }
    const geborenAm = this.form.models.data.$m_Bewerbung.Kopf.Person.Personendaten.Geburtsdatum;
    if (!geborenAm || !mindestAb || !mindestAlter) {
      return true;
    }

    const gebDatum = moment(geborenAm).startOf("day");

    return mindestAb.diff(gebDatum, "years") >= mindestAlter;
  }

  private loadPersonData(idPerson: number): Promise<any> {
    if (!idPerson) {
      return;
    }

    const getOptions = { expand: { Personendaten: null } };

    return this._personService.getPerson(idPerson, getOptions);
  }
  private loadStelleninseratData(idStelleninserat: number): Promise<any> {
    if (!idStelleninserat) {
      return;
    }

    const getOptions = { expand: { FerialbewerbungFragebogen: null, Kopf: null, FerialeinsatzEinstellungen: null } };

    return this._stelleninseratService.getStelleninserat(idStelleninserat, getOptions);
  }
  private onStatusGeaendert(r: IStatusChangeResultData) {
    if (!r.IsValid) {
      DevExpress.ui.dialog.alert(r.Message, this._localizationService.translateOnce("bewerbung-edit-utils.fehler"));
    } else {
      this.form.models.reloadAll();
    }
  }
  private getSelectedKeys(): number[] {
    let selectedRowKeys: any[];

    if (this.form.models.data.$m_Bewerbung) {
      return [this.form.models.data.$m_Bewerbung.Id];
    } else {
      return [];
    }
  }
  private updateTrainerTagDeleteClick() {
    if (this.canUpdateTrainer) {
      this.trainerTagOptions.onDeleteClick = (_, data) => {
        const index = this.form.models.data.$m_Bewerbung.Kopf.BewerbungTrainer.indexOf(data);
        if (index < 0) {
          return;
        }

        this.form.models.data.$m_Bewerbung.Kopf.BewerbungTrainer.splice(index, 1);
        this._modelUtilsService.setDirty(this.form.models.data.$m_Bewerbung);
      };
    } else {
      delete this.trainerTagOptions.onDeleteClick;
    }
  }
}
