import { RestService } from './../../../../framework/base/services/rest-service';
import { DxWidget } from './../../../../framework/dx/elements/dx-widget';
import { ValidationExService } from './../../../services/validation-ex-service';
import { Models } from './../../../../framework/forms/classes/models';
import { autoinject, computedFrom, transient, TaskQueue } from "aurelia-framework";
import { FormBase } from './../../../../framework/forms/classes/form-base';

@autoinject
@transient()
export class StelleninseratGruppeEditUtils {
  constructor(private rest: RestService, private taskqueue: TaskQueue, private restService: RestService) {
  }

  form: FormBase;
  vorhandeneAuswaehlen: string = "Vorhandenen Typ auswählen";
  neuErstellen: string = "Neuen Typ erstellen";
  gruppeNeuAuswahl: string;
  gruppeNeuAuswahlDatasource: string[] = [this.vorhandeneAuswaehlen, this.neuErstellen];
  aufzaehlungszeichen: boolean = false;

  bind(form: FormBase, funcNamespace: string): void {
    this.form = form;

    this.form.onValidating.register(r => {
      if (!r.validationResult.isValid) {
        return;
      }

      return this.onValidating();
    });

    this.form.parent.editPopups.onEditPopupShown.register(r => {
      if (r.editPopup.id === "stelleninseratGruppeEditPopup") {
        this.aufzaehlungszeichen = false;

        const gruppeNeuAuswahlRadioGroup: DxWidget = this.form["r_guppeNeuAuswahl"];
        gruppeNeuAuswahlRadioGroup.setOption({ "value": this.gruppeNeuAuswahlDatasource[0] });

        if (this.gruppeNeuAuswahl === this.gruppeNeuAuswahlDatasource[1]) {
          const gruppeNeuSortNrNumberBoxGroup: DxWidget = this.form["r_gruppeNeuSortNrNumberBox"];
          gruppeNeuSortNrNumberBoxGroup.setOption({ "value": 0 });

          const gruppeNeuAufzaehlung: DxWidget = this.form["r_gruppeNeuAufzaehlung"];
          gruppeNeuAufzaehlung.setOption({ "value": this.aufzaehlungszeichen });
        }
      }

      return Promise.resolve();
    })

    form.models.onLoaded.register((args) => {
      if (args.model.id == "$m_StelleninseratGruppe" && args.data) {
        if (!args.data.Id) {
          this.taskqueue.queueMicroTask(() => {
            const idStelleninserat = this.form.variables.data.$v_idStelleninserat;

            this.restService.get({
              url: `${this.restService.getApiUrl("ZIT/Objekt/StelleninseratGruppeNextSortNr")}?idStelleninserat=${idStelleninserat}`
            }).then(r => {
              args.data.SortNr = r;
            });
          });
        }
      }

      return Promise.resolve();
    });
  }

  requiredValidatorOptions = {
    validationRules: [{ type: "required", message: "" }]
  };

  stelleninseratGruppeNeuAuswahlRadioGroupOptions: DevExpress.ui.dxRadioGroupOptions = {
    dataSource: this.gruppeNeuAuswahlDatasource,
    layout: "horizontal",
    onValueChanged: (r) => {
      this.gruppeNeuAuswahl = r.value;
    }
  };

  neuErfassungTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {};
  neuErfassungNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {};

  aufzaehlungszeichenCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "in der Anzeige als Aufzählung darstellen",
  };

  @computedFrom("gruppeNeuAuswahl") //Todo: Property prüfen
  get isGruppeAuswahlVisible(): boolean {
    let value = this.GetStelleninseratGruppeNeuAuswahlValue();

    if (value == this.vorhandeneAuswaehlen) {
      return true;
    } else {
      return false;
    }
  }

  private GetStelleninseratGruppeNeuAuswahlValue(): string {
    const gruppeNeuAuswahl: DevExpress.ui.dxRadioGroup = this.form["r_guppeNeuAuswahl"].instance;

    if (gruppeNeuAuswahl) {
      return gruppeNeuAuswahl.option("value")
    }
    else {
      null;
    }
  }

  private onValidating(): Promise<any> {
    let value = this.GetStelleninseratGruppeNeuAuswahlValue();
    if (value == this.neuErstellen) {
      const gruppeNeuTextBoxGroup: DevExpress.ui.dxTextBox = this.form["r_gruppeNeuTextBox"].instance;
      const gruppeNeuSortNrNumberBoxGroup: DevExpress.ui.dxNumberBox = this.form["r_gruppeNeuSortNrNumberBox"].instance;

      const bezeichnung: string = gruppeNeuTextBoxGroup.option("text");
      const sortNr: number = gruppeNeuSortNrNumberBoxGroup.option("value");

      let gruppentyp = {
        Bezeichnung: bezeichnung,
        SortNr: sortNr,
        IdGeschaeftspartner: this.form.variables.data.$v_idGeschaeftspartner,
        Aufzaehlung: this.aufzaehlungszeichen
      };

      return this.rest.get({
        url: this.rest.getWebApiUrl("ZIT/Stammdaten/StelleninseratGruppentyp"),
        getOptions: {
          where: [["Bezeichnung", gruppentyp.Bezeichnung], "and", ["IdGeschaeftspartner", gruppentyp.IdGeschaeftspartner]]
        }
      })
        .then((existingGruppentyp) => {
          if (existingGruppentyp && existingGruppentyp.length > 0) {
            this.form.models.data.$m_StelleninseratGruppe.IdStelleninseratGruppentyp = existingGruppentyp[0].Id;
          } else {
            return this.rest.put({
              url: this.rest.getWebApiUrl("ZIT/Stammdaten/StelleninseratGruppentyp"),
              data: gruppentyp,
              increaseLoadingCount: true
            }).then(r => {
              this.form.models.data.$m_StelleninseratGruppe.IdStelleninseratGruppentyp = r.Id;
            });
          }
        });
    }
  };
}
