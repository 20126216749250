import { autoinject, bindable, OverrideContext, Scope } from "aurelia-framework";
import { IdxPopupComponent, IdxValidationGroupComponent, IdxSelectBoxComponent } from '../../interfaces/dx-components';
import { ICommandData } from '../../../framework/forms/interfaces/command-data';
import { RestService, ScopeContainer, LocalizationService } from '../../../framework/base/export';
import { SimpleWidgetCreatorService } from '../../../framework/forms/export';

@autoinject
export class EinsatzvorbereitungHinzufuegen {
  constructor(
    private restService: RestService,
    private simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private localizationService: LocalizationService
  ) {}

  idEinsatzplanungList: number[];

  scope: Scope;
  scopeContainer: ScopeContainer;

  popupCommands: ICommandData[] = [{
    id: "einsatzplanungHinzufuegenSave",
    icon: "fas fa-save",
    tooltip: "base.save",
    sortIndex: 1001,
    execute: async () => {
      const result = this.validationGroup.instance.validate();
      if (!result.isValid) {
        return;
      }

      const idEinsatzvorbereitung: number = this.einsatzvorbereitungSelectBox.instance.option("value");

      await this.restService.post({
        url: this.restService.getApiUrl("ZIT/Dispo/UpdateEinsatzplanungEinsatzvorbereitung"),
        increaseLoadingCount: true,
        data: {
          IdEinsatzvorbereitung: idEinsatzvorbereitung,
          IdEinsatzplanungList: this.idEinsatzplanungList
        }
      });
      
      DevExpress.ui.notify(this.localizationService.translateOnce("base.save_success"), "SUCCESS", 3000);
      this.einsatzvorbereitungHinzufuegenPopup.instance.hide();
    }
  }];
  einsatzvorbereitungHinzufuegenPopup: IdxPopupComponent;
  einsatzvorbereitungHinzufuegenPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxWidth: "550px"
  };

  einsatzvorbereitungSelectBox: IdxSelectBoxComponent;
  einsatzvorbereitungSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Bezeichnung",
  };

  requiredValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };
  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {};

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "einsatzvorbereitungHinzufuegenPopupToolbar",
      caption: "einsatzvorbereitung-hinzufuegen.popup-titel",
      options: this.einsatzvorbereitungHinzufuegenPopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  async showPopup(idEinsatzplanungList: number[]): Promise<any> {
    this.idEinsatzplanungList = idEinsatzplanungList;

    await this.prepareSelectBox();
    await this.einsatzvorbereitungHinzufuegenPopup.instance.show();
  }

  private async prepareSelectBox() {
    const einsatzvorbereitungList = await this.restService.post({
      url: this.restService.getApiUrl("ZIT/Dispo/Einsatzvorbereitung"),
      data: this.idEinsatzplanungList,
      increaseLoadingCount: true
    });

    const selectBoxOptions = {
      dataSource: einsatzvorbereitungList,
      value: null,
      isValid: true
    };

    if (this.einsatzvorbereitungSelectBox && this.einsatzvorbereitungSelectBox.instance) {
      this.einsatzvorbereitungSelectBox.setOption(selectBoxOptions);
    } else {
      Object.assign(this.einsatzvorbereitungSelectBoxOptions, selectBoxOptions);
    }
  }
}