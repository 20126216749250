import * as fwx from "../../../../../framework/forms/form-export";
import {
    LoginprofilFuncs
} from "./loginprofil-form-funcs";

@fwx.autoinject
export class LoginprofilForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f: LoginprofilFuncs) {
        super(element, formBaseImport);
        this.id = "loginprofil-form";
        this.title = "loginprofil-form.loginprofil-form_caption";
        this.addModel({
            "id": "$m_Loginprofil",
            "filters": []
        });
        this.addFunction("$f", $f, "functions.$f");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "loginprofil",
            "filters": [{
                "webApiCustomKey": "IgnoreMandant",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "CheckDomain",
                "webApiCustomValue": "true"
            }],
            "binding": {
                "dataContext": "$m_Loginprofil",
                "bindTo": "Id",
                "bindToFQ": "models.data.$m_Loginprofil.Id"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$f",
                    "bindTo": "requiredValidationRule",
                    "bindToFQ": "functions.$f.requiredValidationRule"
                }
            }],
            "id": "r_loginprofil",
            "options": {
                "optionsName": "r_loginprofilOptions",
                "optionsNameFQ": "r_loginprofilOptions"
            }
        });
        this.widgetCreator.addCommand(this, {
            "id": "wd2",
            "options": {
                "optionsName": "wd2Options",
                "optionsNameFQ": "wd2Options"
            },
            "binding": {
                "bindTo": "$f.loginprofilCommand",
                "bindToFQ": "functions.$f.loginprofilCommand"
            }
        });
        this.widgetCreator.addCommand(this, {
            "id": "wd3",
            "options": {
                "optionsName": "wd3Options",
                "optionsNameFQ": "wd3Options"
            },
            "binding": {
                "bindTo": "$f.zuerueckCommand",
                "bindToFQ": "functions.$f.zuerueckCommand"
            }
        });
        super.onConstructionFinished();
    }
}