import { autoinject, OverrideContext, Scope } from "aurelia-framework";
import { AuthorizationService, LocalizationService, ScopeContainer } from "../../../framework/base/export";
import { DataSourceService } from "../../../framework/base/services/export";
import { ICommandData, SimpleWidgetCreatorService } from "../../../framework/forms/export";
import { IdxPopupComponent, IdxValidationGroupComponent, IPasswortAendernData } from "../../interfaces/export";
import { BenutzereinstellungenService } from "../../services/benutzereinstellungen-service";

@autoinject
export class PasswortAendern {
  private NOTIFY_TIMEOUT: number = 3000;
  
  constructor(
    private _benutzereinstellungenService: BenutzereinstellungenService,
    private _localizationService: LocalizationService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  passwortAendernData = { newPass1: "", newPass2: "" };

  popupCommands: ICommandData[] = [
    {
      id: "passwortAendern",
      idCategory: "$start",
      icon: "fas fa-save",
      tooltip: "base.save",
      sortIndex: 1100,
      execute: () => {
        this.onPasswortAendernClicked();
      }
    }
  ];

  passwortAendernPopup: IdxPopupComponent;
  passwortAendernPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxHeight: "80%",
    maxWidth: "420px"
  };
  validationGroup: IdxValidationGroupComponent;
  validationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {};
  neuesPasswort1Options: DevExpress.ui.dxTextBoxOptions = {
    placeholder: this._localizationService.translateOnce("passwort-aendern.neues-passwort"),
    mode: "password",
    name: "passwort-aendern.neues-passwort",
    bindingOptions: {
      value: "passwortAendernData.newPass1"
    }
  };
  neuesPasswort2Options: DevExpress.ui.dxTextBoxOptions = {
    placeholder: this._localizationService.translateOnce("passwort-aendern.neues-passwort-wiederholen"),
    mode: "password",
    onValueChanged: () => {
      this.validationGroup.instance.validate();
    },
    bindingOptions: {
      value: "passwortAendernData.newPass2"
    }
  };
  newpasswortValidatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: <any>this.getValidator(this.neuesPasswort1Options).validationRules
      .concat({
        type: "custom",
        message: this._localizationService.translateOnce("passwort-aendern.passswort_compare_validation"),
        reevaluate: true,
        validationCallback: (e) => {
          const compareResult = this.passwortAendernData.newPass1.localeCompare(this.passwortAendernData.newPass2);
          const isValid = compareResult === 0;

          return isValid;
        }
      })
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "dmsViewerPopupToolbar",
      caption: "passwort-aendern.popup-titel",
      options: this.passwortAendernPopupOptions,
      commands: this.popupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  showPopup() {
    this.passwortAendernPopup.instance.show();
    this.validationGroup.instance.reset();
  }

  private async onPasswortAendernClicked() {
    const validationResult = this.validationGroup.instance.validate();
    if (!validationResult.isValid) {
      return;
    }

    const data: IPasswortAendernData = {
      OldPassword: "IGNOREME",
      NewPassword: this.passwortAendernData.newPass1,
    };

    await this._benutzereinstellungenService.changePasswort(data);

    DevExpress.ui.notify(this._localizationService.translateOnce("passwort-aendern.passwort_changed_confirmation"), "SUCCESS", this.NOTIFY_TIMEOUT);

    this.passwortAendernPopup.instance.hide();
  }
  private getRequiredMessage(caption: string) {
    const result = this._localizationService.translateOnce(
      "passwort-aendern.passswort_required_validation",
      [this._localizationService.translateOnce(caption)]);

    const resultLowerCase = result.charAt(0)
      .toLowerCase();

    return resultLowerCase + result.slice(1);
  }
  private getValidator(options: DevExpress.ui.dxTextBoxOptions) {
    const validationRules = [{
      type: "custom",
      message: "",
      reevaluate: false,
      validationCallback: (e) => {
        e.rule.message = this.getRequiredMessage(options.name);

        return e.value ? true : false;
      }
    }];

    return { validationRules: validationRules };
  }
}
