import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class StelleninseratListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "stelleninserat-list";
        this.title = "stelleninserat-list.stelleninserat-list_caption";
        this.addModel({
            "id": "$m_Stelleninserat",
            "webApiAction": "ZIT/Objekte/Stelleninserat",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'Geschaeftspartner': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_stellenbezeichnungExtern",
                "caption": "stelleninserat-list.r_stellenbezeichnungextern_caption",
                "bindTo": "Kopf.StellenbezeichnungExtern"
            }, {
                "id": "r_firmenname1",
                "caption": "stelleninserat-list.r_firmenname1_caption",
                "bindTo": "Kopf.Geschaeftspartner.Name1"
            }],
            "optionsToolbar": {
                "optionsName": "r_stelleninseratGridToolbarOptions",
                "optionsNameFQ": "r_stelleninseratGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Stelleninserat"
            },
            "dataModel": "$m_Stelleninserat",
            "editUrl": "Objekte/Stelleninserat",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_stelleninseratGrid",
            "options": {
                "optionsName": "r_stelleninseratGridOptions",
                "optionsNameFQ": "r_stelleninseratGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}