import { observable } from 'aurelia-binding';
import { BindingService, TemplatingExService } from './../../../framework/base/services/export';
import { autoinject, bindable, createOverrideContext, TemplatingEngine, noView, View } from 'aurelia-framework';
import { ScopeContainer } from '../../../framework/base/classes/scope-container';
import { IZitTagOptions, IZitTagIcon } from './zit-tag-options';

import * as template from "html-loader!./zit-tag.xhtml";
import * as templateInfo from "html-loader!./zit-tag-info.xhtml";
import * as moment from "moment";
import "./zit-tag.less";
import { IZitTagInfo } from './export';
import { ZitTagService } from '../../export';
import { FileService } from '../../../framework/base/export';

@autoinject
@noView
export class ZitTag {
  private _createdView: View;

  constructor(
    private binding: BindingService,
    private element: Element,
    private file: FileService,
    private templatingEngine: TemplatingEngine,
    private templatingExService: TemplatingExService,
    private zitTag: ZitTagService
  ) { }

  @bindable options: IZitTagOptions;
  @bindable @observable data: any;
  @bindable tagOptionName: string;

  owningView: any;
  scopeContainer: ScopeContainer;

  dataChanged(newVal) {
    if (!this.scopeContainer) {
      return;
    }

    this.scopeContainer.scope.bindingContext.item = newVal;
  }

  created(owningView: any, myView: any) {
    this.owningView = owningView;
  }

  bind(bindingContext, overrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: {
        item: this.data
      },
      overrideContext: createOverrideContext(bindingContext, overrideContext)
    }, null);

    if (this.options == void 0 && this.tagOptionName != void 0) {
      this.options = this.zitTag.getTagOptions(this.tagOptionName);
    }

    const tagHtml = this.parseHtml(<string>template);
    const j = <HTMLElement>tagHtml;

    if (this.options.onClick || this.options.isClickEnabled) {
      if (this.options.isClickEnabledExpression) {
        j.className = (j.className || "") + "${" + this.options.isClickEnabledExpression + "?'z--clickable':'z--disabled'}";
      } else if (this.options.isClickEnabled !== false) {
        j.classList.add("z--clickable");
      }
    }

    if (this.options.text != void (0)) {
      j.querySelector(".z--tag-text").innerHTML = this.options.text;
    } else if (this.options.textExpression) {
      j.querySelector(".z--tag-text").innerHTML = "${" + this.options.textExpression + "}";
    }
    
    if (this.options.tooltip != void (0)) {
      j.setAttribute("title", this.options.tooltip);
    } else if (this.options.tooltipExpression) {
      j.setAttribute("title", "${" + this.options.tooltipExpression + "}")
    }

    if (this.options.useWhiteSpacePre) {
      (<HTMLElement>j.querySelector(".z--tag-text")).style.whiteSpace = "pre";
    }

    if (this.options.backgroundColor) {
      j.style.backgroundColor = this.options.backgroundColor;
    } else if (this.options.backgroundColorExpression) {
      this.addStyleExpr(j, "background-color", this.options.backgroundColorExpression);
    }

    if (this.options.color || this.options.colorExpression) {
      const t: HTMLElement = j.querySelector(".z--tag-text");
      if (t) {
        if (this.options.color) {
          t.style.color = this.options.color;
        } else if (this.options.colorExpression) {
          this.addStyleExpr(t, "color", this.options.colorExpression);
        }
      }
    }

    if (this.options.icon) {
      this.addIcon(<HTMLElement>j.querySelector(".z--tag-main-icon"), this.options.icon);
    }

    const tagInfoList = this.options.infoList
      ? this.options.infoList.map(i => i)
      : [];

    if (this.options.hasDefaultInfo) {
      tagInfoList.push(...this.getDefaultInfo());
    }

    if (tagInfoList.length > 0) {
      const infoList = j.querySelector(".z--tag-info-list");
      tagInfoList.forEach((info, index) => {
        const infoHtml = this.parseHtml(<string>templateInfo);
        const ij = <HTMLElement>infoHtml;

        infoList.appendChild(infoHtml);

        const ina = `_m.options.infoList[${index}]`;

        if (info.onClick) {
          if (info.isClickEnabledExpression) {
            j.classList.add("${" + info.isClickEnabledExpression + " ? 'z--clickable' : 'z--disabled'}");
          } else if (info.isClickEnabled !== false) {
            ij.classList.add("z--clickable");
          }
          ij.setAttribute("click.trigger", `_m.onInfoClick($event, ${ina})`);
        }

        if (info.backgroundColor) {
          ij.style.backgroundColor = info.backgroundColor;
        } else if (info.backgroundColorExpression) {
          this.addStyleExpr(ij, "background-color", info.backgroundColorExpression);
        }

        if (info.isVisible === false) {
          ij.setAttribute("if.bind", "false");
        } else if (info.isVisibleExpression) {
          ij.setAttribute("if.bind", info.isVisibleExpression);
        }

        if (info.color) {
          ij.style.color = info.color;
        } else if (info.colorExpression) {
          this.addStyleExpr(ij, "color", info.colorExpression);
        }

        if (info.icon) {
          this.addIcon(ij, info.icon);
        }
      });
    }

    const deleteEl = j.querySelector(".z--tag-icon-delete");
    if (this.options.isDeleteClickEnabled != void(0)) {
      if (!this.options.isDeleteClickEnabled) {
        deleteEl.parentElement.removeChild(deleteEl);
      }
    } else if (this.options.isDeleteClickEnabledExpression) {
      deleteEl.setAttribute("if.bind", this.options.isDeleteClickEnabledExpression);
    } else {
      deleteEl.setAttribute("if.bind", "_m.options.onDeleteClick");
    }

    this.element.appendChild(tagHtml);

    this._createdView = this.templatingEngine.enhance({
      element: tagHtml,
      resources: this.owningView.resources,
      bindingContext: {
        _m: this
      },
      overrideContext: createOverrideContext(this.scopeContainer.scope.bindingContext, this.scopeContainer.scope.overrideContext)
    });
  }
  unbind() {
    while (this.element.children.length > 0) {
      this.element.removeChild(this.element.children.item(0));
    }

    this.scopeContainer.disposeAll();

    if (this._createdView) {
      this.templatingExService.destroyView(this._createdView);
      this._createdView = null;
    }
  }

  onClick(event: MouseEvent) {
    if (this.options.onClick == void 0) {
      return false;
    }

    this.options.onClick(event, this.data);
  }
  onInfoClick(event: MouseEvent, info) {
    info.onClick(event, this.data, info);
  }
  onDeleteClick(event: MouseEvent) {
    if (this.options.onDeleteClick == void 0) {
      return false;
    }

    this.options.onDeleteClick(event, this.data);
  }

  private addStyleExpr(j: HTMLElement, attr: string, value: string) {
    j.setAttribute("css", attr + ": ${" + value + "};");
  }

  private addIcon(j: HTMLElement, icon: IZitTagIcon) {
    if (icon.backgroundColor) {
      j.style.backgroundColor = icon.backgroundColor;
    } else if (icon.backgroundColorExpression) {
      this.addStyleExpr(j, "background-color", icon.backgroundColorExpression.replace(/\s/g, ""));
    }

    if (icon.faIcon) {
      const i = document.createElement("i");
      i.classList.add(...icon.faIcon.split(" "));

      j.appendChild(i);
    } else if (icon.faIconExpression) {
      const i = document.createElement("i");
      i.className = (i.className || "") + "${" + icon.faIconExpression + "}";

      j.appendChild(i);
    }
    if (icon.text) {
      const span = document.createElement("span");
      span.innerHTML = icon.text;

      j.appendChild(span);
    } else if (icon.textExpression) {
      const span = document.createElement("span");
      span.innerHTML = "${" + icon.textExpression.replace(/\s/g, "") + "}";

      j.appendChild(span);
    }
    if (icon.dmsLinkIconExpression) {
      const imgKey = this.binding.evaluate(this.scopeContainer.scope, icon.dmsLinkIconExpression);
      const imgSrc = this.file.getInlineUrl(imgKey);
      const imgContainer = document.createElement("img");
      imgContainer.setAttribute("src", imgSrc)


      j.appendChild(imgContainer);
    }
  }

  private getDefaultInfo(): IZitTagInfo[] {
    const infoList: IZitTagInfo[] = [];
    const jetzt = moment();
    if (this.data.GueltigBis && moment(this.data.GueltigBis).isBefore(jetzt.startOf("day"))) {
      infoList.push({
        color: "red",
        icon: {
          faIcon: "fas fa-circle",
          backgroundColor: "red"
        }
      });
    } else if (this.data.GueltigVon && moment(this.data.GueltigVon).isAfter(jetzt)
      || this.data.GueltigBis && jetzt.add(14, "days").isAfter(this.data.GueltigBis)) {
      infoList.push({
        color: "orange",
        icon: {
          faIcon: "fas fa-circle",
          backgroundColor: "orange"
        }
      });
    }

    if (this.data.IsInaktiv === true) {
      infoList.push({
        icon: {
          faIcon: "far fa-trash-alt"
        }
      });
    }

    return infoList;
  }

  private parseHtml(html: string): Element {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    return doc.body.firstElementChild;
  }
}
