import { autoinject, bindable, Scope, observable, computedFrom, TaskQueue } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { NachrichtService } from "./../../../zit/services/nachricht-service";
import { IZitCardOptions, ZitCardTyp } from "./zit-card-options";
import { BrowserService, ScopeContainer } from "../../../framework/base/export";
import { BindingService } from "../../../framework/base/services/export";
import { IZitMarkersOptions } from "../zit-markers/export";
import { ObjektInfoService, DokumentService } from "../../../zit/services/export";
import { StartupService } from "../../../zit/services/startup-service";
import { ICardStartupInfo, IObjektInfoData } from "../../../zit/interfaces/export";
import { LocationService } from "../../../framework/base/services/location-service";
import { FormUtilsService } from "../../../framework/forms/services/form-utils-service";
import { ZitCardService } from "../../services/zit-card-service";
import { PopupInfoService } from "../../../framework/forms/export";
import { FotoPopupService } from "../../../zit/services/foto-popup-service";

@autoinject
export class ZitCard {
  private _defaultCardTyp = ZitCardTyp.Kompakt;
  private _scope: Scope;
  private ID_HEARTBASE: number = 5;

  constructor(
    public _zitCardService: ZitCardService,
    public browserService: BrowserService,
    private _element: Element,
    private _bindingService: BindingService,
    private _objektInfoService: ObjektInfoService,
    private _startupService: StartupService,
    private _locationService: LocationService,
    private _formUtilsService: FormUtilsService,
    private _nachrichtService: NachrichtService,
    private _dokumentService: DokumentService,
    private _popupInfoService: PopupInfoService,
    private _fotoPopupService: FotoPopupService,
    private _eventAggregator: EventAggregator,
    private _taskQueue: TaskQueue
  ) { }

  @bindable options: IZitCardOptions;
  @bindable @observable data: any;

  optionsInstance: any;
  scopeContainer: ScopeContainer;
  objektInfoData: IObjektInfoData;
  cardInfo: ICardStartupInfo;

  typ: ZitCardTyp;
  showRibbon: boolean;
  ribbon: any;

  buttonRight?: string;

  @computedFrom("objektInfoData.Fullname")
  get isNachrichtErstellenVisible(): boolean {
    if (this.typ != ZitCardTyp.Kompakt && !this.options.showNachrichten && !this.data.showNachrichten) {
      return false;
    }

    if (!this._nachrichtService.canNachrichtErstellen(this.objektInfoData.Fullname)) {
      return false;
    }

    return true;
  }
  
  @computedFrom("browserService.isMobile")
  get isMobile() {
    return this.browserService.isMobile;
  }

  get isHeartbase() {
    return this._startupService.startupInfo.Mandant.IdMandant == this.ID_HEARTBASE;
  }

  bind(bindingContext, overrideContext) {
    this._scope = {
      bindingContext,
      overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: {
        item: this.data
      },
      overrideContext: null
    }, null);

    this.objektInfoData = this.getObjektInfoData();

    this._dokumentService.attachDropDokument(
      <any>this._element,
      this.objektInfoData.Fullname,
      () => this.data.Data);

    if (this.options.cardTemplateName == void 0) {
      this.cardInfo = this.getCardInfo();
    }

    this.typ = this.options.typ || this._defaultCardTyp;
    this.optionsInstance = {
      contentTemplate: this.getCardTemplate(this.options.cardTemplateName),
      hasMarkers: this.options.markersCardOptions != void 0 || this.options.useDefaultBindings
    };

    if (this.typ == ZitCardTyp.Klein) {
      this.buttonRight = "85px";
    }

    this.setRibbonOptions(this.cardInfo, this.typ);

    if (this.options.useDefaultBindings) {
      this.options.statusColorExpression = "item.Data.ObjektStatusSymbolFarbe";
      this.options.markersCardOptions = {
        markersListExpression: "item.Data.ObjektMarkerZuordnungen"
      };
    }

    this.evaluateAndObserve(this.options.isClickEnabledExpression, this.options.isClickEnabled, v => this.optionsInstance.isClickEnabled = v);
    this.evaluateAndObserve(this.options.statusColorExpression, this.options.statusColor, v => this.optionsInstance.statusColor = v);
    this.evaluateAndObserve(this.options.dmsLinkExpression, this.options.dmsLink, v => this.optionsInstance.dmsLink = v);

    if (this.optionsInstance.hasMarkers) {
      const markerOptions: IZitMarkersOptions = {
        onClick: this.options.markersCardOptions ? this.options.markersCardOptions.onClick : null
      };

      this.evaluateAndObserve(this.options.markersCardOptions.markersListExpression, this.options.markersCardOptions.markersList, v => markerOptions.markerList = v);

      this.optionsInstance.markersOptions = markerOptions;
    }
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this._scope = null;
  }

  dataChanged(newVal) {
    this.scopeContainer.scope.bindingContext.item = newVal;
  }

  onClick(event: MouseEvent) {
    if (!this.optionsInstance.isClickEnabled) {
      return;
    }

    if (this._popupInfoService.isPopupOpen()) {
      this._popupInfoService.closeAllPopups();
    }
    
    const args = {
      sender: this,
      data: this.data,
      isHandled: false
    }

    this._element.dispatchEvent(new CustomEvent("on-card-click", {
      detail: args,
      bubbles: true
    }));
    
    if (args.isHandled) {
      event.stopPropagation();
      return false;
    } else if (this.options.onClick != void (0)) {
      this.options.onClick(event, this.data);
      event.stopPropagation();
      return false;
    } else if (this._objektInfoService != void (0)) {
      const url = this.getObjektUrl();

      if (url) {
        const currentForm = this._formUtilsService.getMainForm(this._scope);
        this._locationService.goTo({
          url: url,
          currentViewModel: currentForm
        });
      }

      event.stopPropagation();
      return false;
    }
  }

  onButtonClick(event: MouseEvent, button: IButton) {
    event.preventDefault();
    event.stopPropagation();

    if (button.onClick) {
      button.onClick(event);
    }
    if (button.eventName) {
      this._eventAggregator.publish(button.eventName, {
        data: this.data
      });
    }
  }

  onFotoClick(e, data) {
    e.preventDefault();
    e.stopPropagation();

    this._fotoPopupService.popup.show({
      dmsLink: data.Data.DMSLinkFoto,
      name: data.Data.Name
    });
  }

  async nachrichtErstellen(e: MouseEvent) {
    e.stopPropagation();
    this._nachrichtService.doNachrichtErstellenFromObjekt([this.data.Data.Id], this.objektInfoData.Fullname);
  }

  private evaluateAndObserve(expression: string, current: any, setValueCallback: { (val: any): void }) {
    if (expression) {
      this._bindingService.observe({
        scopeContainer: this.scopeContainer,
        expression: expression,
        callback: (newVal) => {
          setValueCallback(newVal);
        }
      });

      setValueCallback(this._bindingService.evaluate(this.scopeContainer.scope, expression));
    } else {
      setValueCallback(current);
    }
  }

  private getCardTemplate(name?: string): string {
    let templateName = name || this.cardInfo[`Card${this.typ}`];
    templateName = this.translateCardName(templateName);

    return `../../card-templates/${templateName}`;
  }

  private getCardInfo(): ICardStartupInfo {
    if (!this.objektInfoData) {
      throw new Error("Keine Objektinfo zur Ermittlung der Card verfügbar");
    }

    const cardInfo = this._startupService.getCardInfoByObjektFullName(this.objektInfoData.Fullname);

    if (!cardInfo) {
      throw new Error(`Keine CardInfo für ${this.objektInfoData.Fullname} verfügbar`);
    }

    return cardInfo;
  }

  private getObjektInfoData(): IObjektInfoData {
    if (!this.data.TypeName) {
      return null;
    }

    return this.data.TypeName.indexOf(".") > 0
      ? this._objektInfoService.getObjektByFullname(this.data.TypeName)
      : this._objektInfoService.getObjektByName(this.data.TypeName);
  }

  private getObjektUrl(): string {
    if (!this.objektInfoData || !this.data || !this.data.Data || !this.data.Data.Id) {
      return null;
    }

    const id = this.data.Data.Id;

    const url = this._objektInfoService.getObjektNavigationUrl(this.objektInfoData.Fullname);
    return `${url}/${id}`;
  }
  
  private setRibbonOptions(cardInfo: ICardStartupInfo, cardTyp: ZitCardTyp) {
    if (!cardInfo) {
      return;
    }
    this.showRibbon = this.options.showRibbon == void 0
      ? true
      : this.options.showRibbon;

    if (this.showRibbon == false) {
      return;
    }

    this.ribbon = Object.assign({}, this.options.ribbon);

    this.optionsInstance.ribbonColor = this.ribbon.backgroundColor || cardInfo[`Card${cardTyp}Hintergrundfarbe`];
    this.optionsInstance.ribbonIcon = this.ribbon.icon || cardInfo[`Card${cardTyp}Symbol`];
  }

  // tslint:disable-next-line: cyclomatic-complexity
  private translateCardName(cardName: string): string {
    switch (cardName) {
      case "AnsprechpersonCardKompakt.html": {
        return "ansprechperson-kompakt.html";
      }
      case "AnsprechpersonCardKlein.html": {
        return "ansprechperson-klein.html";
      }
      case "StelleninseratKundeCardKompakt.html": {
        return "stelleninserat-kunde-kompakt.html";
      }
      case "StelleninseratPersonCardKompakt.html": {
        return "stelleninserat-person-kompakt.html";
      }
      case "ProfilFirmaKundeCardKompakt.html": {
        return "profil-firma-kunde-kompakt.html";
      }
      case "PersonCardKompakt.html": {
        return "person-kompakt.html";
      }
      case "PersonCardKlein.html": {
        return "person-klein.html";
      }
      case "PersonKundeCardKompakt.html": {
        return "person-kunde-kompakt.html";
      }
      case "GeschaeftspartnerCardKompakt.html": {
        return "geschaeftspartner-kompakt.html";
      }
      case "DispoPersonenzuteilungCardKompakt.html": {
        return "dispo-personenzuteilung-kompakt.html";
      }
      case "DispoPlanstelleCardKompakt.html": {
        return "dispo-planstelle-kompakt.html";
      }
      case "BewerbungKundeCardKompakt.html": {
        return "bewerbung-kunde-kompakt.html";
      }
      case "BewerbungPersonCardKompakt.html": {
        return "bewerbung-person-kompakt.html";
      }
      case "StelleninseratKundeCard.html": {
        return "stelleninserat-kunde-gross.html";
      }
      case "StelleninseratPersonCard.html": {
        return "stelleninserat-person-gross.html";
      }
      case "ProfilFirmaKundeCard.html": {
        return "profil-firma-kunde-gross.html";
      }
      case "PersonCard.html": {
        return "person-gross.html";
      }
      case "DispoPersonCard.html": {
        return "dispo-person-gross.html";
      }
      case "DispoPlanstelleCard.html": {
        return "dispo-planstelle-gross.html";
      }
      case "BewerbungKundeCard.html": {
        return "bewerbung-kunde-gross.html";
      }
      case "BewerbungPersonCard.html": {
        return "bewerbung-person-gross.html";
      }
      case "DispoPersonenzuteilungCardKlein.html": {
        return "dispo-personenzuteilung-klein.html";
      }
      case "BewerbungKundeCardKlein.html": {
        return "bewerbung-kunde-klein.html";
      }
      case "TerminCardKompakt.html": {
        return "termin-kompakt.html";
      }
      case "TerminHeartbaseCardKompakt.html": {
        return "termin-heartbase-kompakt.html";
      }
      case "AufgabeCardKompakt.html": {
        return "aufgabe-kompakt.html";
      }
      case "ZielCardKompakt.html": {
        return "ziel-kompakt.html";
      }
      case "ZielCardKlein.html": {
        return "ziel-klein.html";
      }
      case "InformationCardKompakt.html": {
        return "information-kompakt.html";
      }
      case "EventCardKompakt.html": {
        return "event-kompakt.html";
      }
      case "EventZeitraumCardKompakt.html":{
        return "event-zeitraum-kompakt.html";
      }
      case "EventTeilnehmerCardKompakt.html": {
        return "event-teilnehmer-kompakt.html";
      }
      case "EventTeilnehmerCardKlein.html": {
        return "event-teilnehmer-klein.html";
      }
      default: {
        return cardName;
      }
    }
  }
}

interface IButton {
  icon: string;
  eventName?: string;
  onClick?(event: MouseEvent);
}
