import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class BewerbungListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "bewerbung-list";
        this.title = "bewerbung-list.bewerbung-list_caption";
        this.addModel({
            "id": "$m_Bewerbung",
            "webApiAction": "ZIT/Objekte/Bewerbung",
            "webApiExpand": {
                Kopf: {
                    expand: {
                        Stelleninserat: {
                            expand: {
                                Kopf: null
                            }
                        },
                        Person: {
                            expand: {
                                Personendaten: null
                            }
                        }
                    }
                }
            },
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_bewerbungPersonName",
                "caption": "bewerbung-list.r_bewerbungpersonname_caption",
                "bindTo": "Kopf.Person.Personendaten.Name"
            }, {
                "id": "r_bewerbungStellenbezeichnung",
                "caption": "bewerbung-list.r_bewerbungstellenbezeichnung_caption",
                "bindTo": "Kopf.Stelleninserat.Kopf.StellenbezeichnungExtern"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewerbungGridToolbarOptions",
                "optionsNameFQ": "r_bewerbungGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Bewerbung"
            },
            "dataModel": "$m_Bewerbung",
            "editUrl": "Objekte/Bewerbung",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewerbungGrid",
            "options": {
                "optionsName": "r_bewerbungGridOptions",
                "optionsNameFQ": "r_bewerbungGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}