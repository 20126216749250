import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, bindable } from "aurelia-framework";
import { RestService } from "../../../framework/base/export";
import { BenutzerTyp } from "../../enumerations/export";
import { StartupService } from "../../services/export";
import { ISchnuppertermin } from "../schnuppertermin/schnuppertermin";
import { BrowserService } from "./../../../framework/base/services/browser-service";

@autoinject
export class SchnupperterminListViewItem {
  private _schnupperterminErstelltSubscription: Subscription;
  private _ANZAHLTERMINE: number = 2;

  constructor(
    private _element: Element,
    private _restService: RestService,
    private _eventAggregator: EventAggregator,
    private _browserService: BrowserService,
    private _startupService: StartupService
  ) {
    this.isSchuleOrAdmin = this._startupService.startupInfo.Benutzer.CodeBenutzerrolle == "ADMIN"
      || this._startupService.startupInfo.Benutzer.CodeBenutzerrolle == "KUNDE-SCHULE";
  }

  @bindable data: ISchnuppertermin;

  isSchuleOrAdmin: boolean = false;
  showKundenLogo: boolean = true;

  fileDownloadBaseUrl: string;

  bind() {
    let url = this._restService.getApiUrl("ZIT/Schnuppertermin/DokBestaetigung");
    url += `?auth-token=${encodeURIComponent(this._restService.getAuthorizationToken())}&idEventTeilnehmer=`;
    this.fileDownloadBaseUrl = url;

    this._schnupperterminErstelltSubscription = this._eventAggregator.subscribe("schnuppertermin:erstellt", (r) => {
      if (this._startupService.startupInfo.Benutzer.Benutzertyp != BenutzerTyp.NatuerlichePerson) {
        return;
      }

      if (this.data.IdStelleninserat == r.IdStelleninserat) {
        this.data.IdEventTeilnehmer = r.IdEventTeilnehmer;
        this.data.HasDokument = r.HasDokument;

        if (!r.IdTermin) {
          this.data.IsTerminBestaetigt = null;
        }
      }
    });

    if (this._browserService.isMobile) {
      this.showKundenLogo = false;
    }
  }
  unbind() {
    this._schnupperterminErstelltSubscription.dispose();
    this._schnupperterminErstelltSubscription = null;
  }

  downloadDokBestaetigung() {
    window.open(this.fileDownloadBaseUrl + this.data.IdEventTeilnehmer, "_blank");
  }
  openStelleninserat() {
    if (!this.data.VeroeffentlichungTyp || this.data.VeroeffentlichungTyp == 0) {
      return;
    }

    if (!this.data.UrlStelleninserat) {
      return;
    }
    window.open(this.data.UrlStelleninserat, "_blank");
  }
  openGeschaeftspartnerWebsite() {
    if (!this.data.UrlGeschaeftspartner || !this.data.GeschaeftspartnerHasGebuchtesPackage) {
      return;
    }

    if (this.data.UrlGeschaeftspartner.indexOf("http") != -1) {
      window.open(this.data.UrlGeschaeftspartner, "_blank");
    } else {
      window.open("http://" + this.data.UrlGeschaeftspartner, "_blank");
    }
  }
  openLehrberuf() {
    window.open(this.data.LehrberufUrl, "_blank");
  }
  getSchnupperterminMerken() {
    this.data.IsMerkerAktiv = !this.data.IsMerkerAktiv;

    return this._restService.get({
      url: this._restService.getApiUrl(`ZIT/Schnuppertermin/SchnupperterminMerken?idStelleninserat=${this.data.IdStelleninserat}&merken=${this.data.IsMerkerAktiv}`),
      increaseLoadingCount: true
    });
  }
  showTerminErstellenPopup() {
    if (this.data.ErstellungSchnupperterminVerhindern && !this.isSchuleOrAdmin) {
      return;
    }
    
    const isBig = window.screen.width >= 1200;
    
    const lines: string[] = ["Gewünschter Schnuppertermin schon abgeklärt?"];    
    if (this.data.SchnupperterminAnfrageart) {
      lines.push(`Unternehmen wünscht: &quot;${this.data.SchnupperterminAnfrageart}&quot;`)
    }
    if (!isBig) {
      lines.push("Klicke auf Ja, um den Termin zu erfassen.");
      lines.push("Klicke auf Nein, um den Termin zuerst abzuklären.")
    }
    
    const sendCreateEvent = () => {
      const event = new CustomEvent("on-show-erstellen", {
        detail: {
          sender: this,
          idStelleninserat: this.data.IdStelleninserat,
          idEventTeilnehmer: this.data.AnzTermine < this._ANZAHLTERMINE && this.data.IdEventTeilnehmer
            ? this.data.IdEventTeilnehmer
            : null,
          data: this.data
        },
        bubbles: true
      });

      this._element.dispatchEvent(event);
    };

    if (this.data.KeineDirekteKontaktaufnahmeErforderlich) {
      sendCreateEvent();
      return;
    }
    
    DevExpress.ui.dialog.custom({
      title: "Frage",
      messageHtml: lines.join("<br>"),
      buttons: [{
        text: isBig ? "JA, weiter zur Erfassung des Termins" : "Ja",
        onClick: () => {
          sendCreateEvent();
        }
      }, {
        text: isBig ? "NEIN, abbrechen und Termin zuerst abklären" : "Nein"
      }]
    }).show();
  }

  callTelefonnummer() {
    if (!this.data.TelefonnummerClickToCall || this.data.TelefonnummerClickToCall.length == 0) {
      return;
    }

    window.open(this.data.TelefonnummerClickToCall, "_blank");
  }
}
