import { autoinject } from "aurelia-framework";

@autoinject
export class DateiService {
  constructor() {}

  isBild(dateiname: string) {
    dateiname = (dateiname || "").toLowerCase();

    return dateiname.endsWith(".jpg")
      || dateiname.endsWith(".jpeg")
      || dateiname.endsWith(".gif")
      || dateiname.endsWith(".png")
  }
}