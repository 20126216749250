import * as fwx from "../../../../framework/forms/form-export";
import {
    AnsprechpersonEditUtils
} from "./ansprechperson-edit-utils";

@fwx.autoinject
export class AnsprechpersonEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_AnsprechpersonEditUtils: AnsprechpersonEditUtils) {
        super(element, formBaseImport);
        this.id = "ansprechperson-edit";
        this.title = "ansprechperson-edit.ansprechperson-edit_caption";
        this.addModel({
            "id": "$m_Ansprechperson",
            "webApiAction": "ZIT/Objekte/Ansprechperson",
            "webApiExpand": {
                'Personendaten': null
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "CanBenutzeraccountAktivieren",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "SonstigeKontaktdatenVisible",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_AnsprechpersonZuGeschaeftspartnerRel",
            "webApiAction": "ZIT/Objekte/AnsprechpersonZuGeschaeftspartner",
            "webApiExpand": {
                'Geschaeftspartner': {
                    'expand': {
                        'Geschaeftspartnerdaten': null,
                        'Adressen': {
                            'expand': {
                                'Adressen': null
                            }
                        }
                    }
                }
            },
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "onlyGueltig",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_AnsprechpersonZuGeschaeftspartnerEdit",
            "webApiAction": "ZIT/Objekte/AnsprechpersonZuGeschaeftspartner",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_AnsprechpersonKontaktElement",
            "webApiAction": "ZIT/Objekte/AnsprechpersonKontaktElement",
            "key": "models.data.$m_Ansprechperson.IdAnsprechpersonKontaktElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_AnsprechpersonKontaktEdit",
            "webApiAction": "ZIT/Objekte/AnsprechpersonKontakt",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_AnsprechpersonKontaktRel",
            "webApiAction": "ZIT/Objekte/AnsprechpersonKontakt",
            "webApiExpand": {
                Kontakttyp: null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AnsprechpersonEditUtils.actionCommand",
                "bindToFQ": "functions.$f_AnsprechpersonEditUtils.actionCommand"
            }
        });
        this.addEditPopup({
            "idContent": "ansprechpersonGeschaeftspartnerEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_AnsprechpersonZuGeschaeftspartnerEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_AnsprechpersonZuGeschaeftspartnerEdit.Id"
                }
            }],
            "id": "ansprechpersonGeschaeftspartnerEditPopup",
            "options": {
                "optionsName": "ansprechpersonGeschaeftspartnerEditPopupOptions",
                "optionsNameFQ": "ansprechpersonGeschaeftspartnerEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "ansprechpersonKontaktEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_AnsprechpersonKontaktEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_AnsprechpersonKontaktEdit.Id"
                }
            }],
            "id": "ansprechpersonKontaktEditPopup",
            "options": {
                "optionsName": "ansprechpersonKontaktEditPopupOptions",
                "optionsNameFQ": "ansprechpersonKontaktEditPopupOptions"
            },
            "height": "350px",
            "width": "500px",
            "commands": []
        });
        this.addFunction("$f_AnsprechpersonEditUtils", $f_AnsprechpersonEditUtils, "functions.$f_AnsprechpersonEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "geschaeftspartner",
            "filters": [],
            "caption": "ansprechperson-edit.r_selectgeschaeftspartner_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "_IdGeschaeftspartner",
                "bindToFQ": "models.data.$m_Ansprechperson._IdGeschaeftspartner"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_selectGeschaeftspartner",
            "options": {
                "optionsName": "r_selectGeschaeftspartnerOptions",
                "optionsNameFQ": "r_selectGeschaeftspartnerOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "kontaktpersonTyp",
            "filters": [],
            "caption": "ansprechperson-edit.r_ansprechpersontyp_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.KontaktpersonTyp",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.KontaktpersonTyp",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_ansprechpersonTyp",
            "options": {
                "optionsName": "r_ansprechpersonTypOptions",
                "optionsNameFQ": "r_ansprechpersonTypOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "geschlecht",
            "filters": [],
            "caption": "ansprechperson-edit.r_geschlecht_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.Geschlecht",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.Geschlecht",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschlecht",
            "options": {
                "optionsName": "r_geschlechtOptions",
                "optionsNameFQ": "r_geschlechtOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "ansprechperson-edit.r_titelvorne_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.TitelVorne",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.TitelVorne",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_titelVorne",
            "options": {
                "optionsName": "r_titelVorneOptions",
                "optionsNameFQ": "r_titelVorneOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "ansprechperson-edit.r_titelhinten_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.TitelHinten",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.TitelHinten",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_titelHinten",
            "options": {
                "optionsName": "r_titelHintenOptions",
                "optionsNameFQ": "r_titelHintenOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "ansprechperson-edit.r_vorname_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.Vorname",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.Vorname",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_vorname",
            "options": {
                "optionsName": "r_vornameOptions",
                "optionsNameFQ": "r_vornameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "ansprechperson-edit.r_nachname_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.Nachname",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.Nachname",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_nachname",
            "options": {
                "optionsName": "r_nachnameOptions",
                "optionsNameFQ": "r_nachnameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "ansprechperson-edit.r_team_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.Team",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.Team",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_team",
            "options": {
                "optionsName": "r_teamOptions",
                "optionsNameFQ": "r_teamOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "ansprechperson-edit.r_email_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.Email",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.Email",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_email",
            "options": {
                "optionsName": "r_emailOptions",
                "optionsNameFQ": "r_emailOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "landvorwahl",
            "filters": [],
            "caption": "ansprechperson-edit.r_mobillandvorwahl_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.MobilLandvorwahl",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.MobilLandvorwahl",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Ansprechperson.Personendaten && models.data.$m_Ansprechperson.Personendaten.Mobil"
                    }]
                }
            }],
            "id": "r_mobilLandvorwahl",
            "options": {
                "optionsName": "r_mobilLandvorwahlOptions",
                "optionsNameFQ": "r_mobilLandvorwahlOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "ansprechperson-edit.r_mobil_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.Mobil",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.Mobil",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "telefon",
                    "parameters": []
                }
            }],
            "id": "r_mobil",
            "options": {
                "optionsName": "r_mobilOptions",
                "optionsNameFQ": "r_mobilOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "landvorwahl",
            "filters": [],
            "caption": "ansprechperson-edit.r_telefonlandvorwahl_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.TelefonLandvorwahl",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.TelefonLandvorwahl",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Ansprechperson.Personendaten && models.data.$m_Ansprechperson.Personendaten.Telefon"
                    }]
                }
            }],
            "id": "r_telefonLandvorwahl",
            "options": {
                "optionsName": "r_telefonLandvorwahlOptions",
                "optionsNameFQ": "r_telefonLandvorwahlOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "ansprechperson-edit.r_telefon_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.Telefon",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.Telefon",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "telefon",
                    "parameters": []
                }
            }],
            "id": "r_telefon",
            "options": {
                "optionsName": "r_telefonOptions",
                "optionsNameFQ": "r_telefonOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "ansprechperson-edit.r_funktion_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.Funktion",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.Funktion",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_funktion",
            "options": {
                "optionsName": "r_funktionOptions",
                "optionsNameFQ": "r_funktionOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "50px",
            "maxLength": 1000,
            "caption": "ansprechperson-edit.r_bemerkung_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.Bemerkung",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.Bemerkung",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_bemerkung",
            "options": {
                "optionsName": "r_bemerkungOptions",
                "optionsNameFQ": "r_bemerkungOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "ansprechperson-edit.r_imgeschaeftspartnerausblenden_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.ImGeschaeftspartnerAusblenden",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.ImGeschaeftspartnerAusblenden",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_ImGeschaeftspartnerAusblenden",
            "options": {
                "optionsName": "r_ImGeschaeftspartnerAusblendenOptions",
                "optionsNameFQ": "r_ImGeschaeftspartnerAusblendenOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "ansprechperson-edit.r_keinnewslettererwuenscht_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.KeinNewsletterErwuenscht",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.KeinNewsletterErwuenscht",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_keinNewsletterErwuenscht",
            "options": {
                "optionsName": "r_keinNewsletterErwuenschtOptions",
                "optionsNameFQ": "r_keinNewsletterErwuenschtOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 0,
            "acceptType": "image/*",
            "height": "250px",
            "showToolbar": true,
            "showClearButton": false,
            "caption": "ansprechperson-edit.r_dmslinkfoto_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.DMSLinkFoto",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.DMSLinkFoto",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_dmsLinkFoto",
            "options": {
                "optionsName": "r_dmsLinkFotoOptions",
                "optionsNameFQ": "r_dmsLinkFotoOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 2,
            "acceptType": ".vcf",
            "height": "100px",
            "showToolbar": true,
            "showClearButton": false,
            "caption": "ansprechperson-edit.r_dmslinkvisitenkarte_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.DMSLinkVisitenkarte",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.DMSLinkVisitenkarte",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_dmsLinkVisitenkarte",
            "options": {
                "optionsName": "r_dmsLinkVisitenkarteOptions",
                "optionsNameFQ": "r_dmsLinkVisitenkarteOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "landvorwahl",
            "filters": [],
            "caption": "ansprechperson-edit.r_alternativetelefonllandvorwahl_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.AlternativTelefonLandvorwahl",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.AlternativTelefonLandvorwahl",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Ansprechperson.Personendaten && models.data.$m_Ansprechperson.Personendaten.AlternativTelefon"
                    }]
                }
            }],
            "id": "r_alternativeTelefonlLandVorwahl",
            "options": {
                "optionsName": "r_alternativeTelefonlLandVorwahlOptions",
                "optionsNameFQ": "r_alternativeTelefonlLandVorwahlOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "ansprechperson-edit.r_alternativetelefonnummer_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.AlternativTelefon",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.AlternativTelefon",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "telefon",
                    "parameters": []
                }
            }],
            "id": "r_alternativeTelefonNummer",
            "options": {
                "optionsName": "r_alternativeTelefonNummerOptions",
                "optionsNameFQ": "r_alternativeTelefonNummerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "ansprechperson-edit.r_alternativeemail_caption",
            "binding": {
                "dataContext": "$m_Ansprechperson",
                "bindTo": "Personendaten.AlternativEmail",
                "bindToFQ": "models.data.$m_Ansprechperson.Personendaten.AlternativEmail",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_alternativeEmail",
            "options": {
                "optionsName": "r_alternativeEmailOptions",
                "optionsNameFQ": "r_alternativeEmailOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_ansprechpersonGeschaeftspartnerListViewToolbarOptions",
                "optionsNameFQ": "r_ansprechpersonGeschaeftspartnerListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Ansprechperson"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_AnsprechpersonZuGeschaeftspartnerRel",
                "bindTo": "IdAnsprechperson",
                "bindToFQ": "models.data.$m_AnsprechpersonZuGeschaeftspartnerRel.IdAnsprechperson"
            },
            "dataModel": "$m_Ansprechperson",
            "editDataContext": "$m_AnsprechpersonZuGeschaeftspartnerEdit",
            "idEditPopup": "ansprechpersonGeschaeftspartnerEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_ansprechpersonGeschaeftspartnerListView",
            "options": {
                "optionsName": "r_ansprechpersonGeschaeftspartnerListViewOptions",
                "optionsNameFQ": "r_ansprechpersonGeschaeftspartnerListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_ansprechpersonKontaktdatenListViewToolbarOptions",
                "optionsNameFQ": "r_ansprechpersonKontaktdatenListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_AnsprechpersonKontaktElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_AnsprechpersonKontaktRel",
                "bindTo": "IdAnsprechpersonKontaktElement",
                "bindToFQ": "models.data.$m_AnsprechpersonKontaktRel.IdAnsprechpersonKontaktElement"
            },
            "dataModel": "$m_AnsprechpersonKontaktElement",
            "editDataContext": "$m_AnsprechpersonKontaktEdit",
            "idEditPopup": "ansprechpersonKontaktEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_ansprechpersonKontaktdatenListView",
            "options": {
                "optionsName": "r_ansprechpersonKontaktdatenListViewOptions",
                "optionsNameFQ": "r_ansprechpersonKontaktdatenListViewOptions"
            }
        });
        super.onConstructionFinished();
    }
}