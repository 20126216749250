import { EventAggregator } from 'aurelia-event-aggregator';
import { Benutzereinstellungen } from './../benutzereinstellungen/benutzereinstellungen';
import { DashboardButtonAktionTyp } from './../../enumerations/dashboard-button-aktion-typ';
import { NachrichtService } from './../../services/nachricht-service';
import { autoinject, bindable, observable, Scope } from "aurelia-framework";
import { LocationService } from '../../../framework/base/export';
import { SucheService } from '../../services/export';
import { FormUtilsService } from '../../../framework/forms/export';

@autoinject
export class DashboardButton {
  constructor(
    private nachrichtService: NachrichtService,
    private locationService: LocationService,
    private sucheService: SucheService,
    private eventAggregator: EventAggregator,
    private formUtilsService: FormUtilsService
  ) { }

  scope: Scope;

  @bindable item: any;

  bind(bindingContext, overrideContext) {
    this.scope = {
      bindingContext,
      overrideContext
    };
  }

  onDashboardButtonClicked() {
    switch (this.item.AktionTyp) {
      case 0: {
        return this.locationService.goTo({
          url: `${this.item.Aktion}`,
          replace: false,
          currentViewModel: this.formUtilsService.getMainForm(this.scope)
        });
      }
      case 1: {
        return this.locationService.goTo({
          url: `${this.item.Aktion}`,
          clearStack: true
        });
      }
      case 2: {
        return window.open(this.item.Aktion, "_blank");
      }
      case 3: {
        switch (this.item.Aktion) {
          case "Benutzereinstellungen": {
            this.eventAggregator.publish("benutzereinstellungen:show");
            break;
          }
          default: {
            break;
          }
        }
        break;
      }
      case 4: {
        this.sucheService.activateSuche(this.item.Aktion)
      }
      default:
        break;
    }
  }
}
