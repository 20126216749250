import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, transient } from "aurelia-framework";
import { AuthorizationService, FileService, RestService } from "../../../../../framework/base/export";
import { LocationService } from "../../../../../framework/base/services/location-service";
import { FormBase, HistoryService } from "../../../../../framework/forms/export";
import { LoginprofilService } from "../../../../../zit/services/loginprofil-service";
import { LoginService } from "../../services/login-service";
import { BrowserService } from "./../../../../../framework/base/services/browser-service";
import { LocalizationService } from "./../../../../../framework/base/services/localization-service";
import { ICommandData } from "./../../../../../framework/forms/interfaces/command-data";
import { DomaeneService } from "./../../../../services/domaene-service";

@autoinject
@transient()
export class LoginFuncs {
  private _appChangedSubscription: Subscription;
  private _form: FormBase;
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public browserService: BrowserService,
    private _authorizationService: AuthorizationService,
    private _loginprofilService: LoginprofilService,
    private _eventAggregator: EventAggregator,
    private _fileService: FileService,
    private _restService: RestService,
    private _locationService: LocationService,
    private _historyService: HistoryService,
    private _loginService: LoginService,
    private _localizationService: LocalizationService,
    private _domaeneService: DomaeneService) {
    const time = new Date()
      .getTime()
      .toString();

    const randomNumberString = Math.floor((Math.random() * 100))
      .toString();

    const randomKey = time + randomNumberString;
    this.logoSrc = this._restService.getApiUrl(`ZIT/Domaene/Logo?key=${randomKey}`);
    this.hasSms = this._domaeneService.hasSms || false;

    //Kann umgestellt werden, um Wartungen anzuzeigen
    //this.showWartung = location.host == "portal.mylehre.at";
    this.showWartung = false;
  }

  urlImpressum: string;
  farbeDomaene: string;
  bezeichnungLoginMobil: string;
  logoSrc: string;
  goToUrlAfterLogin: string;
  isMobile: boolean;
  hasSms: boolean;
  hasLoginDokButton: boolean;
  showWartung: boolean;

  loginCommand: ICommandData = {
    id: "$login",
    title: "login-form-funcs.anmelden_caption",
    execute: () => {
      const username = this.getUsername();
      if (!username) {
        DevExpress.ui.notify("Bitte erfassen Sie E-Mail, Telefonnummer oder den Benutzernamen", "ERROR", this.NOTIFY_TIMEOUT);
        return;
      }

      this.doLogin();
    }
  };
  tanAnfordernCommand: ICommandData = {
    id: "$tan-anfordern",
    title: "login-form-funcs.anmeldung-sms-tan",
    execute: async() => {
      const username = this.getUsername();
      if (!username) {
        DevExpress.ui.notify("Bitte erfassen Sie E-Mail, Telefonnummer oder den Benutzernamen", "ERROR", this.NOTIFY_TIMEOUT);
        return;
      }

      await this._restService.post({
        url: this._restService.getApiUrl("ZIT/Security/SendTan"),
        data: {
          Username: username
        },
        increaseLoadingCount: true
      });

      await this._locationService.goTo({
        url: "Login-Tan",
        clearStack: true,
        customOptions: {
          Username: username,
          goToUrlAfterLogin: this.goToUrlAfterLogin
        }
      });
    }
  };
  
  loginDokButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this._domaeneService.loginDokButtonText,
    width: "100%",
    onClick: () => {
      const url = this._fileService.getInlineUrl(this._domaeneService.dmsLinkLoginDokButton);
      window.open(url, "_blank");
    }
  };
  
  openPasswortVergessen() {
    this._locationService.goTo({
      url: "Passwort-Zuruecksetzen",
      clearStack: true
    });
  }

  openImpressumClick() {
    window.open(this.urlImpressum);
  }

  async bind(form: FormBase) {
    this._form = form;

    this.goToUrlAfterLogin = this._historyService.lastRequestUrl;

    if (this._loginService.benutzer) {
      this._form.callOnBind(() => {
        this._form.models.data.$m_login.Username = this._loginService.benutzer;
      });
    }

    this._form.focusFirstEnabledEditor = () => {
      if (this._form.models.data.$m_login.Username) {
        if (this._form["password"].instance) {
          this._form["password"].instance.focus();
        }
      } else {
        if (this._form["username"].instance) {
          this._form["username"].instance.focus();
        }
      }
    };

    this.resetLoginModel();
    this.tryLoadBenutzername();

    this._appChangedSubscription = this._eventAggregator.subscribeOnce("authorization:change-app", (args) => {
      args.isHandled = true;
    });

    this.urlImpressum = this._domaeneService.urlImpressum;
    this.farbeDomaene = this._domaeneService.farbeDomaene;
    this._domaeneService.setDomainColorsStyle();
    
    this._form["customTitle"] = this._domaeneService.loginHeadline || this._form.title;
    this._form["customSubtitle"] = this._domaeneService.loginSubHeadline;
    this.bezeichnungLoginMobil = this._domaeneService.bezeichnungMobilLogin;
    
    this.hasLoginDokButton = !!this._domaeneService.loginDokButtonText
      && !!this._domaeneService.dmsLinkLoginDokButton;
  }

  unbind() {
    this._appChangedSubscription.dispose();
  }

  private async doLogin() {
    //TODO DXUPDATE
    const model = this._form.models.data.$m_login;
    model.Username = this.getUsername();
    model.Password = this.getPassword();

    const loginSuccess = await this._authorizationService.login(this._form.models.data.$m_login);
    if (!loginSuccess) {
      return;
    }

    const hasMoreThanOne = await this._loginprofilService.hasMultipleLoginprofil();

    if (hasMoreThanOne) {
      this._locationService.goTo({
        url: "Loginprofil",
        clearStack: true
      });

      if (this.goToUrlAfterLogin) {
        this._historyService.pipelineUrl = this.goToUrlAfterLogin;
      }
    } else {
      if (this.goToUrlAfterLogin) {
        this._historyService.pipelineUrl = this.goToUrlAfterLogin;
      }

      this._authorizationService.isLoggedInChanged(true);
    }
  }
  
  private resetLoginModel() {
    this._form.models.data.$m_login = {
      StayLoggedOn: false
    };
  }
  private async tryLoadBenutzername() {
    const activationKey = this._form?.viewItemInfo?.routeInfo?.customOptions?.activationKey;
    if (!activationKey) {
      return;
    }

    const result = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Security/AktivierungErledigtBenutzername"),
      ignoreErrors: true,
      data: {
        CodeAktivierung: activationKey
      }
    });

    if (!result || !result.Benutzername) {
      return;
    }

    this._form.models.data.$m_login.Username = result.Benutzername;
  }

  private getUsername() {
    return this._form["username"]
      .instance
      .element()
      .querySelector("input")
      .value;
  }

  private getPassword() {
    return this._form["password"]
      .instance
      .element()
      .querySelector("input")
      .value;
  }
}
