import { autoinject, optional, BindingEngine } from 'aurelia-framework';
import { IToolbarModel } from '../../../framework/forms/elements/toolbar/toolbar-model';
import { IToolbarOptions, IItem } from '../../../framework/forms/elements/toolbar/export';
import { FormBase } from '../../../framework/forms/classes/form-base';
import { ObjektInfoService } from '../../../zit/services/objekt-info-service';
import { BrowserService, LocalizationService, BindingService } from '../../../framework/base/export';
import { ContextMenu, CommandService, ICommandData } from '../../../framework/forms/export';
import { Subscription } from 'aurelia-event-aggregator';
import { Share } from '../../../zit/elements/share/share';

@autoinject
export class ZitToolbar {
  private _subscriptionArr: Subscription[];

  constructor(
    private objektInfoService: ObjektInfoService,
    private browserService: BrowserService,
    private localizationService: LocalizationService,
    private commandService: CommandService,
    private bindingService: BindingService,
    private bindingEngine: BindingEngine
  ) { }

  model: IToolbarModel;
  options: IToolbarOptions;

  leftItems: IItem[] = [];
  rightItems: IItem[] = [];

  goBackItem: IItem;
  moreItem: IItem;
  moreItems: IItem[];

  activate(model: IToolbarModel) {
    this.options = model.options;

    model.optionsChanged = () => {
      this.options = model.options;
      this.updateItems(model.options);
    }

    if (!model.options) {
      return;
    }
    this.updateItems(model.options);
  }
  unbind() {
    this.removeSubscriptions();
  }

  updateItems(options: IToolbarOptions): IToolbarOptions {
    const leftItems: IItem[] = [];
    const rightItems: IItem[] = [];

    const form: FormBase = (options
      && options.scopeContainer
      && options.scopeContainer.scope
      && options.scopeContainer.scope.bindingContext
      && options.scopeContainer.scope.bindingContext instanceof FormBase
      && !options.smallToolbar)
      ? options.scopeContainer.scope.bindingContext
      : null;

    const isEditPopup = form
      && form.isEditPopup;

    let isObjektReadOrEdit = false;

    if (form) {
      const model = form.models.modelWithKeyId;
      if (model) {
        const objektInfo = this.objektInfoService.getObjektByWebApiUrl(model.webApiAction);
        if (objektInfo) {
          isObjektReadOrEdit = true;
        }
      }
    }

    options.items.forEach(item => {
      switch (item.id) {
        case "$add": {
          if (isEditPopup || isObjektReadOrEdit) {
            break;
          }

          if (item.title) {
            item.tooltip = item.title;
            item.title = null;
          }
          leftItems.push(item);
          break;
        }
        case "$save": {
          if (isEditPopup) {
            break;
          }

          if (item.title) {
            item.tooltip = item.title;
            item.title = null;
          }
          leftItems.push(item);
          break;
        }
        case "$saveAndClose": {
          if (!isEditPopup) {
            break;
          }

          if (item.title) {
            item.tooltip = item.title;
            item.title = null;
          }
          item.icon = "fas fa-save";

          rightItems.push(item);
          break;
        }
        case "$delete": {
          if (item.title) {
            item.tooltip = item.title;
            item.title = null;
          }
          item.icon = "far fa-trash-alt";

          if (isEditPopup) {
            rightItems.push(item);
          } else {
            leftItems.push(item);
          }

          break;
        }
        case "$goBack": {
          if (item.title) {
            item.tooltip = item.title;
            item.title = null;
          }
          item.icon = "fas fa-times";

          item.sortIndex = item.sortIndex + 10000;
          rightItems.push(item);
          break;
        }
        case "$close": {
          if (item.title) {
            item.tooltip = item.title;
            item.title = null;
          }

          item.sortIndex = item.sortIndex + 10000;
          rightItems.push(item);
          break;
        }
        case "$listExcelExport": {
          if (item.title) {
            item.tooltip = item.title;
            item.title = null;
          }

          leftItems.push(item);
          break;
        }
        case "file-download": {
          item.sortIndex = 1009;
          rightItems.push(item);
          break;
        }
        case "file-upload": {
          item.sortIndex = 1010;
          rightItems.push(item);
          break;
        }
        case "file-delete": {
          item.sortIndex = 1011;
          rightItems.push(item);
          break;
        }
        case "$scrollDown":
        case "$scrollUp":
        case "$saveAndAdd": {
          break;
        }
        default: {
          if (item.sortIndex >= 1000) {
            rightItems.push(item);
          } else {
            leftItems.push(item);
          }
          break;
        }
      }
    });

    leftItems.sort((a, b) => (a.sortIndex || 0) - (b.sortIndex || 0));
    rightItems.sort((a, b) => (a.sortIndex || 0) - (b.sortIndex || 0));
    this.optimizeMobile(form, leftItems, rightItems);

    this.leftItems = leftItems;
    this.rightItems = rightItems;

    return options;
  }

  onItemClick(item: IItem, event: Event) {
    const args = {
      event: event
    };

    if (!item.isVisible) {
      return;
    }
    if (!item.isEnabled) {
      return;
    }

    if (!item.execute) {
      return;
    }

    item.execute(args);
  }

  private removeSubscriptions() {
    if (!this._subscriptionArr) {
      return;
    }

    this._subscriptionArr.forEach(c => c.dispose());
    this._subscriptionArr.splice(0);
  }
  private optimizeMobile(form: FormBase, leftItems: IItem[], rightItems: IItem[]) {
    if (!this.browserService.isMobile) {
      return;
    }
    if ((<any>this.options).layout === "vertical") {
      return;
    }

    this.removeSubscriptions();
    this._subscriptionArr = [];

    let items = [...leftItems, ...rightItems];

    items.forEach(item => {
      this._subscriptionArr.push(this.bindingEngine.propertyObserver(item, "isVisible").subscribe(() => {
        this.updateItems(this.options);
      }));
      this._subscriptionArr.push(this.bindingEngine.propertyObserver(item, "isEnabled").subscribe(() => {
        this.updateItems(this.options);
      }));
    });

    items = items
      .filter(item => 
        this.commandService.isVisibleAndEnabled(<ICommandData>item)
        && !item.template);

    leftItems.splice(0);
    rightItems.splice(0);

    //Wenn es weniger als 4 Elemente sind, dann werden alle angezeigt
    if (items.length < 4) {
      rightItems.push(...items);
      return;
    }

    //Prüfen ob es ein Speichern und Schließen gibt, diese werden auch immer angezeigt
    const saveItem = items.find(item => item.id == "$save" || item.id == "$saveAndClose");
    const closeItem = items.find(item => item.id == "$goBack" || item.id == "$close");
    const kameraItem = items.find(item => item.id == "kameraCommand");
    const videoItem = items.find(item => item.id == "videoCommand");
    const gallerieItem = items.find(item => item.id == "gallerieCommand");
    const nachrichtSendenItem = items.find(item => item.id == "nachrichtErstellen");
    const navPrevCommand = items.find(item => item.id == "navPrevCommand");
    const navNextCommand = items.find(item => item.id == "navNextCommand");
    const showNachrichtenCommand = items.find(item => item.id == "showNachrichten");
    const otherItems = items.filter(item => 
      item != saveItem 
      && item != closeItem 
      && item != gallerieItem
      && item != kameraItem
      && item != videoItem
      && item != nachrichtSendenItem
      && item != navPrevCommand
      && item != navNextCommand
      && item != showNachrichtenCommand);

    //Restliche Items berechnen (2, da das Menü-Item auch eingefügt wird und damit eines weniger zur Verfügung steht)
    const rest = 2 - (saveItem ? 1 : 0) - (closeItem ? 1 : 0);

    const sortedItems = [];
    if (kameraItem) {
      sortedItems.push(kameraItem);
    }
    if (videoItem) {
      sortedItems.push(videoItem);
    }
    if (gallerieItem) {
      sortedItems.push(gallerieItem);
    }
    if (nachrichtSendenItem) {
      sortedItems.push(nachrichtSendenItem);
    }
    if (navPrevCommand) {
      sortedItems.push(navPrevCommand);
    }
    if (navNextCommand) {
      sortedItems.push(navNextCommand);
    }
    if (saveItem) {
      sortedItems.push(saveItem);
    }
    if (showNachrichtenCommand) {
      sortedItems.push(showNachrichtenCommand);
    }

    if (rest > 0) {
      sortedItems.push(...otherItems.slice(0, rest));
      otherItems.splice(0, rest);
    }

    if (otherItems.length > 0) {
      this.moreItem = {
        id: "more",
        icon: "fas fa-ellipsis-v",
        isVisible: true,
        isEnabled: true,
        execute: (e) => {
          const ctxMenu = new ContextMenu();
          this.moreItems.forEach(item => {
            if (item.id == "actionCommand"
              && form["r_share"]) {
              const share: Share = form["r_share"];
              share.loadCtxMenuItems(ctxMenu);
              return;
            }
  
            const text = item.title
              ? this.localizationService.translateOnce(item.title)
              : (item.tooltip
                ? this.localizationService.translateOnce(item.tooltip)
                : null);
  
            ctxMenu.items.push({
              text: text,
              execute: (e) => {
                item.execute({
                  event: e.event
                });
              }
            });
          });
  
          ctxMenu.show(e.event.target);
        }
      };
      this.moreItems = [...otherItems];
      sortedItems.push(this.moreItem);
    }    
    
    if (closeItem) {
      sortedItems.push(closeItem);
    }

    rightItems.push(...sortedItems);
  }
}
