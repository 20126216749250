import * as fwx from "../../../../framework/forms/form-export";
import {
    PersonenEditUtils
} from "./person-edit-utils";

@fwx.autoinject
export class PersonEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_PersonenEditUtils: PersonenEditUtils) {
        super(element, formBaseImport);
        this.id = "person-edit";
        this.title = "person-edit.person-edit_caption";
        this.addModel({
            "id": "$m_Person",
            "webApiAction": "ZIT/Objekte/Person",
            "webApiExpand": {
                'Personendaten': null
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "CanBenutzeraccountAktivieren",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "SonstigeKontaktdatenVisible",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_Bewerbung",
            "webApiAction": "ZIT/Objekte/Bewerbung",
            "webApiColumns": ["Id"],
            "webApiWhere": ["Kopf.IdPerson", {
                "isBound": true,
                "expression": "models.data.$m_Person.Id"
            }],
            "webApiOrderBy": [{
                columnName: 'Anlagedatum',
                sortOrder: 1
            }],
            "modificationInfoEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "AddBewerbungCard",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_BankverbindungElement",
            "webApiAction": "ZIT/Objekte/PersonBankverbindungElement",
            "key": "models.data.$m_Person.IdPersonBankverbindungElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BerufserfahrungElement",
            "webApiAction": "ZIT/Objekte/PersonBerufserfahrungElement",
            "key": "models.data.$m_Person.IdPersonBerufserfahrungElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_AusbildungElement",
            "webApiAction": "ZIT/Objekte/PersonAusbildungElement",
            "key": "models.data.$m_Person.IdPersonAusbildungElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BerufElement",
            "webApiAction": "ZIT/Objekte/PersonBerufElement",
            "key": "models.data.$m_Person.IdPersonBerufElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_SpracheElement",
            "webApiAction": "ZIT/Objekte/PersonSpracheElement",
            "key": "models.data.$m_Person.IdPersonSpracheElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_FuehrerscheinElement",
            "webApiAction": "ZIT/Objekte/PersonFuehrerscheinElement",
            "key": "models.data.$m_Person.IdPersonFuehrerscheinElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonAdresseRel",
            "webApiAction": "ZIT/Objekte/PersonAdresseElement",
            "webApiExpand": {
                'Adresstyp': null,
                'Land': null
            },
            "keyProperty": "Id",
            "allowNew": "true",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonAdresseEdit",
            "webApiAction": "ZIT/Objekte/PersonAdresseElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonKontaktRel",
            "webApiAction": "ZIT/Objekte/PersonKontaktElement",
            "webApiExpand": {
                'Kontakttyp': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonKontaktEdit",
            "webApiAction": "ZIT/Objekte/PersonKontaktElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonBankverbindungRel",
            "webApiAction": "ZIT/Objekte/PersonBankverbindung",
            "webApiExpand": {
                'Waehrung': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonBankverbindungEdit",
            "webApiAction": "ZIT/Objekte/PersonBankverbindung",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonBerufserfahrungRel",
            "webApiAction": "ZIT/Objekte/PersonBerufserfahrung",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonBerufserfahrungEdit",
            "webApiAction": "ZIT/Objekte/PersonBerufserfahrung",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonAusbildungRel",
            "webApiAction": "ZIT/Objekte/PersonAusbildung",
            "webApiExpand": {
                'Ausbildung': null,
                'AusbildungsortOrder': null,
                'AusbildungAbschluss': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonAusbildungEdit",
            "webApiAction": "ZIT/Objekte/PersonAusbildung",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonBerufRel",
            "webApiAction": "ZIT/Objekte/PersonBeruf",
            "webApiExpand": {
                'Beruf': null,
                'BerufQualifikation': null,
                'AusbildungAbschluss': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonBerufEdit",
            "webApiAction": "ZIT/Objekte/PersonBeruf",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonSpracheRel",
            "webApiAction": "ZIT/Objekte/PersonSprache",
            "webApiExpand": {
                'Sprache': null,
                'Sprachniveau': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonSpracheEdit",
            "webApiAction": "ZIT/Objekte/PersonSprache",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonFuehrerscheinRel",
            "webApiAction": "ZIT/Objekte/PersonFuehrerschein",
            "webApiExpand": {
                'Fuehrerscheinklasse': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonFuehrerscheinEdit",
            "webApiAction": "ZIT/Objekte/PersonFuehrerschein",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonSkillRel",
            "webApiAction": "ZIT/Objekte/PersonSkillElement",
            "webApiExpand": {
                'Skill': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonSkillEdit",
            "webApiAction": "ZIT/Objekte/PersonSkillElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_DokumentRel",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "webApiExpand": {
                'Informationstyp': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_DokumentEdit",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonGeschaeftspartnerEinsatzRel",
            "webApiAction": "ZIT/Objekte/PersonGeschaeftspartnerEinsatzElement",
            "webApiExpand": {
                'Geschaeftspartner': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonGeschaeftspartnerEinsatzEdit",
            "webApiAction": "ZIT/Objekte/PersonGeschaeftspartnerEinsatzElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_PersonenEditUtils.actionCommand",
                "bindToFQ": "functions.$f_PersonenEditUtils.actionCommand"
            }
        });
        this.addEditPopup({
            "idContent": "personAdresseEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonAdresseEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonAdresseEdit.Id"
                }
            }],
            "id": "personAdresseEditPopup",
            "options": {
                "optionsName": "personAdresseEditPopupOptions",
                "optionsNameFQ": "personAdresseEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "personBankverbindungEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonBankverbindungEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonBankverbindungEdit.Id"
                }
            }],
            "id": "personBankverbindungEditPopup",
            "options": {
                "optionsName": "personBankverbindungEditPopupOptions",
                "optionsNameFQ": "personBankverbindungEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "personBerufserfahrungEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonBerufserfahrungEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonBerufserfahrungEdit.Id"
                }
            }],
            "id": "personBerufserfahrungEditPopup",
            "options": {
                "optionsName": "personBerufserfahrungEditPopupOptions",
                "optionsNameFQ": "personBerufserfahrungEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "personGeschaeftspartnerEinsatzEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonGeschaeftspartnerEinsatzEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonGeschaeftspartnerEinsatzEdit.Id"
                }
            }],
            "id": "personGeschaeftspartnerEinsatzEditPopup",
            "options": {
                "optionsName": "personGeschaeftspartnerEinsatzEditPopupOptions",
                "optionsNameFQ": "personGeschaeftspartnerEinsatzEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "personFuehrerscheinEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonFuehrerscheinEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonFuehrerscheinEdit.Id"
                }
            }],
            "id": "personFuehrerscheinEditPopup",
            "options": {
                "optionsName": "personFuehrerscheinEditPopupOptions",
                "optionsNameFQ": "personFuehrerscheinEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "personSkillEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonSkillEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonSkillEdit.Id"
                }
            }],
            "id": "personSkillEditPopup",
            "options": {
                "optionsName": "personSkillEditPopupOptions",
                "optionsNameFQ": "personSkillEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "personBerufEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonBerufEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonBerufEdit.Id"
                }
            }],
            "id": "personBerufEditPopup",
            "options": {
                "optionsName": "personBerufEditPopupOptions",
                "optionsNameFQ": "personBerufEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "personSpracheEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonSpracheEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonSpracheEdit.Id"
                }
            }],
            "id": "personSpracheEditPopup",
            "options": {
                "optionsName": "personSpracheEditPopupOptions",
                "optionsNameFQ": "personSpracheEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "personAusbildungEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonAusbildungEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonAusbildungEdit.Id"
                }
            }],
            "id": "personAusbildungEditPopup",
            "options": {
                "optionsName": "personAusbildungEditPopupOptions",
                "optionsNameFQ": "personAusbildungEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "personKontaktEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonKontaktEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonKontaktEdit.Id"
                }
            }],
            "id": "personKontaktEditPopup",
            "options": {
                "optionsName": "personKontaktEditPopupOptions",
                "optionsNameFQ": "personKontaktEditPopupOptions"
            },
            "height": "350px",
            "width": "500px",
            "commands": []
        });
        this.addFunction("$f_PersonenEditUtils", $f_PersonenEditUtils, "functions.$f_PersonenEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "geschlecht",
            "filters": [],
            "caption": "person-edit.r_geschlecht_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Geschlecht",
                "bindToFQ": "models.data.$m_Person.Personendaten.Geschlecht",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschlecht",
            "options": {
                "optionsName": "r_geschlechtOptions",
                "optionsNameFQ": "r_geschlechtOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "person-edit.r_titelvorne_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.TitelVorne",
                "bindToFQ": "models.data.$m_Person.Personendaten.TitelVorne",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_titelVorne",
            "options": {
                "optionsName": "r_titelVorneOptions",
                "optionsNameFQ": "r_titelVorneOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "person-edit.r_titelhinten_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.TitelHinten",
                "bindToFQ": "models.data.$m_Person.Personendaten.TitelHinten",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_titelHinten",
            "options": {
                "optionsName": "r_titelHintenOptions",
                "optionsNameFQ": "r_titelHintenOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-edit.r_vorname_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Vorname",
                "bindToFQ": "models.data.$m_Person.Personendaten.Vorname",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_vorname",
            "options": {
                "optionsName": "r_vornameOptions",
                "optionsNameFQ": "r_vornameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-edit.r_nachname_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Nachname",
                "bindToFQ": "models.data.$m_Person.Personendaten.Nachname",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_nachname",
            "options": {
                "optionsName": "r_nachnameOptions",
                "optionsNameFQ": "r_nachnameOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "person-edit.r_geburtsdatum_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Geburtsdatum",
                "bindToFQ": "models.data.$m_Person.Personendaten.Geburtsdatum",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geburtsdatum",
            "options": {
                "optionsName": "r_geburtsdatumOptions",
                "optionsNameFQ": "r_geburtsdatumOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "staat",
            "filters": [],
            "caption": "person-edit.r_idnationalitaet_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.IdNationalitaet",
                "bindToFQ": "models.data.$m_Person.Personendaten.IdNationalitaet",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_idNationalitaet",
            "options": {
                "optionsName": "r_idNationalitaetOptions",
                "optionsNameFQ": "r_idNationalitaetOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "familienstand",
            "filters": [],
            "caption": "person-edit.r_familienstand_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.IdFamilienstand",
                "bindToFQ": "models.data.$m_Person.Personendaten.IdFamilienstand",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_familienstand",
            "options": {
                "optionsName": "r_familienstandOptions",
                "optionsNameFQ": "r_familienstandOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 4,
            "caption": "person-edit.r_svnr_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.SVNr",
                "bindToFQ": "models.data.$m_Person.Personendaten.SVNr",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "svnr",
                    "parameters": []
                }
            }],
            "id": "r_svNr",
            "options": {
                "optionsName": "r_svNrOptions",
                "optionsNameFQ": "r_svNrOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 20,
            "caption": "person-edit.r_ahvnrliechtenstein_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.AHVNrLiechtenstein",
                "bindToFQ": "models.data.$m_Person.Personendaten.AHVNrLiechtenstein",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "ahvnr",
                    "parameters": []
                }
            }],
            "id": "r_ahvNrLiechtenstein",
            "options": {
                "optionsName": "r_ahvNrLiechtensteinOptions",
                "optionsNameFQ": "r_ahvNrLiechtensteinOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 20,
            "caption": "person-edit.r_ahvnrschweiz_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.AHVNrSchweiz",
                "bindToFQ": "models.data.$m_Person.Personendaten.AHVNrSchweiz",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "ahvnr",
                    "parameters": []
                }
            }],
            "id": "r_ahvNrSchweiz",
            "options": {
                "optionsName": "r_ahvNrSchweizOptions",
                "optionsNameFQ": "r_ahvNrSchweizOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "groesseSchuh",
            "filters": [],
            "caption": "person-edit.r_schuhgroesse_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.IdSchuhgroesse",
                "bindToFQ": "models.data.$m_Person.Personendaten.IdSchuhgroesse",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_schuhgroesse",
            "options": {
                "optionsName": "r_schuhgroesseOptions",
                "optionsNameFQ": "r_schuhgroesseOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "groesseKleider",
            "filters": [],
            "caption": "person-edit.r_kleidergroesse_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.IdKleidergroesse",
                "bindToFQ": "models.data.$m_Person.Personendaten.IdKleidergroesse",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_kleidergroesse",
            "options": {
                "optionsName": "r_kleidergroesseOptions",
                "optionsNameFQ": "r_kleidergroesseOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "person-edit.r_staplerfuehrerschein_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.HasStaplerfuehrerschein",
                "bindToFQ": "models.data.$m_Person.Personendaten.HasStaplerfuehrerschein",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_staplerfuehrerschein",
            "options": {
                "optionsName": "r_staplerfuehrerscheinOptions",
                "optionsNameFQ": "r_staplerfuehrerscheinOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 0,
            "acceptType": "image/*",
            "height": "250px",
            "showToolbar": true,
            "showClearButton": false,
            "caption": "person-edit.r_dmslinkfoto_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.DMSLinkFoto",
                "bindToFQ": "models.data.$m_Person.Personendaten.DMSLinkFoto",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_dmsLinkFoto",
            "options": {
                "optionsName": "r_dmsLinkFotoOptions",
                "optionsNameFQ": "r_dmsLinkFotoOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "caption": "person-edit.r_bemerkung_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Bemerkung",
                "bindToFQ": "models.data.$m_Person.Personendaten.Bemerkung",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [],
            "id": "r_bemerkung",
            "options": {
                "optionsName": "r_bemerkungOptions",
                "optionsNameFQ": "r_bemerkungOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-edit.r_email_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Email",
                "bindToFQ": "models.data.$m_Person.Personendaten.Email",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Person.Personendaten && (!models.data.$m_Person.Personendaten.Mobil || !models.data.$m_Person.Personendaten.MobilLandvorwahl)"
                    }]
                }
            }],
            "id": "r_email",
            "options": {
                "optionsName": "r_emailOptions",
                "optionsNameFQ": "r_emailOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "landvorwahl",
            "filters": [],
            "caption": "person-edit.r_mobillandvorwahl_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.MobilLandvorwahl",
                "bindToFQ": "models.data.$m_Person.Personendaten.MobilLandvorwahl",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Person.Personendaten && models.data.$m_Person.Personendaten.Mobil"
                    }]
                }
            }, {
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Person.Personendaten && !models.data.$m_Person.Personendaten.Email"
                    }]
                }
            }],
            "id": "r_mobilLandvorwahl",
            "options": {
                "optionsName": "r_mobilLandvorwahlOptions",
                "optionsNameFQ": "r_mobilLandvorwahlOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-edit.r_mobil_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Personendaten.Mobil",
                "bindToFQ": "models.data.$m_Person.Personendaten.Mobil",
                "propertyPrefix": "Personendaten"
            },
            "validationRules": [{
                "item": {
                    "type": "telefon",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Person.Personendaten && !models.data.$m_Person.Personendaten.Email"
                    }]
                }
            }],
            "id": "r_mobil",
            "options": {
                "optionsName": "r_mobilOptions",
                "optionsNameFQ": "r_mobilOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personAdresseListViewToolbarOptions",
                "optionsNameFQ": "r_personAdresseListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Person"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonAdresseRel",
                "bindTo": "IdPerson",
                "bindToFQ": "models.data.$m_PersonAdresseRel.IdPerson"
            },
            "dataModel": "$m_Person",
            "editDataContext": "$m_PersonAdresseEdit",
            "idEditPopup": "personAdresseEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personAdresseListView",
            "options": {
                "optionsName": "r_personAdresseListViewOptions",
                "optionsNameFQ": "r_personAdresseListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personAusbildungListViewToolbarOptions",
                "optionsNameFQ": "r_personAusbildungListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_AusbildungElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonAusbildungRel",
                "bindTo": "IdPersonAusbildungElement",
                "bindToFQ": "models.data.$m_PersonAusbildungRel.IdPersonAusbildungElement"
            },
            "dataModel": "$m_AusbildungElement",
            "editDataContext": "$m_PersonAusbildungEdit",
            "idEditPopup": "personAusbildungEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personAusbildungListView",
            "options": {
                "optionsName": "r_personAusbildungListViewOptions",
                "optionsNameFQ": "r_personAusbildungListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personBankverbindungListViewToolbarOptions",
                "optionsNameFQ": "r_personBankverbindungListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_BankverbindungElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonBankverbindungRel",
                "bindTo": "IdPersonBankverbindungElement",
                "bindToFQ": "models.data.$m_PersonBankverbindungRel.IdPersonBankverbindungElement"
            },
            "dataModel": "$m_BankverbindungElement",
            "editDataContext": "$m_PersonBankverbindungEdit",
            "idEditPopup": "personBankverbindungEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personBankverbindungListView",
            "options": {
                "optionsName": "r_personBankverbindungListViewOptions",
                "optionsNameFQ": "r_personBankverbindungListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personBerufserfahrungListViewToolbarOptions",
                "optionsNameFQ": "r_personBerufserfahrungListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_BerufserfahrungElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonBerufserfahrungRel",
                "bindTo": "IdPersonBerufserfahrungElement",
                "bindToFQ": "models.data.$m_PersonBerufserfahrungRel.IdPersonBerufserfahrungElement"
            },
            "dataModel": "$m_BerufserfahrungElement",
            "editDataContext": "$m_PersonBerufserfahrungEdit",
            "idEditPopup": "personBerufserfahrungEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personBerufserfahrungListView",
            "options": {
                "optionsName": "r_personBerufserfahrungListViewOptions",
                "optionsNameFQ": "r_personBerufserfahrungListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personBerufListViewToolbarOptions",
                "optionsNameFQ": "r_personBerufListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_BerufElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonBerufRel",
                "bindTo": "IdPersonBerufElement",
                "bindToFQ": "models.data.$m_PersonBerufRel.IdPersonBerufElement"
            },
            "dataModel": "$m_BerufElement",
            "editDataContext": "$m_PersonBerufEdit",
            "idEditPopup": "personBerufEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personBerufListView",
            "options": {
                "optionsName": "r_personBerufListViewOptions",
                "optionsNameFQ": "r_personBerufListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personFuehrerscheinListViewToolbarOptions",
                "optionsNameFQ": "r_personFuehrerscheinListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_FuehrerscheinElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonFuehrerscheinRel",
                "bindTo": "IdPersonFuehrerscheinElement",
                "bindToFQ": "models.data.$m_PersonFuehrerscheinRel.IdPersonFuehrerscheinElement"
            },
            "dataModel": "$m_FuehrerscheinElement",
            "editDataContext": "$m_PersonFuehrerscheinEdit",
            "idEditPopup": "personFuehrerscheinEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personFuehrerscheinListView",
            "options": {
                "optionsName": "r_personFuehrerscheinListViewOptions",
                "optionsNameFQ": "r_personFuehrerscheinListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personKontaktdatenListViewToolbarOptions",
                "optionsNameFQ": "r_personKontaktdatenListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Person"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonKontaktRel",
                "bindTo": "IdPerson",
                "bindToFQ": "models.data.$m_PersonKontaktRel.IdPerson"
            },
            "dataModel": "$m_Person",
            "editDataContext": "$m_PersonKontaktEdit",
            "idEditPopup": "personKontaktEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personKontaktdatenListView",
            "options": {
                "optionsName": "r_personKontaktdatenListViewOptions",
                "optionsNameFQ": "r_personKontaktdatenListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personSpracheListViewToolbarOptions",
                "optionsNameFQ": "r_personSpracheListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_SpracheElement"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonSpracheRel",
                "bindTo": "IdPersonSpracheElement",
                "bindToFQ": "models.data.$m_PersonSpracheRel.IdPersonSpracheElement"
            },
            "dataModel": "$m_SpracheElement",
            "editDataContext": "$m_PersonSpracheEdit",
            "idEditPopup": "personSpracheEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personSpracheListView",
            "options": {
                "optionsName": "r_personSpracheListViewOptions",
                "optionsNameFQ": "r_personSpracheListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personSkillListViewToolbarOptions",
                "optionsNameFQ": "r_personSkillListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Person"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonSkillRel",
                "bindTo": "IdPerson",
                "bindToFQ": "models.data.$m_PersonSkillRel.IdPerson"
            },
            "dataModel": "$m_Person",
            "editDataContext": "$m_PersonSkillEdit",
            "idEditPopup": "personSkillEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personSkillListView",
            "options": {
                "optionsName": "r_personSkillListViewOptions",
                "optionsNameFQ": "r_personSkillListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "optionsToolbar": {
                "optionsName": "r_personGeschaeftspartnerEinsatzListViewToolbarOptions",
                "optionsNameFQ": "r_personGeschaeftspartnerEinsatzListViewToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Person"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonGeschaeftspartnerEinsatzRel",
                "bindTo": "IdPerson",
                "bindToFQ": "models.data.$m_PersonGeschaeftspartnerEinsatzRel.IdPerson"
            },
            "dataModel": "$m_Person",
            "editDataContext": "$m_PersonGeschaeftspartnerEinsatzEdit",
            "idEditPopup": "personGeschaeftspartnerEinsatzEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personGeschaeftspartnerEinsatzListView",
            "options": {
                "optionsName": "r_personGeschaeftspartnerEinsatzListViewOptions",
                "optionsNameFQ": "r_personGeschaeftspartnerEinsatzListViewOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "col-xs-12 col-sm-6",
            "useDefaultListItemStyle": false,
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_personBewerbungListViewToolbarOptions",
                "optionsNameFQ": "r_personBewerbungListViewToolbarOptions"
            },
            "caption": "person-edit.r_personbewerbunglistview_caption",
            "binding": {
                "dataContext": "$m_Bewerbung"
            },
            "dataModel": "$m_Bewerbung",
            "pageSize": 2,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personBewerbungListView",
            "options": {
                "optionsName": "r_personBewerbungListViewOptions",
                "optionsNameFQ": "r_personBewerbungListViewOptions"
            }
        });
        super.onConstructionFinished();
    }
}