import { BenutzerTyp } from './../../../enumerations/benutzer-typ';
import { autoinject, transient, TaskQueue } from 'aurelia-framework';
import { FormBase } from './../../../../framework/forms/classes/form-base';
import { IZitTagOptions } from '../../../../zit-ui/elements/zit-tag/export';
import { FileService, LocalizationService, BrowserService, RestService } from '../../../../framework/base/services/export';
import { ListView } from '../../../../framework/forms/elements/list-view/export';
import { StartupService } from './../../../services/export';
import { IdxSelectBoxComponent } from '../../../interfaces/export';
import { DateiAuswaehlen } from '../../../elements/datei-auswaehlen/datei-auswaehlen';
import { ICommandData, PopupInfoService } from '../../../../framework/forms/export';
import { Subscription, EventAggregator } from 'aurelia-event-aggregator';

@autoinject
@transient()
export class DokumentEditUtils {
  private _dokumentVersionChangedSubscription: Subscription;

  constructor(
    private _fileService: FileService,
    private _localizationService: LocalizationService,
    private _startupService: StartupService,
    private _browserService: BrowserService,
    private _taskQueue: TaskQueue,
    private _popupInfoService: PopupInfoService,
    private _eventAggregator: EventAggregator,
    private _restService: RestService
  ) { }

  form: FormBase;
  
  isMobil: boolean = this._browserService.isMobile;
  showExtended: boolean = false;
  informationtypFilter: any = [["NurBeiDokumentenuploadNutzbar", true]];
  showDokumentZustand: boolean = this._startupService.startupInfo.Benutzer.Benutzertyp === BenutzerTyp.NatuerlichePerson;

  gueltigVonValidationRule: DevExpress.ui.CustomRule = {
    type: "custom",
    reevaluate: true,
    message: this._localizationService.translateOnce("forms.validator_required", [this._localizationService.translateOnce("dokumente-edit.r_gueltigvon_caption")]),
    validationCallback: (e) => {
      const isRequired = this.form.models.data.$m_InfoTyp != void 0
        && this.form.models.data.$m_InfoTyp.IsGueltigVonPflicht == true

      return isRequired
        ? e.value != void 0
        : true;
    }
  };
  gueltigBisValidationRule: DevExpress.ui.CustomRule = {
    type: "custom",
    reevaluate: true,
    message: this._localizationService.translateOnce("forms.validator_required", [this._localizationService.translateOnce("dokumente-edit.r_gueltigbis_caption")]),
    validationCallback: (e) => {
      const isRequired = this.form.models.data.$m_InfoTyp != void 0
        && this.form.models.data.$m_InfoTyp.IsGueltigBisPflicht == true

      return isRequired
        ? e.value != void 0
        : true;
    }
  };

  dokumentTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    icon: {
      faIconExpression: `item.Zustand ? item.Zustand.TagSymbol : ''`,
      textExpression: `!item.Zustand || !item.Zustand.TagSymbol ? 'D':''`
    },
    textExpression: `(item.VorlageBezeichnung || item.Dateiname || item.Bezeichnung) | zitRestrictLength:50`,
    backgroundColorExpression: "item.InformationsTyp.FarbeTagText",
    infoList: [
      {
        isVisibleExpression: `item.DMSLinkDokument`,
        isClickEnabled: true,
        icon: {
          faIcon: "fas fa-paperclip",
        },
        onClick: (e, data) => {
          e.stopPropagation();
          this._fileService.download(data.DMSLinkDokument);
        }
      }, {
        isVisibleExpression: "item.Id > 0 && ".concat(
          this._startupService.startupInfo.Benutzer.CanViewDokumentDetails ? "true" : "false"          
        ),
        icon: {
          faIcon: "far fa-clock"
        },
        onClick: (e, data) => {
          e.stopPropagation();
          this.form.editPopups.show("dokumentVersionInformationEditPopup", null);
        }
      }
    ],
    onClick: (e, data) => {
      e.stopPropagation();
      this._fileService.inline(data.DMSLinkDokument);
    }
  };
  unterfertigungInformationTagOptions: IZitTagOptions = {
    cssClass: "z--tag-info-message z--tag-info-warning-message",
    icon: {
      faIcon: "fas fa-info-circle",
    },
    textExpression: `"dokument-utils.unterfertigung-info" | tr`
  };

  kameraCommand: ICommandData = {
    id: "kameraCommand",
    icon: "fas fa-camera",
    tooltip: "dokument-utils.kamera",
    isVisibleExpression: "functions.$f_DokumentUtils.isMobil && !models.data.$m_Dokument.Id",
    execute: (args) => {
      this.getDateiAuswaehlen().onMakePhoto();
    }
  };
  videoCommand: ICommandData = {
    id: "videoCommand",
    icon: "fas fa-video",
    tooltip: "dokument-utils.video",
    isVisibleExpression: "functions.$f_DokumentUtils.isMobil && !models.data.$m_Dokument.Id",
    execute: (args) => {
      this.getDateiAuswaehlen().onMakeVideo();
    }
  };
  gallerieCommand: ICommandData = {
    id: "gallerieCommand",
    icon: "far fa-image",
    tooltip: "dokument-utils.gallerie",
    isVisibleExpression: "functions.$f_DokumentUtils.isMobil && !models.data.$m_Dokument.Id",
    execute: (args) => {
      this.getDateiAuswaehlen().onOpenGallery();
    }
  };
  dateiCommand: ICommandData = {
    id: "dateiCommand",
    icon: "far fa-file-alt",
    tooltip: "dokument-utils.datei-auswaehlen",
    isVisibleExpression: "!functions.$f_DokumentUtils.isMobil && !models.data.$m_Dokument.Id",
    execute: (args) => {
      this.getDateiAuswaehlen().onOpenFiles();
    }
  };
  showExtendedCommand: ICommandData = {
    id: "showExtendedCommand",
    icon: "fas fa-ellipsis-v",
    tooltip: "dokument-utils.weitere-details",
    isVisibleExpression: "!functions.$f_DokumentUtils.isMobil",
    execute: (args) => {
      this.showExtended = !this.showExtended;

      this._taskQueue.queueMicroTask(() => {
        const current = this._popupInfoService.getPopup(this.form);
        if (current && current.popup) {
          current.popup.repaint();
        }
      });
    }
  };

  bind(form: FormBase) {
    this.form = form;
    
    form.models.onLoaded.register((args) => {
      if (args.model.id == "$m_Dokument" && args.data) {
        if (this.form.variables.data.$v_idStelleninserat != void 0) {
          this.form.models.data.$m_Dokument.IdStelleninserat = this.form.variables.data.$v_idStelleninserat;
        }
        if (!args.data.Id) {
          if (form.variables.data.$v_dmsLinkDokument && form.variables.data.$v_dateiname) {
            args.data.DMSLinkDokument = form.variables.data.$v_dmsLinkDokument;
            args.data.Dateiname = form.variables.data.$v_dateiname;
            this.checkSchluesselbegriffe();
          } else {
            this._taskQueue.queueTask(() => {
              if (this.isMobil) {
                this.getDateiAuswaehlen().onOpenGallery();
              } else {
                this.getDateiAuswaehlen().onOpenFiles();
              }
            });
          }
        }

        this.checkInformationstypAllowTypes();
      }
      return Promise.resolve();
    });
    form.onValidating.register(args => {
      if (!form.models.data.$m_Dokument.DMSLinkDokument) {
        args.validationResult.isValid = false;
        args.validationResult.messages.push("Bitte ein Dokument auswählen");
      }

      return Promise.resolve();
    });
    form.onSaved.register(args => {
      this._eventAggregator.publish("dokument:updated", {
        idObjekt: form.models.data.$m_Dokument.IdObjekt
      });

      return Promise.resolve();
    });
    form.onDeleted.register(args => {
      this._eventAggregator.publish("dokument:updated", {
        idObjekt: form.models.data.$m_Dokument.IdObjekt
      });

      return Promise.resolve();
    });
    form.editPopups.onEditPopupHidden.register((args) => {
      if (args.editPopup.id === "dokumentVersionInformationEditPopup") {
        this.refreshDokumenteListView();
      }
      return Promise.resolve();
    });

    this._dokumentVersionChangedSubscription = this._eventAggregator.subscribe("dokument:version-changed", (e) => {
      if (this.form.variables.data.$id == e.oldId) {
        this.form.variables.data.$id = e.newId;
      }
    });
  }
  unbind() {
    this._dokumentVersionChangedSubscription.dispose();
    this._dokumentVersionChangedSubscription = null;
  }

  async onDateiAusgewaehlt(e: CustomEvent) {
    const file: File = e.detail.files[0];
    const model = this.form.models.data.$m_Dokument;

    const dmsLinkDokument = await this._fileService.upload(file);
    if (!dmsLinkDokument) {
      return;
    }

    model.Dateiname = file.name;
    model.DMSLinkDokument = dmsLinkDokument;
    this.checkSchluesselbegriffe();
  }

  private checkInformationstypAllowTypes() {
    const allowTypes: string = this.form.variables.data.$v_allowTypes;
    const isFiltered = this.informationtypFilter.length > 1;
    
    if (!allowTypes || isFiltered) {
      return;
    }

    const typeList = allowTypes.split(",");
    typeList.forEach(t =>
      this.informationtypFilter.push(["Code", t])
    );

    const selectbox: IdxSelectBoxComponent = this.form["r_dokumentInformationstyp"];
    const dataSource = selectbox.instance.getDataSource();
    dataSource.filter(this.informationtypFilter);
  }
  private async checkSchluesselbegriffe() {
    const model = this.form.models.data.$m_Dokument;
    if (!model) {
      return;
    }
    if (!model.Dateiname) {
      return;
    }
    if (model.IdInformationstyp) {
      return;
    }

    const r = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Dokument/EvalDokumentInformationstyp"),
      data: {
        ObjektTypeFullName: this.form.variables.data.$v_objektTypeFullname,
        Dateiname: model.Dateiname,
        Codes: this.form.variables.data.$v_allowTypes
      }
    });
    
    if (!r || !r.Id) {
      return;
    }

    model.IdInformationstyp = r.Id;
  }
  private refreshDokumenteListView() {
    const listView: ListView = this.form["dokumentListView"];
    if (!listView) {
      return;
    }

    listView.refresh();
  }
  private getDateiAuswaehlen(): DateiAuswaehlen {
    return this.form["r_dateiAuswaehlen"];
  }
}
