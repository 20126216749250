import { IdxTextAreaComponent } from "./../../../interfaces/dx-components";
import { StartupService } from "./../../../services/startup-service";
import { RestService } from "./../../../../framework/base/services/rest-service";
import { IEditorValueChangedEventArgs } from "./../../../../framework/forms/event-args/editor-value-changed";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, computedFrom, transient, TaskQueue } from "aurelia-framework";
import { FormBase } from "./../../../../framework/forms/classes/form-base";
import { GlobalizationService } from "../../../../framework/base/services/globalization-service";
import { IZitTagOptions } from "../../../../zit-ui/elements/zit-tag/export";
import { ICommandData, ModelUtilsService } from "../../../../framework/forms/export";
import { Share } from "../../../elements/share/share";
import { FormPopupService } from "../../../services/form-popup-service";
import { LocalizationService, LocationService } from "../../../../framework/base/export";

import * as moment from "moment";

@autoinject
@transient()
export class StelleninseratEditUtils {
  private _reloadModelSubscription: Subscription;
  private _stelleninseratKopfElementChannelSaved: Subscription;
  private ID_MANDANT_HEARTBASE = 5;
  private _reloadAfterStelleninseratKopfChannelEditHidden: boolean;

  constructor(
    public startupService: StartupService,
    private _restService: RestService,
    private _globalizationService: GlobalizationService,
    private _taskQueue: TaskQueue,
    private _eventAggregator: EventAggregator,
    private _formPopupService: FormPopupService,
    private _localizationService: LocalizationService,
    private _modelUtilsService: ModelUtilsService,
    private _locationService: LocationService) {
    this.pvTextModel = {
      Kopfzeile1: "",
      Fusszeile1: ""
    };
  }

  form: FormBase;
  pvTextModel: any;
  hasPackage: boolean = true;
  bewerbungenListViewCaption: string;
  isGeschaeftspartnerSchule: boolean;

  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };
  bewerbungstypTextAreaOptions: DevExpress.ui.dxTextAreaOptions = {
    height: "75px",
    readOnly: true
  };

  stelleninseratGruppeTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    textExpression: "item.Text | zitRestrictLength:50",
    icon: {
      textExpression: "item.Gruppentyp.Bezeichnung ? item.Gruppentyp.Bezeichnung[0] : 'G'"
    }
  };

  ferialbewerbungEinsatzbereichTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    textExpression: "item.Einsatzbereich",
    icon: {
      text: "EB"
    }
  };

  stelleninseratSchnupperterminTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    textExpression: "item.SchnupperterminDatum | zitMoment:'wochenTag'",
    icon: {
      text: "T"
    }
  };
  
  stelleninseratKlasseTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    textExpression: "item.Bezeichnung",
    icon: {
      text: "K"
    }
  };

  geschlechtM_W_D_Options: DevExpress.ui.dxTextBoxOptions = {
    readOnly: true,
    value: "(m/w/d)",
    width: 60
  };

  keinPackageRule = {
    type: "custom",
    message: "Firmenwebsite hat kein gültiges Package",
    reevaluate: true,
    validationCallback: () => {
      return false;
    }
  };

  bewerbungVonValidationRule = {
    type: "custom",
    reevaluate: true,
    isValid: true,
    message: "",
    validationCallback: (e) => {
      if (!e.value) {
        return true;
      }

      const model = this.form.models.data.$m_Stelleninserat;
      if (!model || !model.Kopf) {
        return true;
      }

      const gueltigVon = model.Kopf.GueltigVon;
      const gueltigBis = model.Kopf.GueltigBis;

      if (gueltigVon && e.value < gueltigVon) {
        this.bewerbungVonValidationRule.message = "Datum muss größer gleich " + this._globalizationService.format(gueltigVon, "d") + " sein";
        return false;
      }
      if (gueltigBis && e.value > gueltigBis) {
        this.bewerbungVonValidationRule.message = "Datum muss kleiner gleich " + this._globalizationService.format(gueltigBis, "d") + " sein";
        return false;
      }

      return true;
    }
  };
  bewerbungBisValidationRule = {
    type: "custom",
    reevaluate: true,
    isValid: true,
    message: "",
    validationCallback: (e) => {
      if (!e.value) {
        return true;
      }

      const model = this.form.models.data.$m_Stelleninserat;
      if (!model || !model.Kopf) {
        return true;
      }

      const gueltigVon = model.Kopf.GueltigVon;
      const gueltigBis = model.Kopf.GueltigBis;

      if (gueltigVon && e.value < gueltigVon) {
        this.bewerbungBisValidationRule.message = "Datum muss größer gleich " + this._globalizationService.format(gueltigVon, "d") + " sein";
        return false;
      }
      if (gueltigBis && e.value > gueltigBis) {
        this.bewerbungBisValidationRule.message = "Datum muss kleiner gleich " + this._globalizationService.format(gueltigBis, "d") + " sein";
        return false;
      }

      return true;
    }
  };

  trainerTagOptions: IZitTagOptions = {
    icon: {
      textExpression: "item.Ansprechperson.FunktionTagText"
    },
    textExpression: "item.Ansprechperson.Titel",
    infoList: [{
      backgroundColorExpression: "item.IsGueltig ? 'green' : 'red'",
      color: "transparent",
      icon: {
        faIcon: "fas fa-circle"
      }
    }, {
      icon: {
        faIcon: "fas fa-pencil-alt"
      },
      onClick: (ev, data) => {
        ev.preventDefault();
        ev.stopPropagation();

        this.trainerEdit(data);
      }
    }],
    onClick: (_, data) => {
      this._locationService.goTo({
        url: `Objekte/Ansprechperson/${data.IdAnsprechperson}`,
        currentViewModel: this.form
      });
    },
    onDeleteClick: (_, data) => {
      const index = this.form.models.data.$m_Stelleninserat.Kopf.StelleninseratTrainer.indexOf(data);
      if (index < 0) {
        return;
      }

      this.form.models.data.$m_Stelleninserat.Kopf.StelleninseratTrainer.splice(index, 1);
      this._modelUtilsService.setDirty(this.form.models.data.$m_Stelleninserat);
    }
  };

  @computedFrom("form.models.data.$m_Stelleninserat._CanAddChannel")
  get canAddChannel() {
    return this.form?.models?.data?.$m_Stelleninserat?._CanAddChannel || false;
  }

  bind(form: FormBase) {
    this.form = form;

    this._reloadModelSubscription = this._eventAggregator.subscribe("bewerbung:umgebucht", (e) => {
      if (!e.IdStelleninserat || e.IdStelleninserat <= 0) {
        return;
      }

      if (!this.form.models.data.$m_Stelleninserat || this.form.models.data.$m_Stelleninserat.Id != e.IdStelleninserat) {
        return;
      }

      const model = this.form.models.getInfo("$m_Bewerbung");
      this.form.models.onLoadRequired.fire({
        model: model,
        onlyCurrentPage: true
      });
    });

    form.models.onLoaded.register((args) => {
      const textArea: IdxTextAreaComponent = this.form["r_bewerbungstypTextArea"];

      if (args.model.id === "$m_Stelleninserat") {
        if (!args.data.Id && args.data.Kopf && args.data.Kopf._IdGeschaeftspartner) {
          args.data.Kopf.IdGeschaeftspartner = args.data.Kopf._IdGeschaeftspartner;
        }

        this.changeKopfzeile1(args.data.Kopf.IdTextKopfzeile1);
        this.changeFusszeile1(args.data.Kopf.IdTextFusszeile1);

        this.bewerbungenListViewCaption = "Bewerbungen (" + args.data.AnzahlBewerber + ")";

        if (args.data.Id
          && args.data.Kopf
          && args.data.Kopf.Geschaeftspartner
          && args.data.Kopf.Geschaeftspartner.Geschaeftspartnerdaten
          && args.data.Kopf.Geschaeftspartner.Geschaeftspartnerdaten.IsGeschaeftspartnerSchule) {
          this.isGeschaeftspartnerSchule = true;
        }
      }

      if (textArea) {
        if (args.model.id === "$m_Bewerbungstyp") {
          if (args.data
            && !args.data.WebsiteTextBewerbungsbutton
            && !args.data.AusgabeWebsite) {
            textArea.setOption({ value: null });
          }

          if (args.data
            && args.data.WebsiteTextBewerbungsbutton) {
            textArea.setOption({ value: args.data.WebsiteTextBewerbungsbutton });
          }

          if (args.data
            && args.data.AusgabeWebsite
            && !args.data.WebsiteTextBewerbungsbutton) {
            textArea.setOption({ value: args.data.AusgabeWebsite });
          }
        }
      }

      return Promise.resolve();
    });

    form.models.onSaved.register(async(args) => {
      if (this.startupService.startupInfo.Mandant.IdMandant == this.ID_MANDANT_HEARTBASE) {
        if (args.data && args.data.Id && args.data.Kopf) {
          if (!args.data.Kopf.StelleninseratTrainer || args.data.Kopf.StelleninseratTrainer.length == 0) {
            setTimeout(() => {
              DevExpress.ui.notify(
                "Achtung! Es wurde kein Ausbilder hinterlegt.",
                "info",
                3000
              );
            }, 0);
          }
        }
      }
    });

    form.onEditorValueChanged.register(async(args) => {
      if (args.binding.dataContext === "$m_Stelleninserat") {
        if (args.binding.bindTo == "Kopf.IdGeschaeftspartner") {
          this.geschaeftspartnerValueChanged(args);
        }

        if (args.binding.bindTo == "Kopf.IdLehrberuf") {
          this.lehrberufValueChanged(args);
        }

        if (args.binding.bindTo == "Kopf.IdTextKopfzeile1") {
          this.textKopfzeile1ValueChanged(args);
        }

        if (args.binding.bindTo == "Kopf.IdTextFusszeile1") {
          this.textFusszeile1ValueChanged(args);
        }
      }

      return Promise.resolve();
    });

    form.editPopups.onEditPopupHidden.register(async(args) => {
      if (args.editPopup.id == "stelleninseratKopfElementChannelEditPopup") {
        if (this._reloadAfterStelleninseratKopfChannelEditHidden) {
          this._reloadAfterStelleninseratKopfChannelEditHidden = false;
          this.form.models.reloadAll();
        }
      }
    });

    this._stelleninseratKopfElementChannelSaved = this._eventAggregator.subscribe("stelleninserat-kopf-element-channel:saved", () => {
      this._reloadAfterStelleninseratKopfChannelEditHidden = true;
    });
  }
  unbind() {
    this._reloadModelSubscription.dispose();
    this._reloadModelSubscription = null;

    this._stelleninseratKopfElementChannelSaved.dispose();
    this._stelleninseratKopfElementChannelSaved = null;
  }

  async geschaeftspartnerValueChanged(args: IEditorValueChangedEventArgs) {
    if (!args.value) {
      return;
    }

    this._taskQueue.queueTask(async() => {
      const r = await this.form.rest.get({
        url: this.form.rest.getWebApiUrl("ZIT/Stammdaten/StelleninseratBewerbungstyp"),
        getOptions: {
          columns: ["Id"],
          customs: [
            { key: "CheckBenutzerrolle", value: true },
            { key: "IdGeschaeftspartner", value: args.value },
            { key: "OnlyVorbelegung", value: true }
          ]
        }
      });
      
      if (r && r.length > 0) {
        this.form.models.data.$m_Stelleninserat.Kopf.IdBewerbungstyp = r[0].Id;
      }
    });

    if (!this.form.models.data.$m_Stelleninserat.Kopf.GueltigBis) {
      const packageMaxDatumResult = await this.form.rest.get({
        url: this.form.rest.getApiUrl("ZIT/Security/GetPackageMaxDatum/" + args.value)
      });
      
      if (packageMaxDatumResult && packageMaxDatumResult.Data) {
        this.form.models.data.$m_Stelleninserat.Kopf.GueltigBis = packageMaxDatumResult.Data;
        this.hasPackage = true;
      } else {
        this.form.models.data.$m_Stelleninserat.Kopf.GueltigBis = null;
        this.hasPackage = false;
      }
    }

    if (!this.form.models.data.$m_Stelleninserat.Id && !this.form.models.data.$m_Stelleninserat.Kopf.Subheadline) {
      const geschaeftspartnerResult = await this.form.rest.get({
        url: this.form.rest.getApiUrl("data/ZIT/Objekte/Geschaeftspartner/" + args.value)
      });
      
      this.form.models.data.$m_Stelleninserat.Kopf.Subheadline = geschaeftspartnerResult.Name1;
    }
  }

  async lehrberufValueChanged(args: IEditorValueChangedEventArgs) {
    if (!args.value) {
      return;
    }

    if (this.form.models.data.$m_Stelleninserat.Kopf.StellenbezeichnungExtern
      && this.form.models.data.$m_Stelleninserat.Kopf.StellenbezeichnungExtern.length > 0) {
      return;
    }

    const lehrberufResult = await this.form.rest.get({
      url: this.form.rest.getApiUrl("data/ZIT/Stammdaten/Lehrberuf/" + args.value)
    });
    
    if (lehrberufResult && lehrberufResult.Bezeichnung && lehrberufResult.Bezeichnung.length > 0) {
      this.form.models.data.$m_Stelleninserat.Kopf.StellenbezeichnungExtern = lehrberufResult.Bezeichnung;
    }
  }

  textKopfzeile1ValueChanged(args: IEditorValueChangedEventArgs): void {
    this.changeKopfzeile1(args.value);
  }
  textFusszeile1ValueChanged(args: IEditorValueChangedEventArgs): void {
    this.changeFusszeile1(args.value);
  }

  async changeKopfzeile1(id: any) {
    if (id) {
      const pvTextResult = await this._restService.get({
        url: this._restService.getWebApiUrl("ZIT/Stammdaten/PVText/") + id,
        getOptions: {
          columns: ["Text"]
        }
      });
      
      this.pvTextModel.Kopfzeile1 = pvTextResult
        ? pvTextResult.Text
        : "";
    } else {
      this.pvTextModel.Kopfzeile1 = "";
    }
  }
  async changeFusszeile1(id: any) {
    if (id) {
      const pvTextResult = await this._restService.get({
        url: this._restService.getWebApiUrl("ZIT/Stammdaten/PVText/") + id,
        getOptions: {
          columns: ["Text"]
        }
      });
      
      this.pvTextModel.Fusszeile1 = pvTextResult
        ? pvTextResult.Text
        : "";
    } else {
      this.pvTextModel.Fusszeile1 = "";
    }
  }

  onTrainerAddClick() {
    this.trainerEdit();
  }

  private trainerEdit(d?: any) {
    const initialData: any = {
      gueltigVon: moment().startOf("day").toDate(),
      gueltigBis: moment(new Date(2099, 11, 31)).startOf("day").toDate()
    };

    if (d) {
      initialData.gueltigVon = d.GueltigVon;
      initialData.gueltigBis = d.GueltigBis;
      initialData.item = d.Ansprechperson;
    }

    this._formPopupService.formPopup.show({
      titel: this._localizationService.translateOnce("zit.trainer"),
      data: initialData,
      formDef: "ansprechpersonMitGueltigkeit",
      webApiFilters: [{
        webApiCustomKey: "IdGeschaeftspartner",
        webApiCustomValue: this.form.models.data.$m_Stelleninserat.Kopf.IdGeschaeftspartner
      }],
      onFormSubmit: (data) => {
        if (!this.form.models.data.$m_Stelleninserat.Kopf.StelleninseratTrainer) {
          this.form.models.data.$m_Stelleninserat.Kopf.StelleninseratTrainer = [];
        }

        const u = {
          IdAnsprechperson: data.item.Id,
          Ansprechperson: {
            Id: data.item.Id,
            Titel: data.item.Titel,
            FunktionTagText: data.item.FunktionTagText
          },
          GueltigVon: data.gueltigVon,
          GueltigBis: data.gueltigBis,
          IsGueltig: data.gueltigVon.getTime() <= new Date().getTime() 
            && data.gueltigBis.getTime() >= new Date().getTime()
        };

        if (d) {
          Object.assign(d, u);
        } else {
          const exists = this.form.models.data.$m_Stelleninserat.Kopf.StelleninseratTrainer
            .some((t) => t.IdAnsprechperson == data.item.Id);

          if (exists) {
            return;
          }
  
          this.form.models.data.$m_Stelleninserat.Kopf.StelleninseratTrainer.push(u);
        }
        this._modelUtilsService.setDirty(this.form.models.data.$m_Stelleninserat);
      }
    });
  }
}
