import { EventAggregator, Subscription } from "../../../../node_modules/aurelia-event-aggregator";
import { bindable, computedFrom } from "../../../../node_modules/aurelia-framework";
import { ContextMenu } from "../../../framework/forms/classes/context-menu";
import { autoinject } from "../../../framework/forms/form-export";
import { BenutzerTyp } from "../../enumerations/benutzer-typ";
import { StartupService } from "../../services/export";
import { NachrichtService, NachrichtTyp } from "../../services/nachricht-service";
import { IDatei } from "../datei/datei";

@autoinject
export class NachrichtItem {
  private _nachrichtGelesenSubscription: Subscription;
  private _nachrichtUngelesenSubscription: Subscription;
  private NACHRICHT_TYP_TELEFONAT: number = 1;
  private NACHRICHT_TYP_NOTIZ: number = 3;
  private NACHRICHT_STATUS_VERSENDET: number = 1;
  private NACHRICHT_STATUS_GELESEN: number = 2;

  constructor(
    private _element: Element,
    private _eventAggregator: EventAggregator,
    private _startupService: StartupService,
    private _nachrichtService: NachrichtService) {
    this.idBenutzer = this._startupService.startupInfo.Benutzer.IdBenutzer;
  }

  @bindable item: any;
  idBenutzer: number;

  dateiList: IDatei[];
  overruleClass: string;

  @computedFrom("item")
  get showMassennachricht() {
    return this.item.IsMassennachricht
      && this._startupService.startupInfo.Benutzer.CanNachrichtMassennachricht;
  }

  bind() {
    this._nachrichtGelesenSubscription = this._eventAggregator.subscribe("nachricht:gelesen", (e) => {
      if (e.keyNachricht != this.item.KeyNachricht) {
        return;
      }

      this.item.Status = this.NACHRICHT_STATUS_GELESEN;
    });
    this._nachrichtUngelesenSubscription = this._eventAggregator.subscribe("nachricht:ungelesen", (e) => {
      if (e.keyNachricht != this.item.KeyNachricht) {
        return;
      }

      this.item.Status = this.NACHRICHT_STATUS_VERSENDET;
    });

    this.dateiList = (this.item.DateiList || []).map((d) => {
      return <IDatei>{
        DMSLink: d.DMSLink,
        DMSLinkDownload: d.DMSLinkDownload,
        Dateiname: d.Dateiname
      };
    });

    switch (this.item.Typ) {
      case NachrichtTyp.Telefonat: {
        this.overruleClass = "z--nachr-nachrichten-telefonat";
        break;
      }
      case NachrichtTyp.Notiz: {
        this.overruleClass = "z--nachr-nachrichten-notiz";
        break;
      }
      default: {
        break;
      }
    }

    this.doUrlValidation();
  }
  unbind() {
    if (this._nachrichtGelesenSubscription) {
      this._nachrichtGelesenSubscription.dispose();
      this._nachrichtGelesenSubscription = null;
    }
    if (this._nachrichtUngelesenSubscription) {
      this._nachrichtUngelesenSubscription.dispose();
      this._nachrichtUngelesenSubscription = null;
    }
  }

  onNachrichtContextMenuClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    const ctxMenu = new ContextMenu();

    const isEmpfaenger = this._startupService.startupInfo.Benutzer.IdBenutzer == this.item.IdEmpfaenger;
    const isAbsender = this._startupService.startupInfo.Benutzer.IdBenutzer == this.item.IdAbsender;

    const benutzertyp = this._startupService.startupInfo.Benutzer.Benutzertyp;
    const isBenutzerNatuerlichePerson = benutzertyp == BenutzerTyp.NatuerlichePerson;
    const isBenutzerAdmin = benutzertyp == BenutzerTyp.Administrator;

    const isNotiz = this.item.Typ == NachrichtTyp.Notiz
      || this.item.Typ == NachrichtTyp.Telefonat;

    if (!isNotiz) {
      ctxMenu.items.push({
        text: "Antworten",
        execute: () => {
          this._nachrichtService.doAntwortNachrichtErstellen(this.item.KeyNachricht, this.item.IdAbsender, this.item.Absender);
        }
      });
    }

    if (!isBenutzerNatuerlichePerson && !isNotiz) {
      ctxMenu.items.push({
        text: "Weiterleiten",
        execute: () => {
          this._nachrichtService.doWeiterleitenNachrichtErstellen(this.item.KeyNachricht, this.item.TextOrg, this.dateiList);
        }
      });
    }
    if (!isBenutzerNatuerlichePerson && !this.item.IdNachrichtGruppe && !isNotiz) {
      ctxMenu.items.push({
        text: "Umleiten",
        execute: async() => {
          this._nachrichtService.doUmleitenNachrichtErstellen(this.item.KeyNachricht, this.item.TextOrg, this.dateiList);

          this._eventAggregator.publish("nachricht:gelesen", {
            keyNachricht: this.item.KeyNachricht
          });
        }
      });
    }
    if (isEmpfaenger && !isNotiz) {
      ctxMenu.items.push({
        text: "ungelesen markieren",
        execute: async() => {
          await this._nachrichtService.doNachrichtUngelesenMarkieren(this.item.KeyNachricht);

          this._eventAggregator.publish("nachricht:ungelesen", {
            keyNachricht: this.item.KeyNachricht
          });
        }
      });
    }
    if (isAbsender || isBenutzerAdmin) {
      ctxMenu.items.push({
        text: "löschen",
        execute: async() => {
          const r = await this._nachrichtService.showDeleteDialog(this.item.KeyNachricht);
          if (!r) {
            return;
          }

          this._eventAggregator.publish("nachricht:geloescht", {
            keyNachricht: this.item.KeyNachricht
          });
        }
      });
    }
    ctxMenu.items.push({
      text: "Nachrichteninfo",
      execute: () => {
        this._element.dispatchEvent(new CustomEvent(
          "on-nachricht-protokoll-click", {
          bubbles: true,
          detail: {
            nachricht: this.item
          }
        }
        ));
      }
    });

    ctxMenu.show(e.target);
  }
  onNachrichtMassenClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this._element.dispatchEvent(new CustomEvent(
      "on-nachricht-massennachricht-click", {
      bubbles: true,
      detail: {
        nachricht: this.item
      }
    }
    ));
  }
  onNachrichtClick(e: Event) {
    if (e.target["tagName"] == "A") {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this._element.dispatchEvent(new CustomEvent(
      "on-nachricht-click", {
      bubbles: true,
      detail: {
        nachricht: this.item
      }
    }
    ));

    if (this.item.Typ == this.NACHRICHT_TYP_TELEFONAT || this.item.Typ == this.NACHRICHT_TYP_NOTIZ) {
      this._nachrichtService.doNachrichtBearbeiten(this.item);
    }
  }

  private doUrlValidation() {
    const cardText = document.getElementById(this.item.KeyNachricht);
    if (!cardText) {
      return;
    }

    const text = document.createElement("span");
    text.innerHTML = this.item.Text;
    cardText.appendChild(text);
  }
}
