import * as fwx from "../../../../../framework/forms/form-export";
import {
    PasswordConfirmFuncs
} from "./password-confirm-form-funcs";

@fwx.autoinject
export class PasswordConfirmForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f: PasswordConfirmFuncs) {
        super(element, formBaseImport);
        this.id = "password-confirm-form";
        this.title = "password-confirm-form.password-confirm-form_caption";
        this.addModel({
            "id": "$m_Password",
            "filters": []
        });
        this.addFunction("$f", $f, "functions.$f");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "mode": "password",
            "caption": "password-confirm-form.password_caption",
            "binding": {
                "dataContext": "$m_Password",
                "bindTo": "Passwort1",
                "bindToFQ": "models.data.$m_Password.Passwort1"
            },
            "validationRules": [],
            "id": "password",
            "options": {
                "optionsName": "passwordOptions",
                "optionsNameFQ": "passwordOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "mode": "password",
            "caption": "password-confirm-form.confirmpassword_caption",
            "binding": {
                "dataContext": "$m_Password",
                "bindTo": "Passwort2",
                "bindToFQ": "models.data.$m_Password.Passwort2"
            },
            "validationRules": [],
            "id": "confirmPassword",
            "options": {
                "optionsName": "confirmPasswordOptions",
                "optionsNameFQ": "confirmPasswordOptions"
            }
        });
        this.widgetCreator.addCommand(this, {
            "id": "wd2",
            "options": {
                "optionsName": "wd2Options",
                "optionsNameFQ": "wd2Options"
            },
            "binding": {
                "bindTo": "$f.confirmPasswordCommand",
                "bindToFQ": "functions.$f.confirmPasswordCommand"
            }
        });
        this.widgetCreator.addCommand(this, {
            "id": "wd3",
            "options": {
                "optionsName": "wd3Options",
                "optionsNameFQ": "wd3Options"
            },
            "binding": {
                "bindTo": "$f.backToLoginCommand",
                "bindToFQ": "functions.$f.backToLoginCommand"
            }
        });
        super.onConstructionFinished();
    }
}