import { SimpleWidgetCreatorService } from './../../../framework/forms/widget-services/simple-widget-creator-service';
import { ScopeContainer } from './../../../framework/base/classes/scope-container';
import { RestService } from './../../../framework/base/services/rest-service';
import { LocalizationService } from './../../../framework/base/services/localization-service';
import { autoinject } from "aurelia-framework";
import { IdxPopupComponent } from '../../interfaces/export';

@autoinject
export class NachrichtMassennachricht {
  constructor(
    private localizationService: LocalizationService,
    private restService: RestService,
    private simpleWidgetCreator: SimpleWidgetCreatorService
  ) { }

  scopeContainer: ScopeContainer;
  massennachrichtDataSource: DevExpress.data.DataSource;

  nachricht: any;

  nachrichtMassennachrichtPopup: IdxPopupComponent;
  nachrichtMassennachrichtPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxWidth: "750px",
    title: this.localizationService.translateOnce("nachricht-massennachricht.massennachricht-empfaenger"),
    onShown: () => {
      this.massennachrichtDataSource = new DevExpress.data.DataSource(
        <any>new DevExpress.data.CustomStore({
          load: (e) => {
            return new Promise<any>((resolve, reject) => {
              this.restService.get({
                url: this.restService.getApiUrl("ZIT/Nachricht/NachrichtEmpfaenger") + `?keyNachricht=${this.nachricht.KeyNachricht}`,
                increaseLoadingCount: true
              }).then(r => {
                resolve({
                  data: r
                });
              })
            });
          }
        }));
    }
  };

  massennachrichtGridOptions: DevExpress.ui.dxDataGridOptions = {
    columnAutoWidth: true,
    hoverStateEnabled: true,
    searchPanel: {
      visible: true
    },
    paging: { pageSize: 10 },
    columns: [
      { dataField: "Name", caption: this.localizationService.translateOnce("nachricht-massennachricht.name") },
      { dataField: "IsGelesen", caption: this.localizationService.translateOnce("nachricht-massennachricht.is-gelesen"), width: "90px" }
    ],
    bindingOptions: {
      dataSource: "massennachrichtDataSource"
    }
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "nachrichtMassennachrichtPopupToolbar",
      caption: "nachricht-massennachricht.massennachricht-empfaenger",
      options: this.nachrichtMassennachrichtPopupOptions,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  showPopup(nachricht: any) {
    this.nachricht = nachricht;
    this.nachrichtMassennachrichtPopup.instance.show();
  }
}
