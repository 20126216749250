import { autoinject } from "aurelia-framework";
import { IdxPopupComponent } from '../../interfaces/dx-components';
import { BrowserService, ScopeContainer } from '../../../framework/base/export';
import { IframePopupService, IFramePopupShowOptions } from '../../services/iframe-popup-service';
import { SimpleWidgetCreatorService } from '../../../framework/forms/export';

@autoinject
export class IframePopup {
  constructor(
    private _browserService: BrowserService,
    private _iframePopupService: IframePopupService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService
  ) {
    this._iframePopupService.popup = this;
    this.isMobil = _browserService.isMobile;
  }

  isMobil: boolean;
  videoVisible: boolean;
  options: IFramePopupShowOptions;
  popupTitel: string;

  scopeContainer: ScopeContainer;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    width: "auto",
    onShowing: () => {
      this.videoVisible = true;
    },
    onShown: (ev) => {
      ev.component.repaint()
    },
    onHidden: () => {
      this.videoVisible = false;
    }
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "iframePopupToolbar",
      caption: "iframe-popup.popup-titel",
      options: this.popupOptions,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  show(options: IFramePopupShowOptions) {
    this.options = options;
    this.popupTitel = options.titel || "";

    this.popup.instance.show();
  }
}