import { LocationService } from './../../../framework/base/services/location-service';
import { ObjektInfoService } from './../../services/objekt-info-service';
import { observable, Scope, OverrideContext } from 'aurelia-binding';
import { BenutzerTyp } from './../../enumerations/benutzer-typ';
import { StartupService } from './../../services/startup-service';
import { NachrichtService } from './../../services/nachricht-service';
import { SimpleWidgetCreatorService } from './../../../framework/forms/widget-services/simple-widget-creator-service';
import { ScopeContainer } from './../../../framework/base/classes/scope-container';
import { RestService } from './../../../framework/base/services/rest-service';
import { LocalizationService } from './../../../framework/base/services/localization-service';
import { autoinject, bindable, transient } from "aurelia-framework";
import { IdxPopupComponent } from '../../interfaces/export';
import { GlobalizationService } from '../../../framework/base/export';
import { IZitTagOptions } from '../../../zit-ui/elements/zit-tag/export';
import { FormUtilsService, PopupInfoService } from '../../../framework/forms/export';

@autoinject
export class NachrichtProtokoll {
  constructor(
    private _localizationService: LocalizationService,
    private _restService: RestService,
    private _simpleWidgetCreator: SimpleWidgetCreatorService,
    private _nachrichtService: NachrichtService,
    private _globalizationService: GlobalizationService,
    private _objektInfoService: ObjektInfoService,
    private _locationService: LocationService,
    private _formUtilsService: FormUtilsService,
    private _popupInfoService: PopupInfoService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  protokollDataSource: DevExpress.data.DataSource;

  nachricht: any;

  nachrichtProtokollPopup: IdxPopupComponent;
  nachrichtProtokollPopupOptions: DevExpress.ui.dxPopupOptions = {
    height: "auto",
    maxWidth: "900px",
    title: this._localizationService.translateOnce("nachricht-protokoll.nachrichteninfo"),
    onShown: () => {
      this.protokollDataSource = new DevExpress.data.DataSource(
        <any>new DevExpress.data.CustomStore({
          load: async (e) => {
            const r = await this._restService.get({
              url: this._restService.getApiUrl("ZIT/Nachricht/NachrichtProtokoll") + `?keyNachricht=${this.nachricht.KeyNachricht}`,
              increaseLoadingCount: true
            });

            return {
              data: r
            };
          }
        }));
    }
  };
  protokollGridOptions: DevExpress.ui.dxDataGridOptions = {
    columnAutoWidth: true,
    paging: {
      pageSize: 10
    },
    hoverStateEnabled: true,
    searchPanel: {
      visible: true
    },
    columns: [{ 
        dataField: "Benutzer", 
        caption: this._localizationService.translateOnce("nachricht-protokoll.benutzer"),
        width: "180px"
      }, { 
        dataField: "TypBezeichnung", 
        caption: this._localizationService.translateOnce("nachricht-protokoll.typ"),
        width: "120px"
      }, {
        dataField: "Datum", 
        caption: this._localizationService.translateOnce("nachricht-protokoll.protokoll-datum"), 
        format: this._globalizationService.getFormatter("g"),
        width: "120px"
      }, { 
        dataField: "Ursprungsempfaenger", 
        caption: this._localizationService.translateOnce("nachricht-protokoll.ursprungsempfaenger"),
        width: "180px" 
      }, {
        dataField: "ReferenzNachricht", 
        caption: this._localizationService.translateOnce("nachricht-protokoll.referenz-nachricht"), 
        width: "150px" 
      }, { 
        dataField: "ExterneSchnittstelleBezeichnung", 
        caption: this._localizationService.translateOnce("nachricht-protokoll.externe-schnittstelle"),
        minWidth: 100
      }
    ],
    bindingOptions: {
      dataSource: "protokollDataSource"
    }
  };
  objektTagOptions: IZitTagOptions = {
    icon: {
      faIconExpression: "item.ObjektSymbol"
    },
    textExpression: "item.ObjektBez",
    onClick: () => {
      const url = this._objektInfoService.getObjektNavigationUrl(this.nachricht.ObjektTypeName) + "/" + this.nachricht.IdObjekt;

      if (url) {
        const currentForm = this._formUtilsService.getMainForm(this.scope);
        this._locationService.goTo({
          url: url,
          currentViewModel: currentForm
        });

        this._popupInfoService.closeAllPopups();
      }
    }
  };
  nachrichtentypTagOptions: IZitTagOptions = {
    icon: {
      faIconExpression: "item.TypBez == 'Nachricht' ? 'far fa-comment-dots' : item.TypBez == 'Telefonat' ? 'fas fa-phone-alt' : item.TypBez == 'ext. Email' ? 'far fa-envelope' : '' "
    },
    textExpression: "item.TypBez"
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreator.updatePopupOptions({
      idToolbar: "nachrichtProtokollPopupToolbar",
      caption: "nachricht-protokoll.nachrichteninfo",
      options: this.nachrichtProtokollPopupOptions,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  showPopup(nachricht: any) {
    this.nachricht = nachricht;
    this.nachrichtProtokollPopup.instance.show();
  }
}
