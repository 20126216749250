import {
  autoinject,
  TemplatingEngine
} from "aurelia-framework";
import {
  LocalizationService, BrowserService
} from "../../framework/base/export";
import {
  FormEventService, ICommandData, IFileUploaderWithViewerOptions, TipFileUploaderWithViewer, FormBase
} from "../../framework/forms/export";
import { ObjektInfoService } from '../../zit/services/objekt-info-service';
import { CustomEditPopupService } from '../../zit/services/custom-edit-popup-service';
import { FormEvalService } from '../../zit/services/form-eval-service';
import { RouterService } from '../../framework/forms/services/router-service';
import { LayoutTyp } from '../../zit/interfaces/layout-typ';
import { LocationService } from '../../framework/base/services/location-service';
import { StartupService } from './startup-service';
import { BenutzerTyp } from '../enumerations/export';
import { EventAggregator } from 'aurelia-event-aggregator';
import { IToolbarOptions } from '../../framework/forms/elements/toolbar/toolbar-options';
import { ErklaervideoService } from './erklaervideo-service';
import { NachrichtService } from './nachricht-service';

@autoinject
export class FormExtenderService {
  constructor(
    private _formEventService: FormEventService,
    private _localizationService: LocalizationService,
    private _templatingEngine: TemplatingEngine,
    private _objektInfoService: ObjektInfoService,
    private _customEditPopupService: CustomEditPopupService,
    private _formEvalService: FormEvalService,
    private _routerService: RouterService,
    private _locationService: LocationService,
    private _startupService: StartupService,
    private _eventAggregator: EventAggregator,
    private _erklaervideoService: ErklaervideoService,
    private _browserService: BrowserService,
    private _nachrichtService: NachrichtService
  ) {
    this.registerAendInfo();
    this.registerShowEditForm();
    this.registerShowBenutzergruppeEditForm();
    this.registerFileUploader();
    this.registerErklaervideo();
    this.registerNachrichtGruppe();
  }

  private registerAendInfo() {
    this._formEventService.onAttached.register(e => {
      if (!e.form.models.modelWithKeyId) {
        return;
      }
      if (e.form.isNestedForm) {
        return;
      }
      if (e.form.isEditPopup && e.form.id != "nachrichten-gruppe-edit") {
        return;
      }
      if (e.form.id == "event-zeitraum-read") {
        return;
      }

      const model = e.form.models.modelWithKeyId;

      let parentElement = e.form.element.querySelector(".t--form-content .dx-scrollview-content > .parent-container > .container");
      if (!parentElement) {
        parentElement = e.form.element.querySelector(".t--form-validation-group > div > .parent-container > .container");

        if (!parentElement) {
          return;
        }
      }

      const modelPraefix = "models.data.".concat(model.id).concat(".");

      const aendDiv = document.createElement("div");
      aendDiv.className = "col-xs-12 z--aenderung-info";

      const erstelltDiv = document.createElement("div");
      erstelltDiv.setAttribute("if.bind", modelPraefix.concat("Anlagedatum"));

      let erstelltText = this._localizationService.translateOnce("zit-ui.erstellt_am");
      erstelltText = this.replaceIndex(erstelltText, 0, modelPraefix.concat("Anlagedatum | format:'g'"));
      erstelltDiv.innerText = erstelltText;
      aendDiv.appendChild(erstelltDiv);

      const geandertDiv = document.createElement("div");
      geandertDiv.setAttribute("if.bind", modelPraefix.concat("Aenderungsdatum"));

      let geaendertText = this._localizationService.translateOnce("zit-ui.geaendert_am");
      geaendertText = this.replaceIndex(geaendertText, 0, modelPraefix.concat("Aenderungsdatum | format:'g'"));
      geandertDiv.innerText = geaendertText;
      aendDiv.appendChild(geandertDiv);
      
      if (modelPraefix === "models.data.$m_Person.") {
        const ltztVerwendungDiv = document.createElement("div");
        ltztVerwendungDiv.setAttribute("if.bind", modelPraefix.concat("_LetzteVerwendung"));

        let ltztVerwendungText = this._localizationService.translateOnce("zit-ui.ltzt_verwendung_am");
        ltztVerwendungText = this.replaceIndex(ltztVerwendungText, 0, modelPraefix.concat("_LetzteVerwendung | format:'g'"));
        ltztVerwendungDiv.innerText = ltztVerwendungText;
        aendDiv.appendChild(ltztVerwendungDiv);
      }

      if (modelPraefix === "models.data.$m_Benutzer.") {
        const aktiviertDiv = document.createElement("div");
        aktiviertDiv.setAttribute("if.bind", modelPraefix.concat("Aktivierungsdatum"));

        let aktiviertText = this._localizationService.translateOnce("zit-ui.aktiviert_am");
        aktiviertText = this.replaceIndex(aktiviertText, 0, modelPraefix.concat("Aktivierungsdatum | format:'g'"));
        aktiviertDiv.innerText = aktiviertText;
        aendDiv.appendChild(aktiviertDiv);
      }

      parentElement.appendChild(aendDiv);

      this._templatingEngine.enhance({
        element: aendDiv,
        bindingContext: e.form.scope.bindingContext,
        overrideContext: e.form.scope.overrideContext
      });

      return Promise.resolve();
    });
  }
  private replaceIndex(input: string, index: number, value: string): string {
    const find = "{" + index + "}";
    const replace = "${" + value + "}";

    return input.replace(find, replace)
  }

  private registerShowEditForm() {
    this._formEventService.onBind.register(e => {
      e.form.callOnBind(async () => {
        const addCommand = e.form.viewItemInfo
          && e.form.viewItemInfo.routeInfo
          && e.form.viewItemInfo.routeInfo.route
          && e.form.viewItemInfo.routeInfo.route.route
          && e.form.viewItemInfo.routeInfo.parameters.id
          && e.form.viewItemInfo.moduleId
          && e.form.viewItemInfo.moduleId.endsWith("read-form");

        if (!addCommand) {
          return;
        }

        const editCommand = {
          id: "$showEditForm",
          icon: "fas fa-pencil-alt",
          tooltip: "zit.show-edit",
          isVisible: false,
          sortIndex: 50,
          execute: () => {
            const url = e.form.viewItemInfo.routeInfo.url + "?mode=1";

            const getCurrent = (): FormBase => {
              return this._routerService.currentViewItem
                && this._routerService.currentViewItem.controller
                && (<any>this._routerService.currentViewItem.controller).currentViewModel
                ? (<any>this._routerService.currentViewItem.controller).currentViewModel
                : null;
            }
            let current = getCurrent();
            if (current && current.id == "nachrichtenList") {
              this._routerService.removeLastViewItem();

              current = getCurrent();
            }

            this._locationService.goTo({
              url: url,
              currentViewModel: e.form,
              replace: (current == e.form)
            });
          }
        };

        e.form.commands.addCommand(editCommand);

        let route = e.form.viewItemInfo.routeInfo.route.route;
        if (Array.isArray(route)) {
          route = route[0];
        }

        const formBearbeitung = await this._formEvalService.checkRoute(
          route,
          LayoutTyp.Bearbeitung,
          e.form.viewItemInfo.routeInfo.parameters.id,
          false
        );

        if (formBearbeitung && formBearbeitung != e.form.viewItemInfo.moduleId) {
          editCommand.isVisible = true;
        }
      });

      return Promise.resolve();
    });
  }

  private registerShowBenutzergruppeEditForm() {
    this._formEventService.onBind.register(e => {
      e.form.callOnBind(async () => {
        const isAdmin = this._startupService.startupInfo.Benutzer.Benutzertyp == BenutzerTyp.Administrator;

        if (!isAdmin) {
          return;
        }

        const model = e.form.models.modelWithKeyId;
        if (!model) {
          return;
        }

        const objektInfo = this._objektInfoService.getObjektByWebApiUrl(model.webApiAction);
        if (!objektInfo) {
          return;
        }

        const editCommand: ICommandData = {
          id: "$showBenutzergruppen",
          icon: "fas fa-users",
          tooltip: "zit.show-benutzergruppe",
          sortIndex: 500,
          isVisibleExpression: `models.data.${model.id}.Id`,
          expressionScope: e.form.scope,
          execute: () => {
            const data = e.form.models.data[model.id];
            const id = data.Id;
            if (!id) {
              return;
            }

            this._customEditPopupService.objektZuBenutzergruppe.show({
              mappings: {
                "$v_idObjekt": id,
                "$v_titel": data.Titel
              }
            });
          }
        };

        e.form.commands.addCommand(editCommand);
      });

      return Promise.resolve();
    });
  }

  private registerNachrichtGruppe() {
    this._formEventService.onBind.register(e => {
      if (e.form.isEditPopup) {
        return;
      }
      if (e.form.isNestedForm) {
        return;
      }
      if (!this._startupService.startupInfo.Benutzer.HasNachrichten) {
        return;
      }

      e.form.callOnBind(async () => {
        const model = e.form.models.modelWithKeyId;
        if (!model) {
          return;
        }

        const objektInfo = this._objektInfoService.getObjektByWebApiUrl(model.webApiAction);
        if (!objektInfo) {
          return;
        }

        const editCommand = {
          id: "showNachrichten",
          icon: "far fa-comment-dots",
          tooltip: "zit.nachrichten",
          sortIndex: 95,
          isVisible: false,
          expressionScope: e.form.scope,
          execute: () => {}
        };
        e.form.commands.addCommand(editCommand);
        
        e.form.models.onLoaded.register(async (mle) => {
          if (!mle.data || !mle.data.Id) {
            return;
          }

          const info = await this._nachrichtService.getNachrichtGruppeBenutzerInfoForObjekt(
            objektInfo.Fullname,
            mle.data.Id
          );

          if (!info) {
            return;
          }

          const goToAction = () => {
            this._locationService.goTo({
              url: "Nachrichten",
              currentViewModel: e.form,
              customOptions: {
                benutzerInfo: info
              }
            });
          };

          editCommand.isVisible = true;
          editCommand.execute = goToAction;

          if (!this._browserService.isMobile) {
            goToAction();
          }
        })
      });

      return Promise.resolve();
    });
  }

  private registerFileUploader() {
    this._eventAggregator.subscribe("file-uploader:bind", e => {
      const options: IFileUploaderWithViewerOptions = e.options;

      options.showDownloadButton = false;
      options.showToolbar = false;
      options.showToolbarBottom = true;
    });

    this._eventAggregator.subscribe("file-uploader:create-toolbar-bottom", e => {
      const toolbarOptions: IToolbarOptions = e.toolbarOptions;
      const instance: TipFileUploaderWithViewer = e.instance;

      toolbarOptions.items.push({
        id: "file-download",
        icon: "fas fa-download",
        sortIndex: 1050,
        isVisibleExpression: "showViewer",
        execute: (e: any) => {
          if (e && e.event && e.event.stopPropagation) {
            e.event.stopPropagation();
          }

          window.open(instance.downloadUrl, "_blank");
        }
      });
      toolbarOptions.items.push({
        id: "file-upload",
        icon: "fas fa-upload",
        sortIndex: 1051,
        isEnabledExpression: "canUpload",
        execute: (e: any) => {
          if (e && e.event && e.event.stopPropagation) {
            e.event.stopPropagation();
          }

          instance.onClick();
        }
      });
      toolbarOptions.items.push({
        id: "file-delete",
        icon: "fas fa-times",
        sortIndex: 1052,
        isEnabledExpression: "canUpload",
        execute: (e: any) => {
          if (e && e.event && e.event.stopPropagation) {
            e.event.stopPropagation();
          }

          instance.setValue(null);
        }
      });
    });
  }

  private registerErklaervideo() {
    this._erklaervideoService.init();
    
    this._formEventService.onBind.register(e => {
      if (e.form && e.form.viewItemInfo && e.form.viewItemInfo.moduleId) {
        const moduleId = e.form.viewItemInfo.moduleId;
        this._erklaervideoService.attachedToolbarButton(moduleId, i => e.form.commands.addCommand(i));
      }

      return Promise.resolve();
    });
  }
}
