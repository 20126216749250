import { autoinject, bindable, bindingBehavior, computedFrom } from "aurelia-framework";
import { StartupService } from '../../../services/export';

@autoinject
export class TerminEmpfEinschaetzungAkk {
  constructor(
    private _startupService: StartupService
  ) { 
    this.selectedColor = this._startupService.startupInfo.Skin.Farbe20Opacity;
  }

  @bindable code: string;
  @bindable text: string;
  @bindable selected: string;
  @bindable ok: boolean;

  selectedColor: string;

  @computedFrom("selected")
  get isSelected() {
    return this.code == this.selected;
  }

  onClick() {
    if (this.selected == this.code) {
      this.selected = "";
    } else {
      this.selected = this.code;
    }
  }
}