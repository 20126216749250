import { autoinject, computedFrom } from 'aurelia-framework';
import { LayoutService } from '../../export';
import { StartupService } from "../../../zit/services/startup-service";

@autoinject
export class Container {
  constructor(
    public layoutService: LayoutService,
    private _startupService: StartupService    
  ) { }

  @computedFrom("layoutService.isSidebarCollapsed")
  get className(): string {
    const classes = [];
    
    classes.push(this.layoutService.isSidebarCollapsed
      ? "z--sidebar-collapsed"
      : "z--sidebar-expanded");
    
    if (this._startupService.startupInfo.IsApp) {
      classes.push("z--is-app");
    }
    
    return classes.join(" ");
  }

  attached() {
    this.layoutService.resizeContainer();
  }
}
