import {
  autoinject,
  singleton,
  TaskQueue
} from "aurelia-framework";
import {
  FormBase
} from "../classes/form-base";
import * as Interfaces from "../interfaces/export";
import { NestedForm } from '../interfaces/nested-form-x';

@autoinject
@singleton(true)
export class NestedForms {
  private form: FormBase;
  private nestedForms = {};

  constructor(
    private taskQueue: TaskQueue
  ) {}

  addInfo(id: string, mappings: Interfaces.IMapping[]) {
    this.nestedForms[id] = {
      id: id,
      mappings: mappings
    };
    this.createMappings(id, mappings);
  } 
  getInfo(id: string): NestedForm  {
    return this.nestedForms[id];
  }
  getNestedForms(): FormBase[] {
    const arr: FormBase[] = [];

    for (let key in this.nestedForms) {
      const form: FormBase = this.form[key];
      
      if (!form) {
        continue;
      }
      
      arr.push(form);
      arr.push(...form.nestedForms.getNestedForms());
    }

    return arr;
  }
  createMappings(id: string, mappings: Interfaces.IMapping[]) {
    for (let mapping of mappings) {
      this.form.binding.observe({
        scopeContainer: this.form.scopeContainer,
        expression: mapping.binding.bindToFQ,
        callback: (newValue) => {
          const setVariable = () => {
            let nestedForm: FormBase = this.form[id];
            if (!nestedForm) {
              return false;
            }

            nestedForm.variables.data[mapping.to] = newValue;
            return true;
          }

          if (!setVariable()) {
            this.taskQueue.queueTask(() => {
              if (!setVariable()) {
                return;
              }
            })
          }
        },
        checkPrimitiveType: true
      });
    }
  }

  registerForm(form: FormBase) {
    if (this.form) {
      throw new Error("Form was already registered");
    }

    this.form = form;
  }

  dispose() {
  }
}
