import { autoinject } from "aurelia-framework";
import { FotoPopup } from "./../elements/foto-popup/foto-popup";

@autoinject
export class FotoPopupService {
  popup: FotoPopup;
}

export interface IFotoPopupShowOptions {
  dmsLink?: string;
  name?: string;
}
