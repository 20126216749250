import { LocalizationService } from "./../../framework/base/services/localization-service";
import { ValidationService } from "./../../framework/forms/services/validation-service";
import { autoinject } from "aurelia-framework";

@autoinject
export class ValidationExService {
  constructor(
    private localization: LocalizationService,
    private validation: ValidationService
  ) {
    this.registerTelefon();
  }

  private registerTelefon() {
    this.validation.registerValidator("telefon", (scopeContainer, caption, parameters) => {
      return {
        type: "custom",
        message: this.localization.translateOnce(
          "forms.validator_telefon",
          [this.localization.translateOnce(caption)]),
        validationCallback: (e) => {
          if(!e.value){
            return true;
          }
          
          const val: string = e.value;
          const isValid = /^[0-9 ]{4,}$/.test(val);
          
          return isValid;
        }
      };
    });
  }
}
