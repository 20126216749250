import { autoinject } from "aurelia-framework";
import { ScopeContainer } from '../../../framework/base/export';
import { ICommandData, SimpleWidgetCreatorService } from '../../../framework/forms/export';
import { IZitTagOptions } from '../../../zit-ui/elements/zit-tag/zit-tag-options';
import { IdxDateBoxComponent, IdxPopupComponent } from '../../interfaces/export';
import { IZuweisenAufgabeModel } from '../../services/aufgabe-service';
import { GlobalizationService } from './../../../framework/base/services/globalization-service';

@autoinject
export class AufgabeZuweisenStart {
  constructor(
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _globalizationService: GlobalizationService) {
  }

  scopeContainer: ScopeContainer;
  options: IAufgabeZuweisenStart;
  aufgabeArtZuweisen: any = 0;

  resultAufgabensetList: {
    CodeAufgabenSet: string,
    AufgabensetBezeichnung: string,
    Datum?: Date
  }[] = [];

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "600px",
    height: "530px"
  };

  dateBox: IdxDateBoxComponent;
  dateBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this._globalizationService.getFormatterParser("d"),
    bindingOptions: {
      value: "aufgabenset.Datum"
    }
  };

  objektTagOptions: IZitTagOptions = {
    icon: {
      textExpression: "item.Typ"
    },
    textExpression: "item.Bezeichnung"
  };

  aufgabeStartZuweisenCommand: ICommandData = {
    id: "save",
    icon: "fas fa-save",
    tooltip: "base.save",
    execute: (e) => {
      this.saveZuweisung();
    }
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "aufgabeStartZuweisenPopupToolbar",
      caption: "aufgabe-start-zuweisen.popup-titel",
      options: this.popupOptions,
      commands: [this.aufgabeStartZuweisenCommand],
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  show(options: IAufgabeZuweisenStart) {
    this.options = options;
    this.resultAufgabensetList = options.AufgabensetList.slice();
    this.popup.instance.show();
  }
  private saveZuweisung() {
    let returnList = [];
    this.resultAufgabensetList.forEach(element => {
      returnList.push({
        AufgabeCode: element.CodeAufgabenSet,
        Start: element.Datum
      })
    });
    this.popup.instance.hide();
    this.options.callback(returnList);
  }
}

export interface IAufgabeZuweisenStart {
  AufgabensetList: {
    CodeAufgabenSet: string,
    AufgabensetBezeichnung: string
  }[]

  callback?: {
    (ResultAufgabensetList: IZuweisenAufgabeModel[]): void
  }
}
