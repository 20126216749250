import {
  autoinject,
  bindable,
  customAttribute,
  Scope
} from "aurelia-framework";

@autoinject
@customAttribute("repeat-finished")
export class RepeatFinishedCustomAttribute {
  constructor(
    private _element: Element
  ) { }

  scope: Scope;

  bind(bindingContext, overrideContext) {
    this.scope = {
      bindingContext,
      overrideContext
    };
  }
  attached() {
    if (!this.scope) {
      return;
    }
    if (!this.scope.overrideContext) {
      return;
    }
    if (!(<any>this.scope.overrideContext).$last) {
      return;
    }

    this._element.dispatchEvent(new CustomEvent(
      "on-repeat-finished", {
        bubbles: true,
        detail: {
          element: this._element
        }
      }
    ));
  }
}
