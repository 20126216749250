import * as fwx from "../../../../framework/forms/form-export";
import {
    BewerbungEditUtils
} from "./bewerbung-edit-utils";

@fwx.autoinject
export class BewerbungFerialerErsteinstiegEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_BewerbungEditUtils: BewerbungEditUtils) {
        super(element, formBaseImport);
        this.id = "bewerbung-ferialer-ersteinstieg-edit";
        this.title = "bewerbung-ferialer-ersteinstieg-edit.bewerbung-ferialer-ersteinstieg-edit_caption";
        this.addModel({
            "id": "$m_Bewerbung",
            "webApiAction": "ZIT/Objekte/Bewerbung",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'Stelleninserat': {
                            'columns': [
                                'Id',
                                'IdStelleninseratKopfElement',
                                'IdStelleninseratFerialbewerbungFragebogenElement',
                                'IdStelleninseratFerialeinsatzEinstellungenElement'
                            ],
                            'expand': {
                                'Kopf': null,
                                'FerialbewerbungFragebogen': null,
                                'FerialeinsatzEinstellungen': null
                            }
                        },
                        'Person': {
                            'columns': [
                                'Id',
                                'IdPersonPersonendatenElement'
                            ],
                            'expand': {
                                'Personendaten': null
                            }
                        },
                        'Bewerbungsgrund': null,
                        'Bewerbungskanal': null,
                        'Einsatzbereiche': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "webApiCustomKey": "BewerbungInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "StelleninseratInfo",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddStelleninseratCard",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_BewerbungKopf",
            "webApiAction": "ZIT/Objekte/BewerbungKopfElement",
            "key": "models.data.$m_Bewerbung.IdBewerbungKopfElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BewerbungEinsatzbereichRel",
            "webApiAction": "ZIT/Objekte/BewerbungKopfEinsatzbereich",
            "webApiExpand": {
                'Einsatzbereich': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BewerbungEinsatzbereichEdit",
            "webApiAction": "ZIT/Objekte/BewerbungKopfEinsatzbereich",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Person",
            "webApiAction": "ZIT/Objekte/Person",
            "modificationInfoEnabled": true,
            "key": "models.data.$m_Bewerbung.Kopf.IdPerson",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_DokumentRel",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "webApiExpand": {
                'Informationstyp': null
            },
            "webApiWhere": ["IdStelleninserat", {
                "isBound": true,
                "expression": "models.data.$m_Bewerbung.Kopf.IdStelleninserat"
            }],
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_DokumentEdit",
            "webApiAction": "ZIT/Objekte/DokumentElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Geschaeftspartner",
            "webApiAction": "ZIT/Objekte/Geschaeftspartner",
            "webApiWhere": [],
            "modificationInfoEnabled": true,
            "key": "models.data.$m_Bewerbung.Kopf.Stelleninserat.Kopf.IdGeschaeftspartner",
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "standardAddress",
                "webApiCustomValue": "true"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BewerbungEditUtils.actionCommand",
                "bindToFQ": "functions.$f_BewerbungEditUtils.actionCommand"
            }
        });
        this.addEditPopup({
            "idContent": "bewerbungEinsatzbereichEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_BewerbungEinsatzbereichEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_BewerbungEinsatzbereichEdit.Id"
                }
            }],
            "id": "bewerbungEinsatzbereichEditPopup",
            "options": {
                "optionsName": "bewerbungEinsatzbereichEditPopupOptions",
                "optionsNameFQ": "bewerbungEinsatzbereichEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addFunction("$f_BewerbungEditUtils", $f_BewerbungEditUtils, "functions.$f_BewerbungEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "person",
            "filters": [],
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungperson_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdPerson",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdPerson",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bewerbungPerson",
            "options": {
                "optionsName": "r_bewerbungPersonOptions",
                "optionsNameFQ": "r_bewerbungPersonOptions"
            }
        });
        this.widgetCreator.addLookup(this, {
            "idSelect": "stelleninserat",
            "filters": [],
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungstelleninserat_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdStelleninserat",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdStelleninserat",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bewerbungStelleninserat",
            "options": {
                "optionsName": "r_bewerbungStelleninseratOptions",
                "optionsNameFQ": "r_bewerbungStelleninseratOptions"
            }
        });
        this.widgetCreator.addListView(this, {
            "itemClass": "z--list-view-inline-item",
            "useDefaultListItemStyle": false,
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_bewerbungEinsatzbereichListViewToolbarOptions",
                "optionsNameFQ": "r_bewerbungEinsatzbereichListViewToolbarOptions"
            },
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungeinsatzbereichlistview_caption",
            "binding": {
                "dataContext": "$m_BewerbungKopf"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_BewerbungEinsatzbereichRel",
                "bindTo": "IdBewerbungKopfElement",
                "bindToFQ": "models.data.$m_BewerbungEinsatzbereichRel.IdBewerbungKopfElement"
            },
            "dataModel": "$m_BewerbungKopf",
            "editDataContext": "$m_BewerbungEinsatzbereichEdit",
            "idEditPopup": "bewerbungEinsatzbereichEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewerbungEinsatzbereichListView",
            "options": {
                "optionsName": "r_bewerbungEinsatzbereichListViewOptions",
                "optionsNameFQ": "r_bewerbungEinsatzbereichListViewOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungverwandtmit_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.VerwandtMit",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.VerwandtMit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Bewerbung.Kopf && models.data.$m_Bewerbung.Kopf.IdVerwandtschaftsverhaeltnis"
                    }]
                }
            }],
            "id": "r_bewerbungVerwandtMit",
            "options": {
                "optionsName": "r_bewerbungVerwandtMitOptions",
                "optionsNameFQ": "r_bewerbungVerwandtMitOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "verwandtschaftsverhaeltnis",
            "filters": [],
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungverwandtschaftsverhaeltnis_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdVerwandtschaftsverhaeltnis",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdVerwandtschaftsverhaeltnis",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Bewerbung.Kopf && models.data.$m_Bewerbung.Kopf.VerwandtMit"
                    }]
                }
            }],
            "id": "r_bewerbungVerwandtschaftsverhaeltnis",
            "options": {
                "optionsName": "r_bewerbungVerwandtschaftsverhaeltnisOptions",
                "optionsNameFQ": "r_bewerbungVerwandtschaftsverhaeltnisOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungeinsatzzeitraum1von_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Einsatzzeitraum1Von",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Einsatzzeitraum1Von",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Bewerbung",
                    "bindTo": "$f_BewerbungEditUtils.einsatzzeitraum1VonCustomValidation",
                    "bindToFQ": "functions.$f_BewerbungEditUtils.einsatzzeitraum1VonCustomValidation"
                }
            }, {
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_bewerbungEinsatzzeitraum1Von",
            "options": {
                "optionsName": "r_bewerbungEinsatzzeitraum1VonOptions",
                "optionsNameFQ": "r_bewerbungEinsatzzeitraum1VonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungeinsatzzeitraum1bis_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Einsatzzeitraum1Bis",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Einsatzzeitraum1Bis",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Bewerbung",
                    "bindTo": "$f_BewerbungEditUtils.einsatzzeitraum1BisCustomValidation",
                    "bindToFQ": "functions.$f_BewerbungEditUtils.einsatzzeitraum1BisCustomValidation"
                }
            }, {
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_bewerbungEinsatzzeitraum1Bis",
            "options": {
                "optionsName": "r_bewerbungEinsatzzeitraum1BisOptions",
                "optionsNameFQ": "r_bewerbungEinsatzzeitraum1BisOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungeinsatzzeitraum2von_caption",
            "isReadOnlyExpression": "models.data.$m_Bewerbung.Kopf && (!models.data.$m_Bewerbung.Kopf.Einsatzzeitraum1Von || !models.data.$m_Bewerbung.Kopf.Einsatzzeitraum1Bis)",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Einsatzzeitraum2Von",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Einsatzzeitraum2Von",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Bewerbung",
                    "bindTo": "$f_BewerbungEditUtils.einsatzzeitraum2VonCustomValidation",
                    "bindToFQ": "functions.$f_BewerbungEditUtils.einsatzzeitraum2VonCustomValidation"
                }
            }, {
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Bewerbung.Kopf && models.data.$m_Bewerbung.Kopf.Einsatzzeitraum2Bis"
                    }]
                }
            }],
            "id": "r_bewerbungEinsatzzeitraum2Von",
            "options": {
                "optionsName": "r_bewerbungEinsatzzeitraum2VonOptions",
                "optionsNameFQ": "r_bewerbungEinsatzzeitraum2VonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungeinsatzzeitraum2bis_caption",
            "isReadOnlyExpression": "models.data.$m_Bewerbung.Kopf && (!models.data.$m_Bewerbung.Kopf.Einsatzzeitraum1Von || !models.data.$m_Bewerbung.Kopf.Einsatzzeitraum1Bis)",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Einsatzzeitraum2Bis",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Einsatzzeitraum2Bis",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Bewerbung",
                    "bindTo": "$f_BewerbungEditUtils.einsatzzeitraum2BisCustomValidation",
                    "bindToFQ": "functions.$f_BewerbungEditUtils.einsatzzeitraum2BisCustomValidation"
                }
            }, {
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Bewerbung.Kopf && models.data.$m_Bewerbung.Kopf.Einsatzzeitraum2Von"
                    }]
                }
            }],
            "id": "r_bewerbungEinsatzzeitraum2Bis",
            "options": {
                "optionsName": "r_bewerbungEinsatzzeitraum2BisOptions",
                "optionsNameFQ": "r_bewerbungEinsatzzeitraum2BisOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showSpinButtons": true,
            "min": 0.0,
            "max": 52.0,
            "format": "n0",
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbunganzahlwunschwochen_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.AnzahlWunschwochen",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.AnzahlWunschwochen",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Bewerbung",
                    "bindTo": "$f_BewerbungEditUtils.anzahlWunschWochenValidationRule",
                    "bindToFQ": "functions.$f_BewerbungEditUtils.anzahlWunschWochenValidationRule"
                }
            }],
            "id": "r_bewerbungAnzahlWunschwochen",
            "options": {
                "optionsName": "r_bewerbungAnzahlWunschwochenOptions",
                "optionsNameFQ": "r_bewerbungAnzahlWunschwochenOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungpersontaetigkeit_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Taetigkeit",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Taetigkeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bewerbungPersonTaetigkeit",
            "options": {
                "optionsName": "r_bewerbungPersonTaetigkeitOptions",
                "optionsNameFQ": "r_bewerbungPersonTaetigkeitOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "bewerbungsgrund",
            "filters": [],
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungsgrund_caption",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.IdBewerbungsgrund",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.IdBewerbungsgrund",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bewerbungsgrund",
            "options": {
                "optionsName": "r_bewerbungsgrundOptions",
                "optionsNameFQ": "r_bewerbungsgrundOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungsdatum_caption",
            "isReadOnlyExpression": "models.data.$m_Bewerbung.Kopf.Id > 0",
            "binding": {
                "dataContext": "$m_Bewerbung",
                "bindTo": "Kopf.Bewerbungsdatum",
                "bindToFQ": "models.data.$m_Bewerbung.Kopf.Bewerbungsdatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_bewerbungsdatum",
            "options": {
                "optionsName": "r_bewerbungsdatumOptions",
                "optionsNameFQ": "r_bewerbungsdatumOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_bewerbungAbgeschlossenPopup",
            "options": {
                "optionsName": "r_bewerbungAbgeschlossenPopupOptions",
                "optionsNameFQ": "r_bewerbungAbgeschlossenPopupOptions"
            },
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungabgeschlossenpopup_caption",
            "height": "auto",
            "width": "450px",
            "showCloseButton": false,
            "commands": [{
                "binding": {
                    "bindTo": "$f_BewerbungEditUtils.abschliessenCommand",
                    "bindToFQ": "functions.$f_BewerbungEditUtils.abschliessenCommand"
                }
            }]
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_bewerbungEinsatzbereichPopup",
            "options": {
                "optionsName": "r_bewerbungEinsatzbereichPopupOptions",
                "optionsNameFQ": "r_bewerbungEinsatzbereichPopupOptions"
            },
            "caption": "bewerbung-ferialer-ersteinstieg-edit.r_bewerbungeinsatzbereichpopup_caption",
            "height": "auto",
            "width": "450px",
            "showCloseButton": false,
            "commands": [{
                "binding": {
                    "bindTo": "$f_BewerbungEditUtils.bewerbungEinsatzbereichCommand",
                    "bindToFQ": "functions.$f_BewerbungEditUtils.bewerbungEinsatzbereichCommand"
                }
            }]
        });
        super.onConstructionFinished();
    }
}