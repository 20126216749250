import { autoinject } from "aurelia-framework";
import { RestService } from "../../framework/base/export";
import { AufgabeZuweisen } from "../elements/aufgabe-zuweisen/aufgabe-zuweisen";
import { ScopeContainer } from "./../../framework/base/classes/scope-container";
import { DataSourceService } from "./../../framework/base/services/data-source-service";

@autoinject
export class AufgabeService {
  constructor(
    private _restService: RestService
  ) { }

  aufgabeZuweisen: AufgabeZuweisen;

  nav(options: INav): Promise<NavResult> {
    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Aufgabe/Nav"),
      data: options,
      increaseLoadingCount: true
    });
  }

  showZuweisen(options: IAufgabeZuweisenOptions) {
    this.aufgabeZuweisen.show(options);
  }

  zuweisenEval(options: IZuweisenEval): Promise<IZuweisenEvalResult> {
    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Aufgabe/ZuweisenEval"),
      data: options,
      increaseLoadingCount: true
    });
  }
  checkZuweisen(options: ICheckZuweisen): Promise<any> {
    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Aufgabe/CheckZuweisen"),
      data: options,
      increaseLoadingCount: true
    });
  }
  zuweisen(options: IZuweisen): Promise<any> {
    return this._restService.post({
      url: this._restService.getApiUrl("ZIT/Aufgabe/Zuweisen"),
      data: options,
      increaseLoadingCount: true
    });
  }
}

export interface IAufgabeZuweisenOptions {
  idObjektList: number[];
}

export interface IZuweisenEval {
  IdObjektList: number[];
}
export interface IZuweisenEvalResult {
  ObjektList: IZuweisenEvalObjektResult[];
  AufgabeList: [{ Code: string, Bezeichnung: string, IdAufgabengruppe: number }];
  AufgabeGruppeList: [{ Id: number, Bezeichnung: string }];
}
export interface ICheckZuweisen {
  AufgabeCodeList: string[];
  ObjektList: IZuweisenEvalObjektResult[];
}
export interface IZuweisen {
  AufgabeList: IZuweisenAufgabeModel[];
  ObjektList: IZuweisenEvalObjektResult[];
}
export interface IZuweisenAufgabeModel {
  AufgabeCode: string;
  Start: Date;
}
export interface IZuweisenEvalObjektResult {
  IdUrsprungsobjekt: number;
  IdObjekt: number;
  Typ: string;
  Bezeichnung: string;
}
export interface INav {
  IdAufgabe: number;
  NavTyp: NavTyp;
}
export enum NavTyp {
  Vorherige = 0,
  Naechste = 1,
  Alle = 2
}
export class NavResult {
  AufgabeList: NavAufgabe[];
}
export class NavAufgabe {
  Id: number;
  Bezeichnung: string;
}
