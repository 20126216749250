import * as fwx from "../../../../framework/forms/form-export";
import {
    GeschaeftspartnerReadUtils
} from "./geschaeftspartner-read-utils";

@fwx.autoinject
export class GeschaeftspartnerReadForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_GeschaeftspartnerReadUtils: GeschaeftspartnerReadUtils) {
        super(element, formBaseImport);
        this.id = "geschaeftspartner-read";
        this.title = "geschaeftspartner-read.geschaeftspartner-read_caption";
        this.addModel({
            "id": "$m_Geschaeftspartner",
            "webApiAction": "ZIT/Objekte/Geschaeftspartner",
            "webApiExpand": {
                'Geschaeftspartnerdaten': null
            },
            "modificationInfoEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "aktiveGeschaeftspartner",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "addPackage",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "addCustomSubHeadline",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "standardAddress",
                "webApiCustomValue": "true"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_GeschaeftspartnerReadUtils.actionCommand",
                "bindToFQ": "functions.$f_GeschaeftspartnerReadUtils.actionCommand"
            }
        });
        this.addFunction("$f_GeschaeftspartnerReadUtils", $f_GeschaeftspartnerReadUtils, "functions.$f_GeschaeftspartnerReadUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}