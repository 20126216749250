import {
  autoinject
} from "aurelia-framework";
import { RestService } from './rest-service';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class PermissionService {
  private _permissionInfo: any = {};

  constructor(
    private restService: RestService,
    private eventAggregator: EventAggregator
  ) {}

  allowAllPermission: boolean = false;

  async loadPermissions() {
    const result = await this.restService.get({
      url: this.restService.getApiUrl("base/Data/Permission")
    });

    const permissionInfo = {};
    for (let item of result) {
      permissionInfo[item.WebApiAction] = item;
    }

    this._permissionInfo = permissionInfo;
  }

  canWebApiNew(webApiAction: string, moduleId?: string): boolean {
    return this.canWebApi(webApiAction, "CanNew", moduleId);
  }
  canWebApiModify(webApiAction: string, moduleId?: string): boolean {
    return this.canWebApi(webApiAction, "CanModify", moduleId);
  }
  canWebApiDelete(webApiAction: string, moduleId?: string): boolean {
    return this.canWebApi(webApiAction, "CanDelete", moduleId);
  }

  private canWebApi(webApiAction: string, property: string, moduleId?: string): boolean {
    if (this.allowAllPermission) {
      return true;
    }

    const args = {
      webApiAction,
      property,
      moduleId,
      hasAccess: null
    }

    this.eventAggregator.publish("permission:has-access", args);

    if (args.hasAccess != void(0)) {
      return args.hasAccess;
    }

    if (!this._permissionInfo[webApiAction]) {
      return false;
    }

    return this._permissionInfo[webApiAction][property];
  }
}
