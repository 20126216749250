export class ZitRestrictLengthValueConverter {
  toView(text: string, maxLength: number) {
    if (!text) {
      return "";
    }
    return text.length >= maxLength
      ? `${text.substr(0, maxLength)}...`
      : text;
  }
}
